import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	QueryList,
	ViewChildren,
	ViewContainerRef,
	EventEmitter,
} from '@angular/core';
import { CompactType, DisplayGrid, GridType, GridsterItem } from 'angular-gridster2';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import { CanvasWidgetTemplatePopupSettingsComponent } from '../canvas-widget-template-popup-settings/canvas-widget-template-popup-settings.component';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';


@Component({
	selector: 'lib-generic-gridster-parent',
	templateUrl: './generic-gridster-parent.component.html',
	styleUrls: ['./generic-gridster-parent.component.scss'],
})
export class GenericGridsterParentComponent implements OnInit {
	@Input() widgets: any;
	@ViewChildren('canvasWidgetsLazyLoadingSelector', {
		read: ViewContainerRef,
	})
	canvasWidgetsLazyLoadingSelector!: QueryList<ViewContainerRef>;
	public dashboardTimeZoneChanged: EventEmitter<any> = new EventEmitter();
	public dashboardTimeScopeChanged: EventEmitter<any> = new EventEmitter();
	public sameUser: boolean = false;
	options: any = {
		gridType: GridType.Fixed,
		compactType: CompactType.CompactUp,
		//itemChangeCallback: this.itemChange.bind(this), //callback to call for each item when is changes x, y, rows, cols
		//itemResizeCallback: this.itemResize.bind(this), //callback to call for each item when width/height changes
		margin: 5,
		outerMargin: false,
		outerMarginTop: null,
		outerMarginRight: null,
		outerMarginBottom: null,
		outerMarginLeft: null,
		useTransformPositioning: false,
		mobileBreakpoint: 640,
		minCols: 1,
		maxCols: 60,
		height: '2000px',
		width: '2000px',
		minRows: 1,
		maxRows: 100,
		maxItemCols: 60,
		minItemCols: 1,
		maxItemRows: 100,
		minItemRows: 1,
		maxItemArea: 5000,
		minItemArea: 1,
		defaultItemCols: 1,
		defaultItemRows: 1,
		fixedColWidth: 60,
		fixedRowHeight: 60,
		setGridSize: false, //leave as true so that this displays properly on mac. Sets the grid height to the size of its child components.
		keepFixedHeightInMobile: false,
		keepFixedWidthInMobile: false,
		scrollSensitivity: 10,
		scrollSpeed: 20,
		enableEmptyCellClick: false,
		enableEmptyCellContextMenu: false,
		enableEmptyCellDrop: false,
		enableEmptyCellDrag: false,
		enableOccupiedCellDrop: false,
		emptyCellDragMaxCols: 50,
		emptyCellDragMaxRows: 50,
		ignoreMarginInRow: false,
		draggable: {
			enabled: false,
		},
		resizable: {
			enabled: false,
		},
		swap: false,
		pushItems: true,
		disablePushOnDrag: false,
		disablePushOnResize: false,
		pushDirections: {
			north: true,
			east: false,
			south: true,
			west: false,
		},
		pushResizeItems: false,
		displayGrid: DisplayGrid.Always,
		disableWindowResize: false,
		disableWarnings: false,
		scrollToNewItems: false,
	};
	DialogRef: any;
	constructor(private dashboardService: DashboardService, public dialog: MatDialog, public dataService: DataService,) { }

	ngOnInit(): void {
		if (this.widgets && this.widgets[0].CreatorUserId == Global.User.currentUser.Id) {
			this.sameUser = true;
			this.options.itemChangeCallback = this.itemChange.bind(this);//callback to call for each item when is changes x, y, rows, cols
			this.options.itemResizeCallback = this.itemResize.bind(this); //callback to call for each item when width/height changes
			this.options.draggable = {
				enabled: true,
			};
			this.options.resizable = {
				enabled: true,
			};
		}

		for (const widget of this.widgets) {
			if (widget.AngularDirectiveName == 'tagGrid') {
				let filter = this.dataService.cache.canvasWidgetTemplateTagGridFilters.filter(
					(t: any) => {
						return t.CanvasWidgetTemplateId == widget.Id;
					})
				let regexDesc = '';
				filter.forEach((f) => {
					if (f.FilterType == 'Regex')
						regexDesc = regexDesc + ' - ' + f.FieldValue;
				});
				if (regexDesc != '') {
					let index = this.widgets.indexOf(widget)
					this.widgets[index].regEx = regexDesc;
				}
			}
			this.lazyLoadCanvasWidget(this.widgets.indexOf(widget));
		}
	}


	async lazyLoadCanvasWidget(index) {
		if (this.widgets[index].AngularDirectiveName == 'gaugeDataDisplay') {
			let gaugeDataDisplayJBTStandardObservation = this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
				(jbt: any) => {
					return jbt.CanvasWidgetTemplateId == this.widgets[index].Id;
				})
			if (gaugeDataDisplayJBTStandardObservation.length == 1) {
				var JBTStandardObservationForUser = this.dataService.cache.jbtStandardObservations.filter(
					(so: any) => {
						return so.Id == gaugeDataDisplayJBTStandardObservation[0].JBTStandardObservationId;
					}
				);
				this.widgets[index].Name = gaugeDataDisplayJBTStandardObservation[0].JBTStandardObservationId + ' - ' + JBTStandardObservationForUser[0].Name;
			}

		}


		await this.dashboardService.mapWidget(this.widgets[index]);

		setTimeout(() => {
			this.canvasWidgetsLazyLoadingSelector.map((vcr: any, i: number) => {
				let cw = this.widgets[index];

				if (vcr._hostLView[30] !== null) {
					let parentElementName =
						vcr._hostLView[31] + vcr._hostLView[30];

					if (
						parentElementName.includes(
							cw.AngularDirectiveName + cw.Id
						)
					) {
						vcr.clear();
						const { instance }: any = vcr.createComponent(
							cw.component[cw.ProperComponentName]
						);
						switch (cw.AngularDirectiveName) {
							case 'gseActiveAlarms':
								instance.widgetObject = cw;
								instance.dashboardTimeZoneChanged =
									this.dashboardTimeZoneChanged;
								break;
							case 'tagGraph':
							case 'tagGraphSimple':
								instance.widgetObject = cw;
								instance.dashboardTimeZoneChanged =
									this.dashboardTimeZoneChanged;
								instance.dashboardTimeScopeChanged =
									this.dashboardTimeScopeChanged;
								break;
							case 'assetInformation':
							case 'assetState':
							case 'gaugeDataDisplay':
							case 'singleTagValueDisplay':
							case 'tagGrid':
								instance.widgetObject = cw;
								break;
						}
					}
				}
			});
		}, 1000);
	}

	openSettingsPopup(widget) {
		let widgetIndex = this.widgets.findIndex(w => w.Id == widget.Id);
		this.DialogRef = this.dialog.open(CanvasWidgetTemplatePopupSettingsComponent, {
			width: '50%',
			height: '70%',
			data: {
				CanvasWidgetTemplateId: widget.Id,
				CanvasWidgetTypeId: widget.CanvasWidgetTypeId,
				widget: widget,
			},
		});

		this.DialogRef.afterClosed().subscribe((result) => {

			setTimeout(() => {
				let tagSeries =
					this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
						(p: any) => {
							return (
								p.CanvasWidgetTemplateId ==
								parseInt(widget.Id)
							);
						}
					);
				if (tagSeries.length > 0)
					this.widgets[widgetIndex].canvasWidgetHasTagSeries = true;
				else
					this.widgets[widgetIndex].canvasWidgetHasTagSeries = false;

				this.lazyLoadCanvasWidget(widgetIndex);
			}, 1000);
		})
	}

	itemChange(item: any) {

		if (item.Id) {
			let statement = `Canvas_UpdateCanvasWidgetTemplateRecord @CanvasWidgetTemplateId =  ${item.Id} ,@CanvasTemplateId = ${item.CanvasTemplateId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.Canvas_UpdateCanvasWidgetTemplateRecord data = %O',
						data
					);
			});
		}
	}

	itemResize(item: GridsterItem) {

		if (item.Id) {
			let statement = `Canvas_UpdateCanvasWidgetTemplateRecord @CanvasWidgetTemplateId =  ${item.Id} ,@CanvasTemplateId = ${item.CanvasTemplateId} ,@Height = ${item.rows} ,@Width = ${item.cols} ,@Row = ${item.y} ,@Col = ${item.x}`;
			this.dataService.SQLActionAsPromise(statement).then((data: any) => {
				Global.User.DebugMode &&
					console.log(
						'API.Canvas_UpdateCanvasWidgetTemplateRecord data = %O',
						data
					);
			});
		}
	}

}
