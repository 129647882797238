import { Component, Inject, OnInit, Optional } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import _ from 'lodash';
import swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import { Observable, of } from "rxjs";
import { SelectorService } from '../../services/selector.service';
import { catchError, debounceTime, filter, map, startWith, switchMap, tap } from "rxjs/operators";
import { IGlobal } from '../../_models/global.model';

@Component({
	selector: 'lib-canvas-widget-template-popup-settings',
	templateUrl: './canvas-widget-template-popup-settings.component.html',
	styleUrls: ['./canvas-widget-template-popup-settings.component.scss'],
})
export class CanvasWidgetTemplatePopupSettingsComponent implements OnInit {
	tagSeriesExisting: Array<any>;
	configurableOptionsExisting: Array<any>;
	allJBTStandardObservations: Array<any>;
	public filteredList$: Observable<any[]>;
	showNoTagSeriesMsg: boolean;
	showNoConfigurableOptionsMsg: boolean;
	tagGraphSeriesSelected: boolean;
	widgetTemplateForm: FormGroup;
	configurableOptionsForm: FormGroup;
	canvasWidgetTemplateId: any;
	parentCanvasTemplateId: any;
	filterTypeSelected: any;
	filterColumnSelected: any;
	public unsavedChanges = [];
	private swalWithBootstrapButtons: any;
	public chartOptions = ['line', 'area', 'column'];
	public filterTypes = ['Kendo', 'Regex'];
	public filerColumns: { key: string, value: string }[] = [
		{ key: 'ShortTagName', value: 'Name' },
		{ key: 'Value', value: 'Value' },
		{ key: 'JavascriptDate', value: 'Date' }
	];
	public yAxises: any[] = [{ YAxisOrdinal: 0, label: 'Individual Chart' }];
	public lastSearchTerm: any;
	public JBTStandardObservationId: any;
	public HexColor: any;

	public existingGaugeColorRangesFromCache;
	public newScaleRanges: any = [];

	public showAddNewRecord: boolean = false;

	public newRangeFrom: any;
	public newRangeTo: any;
	public newRangeConditionColor: any;

	public unsavedChangesRange: any = [];

	private existingJBTStandardObservationIdsOnParent: any = [];
	public global: IGlobal = Global;

	public selectedGaugeTypeOption: any;
	public selectedGaugeTypeOptionChanged: any = false;

	constructor(
		@Optional() @Inject(MAT_DIALOG_DATA) public dialogModalInputObject: any,
		private dataService: DataService,
		private utilityService: UtilityService,
		private selectorService: SelectorService,
		public dialogRef: MatDialogRef<CanvasWidgetTemplatePopupSettingsComponent>
	) {
		this.swalWithBootstrapButtons = swal.mixin({
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-success',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		console.log(this.dialogRef);
		console.log(this.dialogModalInputObject);

		this.widgetTemplateForm = new FormGroup({
			JBTStandardObservationId: new FormControl(''),
			MaxValue: new FormControl(''),
		});

		this.configurableOptionsForm = new FormGroup({
			Operator: new FormControl(''),
			FieldValue: new FormControl(''),
			ColumnField: new FormControl(''),
		});
		this.initialize();
		this.JBTStandardObservationId = "JBTStandardObservationId";
		(this.filteredList$ = this.widgetTemplateForm.controls[this.JBTStandardObservationId].valueChanges.pipe(
			debounceTime(300),
			startWith(""),
			tap((value) => {
				this.lastSearchTerm = value;
				//this.showAddForm = !this.itemSelected && typeof this.lastSearchTerm !== "object" && this.lastSearchTerm.length > 2 && this.currentSet.selector.allowAdd;
			}),
			switchMap((name: any) => {
				if (typeof name === "string") {
					return this.search(this.lastSearchTerm);
				} else {

				}
			}),
			catchError((_) => {
				return of(null);
			})
		)),
			(err: Error) => Global.User.DebugMode && console.log(`generic-selector: ${err}`);
	}

	public displayFn(jbtStandardObservation: any): string {
		return jbtStandardObservation && jbtStandardObservation.SelectorName;
	}

	public saveSelectedValue(event: Event) {
		console.log("saveSelectedEvent: %O", event);
	}
	search(value: string): Observable<any> {
		return this.getList(value).pipe(
			// map the data.body as our return object
			map((returnedData) => {
				Global.User.DebugMode && console.log("returnedData from getList = %O", returnedData);
				return returnedData;
			}),
			// catch errors
			catchError((_) => {
				return of(null);
			})
		);
	}

	getList(searchText?: any): Observable<any> {
		var sql = "API.RDN_SelectorLookup_JBTStandardObservation" + " @SearchText=" + (searchText != null ? "'" + searchText + "'" : "null");


		if (searchText.Id != undefined) {
			const returnValue = new Observable((observer: any) => {
				return observer.next(searchText);
			});
			return returnValue;
		} else {
			Global.User.DebugMode && console.log("sql = " + sql);
			return this.selectorService.SQLActionAsObservable(sql);
		}
	}

	public changedJBTStandardObservationId(event) {
		console.log(event);
	}

	GetListOfStandardObservationsUsedAlready() {
		let canvasWidgetTemplate = this.dataService.cache.canvasWidgetTemplates.filter(
			(t: any) => {
				return t.Id == this.canvasWidgetTemplateId;
			})

		if(canvasWidgetTemplate.length > 0) {
			this.parentCanvasTemplateId = canvasWidgetTemplate[0].CanvasTemplateId;

			// all widgets on the parent template
			let allParentCanvasWidgetTemplates = this.dataService.cache.canvasWidgetTemplates.filter(
				(t: any) => {
					return t.CanvasTemplateId ==this.parentCanvasTemplateId;
				})

			allParentCanvasWidgetTemplates.forEach(t => {
				//CanvasWidgetTemplateJBTStandardObservation
				let canvasWidgetTemplateJBTStandardObservations = this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
					(jbt: any) => {
						return jbt.CanvasWidgetTemplateId == t.Id;
					})
				canvasWidgetTemplateJBTStandardObservations.forEach(jbt => {
					this.existingJBTStandardObservationIdsOnParent.push(jbt.JBTStandardObservationId)
				});
			});
		}
	}


	initialize() {
		this.HexColor = "#6cdd46";
		this.tagGraphSeriesSelected = false;
		this.showNoTagSeriesMsg = false;
		this.showNoConfigurableOptionsMsg = false;
		let canvasWidgetTemplateId = this.dialogModalInputObject?.CanvasWidgetTemplateId;
		this.canvasWidgetTemplateId = parseInt(canvasWidgetTemplateId);

		this.GetListOfStandardObservationsUsedAlready();

		let canvasWidgetTemplate = this.dataService.cache.canvasWidgetTemplatesObject[this.canvasWidgetTemplateId]
		this.selectedGaugeTypeOption = canvasWidgetTemplate?.CanvasWidgetTemplateGaugeTypeId;

		this.configurableOptionsExisting = this.dataService.cache.canvasWidgetTemplateTagGridFilters.filter(
			(p: any) => {
				return p.CanvasWidgetTemplateId == this.canvasWidgetTemplateId;
			})
		if (this.configurableOptionsExisting.length == 0)
			this.showNoConfigurableOptionsMsg = true;

		this.tagSeriesExisting = this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
			(p: any) => {
				return p.CanvasWidgetTemplateId == this.canvasWidgetTemplateId;
			})
		if (this.tagSeriesExisting.length == 0)
			this.showNoTagSeriesMsg = true;
		else {
			let obj;
			let yAxisesAlreadyDeclared = [];
			for (const series of this.tagSeriesExisting) {
				obj = this.dataService.cache.jbtStandardObservationsObject[series.JBTStandardObservationId];
				series.JBTStandardObservationName = obj.Name;
				yAxisesAlreadyDeclared.push(series.YAxisOrdinal);
			}
			yAxisesAlreadyDeclared = _.uniq(yAxisesAlreadyDeclared);
			yAxisesAlreadyDeclared = _.sortBy(yAxisesAlreadyDeclared);
			yAxisesAlreadyDeclared.forEach((yAxis) => {
				if (yAxis !== 0 && yAxis !== null) {
					this.yAxises.push({ YAxisOrdinal: yAxis, label: yAxis });
				}
			});

			// find the gauge range colors
			this.existingGaugeColorRangesFromCache = this.dataService.cache.canvasWidgetTemplateJBTStandardObservationGaugeColorRanges.filter(
				(r: any) => {
					return r.CanvasWidgetTemplateJBTStandardObservationId == this.tagSeriesExisting[0].Id;
				})
		}

		let assetTypeIds = [
			86292, 86293, 86294, 86295, 86296, 86297, 86298, 86299, 86300,
		];
		this.allJBTStandardObservations = this.dataService.cache.jbtStandardObservations.toArray();
		this.allJBTStandardObservations = _.orderBy(this.allJBTStandardObservations, 'Name', 'asc');
	}

	submitForm(widgetTemplateForm) {
		console.log(widgetTemplateForm);
		let obj = {
			canvasWidgetTemplateId: this.canvasWidgetTemplateId,
			JBTStandardObservationId: widgetTemplateForm.value.JBTStandardObservationId ? parseInt(widgetTemplateForm.value.JBTStandardObservationId.Id) : null,
		}
		if (obj?.JBTStandardObservationId != null && obj?.canvasWidgetTemplateId != null) {
			let statement = "API.Canvas_AddCanvasWidgetTemplateJBTStandardObservationRecord "
				+ "@CanvasWidgetTemplateId = " +
				obj.canvasWidgetTemplateId +
				", @JBTStandardObservationId = " +
				obj.JBTStandardObservationId;
			if (widgetTemplateForm.value.MaxValue != '') {
				statement = statement + ', @MaxValue = ' + parseFloat(widgetTemplateForm.value.MaxValue);
			}
			if(this.HexColor != "") {
				statement = statement + ", @HexColor = '" + this.HexColor + "'";
			}

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "'" + widgetTemplateForm.value.JBTStandardObservationId.SelectorName + "' has been added.",
					});
					this.closeDialogWindow();
				});

		}
		else {
			this.utilityService.showToastMessageShared({
				type: 'info',
				message: "Please enter all the required fields"
			});
		}
	}

	submitConfigurableOptionsForm(optionsForm) {
		console.log(optionsForm);
		let obj = {
			canvasWidgetTemplateId: this.canvasWidgetTemplateId,
			Operator: optionsForm.value.Operator,
			FieldValue: optionsForm.value.FieldValue,
			ColumnField: this.filterColumnSelected,
		}
		if (obj?.Operator != '' && obj?.FieldValue != '' && obj?.ColumnField != '' && obj?.canvasWidgetTemplateId != null) {

			var name = obj.Operator.split("'").join("''");
			if (obj.Operator.includes('^')) {
				name = name.replace("^", "CHAR(94)");
			}
			if (obj.Operator.includes('~')) {
				name = name.replace("~", "CHAR(126)");
			}

			let statement = "API.Canvas_AddCanvasWidgetTemplateTagGridFilterRecord "
				+ "@CanvasWidgetTemplateId = " +
				obj.canvasWidgetTemplateId +
				", @Operator = '" + name + "'" +
				", @FieldValue = '" + obj.FieldValue + "'" +
				", @ColumnField = '" + this.filterColumnSelected + "'" +
				", @FilterType = '" + this.filterTypeSelected + "'";

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "Operator: " + optionsForm.value.Operator + " Value: " + optionsForm.value.FieldValue + " Field: " + optionsForm.value.ColumnField + " option has been added.",
					});
					this.closeDialogWindow();
				});

		}
		else {
			this.utilityService.showToastMessageShared({
				type: 'info',
				message: "Please enter all the required fields"
			});
		}
	}

	drop(event: CdkDragDrop<string[]>) {
		let unchangedTagGraphWidgetTags = _.cloneDeep(
			this.tagSeriesExisting
		);
		moveItemInArray(
			this.tagSeriesExisting,
			event.previousIndex,
			event.currentIndex
		);
		this.tagSeriesExisting.forEach(
			(tagInstance, index) => {
				tagInstance.Ordinal = index + 1;
			}
		);
		console.log(this.tagSeriesExisting);
		if (
			!_.isEqual(
				unchangedTagGraphWidgetTags,
				this.tagSeriesExisting
			)
		) {
			unchangedTagGraphWidgetTags.forEach((unchangedTag) => {
				this.tagSeriesExisting.forEach(
					(potentiallyChangedTag) => {
						if (
							unchangedTag.Id === potentiallyChangedTag.Id &&
							unchangedTag.Ordinal !==
							potentiallyChangedTag.Ordinal
						) {
							//means ordinal was changed
							let ordinalChangesIndex =
								this.unsavedChanges.findIndex(
									(tagChange) =>
										tagChange.Id ===
										potentiallyChangedTag.Id
								);
							if (ordinalChangesIndex !== -1) {
								this.unsavedChanges[
									ordinalChangesIndex
								].Ordinal = potentiallyChangedTag.Ordinal;
							} else {
								this.unsavedChanges.push({
									Id: potentiallyChangedTag.Id,
									Ordinal: potentiallyChangedTag.Ordinal,
								});
							}
						}
					}
				);
			});
		}
	}

	addYAxis() {
		if (this.yAxises.length === 1) {
			this.yAxises.push({ YAxisOrdinal: 1, label: 1 });
		} else {
			//we need to compute the highest yaxis ordinal from the list, then create a new object in that list with 1 higher ordinal number
			let highestYAxisOrdinal = 0;
			this.yAxises.forEach((yAxis) => {
				if (yAxis.YAxisOrdinal !== null) {
					highestYAxisOrdinal = parseInt(yAxis.YAxisOrdinal) + 1;
				}
			});
			this.yAxises.push({
				YAxisOrdinal: highestYAxisOrdinal,
				label: highestYAxisOrdinal,
			});
		}
	}

	yAxisChanged(event, tag) {
		console.log(event);
		console.log(tag);
		let yAxisChange = {
			Id: tag.Id,
			YAxisOrdinal: event.value,
		};
		//discover if change has already been made before save
		let yAxisChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === yAxisChange.Id
		);
		if (yAxisChanges !== -1) {
			this.unsavedChanges[yAxisChanges].YAxisOrdinal = event.value;
		} else {
			this.unsavedChanges.push(yAxisChange);
		}
	}

	colorChanged(event, tag) {
		console.log(event);
		console.log(tag);

		this.dialogModalInputObject.widget.HexColor = event;

		let colorChange = {
			Id: tag.Id,
			HexColor: event,
		};
		//discover if change has already been made before save
		let colorChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === colorChange.Id
		);
		if (colorChanges !== -1) {
			this.unsavedChanges[colorChanges].HexColor = event;
		} else {
			this.unsavedChanges.push(colorChange);
		}
	}

	maxValueChanged(event, tag) {
		console.log(event);
		console.log(tag);
		tag.MaxValue = parseFloat(tag.MaxValue);
		this.dialogModalInputObject.widget.MaxValue = tag.MaxValue;


		let maxValueChange = {
			Id: tag.Id,
			MaxValue: tag.MaxValue,
		};
		//discover if change has already been made before save
		let maxValueChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === maxValueChange.Id
		);
		if (maxValueChanges !== -1) {
			this.unsavedChanges[maxValueChanges].MaxValue = event;
		} else {
			this.unsavedChanges.push(maxValueChange);
		}
		console.log(this.unsavedChanges);
	}

	operatorChanged(event, option) {
		this.dialogModalInputObject.widget.Operator = option.Operator;


		let operatorChange = {
			Id: option.Id,
			Operator: option.Operator,
		};
		//discover if change has already been made before save
		let operatorChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === operatorChange.Id
		);
		if (operatorChanges !== -1) {
			this.unsavedChanges[operatorChanges].MaxValue = event;
		} else {
			this.unsavedChanges.push(operatorChange);
		}
		console.log(this.unsavedChanges);
	}

	fieldValueChanged(event, option) {
		this.dialogModalInputObject.widget.FieldValue = option.FieldValue;


		let fieldValueChange = {
			Id: option.Id,
			Operator: option.FieldValue,
		};
		//discover if change has already been made before save
		let fieldValueChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === fieldValueChange.Id
		);
		if (fieldValueChanges !== -1) {
			this.unsavedChanges[fieldValueChanges].MaxValue = event;
		} else {
			this.unsavedChanges.push(fieldValueChange);
		}
		console.log(this.unsavedChanges);
	}

	columnFieldChanged(event, option) {
		this.dialogModalInputObject.widget.ColumnField = option.ColumnField;


		let columnFieldChange = {
			Id: option.Id,
			Operator: option.ColumnField,
		};
		//discover if change has already been made before save
		let columnFieldChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === columnFieldChange.Id
		);
		if (columnFieldChanges !== -1) {
			this.unsavedChanges[columnFieldChanges].MaxValue = event;
		} else {
			this.unsavedChanges.push(columnFieldChange);
		}
		console.log(this.unsavedChanges);
	}

	applyGaugeTypeSelection(event) {
		console.log(this.selectedGaugeTypeOption);
		console.log("applyGaugeTypeSelection");

		let canvasWidgetTemplate = this.dataService.cache.canvasWidgetTemplatesObject[this.canvasWidgetTemplateId];
		canvasWidgetTemplate.CanvasWidgetTemplateGaugeTypeId = parseInt(this.selectedGaugeTypeOption)

		this.selectedGaugeTypeOptionChanged = true;
	}

	seriesChartTypeChange(event, tag) {
		console.log(event);
		let seriesTypeChange = {
			Id: tag.Id,
			SeriesType: event.value,
		};
		let seriesTypeChanges = this.unsavedChanges.findIndex(
			(tagChange) => tagChange.Id === seriesTypeChange.Id
		);
		if (seriesTypeChanges !== -1) {
			this.unsavedChanges[seriesTypeChanges].SeriesType = event.value;
		} else {
			this.unsavedChanges.push(seriesTypeChange);
		}
	}

	selectedFilterType(event) {
		this.filterTypeSelected = event.value;
	}

	selectedFilterColumn(event) {
		this.filterColumnSelected = event.value;
	}

	chartTypeChange(event) {

		let sqlStatement =
			'API.Canvas_UpdateCanvasWidgetTemplateJBTStandardObservationRecordForChartType @CanvasWidgetTemplateId = ' +
			this.canvasWidgetTemplateId;

		if (!_.isNil(event.value)) {
			sqlStatement =
				sqlStatement + ", @SeriesType = '" + event.value + "'";
		}
		this.dataService.SQLActionAsPromise(sqlStatement).then((data) => {
			console.log(data);
		});

	}

	onDelete(tag: any) {

		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				html: 'This will remove ID <strong>' + tag.JBTStandardObservationId + ' - ' + tag.JBTStandardObservationName +
					'</strong> from the Tag Graph: ',
				showCancelButton: true,
				confirmButtonText: 'Delete Tag',
				cancelButtonText: 'Cancel',
				reverseButtons: false,
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					let statement =
						'API.Canvas_DeleteFromCanvasWidgetTemplateJBTStandardObservation @CanvasWidgetTemplateId = ' +
						this.canvasWidgetTemplateId +
						', @Id = ' +
						tag.Id;
					this.dataService
						.SQLActionAsPromise(statement)
						.then((data) => {
							console.log(data);
							let message = tag.JBTStandardObservationId + " - " + tag.JBTStandardObservationName + " : Tag series has been deleted.";
							this.utilityService.showToastMessageShared({
								type: 'success',
								message: message,
							});
							if (this.dialogModalInputObject.CanvasWidgetTypeId == 29 || this.dialogModalInputObject.CanvasWidgetTypeId == 30)
								this.showNoTagSeriesMsg = true;
							else
								this.closeDialogWindow();
						});
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "'" + tag.JBTStandardObservationId + "' has NOT been deleted.",
					});

				}
			});
	}

	onDeleteOption(option: any) {

		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				html: 'This will remove  <strong>' + 'Operator: ' + option.Operator + ' Value: ' + option.FieldValue + ' Field: ' + option.ColumnField +
					'</strong> from the Configurable Options: ',
				showCancelButton: true,
				confirmButtonText: 'Delete Option',
				cancelButtonText: 'Cancel',
				reverseButtons: false,
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					let statement =
						'API.Canvas_DeleteFromCanvasWidgetTemplateTagGridFilter @CanvasWidgetTemplateId = ' +
						this.canvasWidgetTemplateId +
						', @Id = ' +
						option.Id;
					this.dataService
						.SQLActionAsPromise(statement)
						.then((data) => {
							console.log(data);
							let message = "Operator: " + option.Operator + " Value: " + option.FieldValue + " Field: " + option.ColumnField + " option has been deleted.";
							this.utilityService.showToastMessageShared({
								type: 'success',
								message: message,
							});
							this.closeDialogWindow();
						});
				} else if (result.dismiss === swal.DismissReason.cancel) {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: "Operator: " + option.Operator + " Value: " + option.FieldValue + " Field: " + option.ColumnField + " option has not been deleted."
					});

				}
			});
	}

	saveChanges() {
		this.unsavedChanges.forEach((uc, index) => {
			let sqlStatement =
				'API.Canvas_UpdateCanvasWidgetTemplateJBTStandardObservationRecord @CanvasWidgetTemplateId = ' +
				this.canvasWidgetTemplateId +
				', @Id = ' +
				parseInt(uc.Id);
			if (!_.isNil(uc.HexColor)) {
				sqlStatement =
					sqlStatement + ", @HexColor = '" + uc.HexColor + "'";
			}
			if (!_.isNil(uc.SeriesType)) {
				sqlStatement =
					sqlStatement + ", @SeriesType = '" + uc.SeriesType + "'";
			}
			if (!_.isNil(uc.Ordinal)) {
				sqlStatement = sqlStatement + ', @Ordinal = ' + parseInt(uc.Ordinal);
			}
			if (!_.isNil(uc.YAxisOrdinal)) {
				sqlStatement =
					sqlStatement + ', @YAxisOrdinal = ' + parseInt(uc.YAxisOrdinal);
			}
			if (!_.isNil(uc.MaxValue)) {
				sqlStatement =
					sqlStatement + ', @MaxValue = ' + parseFloat(uc.MaxValue);
			}
			this.dataService.SQLActionAsPromise(sqlStatement).then((data) => {
				console.log(data);
				if (index === this.unsavedChanges.length - 1) {
					//finished with color changes, now save series type changes
					//this.localWidgetObject.refreshComponent = true;
					this.unsavedChanges = [];
					this.closeDialogWindow();

				}
			});
		});

		this.addGaugeColorRangeRecordstoDB()
		this.updateGaugeColorRangeRecordstoDB()
		this.updateGaugeSelectiontoDB();

		this.closeDialogWindow();
	}

	saveConfigurableOptionChanges() {
		this.unsavedChanges.forEach((uc, index) => {
			let sqlStatement =
				'API.Canvas_UpdateCanvasWidgetTemplateTagGridFilterRecord @CanvasWidgetTemplateId = ' +
				this.canvasWidgetTemplateId +
				', @Id = ' +
				parseInt(uc.Id);
			if (!_.isNil(uc.Operator)) {
				var name = uc.Operator.split("'").join("''");
				if (uc.Operator.includes('^')) {
					name = name.replace("^", "CHAR(94)");
				}
				if (uc.Operator.includes('~')) {
					name = name.replace("~", "CHAR(126)");
				}
				sqlStatement = sqlStatement + ", @Operator = '" + name + "'";
			}
			if (!_.isNil(uc.FieldValue)) {
				sqlStatement = sqlStatement + ", @FieldValue = '" + uc.FieldValue + "'";
			}
			if (!_.isNil(uc.ColumnField)) {
				sqlStatement = sqlStatement + ', @ColumnField = ' + uc.ColumnField;
			}

			this.dataService.SQLActionAsPromise(sqlStatement).then((data) => {
				console.log(data);
				if (index === this.unsavedChanges.length - 1) {
					//finished with color changes, now save series type changes
					//this.localWidgetObject.refreshComponent = true;
					this.unsavedChanges = [];
					this.closeDialogWindow();

				}
			});
		});
	}
	checkForTagGraphSeries(event) {
		this.tagGraphSeriesSelected = true;
	}

	saveChangesChartType() {
		this.closeWindow(false);
	}
	public closeDialogWindow(): void {

		// TODO check for unsaved changes and ask user are they sure they want to exit?

		this.closeWindow(false);
	}

	public closeWindow(modalInfo): void {
		if (modalInfo !== false) {
			this.dialogRef.close(modalInfo);
		} else {
			setTimeout(() => {
				this.tagSeriesExisting = this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
					(p: any) => {
						return p.CanvasWidgetTemplateId == this.canvasWidgetTemplateId;
					})
				this.configurableOptionsExisting = this.dataService.cache.canvasWidgetTemplateTagGridFilters.filter(
					(p: any) => {
						return p.CanvasWidgetTemplateId == this.canvasWidgetTemplateId;
					})
				if (this.dialogModalInputObject.CanvasWidgetTypeId == 32)
					this.dialogRef.close(this.configurableOptionsExisting);
				else
					this.dialogRef.close(this.tagSeriesExisting);
			}, 1000);

		}
	}


	public colorChangedStandardObservation(event) {
		this.HexColor = event;

		// let colorChange = {
		// 	Id: record.Id,
		// 	HexColor: event,
		// };
		// //discover if change has already been made before save
		// let colorChanges = this.unsavedChanges.findIndex(
		// 	(change) => change.Id === colorChange.Id
		// );
		// if (colorChanges !== -1) {
		// 	this.unsavedChanges[colorChanges].HexColor = event;
		// } else {
		// 	this.unsavedChanges.push(colorChange);
		// }
	}

	valueChanged(event, record) {
		console.log(event);
		console.log(record);
		let maxValueChange = {
			Id: record.Id,
			Value: event.target.value,
		};
		//discover if change has already been made before save
		let maxValueChanges = this.unsavedChanges.findIndex(
			(change) => change.Id === maxValueChange.Id
		);
		if (maxValueChanges !== -1) {
			this.unsavedChanges[maxValueChanges].Value = event.target.value;
		} else {
			this.unsavedChanges.push(maxValueChange);
		}
	}

	changedExistingRange(event, range) {
		console.log(event)
		console.log(range)

		//discover if change has already been made before save
		let rangeChanges = this.unsavedChangesRange.findIndex(
			(change) => change.Id === range.Id
		);
		if (rangeChanges == -1) {
			this.unsavedChangesRange.push(range);
		}
	}



	colorChangedRange(event,range) {
		console.log(event)
		console.log(range)
	}

	valueChangedRange(event, range) {
		console.log(event)
		console.log(range)
	}

	newValueChanged(event) {
		console.log(event)
	}

	addConditionRecord() {
		this.showAddNewRecord = true;
	}

	addGaugeColorRangeRecord(event: Event) {
		var newGaugeColorRange = {
			RangeMinValue:  this.newRangeFrom,
			RangeMaxValue: this.newRangeTo,
			HexColor: this.newRangeConditionColor,
		}
		this.newScaleRanges.push(newGaugeColorRange)
	}

	onDeleteRange(range) {
		const index = this.newScaleRanges.indexOf(range);
		if (index > -1) {
			this.newScaleRanges.splice(index, 1);
		}
	}

	onDeleteExistingRange(range) {

		let statement = "API.Canvas_DeleteFromCanvasWidgetTemplateJBTStandardObservationGaugeColorRange "
				+ " @Id = " +
				range.Id
				+ ", @CanvasWidgetTemplateJBTStandardObservationId = " +
				range.CanvasWidgetTemplateJBTStandardObservationId


		this.dataService
			.SQLActionAsPromise(statement)
			.then((data: any) => {
				console.log(data)
			});
	}


	addGaugeColorRangeRecordstoDB() {

		this.newScaleRanges.forEach(r => {

			let statement = "API.Canvas_AddCanvasWidgetTemplateJBTStandardObservationGaugeColorRangeRecord "
				+ "@CanvasWidgetTemplateJBTStandardObservationId = " +
				this.tagSeriesExisting[0].Id +
				", @RangeMaxValue = " +
				r.RangeMaxValue +
				", @RangeMinValue = " +
				r.RangeMinValue +
				", @HexColor = " +
				r.HexColor;

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					console.log(data)
				});
		});


	}


	updateGaugeColorRangeRecordstoDB() {

		this.unsavedChangesRange.forEach(r => {

			let statement = "API.Canvas_UpdateCanvasWidgetTemplateJBTStandardObservationGaugeColorRangeRecord "  +
				" @Id = " +
				r.Id +
				", @CanvasWidgetTemplateJBTStandardObservationId = " +
				r.CanvasWidgetTemplateJBTStandardObservationId +
				", @RangeMaxValue = " +
				r.RangeMaxValue +
				", @RangeMinValue = " +
				r.RangeMinValue +
				", @HexColor = " +
				r.HexColor;

			this.dataService
				.SQLActionAsPromise(statement)
				.then((data: any) => {
					console.log(data)
				});
		});


	}

	updateGaugeSelectiontoDB() {

		let statement = "API.Canvas_UpdateCanvasWidgetTemplateRecord "  +
			" @CanvasWidgetTemplateId = " + 			this.canvasWidgetTemplateId +
			", @CanvasTemplateId = " +					this.parentCanvasTemplateId +
			", @CanvasWidgetTemplateGaugeTypeId = " + 	this.selectedGaugeTypeOption;

		this.dataService
			.SQLActionAsPromise(statement)
			.then((data: any) => {
				Global.User.DebugMode && console.log("UpdateCanvasWidgetTemplateRecord", data)
			});

	}

}
