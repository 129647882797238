import { Component, OnInit, Input, Optional, ViewChild, ChangeDetectorRef, EventEmitter, ElementRef, NgZone } from "@angular/core";

import _ from "lodash";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { process } from "@progress/kendo-data-query";
import { PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { GridSettings } from "../../_models/grid-settings.interface";

import { ToastrService } from "ngx-toastr";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ISystem } from "../../_models/system.model";
import { IAsset } from "../../_models/asset.model";
import { ISite } from "../../_models/site.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITag } from "../../_models/tag.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	selector: "lib-centralunits-summary",
	templateUrl: "./centralunits-summary.component.html",
	styleUrls: ["./centralunits-summary.component.scss"]
})
export class CentralunitsSummaryComponent implements OnInit {
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	@ViewChild("alarmDataGrid") alarmDataGrid: KendoGridParentComponent;
	@Input() private cuSummaryCreatedEvent: EventEmitter<any>;
	@ViewChild("tagDataRowForCalculation") tagDataRowForCalculation: ElementRef;
	@ViewChild("eventDataRowForCalculation")
	eventDataRowForCalculation: ElementRef;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

    tagDataRowHeight: any = 43;
    eventDataRowHeight: any = 26;
    cuSummaryCreatedSubscription: Subscription;

	assetTagIds = [];
	assetObj: any;
	fullDataCache$: any;
	theme: string;
	gaugeTextColor: string;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	fromDashboard: boolean;
	defaultRightWidth = 40;
	defaultLeftWidth = 60;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	selectedMatTabIndex = 0;
	tagDataPageSize: number;
	eventDataPageSize: number;
	fullDataCacheSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	widgetIsInViewSubscription: Subscription;
	isWidgetInView: boolean = false;
	parentContainerSize: any;
	private componentName: string = "centralUnits-summary";
	private displaySignalRUpdates: boolean;
	countOfAlarms: any;
	alertStatus: any;

	public navigationOpened: boolean = false;
	public sitesWithCameras: Array<any> = [];
	public systemsWithCameras: Array<ISystem> = [];
	public assetsWithCameras: Array<IAsset> = [];
	public accordionMultiOpenedValue: boolean = false;
	public currentAccordionItem: any;
	public options: any = [];
	public selected: any;
	public selectedAssetId: number;
	public selectedSystemId: number;
	public reloadingData: boolean = false;

	public kendoGrids = {
		tags: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "Name",
						title: "Full Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "Favorite",
						title: "Favorite",
						filterable: true,
						filter: "boolean",
						_width: 150
					}
				]
			} as GridSettings
		},
		activeAlerts: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					}
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 50,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "Name",
						title: "Full Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "ShortTagName",
						title: "Simple Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						_width: 100,
						minResizableWidth: 125
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "ValueWhenActive",
						title: "Value When Active",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		}
	};

	public widgetTabs = [
		{ textLabel: "Data", rendered: true },
		{ textLabel: "Alerts", rendered: false }
	];

	dashboardTimeZoneChangedSubscription: Subscription;
	timeZoneType: any;
	alarmTagIds: any;
	public guid: string;

	constructor(public dataService: DataService, 
				private signalRCore: SignalRCoreService, 
				private ref: ChangeDetectorRef, 
				private kendoSettingsService: KendoSettingsService, 
				private dashboardService: DashboardService, 
				private toastr: ToastrService,
				private zone: NgZone
			) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		//Need to evaluate if the component is a widget or a popup. If it is a widget, we need to make sure it is not in view until the subscription tells us it is. If not, we can assume it is a popup, and that it is in fact in view.
		if (this.fromDashboard === true) {
			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
				if (data.widgetId === this.widgetObject.WidgetId) {
					if (this.tagDataRowForCalculation !== undefined) {
						this.tagDataRowHeight = this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}

					if (this.eventDataRowForCalculation !== undefined) {
						this.eventDataRowHeight = this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}

					console.log(this.isWidgetInView);
					console.log(this.widgetObject.isWidgetInView);
					if (this.isWidgetInView === false && data.isInView === true) {
						//this.loadDataForSelectedTab();
					}
					this.isWidgetInView = data.isInView;
				}
			});
		} else {
			this.isWidgetInView = true;
		}

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					if (Global.Theme === "light") {
						this.theme = "light";
					} else if (Global.Theme === "dark") {
						this.theme = "dark";
					}
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			if (Global.Theme === "light") {
				this.theme = "light";
			} else if (Global.Theme === "dark") {
				this.theme = "dark";
			}
			this.initialize();
		}

		this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((theme: any) => {
			this.theme = theme;
			this.gaugeTextColor = theme === "dark" ? "#eceaea" : "#858585";
		});
		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				console.log(data);
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					console.log("Widget Time Zone Changed");
					this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);

					Object.values(this.kendoGrids).forEach((grid) => {
						// Adjust visible and hidden columns
						const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
						const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
						const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

						localDateCol.hidden = this.timeZoneType !== "User Time";
						siteDateCol.hidden = this.timeZoneType !== "Site Time";
						utcDateCol.hidden = this.timeZoneType !== "UTC Time";
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;
					});
				}
				//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
				this.initialize();
			});
		}
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		if (!_.isNil(this.widgetGroupSettings)) {
			//this.signalRCore.leaveParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
		}

		if (this.signalRTagUpdateSubscription) {
			this.signalRTagUpdateSubscription.unsubscribe();
		}
		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
		this.displaySignalRUpdates && console.log(this.componentName + ": PageSize = " + this.tagDataPageSize);
	}

	saveGridSettings() {
		if (this.fromDashboard) {
			let hiddenColsArray = [];
			let gridObjectsArray = [];
			if (this.tagDataGrid) {
				hiddenColsArray.push(this.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "tagDataGrid",
					gridObject: this.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.tags.gridSettings.state
				});
			}

			if (this.alarmDataGrid) {
				hiddenColsArray.push(this.alarmDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "alarmDataGrid",
					gridObject: this.alarmDataGrid.kendoGridParent,
					gridState: this.kendoGrids.activeAlerts.gridSettings.state
				});
			}

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(hiddenColsArray, this.widgetObject.WidgetId); // update this to ignore columns not inccluded in chooser
			this.widgetObject.WidgetKendoUIJson = this.kendoSettingsService.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId).then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
		}
	}

	mapGridSettings(gridSettings: GridSettings) {
		if (this.fromDashboard) {
			const state = gridSettings.state;
			Global.User.DebugMode && console.log(this.componentName + ": state = %O", state);
			let emptyArray: any = [];
			return {
				state,
				columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
				gridData: this.dataService.cache !== undefined ? process(this.kendoGrids.tags.data, state) : emptyArray
			};
		}
	}

	initialize() {
		Global.User.DebugMode && console.log(this.componentName + ": initialize invoked... widgetObject = %O", this.widgetObject);
		Global.User.DebugMode && console.log(this.componentName + ": dataObject = %O", this.dataObject);
		this.isDataLoading = true;

		if (this.fromDashboard && !this.widgetObject.WidgetGateSystemId) {
			this.isDataLoading = false;
			return;
		}
		this.selectedMatTabLabel = this.widgetTabs[0].textLabel;
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.fromDashboard ? this.widgetObject : this.dataObject);

		this.cacheJbtStandardObsLookup = this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetGateSystemId) {
				if (this.widgetObject.WidgetId !== undefined) {
					this.finishInitializingWidget();
				} else if (this.cuSummaryCreatedEvent) {
					this.cuSummaryCreatedSubscription = this.cuSummaryCreatedEvent.subscribe((data) => {
						if (data.WidgetId === this.widgetObject.WidgetId) {
							this.finishInitializingWidget();
						}
					});
				}
			}
		} else {
			this.assetObj = this.dataObject.assetObj;
			// Get the Global Theme and set the text colors for the gauges
			this.theme = Global.Theme;
			this.gaugeTextColor = this.theme === "dark" ? "#eceaea" : "#858585";
			this.finishInitializingWidget();
		}
		this.isDataLoading = false;
	}

	finishInitializingWidget() {
		var service = this;
		Global.User.DebugMode && console.log(this.componentName + ": finishInitializingWidget invoked...");

		this.assetObj = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId];

		this.selectedSystemId = this.widgetObject.WidgetAssetId;
		this.selectedAssetId = this.assetObj.Id;

		console.log("Getting tags....");
		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(service.assetObj.Id.toString()).subscribe((data) => {
			Global.User.DebugMode && console.log(service.componentName + ": dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O", data);
			service.formatTagDataForGrid(data);
			if (service.fromDashboard) {
				let gridColumnConfigs = [];
				// Reload saved Kendo Grid Settings
				if (service.widgetObject.WidgetKendoUIJson) {
					let jsonObjectParsed = service.kendoSettingsService.parseReturnedSettingsForDates(service.widgetObject.WidgetKendoUIJson);
					let tagDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "tagDataGrid");
					let alarmDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "alarmDataGrid");

					if (tagDataGridSettings) {
						let mergedTemplateAndSavedColumnsForData = service.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(service.kendoGrids.tags.gridSettings, tagDataGridSettings);
						service.kendoGrids.tags.gridSettings = service.mapGridSettings(mergedTemplateAndSavedColumnsForData);
						gridColumnConfigs.push(service.kendoGrids.tags.gridSettings.columnsConfig);
					}

					if (alarmDataGridSettings) {
						let mergedTemplateAndSavedColumnsForAlarms = service.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(service.kendoGrids.activeAlerts.gridSettings, alarmDataGridSettings);
						service.kendoGrids.activeAlerts.gridSettings = service.mapGridSettings(mergedTemplateAndSavedColumnsForAlarms);
						gridColumnConfigs.push(service.kendoGrids.activeAlerts.gridSettings.columnsConfig);
					}
					service.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(gridColumnConfigs, service.widgetObject.WidgetId);
				} else {
					gridColumnConfigs.push(service.kendoGrids.tags.gridSettings.columnsConfig);
					gridColumnConfigs.push(service.kendoGrids.activeAlerts.gridSettings.columnsConfig);
					service.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(gridColumnConfigs, service.widgetObject.WidgetId);
				}
				Object.values(service.kendoGrids).forEach((grid: any) => {
					// Adjust visible and hidden columns
					const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
					const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
					const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

					localDateCol.hidden = service.timeZoneType !== "User Time";
					siteDateCol.hidden = service.timeZoneType !== "Site Time";
					utcDateCol.hidden = service.timeZoneType !== "UTC Time";
					localDateCol.includeInChooser = !localDateCol.hidden;
					siteDateCol.includeInChooser = !siteDateCol.hidden;
					utcDateCol.includeInChooser = !utcDateCol.hidden;
				});

				// Get the Global Theme and set the text colors for the gauges
				service.theme = Global.Theme;
				service.gaugeTextColor = service.theme === "dark" ? "#eceaea" : "#858585";

				//service.loadDataForSelectedTab();
			}

			Object.values(service.kendoGrids).forEach((grid: any) => {
				// Adjust visible and hidden columns
				const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
				const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");

				const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

				localDateCol.hidden = service.timeZoneType !== "User Time";
				siteDateCol.hidden = service.timeZoneType !== "Site Time";
				utcDateCol.hidden = service.timeZoneType !== "UTC Time";
				localDateCol.includeInChooser = !localDateCol.hidden;
				siteDateCol.includeInChooser = !siteDateCol.hidden;
				utcDateCol.includeInChooser = !utcDateCol.hidden;
			});

			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].Tags);
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
		});
	}

	formatTagDataForGrid(tags: any) {
		Global.User.DebugMode && console.log(this.componentName + ": formatTagDataForGrid invoked...");

		this.assetTagIds = tags.map((t) => t.TagId);

		this.alarmTagIds = tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm" || t.Severity == "Warning").map((t) => t.TagId);

		this.countOfAlarms = this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags.length;
		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags);

		this.isDataLoading = false;
		this.getSignalRUpdates();
	}

	getSignalRUpdates() {
		let assetObjectInCache = this.dataService.cache.assetsObject[this.assetObj.Id];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTagGridData();
					this.updateAlarmGridData();
				});
			});

	}

	selectedMatTabLabel: string;
	matTabHasChanged(e) {
		console.log(e);
		this.selectedMatTabLabel = e.tab.textLabel;
		this.widgetTabs.find((tab) => tab.textLabel == this.selectedMatTabLabel).rendered = true;

		switch (this.selectedMatTabLabel) {
			case "Data":
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].Tags);
				}
				break;
			case "Alerts":
				if (this.alarmDataGrid) {
					this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags);
				}
				break;
		}
	}

	updateAlarmGridData() {
		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags);
	}

	alertsStatus(activeAlertsData: any) {
		//Critical
		if (activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 3).length > 0)
			return 3;
		//Alarm
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 1).length > 0
		)
			return 1;
		//Warning
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 2).length > 0
		)
			return 2;
	}

	updateTagGridData() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].Tags);
		}
	}
}


