import {
	Component,
	OnInit,
	Input,
	Optional,
	ViewChild,
	ChangeDetectorRef,
	EventEmitter,
	ElementRef,
	NgZone,
} from '@angular/core';


import _ from 'lodash';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { process } from '@progress/kendo-data-query';
import { PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { GridSettings } from '../../_models/grid-settings.interface';

import { ToastrService } from 'ngx-toastr';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DashboardService } from '../../services/dashboard.service';
import { Global } from '../../_constants/global.variables';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

@Component({
	selector: 'lib-gse-peak-reports-data',
	templateUrl: './gse-peak-reports-data.component.html',
	styleUrls: ['./gse-peak-reports-data.component.scss'],
})
export class GsePeakReportsDataComponent implements OnInit {
	@ViewChild('tagDataGrid') tagDataGrid: KendoGridParentComponent;
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@Input() private cuSummaryCreatedEvent: EventEmitter<any>;
	@ViewChild('tagDataRowForCalculation') tagDataRowForCalculation: ElementRef;
	@ViewChild('eventDataRowForCalculation')
	eventDataRowForCalculation: ElementRef;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	tagDataRowHeight: any = 43;
	eventDataRowHeight: any = 26;
	cuSummaryCreatedSubscription: Subscription;
	tagData: any = [];
	eventData: any = [];
	assetTagIds = [];
	assetObj: any;
	fullDataCache$: any;
	theme: string;
	gaugeTextColor: string;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	fromDashboard: boolean;
	defaultRightWidth = 40;
	defaultLeftWidth = 60;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	selectedMatTabIndex = 0;
	tagDataPageSize: number;
	eventDataPageSize: number;
	fullDataCacheSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	widgetIsInViewSubscription: Subscription;
	isWidgetInView: boolean = false;
	parentContainerSize: any;
	private componentName: string = 'gse-peak-reports-data';
	private displaySignalRUpdates: boolean;
	private peakReportStdIds = [
		19131, 19134, 19133, 19132, 19161, 19125, 55021, 54910, 55231, 55229,
		1968, 54118, 19127, 54911, 54285, 54288, 54286, 54287, 56302, 56303,
		56326, 56327, 56328, 56363, 56364, 56365, 3895, 4504, 54271, 19264,
		55259, 55192, 55185, 19141, 19229, 19230, 54180,
	];

	tagGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'chart',
				title: 'Chart',
				filterable: false,
				_width: 60,
				hidden: false,
				minResizableWidth: 40,
			},
			{
				field: 'ShortTagName',
				title: 'Name',
				filterable: true,
				_width: 150,
				minResizableWidth: 200,
			},
			{
				field: 'JavascriptDate',
				title: 'Date',
				filterable: false,
				_width: 150,
				minResizableWidth: 125,
				filter: 'date',
			},
			{
				field: 'SiteLocalJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				includeInChooser: false,
				_width: 100,
			},
			{
				field: 'UTCJavascriptDate',
				title: 'Date',
				filterable: true,
				filter: 'date',
				includeInChooser: false,
				_width: 100,
			},
			{
				field: 'Value',
				title: 'Value',
				filterable: true,
				_width: 150,
				minResizableWidth: 85,
			},
			{
				field: 'JBTStandardObservationId',
				title: 'JBTStandardObservationId',
				filterable: true,
				_width: 100,
			},
		],
	};

	dashboardTimeZoneChangedSubscription: Subscription;
	timeZoneType: any;
	public guid: string;

	constructor(
		public dataService: DataService,
		private signalRCore: SignalRCoreService,
		private ref: ChangeDetectorRef,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private toastr: ToastrService,
		private zone: NgZone
	) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		//Need to evaluate if the component is a widget or a popup. If it is a widget, we need to make sure it is not in view until the subscription tells us it is. If not, we can assume it is a popup, and that it is in fact in view.
		if (this.fromDashboard === true) {
			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription =
				this.dashboardService.widgetInViewChanged$.subscribe(
					(data: any) => {
						if (data.widgetId === this.widgetObject.WidgetId) {
							if (this.tagDataRowForCalculation !== undefined) {
								this.tagDataRowHeight =
									this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
							}

							if (this.eventDataRowForCalculation !== undefined) {
								this.eventDataRowHeight =
									this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
							}

							console.log(this.isWidgetInView);
							console.log(this.widgetObject.isWidgetInView);
							if (
								this.isWidgetInView === false &&
								data.isInView === true
							) {
								this.loadDataForSelectedTab();
							}
							this.isWidgetInView = data.isInView;
						}
					}
				);
		} else {
			this.isWidgetInView = true;
		}

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
			this.initialize();
		}

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
				this.gaugeTextColor = theme === 'dark' ? '#eceaea' : '#858585';
			});
		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription =
				this.dashboardTimeZoneChanged.subscribe((data) => {
					console.log(data);
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.timeZoneType =
							this.dashboardService.determineTimeZoneType(
								foundWidgetWithSameWidgetId
							);

						const localDateCol =
							this.tagGridSettings.columnsConfig.find(
								(col) => col.field == 'JavascriptDate'
							);
						const siteDateCol =
							this.tagGridSettings.columnsConfig.find(
								(col) => col.field == 'SiteLocalJavascriptDate'
							);
						const utcDateCol =
							this.tagGridSettings.columnsConfig.find(
								(col) => col.field == 'UTCJavascriptDate'
							);

						localDateCol.hidden = this.timeZoneType !== 'User Time';
						siteDateCol.hidden = this.timeZoneType !== 'Site Time';
						utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;
					}
					//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
					this.initialize();
				});
		}
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(
			((event.newRect.height - 120) / this.tagDataRowHeight) * 3
		);
		this.eventDataPageSize = Math.floor(
			((event.newRect.height - 120) / this.eventDataRowHeight) * 3
		);
		this.parentContainerSize = event.newRect.height;
		this.displaySignalRUpdates &&
			console.log(
				this.componentName + ': PageSize = ' + this.tagDataPageSize
			);
	}

	saveGridSettings() {
		if (this.fromDashboard) {
			let hiddenColsArray = [
				this.tagDataGrid.kendoGridParent.columnList.columns._results,
			];
			let gridObjectsArray = [
				{
					gridObject: this.tagDataGrid.kendoGridParent,
					gridState: this.tagGridSettings.state,
				},
			];

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				hiddenColsArray,
				this.widgetObject.WidgetId
			);
			this.kendoSettingsService
				.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	mapGridSettings(gridSettings: GridSettings) {
		if (this.fromDashboard) {
			const state = gridSettings.state;
			Global.User.DebugMode &&
				console.log(this.componentName + ': state = %O', state);
			let emptyArray: any = [];
			return {
				state,
				columnsConfig: gridSettings.columnsConfig.sort(
					(a, b) => a.orderIndex - b.orderIndex
				),
				gridData:
					this.dataService.cache !== undefined
						? process(this.tagData, state)
						: emptyArray,
			};
		}
	}

	initialize() {
		Global.User.DebugMode &&
			console.log(this.componentName + ': initialize invoked...');

		if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
			// filter just the assets for this Fleet
			var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
			console.log("VocationalSettings ", VocationalSettings)

			let fleetId = VocationalSettings.id;

			this.widgetObject.WidgetAssetId = VocationalSettings.child.id;
			if (_.isNil(this.widgetObject.WidgetAssetId)) {
				this.isDataLoading = false;
				return;
			}
			this.widgetObject.WidgetAssetName = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId].Name;

			this.dataService.GetFleets().subscribe((data: any) => {
				if (data.length > 0) {
					let myFleet = data.find((fleet) => {
						return (fleet.FleetId === fleetId);
					});

					this.widgetObject.FleetName = myFleet?.FleetName;
				}
			});

		}

		if (this.fromDashboard && !this.widgetObject.WidgetAssetId) {
			this.isDataLoading = false;
			return;
		}

		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);

		this.cacheJbtStandardObsLookup =
			this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetAssetId) {
				if (this.widgetObject.WidgetId !== undefined) {
					this.finishInitializingWidget();
				} else if (this.cuSummaryCreatedEvent) {
					this.cuSummaryCreatedSubscription =
						this.cuSummaryCreatedEvent.subscribe((data) => {
							if (data.WidgetId === this.widgetObject.WidgetId) {
								this.finishInitializingWidget();
							}
						});
				}
			}
		} else {
			this.assetObj = this.dataObject.assetObj;
			// Get the Global Theme and set the text colors for the gauges
			this.theme = Global.Theme;
			this.gaugeTextColor = this.theme === 'dark' ? '#eceaea' : '#858585';
			this.finishInitializingWidget();
		}
	}

	finishInitializingWidget() {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': finishInitializingWidget invoked...'
			);

		if (!this.assetObj) {
			this.assetObj = this.dataService.cache.assets.find(
				(a) => a.Id == this.widgetObject.WidgetAssetId
			);
		}

		console.log('Getting tags....');
		var GSEPeakReportStandardObservations = '54285,54288,54286,54287,56303,57503,56326,56365,56327,56328,19141,57487,19212,57488';

		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				this.assetObj.Id.toString(),
				false,
				GSEPeakReportStandardObservations
			)
			.subscribe((data) => {
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							': dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
						data
					);
				if (data != null) this.formatTagDataForGrid(data);
				else this.isDataLoading = false;
			});

		if (this.fromDashboard) {
			// Reload saved Kendo Grid Settings
			if (this.widgetObject.WidgetKendoUIJson) {
				let jsonObjectParsed =
					this.kendoSettingsService.parseReturnedSettingsForDates(
						this.widgetObject.WidgetKendoUIJson
					);

				//the first item in the array is the gridSettings for the first tab of data for this widget.
				let mergedTemplateAndSavedColumnsForData =
					this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
						this.tagGridSettings,
						jsonObjectParsed[0]
					);
				this.tagGridSettings = this.mapGridSettings(
					mergedTemplateAndSavedColumnsForData
				);
			}

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				[this.tagGridSettings.columnsConfig],
				this.widgetObject.WidgetId
			);

			// Get the Global Theme and set the text colors for the gauges
			this.theme = Global.Theme;
			this.gaugeTextColor = this.theme === 'dark' ? '#eceaea' : '#858585';

			this.loadDataForSelectedTab();
		}

		const localDateCol = this.tagGridSettings.columnsConfig.find(
			(col) => col.field == 'JavascriptDate'
		);
		const siteDateCol = this.tagGridSettings.columnsConfig.find(
			(col) => col.field == 'SiteLocalJavascriptDate'
		);

		const utcDateCol = this.tagGridSettings.columnsConfig.find(
			(col) => col.field == 'UTCJavascriptDate'
		);

		localDateCol.hidden = this.timeZoneType !== 'User Time';
		siteDateCol.hidden = this.timeZoneType !== 'Site Time';
		utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
		localDateCol.includeInChooser = !localDateCol.hidden;
		siteDateCol.includeInChooser = !siteDateCol.hidden;
		utcDateCol.includeInChooser = !utcDateCol.hidden;
		if (this.dataObject !== undefined) {
			this.dataObject.isDisplayDataLive = true;
		} else if (this.widgetObject !== undefined) {
			this.widgetObject.isDisplayDataLive = true;
		}
	}

	loadDataForSelectedTab() {
		switch (this.selectedMatTabIndex) {
			case 0: // Tag Data
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].GSEPeakReportTags);
				}
				break;
		}
	}

	formatTagDataForGrid(tags: any) {
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': formatTagDataForGrid invoked...'
			);

		this.dataService.cache.assetsObject[this.assetObj.Id].GSEPeakReportTags = tags;
		this.assetTagIds = this.dataService.cache.assetsObject[this.assetObj.Id].GSEPeakReportTags.map((t) => t.TagId);

		this.isDataLoading = false;
		this.getSignalRUpdates();
	}

	getSignalRUpdates() {

		let assetObjectInCache = this.dataService.cache.assetsObject[this.assetObj.Id];

		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + "this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid,
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTagGridData();
				});
			});
	}

	matTabHasChanged(e) {
		this.selectedMatTabIndex = e.index;

		switch (this.selectedMatTabIndex) {
			case 0:
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].GSEPeakReportTags);
				}
				break;
		}
	}

	updateTagGridData() {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetObj.Id].GSEPeakReportTags);
		}
	}
}
