import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecursiveDataNavigatorComponent } from './recursive-data-navigator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '../dynamic-form/dynamic-form.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipeModule } from "../../_helpers/pipes/pipe.module";

@NgModule({
    declarations: [RecursiveDataNavigatorComponent],
    exports: [RecursiveDataNavigatorComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DynamicFormModule,
        DragDropModule,
        PipeModule
    ]
})
export class RecursiveDataNavigatorModule {}
