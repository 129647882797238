import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SignalRCoreService } from 'projects/shared-lib/src/lib/services/signalr-core.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { filter, skip } from 'rxjs/operators';
import { IGlobal } from '../../_models/global.model';
import { DataService } from '../../services/data.service';
import { ISystem } from '../../_models/system.model';
import { ISignalRMessage } from '../../_models/signalr-message';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import * as signalR from "@microsoft/signalr";
import { GridSettings } from '../../_models/grid-settings.interface';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { FormBuilder } from '@angular/forms';
import { ThemeService } from '@progress/kendo-angular-gauges';
import { IAsset } from '../../_models/asset.model';

@Component({
	selector: 'signalr-test',
	templateUrl: './signalr-test.component.html',
	styleUrls: ['./signalr-test.component.scss']
})
export class SignalRTestComponent implements OnInit, OnDestroy {

	@HostListener('window:resize', ['$event'])
	public hubConnection: signalR.HubConnection;

	public timeSent: number;
	public timeReceived: number;
	public totalTime: number;
	public numberOfTests: number = 50;
	public componentName: string = "signalR-test: ";
	public arrayOfObservations: Array<any>;

	public countOfObservations: number = 0;
	public signalRTestMessageGroups: Array<any> = [];
	public currentIndex: string;
	public global: IGlobal = Global;

	public screenHeight: number;
	public screenWidth: number;
	public dbTransmitterTableWidth: number = 770;
	public signalRContainerWidth: number = 840;
	public startTime: number = performance.now();
	public batchNumber: number = 0;
	public messageCount: number = 0;
	public systemSignalRGroups: string;
	public listOfTagNamePrefixes: string;
	public messagesPerSecond: number;
	public averageMessagesPerBatch: number;
	public messageArray: any;
	public topParsedMessage: any;
	public displayContent: any;
	public noConnectionContent: any;
	public latestStatus: any;
	public joinedGroups: boolean = false;
	tagIds: any;
	public formattedTagIds: any = [];
	public rawGridData: any = [];
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	public rawGridConnectedClients: any = [];
	@ViewChild("connectedClientsGrid") connectedClientsGrid: KendoGridParentComponent;
	public rawGridUnknownMessages: any = [];
	public mySignalRHubConnectionId: string; 

	@ViewChild("unknownMessagesGrid") unknownMessagesGrid: KendoGridParentComponent;

	checkoutForm = this.formBuilder.group({
		name: '',
		tagId: ''
	  });
	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: "TagId",
				title: "Tag Id",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "Name",
				title: "Name",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "Date",
				title: "Date",
				filter: "date",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "DateMS",
				title: "Date MS",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "Value",
				title: "Value",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "GroupName",
				title: "Group Name",
				filterable: true,
				_width: 200,
			},

		],
	};
	public gridSettingsForConnectedClients: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: "clientId",
				title: "Client Id",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "date",
				title: "Date",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},


		],
	};

	public gridSettingsForUnknownMessages: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: "TagId",
				title: "Tag Id",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			// {
			// 	field: "Name",
			// 	title: "Name",
			// 	filterable: true,
			// 	_width: 200,
			// 	minResizableWidth: 200,
			// },
			{
				field: "Date",
				title: "Date",
				filter: "date",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "DateMS",
				title: "Date MS",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "Value",
				title: "Value",
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: "GroupName",
				title: "Group Name",
				filterable: true,
				_width: 200,
			},

		],
	};
	tagIdsList: any = [];

	constructor(public formBuilder: FormBuilder, private signalRCore: SignalRCoreService, public dataService: DataService, public dialogRef: MatDialogRef<SignalRTestComponent>) {
		this.onResize();
	}

	ngOnInit() {
		Global.SignalR.testInProgress = true;
		this.signalRCore.signalRTestInProgress$.next(Global.SignalR.testInProgress); //-notifying the silent SignalR test in the SignalR service that the testing has started. --Kirk T. Sherer, September 27, 2022.

		if (Global.isMobile) {
			this.signalRContainerWidth = this.screenWidth > 390 ? this.screenWidth - 260 : this.screenWidth - 120;
			this.dbTransmitterTableWidth = this.signalRContainerWidth - 20;
		}

		this.signalRCore.broadcastMessages$.pipe(filter((msg: any) => msg.code == "loopback")).subscribe(
			(data: any) => {
				Global.User.DebugMode && console.log(this.componentName + "data = %O", data);
				this.timeReceived = parseFloat(performance.now().toFixed(3));
				this.totalTime = parseFloat((this.timeReceived - this.timeSent).toFixed(3));
				Global.User.DebugMode && console.log("Total loopback time: " + this.totalTime + " ms.");
				Global.SignalR.countOfObservations++;
			},
			(err: Error) => Global.User.DebugMode && console.log(`Error with signalR notification$: ${err}`)
		);
		this.startTest();
		let systemSignalRGroupsAsArray = this.dataService.cache.systems.select((g: ISystem) => { return "PS" + g.Id }).toArray();
		systemSignalRGroupsAsArray.push('unknown')
		this.systemSignalRGroups = systemSignalRGroupsAsArray.join(",");

		this.startHub();
	}

	ngOnDestroy() {
		Global.SignalR.testInProgress = false;
		this.signalRCore.signalRTestInProgress$.next(Global.SignalR.testInProgress); //-notifying the silent SignalR test in the SignalR service that the testing is complete. --Kirk T. Sherer, September 27, 2022.
		this.leaveGroups();
	}

	startHub() {
		if (!this.hubConnection) {
			this.buildHubConnection();
		}
		else {
			this.startConnection().then((data: any) => {
				console.log("signalr-test: SignalR hub connection started.");
			});
		}
	}



	buildHubConnection() {
		var service = this;
		var accessToken = Global.User.currentUser.ODataAccessToken;
		var batchNumber = 0;
		var receiveTimeMS: number = 0;
		var messageCount = 0;
		var messagesPerSecond = 0;

		const options: IHttpConnectionOptions = {
			accessTokenFactory: () => {
				return accessToken;
			},
			skipNegotiation: false, //-- can't skip negotiation unless you're using WebSockets. Error if you try without WebSockets: 'Negotiation can only be skipped when using the WebSocket transport directly'
			transport: signalR.HttpTransportType.WebSockets, //-- removed signalR.HttpTransportType.WebSockets since it's unreliable. Mark Thompson said the ServerSentEvents is much more reliable. --Kirk T. Sherer, October 10, 2022.
			withCredentials: false
		};
		service.hubConnection = new signalR.HubConnectionBuilder()
			.configureLogging(signalR.LogLevel.Information)
			.withUrl(Global.SignalR.CoreUrl, options)
			.withAutomaticReconnect([0, 0, 0, 0])  // .withAutomaticReconnect([0, 2000, 10000, 30000]) yields the default behavior. The 0, 0, 0, 0 means when we disconnect, immediately try to reconnect each of the four times (i.e. don't wait any milliseconds to reconnect).
			.build();							   // if it can't reconnect after four times, then it runs the onclose function and the user's browser is completely disconnected from SignalR. --Kirk T. Sherer, December 4, 2023.

		//-- a number of sites said to set up the .on function before starting the hub.
		// let connectedClients = []
		service.hubConnection.on("SignalRNotification", (code: any, clientObject: any, callerConnectionId: any, groupName: any) => {

			if(code === 'System.SignalR.ClientConnected'){
				service.rawGridConnectedClients.push({clientId: clientObject, date: new Date()})
				if (service.connectedClientsGrid) {
					service.connectedClientsGrid.gridDataSubject.next(service.rawGridConnectedClients);
				}
			}
			if(code === 'System.SignalR.ClientDisconnected'){
				service.rawGridConnectedClients = service.rawGridConnectedClients.filter(x => x.clientId !== clientObject)
				if (service.connectedClientsGrid) {
					service.connectedClientsGrid.gridDataSubject.next(service.rawGridConnectedClients);
				}
			}
			try {
				if (code == "o") {


					var messageArray: any = [];
					batchNumber++;
					receiveTimeMS = performance.now() - service.startTime;
					var batchMessage = clientObject.split('Conn =0,').last();
					if (batchMessage.includes("\r\n")) {
						messageArray = batchMessage.split("\r\n");
					}
					else {
						messageArray.push(batchMessage);
					}
					this.formattedTagIds.forEach(x => {
						if(clientObject.includes(x.toString())){
							console.log('found tag in raw message')
						}

					})
					messageCount += messageArray.count();
					messagesPerSecond = (messageCount / (service.startTime / 1000));
					var averageMessagesPerBatch = (parseFloat(messageCount.toString()) / parseFloat(batchNumber.toString())).toFixed(2);
					var messageList = messageArray.select(a => service.ParseMessageContent(a, service.batchNumber, groupName, clientObject, receiveTimeMS)).where(a => a != null).toArray();

					service.displayContent =
					{
						BatchNumber: batchNumber ?? 0,
						MessageCount: messageCount,
						MessagesPerSecond: +messagesPerSecond.toFixed(2),
						AverageMessagesPerBatch: +averageMessagesPerBatch,
						TopParsedMessage: messageList.first(),
					};

					if(service.formattedTagIds.length > 0 ){
						let filteredMessageList = [];
						messageList.forEach(message => {
							if(service.formattedTagIds.includes(parseInt(message.TagId))){
								let parsedObject = {
									TagId:message.TagId,
									Date: new Date(parseInt(message.DateMS)),
									DateMS: parseInt(message.DateMS),
									Value:message.Value,
									GroupName:message.GroupName,
									Name: service.tagIdsList.find(x => x.tagId == parseInt(message.TagId))?.name
								}
								service.rawGridData.push(parsedObject);
								console.log(parsedObject)
								filteredMessageList.push(parsedObject)
								if (service.tagDataGrid) {
									service.tagDataGrid.gridDataSubject.next(service.rawGridData);
								}
							}

						})
						let outofOrder = false;
						//determine if any of the messages are out of order historically
						filteredMessageList.forEach((message, index) => {
							if(index > 0){
								let previousMessage = filteredMessageList[index - 1];
								if(message.DateMS < previousMessage.DateMS){
									console.log('message out of order')
									outofOrder = true;
								}
							}
						})
						if(outofOrder){
							console.log('messages out of order')
							console.log(filteredMessageList);
						}


					}
					if(groupName === 'unknown'){
						messageList.forEach(x => {
							service.rawGridUnknownMessages.push({
								TagId: x.TagId,
								Date: new Date(parseInt(x.DateMS)),
								DateMS: parseInt(x.DateMS),
								Value: x.Value,
								GroupName: x.GroupName
							})
						})
						if (service.unknownMessagesGrid) {
							service.unknownMessagesGrid.gridDataSubject.next(service.rawGridUnknownMessages);
						}
					}

				}
				else {
					if (service.displayContent == null) {
						service.latestStatus = {
							Code: code,
							GroupName: groupName ?? "System",
							ClientObject: clientObject
						}
					}
					else
					{
						service.latestStatus = null;
					}
				}
			}
			catch (e) {
				console.log("signalr-test: Error with SignalRNotification: " + e.Message);
			}

		});

		this.startConnection().then((data: any) => {
			console.log("signalr-test: SignalR Hub connection started.");
		});
	}

	startConnection(): Promise<any> {
		var service = this;
		return new Promise((resolve, reject) => {
			if (service.hubConnection.state != signalR.HubConnectionState.Connected && service.hubConnection.state != signalR.HubConnectionState.Reconnecting && service.hubConnection.state != signalR.HubConnectionState.Connecting) {

				service.hubConnection
					.start()
					.then((data: any) => {

						service.mySignalRHubConnectionId = service.hubConnection.connectionId;

						//--Reconnecting--
						service.hubConnection.onreconnecting(() => {
							console.assert(this.hubConnection.state === signalR.HubConnectionState.Reconnecting);
							console.log("reconnecting to SignalR hub.");
						});

						//--Reconnected--
						service.hubConnection.onreconnected(connectionId => {
							console.assert(service.hubConnection.state === signalR.HubConnectionState.Connected);
							console.log("reconnected to SignalR hub.");
							service.mySignalRHubConnectionId = connectionId;
							service.joinGroups(); //-- rejoining list of joined groups before we were disconnected.
						});

						//--OnClose--
						service.hubConnection.onclose(() => {
							console.log("closed SignalR hub connection.");
						});
					})
					.catch((err: Error) => {
						console.error("signalr-test: Error while starting connection: %O", err);
						reject(err);
						setTimeout(() => {
							this.startHub();
						}, 1000);
					});
			}
			else {
				console.log("signalr-test: Connection state: " + service.hubConnection.state + ". hubConnection = %O", service.hubConnection);
				setInterval(() => {
					if (service.hubConnection.state == signalR.HubConnectionState.Connected) {
						if (!this.joinedGroups) {
							service.joinGroups(); //-- hub should be connected here. Go ahead and retry joining groups.
						}
					}
				}, 500, 5);
			}
		});
	}


	joinGroups() {
		var service = this;
		if (service.hubConnection) {
			try {
				// service.hubConnection.invoke("joinGroup", Global.User.currentUser.ODataAccessToken, service.systemSignalRGroups).then((data: any) => {
				// 	console.log("signalr-test: joined all system groups available.");
				// 	this.joinedGroups = true;
				// });
				var skipNumber = 0;
				var takeNumber = 100;
				var collectedNumber = 100;
				
				while (takeNumber == collectedNumber) {
					var listOfTagNamePrefixesArray = service.dataService.cache.assets.where((asset: IAsset) => { return asset.TagNamePrefix != null })
																				.select((asset: IAsset) => { return asset.TagNamePrefix; })
																				.skip(skipNumber)
																				.take(takeNumber)
																				.toArray();
		
					collectedNumber = listOfTagNamePrefixesArray.length;
					var listOfTagNamePrefixes = listOfTagNamePrefixesArray.join(",");
					console.log("signalr-test: joining Tag Name Prefix Groups. " + listOfTagNamePrefixes);
					service.hubConnection.invoke("joinGroup", Global.User.currentUser.ODataAccessToken, listOfTagNamePrefixes).then((data: any) => {
						//console.log("signalr-test: joined Tag Name Prefix Groups. " + listOfTagNamePrefixes);
					});
			
					Global.User.DebugMode && console.log("collectedNumber: " + collectedNumber);																 
					skipNumber += 100;				 
				}

				console.log("signalr-test: joined all tag name prefix groups.");
				service.joinedGroups = true;
			}
			catch (e) {
				console.log("signalr-test: still haven't joined the groups.");
			}
		}
	}

	leaveGroups() {
		var service = this;
		if (service.hubConnection) {
			try {
				// service.hubConnection.invoke("leaveGroup", Global.User.currentUser.ODataAccessToken, service.systemSignalRGroups).then((data: any) => {
				// 	console.log("signalr-test: left all system groups available.");
				// 	this.joinedGroups = true;
				// });
				var skipNumber = 0;
				var takeNumber = 100;
				var collectedNumber = 100;
				
				while (takeNumber == collectedNumber) {
					var listOfTagNamePrefixesArray = service.dataService.cache.assets.where((asset: IAsset) => { return asset.TagNamePrefix != null })
																				.select((asset: IAsset) => { return asset.TagNamePrefix; })
																				.skip(skipNumber)
																				.take(takeNumber)
																				.toArray();
		
					collectedNumber = listOfTagNamePrefixesArray.length;
					var listOfTagNamePrefixes = listOfTagNamePrefixesArray.join(",");
					console.log("signalr-test: leaving Tag Name Prefix Groups. " + listOfTagNamePrefixes);
					service.hubConnection.invoke("leaveGroup", Global.User.currentUser.ODataAccessToken, listOfTagNamePrefixes).then((data: any) => {
						//console.log("signalr-test: joined Tag Name Prefix Groups. " + listOfTagNamePrefixes);
					});
			
					Global.User.DebugMode && console.log("collectedNumber: " + collectedNumber);																 
					skipNumber += 100;				 
				}

				console.log("signalr-test:  left all tag name prefix groups.");
				service.listOfTagNamePrefixes = null;
				service.hubConnection.off;
			}
			catch (e) {
				console.log("signalr-test: still haven't left all joined the groups.");
			}
		}

	}

	startTest() {
		var service = this;
		service.timeSent = parseFloat(performance.now().toFixed(3));
		service.signalRCore.notifySpecificClient(Global.SignalR.ClientId, 'loopback', service.timeSent);
		if (!service.hubConnection) {
			service.startHub();
		}
	}

	ParseMessageContent(message: string, batchNumber: number, groupName: string, rawClientObject: any, receiveTimeMS: number) {
		var dataArray = message.split('~');
		if (dataArray.length == 3) {
			var returnObject: ISignalRMessage = {
				GroupName: groupName,
				TagId: dataArray[0].split('!').last(),
				DateMS: dataArray[1].split('!').last(),
				Value: dataArray[2].split('!').last()
			};

			return returnObject;
		}
		return null;
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	restartTest() {
		this.startTest();
	}

	onResize(event?) {
		this.screenHeight = window.innerHeight;
		this.screenWidth = window.innerWidth;
	}

	onSubmit(event): void {
		// Process checkout data here
		this.tagIdsList.push(this.checkoutForm.value);
		this.formattedTagIds.push(parseInt(this.checkoutForm.value.tagId));
		console.log(this.tagIdsList);

		this.checkoutForm.reset();
	  }
}
