import {
	Component,
	OnInit,
	ChangeDetectorRef,
	ViewEncapsulation,
	EventEmitter,
	Input,
	ViewChild,
	OnDestroy,
	NgZone,
	ElementRef,
	AfterViewInit,
} from '@angular/core';

import { process, State } from '@progress/kendo-data-query';

import { GridSettings } from '../../_models/grid-settings.interface';
import { RowClassArgs, PageChangeEvent } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import _ from 'lodash';
import { filter } from 'rxjs/operators';
import { SassHelperComponent } from '../../_helpers/sass-helper/sass-helper.component';

import { Subscription } from 'rxjs';
import { DataService } from '../../services/data.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { KendoSettingsService } from '../../services/kendo-settings.service';
import { DashboardService } from '../../services/dashboard.service';
import { UtilityService } from '../../services/utility.service';
import { Global } from '../../_constants/global.variables';
import * as $ from 'jquery';
import { GseService } from '../../services/gse.service';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { IAsset } from '../../_models/asset.model';
import { MapsService } from '../../services/maps.service';
import { IGlobal } from '../../_models/global.model';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

declare var Highcharts: any;
declare const google: any;
declare const markerClusterer:any;


@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-site-active-alarms',
	templateUrl: './site-active-alarms.component.html',
	styleUrls: ['./site-active-alarms.component.scss'],
})
export class SiteActiveAlarmsComponent implements AfterViewInit, OnInit, OnDestroy {
	public gridContextMenuItems: Array<string> = []
	@ViewChild('alertGrid') alertGrid: KendoGridParentComponent;
	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	@ViewChild('calculatedDataRow') calculatedDataRow: ElementRef;
	@ViewChild('major') elementView: ElementRef;
	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	Highcharts: typeof Highcharts = Highcharts;
	Chart: any;
	ChartData = {
		Criticals: [],
		Alarms: [],
		Alerts: [],
		Categories: [],
		TimeStamps: [],
	};
	alertData: any;
	alarmTagIds = [];
	alarmTypeCounts = { Alarms: 0, Criticals: 0, Alerts: 0 };
	jbtStandardObsLookup: any;
	siteUTCTimeOffset: number;
	isDataLoading: boolean = true;
	timeZoneType: string;
	isChartDataLoading: boolean = false;
	chartUpdateIntervalSet: boolean = false;
	chartTimeRecentlyUpdated: boolean;
	rowHeight = 26;
	pageSize: number;
	theme: any;
	majorFontSize: any;
	minorFontSize: any;
	bodyTextColor: string;
	signalRSubscription: Subscription;
	updateDataInterval: NodeJS.Timeout;
	updateChartInterval: NodeJS.Timeout;
	signalRTagUpdateSubscription: Subscription;
	colorChangedSubscription: Subscription;
	widgetResizedSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;

	fontResizeTimeout:any;

	allGSEAssetTypeIds = [86292, 86293, 86294, 86295, 86296, 86297, 86313, 86314];

	alertGridSettings: any = {

		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
	};
	fullDataCacheSubscription: any;
	parentContainerSize: any;

	assetIds: any = [];
	siteList: any[] = [];
	chartIsUsable: boolean;
	newHeight: any;
	oldHeight: any;
	valuePicked = 0;
	hasBeenRedrawn: boolean = false;
	map: any;
	private centerLatitude: number = 30.3092613;
	private centerLongitude: number = -95.4679269;
	mapOptions: any;
	markers: any[];
	markerclusterer: any;
	private swalWithBootstrapButtons: any;
	tagGraphSingleModalSubscription: Subscription;

	assetIdListArray: Array<any>;
	summaryModalSubscription: Subscription;
	public global: IGlobal = Global;

	componentName: string = "site-active-alarms: ";
	public guid: string;

	constructor(
		public dataService: DataService,
		private ref: ChangeDetectorRef,
		private signalRCore: SignalRCoreService,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private utilityService: UtilityService,
		private zone: NgZone,
		private gseService: GseService,
		public dialog: MatDialog,
		private mapsService: MapsService,
		private elem: ElementRef
	) {}

	ngAfterViewInit() {
		this.mapsService.loadGoogleMaps().then(() => {
			if (!Global.FullDataCacheExists) {
				this.fullDataCacheSubscription =
					this.dataService.fullDataCacheExists$.subscribe((data: any) => {
						if (data === true) {
							this.initialize();
							this.fullDataCacheSubscription.unsubscribe();
						}
					});
			} else {



				if(!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
					var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
					let fleetId = VocationalSettings.id;

					this.dataService.GetFleetAssets(fleetId).subscribe((data: any) => {
						this.assetIdListArray = new Array();
						data.forEach(record => {
							this.assetIdListArray.push(Number(record.AssetId));
						});

						// get the name of the Fleet
						this.widgetObject.FleetName = data.length > 0 ? data[0].FleetName : "";

						this.initialize();
					});
				}
				else {
					this.initialize();
				}
			}

			this.colorChangedSubscription =
				this.dataService.colorChanged$.subscribe((theme: any) => {
					this.theme = theme;
					this.bodyTextColor =
						this.theme === 'dark'
							? 'white'
							: this.sassHelper.readProperty('body-text-color-dark');
					if (this.Chart) {
						if (this.chartIsUsable) {
							this.createChart();
						}
					}
				});

			if (this.widgetResizedEvent) {
				this.widgetResizedSubscription = this.widgetResizedEvent.subscribe(
					(data) => {
						if (
							this.Chart &&
							this.widgetObject.WidgetId == data.item.WidgetId
						) {
							this.Chart.setSize(
								$('.alert-chart').width(),
								$('.alert-chart').height(),
								false
							);

							// Resize the major and minor divs
							this.fontResize(data);
						}
					}
				);
			}

			if (this.dashboardTimeZoneChanged) {
				this.dashboardTimeZoneChangedSubscription =
					this.dashboardTimeZoneChanged.subscribe((data) => {
						Global.User.currentUser.Username == 'dylan' &&
							console.log(data);
						let foundWidgetWithSameWidgetId = data.find(
							(widgetThatWasChanged) => {
								return (
									widgetThatWasChanged.WidgetId ===
									this.widgetObject.WidgetId
								);
							}
						);

						if (!_.isNil(foundWidgetWithSameWidgetId)) {
							Global.User.currentUser.Username == 'dylan' &&
								console.log('Widget Time Zone Changed');

							this.timeZoneType =
								this.dashboardService.determineTimeZoneType(
									foundWidgetWithSameWidgetId
								);
							this.isChartDataLoading = true;
							if (this.chartIsUsable) {
								this.getChartData();
							}
							const localDateCol =
								this.alertGridSettings.columnsConfig.find(
									(col) => col.field == 'JavascriptDate'
								);
							const siteDateCol =
								this.alertGridSettings.columnsConfig.find(
									(col) => col.field == 'SiteLocalJavascriptDate'
								);

							const utcDateCol =
								this.alertGridSettings.columnsConfig.find(
									(col) => col.field == 'UTCJavascriptDate'
								);

							localDateCol.hidden = this.timeZoneType !== 'User Time';
							siteDateCol.hidden = this.timeZoneType !== 'Site Time';
							utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
							localDateCol.includeInChooser = !localDateCol.hidden;
							siteDateCol.includeInChooser = !siteDateCol.hidden;
							utcDateCol.includeInChooser = !utcDateCol.hidden;
						}
					});
			}
		})


	}

	ngOnInit(): void {
		this.guid = this.dataService.guid();

	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.updateChartInterval) {
			clearInterval(this.updateChartInterval);
		}

		if (this.fontResizeTimeout) {
			clearTimeout(this.fontResizeTimeout);
		}


		if (this.dashboardTimeZoneChangedSubscription) {
			this.dashboardTimeZoneChangedSubscription.unsubscribe();
		}
	}


	fontResize(data: any) {

		if (data.height < 400 || data.width < 400) {
			this.majorFontSize = '100%';
			this.minorFontSize = 'x-small';
		}

		else if ((data.height > 400 && data.height < 500) || (data.width > 400 && data.width < 600)) {
			this.majorFontSize = '300%';
			this.minorFontSize = 'small';
		}

		else if ((data.height > 500 && data.height < 700) || (data.width > 600 && data.width < 800)) {
			this.majorFontSize = '400%';
			this.minorFontSize = 'medium';
		}
		else if (data.height > 700 || data.width > 800) {
			this.majorFontSize = '500%';
			this.minorFontSize = 'large';
	  }
  }

	// generateHeight(name, ratio) {
	// 	let returnedHeight = $(name).height();
	// 	let fontSizeCalculationAsNumber = returnedHeight / ratio;
	// 	return fontSizeCalculationAsNumber.toString() + 'px';
	// }

	onResized(event) {
		this.pageSize = Math.floor(
			((event.newRect.height - 120) / this.rowHeight) * 3
		);
		this.parentContainerSize = event.newRect.height;
		Global.User.currentUser.Username == 'dylan' &&
			console.log('PageSize = ' + this.pageSize);
	}

	saveGridSettings() {
		this.kendoSettingsService
			.saveGridSettings(
				[
					{
						gridObject: this.alertGrid.kendoGridParent,
						gridState: this.alertGridSettings.state,
					},
				],
				this.widgetObject.WidgetId
			)
			.then((data: any) => {
				Global.User.currentUser.Username == 'dylan' &&
					console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
	}

	mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		Global.User.DebugMode && console.log(state);
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			)

		};
	}

	evaluateSelectedFilters(): any {
		if (
			this.alertGridSettings.state.filter.filters.length > 0 &&
			this.alertGridSettings.state.filter.filters[0].filters.length > 0
		) {
			let arrayToReturn = [];
			this.alertGridSettings.state.filter.filters[0].filters.forEach(
				(filter) => {
					return arrayToReturn.push(filter.value);
				}
			);

			return arrayToReturn;
		} else {
			return [];
		}
	}

	filterTableData(type: string, dataGrid) {
		if (type === 'All') {
			this.alertGridSettings.state.filter = {
				filters: [
					{
						filters: [],
						logic: 'and',
					},
				],
				logic: 'and',
			};
			if (this.Chart) {
				for (let j = 0; j < this.Chart.series.length; j++) {
					this.Chart.series[j].show();
				}
			}
		} else {
			this.alertGridSettings.state.filter = {
				filters: [
					{
						filters: [
							{
								field: 'Severity',
								operator: 'doesnotcontain',
								value: type,
							},
						],
						logic: 'and',
					},
				],
				logic: 'and',
			};
			if (this.Chart) {
				if (type === 'Critical' || type === 'Red') {
					this.Chart.series[0].hide();
					if (this.Chart.series.length > 0)
						this.Chart.series[1].show();
				}
				if (type === 'Alarm' || type === 'Amber') {
					this.Chart.series[1].hide();
					this.Chart.series[0].show();
				}
			}
		}
		if (this.alertGrid) {
			this.alertGrid.gridDataSubject.next(this.alertData);
		}
	}

	initialize() {
		this.gridContextMenuItems = ['View Asset Summary'];
		if(this.widgetObject.AngularDirectiveName === 'gseActiveAlarms'){
			this.gridContextMenuItems.push('Trend Using Playback - 5 Min Before');
			this.gridContextMenuItems.push('Trend Using Playback - 2 Hr Before');
		}
		this.alertGridSettings.columnsConfig = [
			{
				field: 'ShortTagName',
				title: 'Name',
				filterable: true,
				_width: 200,
				minResizableWidth: 200,
			},
			{
				field: 'SiteName',
				title: 'Site',
				filterable: true,
				_width: 150,
				minResizableWidth: 60,
				hidden:
					this.widgetObject.AngularDirectiveName === 'gseActiveAlarms'
						? false
						: true,
			},
			{
				field: 'Asset.ParentSystem.Name',
				title: 'Gate',
				filterable: true,
				_width: 150,
				minResizableWidth: 60,
				hidden:
					this.widgetObject.AngularDirectiveName === 'gseActiveAlarms'
						? true
						: false,
			},
			{
				field: 'Asset.Name',
				title: 'Asset',
				filterable: true,
				_width: 150,
				minResizableWidth: 60,
			},
			{
				field: 'Severity',
				title: 'Severity',
				filterable: true,
				_width: 150,
				minResizableWidth: 125,
			},
			{
				field: 'JavascriptDate',
				title: 'Last Updated',
				filter: 'date',
				filterable: true,
				_width: 150,
				minResizableWidth: 125,
			},
			{
				field: 'SiteLocalJavascriptDate',
				title: 'Last Updated',
				filterable: true,
				filter: 'date',
				_width: 150,
				minResizableWidth: 125,
			},
			{
				field: 'UTCJavascriptDate',
				title: 'Last Updated',
				filterable: true,
				filter: 'date',
				_width: 150,
				minResizableWidth: 125,
			},
			{
				field: 'Value',
				title: 'Value',
				filterable: true,
				_width: 100,
			},
			{
				field: 'ValueWhenActive',
				title: 'Value When Active',
				filterable: true,
				_width: 100,
			},
		];
		if (
			(this.widgetObject.AngularDirectiveName === 'siteActiveAlarms' &&
				this.widgetObject.WidgetSiteId) ||
			(this.widgetObject.AngularDirectiveName === 'gseActiveAlarms' &&
				(!_.isNil(this.widgetObject.SiteList) || !_.isNil(this.widgetObject.VocationalSettingsJSON)))
		) {
			this.isDataLoading = true;
			this.isChartDataLoading = true;
			this.theme = Global.Theme;
			this.bodyTextColor =
				this.theme === 'dark'
					? 'white'
					: this.sassHelper.readProperty('body-text-color-dark');
			this.timeZoneType = this.dashboardService.determineTimeZoneType(
				this.widgetObject
			);
			if (this.widgetObject.AngularDirectiveName === 'siteActiveAlarms') {
				this.siteUTCTimeOffset =
					this.dataService.cache.sitesObject[
						this.widgetObject.WidgetSiteId
					].UTCTimeOffset;
			}
			if (this.widgetObject.AngularDirectiveName === 'gseActiveAlarms') {
				let siteUTCOffsets = [];

				if(!_.isNil(this.widgetObject.SiteList)) {
					if (typeof this.widgetObject.SiteList === 'string') {
						this.siteList = this.widgetObject.SiteList.split(',').map(
							(item) => {
								return parseInt(item);
							}
						);
					} else {
						this.siteList = this.widgetObject.SiteList;
					}
					this.siteList.forEach((siteId) => {
						siteUTCOffsets.push(
							this.dataService.cache.sitesObject[siteId].UTCTimeOffset
						);
					});
					console.log(siteUTCOffsets);
					siteUTCOffsets = _.uniq(siteUTCOffsets);
					console.log(siteUTCOffsets);
					if (
						siteUTCOffsets.length > 1 &&
						_.isNil(this.widgetObject.WidgetAssetId)
					) {
						this.chartIsUsable = false;
					} else {
						this.chartIsUsable = true;
						this.siteUTCTimeOffset = siteUTCOffsets[0];
					}
				}
				else if(!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
					// set the fleet UTC offset ???
					this.siteUTCTimeOffset = -5;
					this.chartIsUsable = true;
				}
			} else if (
				this.widgetObject.AngularDirectiveName === 'siteActiveAlarms'
			) {
				this.siteList.push(parseInt(this.widgetObject.WidgetSiteId));
				this.chartIsUsable = true;
			}
			if (this.chartIsUsable) {
				this.createChart();
			}

			// Get the jbtStandardObservationsObject and the tagsObject from the cache to look up readable names for the tags
			this.jbtStandardObsLookup =
				this.dataService.cache.jbtStandardObservationsObject;


			let siteAssets;
			let assetIds: string;
			if(!_.isNil(this.widgetObject.SiteList)) {
				siteAssets = this.dataService.cache.assets.filter((t) => {
					if (
						this.widgetObject.AngularDirectiveName ===
						'siteActiveAlarms'
					) {
						return this.siteList.includes(t.SiteId);
					} else if (
						this.widgetObject.AngularDirectiveName === 'gseActiveAlarms'
					) {
						return (
							this.siteList.includes(t.SiteId) &&
							this.allGSEAssetTypeIds.includes(t.AssetTypeId)
						);
					}
				});
				this.assetIdListArray = siteAssets.map((a) => a.Id);
			}

			else if(!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
				var VocationalSettings = JSON.parse(this.widgetObject.VocationalSettingsJSON);
				let fleetId = VocationalSettings.id;
				siteAssets = this.dataService.cache.assets.filter((asset) => {
					return this.assetIdListArray.includes(asset.Id)
				});

				// set the child asset
				if(VocationalSettings.child && VocationalSettings.child.type == "Asset" && VocationalSettings.child.id) {
					this.widgetObject.WidgetAssetId = VocationalSettings.child.id;
					this.widgetObject.WidgetAssetName = this.dataService.cache.assetsObject[this.widgetObject.WidgetAssetId].Name;
				}


			}



			if (!_.isNil(this.widgetObject.WidgetAssetId)) {
				assetIds = this.widgetObject.WidgetAssetId.toString();
				this.assetIds.push(parseInt(this.widgetObject.WidgetAssetId));
			}
			else if(_.isNil(this.widgetObject.VocationalSettingsJSON)) {
				if(this.assetIdListArray?.length > 0) {
					assetIds = this.assetIdListArray.join();
				} else {
					assetIds = this.dataService.cache.assets.where((asset: IAsset) => { return asset.SiteId == this.widgetObject.WidgetSiteId }).toArray().map((a: IAsset) => a.Id.toString()).join();

				}
				this.assetIds = assetIds.split(",");

			} else {
				assetIds = this.assetIdListArray.join();
			}

			let allObsIdsForAlarms = [];
			this.dataService.cache.jbtStandardObservationSeverityLevels.forEach(
				(level: any) => {
					if (level.Name !== 'Informational') {
						level.JBTStandardObservations.forEach((obs: any) => {
							allObsIdsForAlarms.push(obs.Id);
						});
					}
				}
			);
			let allObsIdsForAlarmsString = allObsIdsForAlarms.join();

			this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
					assetIds,
					false
				)
				.subscribe((data) => {
					Global.User.currentUser.Username == 'dylan' &&
						console.log(data);
					if(data) {
						this.formatTagData(data);
					}
				});

			// Reload saved Kendo Grid Settings
			if (this.widgetObject.WidgetKendoUIJson) {
				let jsonObjectParsed =
					this.kendoSettingsService.parseReturnedSettingsForDates(
						this.widgetObject.WidgetKendoUIJson
					);
				//the first item in the array is the alertgridsettings for the first tab of data for this widget.

				let returnedParsedObject = jsonObjectParsed[0];
				//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
				this.alertGridSettings = this.mapGridSettings(
					this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
						this.alertGridSettings,
						returnedParsedObject
					)
				);
			}
			this.widgetObject.isDisplayDataLive = true;

			const localDateCol = this.alertGridSettings.columnsConfig.find(
				(col) => col.field == 'JavascriptDate'
			);
			const siteDateCol = this.alertGridSettings.columnsConfig.find(
				(col) => col.field == 'SiteLocalJavascriptDate'
			);

			const utcDateCol = this.alertGridSettings.columnsConfig.find(
				(col) => col.field == 'UTCJavascriptDate'
			);

			localDateCol.hidden = this.timeZoneType !== 'User Time';
			siteDateCol.hidden = this.timeZoneType !== 'Site Time';
			utcDateCol.hidden = this.timeZoneType !== 'UTC Time';
			localDateCol.includeInChooser = !localDateCol.hidden;
			siteDateCol.includeInChooser = !siteDateCol.hidden;
			utcDateCol.includeInChooser = !utcDateCol.hidden;

		} else {
			this.isDataLoading = false;
			return;
		}
	}

	formatTagData(tags: any) {
		let tag = tags.filter((t) => t.Id == 11806902);
		// Get all the critical, alarm, and warning tags
		let alarmTagIds = [];

		let allCurrentlyActiveAlarms = this.dataService.cache.tags
			.filter((t) => {
				if(t.Asset == null) {
					return false;
				}
				if (
					this.widgetObject.AngularDirectiveName ===
					'siteActiveAlarms'
				) {
					return ['PBB', 'PCA', 'GPU', 'WCL', 'AHU'].includes(
						t.Asset.Name
					);
				} else if (
					this.widgetObject.AngularDirectiveName === 'gseActiveAlarms'
				) {
					return this.allGSEAssetTypeIds.includes(
						t.Asset.AssetTypeId
					);
				}
			})
			.filter(
				(t) => t.EffectiveSeverityLevelId != 2 && t.EffectiveSeverityLevelId != 4
			) // Only Alarms,Alerts and Critical Tags
			.filter((t) => {
				if (!_.isNil(this.widgetObject.WidgetAssetId)) {
					return t.Asset.Id == this.widgetObject.WidgetAssetId;
				} else if(!_.isNil(this.widgetObject.VocationalSettingsJSON)){
					return this.assetIdListArray.includes(t.Asset.Id);
				}
				else {
					return this.siteList.includes(t.SiteId); // Only Tags that are currently active (Value != 0
				}
			})
			.filter(
				(t) =>
					![12324,70719, 12335, 12349].includes(t.JBTStandardObservationId)
			) // Exclude "Alarm is Active", "Warning is Active", and "Critical Alarm is Active"
			.filter((t) => {
				if (t.EffectiveSeverityLevelId == 1 || t.EffectiveSeverityLevelId == 3 || t.EffectiveSeverityLevelId == 5) {
					alarmTagIds.push(t);
				}
				return (
					t.ValueWhenActive == t.Value ||
					(_.isNil(t.ValueWhenActive) && t.Value == '1')
				);
			});

		this.alertData = _.orderBy(
			allCurrentlyActiveAlarms,
			['JavascriptDate'],
			['desc']
		);
		Global.User.currentUser.Username == 'dylan' &&
			console.log(this.alertData);
		//Critical
		this.alarmTypeCounts.Criticals = this.alertData.reduce(
			(count, tag) => count + (tag.EffectiveSeverityLevelId == 3),
			0
		);
		//Alarm
		this.alarmTypeCounts.Alarms = this.alertData.reduce(
			(count, tag) => count + (tag.EffectiveSeverityLevelId == 1),
			0
		);
		//Alert
		this.alarmTypeCounts.Alerts = this.alertData.reduce(
			(count, tag) => count + (tag.EffectiveSeverityLevelId == 5),
			0
		);

		if (this.alertGrid) {
			this.alertGrid.gridDataSubject.next(this.alertData);
		}

		this.isDataLoading = false;
		this.chartTimeRecentlyUpdated = false;

		// this.majorFontSize = 'x-large';
		// this.minorFontSize = 'large';

		// set the initial font size
		var service = this;
		this.fontResizeTimeout = setTimeout(function () {
			var bounds = service.elem.nativeElement.getBoundingClientRect();
			service.fontResize(bounds);
		}, 100);

		if (this.chartIsUsable) {
			this.getChartData();
		}

		this.getSignalRUpdates(alarmTagIds.map((t) => t.Id));
	}

	getChartData() {
		this.ChartData = {
			Criticals: [],
			Alarms: [],
			Alerts: [],
			Categories: [],
			TimeStamps: [],
		};
		let hourAgo;

		if (this.timeZoneType === 'User Time') {
			hourAgo = moment().subtract(1, 'hour');
		} else if (this.timeZoneType === 'Site Time') {
			hourAgo = moment(
				this.utilityService.convertFromUtcToLocalToSite(
					new Date().valueOf(),
					this.siteUTCTimeOffset
				)
			).subtract(1, 'hour');
		} else if (this.timeZoneType === 'UTC Time') {
			hourAgo = moment(
				this.utilityService.convertFromUtcToLocalToSite(
					new Date().valueOf(),
					0
				)
			).subtract(1, 'hour');
		}
		hourAgo.minutes(Math.floor(hourAgo.minutes() / 5) * 5); // Calculate last hour and 5 minute intervals

		for (let i = 0; i < 13; i++) {
			let timeStamp = hourAgo.add(5, 'minutes');

			this.ChartData.Categories.push(timeStamp.format('LT'));
			this.ChartData.TimeStamps.push(new Date(timeStamp.valueOf()));
			this.ChartData.Criticals.push(0);
			this.ChartData.Alarms.push(0);
			this.ChartData.Alerts.push(0);
		}

		// Get Active Alarms for past hour
		let siteIds = this.siteList.map((a) => a.toString()).join();
		let statement = 'GetActiveAlarmsForLastHour ';
		if (!_.isNil(this.widgetObject.WidgetAssetId)) {
			statement +=
				"@ListOfAssetIds='" + this.widgetObject.WidgetAssetId + "'";
		} else {
			statement += "@ListOfSiteIds='" + siteIds + "'";
		}

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.currentUser.Username == 'dylan' && console.log(data);
			let apiDataTags = data;

			this.ChartData.TimeStamps.forEach((time, index) => {
				if (index != 12) {
					var tagsInRange = apiDataTags.filter(
						(t) =>
							moment.utc(t.StartDate).isSameOrBefore(time) &&
							(moment(t.EndDate).isSameOrAfter(time) ||
								t.EndDate == null)
					);

					//Critical
					this.ChartData.Criticals[index] = tagsInRange.reduce(
						(count, tag) =>
							count + (tag.EffectiveSeverityLevelId == 3),
						0
					);
					//Alarm
					this.ChartData.Alarms[index] = tagsInRange.reduce(
						(count, tag) =>
							count + (tag.EffectiveSeverityLevelId == 1),
						0
					);
					//Alert
					this.ChartData.Alerts[index] = tagsInRange.reduce(
						(count, tag) =>
							count + (tag.EffectiveSeverityLevelId == 5),
						0
					);
				} // Push the current Alarm Counts
				else {
					this.ChartData.Criticals[index] =
						this.alarmTypeCounts.Criticals;
					this.ChartData.Alarms[index] = this.alarmTypeCounts.Alarms;
					this.ChartData.Alerts[index] = this.alarmTypeCounts.Alerts;
				}
			});

			this.isChartDataLoading = false;
			Global.User.currentUser.Username == 'dylan' &&
				console.log(this.ChartData);
			if (this.chartIsUsable) {
				this.createChart();
			}
		});
	}

	createChart() {
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');

		var chartOptions = {
			chart: {
				type: 'area',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
			},
			loading: {
				labelStyle: {
					color: innerChartTextColor,
				},
				style: {
					backgroundColor:
						this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				},
			},
			animation: false,
			credits: { enabled: false },
			title: {
				text: 'Last Hour Fault / Alert Counts',
				style: { fontSize: '1.25em', color: outerChartTextColor },
			},
			xAxis: {
				categories: this.ChartData.Categories,
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
				title: {
					text: '',
				},
			},
			tooltip: {
				shared: true,
				crosshairs: true,
				headerFormat:
					'<span style="font-size: 12px;font-weight: bold">{point.key}</span><br/>',
			},
			plotOptions: {
				area: {
					stacking: 'normal',
					dataLabels: {
						enabled: false,
					},
				},
			},
			legend: {
				align: 'center',
				verticalAlign: 'top',
				itemStyle: {
					color: innerChartTextColor,
					fontWeight: 'bold',
				},
			},
			series: [
				{
					name: this.dataService.getSeverityNameById(3),
					data: this.ChartData.Criticals,
					color: 'rgba(255, 0, 0, 0.5)',
				},
				{
					name: this.dataService.getSeverityNameById(1),
					data: this.ChartData.Alarms,
					color: 'rgba(219, 45, 45, 0.8)',
				},
				{
					name: 'Alerts',
					data: this.ChartData.Alerts,
					color: 'rgba(219, 45, 45, 0.8)',
				},
			],
		};
		if (this.isDataLoading === false) {
			this.zone.runOutsideAngular(() => {
				this.Chart = Highcharts.chart(
					`siteActiveAlerts${this.widgetObject.WidgetId}`,
					chartOptions
				);
			});
			if (this.isChartDataLoading) {
				this.Chart.showLoading();
			} else {
				this.Chart.hideLoading();
				this.updateChartInterval = setInterval(() => {
					this.updateChartTimes();
				}, 1000 * 60);
			}
			if (
				this.alertGridSettings.state.filter?.filters[0]?.filters[0]
					?.value == 'Critical'
			)
				this.Chart.series[0].hide();
			if (
				this.alertGridSettings.state.filter?.filters[0]?.filters[0]
					?.value == 'Alarm'
			)
				this.Chart.series[1].hide();
		}
	}

	getSignalRUpdates(tagIds: any) {
		let tagNamePrefixesString = _.uniq(this.assetIds.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix)).join(",");
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;


		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}
		Global.User.DebugMode && console.log("this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid,
										 WidgetName: this.widgetObject.WidgetName,
										 TagNamePrefix: tagNamePrefixesString.split(",")  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateAlarmGridData(tag);
				});
			});
	}

	updateAlarmGridData(tagObj: any) {
		let alarmTagIndex = this.alertData.findIndex(
			(t) => t.TagId == tagObj.TagId
		);
		if (alarmTagIndex != -1 && (tagObj.EffectiveSeverityLevelId != 2 || tagObj.EffectiveSeverityLevelId != 4 ) && (![12324,70719, 12335, 12349].includes(tagObj.JBTStandardObservationId))) {
			let alarmTag = this.alertData[alarmTagIndex];

			if (
				tagObj.ValueWhenActive == tagObj.Value ||
				(!_.isNil(tagObj.ValueWhenActive) && tagObj.Value == '1')
			) {
				if (this.alertGrid) {
					this.alertGrid.gridDataSubject.next(this.alertData);
				}

				// Change recently updated property back to false after 2 seconds
				setTimeout(() => {
					let newIndex = this.alertData.findIndex(
						(t) => t.TagId == tagObj.TagId
					);
					if (newIndex != -1) {
						this.ref.detectChanges();
					}
				}, 2000);

				Global.User.currentUser.Username == 'dylan' &&
					console.log(
						`Alert: ${alarmTag.Gate} | ${alarmTag.Asset} | "${alarmTag.Name}" was updated.`
					);
				this.ref.detectChanges();
			} else {
				this.alertData.splice(alarmTagIndex, 1);
				if (this.alertGrid) {
					this.alertGrid.gridDataSubject.next(this.alertData);
				}
				this.updateCountsAndChart(alarmTag.EffectiveSeverityLevelId, -1); // Update Counts
			}
		} else {
			if (
				(tagObj.ValueWhenActive == tagObj.Value ||
				(!_.isNil(tagObj.ValueWhenActive) && tagObj.Value == '1') && 
				 (tagObj.EffectiveSeverityLevelId != 2 || tagObj.EffectiveSeverityLevelId != 4 ) && 
				 (![12324,70719, 12335, 12349].includes(tagObj.JBTStandardObservationId)))
			) {
				this.alertData.push(tagObj); // Update Kendo Grid
				if (this.alertGrid) {
					this.alertGrid.gridDataSubject.next(this.alertData);
				}
				this.updateCountsAndChart(tagObj.EffectiveSeverityLevelId, 1); // Update Counts

				// Change recently updated property back to false after 2 seconds
				setTimeout(() => {
					let newIndex = this.alertData.findIndex(
						(t) => t.TagId == tagObj.TagId
					);
					if (newIndex != -1) {
						this.alertData[newIndex].RecentlyUpdated = false;
						this.ref.detectChanges();
					}
				}, 2000);
			}
		}
	}

	updateCountsAndChart(alarmType: number, opCode: number) {
		switch (alarmType) {
			//Critical
			case 3:
				this.alarmTypeCounts.Criticals += opCode;
				if (!this.isChartDataLoading && this.Chart) {
					this.Chart.series[0].data[
						this.ChartData.Criticals.length - 1
					]?.update(this.alarmTypeCounts.Criticals);
				}
				break;
			//Alarm
			case 1:
				this.alarmTypeCounts.Alarms += opCode;
				if (!this.isChartDataLoading && this.Chart) {
					this.Chart.series[1].data[
						this.ChartData.Alarms.length - 1
					]?.update(this.alarmTypeCounts.Alarms);
				}
				break;
			//Alert
			case 5:
				this.alarmTypeCounts.Alerts += opCode;
				if (!this.isChartDataLoading && this.Chart) {
					this.Chart.series[1].data[
						this.ChartData.Alerts.length - 1
					]?.update(this.alarmTypeCounts.Alerts);
				}
				break;

		}
	}

	updateChartTimes() {
		if (moment().minutes() % 5 == 0 && !this.chartTimeRecentlyUpdated) {
			// Add current values and generate new timestamp for next 5 minutes
			let timeStamp = moment(
				this.ChartData.TimeStamps[this.ChartData.TimeStamps.length - 1]
			).add(5, 'minutes');

			this.ChartData.TimeStamps.push(new Date(timeStamp.valueOf()));
			this.ChartData.Categories.push(timeStamp.format('LT'));
			if (this.Chart) {
				this.Chart.series[0].addPoint(
					this.alarmTypeCounts.Criticals,
					true,
					true
				);
				this.Chart.series[1].addPoint(
					this.alarmTypeCounts.Alarms,
					true,
					true
				);
				this.Chart.series[2].addPoint(
					this.alarmTypeCounts.Alerts,
					true,
					true
				);
			}

			this.chartTimeRecentlyUpdated = true;
			Global.User.currentUser.Username == 'dylan' &&
				console.log('Chart Times Updated');

			setTimeout(() => {
				this.chartTimeRecentlyUpdated = false;
			}, 1000 * 60 * 3);
		}
	}

	evaluateRedraw() {
		if (this.widgetObject !== undefined) {
			let cardHeight = $('#card-whole').height();
			if (this.newHeight !== this.oldHeight) {
				let height10 = this.newHeight * 0.1;
				let height = this.newHeight - (height10 + 30);
				$('.map-container' + this.widgetObject.WidgetId).height(height);
				$('#regularMap' + this.widgetObject.WidgetId).height(height);
			}
		}
	}

	animationRedraw() {
		if (this.valuePicked == 1) {
			// this.drawMap();
			this.hasBeenRedrawn = true;
			let mapContainer = $(
				'.map-container' + this.widgetObject.WidgetId
			).height();
			if (this.widgetObject !== undefined) {
				$('#regularMap' + this.widgetObject.WidgetId).height(
					mapContainer
				);
			}
			this.drawMap();
			// this.hasBeenRedrawn = true;
			// let mapContainer = $("#card-body-id").height();
			// if (this.dataObject !== undefined) {
			//   $("#regularMap").height(mapContainer - 50);
			// } else if (this.widgetObject !== undefined) {
			//   $("#regularMap" + this.widgetObject.WidgetId).height(mapContainer - 50);
			// }
		}
	}

	drawMap() {
		this.evaluateRedraw();
		if (this.map === undefined) {
			let legend = this.gseService.createLegendForGseMaps(
				'legend' + this.widgetObject.WidgetId
			);

			var centerPointLatLng = new google.maps.LatLng(
				this.centerLatitude,
				this.centerLongitude
			);
			this.map = new google.maps.Map(
				document.getElementById(
					'regularMap' + this.widgetObject.WidgetId
				),
				this.mapsService.loadMapConfiguration({center: {lat: this.centerLatitude, lng: this.centerLongitude}, zoom: 8})
			);
			this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
				legend
			);
			const styleControl = document.getElementById(
				'style-selector-control' + this.widgetObject.WidgetId
			) as HTMLElement;
			this.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(
				styleControl
			);
			(
				document.getElementById(
					'hide-poi' + this.widgetObject.WidgetId
				) as HTMLElement
			).addEventListener('click', () => {
				legend.hidden = true;
				//get legend by id and apply hiddden style
			});
			(
				document.getElementById(
					'show-poi' + this.widgetObject.WidgetId
				) as HTMLElement
			).addEventListener('click', () => {
				legend.hidden = false;

				//get legend by id and apply hiddden style
			});
		} else {
			for (let i = 0; i < this.markers.length; i++) {
				this.markers[i].setMap(null);
				this.markers[i] = null;
			}
			this.markerclusterer.clearMarkers();
			this.markerclusterer = null;
		}

		let bounds = new google.maps.LatLngBounds();

		let uniqueArray = _.uniqBy(
			this.alertGrid.processedRawDataForGridWithoutPaging.data,
			'AssetId'
		);
		this.markers = [];
		console.log(uniqueArray);
		uniqueArray.forEach((tag: any) => {
			let lat: any = _.head(
				tag.Asset.Tags.filter(
					(t) => t.JBTStandardObservationId == 54260
				)
			);
			let long: any = _.head(
				tag.Asset.Tags.filter(
					(t) => t.JBTStandardObservationId == 54261
				)
			);
			if (!_.isNil(lat) && !_.isNil(long)) {
				var gseLatLng = new google.maps.LatLng(lat.Value, long.Value);
				bounds.extend(gseLatLng);
				let listOfAlarmsAsFormatedHtml =
					this.findAllActiveAlarmsFromTagAndFormatForMap(tag);
				var infoWindow = new google.maps.InfoWindow({
					content: `<div class='firstHeading' style='font-size: 12px'><b><span style='color:teal;'> ${tag.SiteName} ${tag.Asset.Name}</div></b>
        <b><div style="font-weight: bold">Active Faults: </div></b>
        ${listOfAlarmsAsFormatedHtml.htmlString}
        </div>`,
				});

				let evaluationObject: any =
					this.gseService.determineAssetImageFromAssetTypeForMap(
						tag.Asset
					);
				if (evaluationObject !== undefined) {
					if (evaluationObject.alarmType === 'Critical') {
						var marker = new google.maps.Marker({
							position: gseLatLng,
							title: tag.Asset.Name,
							infowindow: infoWindow,
							icon: evaluationObject.icon,
							animation: google.maps.Animation.BOUNCE,
						});
					} else {
						var marker = new google.maps.Marker({
							position: gseLatLng,
							title: tag.Asset.Name,
							infowindow: infoWindow,
							icon: evaluationObject.icon,
						});
					}
				} else {
					var marker = new google.maps.Marker({
						position: gseLatLng,
						title: tag.Asset.Name,
						infowindow: infoWindow,
					});
				}

				google.maps.event.addListener(marker, 'click', function (evt) {
					infoWindow.open(this.map, marker);
				});
				listOfAlarmsAsFormatedHtml.allAlarmsForAsset;
				google.maps.event.addListener(infoWindow, 'domready', () => {
					listOfAlarmsAsFormatedHtml.allAlarmsForAsset.forEach(
						(tag) => {
							let tagDiv = document.getElementById(
								tag.JBTStandardObservation.Name + tag.AssetId
							);
							if (tagDiv !== null) {
								google.maps.event.addDomListener(
									tagDiv,
									'click',
									(evt) => {
										this.openTagGraphSingle(
											tag,
											tag.JBTStandardObservationId
										);
									}
								);
							}
						}
					);
					// this.tagOptions.forEach((tag) => {
					//   let tagDiv = document.getElementById(tag.tagName + assetObject.Id);
					//   if (tagDiv !== null) {
					//     google.maps.event.addDomListener(tagDiv, "click", (evt) => {
					//       this.openTagGraphSingle(assetObject, tag.standardObservationId);
					//     });
					//   }
					// });
				});

				infoWindow.open(this.map, marker);
				this.markers.push(marker);
			}
		});

		this.markerclusterer = new markerClusterer.MarkerClusterer({map: this.map, markers:  this.markers, renderer: this.mapsService.renderer});
		this.map.fitBounds(bounds);
		this.ref.detectChanges();
	}



	private findAllActiveAlarmsFromTagAndFormatForMap(tag) {
		let allCurrentAlarmsForAsset =
			this.alertGrid.processedRawDataForGridWithoutPaging.data.filter(
				(gridRow) => {
					return gridRow.AssetId === tag.AssetId;
				}
			);
		if (allCurrentAlarmsForAsset.length > 0) {
			let returnedString = '';
			allCurrentAlarmsForAsset.forEach((tag) => {
				returnedString =
					returnedString +
					`<div id='${tag.JBTStandardObservation.Name}${tag.AssetId}'style='font-size: 12px; cursor: pointer'> ${tag.JBTStandardObservation.Name}</div>`;
			});
			return {
				htmlString: returnedString,
				allAlarmsForAsset: allCurrentAlarmsForAsset,
			};
		}
	}

	openTagGraphSingle(tag, observationId): void {
		console.log(tag);
		const tagGraphSingleModal = this.dialog.open(
			DialogModalParentComponent,
			{
				width: '95%',
				height: '80%',
				data: {
					TagId: tag.TagId,
					widgetNameDisplay: 'Tag Graph',
					WidgetName: 'tag-graph',
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(
						this.widgetObject
					),
				},
				maxWidth: '100vw',
				maxHeight: '100vh',
			}
		);
		this.tagGraphSingleModalSubscription = tagGraphSingleModal
			.afterClosed()
			.subscribe((result) => {
				// if (this.debugMode) {
				//   console.log("The modal was closed");
				// }
			});
	}

	getValue(element, observationId, needObject) {
		let tag = element.Tags.find(
			(element) => element.JBTStandardObservationId === observationId
		);
		if (tag === undefined) {
			return null;
		} else if (needObject === undefined) {
			return tag.Value;
		} else if (needObject === true) {
			return tag;
		}
	}

	public determineWidgetForTag(tag: any){

	}

	public onRightClickSelect({ dataItem, item }): void {
		if (item === "View Asset Summary") {

			console.log(dataItem);
			if(this.widgetObject.AngularDirectiveName === 'gseActiveAlarms'){
					const summaryModal = this.dialog.open(DialogModalParentComponent, {
						width: Global.isMobile ? "100%" : "60%",
						height: Global.isMobile ? "90%" : "80%",
						data: {
							widgetObject: dataItem.Asset,
							shouldShowMap: false,
							siteName: dataItem.Asset.Site.Name,
							gseName: dataItem.Asset.Name,
							widgetNameDisplay: "Fleet Asset Summary",
							WidgetName: "GSE-Summary",
							parentWidget: this.widgetObject
						},

						maxWidth: "100vw",
						maxHeight: "100vh"
					});
					this.summaryModalSubscription = summaryModal.afterClosed().subscribe((result) => {
						this.summaryModalSubscription.unsubscribe();
					});
			} else {
				const summaryModal = this.dialog.open(DialogModalParentComponent, {
					width: Global.isMobile ? "100%" : "60%",
					height: Global.isMobile ? "90%" : "80%",
					data: {
						popupTitle: dataItem.Asset.AssetTypeName + ` Summary: ${dataItem.Asset.Site.Name} | Gate ${dataItem.Asset.ParentSystem.Name}`,
						siteName: dataItem.Asset.Site.Name,
						gateSystemName: dataItem.Asset.ParentSystem.Name,
						widgetNameDisplay: dataItem.Asset.AssetTypeName + ' Summary',
						assetObj: dataItem.Asset,
						WidgetName: dataItem.Asset.AssetTypeName.toUpperCase() + '-Summary',
						TimeZoneId: this.widgetObject.TimeZoneId,
					},

					maxWidth: "100vw",
					maxHeight: "100vh"
				});
				this.summaryModalSubscription = summaryModal.afterClosed().subscribe((result) => {
					this.summaryModalSubscription.unsubscribe();
				});
			}


			// const tagGraphSingleModal = this.dialog.open(DialogModalParentComponent, {
			// 	width: "95%",
			// 	height: "80%",
			// 	data: {
			// 		TagId: dataItem.TagId,
			// 		widgetNameDisplay: "Tag Graph",
			// 		WidgetName: "tag-graph",
			// 		isDisplayDataLive: true,
			// 		timeZoneType: this.dashboardService.determineTimeZoneType(this.widgetObject)
			// 	},
			// 	maxWidth: "100vw",
			// 	maxHeight: "100vh"
			// });
			// this.tagGraphSingleModalSubscription = tagGraphSingleModal.afterClosed().subscribe((result) => {
			// 	this.tagGraphSingleModalSubscription.unsubscribe();
			// });
		} else if (item === 'Trend Using Playback - 5 Min Before' || item === 'Trend Using Playback - 2 Hr Before') {
			const summaryModal = this.dialog.open(DialogModalParentComponent, {
				width: Global.isMobile ? "100%" : "60%",
				height: Global.isMobile ? "90%" : "80%",
				data: {
					widgetObject: dataItem.Asset,
					shouldShowMap: false,
					breadcrumbTags: [dataItem],
					timeBeforeToStart: item === 'Trend Using Playback - 5 Min Before' ? 5 : 120,
					siteName: dataItem.Asset.Site.Name,
					gseName: dataItem.Asset.Name,
					widgetNameDisplay: "Fleet Asset Summary",
					WidgetName: "GSE-Summary",
					parentWidget: this.widgetObject
				},

				maxWidth: "100vw",
				maxHeight: "100vh"
			});
			this.summaryModalSubscription = summaryModal.afterClosed().subscribe((result) => {
				this.summaryModalSubscription.unsubscribe();
			});
		}
	}
}
