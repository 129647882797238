import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	OnDestroy,
	Optional,
	Inject,
	ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GroupDescriptor } from '@progress/kendo-data-query';

import { of, from } from 'rxjs';
import {
	map,
	filter,
	tap,
	groupBy,
	mergeMap,
	reduce,
	toArray,
	switchMap,
} from 'rxjs/operators';
import _ from 'lodash';

import { process } from '@progress/kendo-data-query';
import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { KendoGridParentComponent } from '../kendo-grid-parent/kendo-grid-parent.component';
@Component({
	selector: 'lib-add-widget-to-dashboard',
	templateUrl: './add-widget-to-dashboard.component.html',
	styleUrls: ['./add-widget-to-dashboard.component.scss'],
})
export class AddWidgetToDashboardComponent implements OnInit, OnDestroy {
	@ViewChild('widgetTypeGrid') widgetTypeGrid: KendoGridParentComponent;

	displayedColumns: string[] = ['name', 'description'];
	dataSource: Object[];
	widgetTypeGroups: any;
	isLoading: boolean;
	theme: string;
	public gridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			group: [
				{
					field: 'Category',
					dir: 'asc',
				},
			],
		},
		columnsConfig: [
			{
				field: 'Name',
				title: 'Name',
				filterable: true,
				_width: 150,
				hidden: false,
				minResizableWidth: 40,
			},
			{
				field: 'Description',
				title: 'Description',
				filterable: true,
				_width: 150,
				minResizableWidth: 150,
			},
			{
				field: 'TrainingLesson',
				title: 'Training Lesson',
				filterable: false,
				_width: 50,
			},
			{
				field: 'Category',
				title: 'Category',
				hidden: true,
				filterable: true,
				_width: 100,
				minResizableWidth: 150,
			},
		],
	};

	@Output() widgetClicked = new EventEmitter<any>();
	colorChangedSubscription: any;
	widgetTypesInOneArray: any[];
	gridView: any;
	constructor(
		public dialogRef: MatDialogRef<AddWidgetToDashboardComponent>,
		public dataService: DataService,
		@Optional() @Inject(MAT_DIALOG_DATA) public dashboardData: any
	) {}

	private videoKeyURL: string = Global.Video.serverArchiveUrl;
	private accessToken: string = Global.User.currentUser.ODataAccessToken;

	consoleElement(element) {
		console.log(element);
	}
	canUserAddWidget(widgetTypeId) {
		if (
			Global.User.isAdmin ||
			Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(
				widgetTypeId
			)
		) {
			return true;
		} else {
			return false;
		}

		// return true;
	}

	canUserAddWidgetText(element) {
		if (
			Global.User.isAdmin ||
			Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(
				element.WidgetTypeId
			)
		) {
			return true;
		} else {
			return false;
		}
		// return false;
	}
	ngOnInit() {
		var service = this;
		console.log(service.dashboardData);
		console.log(Global);
		if (Global.Theme === 'light') {
			service.theme = 'light';
		} else if (Global.Theme === 'dark') {
			service.theme = 'dark';
		}

		service.colorChangedSubscription =
			service.dataService.colorChanged$.subscribe((data: any) => {
				Global.User.DebugMode && console.log(data);
				if (data === 'light') {
					service.theme = 'light';
				} else {
					service.theme = 'dark';
				}
			});
		service.isLoading = true;
		service.widgetTypeGroups = [];
		var widgetTypeTabGroupsWithWidgetTypes = [];

		var wt = service.dataService.cache.widgetTypes;

		widgetTypeTabGroupsWithWidgetTypes =
			service.dataService.cache.widgetTypeTabGroups
				.select((group: any) => {
					var newGroup: any = {};
					(newGroup.Id = group.Id),
						(newGroup.Name = group.Name),
						(newGroup.Ordinal = group.Ordinal),
						(newGroup.V2Compatible = group.V2Compatible),
						(newGroup.WidgetTypeTabGroup = group),
						(newGroup.WidgetTypes =
							service.dataService.cache.widgetTypes
								.where((wt: any) => {
									return wt.WidgetTypeTabGroupId == group.Id;
								})
								.select((wt: any) => {
									var newWidgetType = {
										Id: wt.Id,
										Name: wt.Name,
										DashboardTypeId: wt.DashboardTypeId,
										V2Compatible: wt.V2Compatible,
										WidgetType: wt,
										TrainingCourseLesson:
											service.dataService.cache.trainingCourseLessons.firstOrDefault(
												(lesson: any) => {
													return (
														lesson.WidgetTypeId ==
														wt.Id
													);
												}
											),
										Visible: false,
										IsAvailableToAll: wt.IsAvailableToAll,
									};
									return newWidgetType;
								})
								.select((wt: any) => {
									if (!Global.User.isAdmin) {
										if (

											Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(
												wt.Id
											) &&
											wt.V2Compatible === true
										) {
											wt.Visible = true;
										} else {
											wt.Visible = false;
										}
									} else {
										if (

											wt.V2Compatible === true
										) {
											wt.Visible = true;
										} else {
											wt.Visible = false;
										}
									}
									return wt;
								})
								.where((wt: any) => {
									return (
										wt.Visible == true &&
										wt.IsAvailableToAll == true
									);
								})
								.toArray());
					return newGroup;
				})

				.orderBy((g: any) => {
					return g.Ordinal;
				})
				.toArray();

		var widgetTypeGroupsFromDataCache = widgetTypeTabGroupsWithWidgetTypes
			.where((group: any) => {
				return (
					group.V2Compatible == true && group.WidgetTypes.length > 0
				);
			})
			.toArray();

		//-- changing the widget type groups and list of dashboard widgets to come from the data cache rather than the stored procedure...
		this.widgetTypeGroups = widgetTypeGroupsFromDataCache;
		this.widgetTypesInOneArray = [];
		if (Global.User.currentUser.OrganizationUsesGateSystemAssets == false) {
			//-- we're limiting the list of widget types to only Admin, the Fleet-specific ones, and eliminating the others.  This is the quickest way to hide the Gate System and Airport Site widgets.
			//-- if the user is in an organization that does use gate system assets, then we're not changing the widgetTypeGroups.  Leaving everything the same.  --Kirk T. Sherer, August 26, 2024. 
			var newGroups = this.widgetTypeGroups.where((group:any) => { return group.Id == 5 || group.Id == 8 || group.Id == 9 }).toArray();
			this.widgetTypeGroups = newGroups;
		}
		this.widgetTypeGroups.forEach((group: any) => {
			group.WidgetTypes.forEach((widgetType: any) => {
				if (Global.User.currentUser.OrganizationUsesGateSystemAssets == false) {
					if (widgetType.Name != "Sabel Data" ) {
						widgetType.Category = group.Name;
						widgetType.Description = widgetType.WidgetType.Description;
						this.widgetTypesInOneArray.push(widgetType);
					}
				}
				else {
					widgetType.Category = group.Name;
					widgetType.Description = widgetType.WidgetType.Description;
					this.widgetTypesInOneArray.push(widgetType);
				}
			});
		});
		console.log("add-widget-to-dashboard: accessible widget types = %O", this.widgetTypesInOneArray);

		this.isLoading = false;
	}

	loadVideo(widgetType: any) {
		var url =
			this.videoKeyURL +
			widgetType.TrainingCourseLesson.VideoImageKey +
			'.mp4' +
			'?accessToken=' +
			encodeURIComponent(this.accessToken);

		var intHSize = window.screen.availWidth * 0.7;
		var intVSize = window.screen.availHeight * 0.7;
		var intTop = (window.screen.availHeight - intVSize) / 2;
		var intLeft = (window.screen.availWidth - intHSize) / 2;
		var sngWindow_Size_Percentage = 0.85;

		var strFeatures = '';
		intHSize = window.screen.availWidth * sngWindow_Size_Percentage * 0.6;
		intVSize = window.screen.availHeight * sngWindow_Size_Percentage;
		intTop = (window.screen.availHeight - intVSize) / 2;
		intLeft = (window.screen.availWidth - intHSize) / 2;
		strFeatures =
			'top=' +
			intTop +
			',left=' +
			intLeft +
			',height=' +
			intVSize +
			',width=' +
			intHSize +
			', scrollbars=yes, resizable=yes, location=no';

		var SRWindow = window.open(url, 'SRWindow', strFeatures);
	}

	addWidgetToDashboard(widgetToAdd: any) {
		console.log(widgetToAdd);

		let clonedWidget = {
			AngularDirectiveName: widgetToAdd.WidgetType.AngularDirectiveName,
			CategoryPath: widgetToAdd.WidgetType.CategoryPath,
			CreationDate: widgetToAdd.WidgetType.CreationDate,
			DashboardTypeId: widgetToAdd.WidgetType.DashboardTypeId,
			DataTypeCode: widgetToAdd.WidgetType.DataTypeCode,
			DateCompleted: widgetToAdd.WidgetType.DateCompleted,
			Description: widgetToAdd.WidgetType.Description,
			DeveloperPersonId: widgetToAdd.WidgetType.DeveloperPersonId,
			DevelopmentPriority: widgetToAdd.WidgetType.DevelopmentPriority,
			EstimatedDateOfCompletion:
				widgetToAdd.WidgetType.EstimatedDateOfCompletion,
			HasSettings: widgetToAdd.WidgetType.HasSettings,
			InitialHeight: widgetToAdd.WidgetType.InitialHeight,
			InitialWidth: widgetToAdd.WidgetType.InitialWidth,
			IsAvailableToAdmin: widgetToAdd.WidgetType.IsAvailableToAdmin,
			IsAvailableToAll: widgetToAdd.WidgetType.IsAvailableToAll,
			IsHiddenSystemType: widgetToAdd.WidgetType.IsHiddenSystemType,
			Mnemonic: widgetToAdd.WidgetType.Mnemonic,
			Ordinal: widgetToAdd.WidgetType.Ordinal,
			WidgetTypeId: widgetToAdd.WidgetType.Id,
			WidgetTypeName: widgetToAdd.WidgetType.Name,
			WidgetTypeTabGroupId: widgetToAdd.WidgetType.WidgetTypeTabGroupId,
			WidgetTypeTabGroupName:
				widgetToAdd.WidgetType.WidgetTypeTabGroupName,
			WidgetTypeTabGroupOrdinal:
				widgetToAdd.WidgetType.WidgetTypeTabGroupOrdinal,
			WidgetTypeTabGroupV2Compatible:
				widgetToAdd.WidgetType.WidgetTypeTabGroupV2Compatible,
			WidgetTypeV2Compatible: widgetToAdd.WidgetType.V2Compatible,
		};

		this.widgetClicked.emit(clonedWidget);
		// console.log(this.widgetTypeGroups);
	}

	onCancel() {
		this.dialogRef.close();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
	}

	// 	AngularDirectiveName: "siteActiveAlarms"
	// CategoryPath: "Site"
	// CreationDate: null
	// DashboardTypeId: 1
	// DataTypeCode: null
	// DateCompleted: null
	// Description: null
	// DeveloperPersonId: null
	// DevelopmentPriority: 11
	// EstimatedDateOfCompletion: null
	// HasSettings: true
	// InitialHeight: 12
	// InitialWidth: 10
	// IsAvailableToAdmin: true
	// IsAvailableToAll: true
	// IsHiddenSystemType: false
	// Mnemonic: "SAA"
	// Ordinal: 0
	// WidgetTypeId: 51
	// WidgetTypeName: "Site Active Alarms"
	// WidgetTypeTabGroupId: 2
	// WidgetTypeTabGroupName: "Site Charts"
	// WidgetTypeTabGroupOrdinal: 2
	// WidgetTypeTabGroupV2Compatible: true
	// WidgetTypeV2Compatible: true
}
