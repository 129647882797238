import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetSettingsComponent } from './widget-settings.component';
import { MatMenuModule } from '@angular/material/menu';
import { WidgetSettingsPopupModule } from '../widget-settings-popup/widget-settings-popup.module';
import { MatButtonModule } from '@angular/material/button';
import { EditWidgetModule } from '../edit-widget/edit-widget.module';
import { WidgetIntervalSelectionPopupModule } from '../widget-interval-selection-popup/widget-interval-selection-popup.module';
import { WidgetTimeZoneSelectionPopupModule } from '../widget-time-zone-selection-popup/widget-time-zone-selection-popup.module';
import { WidgetTimeScopeSelectionPopupModule } from '../widget-time-scope-selection-popup/widget-time-scope-selection-popup.module';
import { TagGraphPopupSettingsModule } from '../tag-graph-popup-settings/tag-graph-popup-settings.module';
import { WidgetSplitPercentPopupModule } from '../widget-split-percent-popup/widget-split-percent-popup.module';

@NgModule({
	declarations: [WidgetSettingsComponent],
	imports: [
		CommonModule,
		MatMenuModule,
		WidgetSettingsPopupModule,
		WidgetIntervalSelectionPopupModule,
		WidgetTimeZoneSelectionPopupModule,
		WidgetTimeScopeSelectionPopupModule,
		TagGraphPopupSettingsModule,
		WidgetSplitPercentPopupModule,
		MatButtonModule,
		EditWidgetModule,
	],
	exports: [WidgetSettingsComponent],
})
export class WidgetSettingsModule {}
