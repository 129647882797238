import {
	Component,
	AfterViewInit,
	ViewChild,
	Optional,
	Input,
	EventEmitter,
	TemplateRef,
	ViewContainerRef,
	ViewEncapsulation,
	ChangeDetectorRef,
	NgZone,
	OnDestroy,
	HostListener,
	ElementRef,
	ViewChildren,
	QueryList,
	OnInit,
} from "@angular/core";
import {
	MAT_DIALOG_DATA,
	MatDialogRef,
	MatDialog,
} from "@angular/material/dialog";

import { Subscription, fromEvent } from "rxjs";

import { filter, take } from "rxjs/operators";

import _ from "lodash";
//For context-menu
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { process, State } from "@progress/kendo-data-query";
import {
	GridDataResult,
	PageChangeEvent,
	RowClassArgs,
} from "@progress/kendo-angular-grid";
import { GridSettings } from "../../_models/grid-settings.interface";

import Swal from "sweetalert2";

import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { DropdownQuestion } from "../../_models/dynamic-fields/questions/question-dropdown";

import { TextareaQuestion } from "../../_models/dynamic-fields/questions/question-textarea";
import { FormGroup, FormControl } from "@angular/forms";

import { NumberQuestion } from "../../_models/dynamic-fields/questions/question-number";
import { Global } from "../../_constants/global.variables";
import { IGlobal } from "../../_models/global.model";
import { DataService } from "../../services/data.service";
import { CacheUtilityService } from "../../services/cache-utility.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DashboardService } from "../../services/dashboard.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { GseService } from "../../services/gse.service";
import { UtilityService } from "../../services/utility.service";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import * as $ from "jquery";
import { FileUploadService } from "../../services/file-upload.service";
import {
	CompactType,
	DisplayGrid,
	GridsterConfig,
	GridsterItem,
	GridsterItemComponentInterface,
	GridType,
} from "angular-gridster2";
declare const google: any;

import { groupBy } from "@progress/kendo-data-query";
import { LastThousandGridComponent } from "../last-thousand-grid/last-thousand-grid.component";
import { ActivatedRoute } from "@angular/router";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ITag } from "../../_models/tag.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { BreadcrumbsComponent } from "../breadcrumbs/breadcrumbs.component";
import { MapsService } from "../../services/maps.service";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	selector: "lib-asset-summary-parent",
	templateUrl: "./asset-summary-parent.component.html",
	styleUrls: ["./asset-summary-parent.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AssetSummaryParentComponent
	implements AfterViewInit, OnInit, OnDestroy
{
	@ViewChild("breadcrumbsChild") breadcrumbsChild: BreadcrumbsComponent;
	public assetModelId: any;
	@ViewChild("tagMenu") tagMenu: TemplateRef<any>;
	@ViewChild("dataGrid") dataGrid: KendoGridParentComponent;
	@ViewChild("alarmsGrid") alarmsGrid: KendoGridParentComponent;
	@ViewChild("alertsGrid") alertsGrid: KendoGridParentComponent;
	@ViewChild("infoGrid") infoGrid: KendoGridParentComponent;
	@ViewChild("last1000EventsGrid") last1000EventsGrid;
	@ViewChild("last1000AlarmsGrid") last1000AlarmsGrid;
	@ViewChild("last1000AlertsGrid") last1000AlertsGrid;
	@ViewChild("deicerEventsGrid") deicerEventsGrid: KendoGridParentComponent;
	@ViewChild("fuelEventsGrid") fuelEventsGrid: KendoGridParentComponent;
	@ViewChild("gseSummaryGenericMapChild") gseSummaryGenericMapChild: any;

	public options: GridsterConfig;
	tagDataRowHeight: any = 43;
	eventDataRowHeight: any = 26;
	tagDataPageSize: number;
	eventDataPageSize: number;
	//If it is being instantiated from gse-overview modal passthrough
	@Optional() @Input() dataObject: any;
	//If it is being instantiated from dashboard
	@Optional() @Input() widgetObject: any;
	@Input() private gseSummaryCreatedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;

	// @Optional() @Input() searchValue: string;
	displayedColumns: string[] = [
		"ChartButton",
		"Name",
		"DateInMilliseconds",
		"Value",
	];
	materialTabSelected = 0;
	tableData: GridDataResult;
	isFullScreen = false;
	hasBeenRedrawn = false;
	isDataLoading: boolean;
	searchboxDisabled: boolean;
	timeZoneType: any;
	siteUTCTimeOffset: Number;
	getGSESummaryByAssetIdSub: Subscription;
	newHeight: number;
	newWidth: number;
	oldHeight: number;
	oldWidth: number;
	canvasTemplates: Array<any>;
	differ: any;
	testArray: Array<Object>;
	originalDataArray: any;
	dataModified$: any;
	fullDataCacheSubscription: any;
	signalRSubscription: Subscription;
	gseSummaryCreatedSubscription: Subscription;
	tagGraphSingleModalSubscription: Subscription;
	colorChangedSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	dashboardTimeScopeChangedSubscription: any;
	routeSubscription: Subscription;
	theme: string;
	parentContainerSize: any;
	overlayRef: OverlayRef | null;
	sub: Subscription;
	updateDataInterval: NodeJS.Timeout;
	assetHasCanvasTemplates: boolean = false;
	assetOverviewTagGraphAssetObj: any;
	assetOverviewTagGraphAssetObj1: any;
	assetOverviewObj: any;
	assetOverviewGSETagObj: any;
	//-- Deicer event fields --
	public assetIsDeicer: boolean = false;
	public deicerQuestions: QuestionBase<any>[];
	public fuelQuestions: QuestionBase<any>[];

	public editHeader: string;
	public editSubHeader: string;
	public siteId: number;
	public assetId: number;
	public assetObject: any;

	public currentDeicerEventId: number;
	public deicerFields: QuestionBase<any>[];
	public deicerDynamicFieldDataExists: boolean = false;
	public loadingDeicerForm: boolean;
	public widgetInstance: number = Math.random();

	private checkAlarmNotificationsInterval;

	public deicerFormOptions: any = {
		submitButtonText: "Start Deicing",
		submitButtonClass: "btn-success",
		submitButtonTitle: "Press this button to start the deicing event.",
		saveValuesPerField: false,
		clearFieldsAfterSubmit: false,
		saveStoredProcedureName: "API.GSE_DeicerEvent",
	};
	//-- end Deicer event fields --

	//-- Fuel event fields --
	public fuelFields: QuestionBase<any>[];
	public fuelDynamicFieldDataExists: boolean = false;
	public loadingFuelForm: boolean;
	public fuelFormOptions: any = {
		submitButtonText: "Save Entry",
		submitButtonClass: "btn-success",
		submitButtonTitle: "Press this button to save the fuel entry.",
		saveValuesPerField: false,
		clearFieldsAfterSubmit: true,
		saveStoredProcedureName: "API.GSE_FuelEvent",
	};
	//-- end Fuel event fields --

	//-- Maintenance Mode / Out of Service fields

	public assetObj: any;
	public loadingMaintenanceForm: boolean = false;
	public maintenanceHeader: string = "Maintenance";
	public maintenanceSubHeader: string =
		"Please enter the fields listed for this maintenance event.";
	public maintenanceFields: Array<any>;
	public maintenanceFormOptions: any = {
		submitButtonText: "",
		saveValuesPerField: true,
		saveStoredProcedureName:
			"API.Asset_UpdateMaintenanceModeOrOutOfService",
	};

	public maintenanceForm: FormGroup;
	private maintenanceMode: FormControl;
	private outOfService: FormControl;
	private outOfServiceReasonId: FormControl;
	private maintenanceModeReasonId: FormControl;

	public maintenanceModeReasons: Array<any>;
	public outOfServiceReasons: Array<any>;
	private fullAssetRecord: any;
	public global: IGlobal = Global;
	public mapHeight: number;
	public mapWidth: number;
	widgetIsInViewSubscription: Subscription;
	isWidgetInView: boolean = false;
	public countOfAlarms: number = 0;
	public countOfAlerts: number = 0;
	private componentName: string = "asset-summary-parent: ";

	public widgetTabs = [
		{ textLabel: "Data", rendered: true },
		{ textLabel: "Map", rendered: false },
		{ textLabel: "Playback", rendered: false },
		{ textLabel: "Faults", rendered: false },
		{ textLabel: "Alerts", rendered: false },
		{ textLabel: "Last 1000 Events", rendered: false },
		{ textLabel: "Historic Faults", rendered: false },
		{ textLabel: "Historic Alerts", rendered: false },
		{ textLabel: "Fuel", rendered: false },
		{ textLabel: "Maintenance", rendered: false },
		{ textLabel: "Information", rendered: false },
	];

	public kendoGrids = {
		dataGrid: {
			data: [],
			gridSettings: {
				groupable: false,
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 75,
						hidden: false,
					},
					{
						field: "JBTStandardObservation.Name",
						title: "Name",
						_width: 150,
						filterable: true,
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100,
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "Value",
						title: "Value",
						_width: 50,
						filterable: true,
					},
					{
						field: "Favorite",
						title: "Favorite",
						filterable: true,
						filter: "boolean",
						_width: 50,
					},
					{
						field: "AlarmReason",
						title: "Fault Reason",
						filterable: false,
						_width: 100,
					},
				],
			} as GridSettings,
		},
		alarmsGrid: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "JBTStandardObservation.Name",
						title: "Name",
						filterable: true,
						_width: 200,
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						_width: 50,
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 150,
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						_width: 50,
					},
					{
						field: "ValueWhenActive",
						title: "Value When Active",
						filterable: true,
						_width: 75,
					},
					{
						field: "AlarmReason",
						title: "Fault Reason",
						filterable: false,
						_width: 100,
					},
				],
			} as GridSettings,
			config: {
				gseOnly: true,
			},
		},
		alertsGrid: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "JBTStandardObservation.Name",
						title: "Name",
						filterable: true,
						_width: 200,
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 150,
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						_width: 50,
					},
					{
						field: "ValueWhenActive",
						title: "Value When Active",
						filterable: true,
						_width: 75,
					},
					{
						field: "AlarmReason",
						title: "Fault Reason",
						filterable: false,
						_width: 100,
					},
				],
			} as GridSettings,
			config: {
				gseOnly: true,
			},
		},
		deicerEventsGrid: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "TailNumber",
						title: "Tail Number",
						filterable: true,
						_width: 50,
					},
					{
						field: "FluidType",
						title: "Fluid Type",
						filterable: true,
						_width: 150,
					},
					{
						field: "StartDateLocal",
						title: "Start Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "StartDateSite",
						title: "Start Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "StartDateUTC",
						title: "Start Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "StopDateLocal",
						title: "Stop Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "StopDateSite",
						title: "Stop Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "StopDateUTC",
						title: "Stop Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "Notes",
						title: "Notes",
						filterable: true,
						_width: 150,
					},
					{
						field: "CreatorUser",
						title: "Creator User",
						filterable: true,
						_width: 150,
					},
					{
						field: "LastModifiedUser",
						title: "Last Modified User",
						filterable: true,
						_width: 150,
					},
					{
						field: "LastModifiedDateLocal",
						title: "Last Modified Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "LastModifiedDateSite",
						title: "Last Modified Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "LastModifiedDateUTC",
						title: "Last Modified Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
				],
			} as GridSettings,
		},
		fuelEventsGrid: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "Quantity",
						title: "Quantity",
						filterable: true,
						_width: 50,
					},
					{
						field: "DateLocal",
						title: "Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "DateSite",
						title: "Date",
						hidden: true,
						includeInChooser: false,
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "DateUTC",
						title: "Date",
						hidden: true,
						includeInChooser: false,
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "CreatorUser",
						title: "Creator User",
						filterable: true,
						_width: 150,
					},
					{
						field: "LastModifiedUser",
						title: "Last Modified User",
						filterable: true,
						_width: 150,
					},
					{
						field: "LastModifiedDateLocal",
						title: "Last Modified Date",
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "LastModifiedDateSite",
						title: "Last Modified Date",
						hidden: true,
						includeInChooser: false,
						filterable: true,
						filter: "date",
						_width: 150,
					},
					{
						field: "LastModifiedDateUTC",
						title: "Last Modified Date",
						filterable: true,
						includeInChooser: false,
						filter: "date",
						_width: 150,
					},
				],
			} as GridSettings,
		},
		last1000Events: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100,
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 70,
						_width: 70,
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100,
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						_width: 100,
					},
				],
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				alarmsOnly: false,
				gridInView: false,
				exportTitle: "",
				gridSettings: {
					state: {
						skip: 0,
						filter: {
							logic: "and",
							filters: [],
						},
						take: 15,
					},
					columnsConfig: [
						{
							field: "Name",
							title: "Name",
							filterable: true,
							_width: 100,
						},
						{
							field: "Severity",
							title: "Severity",
							filterable: true,
							minResizableWidth: 70,
							_width: 70,
						},
						{
							field: "JavascriptDate",
							title: "Date",
							filterable: true,
							filter: "date",
							_width: 100,
						},
						{
							field: "Value",
							title: "Value",
							filterable: true,
							_width: 100,
						},
					],
				} as GridSettings,
			},
		},
		last1000Alarms: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 200,
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 50,
						_width: 50,
					},
					{
						field: "JavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 125,
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 125,
					},
					{
						field: "UTCJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 125,
					},
					{
						field: "JavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100,
					},
					{
						field: "SiteLocalJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "UTCJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						filter: "numeric",
						_width: 0,
					},
					{
						field: "Duration",
						title: "Duration (secs)",
						filterable: true,
						_width: 75,
					},
					{
						field: "DurationinMins",
						title: "Duration (mins)",
						filterable: true,
						_width: 75,
					},
				],
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				currentAlarms: undefined,
				alarmsOnly: true,
				gseOnly: true,
				gridInView: false,
				exportTitle: "",
			},
		},
		last1000Alerts: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 200,
					},
					{
						field: "JavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 125,
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 125,
					},
					{
						field: "UTCJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 125,
					},
					{
						field: "JavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100,
					},
					{
						field: "SiteLocalJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "UTCJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100,
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						filter: "numeric",
						_width: 0,
					},
					{
						field: "Duration",
						title: "Duration (secs)",
						filterable: true,
						_width: 75,
					},
					{
						field: "DurationinMins",
						title: "Duration (mins)",
						filterable: true,
						_width: 75,
					},
				],
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				currentAlarms: undefined,
				alarmsOnly: true,
				gseOnly: true,
				alertsOnly: true,
				gridInView: false,
				exportTitle: "",
			},
		},
		infoGrid: {
			data: [],
			gridSettings: {
				groupable: false,
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: [],
					},
					take: 15,
				},
				columnsConfig: [
					{
						field: "title",
						title: "Title",
						_width: 150,
						filterable: true,
					},
					{
						field: "value",
						title: "Value",
						_width: 150,
						filterable: true,
					},
				],
			} as GridSettings,
		},
	};
	componentHasBeenInitialized: boolean = false;
	pageSize: number;
	swalWithBootstrapButtons: any;
	map: any;
	marker: any;
	signalRTagUpdateSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;

	assetTagIds: any;
	last1000Events: any;
	widgetGrids: any[];
	showSpinner: boolean;
	public skip = 0;
	public gridView: GridDataResult;
	selectedMatTabLabel: string;
	alertStatus: any;
	internalWidgetObject: any;
	fileImageLibraryControlObject: any;
	public countOfFilesUploaded: number = 0;
	fromDashboard: boolean;
	noPositionForAsset: boolean = false;
	canvasWidgetsLoaded: boolean = false;
	assetTemplateHasNoWidgetsMessage: boolean = true;
	punchoutTimeZoneChangedSubscription: Subscription;
	public gridContextMenuItems = ["Quick Trend of Tag"];
	public gridContextMenuItemsAlarms = [
		"Quick Trend of Tag",
		"Trend Using Playback - 5 Min Before",
		"Trend Using Playback - 2 Hr Before",
	];
	public guid: string;
	timeoutToExpire: NodeJS.Timeout;
	childMapReadyToLoad: boolean;
	tagOptions: {
		tagName: string;
		standardObservationId: number;
		percent?: boolean;
	}[];
	assetListForUserSites: any[];
	fleetId: any;

	constructor(
		public overlay: Overlay,
		public viewContainerRef: ViewContainerRef,
		public dataService: DataService,
		private cacheUtilityService: CacheUtilityService,
		@Optional() public dialogRef: MatDialogRef<AssetSummaryParentComponent>,
		private signalRCore: SignalRCoreService,
		public dialog: MatDialog,
		private ref: ChangeDetectorRef,
		zone: NgZone,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private gseService: GseService,
		private utilityService: UtilityService,
		private fileUpload: FileUploadService,
		private route: ActivatedRoute,
		private mapsService: MapsService
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger",
			},
			buttonsStyling: false,
		});
		zone.runOutsideAngular(() => {
			this.updateDataInterval = setInterval(() => {
				// Global.User.DebugMode && console.log("this.gridSettings = %O", this.gridSettings);
				this.evaluateRedraw();
				this.ref.detectChanges();
			}, 1000);
		});
	}

	ngOnInit(): void {
		this.guid = this.dataService.guid();
	}

	faultsData() {
		return this.dataService.cache.assetsObject[
			this.assetId
		].ActiveAlarmTags.filter((t) => t.EffectiveSeverityLevelId != 5);
	}
	alertsData() {
		return this.dataService.cache.assetsObject[
			this.assetId
		].ActiveAlarmTags.filter((t) => t.EffectiveSeverityLevelId == 5);
	}

	saveGridSettings() {
		if (this.widgetObject !== undefined) {
			let hiddenColsArray = [];
			let gridObjectsArray = [];
			if (this.dataGrid) {
				hiddenColsArray.push(
					this.dataGrid.kendoGridParent.columnList.columns._results
				);
				gridObjectsArray.push({
					gridName: "dataGrid",
					gridObject: this.dataGrid.kendoGridParent,
					gridState: this.kendoGrids.dataGrid.gridSettings.state,
				});
			}

			if (this.alarmsGrid) {
				hiddenColsArray.push(
					this.alarmsGrid.kendoGridParent.columnList.columns._results
				);
				gridObjectsArray.push({
					gridName: "alarmsGrid",
					gridObject: this.alarmsGrid.kendoGridParent,
					gridState: this.kendoGrids.alarmsGrid.gridSettings.state,
				});
			}

			if (this.alertsGrid) {
				hiddenColsArray.push(
					this.alertsGrid.kendoGridParent.columnList.columns._results
				);
				gridObjectsArray.push({
					gridName: "alertsGrid",
					gridObject: this.alertsGrid.kendoGridParent,
					gridState: this.kendoGrids.alertsGrid.gridSettings.state,
				});
			}

			if (this.fuelEventsGrid) {
				hiddenColsArray.push(
					this.fuelEventsGrid.kendoGridParent.columnList.columns
						._results
				);
				gridObjectsArray.push({
					gridName: "fuelEventsGrid",
					gridObject: this.fuelEventsGrid.kendoGridParent,
					gridState:
						this.kendoGrids.fuelEventsGrid.gridSettings.state,
				});
			}

			if (this.deicerEventsGrid) {
				hiddenColsArray.push(
					this.deicerEventsGrid.kendoGridParent.columnList.columns
						._results
				);
				gridObjectsArray.push({
					gridName: "deicerEventsGrid",
					gridObject: this.deicerEventsGrid.kendoGridParent,
					gridState:
						this.kendoGrids.deicerEventsGrid.gridSettings.state,
				});
			}

			if (
				this.last1000EventsGrid &&
				this.last1000EventsGrid?.tagDataGrid.kendoGridParent
			) {
				hiddenColsArray.push(
					this.last1000EventsGrid.tagDataGrid.kendoGridParent
						.columnList.columns._results
				);
				gridObjectsArray.push({
					gridName: "last1000EventsGrid",
					gridObject:
						this.last1000EventsGrid.tagDataGrid.kendoGridParent,
					gridState:
						this.kendoGrids.last1000Events.gridSettings.state,
				});
			}
			if (
				this.last1000AlarmsGrid &&
				this.last1000AlarmsGrid?.tagDataGrid.kendoGridParent
			) {
				hiddenColsArray.push(
					this.last1000AlarmsGrid.tagDataGrid.kendoGridParent
						.columnList.columns._results
				);
				gridObjectsArray.push({
					gridName: "last1000AlarmsGrid",
					gridObject:
						this.last1000AlarmsGrid.tagDataGrid.kendoGridParent,
					gridState:
						this.kendoGrids.last1000Alarms.gridSettings.state,
				});
			}
			if (
				this.last1000AlertsGrid &&
				this.last1000AlertsGrid?.tagDataGrid.kendoGridParent
			) {
				hiddenColsArray.push(
					this.last1000AlertsGrid.tagDataGrid.kendoGridParent
						.columnList.columns._results
				);
				gridObjectsArray.push({
					gridName: "last1000AlertsGrid",
					gridObject:
						this.last1000AlertsGrid.tagDataGrid.kendoGridParent,
					gridState:
						this.kendoGrids.last1000Alerts.gridSettings.state,
				});
			}

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				hiddenColsArray,
				this.widgetObject.WidgetId
			);
			this.widgetObject.WidgetKendoUIJson = this.kendoSettingsService
				.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId)
				.then((data: any) => {
					console.log(data);
					this.widgetObject.WidgetKendoUIJson = data;
				});
		}
	}

	public onSelect({ dataItem, item }): void {
		if (item === "Trend Single Tag") {
			Global.User.DebugMode && console.log("dataItem = %O", dataItem);
			this.openTagGraphSingle(dataItem.TagId);
			Global.User.DebugMode && console.log("item = %O", item);
		}
	}

	open(event: MouseEvent, user) {
		let x = event.x;
		let y = event.y;
		event.preventDefault();
		this.close();
		const positionStrategy = this.overlay
			.position()
			.flexibleConnectedTo({ x, y })
			.withPositions([
				{
					originX: "end",
					originY: "bottom",
					overlayX: "end",
					overlayY: "top",
				},
			]);

		this.overlayRef = this.overlay.create({
			positionStrategy,
			scrollStrategy: this.overlay.scrollStrategies.close(),
		});

		this.overlayRef.attach(
			new TemplatePortal(this.tagMenu, this.viewContainerRef, {
				$implicit: user,
			})
		);

		this.sub = fromEvent<MouseEvent>(document, "click")
			.pipe(
				filter((event) => {
					const clickTarget = event.target as HTMLElement;
					return (
						!!this.overlayRef &&
						!this.overlayRef.overlayElement.contains(clickTarget)
					);
				}),
				take(1)
			)
			.subscribe(() => this.close());
	}
	close() {
		this.sub && this.sub.unsubscribe();
		if (this.overlayRef) {
			this.overlayRef.dispose();
			this.overlayRef = null;
		}
	}

	ngAfterViewInit() {
		if (Global.User.DebugMode) {
			this.kendoGrids.dataGrid.gridSettings.columnsConfig.push({
				field: "JBTStandardObservationId",
				title: "JBTStandardObservationId",
				filterable: true,
				hidden: true,
				_width: 50,
			});
			this.kendoGrids.dataGrid.gridSettings.columnsConfig.push({
				field: "TagId",
				title: "TagId",
				filterable: true,
				hidden: true,
				_width: 50,
			});
		}
		this.mapsService.loadGoogleMaps().then(() => {
			this.options = {
				gridType: GridType.Fit,
				displayGrid: DisplayGrid.Always,
				pushItems: true,
			};

			if (
				this.widgetObject == undefined &&
				this.dataObject == undefined
			) {
				this.routeSubscription = this.route.params.subscribe(
					(params) => {
						console.log(params);
						if (params.id) {
							if (this.componentHasBeenInitialized) {
								this.initialize();
							}

							// this.router.navigate(['layout/assets', params.id]);
						}
					}
				);
			}
			this.componentHasBeenInitialized = true;

			if (this.gseSummaryCreatedEvent) {
				this.gseSummaryCreatedSubscription =
					this.gseSummaryCreatedEvent.subscribe((data) => {
						if (data.WidgetId === this.widgetObject.WidgetId) {
							this.initialize();
						}
					});
			}
			if (!Global.FullDataCacheExists) {
				this.fullDataCacheSubscription =
					this.dataService.fullDataCacheExists$.subscribe(
						(data: any) => {
							if (data === true) {
								this.initialize();
								this.fullDataCacheSubscription.unsubscribe();
							}
						}
					);
			} else {
				if (Global.Theme === "light") {
					this.theme = "light";
				} else if (Global.Theme === "dark") {
					this.theme = "dark";
				}
				Global.User.DebugMode &&
					console.log(
						"tag-graph: going to initialize since cache exists..."
					);
				this.initialize();
			}
		});

		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						Global.User.DebugMode &&
							console.log("Widget Time Scope Changed");
						this.loadHistoricFaultsAndAlerts();
					}
				});
		}
	}

	itemInit(item: GridsterItem) {}

	finishInitializeGrids() {
		let alarmColumn: any = {
			field: "alertNotification",
			title: "Fault",
			filterable: true,
			filter: "boolean",
			_width: 40,
			hidden: false,
			minResizableWidth: 40,
		};
		this.kendoGrids.dataGrid.gridSettings.columnsConfig.push(alarmColumn);
	}

	initialize() {
		if (this.widgetObject === undefined && this.dataObject === undefined) {
			this.assetHasCanvasTemplates = false;
			this.assetId = parseInt(this.route.snapshot.paramMap.get("id"));
			this.assetObject =
				this.dataService.cache.assetsObject[this.assetId];
			const cacheAssetObj =
				this.dataService.cache.assetsObject[this.assetId];

			//Find Templates that have no AssetTypeId and AssetModelId and AssetId associated
			this.canvasTemplates =
				this.dataService.cache.canvasTemplates.filter((p: any) => {
					return (
						p.CanvasTemplateTypeId == 1 &&
						p.AssetTypeId == 0 &&
						p.AssetModelId == 0 &&
						p.AssetId == 0
					);
				});
			//Find templates associated with the AssetType
			let filterCanvasTemplatesByAssetType =
				this.dataService.cache.canvasTemplates.filter((p: any) => {
					return (
						p.CanvasTemplateTypeId == 1 &&
						p.AssetTypeId != 0 &&
						p.AssetTypeId == cacheAssetObj.AssetTypeId &&
						(p.AssetModelId == 0 ||
							p.AssetModelId == cacheAssetObj.AssetModelId) &&
						(p.AssetId == 0 || p.AssetId == cacheAssetObj.Id)
					);
				});
			if (filterCanvasTemplatesByAssetType.length > 0)
				for (const template of filterCanvasTemplatesByAssetType)
					this.canvasTemplates.push(template);

			//Find templates associated with the AssetModel
			let filterCanvasTemplatesByAssetModel =
				this.dataService.cache.canvasTemplates.filter((p: any) => {
					return (
						p.CanvasTemplateTypeId == 1 &&
						p.AssetModelId != 0 &&
						p.AssetModelId == cacheAssetObj.AssetModelId &&
						(p.AssetTypeId == 0 ||
							p.AssetTypeId == cacheAssetObj.AssetTypeId) &&
						(p.AssetId == 0 || p.AssetId == cacheAssetObj.Id)
					);
				});
			if (filterCanvasTemplatesByAssetModel.length > 0)
				for (const template of filterCanvasTemplatesByAssetModel)
					this.canvasTemplates.push(template);

			//Find templates associated with the AssetId
			let filterCanvasTemplatesByAssetId =
				this.dataService.cache.canvasTemplates.filter((p: any) => {
					return (
						p.CanvasTemplateTypeId == 1 &&
						p.AssetId == cacheAssetObj.Id &&
						(p.AssetModelId == 0 ||
							p.AssetModelId == cacheAssetObj.AssetModelId) &&
						(p.AssetTypeId == 0 ||
							p.AssetTypeId == cacheAssetObj.AssetTypeId)
					);
				});
			if (filterCanvasTemplatesByAssetId.length > 0)
				for (const template of filterCanvasTemplatesByAssetId)
					this.canvasTemplates.push(template);

			if (this.canvasTemplates.length > 0) {
				this.assetHasCanvasTemplates = true;
				for (const template of this.canvasTemplates) {
					let canvasWidgets = new Array();
					canvasWidgets =
						this.dataService.cache.canvasWidgetTemplates.filter(
							(p: any) => {
								return (
									p.CanvasTemplateId == parseInt(template.Id)
								);
							}
						);
					if (canvasWidgets.length > 0) {
						for (const widget of canvasWidgets) {
							widget.Id = widget.Id;
							widget.WidgetId = widget.Id;
							widget.WidgetAssetId = this.assetId;
							widget.SiteList = cacheAssetObj.SiteId.toString();
							widget.cols = widget.Width;
							widget.rows = widget.Height;
							widget.x = widget.Col;
							widget.y = widget.Row;
							//if CanvasWidgetType of type TagGraph,
							if (
								widget.CanvasWidgetTypeId == 28 ||
								widget.CanvasWidgetTypeId == 29
							) {
								if (widget.CanvasWidgetTypeId == 29)
									widget.IsSimpleTagGraph = true;
								let tagSeries =
									this.dataService.cache.canvasWidgetTemplateJBTStandardObservations.filter(
										(p: any) => {
											return (
												p.CanvasWidgetTemplateId ==
												parseInt(widget.Id)
											);
										}
									);
								if (tagSeries.length > 0)
									widget.canvasWidgetHasTagSeries = true;
								else widget.canvasWidgetHasTagSeries = false;
							}
						}
						template.assetTemplateHasNoWidgetsMessage = false;
						template.canvasWidgets = canvasWidgets;
					} else {
						template.assetTemplateHasNoWidgetsMessage = true;
					}
				}
				this.canvasTemplates.forEach((t: any) => {
					if (t.canvasWidgets?.length > 0) {
						t.canvasWidgets.forEach((cw: any) => {
							if (
								cw.AngularDirectiveName == "gseActiveAlarms" ||
								cw.AngularDirectiveName == "tagGraph" ||
								cw.AngularDirectiveName == "tagGraphSimple"
							)
								this.dashboardService.canvasWidgetsOnPunchOutScreenWithTimeZoneTimeScope.push(
									cw
								);
						});
					}
				});
				this.canvasTemplates.sort(
					(a, b) =>
						b.CanvasTemplateTabRanking - a.CanvasTemplateTabRanking
				);
			}
		}

		this.finishInitializeGrids();

		const id = this.route.snapshot.paramMap.get("id");
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		//setting to true here so that the form on the template doesn't exist before we build the formgroup in the logic to populate the html form, fixing an error that was occurring.
		this.loadingMaintenanceForm = true;
		this.loadingDeicerForm = true;
		this.loadingFuelForm = true;

		this.selectedMatTabLabel = this.widgetTabs[0].textLabel;

		if (Global.Theme === "light") {
			this.theme = "light";
		} else if (Global.Theme === "dark") {
			this.theme = "dark";
		}

		if (this.dataObject !== undefined) {
			this.isWidgetInView = true;

			this.assetId = this.dataObject.widgetObject.Id;
			this.assetObject =
				this.dataService.cache.assetsObject[this.assetId];
			this.assetIsDeicer =
				this.dataService.cache.assetsObject[this.assetId]
					?.AssetTypeId === 86293;

			if (this.assetId !== undefined) {
				this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
						this.assetId
					)
					.subscribe((data) => {
						Global.User.DebugMode &&
							console.log(
								this.componentName +
									"dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory data = %O",
								data
							);
						if (data === null) {
							this.swalWithBootstrapButtons
								.fire({
									title: "Notice: ",
									text: "No tags for this asset in the last 60 days.",
									showCancelButton: false,
									confirmButtonText: "Ok",
									reverseButtons: false,
								})
								.then(() => {});
						} else {
							this.assetTagIds = data.map((t) => t.Id);
							Global.User.DebugMode &&
								console.log(
									this.componentName + "data = %O",
									data
								);
						}

						this.getSignalRUpdates();
						this.finishInitializingComponent("Popup");
					});
			}

			this.searchboxDisabled = true;
			this.isDataLoading = true;

			this.intializeDeicer();

			if (this.dataObject.shouldShowMap) {
				this.materialTabSelected = 1;
				setTimeout(() => {
					this.evaluateRedraw();
				}, 1000);
				this.searchboxDisabled = true;
			} else if (
				(this.dataObject?.breadcrumbTags &&
					this.dataObject.breadcrumbTags.length > 0) ||
				this.dataObject?.shouldShowPlayback
			) {
				this.materialTabSelected = 2;
				this.selectedMatTabLabel = "Playback";
				this.searchboxDisabled = true;
			} else {
				this.materialTabSelected = 0;
				this.searchboxDisabled = false;
			}

			this.ref.detectChanges();
		} else if (this.widgetObject !== undefined) {
			if (
				_.isNil(this.widgetObject.WidgetAssetId) &&
				_.isNil(this.widgetObject.VocationalSettingsJSON)
			) {
				this.isDataLoading = false;
				return;
			}

			if (!_.isNil(this.widgetObject.VocationalSettingsJSON)) {
				// filter just the assets for this Fleet
				var VocationalSettings = JSON.parse(
					this.widgetObject.VocationalSettingsJSON
				);
				Global.User.DebugMode &&
					console.log("VocationalSettings ", VocationalSettings);

				let fleetId = VocationalSettings.id;

				this.widgetObject.WidgetAssetId = VocationalSettings.child.id;

				if (_.isNil(this.widgetObject.WidgetAssetId)) {
					this.isDataLoading = false;
					return;
				}

				this.widgetObject.WidgetAssetName =
					this.dataService.cache.assetsObject[
						this.widgetObject.WidgetAssetId
					].Name;

				this.dataService.GetFleets().subscribe((data: any) => {
					if (data.length > 0) {
						let myFleet = data.find((fleet) => {
							return fleet.FleetId === fleetId;
						});

						this.widgetObject.FleetId = myFleet?.FleetId;
						this.widgetObject.FleetName = myFleet?.FleetName;
					}
				});
			}

			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription =
				this.dashboardService.widgetInViewChanged$.subscribe(
					(data: any) => {
						if (data.widgetId === this.widgetObject.WidgetId) {
							this.isWidgetInView = data.isInView;
						}
					}
				);

			this.assetId = +this.widgetObject.WidgetAssetId;
			this.assetObject =
				this.dataService.cache.assetsObject[this.assetId];
			this.assetIsDeicer =
				this.dataService.cache.assetsObject[this.assetId]
					?.AssetTypeId === 86293;
			Global.User.DebugMode &&
				console.log(
					"asset-summary-parent: this.assetObject = %O",
					this.assetObject
				);

			this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
					this.assetId
				)
				.subscribe((data: any) => {
					if (data === null) {
						//message in grid change to say no tags in last 60 days
					} else {
						this.assetTagIds = data.map((t) => t.Id);
					}
					let assetObjectInCache =
						this.dataService.cache.assetsObject[this.assetId];
					this.widgetGroupSettings = {
						WidgetId: this.widgetObject.WidgetId,
						GroupList: "PS" + assetObjectInCache.ParentSystemId,
						IsPopup: false,
					};
					// this.signalRCore.joinParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
					// this.timerId = setInterval(() => this.evaluateRedraw(), 300);

					if (this.widgetObject.WidgetKendoUIJson) {
						let gridColumnConfigs = [];
						let jsonDataObjectParsed =
							this.kendoSettingsService.parseReturnedSettingsForDates(
								this.widgetObject.WidgetKendoUIJson
							);
						let dataGridSettings = jsonDataObjectParsed.find(
							(grid) => grid.name == "dataGrid"
						);
						let alarmsGridSettings = jsonDataObjectParsed.find(
							(grid) => grid.name == "alarmsGrid"
						);
						let alertsGridSettings = jsonDataObjectParsed.find(
							(grid) => grid.name == "alertsGrid"
						);
						let fuelEventsGridSettings = jsonDataObjectParsed.find(
							(grid) => grid.name == "fuelEventsGrid"
						);
						let deicerEventsGridSettings =
							jsonDataObjectParsed.find(
								(grid) => grid.name == "deicerEventsGrid"
							);
						let last1000EventsGridSettings =
							jsonDataObjectParsed.find(
								(grid) => grid.name == "last1000EventsGrid"
							);
						let last1000AlarmsGridSettings =
							jsonDataObjectParsed.find(
								(grid) => grid.name == "last1000AlarmsGrid"
							);
						let last1000AlertsGridSettings =
							jsonDataObjectParsed.find(
								(grid) => grid.name == "last1000AlertsGrid"
							);
						if (dataGridSettings) {
							let mergedTemplateAndSavedColumnsForData =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.kendoGrids.dataGrid.gridSettings,
									dataGridSettings
								);
							this.kendoGrids.dataGrid.gridSettings =
								this.mapGridSettings(
									mergedTemplateAndSavedColumnsForData
								);
							gridColumnConfigs.push(
								this.kendoGrids.dataGrid.gridSettings
									.columnsConfig
							);
						}

						if (this.widgetObject.WidgetKendoUIJson) {
							let gridColumnConfigs = [];
							let jsonDataObjectParsed =
								this.kendoSettingsService.parseReturnedSettingsForDates(
									this.widgetObject.WidgetKendoUIJson
								);
							let dataGridSettings = jsonDataObjectParsed.find(
								(grid) => grid.name == "dataGrid"
							);
							let alarmsGridSettings = jsonDataObjectParsed.find(
								(grid) => grid.name == "alarmsGrid"
							);
							let alertsGridSettings = jsonDataObjectParsed.find(
								(grid) => grid.name == "alertsGrid"
							);
							let fuelEventsGridSettings =
								jsonDataObjectParsed.find(
									(grid) => grid.name == "fuelEventsGrid"
								);
							let deicerEventsGridSettings =
								jsonDataObjectParsed.find(
									(grid) => grid.name == "deicerEventsGrid"
								);
							let last1000EventsGridSettings =
								jsonDataObjectParsed.find(
									(grid) => grid.name == "last1000EventsGrid"
								);
							let last1000AlarmsGridSettings =
								jsonDataObjectParsed.find(
									(grid) => grid.name == "last1000AlarmsGrid"
								);
							let last1000AlertsGridSettings =
								jsonDataObjectParsed.find(
									(grid) => grid.name == "last1000AlertsGrid"
								);
							if (dataGridSettings) {
								let mergedTemplateAndSavedColumnsForData =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.dataGrid.gridSettings,
										dataGridSettings
									);
								this.kendoGrids.dataGrid.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForData
									);
								gridColumnConfigs.push(
									this.kendoGrids.dataGrid.gridSettings
										.columnsConfig
								);
							}

							if (alarmsGridSettings) {
								let mergedTemplateAndSavedColumnsForAlarms =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.alarmsGrid.gridSettings,
										alarmsGridSettings
									);
								this.kendoGrids.alarmsGrid.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForAlarms
									);
								gridColumnConfigs.push(
									this.kendoGrids.alarmsGrid.gridSettings
										.columnsConfig
								);
								console.log(this);
							}

							if (alertsGridSettings) {
								let mergedTemplateAndSavedColumnsForAlarms =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.alertsGrid.gridSettings,
										alertsGridSettings
									);
								this.kendoGrids.alertsGrid.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForAlarms
									);
								gridColumnConfigs.push(
									this.kendoGrids.alertsGrid.gridSettings
										.columnsConfig
								);
								console.log(this);
							}

							if (fuelEventsGridSettings) {
								let mergedTemplateAndSavedColumnsForFuel =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.fuelEventsGrid
											.gridSettings,
										fuelEventsGridSettings
									);
								this.kendoGrids.fuelEventsGrid.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForFuel
									);
								gridColumnConfigs.push(
									this.kendoGrids.fuelEventsGrid.gridSettings
										.columnsConfig
								);
							}

							if (deicerEventsGridSettings) {
								let mergedTemplateAndSavedColumnsForDeicer =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.deicerEventsGrid
											.gridSettings,
										deicerEventsGridSettings
									);
								this.kendoGrids.deicerEventsGrid.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForDeicer
									);
								gridColumnConfigs.push(
									this.kendoGrids.deicerEventsGrid
										.gridSettings.columnsConfig
								);
							}

							if (last1000EventsGridSettings) {
								let mergedTemplateAndSavedColumnsForLast1000Events =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.last1000Events
											.gridSettings,
										last1000EventsGridSettings
									);
								this.kendoGrids.last1000Events.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForLast1000Events
									);
								gridColumnConfigs.push(
									this.kendoGrids.last1000Events.gridSettings
										.columnsConfig
								);
							}

							if (last1000AlarmsGridSettings) {
								let mergedTemplateAndSavedColumnsForLast1000Alarms =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.last1000Alarms
											.gridSettings,
										last1000AlarmsGridSettings
									);
								this.kendoGrids.last1000Alarms.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForLast1000Alarms
									);
								gridColumnConfigs.push(
									this.kendoGrids.last1000Alarms.gridSettings
										.columnsConfig
								);
							}

							if (last1000AlertsGridSettings) {
								let mergedTemplateAndSavedColumnsForLast1000Alarms =
									this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
										this.kendoGrids.last1000Alerts
											.gridSettings,
										last1000AlertsGridSettings
									);
								this.kendoGrids.last1000Alerts.gridSettings =
									this.mapGridSettings(
										mergedTemplateAndSavedColumnsForLast1000Alarms
									);
								gridColumnConfigs.push(
									this.kendoGrids.last1000Alerts.gridSettings
										.columnsConfig
								);
							}

							this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
								gridColumnConfigs,
								this.widgetObject.WidgetId
							);
						}

						if (fuelEventsGridSettings) {
							let mergedTemplateAndSavedColumnsForFuel =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.kendoGrids.fuelEventsGrid.gridSettings,
									fuelEventsGridSettings
								);
							this.kendoGrids.fuelEventsGrid.gridSettings =
								this.mapGridSettings(
									mergedTemplateAndSavedColumnsForFuel
								);
							gridColumnConfigs.push(
								this.kendoGrids.fuelEventsGrid.gridSettings
									.columnsConfig
							);
						}

						if (deicerEventsGridSettings) {
							let mergedTemplateAndSavedColumnsForDeicer =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.kendoGrids.deicerEventsGrid
										.gridSettings,
									deicerEventsGridSettings
								);
							this.kendoGrids.deicerEventsGrid.gridSettings =
								this.mapGridSettings(
									mergedTemplateAndSavedColumnsForDeicer
								);
							gridColumnConfigs.push(
								this.kendoGrids.deicerEventsGrid.gridSettings
									.columnsConfig
							);
						}

						if (last1000EventsGridSettings) {
							let mergedTemplateAndSavedColumnsForLast1000Events =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.kendoGrids.last1000Events.gridSettings,
									last1000EventsGridSettings
								);
							this.kendoGrids.last1000Events.gridSettings =
								this.mapGridSettings(
									mergedTemplateAndSavedColumnsForLast1000Events
								);
							gridColumnConfigs.push(
								this.kendoGrids.last1000Events.gridSettings
									.columnsConfig
							);
						}

						if (last1000AlarmsGridSettings) {
							let mergedTemplateAndSavedColumnsForLast1000Alarms =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.kendoGrids.last1000Alarms.gridSettings,
									last1000AlarmsGridSettings
								);
							this.kendoGrids.last1000Alarms.gridSettings =
								this.mapGridSettings(
									mergedTemplateAndSavedColumnsForLast1000Alarms
								);
							gridColumnConfigs.push(
								this.kendoGrids.last1000Alarms.gridSettings
									.columnsConfig
							);
						}

						if (last1000AlertsGridSettings) {
							let mergedTemplateAndSavedColumnsForLast1000Alarms =
								this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
									this.kendoGrids.last1000Alerts.gridSettings,
									last1000AlertsGridSettings
								);
							this.kendoGrids.last1000Alerts.gridSettings =
								this.mapGridSettings(
									mergedTemplateAndSavedColumnsForLast1000Alarms
								);
							gridColumnConfigs.push(
								this.kendoGrids.last1000Alerts.gridSettings
									.columnsConfig
							);
						}

						this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
							gridColumnConfigs,
							this.widgetObject.WidgetId
						);
					}

					this.getSignalRUpdates();

					this.finishInitializingComponent("Widget");
					this.isDataLoading = false;
					this.loadCorrectTabData();
					this.changeColumnsForTimeZone();
				});

			if (this.dashboardTimeZoneChanged) {
				this.dashboardTimeZoneChangedSubscription =
					this.dashboardTimeZoneChanged.subscribe((data) => {
						console.log(data);
						let foundWidgetWithSameWidgetId = data.find(
							(widgetThatWasChanged) => {
								return (
									widgetThatWasChanged.WidgetId ===
									this.widgetObject.WidgetId
								);
							}
						);

						if (!_.isNil(foundWidgetWithSameWidgetId)) {
							console.log("Widget Time Zone Changed");
							this.timeZoneType =
								this.dashboardService.determineTimeZoneType(
									this.widgetObject
								);

							this.changeColumnsForTimeZone();
						}
					});
			}
		} else {
			this.assetObject =
				this.dataService.cache.assetsObject[this.assetId];
			this.assetIsDeicer =
				this.dataService.cache.assetsObject[this.assetId]
					.AssetTypeId === 86293;

			if (this.assetId !== undefined) {
				// if (Global.isMobile && this.widgetObject === undefined) {
				// 	this.widgetTabs.push({
				// 		textLabel: 'File Upload',
				// 		rendered: false,
				// 	});
				// 	this.internalWidgetObject =
				// 		Global.Dialog.currentDialogObject;

				// 	this.fileUpload
				// 		.buildFileUploadObject(this.assetId, this.dataObject)
				// 		.then((data: any) => {
				// 			this.fileImageLibraryControlObject = data;
				// 			this.countOfFilesUploaded =
				// 				this.fileImageLibraryControlObject.countOfFilesUploaded;
				// 		});
				// }
				this.dataService
					.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory(
						this.assetId
					)
					.subscribe((data) => {
						Global.User.DebugMode &&
							console.log(
								"gse-summary: dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventory data = %O",
								data
							);
						if (data === null) {
							this.swalWithBootstrapButtons
								.fire({
									title: "Notice: ",
									text: "No tags for this asset in the last 60 days.",
									showCancelButton: false,
									confirmButtonText: "Ok",
									reverseButtons: false,
								})
								.then(() => {});
						} else {
							this.assetTagIds = data.map((t) => t.Id);
							Global.User.DebugMode &&
								console.log("gse-summary: data = %O", data);
						}

						let assetObjectInCache =
							this.dataService.cache.assetsObject[this.assetId];
						this.widgetGroupSettings = {
							WidgetId:
								this.signalRCore.generateIdForPopupThatIsUnique(),
							GroupList: "PS" + assetObjectInCache.ParentSystemId,
							IsPopup: true,
						};
						//this.signalRCore.joinParentSystemGroupsThroughSignalR(this.widgetGroupSettings);
						this.getSignalRUpdates();
						this.finishInitializingComponent("Popup");
					});
			}

			this.searchboxDisabled = true;
			this.isDataLoading = true;

			// this.timerId = setInterval(() => this.evaluateRedraw(), 300);

			this.intializeDeicer();

			if (this.dataObject?.shouldShowMap) {
				this.materialTabSelected = 1;
				this.searchboxDisabled = true;
			} else if (
				(this.dataObject?.breadcrumbTags &&
					this.dataObject.breadcrumbTags.length > 0) ||
				this.dataObject?.shouldShowPlayback
			) {
				this.materialTabSelected = 2;
				this.selectedMatTabLabel = "Playback";
				this.searchboxDisabled = true;
			} else if (this.widgetObject?.SelectedTabIndex !== null) {
				this.materialTabSelected = this.widgetObject.SelectedTabIndex;
				this.searchboxDisabled = false;
			} else {
				this.materialTabSelected = 0;
				this.searchboxDisabled = false;
			}

			this.ref.detectChanges();
		}

		this.punchoutTimeZoneChangedSubscription =
			this.dataService.punchoutTimeZoneChanged$.subscribe((data: any) => {
				if (
					this.dashboardService.currentDashboard.Name ==
					"PunchOut Screen"
				) {
					this.timeZoneType =
						this.dashboardService.determineTimeZoneType({
							TimeZoneId:
								this.dashboardService.currentDashboard
									.TimeZoneId,
						});

					this.changeColumnsForTimeZone();
				}
			});

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				Global.User.DebugMode && console.log("data = %O", data);
				if (data === "light") {
					this.theme = "light";
				} else {
					this.theme = "dark";
				}
			});

		this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${
			this.assetObject?.Site?.Name ?? ""
		}_${this.assetObject?.AssetTypeName ?? ""}_${this.assetObject?.Name}`;
		this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${
			this.assetObject?.Site?.Name ?? ""
		}_${this.assetObject?.AssetTypeName ?? ""}_${this.assetObject?.Name}`;
		this.kendoGrids.last1000Alerts.config.exportTitle = `Last-1000-Alerts_${
			this.assetObject?.Site?.Name ?? ""
		}_${this.assetObject?.AssetTypeName ?? ""}_${this.assetObject?.Name}`;
	}

	getSignalRUpdates() {
		let assetObjectInCache =
			this.dataService.cache.assetsObject[this.assetId];

		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes =
			Global.SignalR.ListOfTagNamePrefixes != null
				? (Global.SignalR.ListOfTagNamePrefixes +=
						"," + tagNamePrefixesString)
				: tagNamePrefixesString;

		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false,
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode &&
			console.log(
				this.componentName + ": this.widgetGroupSettings = %O",
				this.widgetGroupSettings
			);

		this.dataService
			.createSubjectAndSubscribe({
				Id: this.guid,
				WidgetName: "Asset Summary Parent",
				TagNamePrefix: [tagNamePrefixesString],
			})
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode &&
					console.log(
						this.componentName + "current active subjects: %O",
						this.dataService.activeSubjects
					);
				var activeSubject =
					this.dataService.activeSubjects.firstOrDefault(
						(subject: ITagNamePrefixSubject) => {
							return subject.Id == this.guid;
						}
					);
				activeSubject &&
					activeSubject.Subject$.subscribe((tag: ITag) => {
						//console.log(this.componentName + "Updating tag we care about: %O", tag);
						this.updateProperTabDataForSignalRMessage(tag);
						if (!_.isNil(this.marker)) {
							if (
								tag.JBTStandardObservation?.Id === 54260 ||
								tag.JBTStandardObservation?.Id === 54261
							) {
								this.setMarkerLocationForMap();
							}
						}
					});
			});
	}

	private intializeDeicer() {
		// add the deicer tab
		if (this.assetIsDeicer) {
			//-- get Deicer event fields built out for Deicer dynamic form... --Kirk T. Sherer, October 7, 2020.
			this.siteId =
				this.dataService.cache.assetsObject[this.assetId].SiteId;

			this.widgetTabs = [
				{ textLabel: "Data", rendered: false },
				{ textLabel: "Map", rendered: false },
				{ textLabel: "Playback", rendered: false },
				{ textLabel: "Faults", rendered: false },
				{ textLabel: "Alerts", rendered: false },
				{ textLabel: "Last 1000 Events", rendered: false },
				{ textLabel: "Historic Faults", rendered: false },
				{ textLabel: "Historic Alerts", rendered: false },
				{ textLabel: "Fuel", rendered: false },
				{ textLabel: "Deicer Events", rendered: false },
				{ textLabel: "Maintenance", rendered: false },
				{ textLabel: "Information", rendered: false },
			];
		} else {
			this.widgetTabs = [
				{ textLabel: "Data", rendered: false },
				{ textLabel: "Map", rendered: false },
				{ textLabel: "Playback", rendered: false },
				{ textLabel: "Faults", rendered: false },
				{ textLabel: "Alerts", rendered: false },
				{ textLabel: "Last 1000 Events", rendered: false },
				{ textLabel: "Historic Faults", rendered: false },
				{ textLabel: "Historic Alerts", rendered: false },
				{ textLabel: "Fuel", rendered: false },
				{ textLabel: "Maintenance", rendered: false },
				{ textLabel: "Information", rendered: false },
			];
		}
		// add the Templates Tabs if Assets have any associated Canvas templates -- Jyotsna Inapuri 07/18/2023
		if (this.assetHasCanvasTemplates && this.canvasTemplates.length > 0) {
			this.canvasTemplates.forEach((t: any) => {
				var obj: any = {};
				obj.textLabel = t.Name;
				obj.rendered = false;
				this.widgetTabs.unshift(obj);
			});
		}
		//Render the first tab
		this.widgetTabs[0].rendered = true;
	}

	//-- Deicer Functions
	submitDeicerEvent(submittedValues: string) {
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"Deicer event invoked... submittedValues: %O",
				submittedValues
			);
		var fieldListAsString = submittedValues
			.replace("{", "")
			.replace("}", "")
			.replace(/:/g, "=")
			.replace(/\"/g, "");
		Global.User.DebugMode &&
			console.log(
				this.componentName + "fieldListAsString = " + fieldListAsString
			);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode &&
			console.log(
				this.componentName + "submittedValuesObject = %O",
				submittedValuesObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName + "Object.keys(submittedValuesObject) = %O",
				Object.keys(submittedValuesObject)
			);
		var keys: Array<any> = Object.keys(submittedValuesObject);

		//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				fieldListAsString += value ? value : "null";
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		if (this.currentDeicerEventId) {
			fieldListAsString += ", @Id=" + this.currentDeicerEventId;
		}

		Global.User.DebugMode &&
			console.log("fieldListAsString = " + fieldListAsString);

		var storedProcedure = this.deicerFormOptions.saveStoredProcedureName;
		this.dataService
			.SQLActionAsPromise(
				storedProcedure +
					" @AssetId=" +
					this.assetId +
					", @UserId = " +
					Global.User.currentUser.Id +
					", " +
					fieldListAsString
			)
			.then((data: any) => {
				console.log(
					this.componentName + storedProcedure + " return value = %O",
					data
				);
				var currentDeicerEvent = data.first();

				if (currentDeicerEvent.StopDate) {
					//-- if there's a stop date, we're finished deicing.  Send back the entire list of deicer history that was sent by the stored procedure.
					this.getPastHistoryOfDeicerEvents(null, data); //-- stored procedure update will send back entire list of deicer events for this asset. --Kirk T. Sherer, October 14, 2020
					this.deicerFormOptions = {
						submitButtonText: "Start Deicing",
						submitButtonTitle:
							"Press this button to start the deicing event.",
						submitButtonClass: "btn-success",
						clearFieldsAfterSubmit: false,
						saveValuesPerField: false,
						saveStoredProcedureName: "API.GSE_DeicerEvent",
					};
					//this.buildDeicerFormQuestions(); //-- this should reset the form since we no longer have an existing deicer event happening.
				} else {
					this.currentDeicerEventId = currentDeicerEvent.Id; //-- need the Id number of the current deicing event so it's updated when the "Stop Deicing" button is pressed.
					this.deicerFormOptions = {
						submitButtonText: "Stop Deicing",
						submitButtonTitle:
							"Press this button to stop the deicing event.",
						submitButtonClass: "btn-danger",
						clearFieldsAfterSubmit: true,
						saveValuesPerField: false,
						saveStoredProcedureName: "API.GSE_DeicerEvent",
					};
					this.getPastHistoryOfDeicerEvents(currentDeicerEvent);
				}
			});
	}

	buildDeicerFormQuestions() {
		var service = this;
		this.deicerDynamicFieldDataExists = false;
		this.editHeader = "Deicer Event";
		this.editSubHeader =
			"Please enter the fields listed for this deicer event.";
		this.deicerFields = [];
		//-- only get the stored procedures that don't currently have a report definition (hence the reason for 'true' as a parameter value when getting the list of stored procedures.)  Will display a message if none exist. --Kirk T. Sherer, March 30, 2020.
		this.dataService
			.SQLActionAsPromise(
				"API.GSE_GetOutstandingDeicerEvent " + this.assetId
			)
			.then((data: any) => {
				var existingDeicerEvent = data;

				if (existingDeicerEvent.length > 0) {
					this.currentDeicerEventId = existingDeicerEvent[0].Id;
					existingDeicerEvent = existingDeicerEvent[0];
					this.deicerFormOptions.submitButtonText = "Stop Deicing";
					this.deicerFormOptions.submitButtonTitle =
						"Press this button to stop the deicing event.";
					this.deicerFormOptions.submitButtonClass = "btn-danger";
				} else {
					existingDeicerEvent = null;
					this.deicerFormOptions.submitButtonText = "Start Deicing";
					this.deicerFormOptions.submitButtonTitle =
						"Press this button to start the deicing event.";
					this.deicerFormOptions.submitButtonClass = "btn-success";
				}

				Global.User.DebugMode &&
					console.log(this.componentName + "data = %O", data);

				this.dataService
					.SQLActionAsPromise(
						"API.GSE_ListOfTailNumbersDepartingBySite " +
							this.siteId
					)
					.then((data: any) => {
						Global.User.DebugMode &&
							console.log(
								this.componentName +
									"list of tail numbers departing for this site: %O",
								data
							);
						var tailNumberList = data;
						if (tailNumberList.length != 0) {
							//-- build out the options list for the dynamic form.
							var optionsList = [];
							tailNumberList.forEach((t: any) => {
								var obj: any = {};
								obj.key = t.TailNumber;
								obj.value = t.TailNumber;
								optionsList.push(obj);
							});
							//-- create the dropdown question structure for the stored procedure.
							var field = new DropdownQuestion({
								key: "@TailNumber",
								label: "Tail Number",
								value: existingDeicerEvent
									? existingDeicerEvent.TailNumber
									: null,
								required: true,
								title: "Please select the tail number of the aircraft being de-iced.",
								options: optionsList,
								order: 1,
							});

							this.deicerFields.push(field);

							this.dataService
								.SQLActionAsPromise(
									"API.GSE_ListOfDeicerFluidTypes"
								)
								.then((data: any) => {
									//-- build out the options list for the dynamic form.
									var fluidTypes = data;
									var optionsList = [];
									fluidTypes.forEach((t: any) => {
										var obj: any = {};
										obj.key = t.Id;
										obj.value = t.Name;
										optionsList.push(obj);
									});
									//-- create the dropdown question structure for the stored procedure.
									var field = new DropdownQuestion({
										key: "@FluidTypeId",
										label: "Fluid Type",
										value: existingDeicerEvent
											? existingDeicerEvent.FluidTypeId
											: null,
										required: true,
										title: "Please select the fluid type used for deicing the aircraft.",
										options: optionsList,
										order: 2,
									});

									this.deicerFields.push(field);

									var notes = new TextareaQuestion({
										key: "@Notes",
										label: "Notes",
										value: existingDeicerEvent
											? existingDeicerEvent.Notes
											: null,
										required: false,
										title: "Please enter any notes for this de-icing event, if applicable.",
										order: 3,
									});

									this.deicerFields.push(notes);

									Global.User.DebugMode &&
										console.log(
											this.componentName +
												"this.deicerFields = %O",
											this.deicerFields
										);
									this.deicerDynamicFieldDataExists = true;

									this.loadingDeicerForm = false;

									this.getPastHistoryOfDeicerEvents();
								});
						}
					});
			});
	}

	getPastHistoryOfDeicerEvents(lastEvent?: any, deicerEvents?: any) {
		if (
			!this.kendoGrids.deicerEventsGrid.data ||
			(!lastEvent && !deicerEvents)
		) {
			//-- if we don't have deicer events populated yet, or we didn't send a current deicing event, then go get the list of deicer events again from SQL Server. --Kirk T. Sherer, October 12, 2020.
			this.dataService
				.SQLActionAsPromise(
					"API.GSE_ListOfPastDeicerEventsByAssetId " + this.assetId
				)
				.then((data: any) => {
					var deicerEventsSorted = _.orderBy(data, ["Id"], ["desc"]);
					this.kendoGrids.deicerEventsGrid.data =
						deicerEventsSorted.map((de) => ({
							TailNumber: de.TailNumber,
							FluidType: de.FluidType,
							StartDateLocal: de.StartDate,
							StartDateSite:
								this.utilityService.convertFromUtcToLocalToSite(
									de.StartDateMS,
									this.siteUTCTimeOffset
								),
							StartDateUTC:
								this.utilityService.convertFromUtcToLocalToSite(
									de.StartDateMS,
									0
								),
							StopDateLocal: de.StopDate,
							StopDateSite:
								this.utilityService.convertFromUtcToLocalToSite(
									de.StopDateMS,
									this.siteUTCTimeOffset
								),
							StopDateUTC:
								this.utilityService.convertFromUtcToLocalToSite(
									de.StopDateMS,
									0
								),
							Notes: de.Notes,
							CreatorUser: de.CreatorUser,
							LastModifiedUser: de.LastModifiedUser,
							LastModifiedDateLocal: de.LastModifiedDate,
							LastModifiedDateSite:
								this.utilityService.convertFromUtcToLocalToSite(
									de.LastModifiedDateMS,
									this.siteUTCTimeOffset
								),
							LastModifiedDateUTC:
								this.utilityService.convertFromUtcToLocalToSite(
									de.LastModifiedDateMS,
									0
								),
						}));
					this.kendoGrids.deicerEventsGrid.gridSettings.gridData =
						this.kendoGrids.deicerEventsGrid.data;
					if (this.deicerEventsGrid) {
						this.deicerEventsGrid.gridDataSubject.next(
							this.kendoGrids.deicerEventsGrid.data
						);
					}

					Global.User.DebugMode &&
						console.log(
							this.componentName +
								"this.kendoGrids.deicerEvents.gridSettings.gridData = %O",
							this.kendoGrids.deicerEventsGrid.gridSettings
								.gridData
						);
				});
		} else {
			if (deicerEvents) {
				this.kendoGrids.deicerEventsGrid.gridSettings.gridData =
					deicerEvents;
				if (this.deicerEventsGrid) {
					this.deicerEventsGrid.gridDataSubject.next(
						this.kendoGrids.deicerEventsGrid.data
					);
				}
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							"this.kendoGrids.deicerEvents.gridSettings.gridData = %O",
						this.kendoGrids.deicerEventsGrid.gridSettings.gridData
					);
			} else {
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							"this.kendoGrids.deicerEvents.data = %O",
						this.kendoGrids.deicerEventsGrid.data
					);
				Global.User.DebugMode &&
					console.log(
						this.componentName + "lastEvent = %O",
						lastEvent
					);

				let newDecierEvent = {
					TailNumber: lastEvent.TailNumber,
					FluidType: lastEvent.FluidType,
					StartDateLocal: lastEvent.StartDate,
					StartDateSite:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.StartDate.getUTCMilliseconds(),
							this.siteUTCTimeOffset
						),
					StartDateUTC:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.StartDate.getUTCMilliseconds(),
							0
						),
					StopDateLocal: lastEvent.StopDate,
					StopDateSite:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.StopDate.getUTCMilliseconds(),
							this.siteUTCTimeOffset
						),
					StopDateUTC:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.StopDate.getUTCMilliseconds(),
							0
						),
					Notes: lastEvent.Notes,
					CreatorUser: lastEvent.CreatorUser,
					LastModifiedUser: lastEvent.LastModifiedUser,
					LastModifiedDateLocal: lastEvent.LastModifiedDate,
					LastModifiedDateSite:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.LastModifiedDate.getUTCMilliseconds(),
							this.siteUTCTimeOffset
						),
					LastModifiedDateUTC:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.LastModifiedDate.getUTCMilliseconds(),
							0
						),
				};

				this.kendoGrids.deicerEventsGrid.data.push(newDecierEvent); // map this fuel event
				this.kendoGrids.deicerEventsGrid.data = _.orderBy(
					this.kendoGrids.deicerEventsGrid.data,
					["Id"],
					["desc"]
				);
				this.kendoGrids.deicerEventsGrid.gridSettings.gridData =
					this.kendoGrids.deicerEventsGrid.data;
				if (this.deicerEventsGrid) {
					this.deicerEventsGrid.gridDataSubject.next(
						this.kendoGrids.deicerEventsGrid.data
					);
				}
			}
		}
	}

	//-- Fuel Functions
	submitFuelEvent(submittedValues: string) {
		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"Fuel event invoked... submittedValues: %O",
				submittedValues
			);
		var fieldListAsString = submittedValues
			.replace("{", "")
			.replace("}", "")
			.replace(/:/g, "=")
			.replace(/\"/g, "");
		Global.User.DebugMode &&
			console.log(
				this.componentName + "fieldListAsString = " + fieldListAsString
			);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode &&
			console.log(
				this.componentName + "submittedValuesObject = %O",
				submittedValuesObject
			);
		Global.User.DebugMode &&
			console.log(
				this.componentName + "Object.keys(submittedValuesObject) = %O",
				Object.keys(submittedValuesObject)
			);
		var keys: Array<any> = Object.keys(submittedValuesObject);

		//-- building a parameter list to attach to the end of the stored procedure to execute it. --Kirk T. Sherer, April 7, 2020.

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				fieldListAsString += value ? value : "null";
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		Global.User.DebugMode &&
			console.log("fieldListAsString = " + fieldListAsString);

		var storedProcedure = this.fuelFormOptions.saveStoredProcedureName;
		this.dataService
			.SQLActionAsPromise(
				storedProcedure +
					" @AssetId=" +
					this.assetId +
					", @UserId = " +
					Global.User.currentUser.Id +
					", " +
					fieldListAsString
			)
			.then((data: any) => {
				console.log(
					this.componentName + storedProcedure + " return value = %O",
					data
				);
				var currentFuelEvent = data.first();
				this.getPastHistoryOfFuelEvents(null, data); //-- stored procedure update will send back entire list of fuel events for this asset. --Kirk T. Sherer, October 14, 2020
			});
	}

	buildFuelFormQuestions() {
		this.fuelDynamicFieldDataExists = false;
		this.editHeader = "Fuel Event";
		this.editSubHeader =
			"Please enter the fields listed for this fuel event.";
		this.fuelFields = [];

		var quantity = new NumberQuestion({
			key: "@Quantity",
			label: "Quantity",
			value: null,
			required: true,
			title: "Please enter the quantity for this fuel event.",
			order: 1,
		});

		this.fuelFields.push(quantity);

		Global.User.DebugMode &&
			console.log(
				this.componentName + "this.fuelFields = %O",
				this.fuelFields
			);
		this.fuelDynamicFieldDataExists = true;

		this.loadingFuelForm = false;

		this.getPastHistoryOfFuelEvents();
	}

	getPastHistoryOfFuelEvents(lastEvent?: any, fuelEvents?: any) {
		if (
			!this.kendoGrids.fuelEventsGrid.data ||
			(!lastEvent && !fuelEvents)
		) {
			//-- if we don't have fuel events populated yet, then go get the list of fuel events again from SQL Server. --Kirk T. Sherer, January 5, 2021.
			this.dataService
				.SQLActionAsPromise(
					"API.GSE_ListOfPastFuelEventsByAssetId " + this.assetId
				)
				.then((data: any) => {
					var fuelEventsSorted = _.orderBy(data, ["Id"], ["desc"]);
					this.kendoGrids.fuelEventsGrid.data = fuelEventsSorted.map(
						(fe) => ({
							Quantity: fe.Quantity,
							DateLocal: fe.Date,
							DateSite:
								this.utilityService.convertFromUtcToLocalToSite(
									fe.DateMS,
									this.siteUTCTimeOffset
								),
							DateUTC:
								this.utilityService.convertFromUtcToLocalToSite(
									fe.DateMS,
									0
								),
							LastModifiedDateLocal: fe.LastModifiedDate,
							LastModifiedDateSite:
								this.utilityService.convertFromUtcToLocalToSite(
									fe.LastModifiedDateMS,
									this.siteUTCTimeOffset
								),
							LastModifiedDateUTC:
								this.utilityService.convertFromUtcToLocalToSite(
									fe.LastModifiedDateMS,
									0
								),
							LastModifiedUser: fe.LastModifiedUser,
							CreatorUser: fe.CreatorUser,
						})
					);
					this.kendoGrids.fuelEventsGrid.gridSettings.gridData =
						this.kendoGrids.fuelEventsGrid.data;
					if (this.fuelEventsGrid) {
						this.fuelEventsGrid.gridDataSubject.next(
							this.kendoGrids.fuelEventsGrid.data
						);
					}

					Global.User.DebugMode &&
						console.log(
							this.componentName +
								"this.kendoGrids.fuelEvents.gridSettings.gridData = %O",
							this.kendoGrids.fuelEventsGrid.gridSettings.gridData
						);
				});
		} else {
			if (fuelEvents) {
				this.kendoGrids.fuelEventsGrid.gridSettings.gridData =
					fuelEvents;
				if (this.fuelEventsGrid) {
					this.fuelEventsGrid.gridDataSubject.next(
						this.kendoGrids.fuelEventsGrid.data
					);
				}
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							"this.kendoGrids.fuelEvents.gridSettings.gridData = %O",
						this.kendoGrids.fuelEventsGrid.gridSettings.gridData
					);
			} else {
				Global.User.DebugMode &&
					console.log(
						this.componentName +
							"this.kendoGrids.fuelEvents.data = %O",
						this.kendoGrids.fuelEventsGrid.data
					);
				Global.User.DebugMode &&
					console.log(
						this.componentName + "lastEvent = %O",
						lastEvent
					);

				let newFuelEvent = {
					Quantity: lastEvent.Quantity,
					DateLocal: lastEvent.Date,
					DateSite: this.utilityService.convertFromUtcToLocalToSite(
						lastEvent.Date.getUTCMilliseconds(),
						this.siteUTCTimeOffset
					),
					DateUTC: this.utilityService.convertFromUtcToLocalToSite(
						lastEvent.Date.getUTCMilliseconds(),
						0
					),
					LastModifiedDateLocal: lastEvent.LastModifiedDate,
					LastModifiedDateSite:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.LastModifiedDate.getUTCMilliseconds(),
							this.siteUTCTimeOffset
						),
					LastModifiedDateUTC:
						this.utilityService.convertFromUtcToLocalToSite(
							lastEvent.LastModifiedDate.getUTCMilliseconds(),
							0
						),
					LastModifiedUser: lastEvent.LastModifiedUser,
					CreatorUser: lastEvent.CreatorUser,
				};

				this.kendoGrids.fuelEventsGrid.data.push(newFuelEvent); // map this fuel event
				this.kendoGrids.fuelEventsGrid.data = _.orderBy(
					this.kendoGrids.fuelEventsGrid.data,
					["Id"],
					["desc"]
				);
				this.kendoGrids.fuelEventsGrid.gridSettings.gridData =
					this.kendoGrids.fuelEventsGrid.data;
				if (this.fuelEventsGrid) {
					this.fuelEventsGrid.gridDataSubject.next(
						this.kendoGrids.fuelEventsGrid.data
					);
				}
			}
		}
		this.changeColumnsForTimeZone();
	}

	changeColumnsForTimeZone() {
		// Adjust visible and hidden columns
		Object.values(this.kendoGrids).forEach((grid, index) => {
			if (index == 3) {
				// DeicerEvents grid
				const localStartDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "StartDateLocal"
				);
				const localEndDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "StopDateLocal"
				);

				const siteStartDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "StartDateSite"
				);
				const siteEndDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "StopDateSite"
				);

				const utcStartDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "StartDateUTC"
				);
				const utcEndDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "StopDateUTC"
				);
				const localLastModDateCol =
					grid.gridSettings.columnsConfig.find(
						(col) => col.field == "LastModifiedDateLocal"
					);
				const siteLastModDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "LastModifiedDateSite"
				);
				const utcLastModDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "LastModifiedDateUTC"
				);
				if (localStartDateCol != undefined) {
					localStartDateCol.hidden =
						this.timeZoneType !== "User Time";
					localStartDateCol.includeInChooser =
						this.timeZoneType === "User Time";
				}

				if (localEndDateCol != undefined) {
					localEndDateCol.hidden = this.timeZoneType !== "User Time";
					localEndDateCol.includeInChooser =
						this.timeZoneType === "User Time";
				}

				if (localLastModDateCol != undefined) {
					localLastModDateCol.hidden =
						this.timeZoneType !== "User Time";
					localLastModDateCol.includeInChooser =
						this.timeZoneType === "User Time";
				}

				if (siteStartDateCol != undefined) {
					siteStartDateCol.hidden = this.timeZoneType !== "Site Time";
					siteStartDateCol.includeInChooser =
						this.timeZoneType === "Site Time";
				}

				if (siteEndDateCol != undefined) {
					siteEndDateCol.hidden = this.timeZoneType !== "Site Time";
					siteEndDateCol.includeInChooser =
						this.timeZoneType === "Site Time";
				}

				if (siteEndDateCol != undefined) {
					siteEndDateCol.hidden = this.timeZoneType !== "Site Time";
					siteEndDateCol.includeInChooser =
						this.timeZoneType === "Site Time";
				}

				if (utcStartDateCol != undefined) {
					utcStartDateCol.hidden = this.timeZoneType !== "UTC Time";
					utcStartDateCol.includeInChooser =
						this.timeZoneType === "UTC Time";
				}

				if (utcEndDateCol != undefined) {
					utcEndDateCol.hidden = this.timeZoneType !== "UTC Time";
					utcEndDateCol.includeInChooser =
						this.timeZoneType === "UTC Time";
				}

				if (utcLastModDateCol != undefined) {
					utcLastModDateCol.hidden = this.timeZoneType !== "UTC Time";
					utcLastModDateCol.includeInChooser =
						this.timeZoneType === "UTC Time";
				}
			} else if (index == 4) {
				// fuelEvents grid
				const localDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "DateLocal"
				);
				const siteDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "DateSite"
				);
				const utcDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "DateUTC"
				);
				const localLastModDateCol =
					grid.gridSettings.columnsConfig.find(
						(col) => col.field == "LastModifiedDateLocal"
					);
				const siteLastModDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "LastModifiedDateSite"
				);
				const utcLastModDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "LastModifiedDateUTC"
				);

				if (localDateCol != undefined) {
					localDateCol.hidden = this.timeZoneType !== "User Time";
					localDateCol.includeInChooser =
						this.timeZoneType === "User Time";
				}
				if (localLastModDateCol != undefined) {
					localLastModDateCol.hidden =
						this.timeZoneType !== "User Time";
					localLastModDateCol.includeInChooser =
						this.timeZoneType === "User Time";
				}
				if (siteDateCol != undefined) {
					siteDateCol.hidden = this.timeZoneType !== "Site Time";
					siteDateCol.includeInChooser =
						this.timeZoneType === "Site Time";
				}
				if (siteLastModDateCol != undefined) {
					siteLastModDateCol.hidden =
						this.timeZoneType !== "Site Time";
					siteLastModDateCol.includeInChooser =
						this.timeZoneType === "Site Time";
				}
				if (utcDateCol != undefined) {
					utcDateCol.hidden = this.timeZoneType !== "UTC Time";
					utcDateCol.includeInChooser =
						this.timeZoneType !== "UTC Time";
				}

				if (utcLastModDateCol != undefined) {
					utcLastModDateCol.hidden = this.timeZoneType !== "UTC Time";
					utcLastModDateCol.includeInChooser =
						this.timeZoneType !== "UTC Time";
				}
			} else if (index != 5) {
				const localDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "JavascriptDate"
				);
				const siteDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "SiteLocalJavascriptDate"
				);
				const utcDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "UTCJavascriptDate"
				);

				if (localDateCol != undefined)
					localDateCol.hidden = this.timeZoneType !== "User Time";
				if (siteDateCol != undefined)
					siteDateCol.hidden = this.timeZoneType !== "Site Time";
				if (utcDateCol != undefined)
					utcDateCol.hidden = this.timeZoneType !== "UTC Time";
				if (localDateCol != undefined)
					localDateCol.includeInChooser = !localDateCol.hidden;
				if (siteDateCol != undefined)
					siteDateCol.includeInChooser = !siteDateCol.hidden;
				if (utcDateCol != undefined)
					utcDateCol.includeInChooser = !utcDateCol.hidden;
				const localEndDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "JavascriptEndDate"
				);
				const siteEndDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "SiteLocalJavascriptEndDate"
				);
				const utcEndDateCol = grid.gridSettings.columnsConfig.find(
					(col) => col.field == "UTCJavascriptEndDate"
				);
				if (localEndDateCol != undefined) {
					localEndDateCol.hidden = this.timeZoneType !== "User Time";
					localEndDateCol.includeInChooser = !localEndDateCol.hidden;
				}
				if (siteEndDateCol != undefined) {
					siteEndDateCol.hidden = this.timeZoneType !== "Site Time";
					siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
				}
				if (utcEndDateCol != undefined) {
					utcEndDateCol.hidden = this.timeZoneType !== "UTC Time";
					utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
				}
			}
		});
	}

	finishInitializingComponent(typeOfComponent: string) {
		if (Global.Theme === "light") {
			this.theme = "light";
		} else if (Global.Theme === "dark") {
			this.theme = "dark";
		}
		this.isDataLoading = true;
		this.assetModelId = this.assetObject.AssetModelId;
		if (
			this.assetObject &&
			!this.dataService.assetModelMetaData[this.assetModelId]
		) {
			this.dataService
				.SQLActionAsPromise(
					"API.GetAssetModelJBTStandardObservationWithReasons @assetModelIds =" +
						this.assetModelId
				)
				.then((alarmRecords: any) => {
					if (alarmRecords && alarmRecords.length > 0) {
						Global.User.DebugMode &&
							console.log(
								this.componentName + "alarmRecords = %O",
								alarmRecords
							);

						let dataGroupedByObsId = groupBy(alarmRecords, [
							{ field: "JBTStandardObservationId" },
						]);
						dataGroupedByObsId.forEach((grouping: any) => {
							grouping.JBTStandardObservationName =
								grouping.items[0].JBTStandardObservationName;
						});
						Global.User.DebugMode &&
							console.log(
								this.componentName + "dataGroupedByObsId = %O",
								dataGroupedByObsId
							);
						this.dataService.assetModelMetaData[this.assetModelId] =
							dataGroupedByObsId;
					}
				});
		}

		this.countOfAlarms = this.dataService.cache.assetsObject[
			this.assetId
		].ActiveAlarmTags.filter((t) => t.Severity != "Alert").length;
		this.countOfAlerts = this.dataService.cache.assetsObject[
			this.assetId
		].ActiveAlarmTags.filter((t) => t.Severity == "Alert").length;
		this.alertStatus = this.alertsStatus(
			this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags
		);

		//we pass this componet a string of Popup or Widget so we can differentiate what is loaded from one function
		if (typeOfComponent === "Popup") {
			this.timeZoneType = this.dashboardService.determineTimeZoneType(
				this.dataObject
			);
			if (this.assetObject === undefined)
				this.assetObject = this.dataObject;
			if (this.assetId === undefined)
				this.assetId = this.dataObject.widgetObject.Id;

			if (this.dataObject?.shouldShowMap) {
				//Go to map tab programatically because user drove here from clicking map icon on gse-overview
				this.isDataLoading = false;
				this.selectedMatTabLabel = "Map";
			} else if (
				(this.dataObject?.breadcrumbTags &&
					this.dataObject.breadcrumbTags.length > 0) ||
				this.dataObject?.shouldShowPlayback
			) {
				this.materialTabSelected = 2;
				this.selectedMatTabLabel = "Playback";
				this.searchboxDisabled = true;
			}
			this.isDataLoading = false;
			this.kendoGrids.last1000Events.config.assetId = this.assetId;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetId;
			this.kendoGrids.last1000Alerts.config.assetId = this.assetId;
			this.loadCorrectTabData();
			this.changeColumnsForTimeZone();
		} else if (typeOfComponent === "Widget") {
			this.timeZoneType = this.dashboardService.determineTimeZoneType(
				this.widgetObject
			);
			if (this.widgetObject.WidgetAssetId === undefined) {
				return;
			} else {
				this.assetObject = this.widgetObject;
				this.assetId = this.widgetObject.WidgetAssetId;
			}

			if (this.widgetObject.SelectedTabIndex) {
				this.materialTabSelected = this.widgetObject.SelectedTabIndex;
				this.widgetTabs[this.materialTabSelected].rendered = true;
				this.selectedMatTabLabel =
					this.widgetTabs[this.materialTabSelected].textLabel;
			}
		}
		const cacheAssetObj = this.dataService.cache.assetsObject[this.assetId];
		// if (this.assetHasOverviewTab) {
		// 	let powerOn = [4504];
		// 	let coolantTemp = [19140];
		// 	this.assetOverviewTagGraphAssetObj = this.assetObject.Tags.find(
		// 		(t) => powerOn.includes(parseInt(t.JBTStandardObservationId))
		// 	);
		// 	this.assetOverviewTagGraphAssetObj1 = this.assetObject.Tags.find(
		// 		(t) =>
		// 			coolantTemp.includes(parseInt(t.JBTStandardObservationId))
		// 	);
		// 	this.assetOverviewGSETagObj = coolantTemp;
		// 	this.assetOverviewObj = this.assetObject;
		// 	this.assetOverviewObj.WidgetAssetId = this.assetId;
		// 	this.assetOverviewObj.SiteList = this.assetObject.SiteId.toString();
		// }
		this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${
			cacheAssetObj.Site.Name ?? ""
		}_${cacheAssetObj.AssetTypeName ?? ""}_${cacheAssetObj.Name}`;
		this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${
			cacheAssetObj.Site.Name ?? ""
		}_${cacheAssetObj.AssetTypeName ?? ""}_${cacheAssetObj.Name}`;
		this.kendoGrids.last1000Alerts.config.exportTitle = `Last-1000-Alerts_${
			cacheAssetObj.Site.Name ?? ""
		}_${cacheAssetObj.AssetTypeName ?? ""}_${cacheAssetObj.Name}`;

		this.kendoGrids.last1000Events.config.assetId = this.assetId;
		this.kendoGrids.last1000Alerts.config.assetId = this.assetId;

		this.siteUTCTimeOffset =
			this.dataService.cache.sitesObject[
				cacheAssetObj
					? cacheAssetObj.SiteId
					: this.dataObject.widgetObject.WidgetSiteId
			]?.UTCTimeOffset;
		this.kendoGrids.last1000Events.config.siteUTCTimeOffset =
			this.siteUTCTimeOffset;
		this.kendoGrids.last1000Alarms.config.siteUTCTimeOffset =
			this.siteUTCTimeOffset;
		this.kendoGrids.last1000Alerts.config.siteUTCTimeOffset =
			this.siteUTCTimeOffset;
		this.assetListForUserSites = [];
		this.assetListForUserSites.push(
			this.dataService.cache.assetsObject[this.assetId]
		);
		this.childMapReadyToLoad = true;

		if (this.dataObject != undefined) {
			var VocationalSettings = JSON.parse(
				this.dataObject.parentWidget.VocationalSettingsJSON
			);
			this.fleetId = VocationalSettings?.id;
		} else if (this.widgetObject != undefined) {
			this.fleetId = this.widgetObject.FleetId;
		}
		if (this.fleetId) {
			this.tagOptions = [
				{ tagName: "Power On", standardObservationId: 4504 },
				{ tagName: "Drive Mode", standardObservationId: 19127 },
				{ tagName: "Speedometer", standardObservationId: 77417 },
				{
					tagName: "Fuel Level",
					standardObservationId: 19141,
					percent: true,
				},
				{
					tagName: "Def Level",
					standardObservationId: 19229,
					percent: true,
				},
				{
					tagName: "Soot Load %",
					standardObservationId: 63843,
					percent: true,
				},
			];
		} else {
			this.tagOptions = [
				{ tagName: "Power On", standardObservationId: 4504 },
			];
		}
	}

	setMarkerLocationForMap() {
		let lat = this.dataService.cache.assetsObject[this.assetId].Tags.find(
			(t) => t.JBTStandardObservationId == 54260
		);
		let long = this.dataService.cache.assetsObject[this.assetId].Tags.find(
			(t) => t.JBTStandardObservationId == 54261
		);
		if (!_.isNil(lat?.Value) && !_.isNil(long?.Value)) {
			let myLatlng = new google.maps.LatLng(lat.Value, long.Value);
			this.marker.setPosition(myLatlng);
		} else {
			this.noPositionForAsset = true;
		}
	}

	openTagGraphSingle(tag): void {
		// Global.User.DebugMode && console.log("widgetObject = %O", widgetObject);
		const tagGraphSingleModal = this.dialog.open(
			DialogModalParentComponent,
			{
				width: "95%",
				height: "80%",
				data: {
					TagId: tag,
					widgetNameDisplay: "Tag Graph",
					WidgetName: "tag-graph",
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(
						this.widgetObject
					),
					// WidgetName: "TagGraphSingle",
				},

				maxWidth: "100vw",
				maxHeight: "100vh",
				// height: '100%',
				// width: '100%'
			}
		);
		this.tagGraphSingleModalSubscription = tagGraphSingleModal
			.afterClosed()
			.subscribe((result) => {
				Global.User.DebugMode &&
					console.log(this.componentName + "The modal was closed");
			});
	}

	updateProperTabDataForSignalRMessage(tag: ITag) {
		//0 is summary, 1 is map, 2 is alarms, 3 is last 1000 events, 4 is fuel, 5 is maintenance

		if (this.selectedMatTabLabel === "Data") {
			if (this.dataGrid) {
				this.dataGrid.gridDataSubject.next(
					this.dataService.cache.assetsObject[this.assetId].Tags
				);
			}
		} else if (this.selectedMatTabLabel === "Faults") {
			if (this.alarmsGrid) {
				this.alarmsGrid.gridDataSubject.next(
					this.dataService.cache.assetsObject[
						this.assetId
					].ActiveAlarmTags.filter((t) => t.Severity != "Alert")
				);
			}
		} else if (this.selectedMatTabLabel === "Alerts") {
			if (this.alertsGrid) {
				this.alertsGrid.gridDataSubject.next(
					this.dataService.cache.assetsObject[
						this.assetId
					].ActiveAlarmTags.filter((t) => t.Severity == "Alert")
				);
			}
		} else if (this.selectedMatTabLabel === "Map") {
			this.gseSummaryGenericMapChild.updateMapData(tag);
		}

		this.countOfAlarms = this.dataService.cache.assetsObject[
			this.assetId
		].ActiveAlarmTags.filter(
			(t) => t.EffectiveSeverityLevelId != "5"
		).length;
		this.countOfAlerts = this.dataService.cache.assetsObject[
			this.assetId
		].ActiveAlarmTags.filter(
			(t) => t.EffectiveSeverityLevelId == "5"
		).length;
		this.alertStatus = this.alertsStatus(
			this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags
		);
	}

	alertsStatus(activeAlertsData: any) {
		//Critical
		if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 3)
				.length > 0
		)
			return 3;
		//Alarm
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 1)
				.length > 0
		)
			return 1;
		//Warning
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 2)
				.length > 0
		)
			return 2;
	}

	checkForMetaDataInGSEService(tagObject) {
		if (
			!_.isNil(tagObject.JBTStandardObservationId) &&
			this.dataService.assetModelMetaData[this.assetModelId] !== undefined
		) {
			let foundMetaDataRecord = this.dataService.assetModelMetaData[
				this.assetModelId
			].find((t) => {
				// return t.value === 54535;
				return t.value == tagObject.JBTStandardObservationId;
			});
			if (!_.isNil(foundMetaDataRecord)) {
				return foundMetaDataRecord;
			} else {
				return undefined;
			}
		} else {
			return undefined;
		}
	}

	createMetaDataPopup(tagObject) {
		let tag = this.checkForMetaDataInGSEService(tagObject);
		console.log(tag);
		let tagArray = [];
		tagArray.push(tag);

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? "100%" : "60%",
			height: Global.isMobile ? "90%" : "80%",
			data: {
				popupTitle: "Asset Fault Information",
				WidgetName: "Raw-Information-Popup",
				dataObject: this.widgetObject
					? this.widgetObject
					: this.dataObject,
				MetaData: tagArray,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: "100vw",
			maxHeight: "100vh",
		});
	}

	loadCorrectTabData(ifWeNeedToWaitForMap?) {
		this.widgetTabs.find(
			(tab) => tab.textLabel == this.selectedMatTabLabel
		).rendered = true;
		this.kendoGrids.last1000Events.config.gridInView =
			this.selectedMatTabLabel === "Last 1000 Events" ? true : false;
		this.kendoGrids.last1000Alarms.config.gridInView =
			this.selectedMatTabLabel === "Historic Faults" ? true : false;
		this.kendoGrids.last1000Alerts.config.gridInView =
			this.selectedMatTabLabel === "Historic Alerts" ? true : false;

		// this.showSpinner === true;

		this.alertStatus = this.alertsStatus(
			this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags
		);

		if (this.selectedMatTabLabel === "Data") {
			if (this.dataGrid) {
				this.dataGrid.gridDataSubject.next(
					this.dataService.cache.assetsObject[this.assetId].Tags
				);
			}

			this.showSpinner = false;
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
		} else if (
			this.selectedMatTabLabel === "Map" &&
			!this.hasBeenRedrawn &&
			ifWeNeedToWaitForMap
		) {
			this.gseSummaryGenericMapChild.drawMap();
			this.hasBeenRedrawn = true;
			this.evaluateRedraw();
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
			this.ref.detectChanges();
			this.showSpinner = false;
		} else if (this.selectedMatTabLabel === "Faults") {
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
			if (this.alarmsGrid) {
				this.alarmsGrid.gridDataSubject.next(
					this.dataService.cache.assetsObject[
						this.assetId
					].ActiveAlarmTags.filter((t) => t.Severity != "Alert")
				);
			}
		} else if (this.selectedMatTabLabel === "Alerts") {
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
			if (this.alertsGrid) {
				this.alertsGrid.gridDataSubject.next(
					this.dataService.cache.assetsObject[
						this.assetId
					].ActiveAlarmTags.filter((t) => t.Severity == "Alert")
				);
			}
		} else if (this.selectedMatTabLabel === "Last 1000 Events") {
			if (this.kendoGrids.last1000Events.isRendered) {
				this.last1000EventsGrid?.updateDataAfterTabSwitch();
			} else {
				this.kendoGrids.last1000Events.config.assetTagIds =
					this.dataService.cache.assetsObject[
						this.assetId
					].Tags.filter(
						(t) =>
							t.Severity == "Informational" ||
							t.Severity == "Warning"
					).map((t) => t.TagId);
				this.kendoGrids.last1000Events.isRendered = true;
			}
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
		} else if (this.selectedMatTabLabel === "Historic Faults") {
			if (this.kendoGrids.last1000Alarms.isRendered) {
				this.last1000AlarmsGrid?.updateDataAfterTabSwitch();
			} else {
				this.kendoGrids.last1000Alarms.config.assetTagIds =
					this.dataService.cache.assetsObject[
						this.assetId
					].Tags.filter(
						(t) =>
							t.EffectiveSeverityLevelId == 3 ||
							t.EffectiveSeverityLevelId == 1
					).map((t) => t.TagId);
				this.kendoGrids.last1000Alarms.isRendered = true;
			}
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = false;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = false;
			}
		} else if (this.selectedMatTabLabel === "Historic Alerts") {
			if (this.kendoGrids.last1000Alerts.isRendered) {
				this.last1000AlertsGrid?.updateDataAfterTabSwitch();
			} else {
				this.kendoGrids.last1000Alerts.config.assetTagIds =
					this.dataService.cache.assetsObject[
						this.assetId
					].Tags.filter((t) => t.Severity == "Alert").map(
						(t) => t.TagId
					);
				this.kendoGrids.last1000Alerts.isRendered = true;
			}
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = false;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = false;
			}
		} else if (this.selectedMatTabLabel === "Fuel") {
			this.buildFuelFormQuestions();
		} else if (
			this.selectedMatTabLabel === "Deicer Events" &&
			this.assetIsDeicer
		) {
			this.buildDeicerFormQuestions();
		} else if (this.selectedMatTabLabel === "Maintenance") {
			this.buildMaintenanceQuestions();
		}
		this.checkAlarmNotifications(
			this.dataService.cache.assetsObject[this.assetId].Tags
		);

		this.checkAlarmNotificationsInterval = setInterval(() => {
			this.checkAlarmNotifications(
				this.dataService.cache.assetsObject[this.assetId].Tags
			);
		}, 10000);
	}

	public informationTab() {
		let info = [
			{
				title: "Asset Name",
				value: this.dataService.cache.assetsObject[this.assetId]
					.PreferredName,
			},
			{
				title: "Original Name",
				value: this.dataService.cache.assetsObject[this.assetId]
					.OriginalName,
			},
			{
				title: "Fleet Name",
				value: this.dataService.cache.assetsObject[this.assetId]
					.FleetName,
			},
			{
				title: "Asset Type",
				value: this.dataService.cache.assetsObject[this.assetId]
					.AssetTypeName,
			},
			{
				title: "Manufacturer Organization Name",
				value: this.dataService.cache.assetsObject[this.assetId]
					.ManufacturerOrganizationName,
			},
			{
				title: "Operator Organization Name",
				value: this.dataService.cache.assetsObject[this.assetId]
					.OperatorOrganizationName,
			},
			{
				title: "Owner Organization Name",
				value: this.dataService.cache.assetsObject[this.assetId]
					.OwnerOrganizationName,
			},
		];
		return info;
	}
	public mapGridSettings(gridSettings: GridSettings): GridSettings {
		const state = gridSettings.state;
		// this.mapDateFilter(state.filter);
		let example = gridSettings.columnsConfig.sort(
			(a, b) => a.orderIndex - b.orderIndex
		);

		Global.User.DebugMode && console.log(example);
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
		};
	}

	onResized(event) {
		if (this.materialTabSelected === 1) {
			this.evaluateRedraw();
		}

		this.parentContainerSize = event.newRect.height;
		Global.User.DebugMode &&
			console.log(
				this.componentName + "pageSize = " + this.tagDataPageSize
			);
		this.newHeight = event.newRect.height;
		this.oldHeight = event.oldHeight;
		this.newWidth = event.newRect.width;
		this.oldWidth = event.oldWidth;
	}
	fullScreen() {
		this.isFullScreen = !this.isFullScreen;
		Global.User.DebugMode &&
			console.log(
				this.componentName + "isFullScreen = " + this.isFullScreen
			);
		if (this.isFullScreen) {
			this.dialogRef.updateSize("100%", "100%");
		} else {
			this.dialogRef.updateSize("95%", "80%");
		}
	}
	evaluateRedraw() {
		if (
			(this.dataObject !== undefined || this.assetObject !== undefined) &&
			this.widgetObject == undefined
		) {
			let cardHeight = $("#card-whole-test").height();
			if (this.newHeight !== this.oldHeight) {
				$(".gse-summary-map-container").height(this.newHeight - 50);
				$("#regularMap").height(this.newHeight - 50);
			}
		} else if (this.widgetObject !== undefined) {
			let cardHeight = $("#card-whole").height();
			if (this.newHeight !== this.oldHeight) {
				$(
					".gse-summary-map-container" + this.widgetObject.WidgetId
				).height(this.newHeight - 50);
				$("#regularMap" + this.widgetObject.WidgetId).height(
					this.newHeight - 50
				);
			}
		}
	}

	animationRedraw() {
		if (this.selectedMatTabLabel === "Map") {
			this.loadCorrectTabData(true);
			this.gseSummaryGenericMapChild.drawMap();
		}
	}

	tabHasChanged(event) {
		this.selectedMatTabLabel = event.tab.textLabel;
		this.materialTabSelected = event.index;
		this.loadCorrectTabData();

		this.timeoutToExpire = setTimeout(() => {
			if (this.widgetObject != undefined) {
				this.widgetObject.SelectedTabIndex = this.materialTabSelected;
				if (this.widgetObject.RememberSelectedTabIndex) {
					let returnedWidgetAsArray =
						this.dashboardService.addOrUpdateDashboardWidgetItem(
							this.widgetObject
						);
				}
			}
		}, 5000);
	}

	onDelete() {}

	ngOnDestroy() {
		Global.User.DebugMode &&
			console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription !== undefined) {
			this.colorChangedSubscription.unsubscribe();
		}

		if (this.gseSummaryCreatedSubscription !== undefined) {
			this.gseSummaryCreatedSubscription.unsubscribe();
		}
		// this.getGSESummaryByAssetIdSub.unsubscribe();

		Global.User.DebugMode && console.log("gse-summary component destroyed");
		if (this.tagGraphSingleModalSubscription) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
		if (this.updateDataInterval) {
			clearInterval(this.updateDataInterval);
		}

		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}

		if (this.dashboardTimeZoneChangedSubscription) {
			this.dashboardTimeZoneChangedSubscription.unsubscribe();
		}

		if (this.punchoutTimeZoneChangedSubscription) {
			this.punchoutTimeZoneChangedSubscription.unsubscribe();
		}

		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}

		if (this.checkAlarmNotificationsInterval) {
			clearInterval(this.checkAlarmNotificationsInterval);
		}
	}

	onCancel() {
		this.dialogRef.close();
	}
	//labels are 48 px, so get height of .card-body - that and that is your height.

	buildMaintenanceQuestions() {
		this.fullAssetRecord =
			this.dataService.cache.assetsObject[this.assetId];

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					"this.fullAssetRecord for assetId " +
					this.assetId +
					" = %O",
				this.fullAssetRecord
			);
		this.maintenanceMode = new FormControl(
			this.fullAssetRecord.MaintenanceMode
		);
		this.outOfService = new FormControl(this.fullAssetRecord.OutOfService);
		this.maintenanceModeReasonId = new FormControl(
			this.fullAssetRecord.MaintenanceModeReasonId
		);
		this.outOfServiceReasonId = new FormControl(
			this.fullAssetRecord.OutOfServiceReasonId
		);

		this.maintenanceForm = new FormGroup({
			maintenanceMode: this.maintenanceMode,
			outOfService: this.outOfService,
			maintenanceModeReasonId: this.maintenanceModeReasonId,
			outOfServiceReasonId: this.outOfServiceReasonId,
		});

		this.maintenanceModeReasons =
			this.dataService.cache.assetMaintenanceModeReasons;
		this.outOfServiceReasons =
			this.dataService.cache.assetOutOfServiceReasons;

		this.maintenanceForm.valueChanges.subscribe((val) => {
			Global.User.DebugMode && console.log("val = %O", val);

			if (
				(!this.maintenanceForm.value.maintenanceMode ||
					this.maintenanceForm.value.maintenanceMode == "") &&
				(!this.maintenanceForm.value.outOfService ||
					this.maintenanceForm.value.outOfService == "")
			) {
				//-- clear both reasons since both the maintenance mode and out of service are off. --Kirk T. Sherer, October 25, 2020.
				this.maintenanceModeReasonId = null;
				this.outOfServiceReasonId = null;

				this.saveValue(null, this.maintenanceForm); //-- have to save the asset since the maintenance mode and out of service have both been set to false. --Kirk T. Sherer, October 21, 2020.
			}
		});

		this.loadingMaintenanceForm = false;
	}

	sliderSelect(event: Event, obj: any) {
		console.log("obj = %O", obj);
	}

	saveValue(event: Event, obj: any) {
		var service = this;
		if (event) {
			event.stopPropagation();
			console.log("saveValue invoked... event.target = %O", event.target);
		}
		console.log("maintenanceForm = %O", obj);

		var sqlStatement =
			this.maintenanceFormOptions.saveStoredProcedureName +
			" @UserId=" +
			Global.User.currentUser.Id +
			", @AssetId=" +
			this.assetId +
			", @MaintenanceMode=" +
			(obj.value.maintenanceMode ? "1" : "0") +
			", @OutOfService=" +
			(obj.value.outOfService ? "1" : "0") +
			", @MaintenanceModeReasonId=" +
			(obj.value.maintenanceModeReasonId == "" ||
			!obj.value.maintenanceMode
				? "null"
				: obj.value.maintenanceModeReasonId) +
			", @OutOfServiceReasonId=" +
			(obj.value.outOfServiceReasonId == "" || !obj.value.outOfService
				? "null"
				: obj.value.outOfServiceReasonId);
		Global.User.DebugMode && console.log("SQL Statement = " + sqlStatement);

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//-- SECTION FOR SENDING OUT SIGNALR MESSAGE IMMEDIATELY BEFORE SENDING THE STORED PROCEDURE TO UPDATE THE ACTUAL ASSET RECORD AND CREATE THE OBSERVATIONS.
		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		var assetInCache = this.dataService.cache.assetsObject[this.assetId];
		assetInCache.Tags.forEach((tag: ITag) => {
			console.log(
				"Id: " +
					tag.Id +
					", Name: " +
					tag.Name +
					", SimpleName: " +
					tag.SimpleName
			);
		});
		//-- Find the out of service and maintenance mode tags for this asset and build a set of SignalR messages so we can update the application immediately while the stored procedure is updating SQL Server.
		var maintenanceModeOutOfServiceTags = assetInCache.Tags.where(
			(tag: ITag) => {
				return (
					tag.SimpleName?.toLowerCase().indexOf(
						"is in maintenance mode"
					) > -1 ||
					tag.SimpleName?.toLowerCase().indexOf(
						"maintenance mode reason"
					) > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("is out of service") >
						-1 ||
					tag.SimpleName?.toLowerCase().indexOf(
						"out of service reason"
					) > -1
				);
			}
		).toArray();

		//-- creating SignalR message for each of the four tags.
		if (maintenanceModeOutOfServiceTags.length > 0) {
			var signalRDataObject = "";
			maintenanceModeOutOfServiceTags.forEach((tag: ITag) => {
				signalRDataObject +=
					"i!" +
					tag.Id +
					"~d!" +
					this.utilityService.DateTimeInMilliseconds(new Date()) +
					"~v!";
				var tagValue = "";
				switch (tag.SimpleName?.toLowerCase()) {
					case "is in maintenance mode":
						tagValue = obj.value.maintenanceMode ? "1" : "0";
						break;
					case "maintenance mode reason":
						tagValue =
							obj.value.maintenanceModeReasonId == "" ||
							!obj.value.maintenanceMode
								? ""
								: obj.value.maintenanceModeReasonId;
						break;
					case "is out of service":
						tagValue = obj.value.outOfService ? "1" : "0";
						break;
					case "out of service reason":
						tagValue =
							obj.value.outOfServiceReasonId == "" ||
							!obj.value.outOfService
								? ""
								: obj.value.outOfServiceReasonId;
						break;
				}
				signalRDataObject += tagValue + "\r\n";
			});
			if (signalRDataObject != "") {
				console.log(
					"broadcasting SignalR Message about changes to asset's maintenance mode / out of service: %O",
					signalRDataObject
				);
				this.signalRCore.broadcast(
					"o",
					signalRDataObject,
					"PS" + this.assetId
				);
			}
		}

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(service.componentName + "" + "data = %O", data);
			//-- update data cache since leaving the Maintenance tab and coming back to it will reload what the asset was originally set to in the data cache. --Kirk T. Sherer, April 22, 2023.
			var currentAsset =
				service.dataService.cache.assetsObject[service.assetId];
			currentAsset.MaintenanceMode = obj.value.maintenanceMode;
			currentAsset.MaintenanceModeReasonId =
				obj.value.maintenanceMode == false
					? null
					: obj.value.maintenanceModeReasonId;
			currentAsset.OutOfService = obj.value.outOfService;
			currentAsset.OutOfServiceReasonId =
				obj.value.outOfService == false
					? null
					: obj.value.outOfServiceReasonId;

			service.utilityService.showToastMessageShared({
				type: "success",
				message: "Asset maintenance updated.",
			});
		});
	}

	public loadHistoricFaultsAndAlerts() {
		this.last1000AlarmsGrid?.initialize();
		this.last1000AlertsGrid?.initialize();
	}

	public trendBreadCrumbsFromLast1000Grid(configuration) {
		if (this.dataObject !== undefined) {
			this.dataObject.breadcrumbTags = [configuration.dataItem];
		} else if (this.widgetObject !== undefined) {
			this.widgetObject.breadcrumbTags = [configuration.dataItem];
		} else if (this.assetObject != undefined) {
			this.assetObject.breadcrumbTags = [configuration.dataItem];
		}
		let matIndex = this.widgetTabs.findIndex((i) => {
			return i.textLabel === "Playback";
		});
		this.materialTabSelected = matIndex;
		this.selectedMatTabLabel = "Playback";
		this.searchboxDisabled = true;
		if (this.breadcrumbsChild !== undefined) {
			this.getBreadcrumbsChild(configuration);
		} else {
			setTimeout(() => {
				this.getBreadcrumbsChild(configuration);
			}, 500);
		}
	}

	getBreadcrumbsChild(configuration) {
		let timeBefore: number;
		if (configuration.selection === "Trend Using Playback - 5 Min Before") {
			timeBefore = 5;
		} else if (
			configuration.selection === "Trend Using Playback - 2 Hr Before"
		) {
			timeBefore = 120;
		}
		this.breadcrumbsChild.getDataForDateRange({
			getObservationsForDefaultList: true,
			timeBeforeToStart: timeBefore,
		});
	}

	public onRightClickSelect({ dataItem, item }): void {
		if (item === "Quick Trend of Tag") {
			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: "95%",
					height: "80%",
					data: {
						TagId: dataItem.TagId,
						widgetNameDisplay: "Tag Graph",
						WidgetName: "tag-graph",
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},
					maxWidth: "100vw",
					maxHeight: "100vh",
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		} else if (
			item === "Trend Using Playback - 5 Min Before" ||
			item === "Trend Using Playback - 2 Hr Before"
		) {
			console.log(dataItem);
			console.log(item);
			if (this.dataObject !== undefined) {
				this.dataObject.breadcrumbTags = [dataItem];
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.breadcrumbTags = [dataItem];
			} else if (this.assetObject != undefined) {
				this.assetObject.breadcrumbTags = [dataItem];
			}

			let matIndex = this.widgetTabs.findIndex((i) => {
				return i.textLabel === "Playback";
			});
			this.materialTabSelected = matIndex;
			this.selectedMatTabLabel = "Playback";
			this.searchboxDisabled = true;
			let timeBefore: number;
			if (item === "Trend Using Playback - 5 Min Before") {
				timeBefore = 5;
			} else if (item === "Trend Using Playback - 2 Hr Before") {
				timeBefore = 120;
			}
			if (this.breadcrumbsChild !== undefined) {
				//run with new data

				this.breadcrumbsChild.getDataForDateRange({
					getObservationsForDefaultList: true,
					timeBeforeToStart: timeBefore,
				});
			} else {
				setTimeout(() => {
					this.breadcrumbsChild.getDataForDateRange({
						getObservationsForDefaultList: true,
						timeBeforeToStart: timeBefore,
					});
				}, 1000);
			}
		}
	}

	checkAlarmNotifications(tags: any) {
		let fleetId;

		if (this.dataObject != undefined) {
			var VocationalSettings = JSON.parse(
				this.dataObject.parentWidget.VocationalSettingsJSON
			);
			fleetId = VocationalSettings?.id;
		} else if (this.widgetObject != undefined) {
			fleetId = this.widgetObject.FleetId;
		}

		tags.forEach((t) => {
			let userAlert;

			if (fleetId) {
				userAlert = this.dataService.cache.emailTagUsers.find(
					(a) =>
						a.UserId == Global.User.currentUser.Id &&
						(a.TagId == t.TagId ||
							(a.JbtStandardObservationId ==
								t.JBTStandardObservationId &&
								a.GroupThingId == fleetId))
				);
			} else {
				userAlert = this.dataService.cache.emailTagUsers.find(
					(a) =>
						a.UserId == Global.User.currentUser.Id &&
						(a.TagId == t.TagId ||
							(a.JbtStandardObservationId ==
								t.JBTStandardObservationId &&
								a.JbtStandardObservationSiteId == t.SiteId))
				);
			}

			t.alertNotification = userAlert == undefined ? false : true;

			// set the title with alert info
			if (userAlert != undefined) {
				let title =
					"Alert notifications set for " +
					t.JBTStandardObservation.Name;
				let alertObject = this.GetAlertNotificationDetails(userAlert);

				if (
					alertObject.Email == true &&
					alertObject.TextMessage == true
				) {
					title += " email and text delivery";
				} else {
					title += alertObject.Email == true ? " email delivery" : "";
					title +=
						alertObject.TextMessage == true ? " text delivery" : "";
				}

				title += " on " + alertObject.DaysSelected;
				title += " during " + alertObject.HoursSelected;

				t.alertNotificationTitle = title;
			} else {
				t.alertNotificationTitle =
					"Click to set alert management settings.";
			}
		});
	}

	GetAlertNotificationDetails(userAlert): any {
		let alertObject: any = {
			siteId: 0,
			siteName: "",
			gateSystemId: 0,
			gateSystemName: "",
			category: "",
			Email: 0,
		};

		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if (userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		} else {
			alertObject.DaysSelected = "select days";
		}

		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if (userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		} else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}
}
