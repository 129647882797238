import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, NgZone } from "@angular/core";

import { filter } from "rxjs/operators";
import * as moment from "moment";
import _ from "lodash";

import { SassHelperComponent } from "../../_helpers/sass-helper/sass-helper.component";
import { Global } from "../../_constants/global.variables";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { UtilityService } from "../../services/utility.service";
import * as $ from 'jquery';
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITag } from "../../_models/tag.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

declare var Highcharts: any;

@Component({
	selector: "lib-pca-discharge-performance",
	templateUrl: "./pca-discharge-performance.component.html",
	styleUrls: ["./pca-discharge-performance.component.scss"]
})
export class PcaDischargePerformanceComponent implements OnInit, OnDestroy {
	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	Highcharts: typeof Highcharts = Highcharts;
	signalRSubscription: any;
	ChartData: any;
	ChartMap: Map<string, any> = new Map();
	theme: string;
	Chart: any;
	colorChangedSubscription: any;
	isDataLoading: boolean;
	widgetResizedSubscription: any;
	tagIds: any;
	assetList: any;
	alarmTagIds: any;
	signalRTagUpdateSubscription: any;
	runtimeInterval: NodeJS.Timeout;
	cacheJbtStandardObsLookup: any;

	private runtimeData = [];

	private dischargeTempIds = [2736]; // Discharge Temperature - Aircraft
	private dischModeIds = [15220, 15215]; // Mode Bridge, Mode Selection - Aircraft Output,
	private operationModeIds = [15219]; // Mode of Operation
	private pcaStatusIds = [12374]; // Unit On
	private pcaAlarmIds = [12323]; // Alarm is Active
	private OutOfServiceIds = [54284]; // Is Out of Service
	private MaintenanceModeIds = [54283]; // Is in Maintenance Mode
	private CommsLossIds = [4331]; // Comms Loss
	private allStdIds = [...this.dischargeTempIds, ...this.dischModeIds, ...this.operationModeIds, ...this.pcaStatusIds, ...this.pcaAlarmIds, ...this.OutOfServiceIds, ...this.MaintenanceModeIds, ...this.CommsLossIds];
	fullDataCacheSubscription: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	private componentName: string = "pca-discharge-performance";
	assetIds: any;
	public guid: string;
	
	constructor(private dataService: DataService, private signalRCore: SignalRCoreService, private utilityService: UtilityService, private zone: NgZone) { }

	ngOnInit() {
		this.guid = this.dataService.guid();
		if (this.widgetObject.WidgetSiteId) {
			this.isDataLoading = true;

			if (this.widgetResizedEvent) {
				this.widgetResizedSubscription = this.widgetResizedEvent.subscribe((data) => {
					if (this.Chart && this.widgetObject.WidgetId == data.item.WidgetId) {
						if (Global.isMobile) {
							let tagGraph = $("#pcaDischargePerformance" + this.widgetObject.WidgetId);
							this.Chart.setSize(tagGraph.width(), tagGraph.height(), false);
						} else {
							this.Chart.setSize(data.width, data.height - 43, false);
						}
					}
				});
			}

			if (!Global.FullDataCacheExists) {
				this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.Initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
			} else {
				this.Initialize();
			}

			// replaces $scope.on
			this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
				this.CreateChart();
			});
		}
		
	}
	
	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + "ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.widgetResizedSubscription !== undefined) {
			this.widgetResizedSubscription.unsubscribe();
		}

		if (this.colorChangedSubscription !== undefined) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.runtimeInterval) {
			clearInterval(this.runtimeInterval);
		}
	}

	GetRuntimesFromDB() {
		// Get Runtime Data
		let statement = `GSGetRuntimeForAssetsBySiteList @siteNames = '${this.widgetObject.WidgetSiteName}', @assetTypes = 'PCA'`;

		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.runtimeData = data;
			Global.User.DebugMode && console.log(this.componentName + ": " + statement + " data = %O", data);
		});
	}

	Initialize() {
		this.GetRuntimesFromDB();
		this.theme = Global.Theme;
		this.cacheJbtStandardObsLookup = this.dataService.cache.jbtStandardObservationsObject;

		// Initialize chart data structure
		this.ChartData = { points: [], icons: [], assets: [] };

		this.assetList = this.dataService.cache.assets
			.filter((a) => a.SiteId == this.widgetObject.WidgetSiteId)
			.filter((a) => a.Name == 'PCA' || a.Name == 'AHU')
			.map((a) => ({
				Id: a.Id,
				GateName: a.ParentSystem.Name,
			}));

		this.assetList = this.assetList.sort((a1, a2) => this.utilityService.GetGateNameSortValue(a1.GateName).localeCompare(this.utilityService.GetGateNameSortValue(a2.GateName)));
		this.assetIds = this.assetList.map((a) => a.Id);
		let assetIdList = this.assetList.map((a) => a.Id.toString()).join();

		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(assetIdList, false, this.allStdIds).subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + ": dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O", data);
			this.GetChartData(data);
			this.CreateChart();
		});
	}

	GetChartData(tags) {
		Global.User.DebugMode && console.log(this.componentName + ": GetChartData invoked...");

		this.tagIds = tags.map((t) => t.Id);
		this.assetList.forEach((asset, index) => {
			let assetTags = tags.filter((t) => t.AssetId == asset.Id);

			if (assetTags.length >= 2) {
				let result = this.runtimeData.find((d) => d.AssetId == asset.Id);
				let gateObj = {
					GateName: asset.GateName,
					ChartIndex: index,
					AssetId: asset.Id,
					DischTemp: parseFloat(this.GetDataFromAssetTags(assetTags, "DISCHTEMP")),
					Status: this.GetDataFromAssetTags(assetTags, "STATUS"),
					DischMode: this.GetDataFromAssetTags(assetTags, "DISCHMODE"),
					OpMode: this.GetDataFromAssetTags(assetTags, "OPMODE"),
					UnitOnDate: result ? result.UnitOnDate : "None",
					Runtime: result ? moment.utc().diff(moment.utc(result.UnitOnDate), "minutes") : 0,
					AlarmActive: this.GetDataFromAssetTags(assetTags, "ALARMS"),
					IsOutOfService: this.GetDataFromAssetTags(assetTags, "SERVICE"),
					IsInMaintenance: this.GetDataFromAssetTags(assetTags, "MAINTENANCE"),
					HasCommsLoss: this.GetDataFromAssetTags(assetTags, "COMMS")
				};

				this.ChartData.assets.push(gateObj);

				this.ChartData.points.push({
					assetId: gateObj.AssetId,
					name: `${gateObj.GateName}<br/>${gateObj.Runtime} min`,
					y: gateObj.DischTemp,
					gate: gateObj.GateName,
					status: gateObj.Status,
					opMode: gateObj.OpMode,
					dischMode: gateObj.DischMode,
					runtime: gateObj.Runtime,
					color: this.getColumnColor(gateObj)
				});

				this.ChartData.icons.push(this.getColumnIcon(gateObj));
			}
		});

		// Update the runtime values every minute
		this.runtimeInterval = setInterval(() => {
			this.SetRuntimeValues();
		}, 1000 * 60);
	}

	CreateChart() {
		Global.User.DebugMode && console.log(this.componentName + ": CreateChart invoked...");

		let innerChartTextColor = this.theme === "dark" ? "white" : this.sassHelper.readProperty("body-text-color-dark");
		let outerChartTextColor = this.theme === "dark" ? this.sassHelper.readProperty("body-text-color-light") : this.sassHelper.readProperty("body-text-color-dark");

		var chartOptions = {
			chart: {
				type: "column",
				animation: false,
				backgroundColor: this.theme === "dark" ? "rgb(39,41,61)" : "white",
				style: {
					fontFamily: "Poppins, sans-serif",
					color: outerChartTextColor
				}
			},
			credits: { enabled: false },
			title: {
				text: ""
			},
			xAxis: {
				type: "category",
				title: {
					text: "Gate<br/>Asset Runtime",
					style: { color: outerChartTextColor }
				},
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: "10px",
						fontFamily: "Verdana, sans-serif",
						color: outerChartTextColor
					}
				}
			},
			yAxis: {
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: "10px",
						wordWrap: "break word",
						fontFamily: "Verdana, sans-serif",
						color: outerChartTextColor
					}
				},
				title: {
					text: "Discharge Temperature (F)",
					style: { color: outerChartTextColor }
				},
				stackLabels: {
					enabled: true,
					style: { fontWeight: "bold" }
				}
			},
			legend: { enabled: false },
			plotOptions: {
				stacking: "normal",
				bar: {
					dataLabels: { enabled: true }
				},
				series: {
					colorByPoint: true
				}
			},

			series: [
				{
					name: "Discharge Temperature",
					data: this.ChartData.points,
					dataLabels: {
						enabled: true,
						color: innerChartTextColor
					},
					tooltip: {
						headerFormat: '<span style="font-size: 10px;font-weight: bold">PCA Details</span><br/>',
						pointFormatter: function () {
							let tooltip = `
							  Status: ${this.status} <br/>
							  Discharge Temperature: ${this.y}°F<br/>
							  Discharge Mode: ${this.dischMode} <br/>
							  Operation Mode: ${this.opMode} <br/>
							  Runtime: ${this.runtime} min
							  `;
							return tooltip;
						}
					}
				},
				{
					type: "scatter",
					labels: false,
					data: this.ChartData.icons,
					tooltip: {
						headerFormat: '<span style="font-size: 10px;font-weight: bold">Alert</span><br/>',
						pointFormatter: function () {
							return `${this.tooltip}`;
						}
					}
				}
			]
		};
		this.zone.runOutsideAngular(() => {
			this.Chart = Highcharts.chart("pcaDischargePerformance" + this.widgetObject.WidgetId, chartOptions);
		});
		this.widgetObject.isDisplayDataLive = true;
		this.isDataLoading = false;
		this.getSignalRUpdates();
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = _.uniq(this.assetIds.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix)).join(",");
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: this.widgetObject.WidgetName, 
										 TagNamePrefix: tagNamePrefixesString.split(",")  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.UpdateChart(tag);
				});
			});
	}

	UpdateChart(tagObj: any) {
		let newValue: any;
		let gateName = tagObj.Asset.ParentSystem.Name;
		let pcaObject = this.ChartData.assets.find((a) => a.GateName == gateName);

		if (!pcaObject) {
			return;
		}

		if (this.pcaStatusIds.includes(tagObj.JBTStandardObservationId)) {
			newValue = this.GetDataFromNewTag(tagObj, "STATUS");

			if (pcaObject.Status != newValue) {
				if (newValue == "On") {
					pcaObject.Status = newValue;
					pcaObject.UnitOnDate = tagObj.JavascriptDate;
				} else {
					pcaObject.Status = newValue;
					pcaObject.UnitOnDate = "None";
					pcaObject.Runtime = 0;
				}

				let newColor = this.getColumnColor(pcaObject); // Check to see if the column color needs to be updated
				this.Chart.series[0].data[pcaObject.ChartIndex].update({
					status: newValue,
					color: newColor,
					runtime: 0,
					name: `${pcaObject.GateName}<br/>${pcaObject.Runtime} min`
				});
				Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: PCA Status: ${newValue}`);
			}
		} else if (this.dischargeTempIds.includes(tagObj.JBTStandardObservationId)) {
			// parse the new value from the tag depending on its type
			newValue = parseFloat(this.GetDataFromNewTag(tagObj, "DISCHTEMP"));

			if (pcaObject.DischTemp != newValue) {
				// Update the pcaObject in the ChartMap and Chart Series
				this.Chart.series[0].data[pcaObject.ChartIndex].update({
					y: newValue
				});
				pcaObject.DischTemp = newValue;

				// Update position of alarm icon
				if (pcaObject.AlarmActive || pcaObject.IsOutOfService || pcaObject.IsInMaintenance || pcaObject.HasCommsLoss) {
					this.Chart.series[1].points[pcaObject.ChartIndex].update({
						x: pcaObject.ChartIndex,
						y: newValue > 0 ? newValue + 20 : 20
					});
				}

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: Discharge Temperature: ${newValue}`);
			}
		} else if (this.operationModeIds.includes(tagObj.JBTStandardObservationId)) {
			// parse the new value from the tag depending on its type
			newValue = this.GetDataFromNewTag(tagObj, "OPMODE");

			if (pcaObject.OpMode != newValue) {
				pcaObject.OpMode = newValue;

				// Check to see if the column color needs to be updated
				let newColor = this.getColumnColor(pcaObject);

				// Update the pcaObject in the ChartMap and Chart Series
				this.Chart.series[0].data[pcaObject.ChartIndex].update({
					opMode: newValue,
					color: newColor
				});

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: Operation Mode: ${newValue}`);
			}
		} else if (this.dischModeIds.includes(tagObj.JBTStandardObservationId)) {
			// parse the new value from the tag depending on its type
			newValue = this.GetDataFromNewTag(tagObj, "DISCHMODE");

			if (pcaObject.DischMode != newValue) {
				// Update the pcaObject in the ChartMap and Chart Series
				this.Chart.series[0].data[pcaObject.ChartIndex].update({
					dischMode: newValue
				});
				pcaObject.DischMode = newValue;

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: Discharge Mode: ${newValue}`);
			}
		} else if (this.pcaAlarmIds.includes(tagObj.JBTStandardObservationId)) {
			newValue = this.GetDataFromNewTag(tagObj, "ALARMS");

			if (pcaObject.AlarmActive != newValue) {
				pcaObject.AlarmActive = newValue;
				let imageObj = this.getColumnIcon(pcaObject);
				this.Chart.series[1].points[pcaObject.ChartIndex].update({
					x: pcaObject.ChartIndex,
					marker: imageObj.marker,
					tooltip: imageObj.tooltip,
					visible: imageObj.visible
				});

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: Alarm Active: ${newValue}`);
			}
		} else if (this.CommsLossIds.includes(tagObj.JBTStandardObservationId)) {
			newValue = this.GetDataFromNewTag(tagObj, "COMMS");

			if (pcaObject.HasCommsLoss != newValue) {
				pcaObject.HasCommsLoss = newValue;
				let imageObj = this.getColumnIcon(pcaObject);
				this.Chart.series[1].points[pcaObject.ChartIndex].update({
					x: pcaObject.ChartIndex,
					marker: imageObj.marker,
					tooltip: imageObj.tooltip,
					visible: imageObj.visible
				});

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: Comms Loss: ${newValue}`);
			}
		} else if (this.OutOfServiceIds.includes(tagObj.JBTStandardObservationId)) {
			newValue = this.GetDataFromNewTag(tagObj, "SERVICE");
			if (pcaObject.IsOutOfService != newValue) {
				pcaObject.IsOutOfService = newValue;
				let imageObj = this.getColumnIcon(pcaObject);
				this.Chart.series[1].points[pcaObject.ChartIndex].update({
					x: pcaObject.ChartIndex,
					marker: imageObj.marker,
					tooltip: imageObj.tooltip,
					visible: imageObj.visible
				});

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: Out of Service: ${newValue}`);
			}
		} else if (this.MaintenanceModeIds.includes(tagObj.JBTStandardObservationId)) {
			newValue = this.GetDataFromNewTag(tagObj, "MAINTENANCE");

			if (pcaObject.IsInMaintenance != newValue) {
				pcaObject.IsInMaintenance = newValue;
				let imageObj = this.getColumnIcon(pcaObject);
				this.Chart.series[1].points[pcaObject.ChartIndex].update({
					x: pcaObject.ChartIndex,
					marker: imageObj.marker,
					tooltip: imageObj.tooltip,
					visible: imageObj.visible
				});

				//Global.User.DebugMode && console.log(this.componentName + ": " + this.widgetObject.WidgetSiteName + " Gate " + `${gateName}: In Maintenance Mode: ${newValue}`);
			}
		}
	}

	getColumnIcon(pcaObject: any): any {
		let iconURL = "",
			iconText = "";

		if (pcaObject.IsOutOfService) {
			iconURL = "url(" + Global.imagesUrl + "assets/img/out-of-service.svg)";
			iconText = "Unit is Out of Service";
		} else if (pcaObject.IsInMaintenance) {
			iconURL = "url(" + Global.imagesUrl + "assets/img/maintenance-mode.svg)";
			iconText = "Unit is in Maintenance Mode";
		} else if (pcaObject.HasCommsLoss) {
			iconURL = 'url(' + Global.imagesUrl + 'assets/img/comms-loss.svg)';
			iconText = "Unit has Comms Loss";
		} else if (pcaObject.AlarmActive) {
			iconURL = "url(https://sds.toltech.net/charting/warning-icon.png)";
			iconText = "Unit has one or more active faults";
		}

		let imageObj = {
			category: pcaObject.GateName,
			y: pcaObject.DischTemp > 0 ? pcaObject.DischTemp + 20 : 20,
			tooltip: iconText,
			gate: pcaObject.GateName,
			visible: iconURL ? true : false,
			marker: {
				symbol: iconURL,
				width: 25,
				height: 25
			}
		};
		return imageObj;
	}

	getColumnColor(pcaObject: any): string {
		if (pcaObject.Status == "On") {
			switch (pcaObject.OpMode) {
				case "Cool":
					return "DodgerBlue";
				case "Heat":
					return "Red";
				case "Vent":
					return "OrangeRed";
				case "Auto":
					return "ForestGreen";
				default:
					return "LightGrey";
			}
		} else {
			return "LightGrey";
		}
	}

	SetRuntimeValues() {
		let activeAssets = this.ChartData.assets.filter((a) => a.Status == "On");

		activeAssets.forEach((a) => {
			a.Runtime = moment.utc().diff(moment.utc(a.UnitOnDate), "minutes");
			this.Chart.series[0].data[a.ChartIndex].update({
				runtime: a.Runtime,
				name: `${a.GateName}<br/>${a.Runtime} min`
			});
		});

		Global.User.DebugMode && console.log(this.componentName + ": Runtimes have been updated.");
	}

	GetDataFromAssetTags(tags: any, code: string): any {
		let tagObj: any;

		switch (code) {
			case "STATUS":
				return tags.filter((t) => this.pcaStatusIds.includes(t.JBTStandardObservationId)).some((t) => t.LastObservationTextValue == "1") ? "On" : "Off";

			case "DISCHTEMP":
				tagObj = tags.find((t) => this.dischargeTempIds.includes(t.JBTStandardObservationId));
				return tagObj ? parseFloat(tagObj.LastObservationTextValue).toFixed(1) : 0;

			case "OPMODE":
				tagObj = tags.find((t) => this.operationModeIds.includes(t.JBTStandardObservationId));
				if (tagObj) {
					switch (tagObj.LastObservationTextValue) {
						case "0":
							return "Unknown";
						case "1":
							return "Auto";
						case "2":
							return "Cool";
						case "3":
							return "Vent";
						case "4":
							return "Heat";
						default:
							return "Auto";
					}
				} else {
					return "Auto";
				}

			case "DISCHMODE":
				tagObj = tags.find((t) => t.JBTStandardObservationId == 15220);
				if (tagObj?.LastObservationTextValue == "1") {
					return "Bridge";
				} else {
					tagObj = tags.find((t) => t.JBTStandardObservationId == 15215);
					if (tagObj) {
						switch (tagObj.LastObservationTextValue) {
							case "1":
								return "Commuter";
							case "2":
								return "Narrow";
							case "3":
								return "Wide";
							case "4":
								return "Jumbo";
							default:
								return "Aircraft";
						}
					} else {
						return "Aircraft";
					}
				}

			case "ALARMS":
				return tags.find((t) => t.JBTStandardObservationId == 12323)?.LastObservationTextValue == "1";

			case "SERVICE":
				return tags.find((t) => t.JBTStandardObservationId == 54284)?.LastObservationTextValue == "1";

			case "MAINTENANCE":
				return tags.find((t) => t.JBTStandardObservationId == 54283)?.LastObservationTextValue == "1";

			case "COMMS":
				return tags.find((t) => t.JBTStandardObservationId == 4331)?.LastObservationTextValue == "1";
		}
	}

	GetDataFromNewTag(tagObj: any, code: string): any {
		switch (code) {
			case "STATUS":
				return tagObj.Value == "1" ? "On" : "Off";

			case "DISCHTEMP":
				return parseFloat(tagObj.Value).toFixed(1);

			case "OPMODE":
				switch (tagObj.LastObservationTextValue) {
					case "0":
						return "UDF";
					case "1":
						return "Auto";
					case "2":
						return "Cool";
					case "3":
						return "Vent";
					case "4":
						return "Heat";
					default:
						return "Auto";
				}

			case "DISCHMODE":
				switch (tagObj.JBTStandardObservationId) {
					case 15220:
						return tagObj.Value == "1" ? "Bridge" : "Aircraft";

					case 15215:
						if (tagObj.Value == "0") return "Unknown";
						else if (tagObj.Value == "1") return "Commuter";
						else if (tagObj.Value == "2") return "Narrow";
						else if (tagObj.Value == "3") return "Wide";
						else if (tagObj.Value == "4") return "Jumbo";
						else return "Aircraft";
				}
				break;

			case "ALARMS":
				return tagObj.Value == "1" ? true : false;

			case "SERVICE":
				return tagObj.Value == "1" ? true : false;

			case "MAINTENANCE":
				return tagObj.Value == "1" ? true : false;

			case "COMMS":
				return tagObj.Value == "1" ? true : false;
		}
	}
}
