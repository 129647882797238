import { Component, OnInit, Input, Optional, ViewEncapsulation, ViewChild, ChangeDetectorRef, EventEmitter, OnDestroy, ElementRef, NgZone } from "@angular/core";

import _ from "lodash";
import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import { process, State } from "@progress/kendo-data-query";
import { PageChangeEvent, RowClassArgs } from "@progress/kendo-angular-grid";
import { GridSettings } from "../../_models/grid-settings.interface";

import { FormGroup, FormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { NumberQuestion } from "../../_models/dynamic-fields/questions/question-number";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";
import { UtilityService } from "../../services/utility.service";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { MatDialog } from "@angular/material/dialog";
import { ITag } from "../../_models/tag.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "lib-gpu-summary",
	templateUrl: "./gpu-summary.component.html",
	styleUrls: ["./gpu-summary.component.scss"]
})
export class GpuSummaryComponent implements OnInit, OnDestroy {
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	@ViewChild("alarmDataGrid") alarmDataGrid: KendoGridParentComponent;
	@ViewChild("last1000EventsGrid") last1000EventsGrid;
	@ViewChild("last1000AlarmsGrid") last1000AlarmsGrid;
	@ViewChild("tagDataRowForCalculation") tagDataRowForCalculation: ElementRef;
	@ViewChild("eventDataRowForCalculation")
	eventDataRowForCalculation: ElementRef;
	@Input() private gpuSummaryCreatedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	gpuSummaryCreatedSubscription: Subscription;
	@ViewChild("hourMeterReadingEventsGrid")
	hourMeterReadingEventsGrid: KendoGridParentComponent;

	tagDataRowHeight: any = 43;
	eventDataRowHeight: any = 26;
	assetTagIds = [];
	alarmTagIds = [];
	quickViewTagIds = [];
	assetObj: any;
	siteUTCTimeOffset: Number;
	assetBaseImageURL: string;
	fullDataCache$: any;
	theme: string;
	gaugeTextColor: string;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	fromDashboard: boolean;
	timeZoneType: any;
	defaultRightWidth = 40;
	defaultLeftWidth = 60;
	defaultWidth = 100;
	cacheJbtStandardObsLookup: any;
	cacheTagLookup: any;
	tagDataPageSize: number;
	eventDataPageSize: number;
	fullDataCacheSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	widgetIsInViewSubscription: Subscription;
	isWidgetInView: boolean = false;
	parentContainerSize: any;
	countOfAlarms: any;
	alertStatus: any;
	private componentName: string = "gpu-summary: ";
	private quickViewIds = [12374, 1826, 57513, 12384, 18802, 18803, 15032, 15029, 2244, 15030, 15026, 1857, 1942, 1858, 1859, 12227, 12225, 12221, 4440, 1935, 4442, 4444, 1864, 1865];
	//Hour Meter Reading Fields
	public editHeader: string;
	public editSubHeader: string;
	public hourMeterReadingQuestions: QuestionBase<any>[];
	public hourMeterReadingFields: QuestionBase<any>[];
	public isHourMeterReadingFormLoading: boolean;
	public hourMeterReadingFormOptions: any = {
		submitButtonText: "Save Entry",
		submitButtonClass: "btn-success",
		submitButtonTitle: "Press this button to save the Hour Meter Reading entry.",
		saveValuesPerField: false,
		clearFieldsAfterSubmit: true,
		saveStoredProcedureName: "API.GS_HourMeterReading"
	};
	public hourMeterReadingEvents: any;
	currentHourMeterReadingValue: any;

	//-- Maintenance Mode / Out of Service fields
	public isMaintenanceFormLoading: boolean = true;
	public maintenanceHeader: string = "Maintenance";
	public maintenanceSubHeader: string = "Please enter the fields listed for this maintenance event.";
	public maintenanceFields: Array<any>;
	public maintenanceFormOptions: any = {
		submitButtonText: "",
		saveValuesPerField: true,
		saveStoredProcedureName: "API.Asset_UpdateMaintenanceModeOrOutOfService"
	};

	public maintenanceForm: FormGroup;
	private maintenanceMode: FormControl;
	private outOfService: FormControl;
	private outOfServiceReasonId: FormControl;
	private maintenanceModeReasonId: FormControl;

	public maintenanceModeReasons: Array<any>;
	public outOfServiceReasons: Array<any>;
	private fullAssetRecord: any;
	private displaySignalRUpdates: boolean;
	public widgetInstance: number = Math.random();

	private checkAlarmNotificationsInterval;

	AmpsOutData = {
		Average: undefined,
		PhaseA: undefined,
		PhaseB: undefined,
		PhaseC: undefined,
		GpuStatus: undefined,
		ActiveAlarms: undefined
	};

	VoltsOutData = {
		Average: undefined,
		PhaseA: undefined,
		PhaseB: undefined,
		PhaseC: undefined,
		GpuStatus: undefined,
		ActiveAlarms: undefined
	};

	AmpsOutShadowData = {
		Average: undefined,
		PhaseA: undefined,
		PhaseB: undefined,
		PhaseC: undefined,
		GpuStatus: undefined,
		ActiveAlarms: undefined
	};

	VoltsOutShadowData = {
		Average: undefined,
		PhaseA: undefined,
		PhaseB: undefined,
		PhaseC: undefined,
		GpuStatus: undefined,
		ActiveAlarms: undefined
	};

	public kendoGrids = {
		tags: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "Favorite",
						title: "Favorite",
						filterable: true,
						filter: 'boolean',
						_width: 150,
					},
					{
						field: 'alertNotification',
						title: 'Fault',
						filterable: true,
						filter: 'boolean',
						_width: 40,
						hidden: false,
						minResizableWidth: 40,
					},
				],
			} as GridSettings,
		},
		activeAlerts: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 50,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						_width: 100,
						minResizableWidth: 125
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "ValueWhenActive",
						title: "Value When Active",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		},
		last1000Events: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 125,
						_width: 100
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 100
					}
				]
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				alarmsOnly: false,
				gridInView: false,
				exportTitle: ""
			}
		},
		last1000Alarms: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 125,
						_width: 50
					},
					{
						field: "JavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "JavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						filter: "numeric",
						_width: 0
					},
					{
						field: "Duration",
						title: "Duration (secs)",
						filterable: true,
						_width: 100
					},
					{
						field: "DurationinMins",
						title: "Duration (mins)",
						filterable: true,
						_width: 100
					}
				]
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				currentAlarms: undefined,
				alarmsOnly: true,
				gridInView: false,
				exportTitle: ""
			}
		}
	};

	hourMeterReadingEventsGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			take: 15
		},

		columnsConfig: [
			{
				field: "Reading",
				title: "Reading",
				filterable: true,
				_width: 50
			},
			{
				field: "Date",
				title: "Date",
				filterable: true,
				filter: "date",
				_width: 100
			},
			{
				field: "CreatorUser",
				title: "Creator User",
				filterable: true,
				_width: 100
			}
		]
	};

	public widgetTabs = [
		{ textLabel: "Quick View", rendered: true },
		{ textLabel: "Data", rendered: true },
		{ textLabel: "Amps", rendered: false },
		{ textLabel: "Volts", rendered: false },
		{ textLabel: "Alerts", rendered: false },
		{ textLabel: "Last 1000 Events", rendered: false },
		{ textLabel: "Last 1000 Faults", rendered: false },
		{ textLabel: "Statistics", rendered: false },
		{ textLabel: "Maintenance", rendered: false }
	];

	dashboardTimeZoneChangedSubscription: Subscription;
	selectedMatTabLabel: string;
	assetId: any;
	tagGraphSingleModalSubscription: any;
	public gridContextMenuItems = ["Quick Trend of Tag"];
	public guid: string;

	constructor(public dataService: DataService, 
				private signalRCore: SignalRCoreService, 
				private ref: ChangeDetectorRef, 
				private kendoSettingsService: KendoSettingsService, 
				private dashboardService: DashboardService, 
				private toastr: ToastrService, 
				private utilityService: UtilityService, 
				private dialog: MatDialog,
				private zone: NgZone
			) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		//Need to evaluate if the component is a widget or a popup. If it is a widget, we need to make sure it is not in view until the subscription tells us it is. If not, we can assume it is a popup, and that it is in fact in view.
		if (this.fromDashboard === true) {
			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
				if (data.widgetId === this.widgetObject.WidgetId) {
					if (this.tagDataRowForCalculation !== undefined) {
						this.tagDataRowHeight = this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}

					if (this.eventDataRowForCalculation !== undefined) {
						this.eventDataRowHeight = this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}

					console.log(this.isWidgetInView);
					console.log(this.widgetObject.isWidgetInView);
					if (this.isWidgetInView === false && data.isInView === true) {
						// this.loadDataForSelectedTab();
					}
					this.isWidgetInView = data.isInView;
				}
			});
		} else {
			this.isWidgetInView = true;
		}

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					if (Global.Theme === "light") {
						this.theme = "light";
					} else if (Global.Theme === "dark") {
						this.theme = "dark";
					}
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			if (Global.Theme === "light") {
				this.theme = "light";
			} else if (Global.Theme === "dark") {
				this.theme = "dark";
			}
			this.initialize();
		}

		this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((theme: any) => {
			this.theme = theme;
			this.gaugeTextColor = theme === "dark" ? "#eceaea" : "#858585";
		});

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				console.log(data);
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					console.log("Widget Time Zone Changed");
					this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);

					Object.values(this.kendoGrids).forEach((grid) => {
						// Adjust visible and hidden columns
						const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
						const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
						const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

						localDateCol.hidden = this.timeZoneType !== "User Time";
						siteDateCol.hidden = this.timeZoneType !== "Site Time";
						utcDateCol.hidden = this.timeZoneType !== "UTC Time";
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;

						const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
						const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
						const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
						if (localEndDateCol != undefined) {
							localEndDateCol.hidden = this.timeZoneType !== 'User Time';
							localEndDateCol.includeInChooser = !localEndDateCol.hidden;
						}
						if (siteEndDateCol != undefined) {
							siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
							siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
						}
						if (utcEndDateCol != undefined) {
							utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
							utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
						}
					});
				}
				//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
				this.initialize();
			});
		}
		
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}

		if (this.dashboardTimeZoneChangedSubscription) {
			this.dashboardTimeZoneChangedSubscription.unsubscribe();
		}

		if (this.checkAlarmNotificationsInterval) {
			clearInterval(this.checkAlarmNotificationsInterval);
		}
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
	}

	sliderSelect(event: Event, obj: any) {
		console.log("obj = %O", obj);
	}

	saveGridSettings() {
		if (this.fromDashboard) {
			let hiddenColsArray = [];
			let gridObjectsArray = [];
			if (this.tagDataGrid) {
				hiddenColsArray.push(this.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "tagDataGrid",
					gridObject: this.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.tags.gridSettings.state
				});
			}

			if (this.alarmDataGrid) {
				hiddenColsArray.push(this.alarmDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "alarmDataGrid",
					gridObject: this.alarmDataGrid.kendoGridParent,
					gridState: this.kendoGrids.activeAlerts.gridSettings.state
				});
			}

			if (this.hourMeterReadingEventsGrid) {
				hiddenColsArray.push(this.hourMeterReadingEventsGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "hourMeterReadingEventsGrid",
					gridObject: this.hourMeterReadingEventsGrid.kendoGridParent,
					gridState: this.hourMeterReadingEventsGridSettings.state
				});
			}

			if (this.last1000EventsGrid) {
				hiddenColsArray.push(this.last1000EventsGrid.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "last1000EventsGrid",
					gridObject: this.last1000EventsGrid.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.last1000Events.gridSettings.state
				});
			}
			if (this.last1000AlarmsGrid) {
				hiddenColsArray.push(this.last1000AlarmsGrid.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "last1000AlarmsGrid",
					gridObject: this.last1000AlarmsGrid.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.last1000Alarms.gridSettings.state
				});
			}

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(hiddenColsArray, this.widgetObject.WidgetId); // update this to ignore columns not inccluded in chooser
			this.widgetObject.WidgetKendoUIJson = this.kendoSettingsService.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId).then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
		}
	}

	mapGridSettings(gridSettings: GridSettings) {
		if (this.fromDashboard) {
			const state = gridSettings.state;
			Global.User.DebugMode && console.log(this.componentName + ": state = %O", state);
			let emptyArray: any = [];
			return {
				state,
				columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
				gridData: this.dataService.cache !== undefined ? process(this.kendoGrids.tags.data, state) : emptyArray
			};
		}
	}

	initialize() {
		Global.User.DebugMode && console.log(this.componentName + ": initialize invoked...");

		if (this.fromDashboard && !this.widgetObject.WidgetGateSystemId) {
			this.isDataLoading = false;
			return;
		}

		this.selectedMatTabLabel = this.widgetTabs[0].textLabel;
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.fromDashboard ? this.widgetObject : this.dataObject);

		this.cacheJbtStandardObsLookup = this.dataService.cache.jbtStandardObservationsObject;
		this.cacheTagLookup = this.dataService.cache.tagsObject;

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetGateSystemId) {
				if (this.widgetObject.WidgetId !== undefined) {
					this.finishInitializingWidget();
				} else if (this.gpuSummaryCreatedEvent) {
					this.gpuSummaryCreatedSubscription = this.gpuSummaryCreatedEvent.subscribe((data) => {
						if (data.WidgetId === this.widgetObject.WidgetId) {
							this.finishInitializingWidget();
						}
					});
				}
			}
		} else {
			this.assetObj = this.dataObject.assetObj;
			this.kendoGrids.last1000Events.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${this.assetObj.SiteName}_${this.assetObj.GateName}_${this.assetObj.Type}`;
			this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${this.assetObj.SiteName}_${this.assetObj.GateName}_${this.assetObj.Type}`;

			// Get the Global Theme and set the text colors for the gauges
			this.theme = Global.Theme;
			this.gaugeTextColor = this.theme === "dark" ? "#eceaea" : "#858585";

			// Get Asset Info
			this.assetBaseImageURL = "assets\\" + this.assetObj.BaseUnitImageURL;
			this.finishInitializingWidget();
		}
	}

	finishInitializingWidget() {
		Global.User.DebugMode && console.log(this.componentName + ": finishInitializingWidget invoked...");

		if (!this.assetObj) {
			this.assetObj = this.dataService.cache.assets.find((a) => a.SiteId == this.widgetObject.WidgetSiteId && a.ParentSystemId == this.widgetObject.WidgetGateSystemId && a.Name == "GPU");
			this.kendoGrids.last1000Events.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${this.assetObj.Site.Name}_${this.assetObj.ParentSystem.Name}_${this.assetObj.AssetTypeName}`;
			this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${this.assetObj.Site.Name}_${this.assetObj.ParentSystem.Name}_${this.assetObj.AssetTypeName}`;
		}
		this.assetId = this.assetObj.Id;

		this.siteUTCTimeOffset = this.dataService.cache.sitesObject[this.widgetObject ? this.widgetObject.WidgetSiteId : this.assetObj.SiteId].UTCTimeOffset;
		this.kendoGrids.last1000Events.config.siteUTCTimeOffset = this.siteUTCTimeOffset;
		this.kendoGrids.last1000Alarms.config.siteUTCTimeOffset = this.siteUTCTimeOffset;

		console.log("Getting tags....");
		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(this.assetObj.Id.toString()).subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + ": dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O", data);
			this.formatTagDataForGrid(data);
		});

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetKendoUIJson) {
				// Reload saved Kendo Grid Settings
				let gridColumnConfigs = [];
				let jsonObjectParsed = this.kendoSettingsService.parseReturnedSettingsForDates(this.widgetObject.WidgetKendoUIJson);
				let tagDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "tagDataGrid");
				let alarmDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "alarmDataGrid");
				let hourMeterReadingEventsGridSettings = jsonObjectParsed.find((grid) => grid.name == "hourMeterReadingEventsGrid");
				let last1000EventsGridSettings = jsonObjectParsed.find((grid) => grid.name == "last1000EventsGrid");
				let last1000AlarmsGridSettings = jsonObjectParsed.find((grid) => grid.name == "last1000AlarmsGrid");

				if (tagDataGridSettings) {
					let mergedTemplateAndSavedColumnsForData = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.tags.gridSettings, tagDataGridSettings);
					this.kendoGrids.tags.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForData);
					gridColumnConfigs.push(this.kendoGrids.tags.gridSettings.columnsConfig);
				}

				if (alarmDataGridSettings) {
					let mergedTemplateAndSavedColumnsForAlarms = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.activeAlerts.gridSettings, alarmDataGridSettings);
					this.kendoGrids.activeAlerts.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForAlarms);
					gridColumnConfigs.push(this.kendoGrids.activeAlerts.gridSettings.columnsConfig);
				}

				if (hourMeterReadingEventsGridSettings) {
					let mergedTemplateAndSavedColumnsForHourMeter = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.hourMeterReadingEventsGridSettings, hourMeterReadingEventsGridSettings);
					this.hourMeterReadingEventsGridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForHourMeter);
					gridColumnConfigs.push(this.hourMeterReadingEventsGridSettings.columnsConfig);
				}

				if (last1000EventsGridSettings) {
					let mergedTemplateAndSavedColumnsForLast1000Events = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.last1000Events.gridSettings, last1000EventsGridSettings);
					this.kendoGrids.last1000Events.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForLast1000Events);
					gridColumnConfigs.push(this.kendoGrids.last1000Events.gridSettings.columnsConfig);
				}

				if (last1000AlarmsGridSettings) {
					let mergedTemplateAndSavedColumnsForLast1000Alarms = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.last1000Alarms.gridSettings, last1000AlarmsGridSettings);
					this.kendoGrids.last1000Alarms.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForLast1000Alarms);
					gridColumnConfigs.push(this.kendoGrids.last1000Alarms.gridSettings.columnsConfig);
				}

				this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(gridColumnConfigs, this.widgetObject.WidgetId);
			}

			Object.values(this.kendoGrids).forEach((grid) => {
				// Adjust visible and hidden columns
				const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
				const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");

				const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

				localDateCol.hidden = this.timeZoneType !== "User Time";
				siteDateCol.hidden = this.timeZoneType !== "Site Time";
				utcDateCol.hidden = this.timeZoneType !== "UTC Time";
				localDateCol.includeInChooser = !localDateCol.hidden;
				siteDateCol.includeInChooser = !siteDateCol.hidden;
				utcDateCol.includeInChooser = !utcDateCol.hidden;

				const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
				const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
				const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
				if (localEndDateCol != undefined) {
					localEndDateCol.hidden = this.timeZoneType !== 'User Time';
					localEndDateCol.includeInChooser = !localEndDateCol.hidden;
				}
				if (siteEndDateCol != undefined) {
					siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
					siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
				}
				if (utcEndDateCol != undefined) {
					utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
					utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
				}
			});
		}

		Object.values(this.kendoGrids).forEach((grid) => {
			// Adjust visible and hidden columns
			const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
			const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");

			const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

			localDateCol.hidden = this.timeZoneType !== "User Time";
			siteDateCol.hidden = this.timeZoneType !== "Site Time";
			utcDateCol.hidden = this.timeZoneType !== "UTC Time";
			localDateCol.includeInChooser = !localDateCol.hidden;
			siteDateCol.includeInChooser = !siteDateCol.hidden;
			utcDateCol.includeInChooser = !utcDateCol.hidden;

			const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
			const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
			const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
			if (localEndDateCol != undefined) {
				localEndDateCol.hidden = this.timeZoneType !== 'User Time';
				localEndDateCol.includeInChooser = !localEndDateCol.hidden;
			}
			if (siteEndDateCol != undefined) {
				siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
				siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
			}
			if (utcEndDateCol != undefined) {
				utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
				utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
			}
		});

		if (this.dataObject !== undefined) {
			this.dataObject.isDisplayDataLive = true;
		} else if (this.widgetObject !== undefined) {
			this.widgetObject.isDisplayDataLive = true;
		}
	}

	checkAlarmNotifications(tags: any) {

		tags.forEach(t => {

			let userAlert = this.dataService.cache.emailTagUsers.find((a) =>
								a.UserId == Global.User.currentUser.Id &&
								(a.TagId == t.TagId ||
									(a.JbtStandardObservationId == t.JBTStandardObservationId && a.JbtStandardObservationSiteId == t.SiteId )
								));

			t.alertNotification = userAlert == undefined ? false : true;

			// set the title with alert info
			if(userAlert != undefined) {

				let title = "Alert notifications set for " + t.JBTStandardObservation.Name;
				let alertObject = this.GetAlertNotificationDetails(userAlert);

				if(alertObject.Email == true && alertObject.TextMessage == true) {
					title += " email and text delivery";
				}
				else {
					title += alertObject.Email == true ? " email delivery" : "";
					title += alertObject.TextMessage == true ? " text delivery" : "";
				}

				title += " on " + alertObject.DaysSelected;
				title += " during "+ alertObject.HoursSelected;

				t.alertNotificationTitle = title;
			}
			else {
				t.alertNotificationTitle = "Click to set alert management settings.";
			}

		});

	}


	GetAlertNotificationDetails(userAlert) : any {

		let alertObject: any = {
			siteId: 0,
			siteName: '',
			gateSystemId: 0,
			gateSystemName: '',
			category: '',
			Email: 0,
		};


		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if(userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		}
		else {
			alertObject.DaysSelected = "select days";
		}


		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if(userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		}
		else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}

	formatTagDataForGrid(tags: any) {
		Global.User.DebugMode && console.log(this.componentName + ": formatTagDataForGrid invoked...");
		this.buildHourMeterReadingFormQuestions();
		this.checkAlarmNotifications(tags);

		this.checkAlarmNotificationsInterval = setInterval(() => {
			this.checkAlarmNotifications(tags);
		}, 10000);

		this.assetTagIds = this.dataService.cache.assetsObject[this.assetId].Tags.map((t) => t.TagId);
		this.alarmTagIds = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm" || t.Severity == "Warning").map((t) => t.TagId);

		//Quick View tags
		let quickViewTags = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => this.quickViewIds.includes(t.JBTStandardObservationId));
		this.quickViewTagIds = quickViewTags.map((t) => t.TagId);

		this.kendoGrids.last1000Events.config.assetTagIds = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => t.Severity == "Informational" || t.Severity == "Warning").map((t) => t.TagId);
		this.kendoGrids.last1000Alarms.config.assetTagIds = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm").map((t) => t.TagId);
		// this.kendoGrids.last1000Alarms.config.currentAlarms = this.kendoGrids.activeAlerts.data.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm");

		let statusIds = [12374, 4504, 13760]; // Unit On, Power On, Unit Running Status
		let activeAlarmIds = [12323]; // Alarm Is Active

		this.countOfAlarms = this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags.length;
		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetObj.Id].ActiveAlarmTags);

		// Get Data for Amps Tab
		let ampsOutAvg = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 1942 || t.JBTStandardObservationId == 14394);
		this.AmpsOutShadowData.Average = ampsOutAvg == undefined ? undefined : parseFloat(ampsOutAvg.Value);

		let ampsOutPhsA = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 4439 || t.JBTStandardObservationId == 1857 || t.JBTStandardObservationId == 14408);
		this.AmpsOutShadowData.PhaseA = ampsOutPhsA == undefined ? undefined : parseFloat(ampsOutPhsA.Value);

		let ampsOutPhsB = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 4441 || t.JBTStandardObservationId == 1858 || t.JBTStandardObservationId == 14412);
		this.AmpsOutShadowData.PhaseB = ampsOutPhsB == undefined ? undefined : parseFloat(ampsOutPhsB.Value);

		let ampsOutPhsC = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 4443 || t.JBTStandardObservationId == 1859 || t.JBTStandardObservationId == 14421);
		this.AmpsOutShadowData.PhaseC = ampsOutPhsC == undefined ? undefined : parseFloat(ampsOutPhsC.Value);

		this.AmpsOutShadowData.GpuStatus = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => statusIds.includes(t.JBTStandardObservationId)).some((t) => t.Value == "1") ? true : false;
		this.AmpsOutShadowData.ActiveAlarms = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => activeAlarmIds.includes(t.JBTStandardObservationId)).some((t) => t.Value == "1") ? true : false;

		// Get Data for Volts Tab
		let voltsOutAvg = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 1935 || t.JBTStandardObservationId == 14416);
		this.VoltsOutShadowData.Average = voltsOutAvg == undefined ? undefined : parseFloat(voltsOutAvg.Value);

		let voltsOutPhsA = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 4440 || t.JBTStandardObservationId == 14431);
		this.VoltsOutShadowData.PhaseA = voltsOutPhsA == undefined ? undefined : parseFloat(voltsOutPhsA.Value);

		let voltsOutPhsB = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 4442 || t.JBTStandardObservationId == 14429);
		this.VoltsOutShadowData.PhaseB = voltsOutPhsB == undefined ? undefined : parseFloat(voltsOutPhsB.Value);

		let voltsOutPhsC = this.dataService.cache.assetsObject[this.assetId].Tags.find((t) => t.JBTStandardObservationId == 4444 || t.JBTStandardObservationId == 14403);
		this.VoltsOutShadowData.PhaseC = voltsOutPhsC == undefined ? undefined : parseFloat(voltsOutPhsC.Value);

		this.VoltsOutShadowData.GpuStatus = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => statusIds.includes(t.JBTStandardObservationId)).some((t) => t.Value == "1") ? true : false;
		this.VoltsOutShadowData.ActiveAlarms = this.dataService.cache.assetsObject[this.assetId].Tags.filter((t) => activeAlarmIds.includes(t.JBTStandardObservationId)).some((t) => t.Value == "1") ? true : false;

		this.isDataLoading = false;
		this.getSignalRUpdates();
	}

	alertsStatus(activeAlertsData: any) {
		//Critical
		if (activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 3).length > 0)
			return 3;
		//Alarm
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 1).length > 0
		)
			return 1;
		//Warning
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 2).length > 0
		)
			return 2;
	}

	getSignalRUpdates() {
		let assetObjectInCache = this.dataService.cache.assetsObject[this.assetObj.Id];

		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);
				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTagGridData(tag);
					this.updateAlarmGridData();
				});
			});
	}

	matTabHasChanged(e) {
		console.log(e);
		this.selectedMatTabLabel = e.tab.textLabel;
		this.widgetTabs.find((tab) => tab.textLabel == this.selectedMatTabLabel).rendered = true;
		this.kendoGrids.last1000Events.config.gridInView = this.selectedMatTabLabel === "Last 1000 Events" ? true : false;
		this.kendoGrids.last1000Alarms.config.gridInView = this.selectedMatTabLabel === "Last 1000 Faults" ? true : false;

		switch (this.selectedMatTabLabel) {
			case "Data":
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
				}
				break;

			case "Amps":
				this.AmpsOutData = _.cloneDeep(this.AmpsOutShadowData);
				break;

			case "Volts":
				this.VoltsOutData = _.cloneDeep(this.VoltsOutShadowData);
				break;

			case "Alerts":
				if (this.alarmDataGrid) {
					this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
				}
				break;

			case "Last 1000 Events":
				if (this.kendoGrids.last1000Events.isRendered) {
					this.last1000EventsGrid.updateDataAfterTabSwitch();
				} else {
					this.kendoGrids.last1000Events.isRendered = true;
				}
				break;
			case "Last 1000 Faults":
				if (this.kendoGrids.last1000Alarms.isRendered) {
					this.last1000AlarmsGrid.updateDataAfterTabSwitch();
				} else {
					this.kendoGrids.last1000Alarms.isRendered = true;
				}
				break;

			case "Statistics":
				this.buildHourMeterReadingFormQuestions();
				break;

			case "Maintenance":
				this.buildMaintenanceQuestions();
				break;
		}
	}

	updateAlarmGridData() {
		if (this.alarmDataGrid) {
			this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
		}

		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
	}

	updateTagGridData(tagObj: any) {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
		}

		// // Update Linear Gauge Values
		switch (tagObj.JBTStandardObservationId) {
			case 56885: //Update Hour Meter Reading Value
				this.currentHourMeterReadingValue = tagObj.Value;
				break;
			case 1942:
				this.AmpsOutShadowData.Average = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Amps") {
					this.AmpsOutShadowData.Average = parseFloat(tagObj.Value);
				}
				break;

			case 4439:
				this.AmpsOutShadowData.PhaseA = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Amps") {
					this.AmpsOutShadowData.PhaseA = parseFloat(tagObj.Value);
				}
				break;

			case 4441:
				this.AmpsOutShadowData.PhaseB = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Amps") {
					this.AmpsOutShadowData.PhaseB = parseFloat(tagObj.Value);
				}
				break;

			case 4443:
				this.AmpsOutShadowData.PhaseC = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Amps") {
					this.AmpsOutShadowData.PhaseC = parseFloat(tagObj.Value);
				}
				break;

			case 12374:
				this.AmpsOutShadowData.GpuStatus = tagObj.Value == "1" ? true : false;
				this.VoltsOutShadowData.GpuStatus = tagObj.Value == "1" ? true : false;
				if (this.isWidgetInView && this.selectedMatTabLabel == "Amps") {
					this.AmpsOutShadowData.GpuStatus = tagObj.Value == "1" ? true : false;
				} else if (this.isWidgetInView && this.selectedMatTabLabel == "Volts") {
					this.VoltsOutShadowData.GpuStatus = tagObj.Value == "1" ? true : false;
				}
				break;

			case 12323:
				this.AmpsOutShadowData.ActiveAlarms = tagObj.Value == "1" ? true : false;
				this.VoltsOutShadowData.ActiveAlarms = tagObj.Value == "1" ? true : false;
				if (this.isWidgetInView && this.selectedMatTabLabel == "Amps") {
					this.AmpsOutShadowData.ActiveAlarms = tagObj.Value == "1" ? true : false;
				} else if (this.isWidgetInView && this.selectedMatTabLabel == "Volts") {
					this.VoltsOutShadowData.ActiveAlarms = tagObj.Value == "1" ? true : false;
				}
				break;

			case 1935:
				this.VoltsOutShadowData.Average = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Volts") {
					this.VoltsOutShadowData.Average = parseFloat(tagObj.Value);
				}
				break;

			case 4440:
				this.VoltsOutShadowData.PhaseA = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Volts") {
					this.VoltsOutShadowData.PhaseA = parseFloat(tagObj.Value);
				}
				break;

			case 4442:
				this.VoltsOutShadowData.PhaseB = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Volts") {
					this.VoltsOutShadowData.PhaseB = parseFloat(tagObj.Value);
				}
				break;

			case 4444:
				this.VoltsOutShadowData.PhaseC = parseFloat(tagObj.Value);
				if (this.isWidgetInView && this.selectedMatTabLabel == "Volts") {
					this.VoltsOutShadowData.PhaseC = parseFloat(tagObj.Value);
				}
				break;
		}
	}

	buildMaintenanceQuestions() {
		if (this.assetObj !== undefined) {
			this.isMaintenanceFormLoading = true;
			this.fullAssetRecord = this.dataService.cache.assetsObject[this.assetObj.Id];

			Global.User.DebugMode && console.log(this.componentName + ": this.fullAssetRecord = %O", this.fullAssetRecord);
			this.maintenanceMode = new FormControl(this.fullAssetRecord.MaintenanceMode);
			this.outOfService = new FormControl(this.fullAssetRecord.OutOfService);
			this.maintenanceModeReasonId = new FormControl(this.fullAssetRecord.MaintenanceModeReasonId);
			this.outOfServiceReasonId = new FormControl(this.fullAssetRecord.OutOfServiceReasonId);

			this.maintenanceForm = new FormGroup({
				maintenanceMode: this.maintenanceMode,
				outOfService: this.outOfService,
				maintenanceModeReasonId: this.maintenanceModeReasonId,
				outOfServiceReasonId: this.outOfServiceReasonId
			});

			this.maintenanceModeReasons = this.dataService.cache.assetMaintenanceModeReasons;
			this.outOfServiceReasons = this.dataService.cache.assetOutOfServiceReasons;

			this.maintenanceForm.valueChanges.subscribe((val) => {
				Global.User.DebugMode && console.log(this.componentName + ": val = %O", val);

				if ((!this.maintenanceForm.value.maintenanceMode || this.maintenanceForm.value.maintenanceMode == "") && (!this.maintenanceForm.value.outOfService || this.maintenanceForm.value.outOfService == "")) {
					this.saveValue(null, this.maintenanceForm); //-- have to save the asset since the maintenance mode and out of service have both been set to false. --Kirk T. Sherer, October 21, 2020.
				}
			});

			this.isMaintenanceFormLoading = false;
		}
	}

	saveValue(event: Event, obj: any) {
		var service = this;
		if (event) {
			event.stopPropagation();
		}

		var sqlStatement = this.maintenanceFormOptions.saveStoredProcedureName + " @UserId=" + Global.User.currentUser.Id + ", @AssetId=" + this.assetObj.Id + ", @MaintenanceMode=" + (obj.value.maintenanceMode ? "1" : "0") + ", @OutOfService=" + (obj.value.outOfService ? "1" : "0") + ", @MaintenanceModeReasonId=" + (obj.value.maintenanceModeReasonId == "" || !obj.value.maintenanceMode ? "null" : obj.value.maintenanceModeReasonId) + ", @OutOfServiceReasonId=" + (obj.value.outOfServiceReasonId == "" || !obj.value.outOfService ? "null" : obj.value.outOfServiceReasonId);
		Global.User.DebugMode && console.log(this.componentName + ": SQL Statement = " + sqlStatement);

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//-- SECTION FOR SENDING OUT SIGNALR MESSAGE IMMEDIATELY BEFORE SENDING THE STORED PROCEDURE TO UPDATE THE ACTUAL ASSET RECORD AND CREATE THE OBSERVATIONS.
		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		var assetInCache = this.dataService.cache.assetsObject[this.assetObj.Id];
		assetInCache.Tags.forEach((tag:ITag) => {
			console.log("Id: " + tag.Id + ", Name: " + tag.Name + ", SimpleName: " + tag.SimpleName);
		});
		//-- Find the out of service and maintenance mode tags for this asset and build a set of SignalR messages so we can update the application immediately while the stored procedure is updating SQL Server.
		var maintenanceModeOutOfServiceTags = assetInCache.Tags.where((tag:ITag) => {

			return (tag.SimpleName?.toLowerCase().indexOf("is in maintenance mode") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("maintenance mode reason") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("is out of service") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("out of service reason") > -1)
 	    } ).toArray();

		//-- creating SignalR message for each of the four tags.
		if (maintenanceModeOutOfServiceTags.length > 0) {
			var signalRDataObject = "";
			maintenanceModeOutOfServiceTags.forEach((tag:ITag) => {
				signalRDataObject += "i!" + tag.Id + "~d!" + this.utilityService.DateTimeInMilliseconds(new Date()) + "~v!";
				var tagValue = "";
				switch (tag.SimpleName?.toLowerCase())
				{
					case "is in maintenance mode":
						tagValue = obj.value.maintenanceMode ? "1" : "0";
						break;
					case "maintenance mode reason":
						tagValue = obj.value.maintenanceModeReasonId == "" || !obj.value.maintenanceMode ? "" : obj.value.maintenanceModeReasonId;
						break;
					case "is out of service":
						tagValue = obj.value.outOfService ? "1" : "0";
						break;
					case "out of service reason":
						tagValue = obj.value.outOfServiceReasonId == "" || !obj.value.outOfService ? "" : obj.value.outOfServiceReasonId;
						break;
				}
				signalRDataObject += tagValue + "\r\n";
			});
			if (signalRDataObject != "") {
				console.log("broadcasting SignalR Message about changes to asset's maintenance mode / out of service: %O", signalRDataObject);
				this.signalRCore.broadcast("o", signalRDataObject, "PS" + this.assetObj.Id);
			}
		}

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log(service.componentName + ": data = %O", data);
			//-- update data cache since leaving the Maintenance tab and coming back to it will reload what the asset was originally set to in the data cache. --Kirk T. Sherer, April 22, 2023.
			var currentAsset = service.dataService.cache.assetsObject[service.assetObj.Id];
			currentAsset.MaintenanceMode = obj.value.maintenanceMode;
			currentAsset.MaintenanceModeReasonId = obj.value.maintenanceMode == false ? null : obj.value.maintenanceModeReasonId;
			currentAsset.OutOfService = obj.value.outOfService;
			currentAsset.OutOfServiceReasonId = obj.value.outOfService == false ? null : obj.value.outOfServiceReasonId;

			service.utilityService.showToastMessageShared({
				type: "success",
				message: "Asset maintenance updated."
			});

			// this.toastr.success('Asset maintenance updated.');
		});
	}

	buildHourMeterReadingFormQuestions() {
		this.isHourMeterReadingFormLoading = true;

		this.editHeader = "Hour meter Reading";
		this.editSubHeader = "Please enter the Hour meter reading";
		this.hourMeterReadingFields = [];

		var reading = new NumberQuestion({
			key: "@Reading",
			label: "Hour Meter Reading",
			value: null,
			required: true,
			title: "Please enter the Hour meter reading.",
			order: 1
		});

		this.hourMeterReadingFields.push(reading);

		Global.User.DebugMode && console.log("gpu-summary: this.hourMeterReadingFields = %O", this.hourMeterReadingFields);

		this.isHourMeterReadingFormLoading = false;

		this.getPastHistoryOfHourMeterReadingEvents();
	}

	submitHourMeterReadingEvent(submittedValues: string) {
		Global.User.DebugMode && console.log("gpu-summary: HourMeterReading event invoked... submittedValues: %O", submittedValues);
		var fieldListAsString = submittedValues.replace("{", "").replace("}", "").replace(/:/g, "=").replace(/\"/g, "");
		Global.User.DebugMode && console.log("gpu-summary: fieldListAsString = " + fieldListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode && console.log("gpu-summary: submittedValuesObject = %O", submittedValuesObject);
		Global.User.DebugMode && console.log("gpu-summary: Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				fieldListAsString += value ? value : "null";
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		Global.User.DebugMode && console.log("fieldListAsString = " + fieldListAsString);

		var saveStoredProcedure = this.hourMeterReadingFormOptions.saveStoredProcedureName;
		this.dataService.SQLActionAsPromise(saveStoredProcedure + " @AssetId=" + this.assetObj.Id + ", @UserId = " + Global.User.currentUser.Id + ", " + fieldListAsString).then((data: any) => {
			console.log("gpu-summary: " + saveStoredProcedure + " return value = %O", data);
			var currentHourMeterReadingEvent = data.first();
			this.getPastHistoryOfHourMeterReadingEvents(currentHourMeterReadingEvent);
		});
	}

	getPastHistoryOfHourMeterReadingEvents(lastEvent?: any, hourMeterReadingEvents?: any) {
		if (!this.hourMeterReadingEvents || (!lastEvent && !hourMeterReadingEvents)) {
			//-- if we don't have hourMeterReading events populated yet, then go get the list of hourMeterReading events again from SQL Server.
			this.dataService.SQLActionAsPromise("API.GS_HourMeterReading_ListOfPastReadingsByAssetId " + this.assetObj.Id).then((data: any) => {
				var hourMeterReadingEvents = data;
				var hourMeterReadingEventsSorted = _.orderBy(hourMeterReadingEvents, ["Id"], ["desc"]);
				this.hourMeterReadingEvents = hourMeterReadingEventsSorted;

				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				Global.User.DebugMode && console.log("gpu-summary: this.hourMeterReadingEventsGridSettings.gridData = %O", this.hourMeterReadingEventsGridSettings.gridData);
				if (this.hourMeterReadingEvents != undefined && this.hourMeterReadingEvents.length > 0) {
					let hourMeterTagRecord = this.dataService.cache.tags.find((t) => {
						return t.JBTStandardObservationId === 56885 && t.AssetId === this.assetObj.Id;
					});
					if (hourMeterTagRecord != undefined && (hourMeterTagRecord.Value != null || hourMeterTagRecord.Value != "")) this.currentHourMeterReadingValue = hourMeterTagRecord.Value;
				}
			});
		} else {
			if (hourMeterReadingEvents) {
				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				Global.User.DebugMode && console.log("gpu-summary: this.hourMeterReadingEventsGridSettings.gridData = %O", this.hourMeterReadingEventsGridSettings.gridData);
			} else {
				Global.User.DebugMode && console.log("gpu-summary: this.hourMeterReadingEvents = %O", this.hourMeterReadingEvents);
				Global.User.DebugMode && console.log("gpu-summary: lastEvent = %O", lastEvent);

				var hourMeterReadingEvents = this.hourMeterReadingEvents;
				hourMeterReadingEvents.push(lastEvent);
				var hourMeterReadingEventsSorted = _.orderBy(hourMeterReadingEvents, ["Id"], ["desc"]);
				this.hourMeterReadingEvents = hourMeterReadingEventsSorted;
				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				if (lastEvent != undefined && lastEvent.Reading > 0) {
					this.currentHourMeterReadingValue = lastEvent.Reading;
				}
			}
		}
	}
	public onRightClickSelect({ dataItem, item }): void {
		if (item === "Quick Trend of Tag") {
			const tagGraphSingleModal = this.dialog.open(DialogModalParentComponent, {
				width: "95%",
				height: "80%",
				data: {
					TagId: dataItem.TagId,
					widgetNameDisplay: "Tag Graph",
					WidgetName: "tag-graph",
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(this.widgetObject)
				},
				maxWidth: "100vw",
				maxHeight: "100vh"
			});
			this.tagGraphSingleModalSubscription = tagGraphSingleModal.afterClosed().subscribe((result) => {
				this.tagGraphSingleModalSubscription.unsubscribe();
			});
		}
	}
}
