import {
	Component,
	AfterViewInit,
	Optional,
	Input,
	ViewChild,
	Output,
	EventEmitter,
	OnInit,
} from '@angular/core';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { DataService } from '../../services/data.service';
import { Global } from '../../_constants/global.variables';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { MatDialog } from '@angular/material/dialog';
import { DialogModalParentComponent } from '../dialog-modal-parent/dialog-modal-parent.component';
import { DashboardService } from '../../services/dashboard.service';
import _, { MemoVoidIteratorCapped } from 'lodash';
import Swal from 'sweetalert2';
import { IGlobal } from '../../_models/global.model';
import { GseService } from '../../services/gse.service';
import { EditWidgetComponent } from '../edit-widget/edit-widget.component';
import { AlertManagementPopupComponent } from '../alert-management-popup/alert-management-popup.component';
import { Observable, Subject } from 'rxjs';
import { UtilityService } from '../../services/utility.service';
import { FileUploadListComponent } from '../file-upload-list/file-upload-list.component';
import { ToastrService } from 'ngx-toastr';
import { data } from 'jquery';


@Component({
	selector: 'lib-kendo-grid-parent',
	templateUrl: './kendo-grid-parent.component.html',
	styleUrls: ['./kendo-grid-parent.component.scss'],
})
export class KendoGridParentComponent implements OnInit, AfterViewInit {

	tooltipInformationObject: any = {};

	public progressbarColorScheme: any = [
		{
			className: 'progressbarColorScheme1',
			description: 'Color for value greater than 75',
			operator: 'greater than',
			values: [75]
		}, 	{
			className: 'progressbarColorScheme2',
			description: 'Color for value between 50 and 75',
			operator: 'between',
			values: [50, 75]
		}, 	{
			className: 'progressbarColorScheme3',
			description: 'Color for value between 25 and 49',
			operator: 'between',
			values: [25, 49]
		}, 	{
			className: 'progressbarColorScheme4',
			description: 'Color for value below 25',
			operator: 'less than',
			values: [25]
		}
	]

	public progressbarColorSchemeDpfSootLoadPercent: any = [
		{
			className: 'progressbarColorScheme1',
			description: 'Color for value less than 50',
			operator: 'less than',
			values: [50]
		}, 	{
			className: 'progressbarColorScheme2',
			description: 'Color for value between 50 and 75',
			operator: 'between',
			values: [50, 75]
		}, 	{
			className: 'progressbarColorScheme4',
			description: 'Color for value greater than 75',
			operator: 'greater than',
			values: [75]
		}
	]
	public progressbarColorSchemeEngineCoolant: any = [
		{
			className: 'progressbarColorScheme4',
			description: 'Color for value less than 90',
			operator: 'less than',
			values: [90]
		}, 	{
			className: 'progressbarColorScheme3',
			description: 'Color for value between 90 and 95',
			operator: 'between',
			values: [90, 95]
		}, 	{
			className: 'progressbarColorScheme1',
			description: 'Color for value greater than 95',
			operator: 'greater than',
			values: [95]
		}
	]
	@Optional() @Input() public gridPurpose: any;
	@Optional() @Input() public gridDataSubject: Subject<any> = new Subject<any>();
	@Optional() @Input() public widgetObject: any;
	@Optional() @Input() configuration: any;
	@Optional() @Input() public dataObject: any;
	@Optional() @Input() public timeZoneType: any;
	@Optional() @Input() public assetsWithTags: any;
	@Optional() @Input() public dashboardTimeZoneChanged: any;
	@Optional() @Input() public assetsForSelectedZoneType: any[];
	@Optional() @Output() public toggleAssetInFormListEmitter: any =
		new EventEmitter<any>();
	@Optional() @Output() callSave: any = new EventEmitter<any>();
	@Optional() @Output() onRightClickSelectEmitter: any =
		new EventEmitter<any>();

	@Optional() @Output() addTagsToChart: any = new EventEmitter<any>();
	@Optional() @Output() openSummaryWidgetEmitter: any =
		new EventEmitter<any>();
	@Optional() @Output() showAssetOnMapEmitter: any = new EventEmitter<any>();
	@Optional() @Output() openWCLSummaryEmitter: any = new EventEmitter<any>();

	@Optional() @Output() checkForMetaDataInGSEService: any =
		new EventEmitter<any>();
	@Optional()
	@Output()
	processedRawDataForGridWithoutPagingDataChangedEmitter: any =
		new EventEmitter<any>();
	@Optional() @Output() createMetaDataPopup: any = new EventEmitter<any>();
	@Optional() @Input() public gridData: any;
	@Optional() @Input() exportable: any = true;
	@Optional() @Input() public height: any = 100;
	@Optional() @Input() filterable: any = 'menu';
	@Optional() @Input() sortable: boolean = true;
	@Optional() @Input() reorderable: boolean = true;
	@Optional() @Input() resizable: boolean = true;
	@Optional() @Input() groupable: boolean = false;
	@ViewChild('kendoGridParent') kendoGridParent: any;
	@Optional() @Input() gridSettings: any;
	@Optional() @Input() public hidden: boolean = false;
	@Optional() @Input() public rowClassColor: any;
	@Optional() @Input() public gridContextMenuItems: any;
	@Optional() @Input() public currentDataIndex: any;
	public processedRawDataForGrid: any;
	componentName: string = 'kendo-grid-parent: ';
	fullDataCacheSubscription: any;
	tagGraphSingleModalSubscription: any;
	gseSummaryModalSubscription: any;
	private swalWithBootstrapButtons: any;
	public iconHeight: number;
	public global: IGlobal = Global;
	editWidgetComponentModal: any;
	debugMode: boolean = false;
	@Optional() @Output() loadVideoEmitter: any = new EventEmitter<any>();
	@Optional() @Output() addWidgetToDashboardEmitter: any =
		new EventEmitter<any>();
	@Optional() @Input() columnMenu: any;
	public theme: string = 'light';
	processedRawDataForGridWithoutPaging: any;
	gridDataSubjectSubscriber: any;

	fileImageData: any;
	FileImageLibraryControlObject: any;

	constructor(
		public dataService: DataService,
		public dialog: MatDialog,
		private dashboardService: DashboardService,
		public gseService: GseService,
		private utilityService: UtilityService,
		private toastr: ToastrService,
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
		this.allData = this.allData.bind(this);
	}

	ngOnInit(): void {

		if (this.columnMenu === undefined) {
			this.columnMenu = {
				lock:
					this.widgetObject === undefined
						? true
						: this.widgetObject.scrollmode === 'scrollable'
						? true
						: false,
			};
		}

		this.gridDataSubjectSubscriber = this.gridDataSubject.subscribe((data) => {
			this.gridData = data;
			this.processGridAfterDataUpdate();
		})
	}

	processGridAfterDataUpdate() {
		if (this.gridData !== undefined) {
			//For Fleet Summary show Alarms as Amber,Critical as Red
			// this.gridData.forEach((record) => {
			// 	record.Severity = record.EffectiveSeverityLevelId ?
			// 	this.dataService.cache.jbtStandardObservationSeverityLevelsObject[record.EffectiveSeverityLevelId].Name :
			// 	   'Informational';
			// });
			if (this.widgetObject?.regexExpressionExists) {
				try {

					let localExpressions = []

					this.widgetObject.regexExpressions.forEach(expression => {
						localExpressions.push({ expression: new RegExp(expression.expression, 'g'), desc: expression.desc, columnName: expression.columnName });
					});


					let filteredSampleProducts = this.gridData.filter(dataRecord => {
						let evaluation = true;
						localExpressions.forEach(expObj => {
							if(!expObj.expression.test(dataRecord[expObj.columnName])) {
								evaluation = false;
							}

						});
						if(evaluation) {
							return dataRecord;
						}
					})
					this.processedRawDataForGrid = process(
						filteredSampleProducts,
						this.gridSettings.state
					);
					this.processedRawDataForGridWithoutPaging = process(filteredSampleProducts, {
						filter: this.gridSettings.state.filter,
					});
				} catch (err) {
					//utilityService.showToastMessageShared({type: 'error', message: 'Invalid Regular Expression', title: 'Error'});
					console.log("ERR:", err);
				}
			}
			else {
				let processedDataGridResultsWithoutPagination = process(
					this.gridData,
					{
						filter: this.gridSettings.state.filter,
						sort: this.gridSettings.state.sort,
						// group: this.gridSettings.state.group,
					}
				);
				if (processedDataGridResultsWithoutPagination.data.length > 0) {
					if (
						this.gridSettings.state.skip >
						processedDataGridResultsWithoutPagination.data.length
					) {
						let remainder =
							processedDataGridResultsWithoutPagination.data.length %
							this.gridSettings.state.take;
						this.gridSettings.state.skip =
							processedDataGridResultsWithoutPagination.data.length -
							remainder;
					}
				}
				this.processedRawDataForGrid = process(
					this.gridData,
					this.gridSettings.state
				);
				this.processedRawDataForGridWithoutPaging = process(this.gridData, {
					filter: this.gridSettings.state.filter,
				});
			}

		}
	}

	returnParsedNumber(value) {
		let parsedValue = parseFloat(value);
		return parsedValue;
	}

	ngAfterViewInit(): void {

		if (Global.User.currentUser.Username == 'dylan') {
			this.debugMode = true;
		}
		//any of the attribute bindings need to be done in the controller. I have already done it for data and scrollable, but will need to do it for filter, sort, skip, height, etc. 		console.log(this.kendoGridParent);
		this.iconHeight = Global.isMobile ? 15 : 30;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						if (Global.Theme === 'light') {
							this.theme = 'light';
						} else if (Global.Theme === 'dark') {
							this.theme = 'dark';
						}
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			if (Global.Theme === 'light') {
				this.theme = 'light';
			} else if (Global.Theme === 'dark') {
				this.theme = 'dark';
			}
		}
		this.processGridAfterDataUpdate();
		if (this.widgetObject !== undefined) {
			this.kendoGridParent.scrollable = this.widgetObject.scrollmode;
		} else {
			this.kendoGridParent.scrollable = 'scrollable';
		}
	}




	columnResizeManual(params, grid, c) {
		let foundIndex = grid.columnList.columns._results.findIndex(
			(column) => {
				return column.field === c.field;
			}
		);
		this.debugMode && console.log(foundIndex);

		let column = grid.columnList.columns._results[foundIndex];
		this.debugMode && console.log(column._width);
		if (params === 'bigger') {
			grid.columnList.columns._results[foundIndex]._width =
				grid.columnList.columns._results[foundIndex]._width + 50;
		}

		if (params === 'smaller') {
			if (grid.columnList.columns._results[foundIndex]._width < 50) {
				grid.columnList.columns._results[foundIndex]._width = 40;
			} else {
				grid.columnList.columns._results[foundIndex]._width =
					grid.columnList.columns._results[foundIndex]._width - 50;
			}
		}

		this.saveState();
	}

	filterChange(filter) {
		this.gridSettings.state.filter = filter;
		this.gridSettings.state.skip = 0;
		this.processedRawDataForGridWithoutPagingDataChangedEmitter.next(
			this.processedRawDataForGridWithoutPaging
		);
		this.processGridAfterDataUpdate();
		this.saveState();
	}

	public groupChange(groups: any): void {
		this.gridSettings.state.group = groups;
		this.processGridAfterDataUpdate();
		this.saveState();
	}

	sortChange(sort): void {
		this.gridSettings.state.sort = sort;
		this.processGridAfterDataUpdate();
		this.saveState();
	}

	pageChange(event: any) {
		this.gridSettings.state.skip = event.skip;
		this.gridSettings.state.take = event.take;
		this.processGridAfterDataUpdate();
		this.saveState();
	}

	onChange() {
		this.saveState();
	}

	columnLockedChange() {
		this.saveState();
	}

	onColumnResize() {
		this.saveState();
	}

	onReorder() {
		setTimeout(() => {
			this.saveState();
		}, 500);
	}

	showSeatBeltPositions(assetInfo) {
		let assetTags = this.dataService.cache.assetsObject[assetInfo.Id].Tags;

		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '60%' : '40%',
			data: {

				popupTitle: 'Total Seats & Seat Belts Info ',
				WidgetName: 'Seat-Belt-Info-Popup',
				assetTags: assetTags,
				canCreateDashboardWidgetFromPopup: false,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});

	}
	editNote(note, allowEditing){
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {

				popupTitle: 'Editing note: ',
				WidgetName: 'Edit-Note',
				note: note,
				canCreateDashboardWidgetFromPopup: false,
				allowEditing: allowEditing,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	deleteNote(note) {

		this.swalWithBootstrapButtons
		.fire({
			title: "Are you sure?",
			text: "You are trying to delete the current logbook entry.",
			showCancelButton: true,
			confirmButtonText: "Delete Logbook Entry",
			cancelButtonText: "Cancel",
			reverseButtons: false
		})
		.then((result: any) => {
			if (result.value) {
				this.dataService.SQLActionAsPromise("API.EntityLogDeleteLogById @Id=" + note.Id).then((data: any) => {
					Global.User.DebugMode && console.log("subjects = %O", data);

					// inform user
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "Logbook Entry has been deleted.",
						title: "Logbook Entry"
					});
				});
			}
			else {
				// inform user
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "Logbook Entry has NOT been deleted.",
						title: "Logbook Entry"
				});
			}
		});
	}


	configureWidgetObject(dataItem): any {
		var widgetObject;
		if(dataItem.category == 'site') {
			widgetObject = {
				WidgetSiteId: dataItem.id,
				VocationalSettingsJSON: null,
			};
		}
		else if(dataItem.category == 'tag') {
			const fleetConfig = {
				type: "Fleet",
				id: dataItem.fleetId,
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));

			widgetObject = {
				WidgetSiteId: dataItem.siteId,
				VocationalSettingsJSON: dataItem.fleetId ? stringFleetConfig : null,
			};
		}
		else if(dataItem.category == 'asset' && dataItem.fleetId == null) {
			widgetObject = {
				WidgetAssetId: dataItem.id,
				VocationalSettingsJSON: null,
			};
		}
		else if(dataItem.category == 'asset' && dataItem.fleetId != null) {
			const fleetConfig = {
				type: "Fleet",
				id: dataItem.id,
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));

			widgetObject = {
				WidgetAssetId: dataItem.id,
				VocationalSettingsJSON: stringFleetConfig,
			};
		}
		else if(dataItem.category == 'fleet') {
			const fleetConfig = {
				type: "Fleet",
				id: dataItem.id,
			};

			let stringFleetConfig = JSON.stringify(_.cloneDeep(fleetConfig));

			widgetObject = {
				WidgetSiteId: null,
				VocationalSettingsJSON: stringFleetConfig,
			};
		}

		return widgetObject;
	}


	openAlertManagementPopupComponent(dataItem, itemObj) {
		let widgetObject = this.configureWidgetObject(dataItem);
		let dataItemId = dataItem.id;

		const dlg = this.dialog.open(AlertManagementPopupComponent, {
			width: Global.isMobile ? "90%" : "40%",
			height: Global.isMobile ? "80%" : "70%",
			data: {
				alertLevel: dataItem.category,
				widget: widgetObject,
				dataItem: dataItem,
				item: itemObj,
			},
			maxWidth: "100vw",
			maxHeight: "100vh"
		});
		let d = dlg.afterClosed().subscribe((result) => {
			Global.User.DebugMode && console.log("grid data", this.gridData)
			Global.User.DebugMode && console.log("dataitem", dataItem)
			Global.User.DebugMode && console.log("dataItemId", dataItemId)
			if(dataItem == null) {
				//item has been removed
				let index = this.gridData.findIndex((d: any) => {return d.id == dataItemId});
				this.gridData.splice(index, 1);
				this.processGridAfterDataUpdate();
			}
		});
	}


	editAlert(alert){
		let dataItem = alert;
		let itemObj;
		if (dataItem.category == "asset") {
			itemObj = this.dataService.cache.assets.find((a) => a.Id == dataItem.id);
			this.openAlertManagementPopupComponent(dataItem, itemObj);

		}
		else if (dataItem.category == "tag" && dataItem.siteId == null && dataItem.fleetId == null) {
			itemObj = this.dataService.cache.tagsObject[dataItem.id];
			if(itemObj) {
				this.openAlertManagementPopupComponent(dataItem, itemObj);
			} else {
				this.dataService.SQLActionAsPromise("API.GetTagsByListOfIds '" + dataItem.id + "'")
					.then((data: any) => {
						console.log('tag data:', data);
						if(data.length > 0) {
							itemObj = data[0];
							itemObj.TagId = itemObj.Id;
							itemObj.ShortTagName = itemObj.StandardName;
							this.openAlertManagementPopupComponent(dataItem, itemObj);
						}
				});
			}
		}
		else if (dataItem.category == "tag" && (dataItem.siteId != null || dataItem.fleetId != null) ) {
			itemObj = this.dataService.cache.jbtStandardObservationsObject[dataItem.id];
			itemObj.ShortTagName = itemObj.Name;
			itemObj.JBTStandardObservationId = itemObj.Id;

			this.openAlertManagementPopupComponent(dataItem, itemObj);
		}
		else {
			this.openAlertManagementPopupComponent(dataItem, itemObj);
		}
	}

	deleteAlert(alert) {

		this.swalWithBootstrapButtons
		.fire({
			title: "Are you sure?",
			text: "You are trying to delete the current Alert Notification.",
			showCancelButton: true,
			confirmButtonText: "Delete Alert Notification",
			cancelButtonText: "Cancel",
			reverseButtons: false
		})
		.then((result: any) => {
			if (result.value) {
				this.dataService.SQLActionAsPromise("API.AlertManagementDeleteAlarmRecord @Id=" + alert.etu.Id + ", @UserId=" + Global.User.currentUser.Id).then((data: any) => {
					Global.User.DebugMode && console.log("subjects = %O", data);

					let index = this.gridData.findIndex((d: any) => {return d.id == alert.id});
					this.gridData.splice(index, 1);
					this.processGridAfterDataUpdate();

					// inform user
					this.utilityService.showToastMessageShared({
						type: "info",
						message: "Alert Notification has been deleted.",
						title: "Alert Notification"
					});
				});
			}
			else {
				// inform user
				this.utilityService.showToastMessageShared({
					type: "info",
					message: "Alert Notification has NOT been deleted.",
						title: "Alert Notification"
				});
			}
		});
	}


	openFileImageUploadWindow(dataItem, entityType: string, title: string, entityId?: number) {
		var service = this;

		if (entityId == undefined) {
			entityId = null;
		}

		var sqlStatement = "API.FileImageManagement 'List', '" + entityType + "', " + entityId + ", @UserId = " + Global.User.currentUser.Id;

		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + ": " + sqlStatement + " = %O", data);
			this.fileImageData = data;
			this.FileImageLibraryControlObject = {
				imageKeys: this.fileImageData.filter((i: any) => {
					return i.ImageKey;
				}),
				//---B
				removeUploadFunction(deletedImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement 'Delete', '" + entityType + "', " + entityId + ", '" + deletedImageKey + "'").then((deletedEntity: any) => {
						service.fileImageData.splice(deletedEntity);
					});
				},
				//---B
				addUploadFunction(newImageKey: string) {
					service.dataService.SQLActionAsPromise("API.FileImageManagement 'Add', '" + entityType + "', " + entityId + ", '" + newImageKey + "'").then((newEntity: any) => {
						service.fileImageData.push(newEntity);
					});
				},
				mode: null,
				listTitle: title,
				entityId: entityId,
				entityType: entityType,
				readOnly: dataItem.CreatorUserId == Global.User.currentUser.Id ? false : true,
				closeUploadWindowFunction() {
					service.FileImageLibraryControlObject = null;
				}
			};

			this.openFileUploadDialog(dataItem);
		});
	}

	openFileUploadDialog(dataItem): void {
		var innerWidth = window.innerWidth;
		const dialogRef = this.dialog.open(FileUploadListComponent, {
			width: "70%",
			height: "70%"
		});

		dialogRef.componentInstance.fileImageLibraryControlObject = this.FileImageLibraryControlObject;

		dialogRef.afterClosed().subscribe((result) => {
			Global.User.DebugMode && console.log(this.componentName + ": The File Upload dialog was closed");
			this.getCountOfFilesUploaded(dataItem, this.FileImageLibraryControlObject.entityId, this.FileImageLibraryControlObject.entityType);
		});
	}

	getCountOfFilesUploaded(dataItem: any, entityId: number, entityType: string) {
		var sqlStatement = "API.FileImageManagement 'Count', '" + entityType + "', " + entityId + ", @UserId = " + Global.User.currentUser.Id;
		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			var listOfFiles = data;
			dataItem.countOfFilesUploaded = listOfFiles.length > 0 ? listOfFiles.first().CountOfFilesUploaded : 0;
		});
	}

	public addTagToChart(element): void {
		Global.User.DebugMode && console.log(element);
		if (this.dataService.tempTagsToChart.includes(element.TagId)) {
			_.remove(this.dataService.tempTagsToChart, (TagId) => {
				return TagId == element.TagId;
			});
		} else {
			this.dataService.tempTagsToChart.push(element.TagId);
		}
		Global.User.DebugMode &&
			console.log('dataService Tags', this.dataService.tempTagsToChart);
	}

	public toggleFavorite(tag): void {
		let find = Global.User.currentUser.UserFavorites.find((favorite) => {
			return tag.TagId === favorite.TagId;
		});

		if (find !== undefined) {
			//remove from db and clear from cache in new dataservice method
			this.dataService.RemoveItemFromFavorites({
				FavoriteToRemove: find,
				TypeToRemove: 'TagId',
			});
		} else {
			this.dataService.AddItemToFavorites({
				itemId: tag.TagId,
				itemName: 'TagId',
			});
		}
	}

	checkForMetaDataInGSEServiceParent(tagObject) {
		this.checkForMetaDataInGSEService.emit(tagObject);
	}

	createMetaDataPopupParent(tagObject) {
		this.createMetaDataPopup.emit(tagObject);
	}

	saveState() {
		// if (this.configuration.isFromWidget) { // don't save grid settings if pca summary is a popup (no widgetId) check isFromWidget
		if (this.callSave.observers.length > 0) {
			this.callSave.next(this.gridSettings);
			this.debugMode && console.log('savedfromgridparentcomponent');
		}

		// }
	}

	updateRowColor = (context: RowClassArgs)  => {
		if(this.currentDataIndex !== undefined){
			if(context.index === this.currentDataIndex){
				return {
					'row-highlight': true,
				};
			}
		} else {
			let recentlyUpdated = context.dataItem.RecentlyUpdated;
		let recentlyUpdatedWarningTag =
			context.dataItem.RecentlyUpdated &&
			context.dataItem.Severity == 'Warning' &&
			context.dataItem.Value == context.dataItem.ValueWhenActive;
		let recentlyUpdatedAlarmTag =
			context.dataItem.RecentlyUpdated &&
			context.dataItem.Severity == 'Alarm' &&
			context.dataItem.Value == context.dataItem.ValueWhenActive;
		let recentlyUpdatedCriticalTag =
			context.dataItem.RecentlyUpdated &&
			context.dataItem.Severity == 'Critical' &&
			context.dataItem.Value == context.dataItem.ValueWhenActive;

		// if (context.dataItem.RecentlyUpdated === true) {
		// 	// this.debugMode && console.log(context.dataItem);
		// }
		return {
			recentlyUpdated: recentlyUpdated,
			recentlyUpdatedWarningTag: recentlyUpdatedWarningTag,
			recentlyUpdatedAlarmTag: recentlyUpdatedAlarmTag,
			recentlyUpdatedCriticalTag: recentlyUpdatedCriticalTag,
		};
		}

	}

	updateRowColorManual(context: RowClassArgs) {

	}

	public allData(): ExcelExportData {
		this.debugMode && console.log(this);
		const result: ExcelExportData = {
			data: process(this.gridData, {
				group: this.gridSettings.state.group,
				sort: this.gridSettings.state.sort,
			}).data,
			group: this.gridSettings.state.group,
		};

		return result;
	}

	openTagGraphSingle(passedTagObject, backupObservationId?): void {
		let tagObject: any;
		tagObject = this.getValue(
			passedTagObject.gse,
			passedTagObject.observationId,
			true
		);
		if (_.isNil(tagObject)) {
			tagObject = this.getValue(
				passedTagObject.gse,
				backupObservationId,
				true
			);
		}
		if (tagObject === null) {
			this.swalWithBootstrapButtons
				.fire({
					title: 'Error',
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: 'Ok',
					reverseButtons: false,
				})
				.then(() => {});
		} else {
			const tagGraphSingleModal = this.dialog.open(
				DialogModalParentComponent,
				{
					width: '95%',
					height: '80%',
					data: {
						TagId: tagObject.TagId,
						widgetNameDisplay: 'Tag Graph',
						WidgetName: 'tag-graph',
						isDisplayDataLive: true,
						timeZoneType:
							this.dashboardService.determineTimeZoneType(
								this.widgetObject
							),
					},
					maxWidth: '100vw',
					maxHeight: '100vh',
				}
			);
			this.tagGraphSingleModalSubscription = tagGraphSingleModal
				.afterClosed()
				.subscribe((result) => {
					this.tagGraphSingleModalSubscription.unsubscribe();
				});
		}
	}

	getValue(element, observationId, needObject) {
		let tag = element.Tags.find(
			(element) => element.JBTStandardObservationId === observationId
		);
		if (tag === undefined) {
			return null;
		} else if (needObject === undefined) {
			return tag.Value;
		} else if (needObject === true) {
			return tag;
		}
	}

	isNan(value) {
		return isNaN(value);
	}

	openPBBSummary(assetId: number) {
		let asset = this.assetsWithTags.find((a) => assetId == a.Id);

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					': ' +
					`PBB Summary for Asset ${assetId}  was opened.`
			);
		const pbbSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '100%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				popupTitle: `PBB Summary: ${this.widgetObject.WidgetSiteName} | Gate ${asset.GateName}`,
				siteName: this.widgetObject.WidgetSiteName,
				gateSystemName: asset.GateName,
				widgetNameDisplay: 'PBB Summary',
				assetObj: asset,
				WidgetName: 'PBB-Summary',
				TimeZoneId: this.widgetObject.TimeZoneId,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});
	}

	openPCASummary(assetId: number) {
		let asset = this.assetsWithTags.find((a) => assetId == a.Id);

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					': ' +
					`PCA Summary for Asset ${assetId}  was opened.`
			);
		const pcaSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				popupTitle: `PCA Summary: ${this.widgetObject.WidgetSiteName} | Gate ${asset.GateName}`,
				siteName: this.widgetObject.WidgetSiteName,
				gateSystemName: asset.GateName,
				widgetNameDisplay: 'PCA Summary',
				assetObj: asset,
				WidgetName: 'PCA-Summary',
				TimeZoneId: this.widgetObject.TimeZoneId,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					': PCA Summary for Asset with Id ' +
					assetId +
					'was opened.'
			);
	}

	openGPUSummary(assetId: number) {
		let asset = this.assetsWithTags.find((a) => assetId == a.Id);

		const gpuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				popupTitle: `GPU Summary: ${this.widgetObject.WidgetSiteName} | Gate ${asset.GateName}`,
				siteName: this.widgetObject.WidgetSiteName,
				gateSystemName: asset.GateName,
				widgetNameDisplay: 'GPU Summary',
				assetObj: asset,
				WidgetName: 'GPU-Summary',
				TimeZoneId: this.widgetObject.TimeZoneId,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					': GPU Summary for Asset with Id ' +
					assetId +
					' was opened.'
			);
	}
	openAHUSummary(assetId: number) {
		let asset = this.assetsWithTags.find((a) => assetId == a.Id);

		const gpuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? '90%' : '60%',
			height: Global.isMobile ? '90%' : '80%',
			data: {
				popupTitle: `AHU Summary: ${this.widgetObject.WidgetSiteName} | Gate ${asset.GateName}`,
				siteName: this.widgetObject.WidgetSiteName,
				gateSystemName: asset.GateName,
				widgetNameDisplay: 'AHU Summary',
				assetObj: asset,
				WidgetName: 'AHU-Summary',
				TimeZoneId: this.widgetObject.TimeZoneId,
			},
			maxWidth: '100vw',
			maxHeight: '100vh',
		});

		Global.User.DebugMode &&
			console.log(
				this.componentName +
					': AHU Summary for Asset with Id ' +
					assetId +
					' was opened.'
			);
	}

	toggleAssetInFormList(asset) {
		this.toggleAssetInFormListEmitter.next(asset);
	}

	onRightClickSelect(event) {
		this.onRightClickSelectEmitter.next(event);
	}

	openSummaryWidget(dataItem, shouldShowMap, shouldShowPlayback) {
		this.openSummaryWidgetEmitter.next({
			dataItem: dataItem,
			shouldShowMap: shouldShowMap,
			shouldShowPlayback: shouldShowPlayback,
		});
	}
	showAssetOnMap(dataItem) {
		this.showAssetOnMapEmitter.next(dataItem);
	}
	openWCLSummary(assetId) {
		this.openWCLSummaryEmitter.next(assetId);
	}

	loadVideo(dataItem) {
		this.loadVideoEmitter.next(dataItem);
	}

	addWidgetToDashboard(dataItem) {
		this.addWidgetToDashboardEmitter.next(dataItem);
	}

	openAlertsPopup(dataItem, alertLevel = "tag")
	{
		let widget;
		if(this.widgetObject == null) {
			widget = {
				WidgetSiteId: dataItem.SiteId,
				VocationalSettingsJSON: this.dataObject?.parentWidget?.VocationalSettingsJSON,
			}
		}

		const dlg = this.dialog.open(
			AlertManagementPopupComponent,
			{
				width: Global.isMobile ? '90%' : '40%',
				height: Global.isMobile ? '80%' : '70%',
				data: {
					alertLevel: alertLevel,
					widget: this.widgetObject != null ? this.widgetObject : widget,
					item: dataItem,
				},
				maxWidth: '100vw',
				maxHeight: '100vh',
			}
		);
		let d = dlg
			.afterClosed()
			.subscribe((result) => {
			});
	}

	test(dataItem, column){
		console.log(dataItem)
		console.log(column);
	}


	evaluatorFunction(value, ruleset, returnRuleDescription){
		//use value
		for (const rule of ruleset){
			if(rule.operator == 'greater than') {
				if(value >= rule.values[0]) {
					if(returnRuleDescription) {
						return rule.description;
					} else {
						return rule.className;
					}
				}
			}
			if(rule.operator == 'less than') {
				if(value < rule.values[0]) {
					if(returnRuleDescription) {
						return rule.description;
					} else {
						return rule.className;
					}
				}
			}
			if(rule.operator == 'between') {
				if(value > rule.values[0] && value < rule.values[1]) {
					if(returnRuleDescription) {
						return rule.description;
					} else {
						return rule.className;
					}
				}
			}
		}
	}

	evaluateProgressBarColor(configuration: {value: any; nonstandardname?: string; returnRuleDescription? : boolean  }) {
		let returnedValue;
		if(configuration.nonstandardname == 'dpfSootLoadPercent') {
			returnedValue = this.evaluatorFunction(configuration.value, this.progressbarColorSchemeDpfSootLoadPercent, configuration.returnRuleDescription)
		} else if(configuration.nonstandardname == 'engineCoolantLevel') {
			returnedValue = this.evaluatorFunction(configuration.value, this.progressbarColorSchemeEngineCoolant, configuration.returnRuleDescription)
		}
		else {
			returnedValue = this.evaluatorFunction(configuration.value, this.progressbarColorScheme, configuration.returnRuleDescription);
		}

		if(returnedValue == undefined) {
			returnedValue = '';
		}

		return returnedValue;
	}

	tooltipInformation(column) {
		this.tooltipInformationObject = {
			Title: '',
			StandardId: '',
			StandardName: '',
		};
		if (Global.User.DebugMode) {
			// let foundTag = this.assetTags.find((t) =>
			// 	this[controllerName].includes(
			// 		parseInt(t.JBTStandardObservationId)
			// 	)
			// );

			// if (foundTag !== undefined) {
				let foundColumn = this.gridSettings.columnsConfig.find(columnInConfig => {
					return columnInConfig.field === column.field;
				})
				let foundObs = this.dataService.cache.jbtStandardObservationsObject[foundColumn.columnObId];
				this.tooltipInformationObject = {
					Title: column.title,
					StandardId: foundColumn.columnObId,
					StandardName: foundObs ? foundObs.Name : 'No found Standard Name',
				};

				// return (
				// 	'TagId: ' +
				// 	foundTag.TagId +
				// 	'TagName: ' +
				// 	foundTag.TagName +
				// 	' JBTSTDID: ' +
				// 	foundTag.JBTStandardObservationId +
				// 	' JBTStdName: ' +
				// 	foundTag.Name +
				// 	' Value: ' +
				// 	foundTag.Value +
				// 	' ValueWhenActive: ' +
				// 	foundTag.ValueWhenActive
				// );
			// }
		}

		// let foundTag = this.assetTags.find(t => {
		// 	return this.[controllerName] === true;
		// })
		// if (Global.User.DebugMode) {
		// 	for (const key in this
		// 		.ObservationIdMappingObjectForTrueFalseValues) {
		// 		if (key == controllerName) {
		// 			let foundTag = this.assetTags.find((t) =>
		// 				this.ObservationIdMappingObjectForTrueFalseValues[
		// 					key
		// 				].includes(parseInt(t.JBTStandardObservationId))
		// 			);
		// 			if (foundTag !== undefined) {
		// 				return (
		// 					'TagId: ' +
		// 					foundTag.TagId +
		// 					'TagName: ' +
		// 					foundTag.TagName +
		// 					' JBTSTDID: ' +
		// 					foundTag.JBTStandardObservationId +
		// 					' JBTStdName: ' +
		// 					foundTag.Name +
		// 					' Value: ' +
		// 					foundTag.Value +
		// 					' ValueWhenActive: ' +
		// 					foundTag.ValueWhenActive
		// 				);
		// 			}
		// 		}
		// 	}

		// 	for (const key in this
		// 		.ObservationIdMappingObjectForParseFloatValues) {
		// 		if (key == controllerName) {
		// 			let foundTag = this.assetTags.find((t) =>
		// 				this.ObservationIdMappingObjectForParseFloatValues[
		// 					key
		// 				].includes(parseInt(t.JBTStandardObservationId))
		// 			);
		// 			if (foundTag !== undefined) {
		// 				return (
		// 					'TagId: ' +
		// 					foundTag.TagId +
		// 					'TagName: ' +
		// 					foundTag.TagName +
		// 					' JBTSTDID: ' +
		// 					foundTag.JBTStandardObservationId +
		// 					' JBTStdName: ' +
		// 					foundTag.Name +
		// 					' Value: ' +
		// 					foundTag.Value +
		// 					' ValueWhenActive: ' +
		// 					foundTag.ValueWhenActive
		// 				);
		// 			}
		// 		}
		// 	}
		// }
	}




}
