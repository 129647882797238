import { Component, OnInit, Input, NgZone } from "@angular/core";

import { filter } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import _ from "lodash";
import * as moment from "moment";

import Swal from "sweetalert2";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITag } from "../../_models/tag.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	selector: "lib-central-unit",
	templateUrl: "./central-unit.component.html",
	styleUrls: ["./central-unit.component.scss"]
})
export class CentralUnitComponent implements OnInit {
	@Input() parentAsset: any;
	@Input() widgetObject: any;
	fullDataCacheSubscription: any;
	theme: string;
	assetTags: any;
	formattedTags: any;
	assetTagsCollection: any;
	isDataLoading: boolean;
	assetTagIds = [];
	public UnitStatus = [12374]; // UnitStatus
	public CurrentUnbalance = [56520]; // Current Unbalance
	public VoltageUnbalance = [56521]; // Voltage Unbalance
	public HzOut = [12221]; // Hz Out
	public AmpsOutA = [4439]; // Amps Out A
	public AmpsOutB = [4441]; // Amps Out B
	public AmpsOutC = [4443]; // Amps Out C
	public VoltsOutA = [4440]; // Volts Out A
	public VoltsOutB = [4442]; // Volts Out B
	public VoltsOutC = [4444]; // Volts Out C
	public AmpsOutAvg = [1942]; // Amps Out Avg
	public AmpsKwTotal = [20010]; // Amps Kw Total
	public VoltsOutAvg = [1935]; // Volts Out Avg
	public VoltsKwTotal = [20009]; // V0lts Kw Total
	public allStdIds = [12374, 56520, 56521, 12221, 4439, 4440, 4441, 4442, 4443, 4444, 1942, 20010, 1935, 20009];
	gaugeTextColor: string;
	colorChangedSubscription: any;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	signalRTagUpdateSubscription: any;
	subscriptions: Subscription[] = [];
	private swalWithBootstrapButtons: any;
	tagGraphSingleModalSubscription: Subscription;
	private componentName: string = "central-unit: ";
	public guid: string;

	constructor(public dataService: DataService, 
				private signalRCore: SignalRCoreService, 
				public dialog: MatDialog, 
				private dashboardService: DashboardService,
				private zone: NgZone
			  ) 
	{

			this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger"
			},
			buttonsStyling: false
		});
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					if (Global.Theme === "light") {
						this.theme = "light";
					} else if (Global.Theme === "dark") {
						this.theme = "dark";
					}
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			if (Global.Theme === "light") {
				this.theme = "light";
			} else if (Global.Theme === "dark") {
				this.theme = "dark";
			}
			this.initialize();
		}

		this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((theme: any) => {
			this.theme = theme;
			this.gaugeTextColor = theme === "dark" ? "#eceaea" : "#858585";
		});

	}

	initialize() {
		if (this.parentAsset != undefined) this.gaugeTextColor = this.theme === "dark" ? "#eceaea" : "#858585";
		console.log("Getting tags....");
		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(this.parentAsset.Id.toString(), false, this.allStdIds.toString()).subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + "dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O", data);

			this.assetTagsCollection = data;
			this.dataForDisplay(data);
			this.isDataLoading = false;
			this.getSignalRUpdates();
		});
	}

	openCentralUnitSummary(assetId: number) {
		Global.User.DebugMode && console.log(`Central Unit Summary for Asset ${assetId}  was opened.`);
		const cuSummaryModal = this.dialog.open(DialogModalParentComponent, {
			width: Global.isMobile ? "90%" : "60%",
			height: Global.isMobile ? "90%" : "80%",
			data: {
				popupTitle: `Central Unit Summary: ${this.parentAsset.SiteName | this.parentAsset.GateName | this.parentAsset.WidgetAssetName}`,
				siteName: this.parentAsset.SiteName,
				gateSystemName: this.parentAsset.GateName + " - " + this.parentAsset.WidgetAssetName,
				widgetNameDisplay: "Central Unit Summary",
				assetObj: this.parentAsset,
				WidgetName: "CU-Summary"
			},
			maxWidth: "100vw",
			maxHeight: "100vh"
		});

		this.subscriptions.push(
			cuSummaryModal.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log(": " + ` CU Summary for Asset ${assetId} was closed.`);
			})
		);
	}

	openTagGraphSingle(observationIdArray): void {
		let tagObject = this.formattedTags.find((t) => observationIdArray.includes(t.JBTStandardObservationId));
		if (tagObject === undefined) {
			this.swalWithBootstrapButtons
				.fire({
					title: "Error",
					text: "Tag Object Doesn't Exist",
					showCancelButton: false,
					confirmButtonText: "Ok",
					reverseButtons: false
				})
				.then(() => {});
		} else {
			if (this.widgetObject == undefined) {
				this.widgetObject = tagObject;
				this.widgetObject.TimeZoneId = null;
			}

			const tagGraphSingleModal = this.dialog.open(DialogModalParentComponent, {
				width: "80%",
				height: "70%",
				data: {
					TagId: tagObject.TagId,
					widgetNameDisplay: "Tag Graph",
					WidgetName: "tag-graph",
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(this.widgetObject)
				},

				maxWidth: "100vw",
				maxHeight: "100vh"
			});
			this.tagGraphSingleModalSubscription = tagGraphSingleModal.afterClosed().subscribe((result) => {
				Global.User.DebugMode && console.log("The modal was closed");
				this.tagGraphSingleModalSubscription.unsubscribe();
			});
		}
	}

	dataForDisplay(data: any) {
		this.assetTags = data;
		this.formattedTags = data.map((t) => ({
			TagId: t.Id != undefined ? t.Id : t.TagId,
			Name: t.JBTStandardObservation?.Name != undefined ? t.JBTStandardObservation?.Name : this.dataService.cache.jbtStandardObservationsObject[t.JBTStandardObservationId]?.Name != undefined ? this.dataService.cache.jbtStandardObservationsObject[t.JBTStandardObservationId]?.Name : this.dataService.cache.tagsObject[t.Id != undefined ? t.Id : t.TagId]?.ShortTagName,
			JBTStandardObservationId: t.JBTStandardObservationId,
			JavascriptDate: new Date(t.DateInMilliseconds),
			Value: t.Value != undefined ? t.Value : t.LastObservationTextValue
		}));

        this.assetTagIds = this.formattedTags.map((t) => t.TagId);

		let UnitStatus = this.formattedTags.find((t) => this.UnitStatus.includes(t.JBTStandardObservationId));
		this.assetTags.UnitStatus = UnitStatus == undefined ? undefined : UnitStatus.Value == "1" ? true : false;
		let CurrentUnbalance = this.formattedTags.find((t) => this.CurrentUnbalance.includes(t.JBTStandardObservationId));
		this.assetTags.CurrentUnbalance = CurrentUnbalance == undefined ? undefined : isNaN(CurrentUnbalance.Value) ? 0.0 : parseFloat(CurrentUnbalance.Value).toFixed(2);
		let VoltageUnbalance = this.formattedTags.find((t) => this.VoltageUnbalance.includes(t.JBTStandardObservationId));
		this.assetTags.VoltageUnbalance = VoltageUnbalance == undefined ? undefined : isNaN(VoltageUnbalance.Value) ? 0.0 : parseFloat(VoltageUnbalance.Value).toFixed(2);
		let HzOut = this.formattedTags.find((t) => this.HzOut.includes(t.JBTStandardObservationId));
		this.assetTags.HzOut = HzOut == undefined ? undefined : isNaN(HzOut.Value) ? 0.0 : parseFloat(HzOut.Value).toFixed(2);
		let AmpsOutA = this.formattedTags.find((t) => this.AmpsOutA.includes(t.JBTStandardObservationId));
		this.assetTags.AmpsOutA = AmpsOutA == undefined ? undefined : isNaN(AmpsOutA.Value) ? 0.0 : parseFloat(AmpsOutA.Value).toFixed(2);
		let AmpsOutB = this.formattedTags.find((t) => this.AmpsOutB.includes(t.JBTStandardObservationId));
		this.assetTags.AmpsOutB = AmpsOutB == undefined ? undefined : isNaN(AmpsOutB.Value) ? 0.0 : parseFloat(AmpsOutB.Value).toFixed(2);
		let AmpsOutC = this.formattedTags.find((t) => this.AmpsOutC.includes(t.JBTStandardObservationId));
		this.assetTags.AmpsOutC = AmpsOutC == undefined ? undefined : isNaN(AmpsOutC.Value) ? 0.0 : parseFloat(AmpsOutC.Value).toFixed(2);
		let VoltsOutA = this.formattedTags.find((t) => this.VoltsOutA.includes(t.JBTStandardObservationId));
		this.assetTags.VoltsOutA = VoltsOutA == undefined ? undefined : isNaN(VoltsOutA.Value) ? 0.0 : parseFloat(VoltsOutA.Value).toFixed(2);
		let VoltsOutB = this.formattedTags.find((t) => this.VoltsOutB.includes(t.JBTStandardObservationId));
		this.assetTags.VoltsOutB = VoltsOutB == undefined ? undefined : isNaN(VoltsOutB.Value) ? 0.0 : parseFloat(VoltsOutB.Value).toFixed(2);
		let VoltsOutC = this.formattedTags.find((t) => this.VoltsOutC.includes(t.JBTStandardObservationId));
		this.assetTags.VoltsOutC = VoltsOutC == undefined ? undefined : isNaN(VoltsOutC.Value) ? 0.0 : parseFloat(VoltsOutC.Value).toFixed(2);
		let AmpsOutAvg = this.formattedTags.find((t) => this.AmpsOutAvg.includes(t.JBTStandardObservationId));
		this.assetTags.AmpsOutAvg = AmpsOutAvg == undefined ? undefined : isNaN(AmpsOutAvg.Value) ? 0.0 : parseFloat(AmpsOutAvg.Value).toFixed(2);
		let AmpsKwTotal = this.formattedTags.find((t) => this.AmpsKwTotal.includes(t.JBTStandardObservationId));
		this.assetTags.AmpsKwTotal = AmpsKwTotal == undefined ? undefined : isNaN(AmpsKwTotal.Value) ? 0.0 : parseFloat(AmpsKwTotal.Value).toFixed(2);
		let VoltsOutAvg = this.formattedTags.find((t) => this.VoltsOutAvg.includes(t.JBTStandardObservationId));
		this.assetTags.VoltsOutAvg = VoltsOutAvg == undefined ? undefined : isNaN(VoltsOutAvg.Value) ? 0.0 : parseFloat(VoltsOutAvg.Value).toFixed(2);
		let VoltsKwTotal = this.formattedTags.find((t) => this.VoltsKwTotal.includes(t.JBTStandardObservationId));
		this.assetTags.VoltsKwTotal = VoltsKwTotal == undefined ? undefined : isNaN(VoltsKwTotal.Value) ? 0.0 : parseFloat(VoltsKwTotal.Value).toFixed(2);
		//this.createSparkLineChartForHzOut(this.parentAsset.Id);
	}

	createSparkLineChartForHzOut(assetIds: any) {
		// Get Chart Data
		let statement = `GetSparklineDataByAssetAndJBTStandardObId @AssetIdList = '${assetIds}', @JBTStandardObId = '${12221}'`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode && console.log(": GetSparklineDataByAssetAndJBTStandardObId data = %O", data);
			this.assetTags.hzOutChart = [
				{
					field: "y",
					width: 200,
					height: 200,
					color: "dodgerblue",
					data:
						data.length > 0
							? data.map((a) => ({
									y: parseFloat(parseFloat(a.TextValue).toFixed(1)),
									obsTimeFormatted: moment(a.DateInMS).format("HH:mm:ss"),
									obsTimeMS: a.DateInMS
							  }))
							: []
				}
			];
		});
	}

	getSignalRUpdates() {

		let assetObjectInCache = this.dataService.cache.assetsObject[this.parentAsset.Id];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		this.widgetGroupSettings = {
			WidgetId: this.widgetObject.WidgetId,
			GroupList: tagNamePrefixesString,
			IsPopup: false
		};

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: this.widgetObject.WidgetName, 
										 TagNamePrefix: [tagNamePrefixesString]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					var tagWeCareAbout = this.assetTagsCollection.firstOrDefault((t:any) => t.Id == tag.Id);
					if (tagWeCareAbout != null) {
						tagWeCareAbout.LastObservationTextValue = tag.Value;
						this.dataForDisplay(this.assetTagsCollection);
					}
				});
			});
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}
		if (this.tagGraphSingleModalSubscription !== undefined) {
			this.tagGraphSingleModalSubscription.unsubscribe();
		}
	}
}


