import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	OnDestroy,
	Optional,
} from '@angular/core';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';

import { ToastrService } from 'ngx-toastr';

import { DataService } from 'projects/shared-lib/src/lib/services/data.service';
import { DashboardService } from 'projects/shared-lib/src/lib/services/dashboard.service';
import { Global } from 'projects/shared-lib/src/lib/_constants/global.variables';
import { EditWidgetComponent } from 'projects/shared-lib/src/lib/components/edit-widget/edit-widget.component';

import { WidgetSettingsPopupComponent } from '../widget-settings-popup/widget-settings-popup.component';
import { WidgetIntervalSelectionPopupComponent } from '../../components/widget-interval-selection-popup/widget-interval-selection-popup.component';
import { WidgetTimeZoneSelectionPopupComponent } from '../../components/widget-time-zone-selection-popup/widget-time-zone-selection-popup.component';
import { WidgetTimeScopeSelectionPopupComponent } from '../../components/widget-time-scope-selection-popup/widget-time-scope-selection-popup.component';

import { TagGraphPopupSettingsComponent } from '../../components/tag-graph-popup-settings/tag-graph-popup-settings.component';
import { IGlobal } from '../../_models/global.model';

import { ScrollMode } from '@progress/kendo-angular-grid/main';
import { UtilityService } from '../../services/utility.service';
import { WidgetSplitPercentPopupComponent } from '../widget-split-percent-popup/widget-split-percent-popup.component';
import { Router } from '@angular/router';

@Component({
	selector: 'lib-widget-settings',
	templateUrl: './widget-settings.component.html',
	styleUrls: ['./widget-settings.component.scss'],
})
export class WidgetSettingsComponent implements OnInit, OnDestroy {
	public global: IGlobal = Global;
	@Input() widgetObject: any;
	@Input() optionsString;
	@Input() currentDashboard: any;
	@Output() widgetHeightChanged = new EventEmitter<any>();
	@Optional() @Output() widgetDeleted = new EventEmitter<boolean>();
	@Optional() @Output() widgetDragChanged = new EventEmitter<boolean>();
	@Optional() @Output() widgetResizedEnabledChanged = new EventEmitter<boolean>();
	@Output() widgetRememberSelectedTabIndexChanged =
		new EventEmitter<boolean>();

	private swalWithBootstrapButtons: any;
	widgetSettingsEditModal: any;
	editWidgetComponentModal: any;

	private debugMode: boolean = false;
	widgetSettingsZoneEditModal: any;
	widgetSettingsScopeEditModal: any;
	tagSettingsEditModal: any;
	splitViewPercentsEditModal: any;
	tacticalDashboardWidgetSettingsEditModal: any;
	widgetSettingsIntervalEditModal: any;

	constructor(
		public dialog: MatDialog,
		public dataService: DataService,
		public toastr: ToastrService,
		public dashboardService: DashboardService,
		public utilityService: UtilityService,
		private router: Router
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-danger',
				cancelButton: 'btn btn-success',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {

		if (Global.User.currentUser.Username == 'dylan') {
			this.debugMode = true;
			console.log(this.currentDashboard);
		}
		if (this.debugMode) {
			console.log(this.widgetObject);
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log('ngOnDestroy invoked...');
	}

	ionViewWillLeave() {
		console.log('login.page: ionViewWillLeave invoked...');
	}
	showThisStatus() {
		console.log(this);
	}

	canUserSeeWidgetSettings() {
		if (this.widgetObject.DashboardTypeId === 2) {
			return true;
		} else if (
			Global.User.isAdmin == true ||
			Global.User.currentUser.Security.Aggregate.Collections.WidgetTypeIds.includes(
				this.widgetObject.WidgetTypeId
			)
		) {
			return true;
		} else {
			return false;
		}
		// return false;
	}

	goToPunchoutScreen(){
		const whereToGoUrl = '/layout/assets/' + this.widgetObject.AssetId;
		this.router.navigate([whereToGoUrl]);
	}

	invertDraggableSetting(widgetObject) {
		if (
			widgetObject.dragEnabled === true ||
			widgetObject.dragEnabled === undefined ||
			widgetObject.dragEnabled === null
		) {
			widgetObject.dragEnabled = false;
		} else if (widgetObject.dragEnabled === false) {
			widgetObject.dragEnabled = true;
		}
		this.widgetDragChanged.emit(true);
	}

	invertRememberingOfSelectedTabIndex(widgetObject) {
		if (
			widgetObject.RememberSelectedTabIndex == true ||
			widgetObject.RememberSelectedTabIndex == undefined ||
			widgetObject.RememberSelectedTabIndex == null
		) {
			widgetObject.RememberSelectedTabIndex = false;
		} else if (widgetObject.RememberSelectedTabIndex == false) {
			widgetObject.RememberSelectedTabIndex = true;
		}
		this.widgetRememberSelectedTabIndexChanged.emit(true);
	}

	toggleLegendHidden(widgetObject) {
		if (widgetObject.SelectedTabIndex != 0)
		{
			this.dashboardService._widgetToggleLegendHidden.next(
				widgetObject
			);
		}
	}

	navigateToFirstTabForTacticalDashboardWidgets(widgetObject) {
		if (widgetObject.SelectedTabIndex != 0)
			this.dashboardService._widgetNavigateToFirstTab.next(
				this.widgetObject
			);
	}
	toggleGeofencesForLocateAllGSE() {
		this.dataService.toggleGeofencesForLocateAllGSE(this.widgetObject);
	}

	invertResizeSetting(widgetObject) {
		if (
			widgetObject.resizeEnabled === true ||
			widgetObject.resizeEnabled === undefined ||
			widgetObject.resizeEnabled === null
		) {
			widgetObject.resizeEnabled = false;
		} else if (widgetObject.resizeEnabled === false) {
			widgetObject.resizeEnabled = true;
		}
		this.widgetResizedEnabledChanged.emit(true);
	}

	openTemplatePopup() {
		this.dataService.initializeCanvasPopup(this.widgetObject);
	}

	openGSETemplatePopup() {
		this.dataService.initializeGseCanvasPopup(this.widgetObject);
	}

	addTagsToWidgetChart() {
		console.log(this.widgetObject);
		console.log(this.dataService.tempTagsToChart);
		this.dashboardService.addTagsToExistingChart(
			this.widgetObject.WidgetId
		);
	}

	changeScrollMode(widget) {
		if (
			widget.scrollmode == undefined ||
			widget.scrollmode === 'scrollable'
		) {
			widget.columnlock = false;
			let scrollmode: ScrollMode = 'none';
			widget.scrollmode = scrollmode;
		} else if (widget.scrollmode === 'none') {
			widget.columnlock = true;
			let scrollmode: ScrollMode = 'scrollable';
			widget.scrollmode = scrollmode;
		}
	}

	changeWidgetHeight(changeRequested: string, widget, $event) {
		$event.stopPropagation();
		if (changeRequested === 'taller') {
			widget.rows = widget.rows + 2;
			widget.Height = widget.Height + 2;
			// make widget taller
		} else if (changeRequested === 'shorter') {
			// make widget smaller
			widget.rows = widget.rows - 2;
			widget.Height = widget.Height - 2;
		}

		//emit item was changed
		this.widgetHeightChanged.emit(widget);

		// this.options.api.optionsChanged();
		// this.itemChange(widget);
	}

	openEditWidgetName() {
		this.editWidgetComponentModal = this.dialog.open(EditWidgetComponent, {
			width: Global.isMobile ? '95%' : '70%',
			height: Global.isMobile ? '95%' : '30%',
			data: { title: 'Re-Name Widget', data: this.widgetObject },
			autoFocus: false,
		});
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.editWidgetComponentModal
		);

		this.editWidgetComponentModal.afterClosed().subscribe((result) => {
			if (this.debugMode) {
				console.log('The modal was closed');

				//if not submitted, then result is undefined
				console.log(result);
			}

			if (result !== undefined) {
				if (this.debugMode) {
					console.log(this.widgetObject);
				}
				//make call to update widget name in db, then after that, assign the value to teh widget object
				if (result.data !== undefined) {
					this.widgetObject.WidgetName = result.data.widgetName;
				} else {
					this.widgetObject.WidgetName = result;
				}
				let statement =
					'API.Widget_UpdateRecordByIdAndFieldName ' +
					this.widgetObject.WidgetId +
					", '" +
					'Name' +
					"', '''" +
					this.widgetObject.WidgetName +
					"'''";
				if (this.debugMode) {
					console.log(statement);
				}
				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						this.utilityService.showToastMessageShared({
							type: 'success',
							message: 'Widget Name Changed!',
						});
					});
			}
		});
	}

	refreshComponent() {
		this.widgetObject.refreshComponent = true;
		setTimeout(() => {
			this.widgetObject.refreshComponent = false;
			this.dashboardService._reloadedWidget.next(this.widgetObject);
		}, 500);
	}

	openWidgetSettings() {
		this.widgetSettingsEditModal = this.dialog.open(
			WidgetSettingsPopupComponent,
			{
				width: Global.isMobile ? "95%" : "50%",
				height: Global.isMobile ? "95%" : "80%",
				maxHeight: "100%",
				maxWidth: "100%",
				data: {
					widget: this.widgetObject,
					widgetType:
						this.widgetObject.DashboardTypeId === 1
							? "Standard"
							: "Tactical",
				},
				position: { right: "0" },
				hasBackdrop: false,
				disableClose: true,
				autoFocus: false,
			}
		);
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.widgetSettingsEditModal
		);

		this.widgetSettingsEditModal.afterClosed().subscribe((result) => {
			if (this.debugMode) {
				console.log('The modal was closed');
				//if not submitted, then result is undefined
				console.log(result);
			}
		});
	}

	toggleSidenavPanel(side?: string) {
		this.dataService.toggleSidenavPanel(this.widgetObject, side);
	}

	loadTagSettingsForWidget() {
		this.tagSettingsEditModal = this.dialog.open(
			TagGraphPopupSettingsComponent,
			{
				width: Global.isMobile ? '95%' : '50%',
				height: Global.isMobile ? '95%' : '80%',
				maxHeight: '100%',
				maxWidth: '100%',
				data: this.widgetObject,
				position: { right: '0' },
				hasBackdrop: false,
				disableClose: true,
			}
		);

		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.tagSettingsEditModal
		);

		this.tagSettingsEditModal.afterClosed().subscribe((result) => {
			if (this.debugMode) {
				console.log('The Tag Settings Modal Was closed');
				console.log(result);
			}
		});
	}

	configureSplitViewPercents() {
		this.splitViewPercentsEditModal = this.dialog.open(
			WidgetSplitPercentPopupComponent,
			{
				width: Global.isMobile ? '95%' : '50%',
				height: Global.isMobile ? '95%' : '80%',
				maxHeight: '100%',
				maxWidth: '100%',
				data: this.widgetObject,
				position: { right: '0' },
				hasBackdrop: false,
				disableClose: true,
			}
		);

		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.splitViewPercentsEditModal
		);

		this.splitViewPercentsEditModal.afterClosed().subscribe((result) => {
			if (this.debugMode) {
				console.log('The Split View Percents Modal Was closed');
				console.log(result);
			}
		});
	}

	openTimeZoneSettings() {
		this.widgetSettingsZoneEditModal = this.dialog.open(
			WidgetTimeZoneSelectionPopupComponent,
			{
				width: Global.isMobile ? '95%' : '50%',
				height: Global.isMobile ? '95%' : '80%',
				disableClose: true,
				autoFocus: false,
				data: this.widgetObject,
				position: { right: '0' },
				hasBackdrop: false,
			}
		);

		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.widgetSettingsZoneEditModal
		);

		this.widgetSettingsZoneEditModal
			.afterClosed()
			.subscribe((result) => {});
	}

	openTimeScopeSettings() {
		this.widgetSettingsScopeEditModal = this.dialog.open(
			WidgetTimeScopeSelectionPopupComponent,
			{
				width: Global.isMobile ? '95%' : '50%',
				height: Global.isMobile ? '95%' : '80%',
				maxHeight: '100%',
				maxWidth: '100%',
				data: this.widgetObject,
				position: { right: '0' },
				hasBackdrop: false,
				disableClose: true,
			}
		);
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.widgetSettingsScopeEditModal
		);

		this.widgetSettingsScopeEditModal
			.afterClosed()
			.subscribe((result) => {});
	}

	openIntervalSettings() {
		this.widgetSettingsIntervalEditModal = this.dialog.open(
			WidgetIntervalSelectionPopupComponent,
			{
				width: Global.isMobile ? '95%' : '50%',
				height: Global.isMobile ? '95%' : '80%',
				maxHeight: '100%',
				maxWidth: '100%',
				data: this.widgetObject,
				position: { right: '0' },
				hasBackdrop: false,
				disableClose: true,
			}
		);
		this.dashboardService.listOfPopupsThatAreNotAutoClosedByCLickingOutside.push(
			this.widgetSettingsIntervalEditModal
		);

		this.widgetSettingsIntervalEditModal
			.afterClosed()
			.subscribe((result) => {});
	}

	onDelete() {
		if (this.debugMode) {
			console.log('deleting works');
		}
		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				html: "You won't be able to revert this!",
				showCancelButton: true,
				confirmButtonText: 'Delete Widget',
				cancelButtonText: 'Cancel',
				reverseButtons: false,
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					this.widgetDeleted.emit(true);
					// then alerting the user the dashboard has been deleted.

					this.utilityService.showToastMessageShared({
						type: 'success',
						message: 'Your widget has been deleted!',
					});

					// _.remove(this.dashboards, function(n) {
					//   return n.Id === dashboard.Id;
					// });
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
					this.utilityService.showToastMessageShared({
						type: 'info',
						message: 'Your widget has not been deleted!',
					});
				}
			});
	}
}
