import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { Global } from "../_constants/global.variables";
import { DateQuestion } from "../_models/dynamic-fields/questions/question-date";
import { DateTimeQuestion } from "../_models/dynamic-fields/questions/question-datetime";
import { FileUploadQuestion } from "../_models/dynamic-fields/questions/question-file-upload";
import { NumberQuestion } from "../_models/dynamic-fields/questions/question-number";
import { ReadOnlyQuestion } from "../_models/dynamic-fields/questions/question-read-only";
import { ReadOnlyTextareaQuestion } from "../_models/dynamic-fields/questions/question-read-only-textarea";
import { ReadOnlyTextareaLargeQuestion } from "../_models/dynamic-fields/questions/question-read-only-textarea-large";
import { SliderYesNoQuestion } from "../_models/dynamic-fields/questions/question-slider-yes-no";
import { TextDifferencesComparisonQuestion } from "../_models/dynamic-fields/questions/question-text-difference-comparison";
import { TextareaQuestion } from "../_models/dynamic-fields/questions/question-textarea";
import { TextareaLargeQuestion } from "../_models/dynamic-fields/questions/question-textarea-large";
import { TextboxQuestion } from "../_models/dynamic-fields/questions/question-textbox";
import { UibButtonQuestion } from "../_models/dynamic-fields/questions/question-uib-button";
import { UibButtonMultiSelectQuestion } from "../_models/dynamic-fields/questions/question-uib-button-multi-select";
import { IGlobal } from "../_models/global.model";
import { DataService } from "./data.service";
import { SecurityService } from "./security.service";
import { IUser } from "../_models/user.model";
import { TextareaFancyQuestion } from "../_models/dynamic-fields/questions/question-textarea-fancy";

@Injectable({
	providedIn: "root"
})
export class RecursiveDataNavigatorService {
	public global: IGlobal = Global;
	public editingForm$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public internalDialogIsVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public currentUser$: BehaviorSubject<IUser> = new BehaviorSubject(Global.User.currentUser);

	public commonColumnStructures: any = {
		site: {
			id: {
				name: "Id",
				label: "Id",
				type: "text",
				width: 5,
				widthUnits: "%",
				sortable: true
			},
			siteId: {
				name: "SiteId",
				label: "Site Id",
				type: "text",
				width: 5,
				widthUnits: "%",
				sortable: true
			},
			organizationSiteId: {
				name: "OrganizationSiteId",
				label: "Organization Site Id",
				type: "text",
				width: 5,
				widthUnits: "%",
				sortable: true
			},
			name: {
				plain: {
					name: "Name",
					label: "Name",
					userRole: "Admin",
					type: "text",
					width: 5,
					widthUnits: "%",
					sortable: true,
					editable: true
				},
				bold: {
					name: "Name",
					label: "Name",
					userRole: "Admin",
					type: "text",
					width: 5,
					widthUnits: "%",
					sortable: true,
					editable: true,
					class: "bold-class"
				}
			},
			siteName: {
				plain: {
					name: "SiteName",
					label: "Site Name",
					userRole: "Admin",
					type: "text",
					width: 15,
					widthUnits: "%",
					sortable: true,
					editable: true
				},
				bold: {
					name: "SiteName",
					label: "Site Name",
					userRole: "Admin",
					type: "text",
					width: 15,
					widthUnits: "%",
					sortable: true,
					editable: true,
					class: "bold-class"
				}
			},
			description: {
				plain: {
					name: "Description",
					label: "Description",
					type: "text",
					width: 22,
					widthUnits: "%",
					sortable: true
				},
				bold: {
					name: "Description",
					label: "Description",
					type: "text",
					width: 22,
					widthUnits: "%",
					sortable: true
				}
			},
			active: {
				name: "Active",
				label: "Active",
				type: "boolean",
				width: 5,
				widthUnits: "%",
				sortable: false
			},
			location: {
				name: "Location",
				label: "Map Location",
				type: "text",
				width: 15,
				widthUnits: "%",
				sortable: false
			},
			utcTimeOffset: {
				name: "UTCTimeOffset",
				label: "UTC Offset",
				type: "text",
				width: 5,
				widthUnits: "%"
			},
			timeZone: {
				name: "TimeZone",
				label: "Time Zone",
				type: "text",
				width: 10,
				widthUnits: "%"
			},
			hasGates: {
				name: "HasGates",
				label: "Has Gates",
				type: "boolean",
				width: 5,
				widthUnits: "%",
				sortable: false
			},
			enableFlightData: {
				name: "EnableFlightData",
				label: "Enable Flight Data",
				type: "boolean",
				width: 5,
				widthUnits: "%",
				sortable: false
			},
			generateDockedTagFromAutolevelDeployedTag: {
				name: "GenerateDockedTagFromAutolevelDeployedTag",
				label: "Generate Docked Tag",
				type: "boolean",
				width: 5,
				widthUnits: "%",
				sortable: false
			},
			hasBaggageHandling: {
				name: "HasBaggageHandling",
				label: "Has Baggage Handling",
				type: "boolean",
				width: 5,
				widthUnits: "%",
				sortable: false
			},
			siteSystems: {
				name: "Systems",
				label: "Systems",
				type: "set",
				setName: "System",
				width: 10,
				widthUnits: "%",
				parentColumnLink: "Id",
				childColumnLink: "SiteId"
			},
			perfectTurnThresholds: {
				name: "PerfectTurnThresholds",
				label: "Perfect Turn",
				type: "set",
				setName: "Site_PerfectTurnThreshold",
				width: 5,
				widthUnits: "%",
				parentColumnLink: "Id",
				childColumnLink: "SiteId"
			}
		},
		actions: {
			name: "actions",
			label: "Actions",
			title: "List of actions possible on the records in the list.",
			width: 3,
			widthUnits: "%"
		},
		actionsForSystemAdminOnly: {
			name: "actions",
			label: "Actions",
			title: "List of actions possible on the records in the list.",
			width: 3,
			widthUnits: "%",
			role: "SystemAdministrator" || Global.User.isAdmin
		},
		actionsForAllAdminsOnly: {
			name: "actions",
			label: "Actions",
			title: "List of actions possible on the records in the list.",
			width: 3,
			widthUnits: "%",
			role: "SystemAdministrator" || Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin
		}
	};

	public structure: any;
	public accessTokenChanged$: Subject<string> = new Subject<string>();
	public currentUserAccessToken: string;
	public currentUser: IUser;

	constructor(private dataService: DataService) {
		this.editingForm$ = new BehaviorSubject(Global.RDN.editingForm);
		this.editingForm$.subscribe((editingForm: boolean) => {
			Global.User.DebugMode && console.log(editingForm ? "editing form..." : "not editing form...");
		});

		this.currentUser$.subscribe((currentUser: IUser) => {
			this.currentUser = currentUser;
		});

		this.internalDialogIsVisible$ = new BehaviorSubject(Global.RDN.internalDialog);
		this.internalDialogIsVisible$.subscribe((internalDialogIsVisible: boolean) => {
			Global.User.DebugMode && console.log(internalDialogIsVisible ? "internal dialog is visible..." : "internal dialog is not visible...");
		});

		this.accessTokenChanged$.subscribe(
			(accessToken: string) => {
				this.currentUserAccessToken = accessToken;
				console.log("recursive-data-navigator.service: NEW this.currentUser.ODataAccessToken = " + accessToken);
			},
			(err: Error) => console.error(`Error with accessTokenChanged$: ${err}`)
		);

		Global.User.DebugMode && console.log("recursive-data-navigator.service: this.currentUser.ODataAccessToken = " + this.currentUser?.ODataAccessToken);

		this.structure = [
			{
				//-- RDN Starting Point
				setName: "RDN Starting Point",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_StartingPoint",
					label: "RDN Starting Point",
					displayColumns: [
						{
							label: "Sites",
							type: "set",
							setName: "Site Simple Fields",
							width: 10,
							widthUnits: "px"
						},
						{
							label: "Assets",
							type: "set",
							width: 10,
							widthUnits: "px"
						},
						{
							label: "Organizations",
							type: "set",
							width: 10,
							widthUnits: "px"
						},
						{
							label: "People",
							setName: "Person",
							type: "set",
							width: 10,
							widthUnits: "px"
						}
					]
				}
			}, // End of RDN Starting Point
			{
				// iOPSUsers
				setName: "iOPSUsers",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedUserRecords",
					expandedStoredProcedure: true,
					label: "Users",
					entityName: "Security.iOPSUser",
					entityDisplayName: "User",
					maxRows: 30,
					allowDelete: false,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this user.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this user account.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Person",
							label: "Person",
							title: "The Person who is responsible for this user account.",
							type: "text",
							width: Global.User.isAdmin ? 16 : 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Admin",
							label: "Admin Level",
							type: "text",
							title: "Is this user a system administrator or organization administrator?",
							width: 10,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "PersonRecord",
							label: "Person Record",
							title: "The Person record who owns this user account.",
							setName: "Person",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "PersonId",
							childColumnLink: "Id"
						},
						{
							name: "AuthorizedSites",
							label: "Authorized Sites",
							title: "The authorized Sites for this user.",
							setName: "UserAuthorizedSites",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "UserId"
						},
						{
							name: "EventLog",
							label: "Event Log",
							title: "Event Log for the last 24 hours for this user.",
							setName: "UserEventLog",
							type: "set",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "UserId",
							role: "SystemAdministrator"
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							title: "Whether or not this user account is Active.",
							width: 5,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "SendChangePasswordToken",
							label: "Send Change Password Token",
							title: "Send the user a link via email with a token for changing their password.",
							type: "sendChangePasswordToken",
							field: "Username",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "LogInAs",
							label: "Log In As",
							title: "Log in as this user. Clicking this button will allow you to log out and log you back in as this user.",
							type: "logInAs",
							field: "Username",
							width: 5,
							widthUnits: "%",
							role: "SystemAdministrator"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Security_iOPSUser",
					editStoredProcedureName: "API.RDN_EditRecord_Security_iOPSUser",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "User Id",
							title: "User Id for this user account.",
							type: "primary-key-field",
							order: 1,
							visible: Global.User.isAdmin
						}),
						new TextboxQuestion({
							key: "RequestedUsername",
							label: "Username",
							title: "Enter the username for this person.",
							order: 2,
							type: "username",
							required: true
						}),
						new TextboxQuestion({
							key: "PersonId",
							label: "Person",
							title: "Choose the Person who owns this user account.",
							order: 3,
							type: "generic-selector",
							setName: "Person",
							validateObject: true,
							required: true
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "IsSystemAdministrator",
							label: "Is System Administrator",
							title: "Will this user be a system administrator?",
							order: 5,
							visible: Global.User.isAdmin && !Global.User.isOrgAdmin && !Global.User.isSiteAdmin  //-- only system administrators should be able to set users to System Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new SliderYesNoQuestion({
							key: "IsOrganizationAdministrator",
							label: "Is Organization Administrator",
							title: "Will this user be an organization administrator?",
							order: 6,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								}, //-- if we have a Username value AND...
								{ field: "PersonId", operator: ">", value: 0 }, //-- if we have a PersonId selected AND...
								{
									field: "IsSystemAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not a system administrator, then show the Organization Administrator field. --Kirk T. Sherer, August 17, 2021.
							],
							visible: Global.User.isAdmin //-- only System Administrators should have access to set users to Organization Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new UibButtonMultiSelectQuestion({
							key: "ListOfAuthorizedSites",
							label: "List of Authorized Sites",
							title: "Choose the Sites that this user is authorized to access.",
							order: 7,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								}, //-- if we have a Username value AND...
								{
									field: "IsSystemAdministrator",
									operator: "==",
									value: false
								}, //-- if the user is not a system administrator AND...
								{
									field: "IsOrganizationAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not an organization administrator, then show the List of Authorized Sites. --Kirk T. Sherer, August 17, 2021.
							],
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationSites",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{ name: "@UserId", value: "Id" },
									{ name: "@PersonId", value: "PersonId" },
									{ name: "@AdminUserId", value: this.currentUser?.Id }
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "AdminUserId",
							label: "Admin User Id",
							title: "The Id number of the Administrator editing this record.",
							order: 8,
							value: this.currentUser?.Id,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_User",
					allowAdd: false
				}
			}, //-- End of iOPSUsers
			{
				// UsersBySingleOrganization
				setName: "UsersBySingleOrganization",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedUserRecords",
					expandedStoredProcedure: true,
					label: "Users",
					entityName: "Security.iOPSUser",
					entityDisplayName: "User",
					maxRows: 30,
					allowDelete: false,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this user.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this user account.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Person",
							label: "Person",
							title: "The Person who is responsible for this user account.",
							type: "text",
							width: Global.User.isAdmin ? 16 : 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Admin",
							label: "Admin Level",
							type: "text",
							title: "Is this user an administrator?",
							width: 10,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "PersonRecord",
							label: "Person Record",
							title: "The Person record who owns this user account.",
							setName: "PersonBySingleOrganization",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "PersonId",
							childColumnLink: "Id"
						},
						{
							name: "AuthorizedSites",
							label: "Authorized Sites",
							title: "The authorized Sites for this user.",
							setName: "UserAuthorizedSites",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "UserId"
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							title: "Whether or not this user account is Active.",
							width: 5,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "SendChangePasswordToken",
							label: "Send Change Password Token",
							title: "Send the user a link via email with a token for changing their password.",
							type: "sendChangePasswordToken",
							field: "Username",
							width: 15,
							widthUnits: "%"
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Security_iOPSUser",
					editStoredProcedureName: "API.RDN_EditRecord_Security_iOPSUser",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "User Id",
							title: "User Id for this user account.",
							type: "primary-key-field",
							order: 1,
							visible: Global.User.isAdmin
						}),
						new TextboxQuestion({
							key: "RequestedUsername",
							label: "Username",
							title: "Enter the username for this person.",
							order: 2,
							type: "username",
							required: true
						}),
						new TextboxQuestion({
							key: "PersonId",
							label: "Person",
							title: "Choose the Person who owns this user account.",
							order: 3,
							type: "generic-selector",
							setName: "PersonBySingleOrganization",
							validateObject: true,
							required: true
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 4
						}),
						new UibButtonMultiSelectQuestion({
							key: "ListOfAuthorizedSites",
							label: "List of Authorized Sites",
							title: "Choose the Sites that this user is authorized to access.",
							order: 5,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								} //-- if we have a Username value AND...
							],
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationSites",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{ name: "@UserId", value: "Id" },
									{ name: "@PersonId", value: "PersonId" },
									{ name: "@AdminUserId", value: this.currentUser?.Id }
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "AdminUserId",
							label: "Admin User Id",
							title: "The Id number of the Administrator editing this record.",
							order: 6,
							value: this.currentUser?.Id,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_User",
					allowAdd: true
				}
			}, //-- End of UsersBySingleOrganization
			{
				//-- User Authorized Sites
				setName: "UserAuthorizedSites",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedUserAuthorizedSites",
					expandedStoredProcedure: true,
					label: "User Authorized Sites",
					entityName: "UserAuthorizedSite",
					entityDisplayName: "User Authorized Site",
					maxRows: 30,
					allowDelete: false,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordInsideRecursedSet: true, //--this should be handled inside of the User record setup with a multi-select button group. --Kirk T. Sherer, October 14, 2021.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this user authorized site.",
							hidden: true
						},
						{
							name: "SiteName",
							label: "Site",
							title: "The Name of the Authorized Site for this user.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this user record.",
							type: "text",
							width: 12,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "UserId",
							label: "User Id",
							title: "The User Id of this user record.",
							type: "text",
							hidden: true
						},
						{
							name: "UserRole",
							label: "User Roles",
							type: "text",
							title: "The list of User Roles assigned to this user for this authorized site.",
							width: 35,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Security_UserRolesForAuthorizedSite",
					editStoredProcedureName: "API.RDN_EditRecord_Security_UserRolesForAuthorizedSite",
					fields: [
						new ReadOnlyQuestion({
							key: "SiteName",
							label: "Site",
							title: "The Name of the authorized site.",
							order: 1,
							type: "text"
						}),
						new UibButtonMultiSelectQuestion({
							key: "OrganizationSiteUserRoles",
							label: "Site User Roles",
							title: "Choose the appropriate User Role at each Site for this user.",
							order: 2,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_UserRoles",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{
										name: "@OrganizationSiteUserId",
										value: "Id"
									}
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "User Id",
							title: "Id for this user's Authorized Site.",
							type: "primary-key-field",
							order: 3,
							visible: false
						})
					]
				}
			}, //-- End of User Authorized Sites
			{
				// UserEventLog
				setName: "UserEventLog",
				tableDisplay: {
					sqlStoredProcedure: "Security.UserEventLogDisplay @AdminUserId=" + this.currentUser?.Id,
					expandedStoredProcedure: true,
					label: "Event Log",
					entityName: "Security.UserEventLog",
					entityDisplayName: "Event Log",
					allowDelete: false,
					allowModification: false,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of Event Log.",
							hidden: true
						},
						{
							name: "Person",
							label: "Person",
							title: "The Person related to this event.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LogDate",
							label: "Date",
							type: "date",
							title: "Date this event occurred.",
							width: 15,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "UserEventType",
							label: "Type",
							title: "The type of Event that occurred.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of the Event.",
							type: "text",
							width: 60,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "UserId"
						},
						{
							name: "UserId",
							label: "UserId",
							title: "The UserId of the person on the event.",
							hidden: true
						}
					]
				}
			}, //-- End of UserEventLog
			{
				// User Roles
				setName: "UserRole",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedUserRoleRecords",
					expandedStoredProcedure: true,
					label: "User Roles",
					entityName: "Security.UserRole",
					entityDisplayName: "User Role",
					maxRows: 30,
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this user model.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this user model.",
							type: "text",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed description of this User Model or type of user.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Deleted",
							type: "boolean",
							title: "Whether or not this User Model record has been marked for deletion.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Security_UserRole",
					editStoredProcedureName: "API.RDN_EditRecord_Security_UserRole",
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name for this User Role.",
							order: 1
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this User Role.",
							order: 2
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							title: "Select whether or not this User Role should be deleted.",
							label: "Deleted",
							order: 3,
							visible: true
						}),
						new UibButtonMultiSelectQuestion({
							key: "UserRolePrivileges",
							label: "Privileges",
							title: "Choose the Privileges that this User Role should have.",
							order: 4,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_Privileges",
								canExecuteStoredProcedureWithoutParameters: true,
								selectAllOnInitialLoad: false,
								parameters: [{ name: "@UserRoleId", value: "Id" }]
							}
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id for this User Role record.",
							order: 5,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_UserRole",
					allowAdd: true
				}
			}, //-- End of User Roles
			{
				// Site Simple Fields
				setName: "Site Simple Fields",
				tableDisplay: {
					tableName: "dbo.Site",
					entityName: "Site",
					entityDisplayName: "Site",
					label: "These are the Sites...",
					orderByColumns: ["Name", "Active"],
					maxRows: 30,
					allowDelete: false,
					searchColumns: ["Name", "Description"],
					defaultFilters: [
						{
							columnName: "Name",
							conditional: "like",
							value: "%DAL%"
						}
					],
					displayColumns: [this.commonColumnStructures.site.name.plain, this.commonColumnStructures.site.description.bold, this.commonColumnStructures.site.siteSystems]
				},
				editor: {
					editColumns: [
						{
							name: "LastName",
							label: "Last Name",
							type: "text" //(text, boolean, range, readOnly, name, email, mnemonic, date, textMax, html, cameraView,
							//-------------------------------------
							/*Types
							  text
							  boolean
							  range
							  readOnly
							  name
							  email
							  mnemonic
							  date
							  textMax
							  html
							  cameraView
							  systemId
							  cellPhoneCarrier
							  state
							  assetType
							  asset
							  site
							  tag
							  organization
							  person
							  uibButton
							  */
							//---------------------------------------
						}
					] //End of edit columns
				} //End of editor object
			}, // End of Site Simple Fields Object
			{  // Organization Users
				setName: "OrganizationUsers",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationUserRecords",
					expandedStoredProcedure: true,
					entityName: "OrganizationUser",
					entityDisplayName: "Organization User",
					label: "Organization Users",
					allowModification: Global.User.isAdmin,
					allowDelete: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Organization User record.",
							type: "text",
							hidden: true
						},
						{
							name: "UserId",
							label: "User Id",
							title: "The ID number of this User account record.",
							type: "text",
							hidden: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this account.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this person.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "UserRole",
							label: "User Role",
							title: "The User Role in their organization for this person.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "UserRecord",
							label: "User Record",
							title: "The User record for this Organization Site User.",
							type: "set",
							setName: "iOPSUsers",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "UserId",
							childColumnLink: "Id"
						},
						{
							name: "Email",
							label: "Email Address",
							title: "The Email Address of this person.",
							type: "text",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_OrganizationUser",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_OrganizationUser",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "RequestedUserId",
							label: "Requested User Id",
							title: "The User Id for this user account record.",
							order: 2,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "User",
							label: "User",
							title: "The Username of this user account record.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "IsOrganizationAdministrator",
							label: "Is Administrator?",
							title: "Will this user be an administrator for this organization?",
							order: 4,
							visible: Global.User.isAdmin || Global.User.isOrgAdmin //-- only System Administrators and Organization Administrators should have access to set users to Organization Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new UibButtonMultiSelectQuestion({
							key: "OrganizationUserRoles",
							label: "User Roles",
							title: "Choose the appropriate User Role for this user in this Organization.  If the user doesn't need elevated privileges for this organization, then you don't need to add this record.",
							order: 5,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_UserRoles_OrganizationUserOnly",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{
										name: "@RequestedUserId",
										value: "RequestedUserId"
									},
									{
										name: "@OrganizationUserId",
										value: "Id"
									}
								]
							},
							visibleFields: [
								{
									field: "IsOrganizationAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not an organization administrator, then show this field. 
							],
							
						}),

					]
				}
			}, // End of Organization Users
			{  // Organization Suites
				setName: "OrganizationSuites",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationSuiteRecords",
					expandedStoredProcedure: true,
					entityName: "OrganizationSuite",
					entityDisplayName: "Organization Suite",
					label: "Organization Suites",
					allowModification: Global.User.isAdmin,
					allowDelete: Global.User.isAdmin,
					disableAddRecordInsideRecursedSet: true, //--this should be handled inside of the Organization Site with a multi-select button group. --Kirk T. Sherer, September 22, 2021.
					disableAddRecordOutsideRecursedSet: true, //--this should be handled inside of the Organization Site with a multi-select button group. --Kirk T. Sherer, September 22, 2021.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Organization's Suite.",
							type: "text",
							hidden: true
						},
						{
							name: "Mnemonic",
							label: "Mnemonic",
							title: "The Mnemonic, or simple name, of this Suite.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Suite.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The description of this Suite.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this suite is active.",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "SuiteId",
							label: "Suite Id",
							title: "The Suite Id number of the Suite Module.",
							type: "text",
							hidden: true
						},
						{
							name: "SuiteModules",
							label: "Modules",
							title: "Modules assigned to this Suite.",
							type: "set",
							setName: "SuiteModules",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "SuiteId",
							childColumnLink: "SuiteId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						}
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Suite",
							label: "Suite",
							title: "Select the Suite to assign to this organization's site.",
							order: 2,
							type: "generic-selector",
							setName: "Suite",
							validateObject: true,
							required: true
						})
					]
				}
			}, // End of Organization Suites
			{
				// Organization Sites
				setName: "OrganizationSites",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationSiteRecords",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Organization Sites",
					entityName: "OrganizationSite",
					entityDisplayName: "Organization Site",
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this Organization Site record.",
							hidden: true
						},
						{
							name: "SiteName",
							label: "Name",
							userRole: "Admin",
							type: "text",
							title: "The name of the Site assigned to this Organization.",
							width: 25,
							widthUnits: "%",
							sortable: true,
							editable: true
						},
						{
							name: "SiteId",
							label: "Site Id",
							type: "text",
							title: "The Id Number of the Site assigned to this Organization.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OrganizationSiteId",
							label: "Organization Site Id",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OrganizationSiteUsers",
							label: "Organization Site Users",
							type: "set",
							title: "List of the Organization's Site Users.",
							setName: "OrganizationSiteUsers",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OrganizationSiteId"
						},
						{
							name: "OrganizationSiteSuites",
							label: "Organization Site Suites",
							type: "set",
							title: "List of the Organization's Site Suites.",
							setName: "OrganizationSiteSuites",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OrganizationSiteId"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_OrganizationSite",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_OrganizationSite",
					fields: [
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Select the Site assigned to this Organization.",
							order: 1,
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							required: true
						}),
						new UibButtonMultiSelectQuestion({
							key: "OrganizationSiteSuites",
							label: "Organization Site Suites",
							title: "Choose the Suites where this Site has a subscription.",
							order: 2,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationSiteSuites",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{
										name: "@OrganizationSiteId",
										value: "Id"
									}
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "OrganizationId",
							label: "Organization",
							type: "parent-key-field",
							title: "The Organization that owns this site.",
							listOfValues: this.dataService.cache.organizations,
							order: 3,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Organization Sites
			{
				// Organization Site Users
				setName: "OrganizationSiteUsers",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationSiteUserRecords",
					expandedStoredProcedure: true,
					entityName: "OrganizationSiteUser",
					entityDisplayName: "Organization Site User",
					maxRows: 40,
					label: "Organization Site Users",
					allowModification: Global.User.isAdmin,
					allowDelete: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this User account record.",
							type: "text",
							hidden: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this account.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this person.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Organization",
							label: "Organization",
							title: "The Organization of this person.",
							type: "set",
							setName: "Organization",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "OrganizationId",
							childColumnLink: "Id"
						},
						{
							name: "UserRecord",
							label: "User Record",
							title: "The User record for this Organization Site User.",
							type: "set",
							setName: "iOPSUsers",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "UserId",
							childColumnLink: "Id"
						},
						{
							name: "Email",
							label: "Email Address",
							title: "The Email Address of this person.",
							type: "text",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_OrganizationSiteUser",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_OrganizationSiteUser",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "RequestedUserId",
							label: "Username",
							title: "Enter the username to assign to this organization's site.",
							order: 2,
							type: "generic-selector",
							setName: "iOPSUsers",
							validateObject: true,
							required: true
						}),
						new ReadOnlyQuestion({
							key: "OrganizationSiteId",
							label: "Organization Site",
							type: "parent-key-field",
							title: "The Organization Site where this user resides.",
							listOfValues: this.dataService.cache.sites,
							order: 8,
							visible: false
						})
					]
				}
			}, // End of Organization Site Users
			{
				// Organization Site User Roles
				setName: "OrganizationSiteUserRoles",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationSiteUserRoleRecords",
					expandedStoredProcedure: true,
					entityName: "OrganizationSiteUserRole",
					entityDisplayName: "Organization Site User Role",
					maxRows: 40,
					label: "Organization Site User Roles",
					allowModification: Global.User.isAdmin,
					allowDelete: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this User account record.",
							type: "text",
							hidden: true
						},
						{
							name: "SiteName",
							label: "Site",
							title: "An authorized Site for this user.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "UserRole",
							label: "User Role",
							title: "The User Roles for this site for this user.",
							type: "text",
							width: 35,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this User Role record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this User Role record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this User Role record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this User Role record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_OrganizationSiteUserRole",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_OrganizationSiteUserRole",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "OrganizationSiteId",
							label: "Organization Site",
							order: 2,
							visible: true,
							type: "text"
						}),
						new UibButtonMultiSelectQuestion({
							key: "OrganizationSiteUserRoles",
							label: "Site User Roles",
							title: "Choose the appropriate User Role at each Site for this user.",
							order: 3,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_UserRoles",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{
										name: "@OrganizationSiteUserId",
										value: "OrganizationSiteUserId"
									}
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "OrganizationSiteUserId",
							label: "Organization Site User",
							type: "parent-key-field",
							title: "The Organization Site User affected by these user roles.",
							order: 3,
							visible: false
						})
					]
				}
			}, // End of Organization Site User Roles
			{
				// Organization Site Suites
				setName: "OrganizationSiteSuites",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationSiteSuiteRecords",
					expandedStoredProcedure: true,
					entityName: "OrganizationSiteSuite",
					entityDisplayName: "Organization Site Suite",
					maxRows: 40,
					label: "Organization Site Suites",
					allowModification: false, //--this should be handled inside of the Organization Site with a multi-select button group. --Kirk T. Sherer, September 22, 2021.
					allowDelete: false, //--this should be handled inside of the Organization Site with a multi-select button group. --Kirk T. Sherer, September 22, 2021.
					disableAddRecordInsideRecursedSet: true, //--this should be handled inside of the Organization Site with a multi-select button group. --Kirk T. Sherer, September 22, 2021.
					disableAddRecordOutsideRecursedSet: true, //--this should be handled inside of the Organization Site with a multi-select button group. --Kirk T. Sherer, September 22, 2021.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Organization's Site Suite.",
							type: "text",
							hidden: true
						},
						{
							name: "Mnemonic",
							label: "Mnemonic",
							title: "The Mnemonic, or simple name, of this Suite.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Suite.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The description of this Suite.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this suite is active.",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "SuiteId",
							label: "Suite Id",
							title: "The Suite Id number of the Suite Module.",
							type: "text",
							hidden: true
						},
						{
							name: "SuiteModules",
							label: "Modules",
							title: "Modules assigned to this Suite.",
							type: "set",
							setName: "SuiteModules",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "SuiteId",
							childColumnLink: "SuiteId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						}
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Suite",
							label: "Suite",
							title: "Select the Suite to assign to this organization's site.",
							order: 2,
							type: "generic-selector",
							setName: "Suite",
							validateObject: true,
							required: true
						}),
						new ReadOnlyQuestion({
							key: "OrganizationSiteId",
							label: "Organization Site",
							type: "parent-key-field",
							title: "The Organization Site where this user resides.",
							listOfValues: this.dataService.cache.sites,
							order: 8,
							visible: false
						})
					]
				}
			}, // End of Organization Site Suites
			{
				// Organization Site Systems
				setName: "OrganizationSiteSystems",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedSystemRecords",
					expandedStoredProcedure: true,
					entityName: "System",
					entityDisplayName: "Organization Site System",
					maxRows: 40,
					label: "Organization Site Systems",
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SystemType",
							label: "System Type",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Site",
							label: "Site",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Gate or System Name",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 20,
							widthUnits: "%"
						},
						{
							name: "DoNotDisplay",
							label: "Do Not Display",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "OwnerOrganization",
							label: "Owner Organization",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "Assets",
							label: "Assets",
							type: "set",
							setName: "Assets",
							width: 12,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ParentSystemId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name for this system.",
							order: 2
						}),
						new TextboxQuestion({
							key: "TypeId",
							label: "System Type",
							title: "Enter the appropriate Type of System here.",
							type: "generic-selector",
							setName: "SystemType",
							validateObject: true,
							required: true,
							order: 3
						}),
						new TextboxQuestion({
							key: "OwnerOrganizationId",
							label: "Onwer Organization",
							title: "Enter the organization that owns this system.",
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							order: 4
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this system.",
							order: 5
						}),
						new TextboxQuestion({
							key: "BaseUnitImageURL",
							label: "Base Unit Image URL",
							title: "Enter the URL for the base unit image.",
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "DoNotDisplay",
							label: "Do Not Display",
							title: "Select whether or not this system should be displayed.",
							order: 7
						}),
						new FileUploadQuestion({
							key: "BaseUnitGraphicImageKey",
							label: "System Graphic",
							title: "Upload the system's graphic here.",
							order: 8
						}),
						new ReadOnlyQuestion({
							key: "SiteId",
							label: "Site",
							type: "parent-key-field",
							title: "The Site where this system is referenced.",
							listOfValues: this.dataService.cache.sites,
							order: 9,
							visible: false
						})
					]
				}
			}, // End of Organization Site Systems
			{
				// Suites
				setName: "Suite",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedSuiteRecords",
					expandedStoredProcedure: true,
					entityName: "dbo.Suite",
					entityDisplayName: "Suite",
					maxRows: 40,
					label: "Application Suites",
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Suite record.",
							width: 5,
							type: "text",
							hidden: true
						},
						{
							name: "Mnemonic",
							title: "The short name of this Suite record.",
							label: "Mnemonic",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							title: "The Name of this Suite record.",
							label: "Name",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of this Suite record.",
							type: "text",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AirportSpecific",
							label: "Airport Specific?",
							title: "Whether or not this Suite record is specifically related to airport business.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",	
							label: "Active",
							title: "Whether or not this Suite record is active.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SuiteModules",
							label: "Modules",
							title: "Modules assigned to this Suite.",
							type: "set",
							setName: "SuiteModules",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "SuiteId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Suite",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Suite	",
					fields: [
						new TextboxQuestion({
							key: "Mnemonic",
							label: "Mnemonic",
							title: "Enter the mnemonic, or short name, of this Suite here.",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Suite Name",
							title: "Enter the name of the Suite here.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of the Suite here.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this Suite is active.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "AirportSpecific",
							label: "Airport Specific?",
							title: "Select whether or not this Suite is specifically related to airport business.",
							order: 4
						}),
						new UibButtonMultiSelectQuestion({
							key: "Modules",
							label: "Modules",
							title: "Choose the Modules for this Suite.",
							order: 5,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_SuiteModules",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [{ name: "@SuiteId", value: "Id" }]
							}
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id number of this Suite record.",
							type: "primary-key-field",
							order: 6,
							visible: false
						})
					]
				}
			}, // End of Suites
			{
				// Suites Modules
				setName: "SuiteModules",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedSuiteModuleRecords",
					expandedStoredProcedure: true,
					entityName: "dbo.SuiteModule",
					entityDisplayName: "Suite Module",
					maxRows: 40,
					label: "Suite Modules",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Suite Module record.",
							type: "text",
							hidden: true
						},
						{
							name: "Mnemonic",
							label: "Mnemonic",
							title: "The short name of this Suite Module record.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							title: "The name of this Suite Module record.",
							label: "Name",
							type: "text",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssignedWidgetTypes",
							label: "Assigned Widget Types",
							title: "Widget Types assigned to this Module.",
							type: "set",
							setName: "ModuleWidgetTypesByModuleId",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ModuleId"
						},
						{
							name: "Active",
							title: "Whether or not this Suite Module record is active.",
							label: "Active",
							type: "boolean",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				}
			}, // End of Suite Modules
			{
				// Modules
				setName: "Modules",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedModuleRecords",
					expandedStoredProcedure: true,
					entityName: "dbo.Module",
					entityDisplayName: "Module",
					maxRows: 40,
					label: "Modules",
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Module record.",
							type: "text",
							hidden: true
						},
						{
							name: "Mnemonic",
							label: "Mnemonic",
							title: "The short name of this Module record.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							title: "The name of this Module record.",
							label: "Name",
							type: "text",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssignedWidgetTypes",
							label: "Assigned Widget Types",
							title: "Widget Types assigned to this Module.",
							type: "set",
							setName: "ModuleWidgetTypesByModuleId",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ModuleId"
						},
						{
							name: "Active",
							title: "Whether or not this Module record is active.",
							label: "Active",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Module",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Module",
					fields: [
						new TextboxQuestion({
							key: "Mnemonic",
							label: "Mnemonic",
							title: "Enter the mnemonic, or short name, of this Module here.",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Module Name",
							title: "Enter the name of this Module here.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this Module here.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this Module is active.",
							order: 4
						}),
						new UibButtonMultiSelectQuestion({
							key: "ModuleWidgetTypes",
							label: "Widget Types",
							title: "Choose the Widget Types for this Module.",
							order: 21,
							buttonSize: "small",
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_WidgetTypesByModule",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [{ name: "@ModuleId", value: "Id" }]
							}
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id number of this Module record.",
							order: 6,
							visible: false,
							type: "primary-key-field"
						})
					]
				}
			}, // End of Modules
			{
				// Components
				setName: "Component",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetComponents",
					expandedStoredProcedure: true,
					entityName: "Component",
					entityDisplayName: "Component",
					label: "Angular Components",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 17,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AngularDirectiveName",
							label: "Angular Directive Name",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ComponentJBTStandardObservation",
							label: "Associated JBT Standard Observations",
							title: "Associated JBT Standard Observation records for this Angular Component.",
							type: "set",
							setName: "ComponentJBTStandardObservation",
							width: 15,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ComponentId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							order: 1,
							type: "primary-key-field",
							visible: false
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of this Angular Component.",
							type: "text",
							order: 2
						}),
						new TextboxQuestion({
							key: "AngularDirectiveName",
							label: "Angular Directive Name",
							title: "Enter the Angular Directive Name of this Angular Component.",
							type: "text",
							order: 3
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the detailed Description of this Angular Component.",
							type: "text",
							order: 4
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Component",
					allowAdd: true
				}
			}, // End of Components
			{
				// ComponentJBTStandardObservation
				setName: "ComponentJBTStandardObservation",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetComponentsListOfJBTStandardObservationRecords",
					expandedStoredProcedure: true,
					entityName: "ComponentJBTStandardObservation",
					entityDisplayName: "List of JBT Standard Observations for this Component",
					label: "List of JBT Standard Observations for this Component",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true, //--this should be handled inside of the Component list. --Kirk T. Sherer, November 12, 2021.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id number of this Component's JBT Standard Observation Record.",
							type: "text",
							hidden: true
						},
						{
							name: "Grouping",
							label: "Grouping",
							title: "The Grouping for the JBT Standard Observation records associated with this Angular Component.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationName",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation record associated with this Angular Component.",
							type: "text",
							width: 32,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Unit",
							label: "Unit of Measure",
							title: "The Unit of Measure associated with this Angular Component.",
							type: "text",
							width: 32,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ComponentJBTStandardObservationImages",
							label: "Images",
							title: "Associated images for this JBT Standard Observation record.",
							type: "set",
							setName: "ComponentJBTStandardObservationImage",
							width: 15,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ComponentJBTStandardObservationId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id for this associated JBT Standard Observation record.",
							visible: false
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation record associated with this Angular Component.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: false,
							order: 1
						}),
						new TextboxQuestion({
							key: "ComponentJBTStandardObservationGroupId",
							label: "Grouping",
							title: "The Group of JBT Standard Observation records for this Angular Component.",
							type: "generic-selector",
							setName: "ComponentJBTStandardObservationGroup",
							validateObject: true,
							allowBlankValue: true,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "ComponentId",
							label: "Component",
							title: "The Angular Component associated with this JBT Standard Observation record.",
							type: "parent-key-field",
							visible: false,
							order: 3
						})
					]
				}
			}, // End of ComponentJBTStandardObservation
			{
				// Component JBT Standard Observation Groups
				setName: "ComponentJBTStandardObservationGroup",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetComponentJBTStandardObservationGroups",
					expandedStoredProcedure: true,
					entityName: "ComponentJBTStandardObservationGroup",
					entityDisplayName: "Component JBT Standard Observation Groups",
					label: "Component JBT Standard Observation Groups",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 67,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of this Component Grouping for JBT Standard Observations.",
							type: "text",
							order: 2
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_ComponentJBTStandardObservationGroup",
					allowAdd: true
				}
			}, // End of Component JBT Standard Observation Groups
			{
				// Component JBT Standard Observation Images
				setName: "ComponentJBTStandardObservationImage",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetComponentJBTStandardObservationImages",
					expandedStoredProcedure: true,
					entityName: "ComponentJBTStandardObservationImage",
					entityDisplayName: "Component JBT Standard Observation Images",
					label: "Component JBT Standard Observation Images",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "ComponentJBTStandardObservationId",
							label: "ComponentJBTStandardObservationId",
							type: "parent-key-field",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							title: "The Name of this Condition for displaying the image.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							title: "The Description of this JBT Standard Observation's condition for this image which will display in the 'title' property when hovering over the image when displayed in the application.",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ObservationValue",
							label: "Observation Value",
							type: "text",
							title: "The value of the JBT Standard Observation that has to be considered 'true' in order to display this image.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Filename",
							label: "Image",
							type: "file-image",
							title: "The Name of this image which will display in the 'title' property when hovering over the image when displayed in the application.",
							width: 20,
							widthUnits: "%",
							imageKeyField: "ImageKey"
						},
						{
							name: "ImageKey",
							label: "ImageKey",
							type: "text",
							hidden: true
						},
						{
							name: "IsVideoServerImage",
							label: "IsVideoServerImage",
							type: "boolean",
							hidden: true
						},
						{
							name: "VideoServerFilename",
							label: "VideoServerFilename",
							type: "text",
							hidden: true
						},
						{
							name: "Thumbnail",
							label: "Thumbnail",
							type: "text",
							hidden: true
						},
						{
							name: "ThumbnailBase64",
							label: "ThumbnailBase64",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of this condition of the JBT Standard Observation.",
							type: "text",
							required: true,
							order: 2
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this image which will display in the 'title' property when hovering over the image when displayed in the application.",
							required: true,
							order: 3
						}),
						new TextboxQuestion({
							key: "ObservationValue",
							label: "Observation Value",
							title: "Enter the Value of the JBT Standard Observation for when this image should be displayed.",
							type: "text",
							required: true,
							order: 4
						}),
						new FileUploadQuestion({
							key: "ImageKey",
							label: "Image",
							title: "Upload the Image that should be displayed for this JBT Standard Observation.",
							required: true,
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "ComponentJBTStandardObservationId",
							label: "ComponentJBTStandardObservationId",
							order: 6,
							visible: false,
							type: "parent-key-field"
						})
					]
				}
			}, // End of Component JBT Standard Observation Images
			{
				// Associated Components
				setName: "AssociatedComponents",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetAssociatedComponents",
					expandedStoredProcedure: true,
					entityName: "Component",
					entityDisplayName: "Component",
					label: "Angular Components",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 17,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AngularDirectiveName",
							label: "Angular Directive Name",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ComponentJBTStandardObservation",
							label: "Associated JBT Standard Observations",
							title: "Associated JBT Standard Observation records for this Angular Component.",
							type: "set",
							setName: "ComponentJBTStandardObservation",
							width: 15,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ComponentId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of this Angular Component.",
							type: "text",
							order: 2
						}),
						new TextboxQuestion({
							key: "AngularDirectiveName",
							label: "Angular Directive Name",
							title: "Enter the Angular Directive Name of this Angular Component.",
							type: "text",
							order: 3
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the detailed Description of this Angular Component.",
							type: "text",
							order: 4
						})
					]
				}
			}, // End of Associated Components
			{
				// Widget Types
				setName: "WidgetTypes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedWidgetTypeRecords",
					expandedStoredProcedure: true,
					entityName: "WidgetType",
					entityDisplayName: "Widget Type",
					maxRows: 40,
					label: "Widget Types",
					allowModification: Global.User.isAdmin,
					allowDelete: Global.User.isAdmin,
					deleteStoredProcedure: "API.RDN_DeleteWidgetType",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Widget Type record.",
							type: "text",
							hidden: true
						},
						{
							name: "Mnemonic",
							label: "Mnemonic",
							title: "The short name of this Widget Type record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							title: "The name of this Widget Type record.",
							label: "Name",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							title: "The Description for this Widget Type record.",
							label: "Description",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						// {
						// 	name: "DashboardType",
						// 	title: "The Dashboard Type for this Widget Type record.",
						// 	label: "Dashboard Type",
						// 	type: "text",
						// 	width: 10,
						// 	widthUnits: "%",
						// 	sortable: true
						// },
						{
							name: "AssignedModules",
							label: "Assigned Modules",
							title: "Modules using this Widget Type.",
							type: "set",
							setName: "ModuleWidgetTypesByWidgetTypeId",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "WidgetTypeId"
						},
						{
							name: "WidgetTypeTabs",
							label: "Tabs",
							title: "Tabs for this Widget Type.",
							type: "set",
							setName: "WidgetTypeTab",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "WidgetTypeId"
						},
						{
							name: "ComponentSet",
							label: "Angular Component",
							title: "The Angular Component associated with this Widget Type.",
							type: "set",
							setName: "AssociatedComponents",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "ComponentId",
							childColumnLink: "Id",
							showButtonField: "HasComponent",
							buttonLabelField: "Component"
						},
						{
							name: "HasComponent",
							label: "Has Angular Component?",
							title: "Does this Widget Type have a corresponding Angular Component?",
							type: "boolean",
							hidden: true
						},
						{
							name: "Component",
							label: "Angular Component",
							title: "The Angular Component for this Widget Type record.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_WidgetType",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_WidgetType",
					fields: [
						new TextboxQuestion({
							key: "Mnemonic",
							label: "Mnemonic",
							title: "Enter the mnemonic, or short name, of this Widget Type here.",
							order: 1,
							required: true

						}),
						new TextboxQuestion({
							key: "Name",
							label: "Widget Type Name",
							title: "Enter the name of this Widget Type here.",
							order: 2,
							required: true
						}),
						new NumberQuestion({
							key: "InitialHeight",
							label: "Initial Height",
							title: "Initial height of the widget when this Widget Type is used.",
							order: 3,
							min: 5,
							max: 1000,
							required: true
						}),
						new NumberQuestion({
							key: "InitialWidth",
							label: "Initial Width",
							title: "Initial width of the widget when this Widget Type is used.",
							order: 4,
							min: 5,
							max: 1000,
							required: true
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this Widget Type here.",
							order: 5
						}),
						new TextboxQuestion({
							key: "CategoryPath",
							label: "Category Path",
							title: "Enter the Category Path of this Widget Type here.",
							order: 6
						}),
						new NumberQuestion({
							key: "DevelopmentPriority",
							label: "Development Priority",
							title: "Priority of when this Widget Type should be developed.",
							order: 7,
							min: 0,
							max: 100
						}),
						new DateQuestion({
							key: "DateCompleted",
							label: "Date Completed",
							title: "Date when this widget type was completed.",
							order: 8
						}),
						new DateQuestion({
							key: "EstimatedDateOfCompletion",
							label: "Estimated Date of Completion",
							title: "Estimated Date when this widget type might be completed.",
							order: 9
						}),
						new TextboxQuestion({
							key: "AngularDirectiveName",
							label: "Angular Directive Name",
							title: "Angular Directive or Component name of this Widget Type.",
							order: 10
						}),
						new TextboxQuestion({
							key: "ProperComponentName",
							label: "Proper Component Name",
							title: "Proper Component Name of this Widget Type.",
							order: 11
						}),
						new TextboxQuestion({
							key: "ModuleImportPath",
							label: "Module Import Path",
							title: "Import Path location of the Angular Module for this Widget Type.",
							order: 12
						}),
						new TextboxQuestion({
							key: "ComponentImportPath",
							label: "Component Import Path",
							title: "Import Path location of the Angular Component for this Widget Type.",
							order: 13
						}),
						new TextboxQuestion({
							key: "ComponentId",
							label: "Angular Component",
							title: "The Angular Component associated with this Widget Type.",
							type: "generic-selector",
							setName: "Component",
							validateObject: true,
							allowBlankValue: true,
							order: 14
						}),
						new NumberQuestion({
							key: "Ordinal",
							label: "Ordinal",
							title: "Ordinal value of this Widget Type (i.e. where this widget type will display when retrieved from the database.)",
							order: 15,
							min: 0,
							max: 1000
						}),
						new SliderYesNoQuestion({
							key: "IsAvailableToAdmin",
							label: "Is Available to Administrators?",
							title: "Select whether or not this Widget Type is available to Administrators.",
							order: 16
						}),
						new SliderYesNoQuestion({
							key: "IsAvailableToAll",
							label: "Is Available to All",
							title: "Select whether or not this Widget Type is Available to All Users.",
							order: 17
						}),
						new SliderYesNoQuestion({
							key: "IsHiddenSystemType",
							label: "Is Hidden System Type",
							title: "Select whether or not this Widget Type is a Hidden System Type.",
							order: 18
						}),
						new SliderYesNoQuestion({
							key: "HasSettings",
							label: "Has Settings",
							title: "Select whether or not this Widget Type should have Settings.",
							order: 19
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this Widget Type is active.",
							order: 20
						}),
						new SliderYesNoQuestion({
							key: "V2Compatible",
							label: "V2 Compatible?",
							title: "Select whether or not this Widget Type is compatible with the later version of Angular.",
							order: 21
						}),
						new UibButtonQuestion({
							key: "WidgetTypeTabGroupId",
							label: "Widget Type Tab Group",
							title: "Please select the appropriate Group for this Widget Type.",
							listOfValues: this.dataService.cache.widgetTypeTabGroups,
							order: 22
						}),
						// new UibButtonQuestion({
						// 	key: "DashboardTypeId",
						// 	label: "Dashboard Type",
						// 	title: "Please select the appropriate Dashboard Type for this Widget Type.",
						// 	listOfValues: this.dataService.cache.dashboardTypes,
						// 	order: 23
						// }),
						new UibButtonMultiSelectQuestion({
							key: "ModuleWidgetTypes",
							label: "Modules",
							title: "Choose the Modules where this Widget Type should be allowed to be used.",
							order: 24,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_ModulesByWidgetType",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [{ name: "@WidgetTypeId", value: "Id" }]
							}
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "Id of the Widget Type record.",
							order: 25,
							type: "primary-key-field",
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_WidgetType",
					allowAdd: false
				}
			}, // End of Widget Types
			{
				// Widget Type Tab
				setName: "WidgetTypeTab",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedWidgetTypeTabRecords",
					expandedStoredProcedure: true,
					entityName: "WidgetTypeTab",
					entityDisplayName: "Widget Type Tab",
					label: "Widget Type Tabs",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true, //--this should be handled inside of the Widget Type list. --Kirk T. Sherer, November 12, 2021.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id number of this Widget Type Tab.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Widget Type Tab.",
							type: "text",
							width: 24,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							title: "The Order where this Tab should be displayed in the main Widget Type view.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ComponentSet",
							label: "Angular Component",
							title: "The Angular Component associated with this Tab.",
							type: "set",
							setName: "AssociatedComponents",
							width: 30,
							widthUnits: "%",
							parentColumnLink: "ComponentId",
							childColumnLink: "Id",
							showButtonField: "HasComponent",
							buttonLabelField: "Component"
						},
						{
							name: "HasComponent",
							label: "Has Angular Component?",
							title: "Does this Widget Type Tab have a corresponding Angular Component?",
							type: "boolean",
							hidden: true
						},
						{
							name: "Component",
							label: "Angular Component",
							title: "The Angular Component for this Tab record.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "Id for the Widget Type Tab.",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Name of this Widget Type Tab.",
							order: 2
						}),
						new TextboxQuestion({
							key: "ComponentId",
							label: "Component",
							title: "Select the Angular Component being executed when the user chooses this Tab.",
							type: "generic-selector",
							setName: "Component",
							order: 3
						}),
						new NumberQuestion({
							key: "Ordinal",
							label: "Ordinal",
							title: "Select the Ordinal value (order in which the tabs should appear) for this widget.",
							order: 4
						}),
						new ReadOnlyQuestion({
							key: "WidgetTypeId",
							label: "Widget Type Id",
							title: "WidgetTypeId for the Widget Type Tab.",
							type: "parent-key-field",
							visible: false,
							order: 5
						})
					]
				}
			}, // End of Widget Type Tab
			{
				// Module Widget Types by WidgetTypeId
				setName: "ModuleWidgetTypesByWidgetTypeId",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedModuleWidgetTypeRecords",
					expandedStoredProcedure: true,
					entityName: "ModuleWidgetType",
					entityDisplayName: "Module Widget Types",
					maxRows: 40,
					label: "Module Widget Types",
					allowModification: false,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Module Widget Type record.",
							type: "text",
							hidden: true
						},
						{
							name: "Module",
							label: "Module",
							title: "The Module that contains the Widget Type.",
							type: "text",
							width: 40,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						}
					]
				},
				editor: {
					fields: []
				}
			}, // End of Module Widget Types by WidgetTypeId
			{
				// Module Widget Types by ModuleId
				setName: "ModuleWidgetTypesByModuleId",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedModuleWidgetTypeRecords",
					expandedStoredProcedure: true,
					entityName: "ModuleWidgetType",
					entityDisplayName: "Module Widget Types",
					maxRows: 40,
					label: "Module Widget Types",
					allowModification: false,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Module Widget Type record.",
							type: "text",
							hidden: true
						},
						{
							name: "WidgetType",
							title: "The Widget Type that is contained in the Module.",
							label: "Widget Type",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "DashboardType",
							title: "The Dashboard Type assigned to the Widget Type.",
							label: "Dashboard Type",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						}
					]
				},
				editor: {
					fields: []
				}
			}, // End of Module Widget Types by ModuleId
			{
				// Module Dashboard Types by WidgetTypeId
				setName: "ModuleDashboardTypesByWidgetTypeId",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedModuleDashboardTypeRecords",
					expandedStoredProcedure: true,
					entityName: "ModuleDashboardType",
					entityDisplayName: "Module Dashboard Types",
					maxRows: 40,
					label: "Module Dashboard Types",
					allowModification: false,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Module Dashboard Type record.",
							type: "text",
							hidden: true
						},
						{
							name: "Module",
							label: "Module",
							title: "The Module that contains the Dashboard Type.",
							type: "text",
							width: 40,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						}
					]
				},
				editor: {
					fields: []
				}
			}, // End of Module Dashboard Types by WidgetTypeId
			{
				// Module Dashboard Types by ModuleId
				setName: "ModuleDashboardTypesByModuleId",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedModuleDashboardTypeRecords",
					expandedStoredProcedure: true,
					entityName: "ModuleDashboardType",
					entityDisplayName: "Module Dashboard Types",
					maxRows: 40,
					label: "Module Dashboard Types",
					allowModification: false,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Module Dashboard Type record.",
							type: "text",
							hidden: true
						},
						{
							name: "DashboardType",
							title: "The Dashboard Type that is contained in the Module.",
							label: "Dashboard Type",
							type: "text",
							width: 50,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						}
					]
				},
				editor: {
					fields: []
				}
			}, // End of Module Dashboard Types by ModuleId
			{
				// Widget Type Tab Groups
				setName: "WidgetTypeTabGroup",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedWidgetTypeTabGroupRecords",
					expandedStoredProcedure: true,
					entityName: "WidgetTypeTabGroup",
					entityDisplayName: "Widget Type Tab Group",
					maxRows: 40,
					label: "Widget Type Tab Groups",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 35,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							type: "text",
							width: 17,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "V2Compatible",
							label: "V2 Compatible?",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Name of this Widget Type Tab Group.",
							order: 1
						}),
						new NumberQuestion({
							key: "Ordinal",
							label: "Ordinal",
							title: "Order position value of where this Widget Type Tab Group should appear.",
							order: 2,
							min: 0,
							max: 25
						}),
						new SliderYesNoQuestion({
							key: "V2Compatible",
							label: "V2 Compatible?",
							title: "Select whether or not this Widget Type Tab Group is compatible with the later version of Angular.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id for the Widget Type Tab Group.",
							visible: false,
							order: 4
						})
					]
				}
			}, // End of Widget Type Tab Groups
			{
				// Airline Lookups
				setName: "AirlineLookup",
				tableDisplay: {
					tableName: "dbo.AirlineLookup",
					entityName: "AirlineLookup",
					entityDisplayName: "Airline",
					maxRows: 40,
					label: "Airlines",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Code",
							label: "Code",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 82,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Code",
							label: "Code",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Airline Name",
							order: 2
						})
					]
				}
			}, // End of Airline Lookups
			{
				// Security Authorizable Activities
				setName: "AuthorizableActivity",
				tableDisplay: {
					tableName: "Security.AuthorizableActivity",
					entityName: "Security.AuthorizableActivity",
					entityDisplayName: "Authorizable Activity",
					maxRows: 40,
					label: "Authorizable Activities",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "UICode",
							label: "UI Code",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Activity",
							label: "Activity",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 62,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "UICode",
							label: "UI Code",
							order: 1
						}),
						new TextboxQuestion({
							key: "Activity",
							label: "Activity",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							order: 3
						})
					]
				}
			}, // End of Security Authorizable Activities
			{
				// Security Privileges
				setName: "Privilege",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedPrivilegeRecords",
					expandedStoredProcedure: true,
					entityName: "Security.Privilege",
					entityDisplayName: "Authorizable Privilege",
					maxRows: 40,
					label: "Authorizable Privileges",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this User Privilege.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed description of this User Privilege.",
							type: "text",
							width: 32,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OrganizationName",
							label: "Limited to Organization",
							title: "The Organization where this User Privilege is limited, if applicable.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Deleted",
							title: "Whether or not this User Privilege is marked for deletion.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OrganizationId",
							label: "OrganizationId",
							title: "The OrganizationId where this User Privilege is limited, if applicable.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The last person who modified this record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Privilege.",
							order: 1
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of what this Privilege gives to the user that possesses it.",
							order: 2
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Limited to this Organization",
							title: "Enter the Organization where this privilege might be limited, if applicable.",
							order: 3,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							label: "Deleted",
							title: "Choose whether or not to mark this User Privilege as deleted.",
							order: 4
						})
					]
				}
			}, // End of Security Privileges
			{
				// Canvas Widgets
				setName: "CanvasWidgetTypes",
				tableDisplay: {
					tableName: "CanvasWidgetType",
					entityName: "CanvasWidgetType",
					entityDisplayName: "Canvas Widget Type",
					maxRows: 40,
					label: "Canvas Widget Definitions",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "InitialHeight",
							label: "Initial Height",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "InitialWidth",
							label: "Initial Width",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 47,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AngularDirectiveName",
							label: "Angular Directive Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "IsAvailableToAdmin",
							label: "Is Admin?",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "IsAvailableToAll",
							label: "Show To All?",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							order: 1
						}),
						new NumberQuestion({
							key: "InitialHeight",
							label: "InitialHeight",
							order: 2,
							min: 0,
							max: 25
						}),
						new NumberQuestion({
							key: "InitialWidth",
							label: "InitialWidth",
							order: 3,
							min: 0,
							max: 25
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							order: 4
						}),
						new TextboxQuestion({
							key: "AngularDirectiveName",
							label: "Angular Directive Name",
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "IsAvailableToAdmin",
							label: "Is Available To Admin",
							order: 7
						}),
						new SliderYesNoQuestion({
							key: "IsAvailableToAll",
							label: "Show to All Users?",
							order: 8
						}),
						new FileUploadQuestion({
							key: "IconImageKey",
							label: "Icon Image",
							type: "file-upload",
							order: 9
						})
					]
				}
			}, // End of Canvas Widgets
			{
				// Sites
				setName: "Sites",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_SiteList",
					expandedStoredProcedure: true,
					entityName: "Site",
					entityDisplayName: "Site",
					maxRows: 40,
					label: "Sites",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [this.commonColumnStructures.site.id, this.commonColumnStructures.site.name.plain, this.commonColumnStructures.site.description.bold, this.commonColumnStructures.site.active, this.commonColumnStructures.site.location, this.commonColumnStructures.site.utcTimeOffset, this.commonColumnStructures.site.timeZone, this.commonColumnStructures.site.hasGates, this.commonColumnStructures.site.enableFlightData, this.commonColumnStructures.site.generateDockedTagFromAutolevelDeployedTag, this.commonColumnStructures.site.perfectTurnThresholds, this.commonColumnStructures.site.siteSystems, this.commonColumnStructures.actionsForSystemAdminOnly]
				},
				editor: {
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Site",
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Site.",
							order: 1
						}),
						new TextboxQuestion({
							key: "Mnemonic",
							label: "Mnemonic",
							title: "Enter the mnemonic of this Site. This value is required.",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this Site.",
							order: 3
						}),
						new NumberQuestion({
							key: "GoogleLatitude",
							label: "Latitude",
							title: "Enter the latitude for this Site.",
							order: 4
						}),
						new NumberQuestion({
							key: "GoogleLongitude",
							label: "Longitude",
							title: "Enter the longitude for this Site.",
							order: 5
						}),
						new TextboxQuestion({
							key: "TimeZoneListId",
							label: "Time Zone",
							title: "Select the appropriate Time Zone for this Site. Note that the displayed UTC Time Offset is taking into account if we're in the Daylight Saving Time season, and also whether or not the selected Time Zone uses Daylight Saving Time.",
							order: 6,
							type: "generic-selector",
							setName: "TimeZoneList",
							validateObject: true,
							required: false
						}),
						new TextboxQuestion({
							key: "TagNameFormat",
							label: "Tag Name Format",
							title: "Enter the correct pattern for the name of the Tag records for this site. The system will use the @SiteName|@SystemName|@AssetName|@TagSimpleName if you don't need to set this.",
							placeholder: "@SiteName|@SystemName|@AssetName|@TagSimpleName",
							order: 9
						}),
						new NumberQuestion({
							key: "PCAPerformanceTemperatureThreshold",
							label: "PCA Performance Temperature Threshold",
							title: "Enter the temperature threshold for when the Discharge Temperature is too high for the air conditioner to be considered 'meeting performance'.",
							order: 10,
							min: 0,
							max: 100
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Is Active",
							title: "Is this Site active?",
							order: 11
						}),
						new SliderYesNoQuestion({
							key: "HasGates",
							label: "Has Gates?",
							title: "Does this Site have gate systems?",
							order: 12
						}),
						new SliderYesNoQuestion({
							key: "EnableFlightData",
							label: "Enable Flight Data?",
							title: "Does this Site need flight data?",
							order: 13
						}),
						new NumberQuestion({
							key: "FlightDataSamplingIntervalMinutes",
							label: "Flight Data Sampling Interval (in minutes)",
							title: "Enter how frequently to get Flight Data for this site.",
							order: 14,
							min: 0,
							max: 60,
							visibleFields: [
								{
									field: "EnableFlightData",
									operator: "==",
									value: true
								} //-- if the Site is enabled to get flight data, then show this field.
							]
						}),
						new SliderYesNoQuestion({
							key: "HasBaggageHandling",
							label: "Has Baggage Handling?",
							title: "Does this site have Baggage Handling Systems?",
							order: 15
						}),
						new SliderYesNoQuestion({
							key: "GenerateDockedTagFromAutolevelDeployedTag",
							label: "Generate Docked Tag from Autolevel Deployed Tag?",
							title: "Will this site only use Autolevel Deployed status for generating an Aircraft Docked tag?",
							order: 16
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id for the Site record.",
							visible: false,
							order: 17
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Site",
					allowAdd: true
				}
			}, // End of Sites
			{  // SitesBySingleOrganization
				setName: "SitesBySingleOrganization",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_SiteList_BySingleOrganization",
					expandedStoredProcedure: true,
					entityName: "Site",
					entityDisplayName: "Site",
					maxRows: 40,
					label: "Sites",
					allowDelete: false,
					allowModification: false,
					displayColumns: [
						this.commonColumnStructures.site.id,
						this.commonColumnStructures.site.name.plain,
						this.commonColumnStructures.site.description.bold,
						this.commonColumnStructures.site.active,
						this.commonColumnStructures.site.location,
						this.commonColumnStructures.site.utcTimeOffset,
						this.commonColumnStructures.site.timeZone,
						this.commonColumnStructures.site.hasGates,
						this.commonColumnStructures.site.enableFlightData,
						this.commonColumnStructures.site.generateDockedTagFromAutolevelDeployedTag,
						this.commonColumnStructures.site.perfectTurnThresholds,
						this.commonColumnStructures.site.siteSystems,
						this.commonColumnStructures.actionsForAllAdminsOnly
					]
				},
				editor: {
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Site",
					fields: [
						new ReadOnlyQuestion({
							key: "Name",
							label: "Name",
							title: "The name of this Site.",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "Mnemonic",
							label: "Mnemonic",
							title: "The mnemonic of this Site.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Description",
							label: "Description",
							title: "The description of this Site.",
							order: 3
						}),
						new NumberQuestion({
							key: "PCAPerformanceTemperatureThreshold",
							label: "PCA Performance Temperature Threshold",
							title: "Enter the temperature threshold for when the Discharge Temperature is too high for the air conditioner to be considered 'meeting performance'.",
							order: 10,
							min: 0,
							max: 100
						}),
						new ReadOnlyQuestion({
							key: "Active",
							label: "Is Active",
							title: "Is this Site active?",
							order: 11
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id for the Site record.",
							visible: false,
							order: 17
						})
					]
				},
			}, // End of SitesBySingleOrganization
			{  // Site_PerfectTurnThreshold
				setName: "Site_PerfectTurnThreshold",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Site_PerfectTurnThresholds",
					expandedStoredProcedure: true,
					tableName: "dbo.Site_PerfectTurnThreshold",
					entityName: "Site_PerfectTurnThreshold",
					entityDisplayName: "Perfect Turn Threshold",
					label: "Perfect Turn Threshold",
					allowDelete: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordOutsideRecursedSet: true,
					deleteStoredProcedure: "API.RDN_Delete_Site_PerfectTurnThreshold",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id of the Perfect Turn Threshold record.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Threshold",
							title: "Name of the Perfect Turn Threshold.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of the Perfect Turn Threshold.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ThresholdInMinutes",
							label: "Minutes",
							title: "Number of minutes until Threshold is reached.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Cost",
							label: "Cost",
							title: "The Cost for going past the number of minutes for this Threshold. ",
							type: "set",
							setName: "Site_PerfectTurnThresholdCost",
							width: 7,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "Site_PerfectTurnThresholdId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Perfect Turn Threshold record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Perfect Turn Threshold record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this Perfect Turn Threshold record was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this Perfect Turn Threshold record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "PerfectTurnThresholdTypeId",
							label: "PerfectTurnThresholdTypeId",
							title: "Id of the Perfect Turn Threshold Type record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new UibButtonQuestion({
							key: "PerfectTurnThresholdTypeId",
							label: "Threshold Type",
							title: "Choose the type of Threshold associated with this Perfect Turn Threshold record.",
							order: 1,
							required: true,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_PerfectTurnThresholdTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [{ name: "@PerfectTurnThresholdTypeId", value: "PerfectTurnThresholdTypeId" }]
							}
						}),
						new NumberQuestion({
							key: "ThresholdInMinutes",
							title: "Enter the number of minutes until this Threshold is reached.",
							label: "Threshold in Minutes",
							required: true,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "SiteId",
							title: "The Site assigned to this Perfect Turn Threshold.",
							label: "Site",
							order: 3,
							type: "parent-key-field",
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Site_PerfectTurnThreshold
			{
				// Site_PerfectTurnThresholdCost
				setName: "Site_PerfectTurnThresholdCost",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Site_PerfectTurnThresholdCost",
					expandedStoredProcedure: true,
					tableName: "dbo.Site_PerfectTurnThresholdCost",
					entityName: "Site_PerfectTurnThresholdCost",
					entityDisplayName: "Perfect Turn Threshold Cost",
					label: "Perfect Turn Threshold Cost",
					allowDelete: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id of the Perfect Turn Threshold Cost record.",
							type: "text",
							hidden: true
						},
						{
							name: "FlightTypeName",
							label: "Flight Type",
							title: "The Flight Type associated with this Threshold Cost record.",
							type: "text",
							width: 37,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "FlightTypeId",
							label: "Flight Type Id",
							title: "The Flight Type record related to this Perfect Turn Threshold.",
							type: "text",
							hidden: true
						},
						{
							name: "CostPerMinute",
							label: "Cost Per Minute",
							title: "The Cost Per Minute for this flight type to go past the number of minutes set on the Threshold record.",
							type: "money",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Perfect Turn Threshold record.",
							type: "creator-user",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Perfect Turn Threshold record.",
							type: "last-modified-user",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this Perfect Turn Threshold record was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this Perfect Turn Threshold record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "Site_PerfectTurnThresholdId",
							label: "Site_PerfectTurnThresholdId",
							title: "Threshold Id number",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new UibButtonQuestion({
							key: "FlightTypeId",
							label: "Flight Type",
							title: "Choose the Flight Type associated with this Threshold Cost record.",
							order: 1,
							required: true,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_FlightTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [{ name: "@FlightTypeId", value: "FlightTypeId" }]
							}
						}),
						new NumberQuestion({
							key: "CostPerMinute",
							title: "Enter the Cost Per Minute for this Flight Type when the number of minutes for the Threshold has been reached.",
							label: "Cost Per Minute",
							required: true,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Site_PerfectTurnThresholdId",
							title: "The Perfect Turn Threshold Id for the parent record.",
							label: "Perfect Turn Threshold",
							order: 3,
							type: "parent-key-field",
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Site_PerfectTurnThresholdCost
			{
				// Cell Phone Carriers
				setName: "CellPhoneCarrier",
				tableDisplay: {
					tableName: "dbo.CellPhoneCarrier",
					entityName: "CellPhoneCarrier",
					entityDisplayName: "Cell Phone Carrier",
					maxRows: 10,
					label: "Cell Phone Carriers",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id of the Cell Phone Carrier record.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Cell Carrier",
							title: "Name of the Cell Phone Carrier.",
							type: "text",
							width: 30,
							widthUnits: "px",
							sortable: true
						},
						{
							name: "Extension",
							label: "Email Suffix",
							title: "Email suffix (ending for users to receive email via text) of the Cell Phone Carrier.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Notes",
							label: "Notes",
							title: "Notes about this Cell Phone Carrier.",
							type: "text",
							width: 37,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							title: "Enter the name of the Cell Phone Carrier.",
							label: "Name",
							order: 1
						}),
						new TextboxQuestion({
							key: "Extension",
							title: "Enter the Email suffix (ending for users to receive email via text) of the Cell Phone Carrier.",
							label: "Email Suffix",
							order: 2
						}),
						new TextboxQuestion({
							key: "Notes",
							title: "Enter the Notes about this Cell Phone Carrier.",
							label: "Notes",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 4,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_CellPhoneCarrier",
					allowAdd: false
				}
			}, // End of Cell Phone Carriers
			{
				// JBT Standard Observations
				setName: "JBTStandardObservation",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_JBTStandardObservationList",
					expandedStoredProcedure: true,
					entityName: "JBTStandardObservation",
					entityDisplayName: "JBT Standard Observation",
					maxRows: 40,
					label: "JBT Standard Observations",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id Number of this JBT Standard Observation.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "Name of this JBT Standard Observation.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Unit",
							label: "Unit of Measure",
							title: "The Unit of Measure for this Standard Observation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of this JBT Standard Observation.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationValueType",
							label: "Value Type",
							title: "The Value Type of this JBT Standard Observation.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationSeverityLevel",
							label: "Severity Level",
							title: "The Severity Level of this JBT Standard Observation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Type",
							label: "Type",
							type: "text",
							title: "The Type of this JBT Standard Observation.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "InternalType",
							label: "Internal Type",
							title: "The Internal Type of this JBT Standard Observation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this JBT Standard Observation is Active.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ValueWhenActive",
							label: "Value When Active",
							title: "The Value of this JBT Standard Observation when it is considered Active (true), if different from 'true'.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ExcludeFromReporting",
							label: "Exclude From Reporting",
							title: "Whether or not this JBT Standard Observation record is excluded from reporting.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this JBT Standard Observation record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this JBT Standard Observation record.",
							type: "last-modified-user",
							width: 14,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this JBT Standard Observation record was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this JBT Standard Observation record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "MinimumDeadbandSeconds",
							label: "Minimum Deadband in Seconds",
							title: "The minimum length of time in seconds before considering an observation assigned to this Standard Observation to wait until considered too frequent.  Setting this to zero means to ignore any tags assigned to this Standard Observation.",
							type: "number",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_JBTStandardObservation",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_JBTStandardObservation",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							title: "Id Number of this JBT Standard Observation.",
							type: "primary-key-field",
							label: "Id",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							title: "Enter the Name of this JBT Standard Observation.",
							label: "Name",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							title: "The Description of this JBT Standard Observation.",
							label: "Description",
							order: 3
						}),
						new TextboxQuestion({
							key: "ValueWhenActive",
							label: "ValueWhenActive",
							title: "Enter the Value when Active, if different from the normal 1=True (Active) and 0=False (Inactive).",
							required: false,
							validateObject: false,
							allowBlankValue: true,
							order: 4
						}),
						new NumberQuestion({
							key: "MinimumDeadbandSeconds",
							label: "Minimum Deadband Seconds",
							title: "The minimum length of time in seconds before considering an observation assigned to this Standard Observation to wait until considered too frequent.  Setting this to zero means to ignore any tags assigned to this Standard Observation.",
							order: 5,
							required: false,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "Active",
							title: "Select whether or not this JBT Standard Observation should be considered 'Active'.",
							label: "Active",
							required: true,
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "ExcludeFromReporting",
							title: "Select whether or not this JBT Standard Observation should be Excluded from Reporting. Standard Observations like 'Comms Loss' would qualify for this.",
							label: "Exclude from Reporting",
							required: false,
							order: 7
						}),
						new SliderYesNoQuestion({
							key: "AlarmStatusConfirmed",
							title: "Select whether or not the Fault Status of this JBT Standard Observation has been confirmed (validated).",
							label: "Fault Status Confirmed",
							required: false,
							order: 8
						}),
						new SliderYesNoQuestion({
							key: "ActivePeriodTracking",
							title: "Select whether or not we're in an Active Period of Tracking this JBT Standard Observation record.",
							label: "Active Period Tracking",
							required: false,
							order: 9
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationSeverityLevelId",
							label: "Severity Level",
							title: "Enter the severity level of this standard observation.",
							listOfValues: this.dataService.cache.jbtStandardObservationSeverityLevels,
							order: 10
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationValueTypeId",
							label: "Value Type",
							title: "Enter the value type of this standard observation.",
							listOfValues: this.dataService.cache.jbtStandardObservationValueTypes,
							order: 11
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationTypeId",
							label: "Type",
							title: "Enter the type of standard observation.",
							listOfValues: this.dataService.cache.jbtStandardObservationTypes,
							order: 12
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationInternalTypeId",
							label: "Internal Type",
							title: "Enter the internal type of standard observation, if applicable.",
							listOfValues: this.dataService.cache.jbtStandardObservationInternalTypes,
							order: 13
						}),
						new UibButtonQuestion({
							key: "UnitId",
							label: "Unit of Measure",
							title: "Please select the appropriate Unit of Measure for this standard observation.",
							listOfValues: this.dataService.cache.units,
							order: 14,
							required: false,
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							title: "Select whether or not this JBT Standard Observation should be considered 'Deleted'.",
							label: "Deleted",
							order: 14,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_JBTStandardObservation",
					allowAdd: true
				}
			}, // End of JBT Standard Observations
			{
				// JBT Standard Observation Types
				setName: "JBTStandardObservationTypes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_JBTStandardObservationTypesList",
					expandedStoredProcedure: true,
					entityName: "JBTStandardObservationType",
					entityDisplayName: "JBT Standard Observation Type",
					maxRows: 40,
					label: "JBT Standard Observation Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 37,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Marked for Deletion",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this observation type.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this observation type.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this observation type is active.",
							order: 6
						})
					]
				}
			}, // End of JBT Standard Observation Types
			{
				// JBT Standard Observation Internal Types
				setName: "JBTStandardObservationInternalTypes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_JBTStandardObservationInternalTypesList",
					expandedStoredProcedure: true,
					entityName: "JBTStandardObservationInternalType",
					entityDisplayName: "JBT Standard Observation Internal Type",
					maxRows: 40,
					label: "JBT Standard Observation Internal Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 37,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Marked for Deletion",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this internal observation type.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this internal observation type.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this internal observation type is active.",
							order: 6
						})
					]
				}
			}, // End of JBT Standard Observation Internal Types
			{  // Standard Names (was JBT Standard Observations) - still same table, but filtered by the stored procedure structure.
				sets: [
						{
							Name: "StandardNames_All",
							ParameterValue: null
						},
						{
							Name: "StandardNames_Adveez",
							ParameterValue: "'Adveez'"
						},
						{
							Name: "StandardNames_GateSystem",
							ParameterValue: "'Gate'"
						},
						{
							Name: "StandardNames_NoTags",
							ParameterValue: "'No Tags'"
						},
						{
							Name: "StandardNames_Oshkosh",
							ParameterValue: "'Osh'"
						}
				],
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_StandardObservationListWithNameSoundex @ListType=",
					expandedStoredProcedure: true,
					entityName: "JBTStandardObservation",
					entityDisplayName: "Standard Name",
					label: "Standard Names",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id Number of this Standard Name.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "Name of this Standard Name.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of this Standard Name.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "TagCount",
							label: "Tag Count",
							title: "The number of Tag records associated with this Standard Name.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationValueType",
							label: "Value Type",
							title: "The Value Type of this Standard Name.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationSeverityLevel",
							label: "Severity Level",
							title: "The Severity Level of this Standard Name.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Type",
							label: "Type",
							type: "text",
							title: "The Type of this Standard Name.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Unit",
							label: "Unit of Measure",
							title: "The Unit of Measure for this Standard Name.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "InternalType",
							label: "Internal Type",
							title: "The Internal Type of this Standard Name.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Standard Name is Active.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ValueWhenActive",
							label: "Value When Active",
							title: "The Value of this Standard Name when it is considered Active (true), if different from 'true'.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Standard Name record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Standard Name record.",
							type: "last-modified-user",
							width: 14,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this Standard Name record was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this Standard Name record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "MinimumDeadbandSeconds",
							label: "Minimum Deadband in Seconds",
							title: "The minimum length of time in seconds before considering an observation assigned to this Standard Name to wait until considered too frequent.  Setting this to zero means to ignore any tags assigned to this Standard Name.",
							type: "number",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_JBTStandardObservation",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_JBTStandardObservation",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							title: "Id Number of this Standard Name.",
							type: "primary-key-field",
							label: "Id",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							title: "Enter the Name of this Standard Name.",
							label: "Name",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							title: "The Description of this Standard Name.",
							label: "Description",
							order: 3
						}),
						new TextboxQuestion({
							key: "ValueWhenActive",
							label: "ValueWhenActive",
							title: "Enter the Value when Active, if different from the normal 1=True (Active) and 0=False (Inactive).",
							required: false,
							validateObject: false,
							allowBlankValue: true,
							order: 4
						}),
						new NumberQuestion({
							key: "MinimumDeadbandSeconds",
							label: "Minimum Deadband Seconds",
							title: "The minimum length of time in seconds before considering an observation assigned to this Standard Name to wait until considered too frequent.  Setting this to zero means to ignore any tags assigned to this Standard Name.",
							order: 5,
							required: false,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "Active",
							title: "Select whether or not this Standard Name should be considered 'Active'.",
							label: "Active",
							required: true,
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "ExcludeFromReporting",
							title: "Select whether or not this Standard Name should be Excluded from Reporting. Standard Names like 'Comms Loss' would qualify for this.",
							label: "Exclude from Reporting",
							required: false,
							order: 7
						}),
						new SliderYesNoQuestion({
							key: "AlarmStatusConfirmed",
							title: "Select whether or not the Fault Status of this Standard Name has been confirmed (validated).",
							label: "Fault Status Confirmed",
							required: false,
							order: 8
						}),
						new SliderYesNoQuestion({
							key: "ActivePeriodTracking",
							title: "Select whether or not we're in an Active Period of Tracking this Standard Name record.",
							label: "Active Period Tracking",
							required: false,
							order: 9
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationSeverityLevelId",
							label: "Severity Level",
							title: "Enter the severity level of this Standard Name.",
							listOfValues: this.dataService.cache.jbtStandardObservationSeverityLevels,
							order: 10
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationValueTypeId",
							label: "Value Type",
							title: "Enter the value type of this Standard Name.",
							listOfValues: this.dataService.cache.jbtStandardObservationValueTypes,
							order: 11
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationTypeId",
							label: "Type",
							title: "Enter the type of Standard Name.",
							listOfValues: this.dataService.cache.jbtStandardObservationTypes,
							order: 12
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationInternalTypeId",
							label: "Internal Type",
							title: "Enter the internal type of Standard Name, if applicable.",
							listOfValues: this.dataService.cache.jbtStandardObservationInternalTypes,
							order: 13
						}),
						new UibButtonQuestion({
							key: "UnitId",
							label: "Unit of Measure",
							title: "Please select the appropriate Unit of Measure for this Standard Name.",
							listOfValues: this.dataService.cache.units,
							order: 14,
							required: false,
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							title: "Select whether or not this Standard Name should be considered 'Deleted'.",
							label: "Deleted",
							order: 14,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_JBTStandardObservation",
					allowAdd: true
				}
			}, // End of StandardNames_All
			{  // Cameras
				setName: "Camera",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedCameraRecords",
					expandedStoredProcedure: true,
					entityName: "Camera",
					entityDisplayName: "Camera",
					maxRows: 40,
					label: "Cameras",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 31,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "HLSSourceURL",
							label: "View Camera",
							type: "link",
							linkName: "View Camera in New Window",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssignedSystem",
							label: "Assigned System",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssignedSite",
							label: "Assigned Site",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SelectorButtonLabel",
							label: "Selector Button Label",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Camera is active.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Camera",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Camera",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id value of this camera.",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter name of this camera.",
							order: 2,
							required: true
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description about this camera, if desired.",
							order: 3,
							required: false
						}),
						new TextboxQuestion({
							key: "SelectorButtonLabel",
							label: "Selector Button Label",
							title: "Enter the name of the button for the camera.",
							order: 4,
							required: true
						}),
						new TextboxQuestion({
							key: "AssignedSystemId",
							label: "System",
							title: "Enter the system where this camera is located.",
							order: 5,
							type: "generic-selector",
							setName: "System",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new UibButtonQuestion({
							//-- this one is for the average.  The next one is for the count.
							key: "CameraModelId",
							label: "Model",
							title: "Choose the Camera make and model.",
							order: 6,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_CameraModels",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@CameraModelId",
										value: "CameraModelId"
									}
								]
							},
							required: false
						}),
						new TextboxQuestion({
							key: "SerialNumber",
							label: "Serial Number",
							title: "Enter the serial number of this camera.",
							order: 7,
							required: false
						}),
						new ReadOnlyQuestion({
							key: "HLSSourceURL",
							label: "HLS Source URL",
							title: "Enter the HLS Source URL.",
							order: 8,
							visible: false,
							required: false //-- set this from the Global values when saving this record.
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active?",
							title: "Should this Asset be considered Active?",
							order: 9
						}),
						new TextboxQuestion({
							key: "AssignedSiteId",
							label: "Site",
							title: "Enter the site where this camera is located.",
							order: 10,
							visible: false
						})
					]
				}
			}, // End of Cameras
			{  // Camera Models
				setName: "CameraModel",
				tableDisplay: {
					tableName: "dbo.CameraModel",
					entityName: "CameraModel",
					entityDisplayName: "Camera Model",
					label: "Camera Models",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Make",
							label: "Make",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 35,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 50,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id value of this camera model.",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Make",
							label: "Make",
							title: "Enter the Make of (i.e. company that makes) this camera model.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter name of this camera model.",
							order: 3
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Description of this camera model.",
							order: 4
						}),
					]
				}
			}, // End of Camera Models
			{
				// PLC Programs
				setName: "PLCPrograms",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetPLCPrograms",
					expandedStoredProcedure: true,
					entityName: "PLCProgram",
					entityDisplayName: "PLC Program",
					maxRows: 10,
					label: "PLC Programs",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					allowCopy: true,
					allowAdd: true,
					copyStoredProcedure: "API.RDN_CopyPLCProgram",
					deleteStoredProcedure: "API.RDN_DeletePLCProgram",
					displayColumns: [
						{
							name: "Id",
							label: "PLC Program Id",
							title: "Id number of this Program.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ProgramName",
							label: "Program Name",
							title: "Name of this Program.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PLCProgramRevisions",
							label: "Revisions",
							title: "Revisions of this Program.",
							type: "set",
							setName: "PLCProgramRevisions",
							width: 9,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PLCProgramId"
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of this Program.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Program in iOPS.",
							type: "creator-user",
							width: 21,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Program in iOPS.",
							type: "last-modified-user",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The date and time that this Program was created in iOPS.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The date and time that this Program was last modified in iOPS.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "ProgramName",
							label: "Program Name",
							title: "Enter the name of this programmable logic controller's software Program.",
							order: 1
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this PLC Software Program.",
							order: 2
						})
					]
				}
			}, // End of PLC Programs
			{
				// PLC Program Revisions
				setName: "PLCProgramRevisions",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetPLCProgramRevisions",
					expandedStoredProcedure: true,
					entityName: "PLCProgramRevision",
					entityDisplayName: "PLC Program Revision",
					maxRows: 40,
					label: "PLC Program Revisions",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					allowCopy: true,
					copyStoredProcedure: "API.RDN_CopyPLCProgramRevision",
					deleteStoredProcedure: "API.RDN_DeletePLCProgramRevision",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Revision.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Revision",
							label: "Revision",
							title: "The name of this Revision.",
							type: "text",
							width: 12,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetType",
							label: "Asset Type",
							title: "Asset Types where this Program Revision is applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PLCProgramRevisionTags",
							label: "Tags",
							title: "The list of template Tags assigned to this Revision.",
							type: "set",
							setName: "PLCProgramRevisionTags",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PLCProgramRevisionId"
						},
						{
							name: "Assets",
							label: "Assets",
							type: "set",
							title: "The list of Assets assigned to this Revision.",
							setName: "PLCProgramRevisionAssets",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PLCProgramRevisionId"
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of this Revision.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Date",
							label: "Date",
							title: "The Start Date of this Revision, if applicable.",
							type: "text",
							width: 20,
							widthUnits: "%"
						},
						{
							name: "CopyTagsFromAssetId",
							label: "Copy Tags from this Asset Id",
							title: "The Asset Id to use to copy tags for this Program Revision.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Revision in iOPS.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Revision in iOPS.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The date and time this Revision was created in iOPS.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time this Revision was last modified in iOPS.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_PLCProgramRevision",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_PLCProgramRevision",
					fields: [
						new TextboxQuestion({
							key: "Revision",
							label: "Revision",
							title: "Enter the Revision Level of this PLC Software Program.",
							order: 1,
							required: true
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the Asset Type for this PLC Software Program Revision.",
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							allowBlankValue: false,
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this PLC Software Program Revision Level.",
							order: 3
						}),
						new TextboxQuestion({
							key: "CopyTagsFromAssetId",
							label: "Asset to Copy Tags (if applicable)",
							title: "Select the Asset that most closely matches the set of tags for this new Program Revision.",
							type: "generic-selector",
							setName: "Assets",
							validateObject: true,
							allowBlankValue: true,
							order: 4
							// visibleFields: [
							// 	{
							// 		field: "Id",
							// 		operator: "==",
							// 		value: null
							// 	} //-- if this is a new revision being entered.
							// ],
						}),
						new DateQuestion({
							key: "Date",
							label: "Date",
							title: "Enter the Date of this PLC Software Program Revision Level, if applicable.",
							type: "date",
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "PLCProgramId",
							label: "PLC Program",
							type: "parent-key-field",
							title: "The PLC Program where this is referenced.",
							listOfValues: this.dataService.cache.plcPrograms,
							order: 6,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "The Id field of this Program Revision record.",
							order: 7,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_PLCProgramRevision",
					allowAdd: false
				}
			}, // End of PLC Program Revisions
			{
				// PLC Program Revision Assets
				setName: "PLCProgramRevisionAssets",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetRecords",
					expandedStoredProcedure: true,
					entityName: "Asset",
					entityDisplayName: "PLC Program Revision Asset",
					maxRows: 10,
					label: "PLC Program Revision Assets",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Asset.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Site",
							label: "Site",
							title: "Site location of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Gate",
							label: "Gate or System",
							title: "Gate or System of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},

						{
							name: "Name",
							label: "Asset Name",
							title: "Name of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PreferredName",
							label: "Preferred Name",
							title: "The Preferred Name of this Asset, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetType",
							label: "Asset Type",
							title: "The type of Asset.",
							type: "text",
							width: 7,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Asset is active.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Model",
							label: "Model",
							title: "The Model of this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "OwnerOrganization",
							label: "Owner Organization",
							title: "The Organization that owns this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "OperatorOrganization",
							label: "Operator Organization",
							title: "The Organization that operates (uses) this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "ManufacturerOrganization",
							label: "Manufacturer Organization",
							title: "The Organization that built this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "SiteId",
							label: "Site Record",
							title: "The Site where this Asset is located.",
							type: "set",
							setName: "Sites",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "SiteId",
							childColumnLink: "Id"
						},
						{
							name: "AssetId",
							label: "Asset Record",
							type: "set",
							title: "The Asset record for the PLC Program Revision Asset List shown here.",
							setName: "Assets",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "Id"
						},
						{
							name: "PossibleTags",
							label: "Possible Tags",
							type: "set",
							title: "The list of possible Tag records for this asset assigned to this Revision.",
							setName: "PLCProgramRevisionPossibleTags",
							width: 5,
							widthUnits: "%",
							parameters: [
								{
									name: "@PLCProgramRevisionId",
									value: "PLCProgramRevisionId"
								},
								{
									name: "@AssetId",
									value: "Id"
								}
							],
							//showButtonField: "IsAssignedToPLCProgramRevision"
						},
						{
							name: "AdveezAssetId",
							label: "Adveez Asset Id",
							title: "The Adveez Asset Id of this asset, if applicable.",
							type: "text",
							hidden: true
						},
						{
							name: "PLCProgramRevisionId",
							label: "PLC Program Revision Id",
							title: "The Id of this Program Revision.",
							type: "text",
							hidden: true
						},
						{
							name: "IsAssignedToPLCProgramRevision",
							label: "Is Assigned to a PLC Program Revision?",
							title: "Is the asset assigned to a PLC Program Revision? ",
							type: "boolean",
							hidden: true
						}
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Asset",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Asset",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Asset.",
							type: "primary-key-field",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "AssetTypeGroupId",
							label: "Asset Type Group",
							title: "The type of group where this Asset Type belongs.",
							visible: false,
							order: 2,
							required: false
						}),
						new TextboxQuestion({
							key: "AssetName",
							label: "Name",
							title: "Enter the name of this Asset.",
							order: 3,
							type: "asset",
							required: true
						}),
						new TextboxQuestion({
							key: "TagNamePrefix",
							label: "Tag Name Prefix",
							title: "Enter the prefix of each of the tags for this Asset as they are appearing in the database. This field value is critical for the setup of the correct SignalR messages as well as the widgets.",
							order: 4,
							required: true,
							visible: this.currentUser?.Rockstar
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the appropriate Type for this Asset.",
							order: 5,
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							required: true
						}),
						new TextboxQuestion({
							key: "AssetModelId",
							label: "Asset Model",
							title: "Select the appropriate Model for this Asset.",
							order: 6,
							type: "generic-selector",
							setName: "AssetModel",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "PreferredName",
							label: "Preferred Name",
							title: "Enter the preferred Name of the asset, if applicable. Please do not change from the original Asset Name value, unless it's appropriate.",
							order: 7,
							type: "text",
							required: false,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "!=",
									value: 1
								} //-- if this Asset is a gate system asset, then don't show this field.
							],
						}),
						new TextboxQuestion({
							key: "ModemNumber",
							label: "Modem Number",
							title: "Enter the Modem Number of this Asset, if applicable.",
							order: 8,
							type: "text",
							required: false,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							]
						}),
						new TextareaFancyQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this asset.",
							order: 9,
							required: false
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Select the appropriate Site where this Asset is located.",
							order: 10,
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							allowBlankValue: true,
							required: false,
						
						}),
						new SliderYesNoQuestion({
							key: "HasElevatorRotunda",
							label: "Has Elevating Rotunda?",
							title: "Does this asset have an Elevating Rotunda?",
							order: 11,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							required: false
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active?",
							title: "Should this Asset be considered Active?",
							order: 12,
							required: false
						}),
						new DateQuestion({
							key: "RetireDate",
							label: "Date of Inactivation",
							title: "The Date this asset was set to Inactive.",
							visibleFields: [
								{
									field: "Active",
									operator: "==",
									value: false
								} //-- if this Asset was set to Active = false.
							],
							required: false,
							order: 13
						}),
						new DateQuestion({
							key: "InServiceDate",
							label: "In Service Date",
							title: "The Date this asset placed in service.",
							order: 14,
							required: false
						}),
						new TextboxQuestion({
							key: "OwnerOrganizationId",
							label: "Owner Organization",
							title: "Select the appropriate Organization that Owns this Asset.",
							order: 15,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "OperatorOrganizationId",
							label: "Operator Organization",
							title: "Select the appropriate Organization that Operates (or uses) this Asset.",
							order: 16,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "ManufacturerOrganizationId",
							label: "Manufacturer Organization",
							title: "Select the appropriate Organization that Manufactured this Asset.",
							order: 17,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "MaintenanceOrganizationId",
							label: "Maintenance Organization",
							title: "Select the appropriate Organization that will maintain this Asset.",
							order: 18,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "PLCProgramRevisionId",
							title: "Select the appropriate PLC (Programmable Logic Controller) Software Program Revision that communicates with this Asset.",
							label: "PLC Program Revision",
							order: 19,
							type: "generic-selector",
							setName: "PLCProgramRevisions",
							validateObject: false,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "AdveezAssetId",
							title: "The Id number of the Adveez Asset record, if applicable.",
							label: "Adveez Asset Name",
							order: 20,
							type: "generic-selector",
							setName: "AdveezAsset",
							validateObject: true,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							],
							required: false
						}),
						new SliderYesNoQuestion({
							key: "GenerateDockedTagFromAutolevelDeployedTag",
							label: "Generate Docked Tag from Autolevel Deployed Tag?",
							title: "Will this asset only use Autolevel Deployed status for generating an Aircraft Docked tag?",
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							order: 21,
							required: false
						}),
						new UibButtonQuestion({
							key: "FleetThingId",
							label: "Fleet",
							title: "Choose the appropriate Fleet for this asset.",
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_FleetThings",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@FleetThingId",
										value: "FleetThingId"
									}
								]
							},
							type: "fleet",
							order: 22,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "==",
									value: 3
								} //-- if the current AssetTypeGroupId is a fire or refuse truck, then show this field.
							],
							required: false
						}),
						new ReadOnlyQuestion({
							key: "ParentSystemId",
							label: "System",
							type: "parent-key-field",
							title: "The System where this is referenced.",
							listOfValues: this.dataService.cache.systems,
							order: 23,
							visible: false,
							required: true
						}),

					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Asset",
					allowAdd: false
				}

			}, // End of PLC Program Revision Assets
			{
				// PLC Program Revision Tags
				setName: "PLCProgramRevisionTags",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetPLCProgramRevisionTags",
					expandedStoredProcedure: true,
					entityName: "PLCProgramRevisionTag",
					entityDisplayName: "PLC Program Revision Tag",
					maxRows: 40,
					label: "PLC Program Revision Tags",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					allowCopy: true,
					copyStoredProcedure: "API.RDN_CopyPLCProgramRevisionTag",
					deleteStoredProcedure: "API.RDN_DeletePLCProgramRevisionTag",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this Tag.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ProgramRevision",
							label: "PLC Program Revision",
							title: "The Program Revision where this Tag is assigned.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "TagSimpleName",
							label: "Tag Simple Name",
							title: "The ending portion of normal Tag names that would qualify for this PLC Program Tag.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservation",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation assigned to this Tag.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "BitPositionCompositeType",
							label: "Bit Position Composite Type",
							title: "Bit Position Composite type for this Tag.",
							type: "text",
							hidden: true
						},
						{
							name: "IsBitPositionComposite",
							label: "Is Bit Position Composite?",
							title: "Does this Tag contain Bit Position Composite breakouts?",
							type: "boolean",
							hidden: true
						},
						{
							name: "IsValueComposite",
							label: "Is Value Composite?",
							title: "Does this Tag contain Value Composite breakouts?",
							type: "boolean",
							hidden: true
						},
						{
							name: "PLCProgramRevisionTagBitPositionCompositeComponents",
							label: "Bit Position Composite",
							title: "The list of Bit Position Composite breakouts for this Tag.",
							type: "set",
							setName: "PLCProgramRevisionTagBitPositionCompositeComponent",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PLCProgramRevisionTagId",
							showButtonField: "IsBitPositionComposite",
							additionalLabelField: "BitPositionCompositeType"
						},
						{
							name: "PLCProgramRevisionTagValueCompositeComponents",
							label: "Value Composite",
							title: "The list of Value Composite breakouts for this Tag.",
							type: "set",
							setName: "PLCProgramRevisionTagValueCompositeComponent",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PLCProgramRevisionTagId",
							showButtonField: "IsValueComposite"
						},
						{
							name: "CreatorUser",
							title: "The person who created this Tag in iOPS.",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Tag in iOPS.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The date and time this Tag was created in iOPS.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time this Tag was last modified in iOPS.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "TagSimpleName",
							label: "Tag Simple Name",
							title: "Enter the suffix of the Tag Names that will be related to this PLC Program Revision Level.",
							order: 1
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "Select the appropriate JBT Standard Observation for this Tag Name.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							order: 2
						}),
						new SliderYesNoQuestion({
							key: "IsBitPositionComposite",
							label: "Is Bit Position Composite?",
							title: "Select whether or not this is a Bit Position Composite selection.",
							order: 3
						}),
						new TextboxQuestion({
							key: "BitPositionCompositeTypeId",
							label: "Bit Position Composite Type",
							title: "Select the type of breakout setup in this Bit Position Composite selection.",
							type: "generic-selector",
							setName: "BitPositionCompositeTypes",
							validateObject: true,
							allowBlankValue: true,
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "IsValueComposite",
							label: "Is Value Composite?",
							title: "Select whether or not this is a Value Composite selection.",
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "PLCProgramRevisionId",
							label: "PLC Program Revision",
							type: "parent-key-field",
							title: "The PLC Program Revision where this is referenced.",
							listOfValues: this.dataService.cache.plcProgramRevisions,
							order: 6,
							visible: false
						})
					]
				}
			}, // End of PLC Program Revision Tags
			{
				// PLC Program Revision Tag Bit Position Composite Components
				setName: "PLCProgramRevisionTagBitPositionCompositeComponent",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetPLCProgramRevisionTagBitPositionCompositeComponents",
					expandedStoredProcedure: true,
					entityName: "PLCProgramRevisionTagBitPositionCompositeComponent",
					entityDisplayName: "PLC Program Revision Tag Bit Position Composite Component",
					maxRows: 40,
					label: "PLC Program Revision Tag Bit Position Composite Components",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					allowCopy: true,
					copyStoredProcedure: "API.RDN_CopyPLCProgramRevisionTagBitPositionCompositeComponent",
					deleteStoredProcedure: "API.RDN_DeletePLCProgramRevisionTagBitPositionCompositeComponent",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID Number for this Bit Position Breakout.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "BitPosition",
							title: "The Bit Position for this observation.",
							label: "Bit Position",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SimpleName",
							label: "Simple Name",
							title: "The simple name for this Bit Position Breakout.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "TagSimpleName",
							label: "PLC Program Revision Tag",
							title: "The ending portion of normal Tag names that would qualify for this PLC Program Tag's Bit Position Breakout.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservation",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation assigned to this Bit Position Breakout.",
							type: "text",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "IsWarning",
							label: "Is Warning?",
							title: "Should this override the JBT Standard Observation Severity Level and be marked as a Warning?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "IsAlarm",
							label: "Is Fault?",
							title: "Should this override the JBT Standard Observation Severity Level and be marked as an Fault?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "IsCritical",
							label: "Is Critical?",
							type: "boolean",
							title: "Should this override the JBT Standard Observation Severity Level and be marked as Critical?",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Bit Position Breakout in iOPS.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Bit Position Breakout in iOPS.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The date and time this Bit Position breakout was created in iOPS.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time this Bit Position breakout was last modified in iOPS.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new NumberQuestion({
							key: "BitPosition",
							label: "Bit Position",
							title: "Enter the Bit Position.",
							order: 1,
							min: 0,
							max: 64
						}),
						new TextboxQuestion({
							key: "SimpleName",
							label: "Simple Name",
							title: "Enter the Simple Name of this Bit Position.",
							order: 2
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Bit Position.",
							order: 3
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "Enter the appropriate JBT Standard Observation for this Bit Position.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "IsWarning",
							label: "Is Warning?",
							title: "Select whether or not this Bit Position Warning should override the JBT Standard Observation Severity Level.",
							order: 5
						}),
						new SliderYesNoQuestion({
							key: "IsAlarm",
							label: "Is Fault?",
							title: "Select whether or not this Bit Position Fault should override the JBT Standard Observation Severity Level.",
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "IsCritical",
							label: "Is Critical?",
							title: "Select whether or not this Bit Position Critical should override the JBT Standard Observation Severity Level.",
							order: 7
						}),
						new ReadOnlyQuestion({
							key: "PLCProgramRevisionTagId",
							label: "PLC Program Revision Tag",
							type: "parent-key-field",
							title: "The PLC Program Revision Tag where this is referenced.",
							listOfValues: this.dataService.cache.plcProgramRevisionTags,
							order: 8,
							visible: false
						})
					]
				}
			}, // End of PLC Program Revision Tag Bit Position Composite Component
			{
				// PLC Program Revision Tag Value Composite Components
				setName: "PLCProgramRevisionTagValueCompositeComponent",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetPLCProgramRevisionTagValueCompositeComponents",
					expandedStoredProcedure: true,
					entityName: "PLCProgramRevisionTagValueCompositeComponent",
					entityDisplayName: "PLC Program Revision Tag Value Composite Component",
					maxRows: 40,
					label: "PLC Program Revision Tag Value Composite Components",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					allowCopy: true,
					copyStoredProcedure: "API.RDN_CopyPLCProgramRevisionTagValueCompositeComponent",
					deleteStoredProcedure: "API.RDN_DeletePLCProgramRevisionTagValueCompositeComponent",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SimpleName",
							label: "Simple Name",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "Value",
							label: "Value",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "TagSimpleName",
							label: "PLC Program Revision Tag",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservation",
							label: "JBT Standard Observation",
							type: "text",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "IsWarning",
							label: "Is Warning?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "IsAlarm",
							label: "Is Fault?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "IsCritical",
							label: "Is Fault?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Value",
							label: "Value",
							title: "Enter the value of this Value Position component.",
							order: 1
						}),
						new TextboxQuestion({
							key: "SimpleName",
							label: "Simple Name",
							title: "Enter the simple name of this Value Position.",
							order: 2
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this Value Position.",
							order: 3
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							title: "Select the appropriate JBT Standard Observation for this Value Position.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "IsWarning",
							label: "Is Warning?",
							title: "Select whether or not this Value Position Warning should override the JBT Standard Observation Severity Level.",
							order: 5
						}),
						new SliderYesNoQuestion({
							key: "IsAlarm",
							label: "Is Fault?",
							title: "Select whether or not this Value Position Fault should override the JBT Standard Observation Severity Level.",
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "IsCritical",
							label: "Is Critical?",
							title: "Select whether or not this Value Position Critical should override the JBT Standard Observation Severity Level.",
							order: 7
						}),
						new ReadOnlyQuestion({
							key: "PLCProgramRevisionTagId",
							label: "PLC Program Revision Tag",
							type: "parent-key-field",
							title: "The PLC Program Revision Tag where this is referenced.",
							listOfValues: this.dataService.cache.plcProgramRevisionTags,
							order: 8,
							visible: false
						})
					]
				}
			}, // End of PLC Program Revision Tag Bit Position Composite Component
			{
				// PLC Bit Position Composite Types
				setName: "BitPositionCompositeTypes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetBitPositionCompositeType",
					expandedStoredProcedure: true,
					entityName: "BitPositionCompositeType",
					entityDisplayName: "Bit Position Composite Type",
					maxRows: 40,
					label: "PLC Bit Position Composite Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 57,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Deleted?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Bit Position Composite Type.",
							type: "text",
							order: 2
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_BitPositionCompositeType",
					allowAdd: true
				}
			}, // End of PLC Bit Position Composite Types
			{
				// PLC Program Revision Possible Tags from Revision Tag list
				setName: "PLCProgramRevisionPossibleTags",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetPLCProgramRevisionPossibleTags",
					expandedStoredProcedure: true,
					entityName: "PLCProgramRevisionPossibleTag",
					entityDisplayName: "PLC Program Revision Possible Tag",
					maxRows: 40,
					label: "PLC Program Revision Possible Tags",
					allowDownloadList: false, //-- put this back as soon as the spreadsheet download function works in the webAPI.  Currently it works in Postman, but does not in the iOPS application. --Kirk T. Sherer, May, 1, 2023.
					allowCreateTagsForAsset: true,
					displayColumns: [
						{
							name: "TagName",
							label: "Tag Name",
							title: "The possible Name of the Tag based on the Program Revision Tag Name structure field.",
							type: "text",
							width: 28,
							widthUnits: "%",
							download: true
						},
						{
							name: "TagSimpleName",
							label: "Tag Simple Name",
							title: "The ending portion of normal Tag names that would qualify for this PLC Program Tag.",
							type: "text",
							width: 22,
							widthUnits: "%",
							download: true
						},
						{
							name: "JBTStandardObservation",
							label: "Standard Observation",
							title: "The Standard Observation that should be assigned to this Tag, based on the Program Revision Tag settings.",
							type: "text",
							width: 20,
							widthUnits: "%",
							download: false
						},
						{
							name: "ActualTagStdObsId",
							label: "Current Std Obs Id",
							title: "The current Standard Observation that is assigned to the asset's actual Tag record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							download: false
						},
						{
							name: "ActualTagId",
							label: "Actual Tag Id",
							title: "The asset's actual Tag record Id number that would be changed when the asset is updated with the Program Revision's settings for the list of tags.",
							type: "text",
							width: 5,
							widthUnits: "%",
							download: false
						},
						{
							name: "DataType",
							label: "Data Type",
							title: "The Data Type of this tag.  Possible values would be boolean, short (integer), float, string.",
							type: "text",
							hidden: true,
							download: true
						},
						{
							name: "LastObservationDate",
							label: "Last Observation Date",
							title: "The Last Observation Date/Time for this tag, if it exists.",
							type: "date",
							width: 10,
							widthUnits: "%",
							download: false
						},
						{
							name: "LastObservationTextValue",
							label: "Last Observation Text Value",
							title: "The Last Observation Text Value for this tag, if it exists.",
							type: "text",
							width: 10,
							widthUnits: "%",
							download: true
						},

						// {
						// 	name: "BitPositionCompositeType",
						// 	label: "Bit Position Composite Type",
						// 	title: "Bit Position Composite type for this Tag.",
						// 	type: "text",
						// 	hidden: true,
						// 	download: false
						// },
						// {
						// 	name: "IsBitPositionComposite",
						// 	label: "Is Bit Position Composite?",
						// 	title: "Does this Tag contain Bit Position Composite breakouts?",
						// 	type: "boolean",
						// 	hidden: true,
						// 	download: false
						// },
						// {
						// 	name: "IsValueComposite",
						// 	label: "Is Value Composite?",
						// 	title: "Does this Tag contain Value Composite breakouts?",
						// 	type: "boolean",
						// 	hidden: true,
						// 	download: false
						// },
						// {
						// 	name: "PLCProgramRevisionTagBitPositionCompositeComponents",
						// 	label: "Bit Position Composite",
						// 	title: "The list of Bit Position Composite breakouts for this Tag.",
						// 	type: "set",
						// 	setName: "PLCProgramRevisionTagBitPositionCompositeComponent",
						// 	width: 12,
						// 	widthUnits: "%",
						// 	parentColumnLink: "Id",
						// 	childColumnLink: "PLCProgramRevisionTagId",
						// 	showButtonField: "IsBitPositionComposite",
						// 	additionalLabelField: "BitPositionCompositeType",
						// 	download: false
						// },
						// {
						// 	name: "PLCProgramRevisionTagValueCompositeComponents",
						// 	label: "Value Composite",
						// 	title: "The list of Value Composite breakouts for this Tag.",
						// 	type: "set",
						// 	setName: "PLCProgramRevisionTagValueCompositeComponent",
						// 	width: 13,
						// 	widthUnits: "%",
						// 	parentColumnLink: "Id",
						// 	childColumnLink: "PLCProgramRevisionTagId",
						// 	showButtonField: "IsValueComposite",
						// 	download: false
						// },
						{
							name: "SiteId",
							label: "Site Id",
							title: "Site Id of the asset listed here.",
							type: "text",
							hidden: true,
							download: false
						},
						{
							name: "Address",
							label: "Address",
							title: "Tag Simple Name with a period in the front.",
							type: "text",
							hidden: true,
							download: true
						},
						{
							name: "RespectDataType",
							label: "Respect Data Type",
							title: "Respect the listed data type when using the tag in Kepware.",
							type: "text",
							hidden: true,
							download: true
						},
						{
							name: "ClientAccess",
							label: "Client Access",
							title: "Access that the client will have to this tag on the PLC.",
							type: "text",
							hidden: true,
							download: true
						},
						{
							name: "ScanRate",
							label: "Scan Rate",
							title: "The number of milliseconds that the PLC will scan this tag. Typically set at 1,000 milliseconds.",
							type: "text",
							hidden: true,
							download: true
						},
						{
							name: "JBTStandardObservationId",
							label: "JBT Standard Observation Id",
							title: "The Id number of the JBT Standard Observation record assigned to this tag record.",
							type: "text",
							hidden: true,
							download: true
						}
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Site_TagNameFormatOnly",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Site_TagNameFormatOnly",
					fields: [
						new ReadOnlyQuestion({
							key: "SiteId",
							label: "SiteId",
							title: "The Site Id number of this Asset.",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "TagNameFormat",
							label: "Tag Name Format",
							title: "Enter the format of the Name of the Tags for this Asset. Using '@SiteName|@SystemName|@AssetName|@TagSimpleName' as the initial format.",
							order: 2,
							type: "text",
							value: "@SiteName|@SystemName|@AssetName|@TagSimpleName",
							required: false
						})
					]
				}
			}, // End of PLC Program Revision Possible Tags from Revision
			{
				// United States
				setName: "UnitedStates",
				tableDisplay: {
					tableName: "dbo.StateAbbreviation",
					entityName: "StateAbbreviation",
					entityDisplayName: "State",
					orderByColumns: ["Abbreviation"],
					maxRows: 10,
					searchColumns: ["Abbreviation", "Name"],
					label: "United States of America and Territories",
					allowDelete: false,
					displayColumns: [
						{
							name: "Abbreviation",
							label: "Abbreviation",
							type: "text",
							width: 20,
							widthUnits: "px",
							sortable: true
						},
						{
							name: "Name",
							label: "State Name",
							type: "text",
							width: 20,
							widthUnits: "px",
							sortable: true
						}
					]
				},
				editor: {
					fields: []
				}
			}, // End of United States
			{
				// Organizations
				setName: "Organization",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedOrganizationRecords",
					expandedStoredProcedure: true,
					entityName: "Organization",
					entityDisplayName: "Organization",
					maxRows: 10,
					label: "Organizations",
					allowDelete: false,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Organization.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "UsesGateSystemAssets",
							label: "Uses Gate System Assets",
							type: "boolean",
							hidden: true
						},
						{
							name: "UseOrganizationSuites",
							label: "Use Organization Suites",
							type: "boolean",
							hidden: true
						},
						{
							name: "Mnemonic",
							label: "Mnemonic",
							type: "text",
							title: "Short character designation of this Organization.",
							width: 9,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Organization Name",
							title: "The Name of this Organization.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Organization is active.",
							type: "boolean",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ExpirationDate",
							label: "Expiration Date",
							title: "When the license of this Organization expires.",
							type: "date",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Thresholds",
							label: "Thresholds",
							type: "set",
							setName: "Organization_Threshold",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OrganizationId"
						},
						{
							name: "OrganizationSites",
							label: "Gate System Sites",
							type: "set",
							title: "List of the Organization's Sites.",
							setName: "OrganizationSites",
							width: 8,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OrganizationId",
							showButtonField: "UsesGateSystemAssets"
						},
						{
							name: "OrganizationSuites",
							label: "Suites",
							type: "set",
							title: "List of the Organization's Suites when the organization doesn't have Gate System Assets.",
							setName: "OrganizationSuites",
							width: 8,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OrganizationId",
							showButtonField: "UseOrganizationSuites"
						},
						{
							name: "OrganizationUsers",
							label: "Users",
							type: "set",
							title: "List of the Organization's Users.",
							setName: "OrganizationUsers",
							width: 7,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OrganizationId",
							showButtonField: "UseOrganizationSuites"
						},
						{
							name: "OwnedAssets",
							label: "Owned Assets",
							type: "set",
							setName: "Assets",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OwnerOrganizationId"
						},
						{
							name: "OperatedAssets",
							label: "Operated Assets",
							type: "set",
							setName: "Assets",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "OperatorOrganizationId"
						},
						{
							name: "ManufacturedAssets",
							label: "Manufactured Assets",
							type: "set",
							setName: "Assets",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ManufacturerOrganizationId"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Organization",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Organization",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number representing this Organization.",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "Mnemonic",
							label: "Mnemonic",
							title: "Enter the Name for this Organization.",
							order: 2,
							required: true
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Organization Name",
							title: "Enter the full Name description for this Organization.",
							order: 3,
							required: true
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this Organization.",
							allowBlankValue: true,
							required: false,
							order: 4
						}),
						new TextboxQuestion({
							key: "PrimaryContactPersonId",
							label: "Primary Contact",
							title: "Enter the person who is the Primary Contact for this Organization.",
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true,
							order: 5
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Whether or not this Organization is active.",
							order: 6
						}),
						new DateQuestion({
							key: "ExpirationDate",
							label: "Expiration Date",
							title: "Enter the expiration date of this Organization's license agreement for using iOPS.",
							order: 7
						}),
						new DateQuestion({
							key: "RenewalNoticeDueDate",
							label: "Renewal Notice Due Date",
							title: "Enter the Date of when the iOPS license should be renewed.",
							order: 8
						}),
						new DateQuestion({
							key: "RenewalNoticeSentDate",
							label: "Renewal Notice Sent Date",
							title: "Enter when we sent out the Renewal Notice to the Organization contact.",
							order: 9
						}),
						new SliderYesNoQuestion({
							key: "UsesGateSystemAssets",
							label: "Uses Gate System Assets?",
							title: "Whether or not this Organization uses Gate System assets at an airport site.",
							order: 10
						}),
						new UibButtonMultiSelectQuestion({
							key: "OrganizationSuites",
							label: "Organization Suites",
							title: "Choose the Suites where this Organization has a subscription.",
							order: 11,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationSuites",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{
										name: "@OrganizationId",
										value: "Id"
									}
								]
							},
							visibleFields: [
								{
									field: "UsesGateSystemAssets",
									operator: "==",
									value: false
								} //-- if the current UsesGateSystemAssets field is set to true, then the Suites should be selected at the Organization Site level, not the Organization level. 
							]
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Organization",
					allowAdd: true
				}
			}, // End of Organizations
			{  // Organization_Threshold
				setName: "Organization_Threshold",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Organization_Thresholds",
					expandedStoredProcedure: true,
					tableName: "dbo.Organization_Threshold",
					entityName: "Organization_Threshold",
					entityDisplayName: "Organization Threshold",
					label: "Organization Threshold",
					allowDelete: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordOutsideRecursedSet: true,
					deleteStoredProcedure: "API.RDN_Delete_Organization_Threshold",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id of the Organization Threshold record.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Threshold",
							title: "Name of the Organization Threshold.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of the Organization Threshold.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ThresholdValue",
							label: "Value Exceeding Normal",
							title: "Value or Amount OVER the normal observation value or amount to reach this Threshold.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "UnitOfMeasure",
							label: "Unit of Measure",
							title: "The Unit of Measure for the Threshold Value.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "UnitId",
							label: "UnitId",
							title: "The Unit of Measure Id.",
							type: "text",
							hidden: true
						},
						// {
						// 	name: "ThresholdInSeconds",
						// 	label: "Seconds to reach Threshold",
						// 	title: "Number of seconds until Threshold is reached.",
						// 	type: "text",
						// 	width: 5,
						// 	widthUnits: "%"
						// },
						{
							name: "Cost",
							label: "Cost",
							title: "The Cost for the organiztion for this Threshold to be breached.",
							type: "set",
							setName: "Organization_ThresholdCost",
							width: 12,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "Organization_ThresholdId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Organization Threshold record.",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Organization Threshold record.",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this Organization Threshold record was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this Organization Threshold record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "OrganizationThresholdTypeId",
							label: "OrganizationThresholdTypeId",
							title: "Id of the Organization Threshold Type record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new UibButtonQuestion({
							key: "OrganizationThresholdTypeId",
							label: "Threshold Type",
							title: "Choose the type of Threshold associated with this Organization Threshold record.",
							order: 1,
							required: true,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationThresholdTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [{ name: "@OrganizationThresholdTypeId", value: "OrganizationThresholdTypeId" }]
							}
						}),
						new TextboxQuestion({
							key: "ThresholdValue",
							label: "Threshold Value",
							title: "Enter the Value or Amount OVER the normal observation value or amount to reach this Threshold.",
							order: 2,
							type: "text",
							required: true
						}),
						new UibButtonQuestion({
							key: "UnitId",
							label: "Unit of Measure",
							title: "Please select the appropriate Unit of Measure for this Threshold's Value field.",
							listOfValues: this.dataService.cache.units,
							order: 3,
							required: true,
						}),
						// new NumberQuestion({
						// 	key: "ThresholdInSeconds",
						// 	title: "Enter the number of seconds the asset is consistently exceeding the Threshold Value or Amount until this Threshold is reached.",
						// 	label: "Threshold in Seconds",
						// 	required: true,
						// 	order: 4
						// }),
						new ReadOnlyQuestion({
							key: "OrganizationId",
							title: "The Organization assigned to this Organization Threshold.",
							label: "Organization",
							order: 5,
							type: "parent-key-field",
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 6,
							visible: false
						})
					]
				}
			}, // End of Organization_Threshold
			{  // Organization_ThresholdCost
				setName: "Organization_ThresholdCost",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Organization_ThresholdCost",
					expandedStoredProcedure: true,
					tableName: "dbo.Organization_ThresholdCost",
					entityName: "Organization_ThresholdCost",
					entityDisplayName: "Organization Threshold Cost",
					label: "Organization Threshold Cost",
					allowDelete: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "Id of the Organization Threshold Cost record.",
							type: "text",
							hidden: true
						},
						{
							name: "CostPerSecond",
							label: "Cost Per Second",
							title: "The Cost Per Second for this asset to breach the Threshold limit.",
							type: "money",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Organization Threshold Cost record.",
							type: "creator-user",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Organization Threshold Cost record.",
							type: "last-modified-user",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this Organization Threshold Cost was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this Organization Threshold Cost record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "Organization_ThresholdId",
							label: "Organization_ThresholdId",
							title: "Threshold Id number",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new NumberQuestion({
							key: "CostPerSecond",
							title: "Enter the Cost Per Second for this Organization when this Threshold has been breached by the asset.",
							label: "Cost Per Second",
							required: true,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Organization_ThresholdId",
							title: "The Organization Threshold Id for the parent record.",
							label: "Organization Threshold",
							order: 3,
							type: "parent-key-field",
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Organization_ThresholdCost
			{
				// System Assets - these are the Assets that are being displayed with a ParentSystemId that points to the System table record.
				setName: "Assets",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetRecords",
					expandedStoredProcedure: true,
					entityName: "Asset",
					entityDisplayName: "Asset",
					maxRows: 10,
					label: "Assets",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Asset.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Site",
							label: "Site",
							title: "Site location of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Gate",
							label: "Gate or System",
							title: "Gate or System of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Asset Name",
							title: "Name of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PreferredName",
							label: "Preferred Name",
							title: "The Preferred Name of this Asset, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetType",
							label: "Asset Type",
							title: "The type of Asset.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Model",
							label: "Model",
							title: "The Model of this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "ModemNumber",
							label: "Modem Number",
							title: "The modem number for this asset, if applicable.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PLCProgramRevision",
							label: "PLC Program",
							title: "PLC Program / Revision being used by this Asset.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Asset is active.",
							type: "boolean",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Tags",
							label: "Tags",
							title: "The list of Tags for this Asset.",
							type: "set",
							setName: "Tag",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetId"
						},
						{
							name: "OwnerOrganization",
							label: "Owner Organization",
							title: "The Organization that owns this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "OperatorOrganization",
							label: "Operator Organization",
							title: "The Organization that operates (uses) this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "ManufacturerOrganization",
							label: "Manufacturer Organization",
							title: "The Organization that manufactured this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "AdveezAssetId",
							label: "Adveez Asset Id",
							title: "The Adveez Asset Id of this asset, if applicable.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Asset",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Asset",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Asset.",
							type: "primary-key-field",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "AssetTypeGroupId",
							label: "Asset Type Group",
							title: "The type of group where this Asset Type belongs.",
							visible: false,
							order: 2,
							required: false
						}),
						new TextboxQuestion({
							key: "AssetName",
							label: "Name",
							title: "Enter the name of this Asset.",
							order: 3,
							type: "asset",
							required: true
						}),
						new TextboxQuestion({
							key: "TagNamePrefix",
							label: "Tag Name Prefix",
							title: "Enter the prefix of each of the tags for this Asset as they are appearing in the database. This field value is critical for the setup of the correct SignalR messages as well as the widgets.",
							order: 4,
							required: true,
							visible: this.currentUser?.Rockstar
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the appropriate Type for this Asset.",
							order: 5,
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							required: true
						}),
						new TextboxQuestion({
							key: "AssetModelId",
							label: "Asset Model",
							title: "Select the appropriate Model for this Asset.",
							order: 6,
							type: "generic-selector",
							setName: "AssetModel",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "PreferredName",
							label: "Preferred Name",
							title: "Enter the preferred Name of the asset, if applicable. Please do not change from the original Asset Name value, unless it's appropriate.",
							order: 7,
							type: "text",
							required: false,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "!=",
									value: 1
								} //-- if this Asset is a gate system asset, then don't show this field.
							],
						}),
						new TextboxQuestion({
							key: "ModemNumber",
							label: "Modem Number",
							title: "Enter the Modem Number of this Asset, if applicable.",
							order: 8,
							type: "text",
							required: false,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							]
						}),
						new TextareaFancyQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this asset.",
							order: 9,
							required: false
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Select the appropriate Site where this Asset is located.",
							order: 10,
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							allowBlankValue: true,
							required: true,
							
						}),
						new SliderYesNoQuestion({
							key: "HasElevatorRotunda",
							label: "Has Elevating Rotunda?",
							title: "Does this asset have an Elevating Rotunda?",
							order: 12,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							required: false
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active?",
							title: "Should this Asset be considered Active?",
							order: 13,
							required: false
						}),
						new DateQuestion({
							key: "RetireDate",
							label: "Date of Inactivation",
							title: "The Date this asset was set to Inactive.",
							visibleFields: [
								{
									field: "Active",
									operator: "==",
									value: false
								} //-- if this Asset was set to Active = false.
							],
							required: false,
							order: 14
						}),
						new DateQuestion({
							key: "InServiceDate",
							label: "In Service Date",
							title: "The Date this asset placed in service.",
							order: 15,
							required: false
						}),
						new TextboxQuestion({
							key: "OwnerOrganizationId",
							label: "Owner Organization",
							title: "Select the appropriate Organization that Owns this Asset.",
							order: 16,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "OperatorOrganizationId",
							label: "Operator Organization",
							title: "Select the appropriate Organization that Operates (or uses) this Asset.",
							order: 17,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "ManufacturerOrganizationId",
							label: "Manufacturer Organization",
							title: "Select the appropriate Organization that Manufactured this Asset.",
							order: 18,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "MaintenanceOrganizationId",
							label: "Maintenance Organization",
							title: "Select the appropriate Organization that will maintain this Asset.",
							order: 19,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "PLCProgramRevisionId",
							title: "Select the appropriate PLC (Programmable Logic Controller) Software Program Revision that communicates with this Asset.",
							label: "PLC Program Revision",
							order: 20,
							type: "generic-selector",
							setName: "PLCProgramRevisions",
							validateObject: false,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "AdveezAssetId",
							title: "The Id number of the Adveez Asset record, if applicable.",
							label: "Adveez Asset Name",
							order: 21,
							type: "generic-selector",
							setName: "AdveezAsset",
							validateObject: true,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							],
							required: false
						}),
						new SliderYesNoQuestion({
							key: "GenerateDockedTagFromAutolevelDeployedTag",
							label: "Generate Docked Tag from Autolevel Deployed Tag?",
							title: "Will this asset only use Autolevel Deployed status for generating an Aircraft Docked tag?",
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							order: 22,
							required: false
						}),
						new UibButtonQuestion({
							key: "FleetThingId",
							label: "Fleet",
							title: "Choose the appropriate Fleet for this asset.",
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_FleetThings",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@FleetThingId",
										value: "FleetThingId"
									}
								]
							},
							type: "fleet",
							order: 23,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "==",
									value: 3
								} //-- if the current AssetTypeGroupId is a fire or refuse truck, then show this field.
							],
							required: false
						}),
						new ReadOnlyQuestion({
							key: "ParentSystemId",
							label: "System",
							type: "parent-key-field",
							title: "The System where this is referenced.",
							listOfValues: this.dataService.cache.systems,
							order: 24,
							visible: false,
							required: false
						}),
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Asset",
					allowAdd: true
				}
			}, // End of System Assets
			{
				// Standalone Assets - these are the Assets that will have  the ParentSystemId as a selector rather than a parent value being sent to a child collection. --Kirk T. Sherer, September 29, 2021.
				setName: "StandaloneAssets",
				tableDisplay: {
					// dataCacheCollection: this.dataService.cache.assets,
					sqlStoredProcedure: "API.RDN_GetExpandedAssetRecords",
					expandedStoredProcedure: true,
					entityName: "Asset",
					entityDisplayName: "Asset",
					maxRows: 10,
					label: "Assets",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					orderBy: {
						column: "Name",
						sortOrder: "asc"
					},
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Asset.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Site",
							label: "Site",
							title: "Site location of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ParentSystem",
							label: "Gate or System",
							title: "Gate or System of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},

						{
							name: "Name",
							label: "Asset Name",
							title: "Name of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PreferredName",
							label: "Preferred Name",
							title: "The Preferred Name of this Asset, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetType",
							label: "Asset Type",
							title: "The type of Asset.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Asset is active.",
							type: "boolean",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Model",
							label: "Model",
							title: "The Model of this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "ModemNumber",
							label: "Modem Number",
							title: "The modem number for this asset, if applicable.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PLCProgramRevision",
							label: "PLC Program",
							title: "PLC Program / Revision being used by this Asset.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Tags",
							label: "Tags",
							title: "The list of Tags for this Asset.",
							type: "set",
							setName: "Tag",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetId"
						},
						{
							name: "OwnerOrganization",
							label: "Owner Organization",
							title: "The Organization that owns this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "OperatorOrganization",
							label: "Operator Organization",
							title: "The Organization that operates (uses) this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "ManufacturerOrganization",
							label: "Manufacturer Organization",
							title: "The Organization that manufactured this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "AdveezAssetId",
							label: "Adveez Asset Id",
							title: "The Adveez Asset Id of this asset, if applicable.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Asset",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Asset",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Asset.",
							type: "primary-key-field",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "AssetTypeGroupId",
							label: "Asset Type Group",
							title: "The type of group where this Asset Type belongs.",
							visible: false,
							order: 2,
							required: false
						}),
						new TextboxQuestion({
							key: "AssetName",
							label: "Name",
							title: "Enter the name of this Asset.",
							order: 3,
							type: "asset",
							required: true
						}),
						new TextboxQuestion({
							key: "TagNamePrefix",
							label: "Tag Name Prefix",
							title: "Enter the prefix of each of the tags for this Asset as they are appearing in the database. This field value is critical for the setup of the correct SignalR messages as well as the widgets.",
							order: 4,
							required: true,
							visible: this.currentUser?.Rockstar
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the appropriate Type for this Asset.",
							order: 5,
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							required: true
						}),
						new TextboxQuestion({
							key: "AssetModelId",
							label: "Asset Model",
							title: "Select the appropriate Model for this Asset.",
							order: 6,
							type: "generic-selector",
							setName: "AssetModel",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "PreferredName",
							label: "Preferred Name",
							title: "Enter the preferred Name of the asset, if applicable. Please do not change from the original Asset Name value, unless it's appropriate.",
							order: 7,
							type: "text",
							required: false,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "!=",
									value: 1
								} //-- if this Asset is a gate system asset, then don't show this field.
							],
						}),
						new TextboxQuestion({
							key: "ModemNumber",
							label: "Modem Number",
							title: "Enter the Modem Number of this Asset, if applicable.",
							order: 8,
							type: "text",
							required: false,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							]
						}),
						new TextareaFancyQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this asset.",
							order: 9,
							required: false
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Select the appropriate Site where this Asset is located.",
							order: 10,
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							allowBlankValue: true,
							required: true,
							
						}),
						new TextboxQuestion({
							key: "ParentSystemId",
							label: "System",
							type: "generic-selector",
							title: "The System where this is referenced.",
							setName: "System",
							validateObject: true,
							allowBlankValue: false,
							required: true,
							order: 11
						}),
						new SliderYesNoQuestion({
							key: "HasElevatorRotunda",
							label: "Has Elevating Rotunda?",
							title: "Does this asset have an Elevating Rotunda?",
							order: 12,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							required: false
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active?",
							title: "Should this Asset be considered Active?",
							order: 13,
							required: false
						}),
						new DateQuestion({
							key: "RetireDate",
							label: "Date of Inactivation",
							title: "The Date this asset was set to Inactive.",
							visibleFields: [
								{
									field: "Active",
									operator: "==",
									value: false
								} //-- if this Asset was set to Active = false.
							],
							required: false,
							order: 14
						}),
						new DateQuestion({
							key: "InServiceDate",
							label: "In Service Date",
							title: "The Date this asset placed in service.",
							order: 15,
							required: false
						}),
						new TextboxQuestion({
							key: "OwnerOrganizationId",
							label: "Owner Organization",
							title: "Select the appropriate Organization that Owns this Asset.",
							order: 16,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "OperatorOrganizationId",
							label: "Operator Organization",
							title: "Select the appropriate Organization that Operates (or uses) this Asset.",
							order: 17,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "ManufacturerOrganizationId",
							label: "Manufacturer Organization",
							title: "Select the appropriate Organization that Manufactured this Asset.",
							order: 18,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "MaintenanceOrganizationId",
							label: "Maintenance Organization",
							title: "Select the appropriate Organization that will maintain this Asset.",
							order: 19,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "PLCProgramRevisionId",
							title: "Select the appropriate PLC (Programmable Logic Controller) Software Program Revision that communicates with this Asset.",
							label: "PLC Program Revision",
							order: 20,
							type: "generic-selector",
							setName: "PLCProgramRevisions",
							validateObject: false,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "AdveezAssetId",
							title: "The Id number of the Adveez Asset record, if applicable.",
							label: "Adveez Asset Name",
							order: 21,
							type: "generic-selector",
							setName: "AdveezAsset",
							validateObject: true,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							],
							required: false
						}),
						new SliderYesNoQuestion({
							key: "GenerateDockedTagFromAutolevelDeployedTag",
							label: "Generate Docked Tag from Autolevel Deployed Tag?",
							title: "Will this asset only use Autolevel Deployed status for generating an Aircraft Docked tag?",
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							order: 22,
							required: false
						}),
						new UibButtonQuestion({
							key: "FleetThingId",
							label: "Fleet",
							title: "Choose the appropriate Fleet for this asset.",
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_FleetThings",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@FleetThingId",
										value: "FleetThingId"
									}
								]
							},
							type: "fleet",
							order: 23,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "==",
									value: 3
								} //-- if the current AssetTypeGroupId is a fire or refuse truck, then show this field.
							],
							required: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Asset",
					allowAdd: true
				}
			}, // End of Assets
			{
				// Asset Types
				setName: "AssetType",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetTypeRecords",
					expandedStoredProcedure: true,
					entityName: "AssetType",
					entityDisplayName: "Asset Type",
					maxRows: 40,
					label: "Asset Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Asset Type.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Asset Type Name",
							title: "Name of this Asset Type.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of this Asset Type.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetTypeGroupName",
							label: "Grouping",
							title: "The Group association of this Asset Type.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetTypeGroupId",
							label: "Asset Type Group Id",
							title: "The Group association Id number of this Asset Type.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 19,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Asset Type.",
							order: 1,
							type: "primary-key-field"
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Asset Type.",
							order: 2,
							type: "text",
							required: true
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Asset Type.",
							order: 3,
							type: "text"
						}),
						new UibButtonQuestion({
							key: "AssetTypeGroupId",
							label: "Grouping",
							title: "Enter the Group where this Asset Type should reside.",
							listOfValues: this.dataService.cache.assetTypeGroups,
							order: 4,
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AssetType",
					allowAdd: true
				}
			}, // End of Asset Types
			{  // Asset Type Groups
				setName: "AssetTypeGroup",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetTypeGroupRecords",
					expandedStoredProcedure: true,
					entityName: "AssetTypeGroup",
					entityDisplayName: "Asset Type Group",
					label: "Asset Type Groups",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Asset Type Group.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "Name of this Asset Type Group.",
							type: "text",
							width: 17,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of this Asset Type Group.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetTypes",
							label: "Asset Types",
							title: "Asset Types assigned to this Asset Type Group.",
							type: "set",
							setName: "AssetType",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetTypeGroupId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Asset Type Group.",
							order: 1,
							type: "primary-key-field"
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Asset Type Group.",
							order: 2,
							type: "text",
							required: true
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Asset Type Group.",
							order: 3,
							type: "text"
						})
					]
				},
			}, // End of Asset Type Groups
			{
				// Person
				setName: "Person",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedPersonRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "People",
					entityName: "Person",
					entityDisplayName: "Person",
					allowDelete: false,
					allowModification: Global.User.isAdmin,
					disableAddRecordInsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Person record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Person.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Organization",
							label: "Organization",
							title: "The Organization of this person.",
							type: "set",
							setName: "Organization",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "OrganizationId",
							childColumnLink: "Id"
						},
						{
							name: "Email",
							label: "Email Address",
							title: "The Email Address of this person.",
							type: "email",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "AssociatedUsers",
							label: "Associated User Accounts",
							title: "The list of Associated User Accounts for this person.",
							type: "set",
							setName: "AssociatedUsers",
							width: 15,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PersonId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Person",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Person",
					fields: [
						new TextboxQuestion({
							key: "Email",
							label: "Email Address",
							title: "The email address of this person. This must be an accurate email address since the user will receive notifications about their iOPS account through this email account.",
							order: 1,
							type: "person-email",
							required: true,
							validateObject: false
						}),
						new TextboxQuestion({
							key: "GivenName",
							label: "First Name",
							title: "The First (or given) Name of this person.",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "MiddleName",
							label: "Middle Name",
							title: "The Middle Name of this person.",
							required: false,
							order: 3
						}),
						new TextboxQuestion({
							key: "FamilyName",
							label: "Last Name",
							title: "The Last (or family) Name of this person.",
							required: true,
							order: 4
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Organization",
							title: "The Organization this person works or resides.  This should be the organization where this user will work in terms of the iOPS application.",
							order: 5,
							type: "generic-selector",
							setName: "Organization",
							required: true,
							validateObject: true
						}),
						new TextboxQuestion({
							key: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person. This phone number must be accurate for this person for two-factor authentication if the person will be using their cell phone for communication.",
							order: 6,
							type: "phone-number",
							required: false
						}),
						new TextboxQuestion({
							key: "CellPhoneCarrierId",
							label: "Cell Carrier",
							title: "Please select the correct Cell Carrier for the cell phone. This is needed so texting can happen from the iOPS application to the mobile phone, where applicable.",
							order: 7,
							type: "generic-selector",
							setName: "CellPhoneCarrier",
							required: false,
							visibleFields: [
								{
									field: "MobilePhone",
									operator: "!=",
									value: ""
								}
							]
						}),
						new TextboxQuestion({
							key: "ReportsToPersonId",
							label: "Reports to Person",
							title: "The Supervisor or Manager of this Person. This person would be notified when escalating issues to the next level.",
							order: 8,
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "AlternateNotificationEmailPersonId",
							label: "Alternate Person",
							title: "Another Person to notify when sending out email or texts.",
							order: 9,
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "OutOfOfficeNotificationEmailPersonId",
							label: "Out of Office Person",
							title: "Another Person that will need to be notified when this person is out of the office.",
							order: 10,
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "OutOfOffice",
							label: "Out of Office",
							title: "Select whether or not this person is out of office.",
							order: 11
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 12,
							visible: Global.User.isAdmin
						}),
						new TextboxQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Person record.",
							order: 13,
							type: "primary-key-field",
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Person",
					allowAdd: false
				}
			}, // End of Person
			{
				// PersonBySingleOrganization - for Site Admins and non-JBT Organizational Admins
				setName: "PersonBySingleOrganization",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedPersonRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "People",
					entityName: "Person",
					entityDisplayName: "Person",
					allowDelete: false,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordInsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Person record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Person.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Organization",
							label: "Organization",
							title: "The Organization of this person.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "OrganizationId",
							label: "Organization Id",
							title: "The Organization Id of this person.",
							type: "text",
							hidden: true
						},
						{
							name: "Email",
							label: "Email Address",
							title: "The Email Address of this person.",
							type: "email",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "AssociatedUsers",
							label: "Associated User Accounts",
							title: "The list of Associated User Accounts for this person.",
							type: "set",
							setName: "AssociatedUsersBySingleOrganization",
							width: 15,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PersonId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Person",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Person",
					fields: [
						new TextboxQuestion({
							key: "Email",
							label: "Email Address",
							title: "The email address of this person. This must be an accurate email address since the user will receive notifications about their iOPS account through this email account.",
							order: 1,
							type: "person-email",
							required: true,
							validateObject: false
						}),
						new TextboxQuestion({
							key: "GivenName",
							label: "First Name",
							title: "The First (or given) Name of this person.",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "MiddleName",
							label: "Middle Name",
							title: "The Middle Name of this person.",
							required: false,
							order: 3
						}),
						new TextboxQuestion({
							key: "FamilyName",
							label: "Last Name",
							title: "The Last (or family) Name of this person.",
							required: true,
							order: 4
						}),
						new TextboxQuestion({
							key: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person. This phone number must be accurate for this person for two-factor authentication if the person will be using their cell phone for communication.",
							order: 6,
							type: "phone-number",
							required: false
						}),
						new TextboxQuestion({
							key: "CellPhoneCarrierId",
							label: "Cell Carrier",
							title: "Please select the correct Cell Carrier for the cell phone. This is needed so texting can happen from the iOPS application to the mobile phone, where applicable.",
							order: 7,
							type: "generic-selector",
							setName: "CellPhoneCarrier",
							required: false,
							visibleFields: [
								{
									field: "MobilePhone",
									operator: "!=",
									value: ""
								}
							]
						}),
						new TextboxQuestion({
							key: "ReportsToPersonId",
							label: "Reports to Person",
							title: "The Supervisor or Manager of this Person. This person would be notified when escalating issues to the next level.",
							order: 8,
							type: "generic-selector",
							setName: "PersonBySingleOrganization",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "AlternateNotificationEmailPersonId",
							label: "Alternate Person",
							title: "Another Person to notify when sending out email or texts.",
							order: 9,
							type: "generic-selector",
							setName: "PersonBySingleOrganization",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "OutOfOfficeNotificationEmailPersonId",
							label: "Out of Office Person",
							title: "Another Person that will need to be notified when this person is out of the office.",
							order: 10,
							type: "generic-selector",
							setName: "PersonBySingleOrganization",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "OutOfOffice",
							label: "Out of Office",
							title: "Select whether or not this person is out of office.",
							order: 11
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 12,
							visible: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Organization Id",
							title: "The Organization Id number of this Person's organization where they work.",
							type: "parent-key-field",
							order: 13,
							visible: false
						}),
						new TextboxQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Person record.",
							order: 14,
							type: "primary-key-field",
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Person_with_Username",
					allowAdd: false
				}
			}, // End of PersonBySingleOrganization
			{
				// Person with Username
				setName: "PersonWithUsername",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedPersonRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "People",
					entityName: "Person",
					entityDisplayName: "Person",
					allowDelete: false,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					disableAddRecordInsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Person record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Person.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Organization",
							label: "Organization",
							title: "The Organization of this person.",
							type: "set",
							setName: "Organization",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "OrganizationId",
							childColumnLink: "Id"
						},
						{
							name: "Email",
							label: "Email Address",
							title: "The Email Address of this person.",
							type: "email",
							width: 17,
							widthUnits: "%"
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "AssociatedUsers",
							label: "Associated User Accounts",
							title: "The list of Associated User Accounts for this person.",
							type: "set",
							setName: "AssociatedUsers",
							width: 15,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "PersonId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this Person record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this Person record.",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							title: "The Creation Date of this person record.",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The Last Modified Date of this person record.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Person",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Person",
					fields: [
						new TextboxQuestion({
							key: "Email",
							label: "Email Address",
							title: "The email address of this person. This must be an accurate email address since the user will receive notifications about their iOPS account through this email account.",
							order: 1,
							type: "person-email",
							required: true,
							validateObject: false
						}),
						new TextboxQuestion({
							key: "GivenName",
							label: "First Name",
							title: "The First (or given) Name of this person.",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "MiddleName",
							label: "Middle Name",
							title: "The Middle Name of this person.",
							required: false,
							order: 3
						}),
						new TextboxQuestion({
							key: "FamilyName",
							label: "Last Name",
							title: "The Last (or family) Name of this person.",
							required: true,
							order: 4
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Organization",
							title: "The Organization this person works or resides.  This should be the organization where this user will work in terms of the iOPS application.",
							order: 5,
							type: "generic-selector",
							setName: "Organization",
							required: true,
							validateObject: true
						}),
						new TextboxQuestion({
							key: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person. This phone number must be accurate for this person for two-factor authentication if the person will be using their cell phone for communication.",
							order: 6,
							type: "phone-number",
							required: false
						}),
						new TextboxQuestion({
							key: "CellPhoneCarrierId",
							label: "Cell Carrier",
							title: "Please select the correct Cell Carrier for the cell phone. This is needed so texting can happen from the iOPS application to the mobile phone, where applicable.",
							order: 7,
							type: "generic-selector",
							setName: "CellPhoneCarrier",
							required: false,
							visibleFields: [
								{
									field: "MobilePhone",
									operator: "!=",
									value: ""
								}
							]
						}),
						new TextboxQuestion({
							key: "ReportsToPersonId",
							label: "Reports to Person",
							title: "The Supervisor or Manager of this Person. This person would be notified when escalating issues to the next level.",
							order: 8,
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "AlternateNotificationEmailPersonId",
							label: "Alternate Person",
							title: "Another Person to notify when sending out email or texts.",
							order: 9,
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "OutOfOfficeNotificationEmailPersonId",
							label: "Out of Office Person",
							title: "Another Person that will need to be notified when this person is out of the office.",
							order: 10,
							type: "generic-selector",
							setName: "Person",
							required: false,
							validateObject: true,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "OutOfOffice",
							label: "Out of Office",
							title: "Select whether or not this person is out of office.",
							order: 11
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 12,
							visible: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin
						}),
						new TextboxQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Person record.",
							order: 13,
							type: "primary-key-field",
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_Person_with_Username",
					allowAdd: false
				}
			}, //--Person with Username
			{
				// RequestedPerson - When users request an account via the login screen.
				setName: "RequestedPerson",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetRequestedPersonRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Requested Accounts",
					entityName: "RequestedPerson",
					entityDisplayName: "Requested People",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordInsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The ID number of this Requested Person record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Requested Person.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ClaimedOrganization",
							label: "Organization",
							title: "The Organization of this Requested Person, based on their data entry.  This may or may not match an organization in our system.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "EmailAddress",
							label: "Email Address",
							title: "The Email Address of this Requested Person.",
							type: "email",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "DateRequested",
							label: "Date Requested",
							title: "The Date this person requested an account to be created.",
							type: "date",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "NewlyCreatedPersonId",
							label: "Person Id",
							title: "The Person Id of the created Requested Person account.",
							type: "text",
							hidden: true
						},
						{
							name: "PersonRecordHasBeenCreated",
							label: "PersonRecordHasBeenCreated",
							title: "The Person record has already been created for this Requested Person account.",
							type: "boolean",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "Person",
							label: "Person Record",
							title: "The Person record who owns this user account.",
							setName: "Person",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "NewlyCreatedPersonId",
							childColumnLink: "Id",
							showButtonField: "PersonRecordHasBeenCreated"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_RequestedPerson",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_RequestedPerson",
					fields: [
						new TextboxQuestion({
							key: "EmailAddress",
							label: "Email Address",
							title: "The email address of this Requested Person record.  This is what the user typed in, so we probably should accept this as the correct email address for this user.",
							order: 1,
							type: "person-email",
							required: true,
							validateObject: false
						}),
						new TextboxQuestion({
							key: "FirstName",
							label: "First Name",
							title: "The First Name of this Requested Person, as entered by this person when requesting an account.",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "LastName",
							label: "Last Name",
							title: "The Last Name of this Requested Person, as entered by this person when requesting an account.",
							required: true,
							order: 3
						}),
						new TextboxQuestion({
							key: "MobilePhone",
							label: "Cell Phone Number",
							title: "The Cell Phone Number of this person. This is what the user typed in, so we probably should accept this as the correct mobile phone for this user.",
							order: 4,
							type: "phone-number",
							required: false
						}),
						new ReadOnlyQuestion({
							key: "ClaimedOrganization",
							label: "Claimed Organization",
							title: "The Claimed Organization that this Requested Person typed in when requesting an account.  It may not match any of our current organizations.",
							type: "text",
							order: 5,
							visible: true
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Actual Organization",
							title: "The Organization this person works or resides.  This should be the organization where this user will work in terms of the iOPS application.",
							order: 6,
							type: "generic-selector",
							setName: "Organization",
							required: true,
							validateObject: true
						}),
						new TextboxQuestion({
							key: "NewlyCreatedPersonId",
							label: "Actual Person Id",
							title: "The actual Person record that was created for this request, if applicable.",
							type: "parent-key-field",
							order: 7,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Person",
							label: "Actual Person",
							title: "The actual Person record that was created for this request, if applicable.",
							order: 8,
							type: "text",
							visibleFields: [
								{
									field: "NewlyCreatedPersonId",
									operator: "!=",
									value: null
								}, // AND
								{
									field: "NewlyCreatedPersonId",
									operator: "!=",
									value: ""
								}
							],

						}),
						new TextboxQuestion({
							key: "Id",
							label: "Id",
							title: "The Id number of this Requested Person record.",
							order: 9,
							type: "primary-key-field",
							visible: false
						})
					]
				}
			}, // End of RequestedPerson
			{
				// AssociatedUsersBySingleOrganization
				setName: "AssociatedUsersBySingleOrganization",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedUserRecords",
					expandedStoredProcedure: true,
					label: "Users",
					entityName: "Security.iOPSUser",
					entityDisplayName: "User",
					maxRows: 30,
					allowDelete: false,
					allowModification: Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this user.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this user account.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Person",
							label: "Person",
							title: "The Person who is responsible for this user account.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Admin",
							label: "Admin Level",
							type: "text",
							title: "Is this user a system administrator or organization administrator?",
							width: 10,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "PersonRecord",
							label: "Person Record",
							title: "The Person record who owns this user account.",
							setName: "PersonBySingleOrganization",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "PersonId",
							childColumnLink: "Id"
						},
						{
							name: "AuthorizedSites",
							label: "Authorized Sites",
							title: "The authorized Sites for this user.",
							setName: "UserAuthorizedSites",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "UserId"
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							title: "Whether or not this user account is Active.",
							width: 5,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "SendChangePasswordToken",
							label: "Send Change Password Token",
							title: "Send the user a link via email with a token for changing their password.",
							type: "sendChangePasswordToken",
							field: "Username",
							width: 15,
							widthUnits: "%"
						},
						Global.User.isAdmin || Global.User.isOrgAdmin || Global.User.isSiteAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Security_iOPSUser",
					editStoredProcedureName: "API.RDN_EditRecord_Security_iOPSUser",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "User Id for this user account.",
							type: "primary-key-field",
							order: 1,
							visible: Global.User.isAdmin
						}),
						new TextboxQuestion({
							key: "RequestedUsername",
							label: "Username",
							title: "Enter the username for this person.",
							order: 2,
							type: "username",
							required: true
						}),
						new SliderYesNoQuestion({
							key: "IsSystemAdministrator",
							label: "Is System Administrator",
							title: "Will this user be a system administrator?",
							order: 3,
							visible: Global.User.isAdmin && !Global.User.isOrgAdmin && !Global.User.isSiteAdmin  //-- only system administrators should be able to set users to System Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "IsOrganizationAdministrator",
							label: "Is Organization Administrator",
							title: "Will this user be an organization administrator?",
							order: 6,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								}, //-- if we have a Username value AND...
								{ field: "PersonId", operator: ">", value: 0 }, //-- if we have a PersonId selected AND...
								{
									field: "IsSystemAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not a system administrator, then show the Organization Administrator field. --Kirk T. Sherer, August 17, 2021.
							],
							visible: Global.User.isAdmin || Global.User.isOrgAdmin //-- only System Administrators and Organization Administrators should have access to set users to Organization Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new UibButtonMultiSelectQuestion({
							key: "ListOfAuthorizedSites",
							label: "List of Authorized Sites",
							title: "Choose the Sites that this user is authorized to access.",
							order: 7,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								}, //-- if we have a Username value AND...
								{
									field: "IsSystemAdministrator",
									operator: "==",
									value: false
								}, //-- if the user is not a system administrator AND...
								{
									field: "IsOrganizationAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not an organization administrator, then show the List of Authorized Sites. --Kirk T. Sherer, August 17, 2021.
							],
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationSites",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{ name: "@UserId", value: "Id" },
									{ name: "@PersonId", value: "PersonId" },
									{ name: "@AdminUserId", value: this.currentUser?.Id }
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "AdminUserId",
							label: "Admin User Id",
							title: "The Id number of the Administrator editing this record.",
							order: 8,
							value: this.currentUser?.Id,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "PersonId",
							label: "Person",
							type: "parent-key-field",
							title: "The Person where this user is referenced.",
							listOfValues: this.dataService.cache.people,
							order: 9,
							visible: false
						})
					]
				}
			}, //-- End of AssociatedUsersBySingleOrganization
			{
				// Associated Users
				setName: "AssociatedUsers",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedUserRecords",
					expandedStoredProcedure: true,
					label: "Users",
					entityName: "Security.iOPSUser",
					entityDisplayName: "User",
					maxRows: 30,
					allowDelete: false,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "The ID number of this user.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Username",
							label: "Username",
							title: "The Username of this user account.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Person",
							label: "Person",
							title: "The Person who is responsible for this user account.",
							type: "text",
							width: Global.User.isAdmin ? 17 : 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Admin",
							label: "Admin Level",
							type: "text",
							title: "Is this user a system administrator or organization administrator?",
							width: 10,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "PersonRecord",
							label: "Person Record",
							title: "The Person record who owns this user account.",
							setName: "Person",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "PersonId",
							childColumnLink: "Id"
						},
						{
							name: "AuthorizedSites",
							label: "Authorized Sites",
							title: "The authorized Sites for this user.",
							setName: "UserAuthorizedSites",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "UserId"
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							title: "Whether or not this user account is Active.",
							width: 5,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "CanEditRecord",
							label: "Can Edit Record",
							type: "boolean",
							title: "Whether or not the user accessing this account record has the ability to edit the record.",
							hidden: true
						},
						{
							name: "SendChangePasswordToken",
							label: "Send Change Password Token",
							title: "Send the user a link via email with a token for changing their password.",
							type: "sendChangePasswordToken",
							field: "Username",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "LogInAs",
							label: "Log In As",
							title: "Log in as this user. Clicking this button will allow you to log out and log you back in as this user.",
							type: "logInAs",
							field: "Username",
							width: 5,
							widthUnits: "%",
							role: "SystemAdministrator"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Security_iOPSUser",
					editStoredProcedureName: "API.RDN_EditRecord_Security_iOPSUser",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "User Id for this user account.",
							type: "primary-key-field",
							order: 1,
							visible: Global.User.isAdmin
						}),
						new TextboxQuestion({
							key: "RequestedUsername",
							label: "Username",
							title: "Enter the username for this person.",
							order: 2,
							type: "username",
							required: true
						}),
						new SliderYesNoQuestion({
							key: "IsSystemAdministrator",
							label: "Is System Administrator",
							title: "Will this user be a system administrator?",
							order: 3,
							visible: Global.User.isAdmin //-- only system administrators should be able to set users to System Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this user should be active.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "IsOrganizationAdministrator",
							label: "Is Organization Administrator",
							title: "Will this user be an organization administrator?",
							order: 6,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								}, //-- if we have a Username value AND...
								{ field: "PersonId", operator: ">", value: 0 }, //-- if we have a PersonId selected AND...
								{
									field: "IsSystemAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not a system administrator, then show the Organization Administrator field. --Kirk T. Sherer, August 17, 2021.
							],
							visible: Global.User.isAdmin //-- only System Administrators should have access to set users to Organization Administrator access. --Kirk T. Sherer, November 19, 2021.
						}),
						new UibButtonMultiSelectQuestion({
							key: "ListOfAuthorizedSites",
							label: "List of Authorized Sites",
							title: "Choose the Sites that this user is authorized to access.",
							order: 7,
							visibleFields: [
								{
									field: "RequestedUsername",
									operator: "!=",
									value: ""
								}, //-- if we have a Username value AND...
								{
									field: "IsSystemAdministrator",
									operator: "==",
									value: false
								}, //-- if the user is not a system administrator AND...
								{
									field: "IsOrganizationAdministrator",
									operator: "==",
									value: false
								} //-- if the user is not an organization administrator, then show the List of Authorized Sites. --Kirk T. Sherer, August 17, 2021.
							],
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_OrganizationSites",
								canExecuteStoredProcedureWithoutParameters: false,
								selectAllOnInitialLoad: false,
								parameters: [
									{ name: "@UserId", value: "Id" },
									{ name: "@PersonId", value: "PersonId" },
									{ name: "@AdminUserId", value: this.currentUser?.Id }
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "AdminUserId",
							label: "Admin User Id",
							title: "The Id number of the Administrator editing this record.",
							order: 8,
							value: this.currentUser?.Id,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "PersonId",
							label: "Person",
							type: "parent-key-field",
							title: "The Person where this user is referenced.",
							listOfValues: this.dataService.cache.people,
							order: 9,
							visible: false
						})
					]
				}
			}, //-- End of Associated Users
			{
				// Systems
				setName: "System",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedSystemRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Systems",
					entityName: "System",
					entityDisplayName: "System",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SystemType",
							label: "System Type",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Site",
							label: "Site",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Gate or System Name",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 20,
							widthUnits: "%"
						},
						{
							name: "DoNotDisplay",
							label: "Do Not Display",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "OwnerOrganization",
							label: "Owner Organization",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "Assets",
							label: "Assets",
							type: "set",
							setName: "Assets",
							width: 12,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ParentSystemId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name for this system.",
							order: 2
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Enter the site where this system is located.",
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							order: 3
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this system.",
							order: 5
						}),
						new TextboxQuestion({
							key: "BaseUnitImageURL",
							label: "Base Unit Image URL",
							title: "Enter the URL for the base unit image.",
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "DoNotDisplay",
							label: "Do Not Display",
							title: "Select whether or not this system should be displayed.",
							order: 7
						}),
						new FileUploadQuestion({
							key: "BaseUnitGraphicImageKey",
							label: "System Graphic",
							title: "Upload the system's graphic here.",
							order: 8
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_System",
					allowAdd: true
				}
			}, // End of Systems
			{
				// System Types
				setName: "SystemType",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedSystemTypeRecords",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "System Types",
					entityName: "System Type",
					entityDisplayName: "System Type",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 27,
							widthUnits: "%"
						},
						{
							name: "LongName",
							label: "Long Name",
							type: "text",
							width: 45,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name for this System Type.",
							order: 2
						}),
						new TextboxQuestion({
							key: "LongName",
							label: "Long Name",
							title: "Enter a more descriptive name for this System Type.",
							order: 2
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_SystemType",
					allowAdd: true
				}
			}, // End of System Types
			{
				// AdveezAsset
				setName: "AdveezAsset",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAdveezAssetRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Adveez Assets",
					entityName: "AdveezAsset",
					entityDisplayName: "Adveez Asset",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true, //-- please leave this alone... adding a record to Asset will determine based on the Asset Type whether or not to add an AdveezAsset record.  Adding a record from this object will create duplicates on the Asset table. --Kirk T. Sherer, November 27, 2023.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "PreferredName",
							label: "Preferred Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssignedSite",
							label: "Assigned Site",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Latitude",
							label: "Latitude",
							type: "text",
							width: 9,
							widthUnits: "%"
						},
						{
							name: "Longitude",
							label: "Longitude",
							type: "text",
							width: 9,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of the vehicle. This name will be used in the tag name structure if this is a newly created Adveez asset record.",
							order: 1
						}),
						new TextboxQuestion({
							key: "PreferredName",
							label: "Preferred Name",
							title: "Enter a different 'preferred' name, if applicable.  Please do not change if the Name field value is acceptable to identify the vehicle.",
							order: 2
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Assigned to Site",
							title: "Enter the site where this vehicle should be located.  This should come from the unit itself, but if it is not broadcasting its GPS location you can enter the Site here and the latitude and longitude of the Site's GPS coordinates will be used to display where the vehicle is located.",
							order: 3,
							validateObject: true,
							type: "generic-selector",
							setName: "Sites"
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AdveezAsset",
					allowAdd: false
				}
			}, // End of Adveez Assets
			{
				// Tags
				setName: "Tag",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedTagRecords @ViewDeletedRecords=1",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Tags",
					entityName: "Tag",
					entityDisplayName: "Tag",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number associated with this Tag record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Tag record.",
							type: "text",
							width: 33,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservation",
							label: "Standard Observation Name",
							type: "text",
							title: "The Standard Observation Name of this Tag record.",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ValueWhenActive",
							label: "Value When Active",
							title: "The Value this tag is sending out when it should be considered 'active'.  This would be overriding the default, which is 1=True (Active) and 0=False (Inactive).",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "EffectiveSeverityLevel",
							label: "Effective Severity Level",
							title: "The actual Severity Level of this Tag record. If any severity level is set on the Tag record, that takes precedence.  Otherwise, the Severity Level comes from the Standard Name Severity Level.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "TagSeverityLevel",
							label: "Tag Severity Level",
							title: "The Severity Level of this Tag record. If we're using the Standard Name Severity Level, then this should be blank (null).",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "StandardNameSeverityLevel",
							label: "Standard Name Severity Level",
							title: "The Severity Level of the Standard Name assigned to this Tag record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "LastObservationDate",
							label: "Last Observation Date",
							title: "The last observation date and time of this record.",
							type: "date",
							width: 8,
							widthUnits: "%"
						},
						{
							name: "LastObservationTextValue",
							label: "Last Observation Text Value",
							title: "The last observation text value of this record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "SeverityLevelId",
							label: "Tag Severity Level Id",
							type: "text",
							hidden: true
						},
						{
							name: "JBTStandardObservationSeverityLevelId",
							label: "Standard Name Severity Level Id",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Tag",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Tag",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The ID value of this Tag record.",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the appropriate name for this Tag record.",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							title: "Enter the appropriate JBT Standard Observation for this tag record.",
							required: true,
							validateObject: true,
							allowBlankValue: true,
							order: 3
						}),
						new TextboxQuestion({
							key: "AssetId",
							label: "Asset",
							title: "Enter the appropriate Asset record for this tag.",
							required: true,
							validateObject: true,
							order: 4,
							type: "generic-selector",
							setName: "Assets"
						}),
						new TextboxQuestion({
							key: "ValueWhenActive",
							label: "ValueWhenActive",
							title: "Enter the Value when Active, if different from the normal 1=True (Active) and 0=False (Inactive).",
							required: false,
							validateObject: false,
							allowBlankValue: true,
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "LastObservationDate",
							label: "Last Observation Date",
							title: "The last time this tag was used on an observation, denoted by the date/time in this field.",
							order: 7
						}),
						new ReadOnlyQuestion({
							key: "LastObservationTextValue",
							label: "Last Observation Text Value",
							title: "The last observation value of this tag.",
							order: 8
						}),
						new UibButtonQuestion({
							key: "SeverityLevelId",
							label: "Severity Level",
							title: "Enter the Severity Level of this Tag record. NOTE: Please DO NOT set this value on the Tag record if this tag doesn't specifically need a different setting from the Standard Name severity level.",
							listOfValues: this.dataService.cache.jbtStandardObservationSeverityLevels,
							allowBlankValue: true,
							order: 10
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active?",
							title: "Select whether or not this Tag should be active.",
							order: 13
						}),
						new SliderYesNoQuestion({
							key: "MarkedForDelete",
							label: "Mark for Delete?",
							title: "Select whether or not this Tag should be marked for deletion.",
							order: 14
						})
					]
				}
			}, // End of Tags
			{
				// Tag Simple Name to JBT Standard Observation Name Mapping
				setName: "TagToJBTSTandardObservationIdMapper",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetTagSimpleNamesToStandardNameRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Tag Simple Name to Standard Name Mapping",
					entityName: "TagSimpleNameToStandardNameMapping",
					entityDisplayName: "Tag Simple Name to Standard Name",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Tag Simple Name to Standard Name record.",
							type: "text",
							hidden: true
						},
						{
							name: "TagSimpleName",
							label: "Tag Simple Name",
							title: "The Tag ending name of this record.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationName",
							label: "Standard Name",
							title: "The Standard Name of this record.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SeverityLevel",
							label: "Severity",
							title: "The Severity Level of this Standard Name.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "IsReportedToSabel",
							label: "Is Reported to Sabel?",
							title: "Is this information reported to Sabel?",
							type: "boolean",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "UpdateAllTagsWithThisStandardName",
							label: "Have Tag records with the same Simple Name been mapped to this Standard Name?",
							title: "Map Tag records to this Standard Name?",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Deleted",
							label: "Marked for Delete?",
							title: "Has the record been deleted?",
							type: "boolean",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "CountOfTagsWithNoJBTStandardObservation",
							label: "Count of Unmapped Tags",
							title: "Count of records that have not been adjusted for the JBT Standard Observation Name",
							type: "number",
							width: 4,
							widthUnits: "%"
						},
						{
							name: "Tags",
							label: "Total Tags",
							title: "Set of Tags listed with this Simple Name.",
							type: "set",
							setName: "Tag",
							width: 12,
							widthUnits: "%",
							parentColumnLink: "TagSimpleName",
							childColumnLink: "SimpleName",
							countOfRecordsField: "CountOfTags",
							countOfRecordsFieldLabel: "Tags"
						},
						{
							name: "CreatorUser",
							title: "The person who created this record.",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						{
							name: "CountOfTags",
							label: "Total Number of Tags",
							title: "The Total Number of Tag records.",
							type: "number",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "TagSimpleName",
							title: "The Tags that end with this name.",
							label: "Tag Name",
							order: 1
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "Associated Standard Name",
							title: "The associated Standard Name of this record.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							required: true,
							validateObject: true,
							allowBlankValue: true,
							order: 2
						}),
						new SliderYesNoQuestion({
							key: "UpdateAllTagsWithThisStandardName",
							title: "Update all tags in the Tag table that end with the same Tag Simple Name with the Standard Name selected?",
							label: "Update all Tags with this same Simple Name?",
							value: false,
							order: 3
						})
					]
				}
			}, // End of Tag Simple Name to JBT Standard Observation Name Mapping
			{
				// Asset Make
				setName: "AssetMake",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetMakeRecords",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Asset Make",
					entityName: "AssetMake",
					entityDisplayName: "Asset Make",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Asset Make record.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The name of this Asset Make.",
							type: "text",
							width: 32,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							title: "The person who created this record.",
							label: "Created By",
							type: "creator-user",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this Asset Make to display in the Asset Model record.",
							required: true,
							order: 1
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AssetMake",
					allowAdd: true
				}
			}, // End of Asset Make
			{
				// Asset Model
				setName: "AssetModel",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetModelRecords",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Asset Model",
					entityName: "AssetModel",
					entityDisplayName: "Asset Model",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Asset Model record.",
							type: "text",
							hidden: true
						},
						{
							name: "Make",
							label: "Make",
							title: "The name of the company that makes this Asset Model.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Model",
							label: "Name",
							title: "The name of this Asset Model.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetType",
							label: "Asset Type",
							title: "The Type of Asset for this Asset Model.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Assets",
							label: "Assets",
							type: "set",
							title: "List of the Assets that have this model assigned.",
							setName: "Assets",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetModelId"
						},
						{
							name: "StandardObservations",
							label: "Std Obs",
							type: "set",
							title: "List of the JBT Standard Observation Ids that come from this model.",
							setName: "AssetModelJBTStandardObservation",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetModelId"
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of this Asset Model.",
							type: "text",
							width: 17,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "BaseImageURL",
							label: "Base Image URL",
							title: "The URL of the Base Image of this model.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							title: "The person who created this record.",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "AssetMakeId",
							label: "Make",
							title: "The company that makes this Asset Model.",
							type: "generic-selector",
							setName: "AssetMake",
							validateObject: true,
							required: true,
							order: 1
						}),
						new TextboxQuestion({
							key: "Model",
							label: "Model Name",
							title: "The Name of this Model.",
							required: true,
							order: 2
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Asset Model.",
							order: 3
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "The Type of Asset that applies to this model.",
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							allowBlankValue: true,
							order: 4
						}),
						new TextboxQuestion({
							key: "BaseImageURL",
							label: "Base Image URL",
							title: "Enter the URL address of where the base image of this model is located.",
							order: 6
						}),
						new UibButtonQuestion({
							key: "AssetEnergyTypeId",
							label: "Energy Type",
							title: "Please select the appropriate Energy Type for this Asset.",
							listOfValues: this.dataService.cache.assetEnergyTypes,
							order: 7,
							required: false,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							]
						}),
						new ReadOnlyQuestion({
							key: "Id",
							type: "primary-key-field",
							label: "Id",
							title: "Id field for this Asset Model.",
							visible: false,
							order: 8
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AssetModel",
					allowAdd: true
				}
			}, // End of Asset Model
			{
				// Asset Model JBT Standard Observation
				setName: "AssetModelJBTStandardObservation",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetModelJBTStandardObservationRecords",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Asset Model JBT Standard Observation",
					entityName: "AssetModelJBTStandardObservation",
					entityDisplayName: "Asset Model JBT Standard Observation",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Asset Model JBT Standard Observation record.",
							type: "text",
							hidden: true
						},
						{
							name: "JBTStandardObservation",
							label: "Standard Name",
							title: "The Name of this JBT Standard Observation",
							type: "text",
							width: 47,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Reasons",
							label: "Reasons",
							type: "set",
							title: "List of the Reason for this JBT Standard Observation for this model.",
							setName: "AssetModelJBTStandardObservationReason",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetModelJBTStandardObservationId"
						},
						{
							name: "CreatorUser",
							title: "The person who created this record.",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "Select the appropriate JBT Standard Observation for this Asset Model.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: false,
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "AssetModelId",
							type: "parent-key-field",
							label: "Model",
							title: "The Asset Model that owns this graphic.",
							visible: false,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Id",
							type: "primary-key-field",
							label: "Id",
							title: "Id field for this graphic.",
							visible: false,
							order: 3
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AssetModelJBTStandardObservation",
					allowAdd: true
				}
			}, // End of Asset Model JBT Standard Observation
			{
				// Asset Model JBT Standard Observation Reason
				setName: "AssetModelJBTStandardObservationReason",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetModelJBTStandardObservationReasons",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Asset Model JBT Standard Observation Reasons",
					entityName: "AssetModelJBTStandardObservationReason",
					entityDisplayName: "Asset Model JBT Standard Observation Reasons",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Asset Model JBT Standard Observation Reason.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Reason for this JBT Standard Observation.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description for this JBT Standard Observation Reason.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetModelJBTStandardObservationId",
							label: "AssetModelJBTStandardObservationId",
							title: "The Id number of this Asset Model JBT Standard Observation.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							title: "The person who created this record.",
							label: "Created By",
							type: "creator-user",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 23,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this Reason for the JBT Standard Observation.",
							order: 1
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "The Description of this Reason for the JBT Standard Observation that can be used in the tooltip or title of the reason when displayed.",
							allowBlankValue: true,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "AssetModelJBTStandardObservationId",
							type: "parent-key-field",
							label: "Asset Model JBT Standard Observation",
							title: "The Asset Model JBT Standard Observation that is the parent record for this reason.",
							visible: false,
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Id",
							type: "primary-key-field",
							label: "Id",
							title: "Id field for this graphic.",
							visible: false,
							order: 4
						})
					]
				}
			}, // End of Asset Model JBT Standard Observation Reasons
			{
				// Asset Model Graphic
				setName: "AssetModelGraphic",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetModelGraphicRecords",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Asset Model Graphic",
					entityName: "AssetModelGraphic",
					entityDisplayName: "Asset Model Graphic",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Asset Model Graphic record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Make",
							label: "Make",
							title: "The name of the company that makes this Asset Model.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Model",
							label: "Name",
							title: "The name of this Asset Model.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Asset Model Graphic.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ImageURL",
							label: "Image URL",
							title: "The URL of the Image of this model graphic.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Graphic Name",
							title: "The Name of this Asset Model Graphic.",
							required: true,
							order: 1
						}),
						new TextboxQuestion({
							key: "ImageURL",
							label: "Image URL",
							title: "Enter the URL address of where the image of this model graphic is located.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "AssetModelId",
							type: "parent-key-field",
							label: "Model",
							title: "The Asset Model that owns this graphic.",
							visible: false,
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Id",
							type: "primary-key-field",
							label: "Id",
							title: "Id field for this graphic.",
							visible: false,
							order: 4
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AssetModelGraphic",
					allowAdd: true
				}
			}, // End of Asset Model Graphic
			{
				// Asset Model Graphic Visible Value
				setName: "AssetModelGraphicVisibleValue",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetExpandedAssetModelGraphicVisibleValueRecords",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Asset Model Graphic Visible Value",
					entityName: "AssetModelGraphicVisibleValue",
					entityDisplayName: "Asset Model Graphic Visible Value",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Asset Model Graphic Visible Value record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Make",
							label: "Make",
							title: "The name of the company that makes the Asset Model.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Model",
							label: "Name",
							title: "The name of the Asset Model.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of the Asset Model Graphic.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservation",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation of this asset model graphic visible value.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "Select the appropriate JBT Standard Observation for this Asset Model Graphic Value.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							order: 1
						}),
						new TextboxQuestion({
							key: "ValueWhenVisible",
							label: "Value When Visible",
							title: "The Value this asset model graphic should be when it's visible.",
							required: false,
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "AssetModelGraphicId",
							type: "parent-key-field",
							label: "Model Graphic",
							title: "The Asset Model Graphic that this value is representing.",
							visible: false,
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Id",
							type: "primary-key-field",
							label: "Id",
							title: "Id field for this Model Graphic.",
							visible: false,
							order: 4
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_AssetModelGraphicVisibleValue",
					allowAdd: true
				}
			}, // End of Asset Model Graphic Visible Value
			{
				// Global Airport Reference
				setName: "GlobalAirportReference",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetListOfGlobalAirportReferences",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Global Airport Reference",
					entityName: "GlobalAirportReference",
					entityDisplayName: "Global Airport Reference",
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Global Airport Reference record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "ICAOCode",
							label: "ICAO Code",
							title: "The International Civil Aviation Organization (ICAO) four-letter code location indicator designating aerodromes around the world.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "IATACode",
							label: "IATA Code",
							title: "The International Air Transportation Association (IATA) airline location three or four-letter code.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SiteName",
							label: "Site",
							title: "The Site that is closest to the location of this record.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AirportName",
							label: "Airport Name",
							title: "The Airport Name closest to this location.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "City",
							label: "City",
							title: "The City of this location.",
							type: "text",
							width: 17,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AltitudeInMeters",
							label: "Altitude in Meters",
							title: "The altitude in meters of this location.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					editStoredProcedureName: "API.RDN_EditRecord_dbo_GlobalAirportReference",
					saveStoredProcedureName: "API.RDN_SaveGlobalAirportReference",
					fields: [
						new TextboxQuestion({
							key: "ICAOCode",
							label: "ICAO Code",
							title: "Enter the appropriate ICAO Code for this location.",
							type: "text",
							order: 1
						}),
						new TextboxQuestion({
							key: "IATACode",
							label: "IATA Code",
							title: "Enter the appropriate IATA Code for this location.",
							type: "text",
							order: 2
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Select the Site assigned to this Organization.",
							order: 3,
							type: "generic-selector",
							setName: "Sites",
							required: false
						}),
						new TextboxQuestion({
							key: "AirportName",
							label: "Airport Name",
							title: "Enter the Airport Name for this location.",
							type: "text",
							order: 4
						}),
						new TextboxQuestion({
							key: "City",
							label: "City",
							title: "Enter the City for this location.",
							type: "text",
							order: 5
						}),
						new TextboxQuestion({
							key: "Country",
							label: "Country",
							title: "Enter the Country for this location.",
							type: "text",
							order: 6
						}),
						new TextboxQuestion({
							key: "Latitude",
							label: "Latitude",
							title: "Enter the Latitude for this location.",
							type: "text",
							order: 7
						}),
						new TextboxQuestion({
							key: "Longitude",
							label: "Longitude",
							title: "Enter the Longitude for this location.",
							type: "text",
							order: 8
						}),
						new TextboxQuestion({
							key: "AltitudeInMeters",
							label: "Altitude in Meters",
							title: "Enter the Altitude in Meters for this location.",
							type: "number",
							order: 9
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "The Global Airport Reference Id.",
							visible: false,
							order: 10
						})
					]
				}
			}, // End of Global Airport Reference
			{
				// Application Version
				setName: "ApplicationVersion",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetApplicationVersions",
					expandedStoredProcedure: true,
					entityName: "ApplicationVersion",
					entityDisplayName: "Application Version",
					maxRows: 40,
					label: "Application Versions",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Application Version.",
							width: 3,
							widthUnits: "%",
							hidden: true
						},
						{
							name: "Version",
							label: "Application Version",
							title: "The Version number of the Application. This should match the environment version in Angular.",
							type: "text",
							width: 12,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The general Description for this Application Version.  Use HTML markup for different styling when the text is displayed.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "GoLiveDate",
							label: "Go-Live Date",
							title: "The Date this version went live.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Environment",
							label: "Environment",
							title: "The latest Environment for this Application Version.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ApplicationType",
							label: "Application Type",
							title: "The Type of application for these release notes.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ReleaseNotes",
							label: "Release Notes",
							type: "set",
							title: "List of the Release Notes for this application version.",
							setName: "ApplicationVersionReleaseNotes",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ApplicationVersionId"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Application Version.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Application Version.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_ApplicationVersion",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_ApplicationVersion",
					fields: [
						new TextboxQuestion({
							key: "Version",
							label: "Application Version",
							title: "Enter the version for the application.",
							order: 1,
							type: "text"
						}),
						new TextareaFancyQuestion({
							key: "Description",
							label: "Description",
							title: "The general Description for this Application Version.  Use HTML markup for different styling when the text is displayed.",
							order: 2,
							type: "text"
						}),
						new DateQuestion({
							key: "GoLiveDate",
							label: "Go-Live Date",
							title: "The Date this version should go live.",
							order: 3
						}),
						new UibButtonQuestion({
							key: "ApplicationTypeId",
							label: "Application Type",
							title: "Please select the correct Application Type for this Application Version.",
							listOfValues: this.dataService.cache.applicationTypes,
							order: 4
						}),
						new UibButtonQuestion({
							key: "EnvironmentId",
							label: "Environment",
							title: "Please select the latest Environment for this Application Version.  This will restrict later environments from seeing the release notes for an earlier environment.",
							listOfValues: this.dataService.cache.environments,
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "Id of this application version.",
							order: 6,
							type: "primary-key-field",
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_ApplicationVersion",
					allowAdd: false
				}
			}, // End of Application Version
			{
				// Application Version Release Notes
				setName: "ApplicationVersionReleaseNotes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetApplicationVersionReleaseNotes",
					expandedStoredProcedure: true,
					entityName: "ApplicationVersionReleaseNote",
					entityDisplayName: "Application Version Release Note",
					maxRows: 40,
					label: "Application Version Release Notes",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Application Version Release Note.",
							width: 3,
							widthUnits: "%",
							hidden: true
						},
						{
							name: "ApplicationVersionId",
							label: "Application Version Id",
							type: "text",
							title: "Id number of this Application Version.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The general Name or highlight of this particular Release Note.",
							type: "text",
							width: 12,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of this Release Note for the new application version.  Use HTML markup for different styling when the text is displayed.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ApplicationType",
							label: "Application Type",
							title: "The Type of application for these release notes.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ApplicationVersionReleaseNoteType",
							label: "Type",
							title: "The Type of change for this Release Note.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Internal",
							label: "Internal",
							title: "Whether or not this Release Note should only be seen by internal staff.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created these Release Notes.",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified these Release Notes.",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_ApplicationVersionReleaseNote",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_ApplicationVersionReleaseNote",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "Id of this Release Note.",
							type: "primary-key-field",
							order: 1,
							visible: false
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the general name of this Release Note.",
							order: 2,
							type: "text"
						}),
						new TextareaFancyQuestion({
							key: "Description",
							label: "Description",
							title: "The detailed description of this Release Note for this Application Version.  Use HTML markup for different styling when the text is displayed.",
							order: 3,
							type: "text"
						}),
						new UibButtonQuestion({
							key: "ApplicationTypeId",
							label: "Application Type",
							title: "Please select the correct Application Type for this Application Version Release Note.",
							listOfValues: this.dataService.cache.applicationTypes,
							order: 4
						}),
						new UibButtonQuestion({
							key: "ApplicationVersionReleaseNoteTypeId",
							label: "Type",
							title: "Please select the correct Type for this Application Version Release Note.",
							listOfValues: this.dataService.cache.applicationVersionReleaseNoteTypes,
							order: 5
						}),
						new SliderYesNoQuestion({
							key: "Internal",
							label: "Internal",
							title: "Select whether or not this Release Note should be only for Internal staff.",
							order: 6
						}),
						new FileUploadQuestion({
							key: "PictureImageKey",
							label: "Picture",
							required: false,
							title: "Upload an image for this Release Note.",
							order: 7
						}),
						new ReadOnlyQuestion({
							key: "ApplicationVersionId",
							label: "Application Version",
							type: "parent-key-field",
							title: "The Application Version for this release note.",
							visible: false,
							order: 8
						})
					]
				}
			}, // End of Application Version Release Notes
			{
				// Application Broadcast Message
				setName: "ApplicationBroadcastMessage",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetApplicationBroadcastMessages",
					expandedStoredProcedure: true,
					entityName: "ApplicationBroadcastMessage",
					entityDisplayName: "Broadcast Message",
					label: "Broadcast Messages",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Broadcast Message.",
							hidden: true
						},
						{
							name: "Message",
							label: "Broadcast Message",
							title: "The Broadcast Message that will be displayed at the top of the Application.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The general Description for this Broadcast Message. This will go in the 'title' when the user hovers over the Broadcast Message being displayed.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OrganizationName",
							label: "Limited to Organization",
							title: "The Organization where this Broadcast Message is limited, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "StartDate",
							label: "Start Date",
							title: "The Start Date and Time that this Broadcast Message needs to be displayed in the application.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "EndDate",
							label: "End Date",
							title: "The End Date and Time that this Broadcast Message needs to stop being displayed in the application.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Application Version.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Application Version.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Message",
							label: "Broadcast Message",
							title: "Enter the Broadcast Message you wish to display on the application. Use HTML markup for different styling when the text is displayed.",
							order: 1,
							type: "text"
						}),
						new TextareaFancyQuestion({
							key: "Description",
							label: "Description",
							title: "The general Description for this Broadcast Message. This will display in the 'title' of the Broadcast Message when the user hovers over the message.'",
							order: 2,
							type: "text"
						}),
						new DateTimeQuestion({
							key: "StartDate",
							label: "Start Date",
							title: "The Start Date and Time this Broadcast Message should be displayed on the application. This must be entered as UTC time, not your local time, since the application will convert the time to your local time.",
							order: 3
						}),
						new DateTimeQuestion({
							key: "EndDate",
							label: "End Date",
							title: "The End Date and Time this Broadcast Message should no longer be displayed on the application. This must be entered as UTC time, not your local time, since the application will convert the time to your local time.",
							order: 4
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Limited to this Organization",
							title: "Enter the Organization where this broadcast message should be displayed, if applicable. Leave this blank if this message should be broadcast to all organizations.",
							order: 5,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Broadcast Message.",
							order: 6,
							visible: false
						})
					]
				}
			}, // End of Application Broadcast Message
			{
				// Ninja Stages
				setName: "NinjaStages",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_NinjaStages",
					expandedStoredProcedure: true,
					entityName: "ETL.NinjaStage",
					entityDisplayName: "Ninja Stage",
					label: "Ninja Stages",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Ninja Stage.",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Ninja Stage.",
							type: "text",
							width: 13,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ClassName",
							label: "Class Name",
							title: "The Class Name of this Ninja Stage within the Ninja program.",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed Description of this Ninja Stage.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Ninja Stage is Active.",
							type: "boolean",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "LogActivity",
							label: "Log Activity",
							title: "Whether or not to log detailed activity of this Ninja Stage.",
							type: "boolean",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "NinjaStageConsideredJBTStandardObservationIds",
							label: "Standard Observations",
							type: "set",
							title: "List of the Considered JBT Standard Observation Ids for this Ninja Stage.",
							setName: "NinjaStageConsideredJBTStandardObservationIds",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "NinjaStageId"
						},
						{
							name: "NinjaStageCalculatedObservations",
							label: "Calculations",
							type: "set",
							title: "List of the Calculated Observations for this Ninja Stage.",
							setName: "NinjaStageCalculatedObservations",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "NinjaStageId"
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Ninja Stage.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Ninja Stage record.",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name for this Ninja Stage.",
							order: 2,
							type: "text",
							required: true
						}),
						new TextboxQuestion({
							key: "ClassName",
							label: "Class Name",
							title: "Enter the Class Name for this Ninja Stage as it appears in the Ninja program.",
							order: 3,
							type: "text",
							required: true
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed Description for this Ninja Stage.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "Active",
							title: "Active",
							label: "Is this Ninja Stage active?",
							order: 5
						}),
						new TextboxQuestion({
							key: "GeneratedTagSimpleName",
							label: "Generated Tag Simple Name",
							title: "Enter the Simple Name of the tag that will be generated by this Ninja Stage.",
							order: 6
						}),
						new TextboxQuestion({
							key: "GeneratedTagJBTStandardObservationId",
							label: "Generated Tag JBT Standard Observation",
							title: "Select the appropriate JBT Standard Observation for this calculate observation.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							order: 7
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the Asset Type specifically for this calculated observation, if applicable.",
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							allowBlankValue: true,
							order: 8
						}),
						new TextboxQuestion({
							key: "RedisLogName",
							label: "Redis Log Name",
							title: "Enter the name to be used for the Redis log for logging the activity of this stage.",
							order: 9
						}),
						new TextboxQuestion({
							key: "RedisKeyName",
							label: "Redis Key Name",
							title: "Enter the Redis Key Name that will be used when creating the calculated observation. This will be displayed in the Observation table under the 'RedisKeyName' field.",
							order: 10
						}),
						new SliderYesNoQuestion({
							key: "LogActivity",
							title: "Log this stage's activity to debug the tag generator and determine if the calculated observations are correct.",
							label: "Log Activity?",
							order: 11
						})
					]
				}
			}, // -- End of Ninja Stages
			{
				// Ninja Stage Considered JBT Standard Observation Ids
				setName: "NinjaStageConsideredJBTStandardObservationIds",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_NinjaStageConsideredJBTStandardObservationIds",
					expandedStoredProcedure: true,
					entityName: "ETL.NinjaStageConsideredJBTStandardObservationId",
					entityDisplayName: "Ninja Stage Considered JBT Standard Observation Id",
					label: "Ninja Stage Considered JBT Standard Observation Ids",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true, //--Records should only be added while inside of Ninja Stages. --Kirk T. Sherer, May 9, 2022.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Considered JBT Standard Observation Id.",
							hidden: true
						},
						{
							name: "JBTStandardObservationId",
							label: "JBT Standard Observation Id",
							type: "text",
							title: "The JBT Standard Observation Id we are using for consideration to generate this calculated observation.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationName",
							label: "JBT Standard Observation Name",
							title: "The Name of this Considered JBT Standard Observation.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Considered JBT Standard Observation.",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Considered JBT Standard Observation.",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						{
							name: "NinjaStageId",
							label: "Ninja Stage Id",
							type: "text",
							title: "Id number of the parent Ninja Stage record.",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "Select the appropriate JBT Standard Observation for consideration when calculating this observation.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: false,
							required: true,
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "NinjaStageId",
							label: "Ninja Stage Id",
							title: "Id of the Ninja Stage this is referring to.",
							type: "parent-key-field",
							order: 2,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Considered JBT Standard Observation record.",
							order: 3,
							visible: false
						})
					]
				}
			}, // -- End of Ninja Stage Considered JBT Standard Observation Ids
			{
				// Ninja Stage Calculated Observations
				setName: "NinjaStageCalculatedObservations",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_NinjaStageCalculatedObservations_with_AND_Conditions",
					expandedStoredProcedure: true,
					entityName: "ETL.NinjaStageCalculatedObservation",
					entityDisplayName: "Calculated Observation",
					label: "Calculated Observations",
					disableAddRecordOutsideRecursedSet: true, //--Records should only be added by Ninja into this table. --Kirk T. Sherer, May 9, 2022.
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Calculated Observation.",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "TagId",
							label: "Tag Id",
							type: "text",
							title: "Id number of the Tag record for this Calculated Observation.",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "TagName",
							label: "Tag Name",
							title: "The Tag Name of this Calculated Observation.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationId",
							label: "Std Obs Id",
							title: "The Id number of the JBT Standard Observation record that represents this calculation.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "AssetId",
							label: "Asset Id",
							title: "The Id number of the Asset where this calculated observation was applied.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "ObservationDateTime",
							label: "Date",
							title: "The date and time this Calculated Observation was created.",
							type: "date",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CalculatedValue",
							label: "Value",
							title: "The Value of this Calculated Observation.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "NinjaStageCalculatedObservationConsideredTags",
							label: "Evaluation History",
							type: "set",
							title: "List of the Tag records considered when generating this Calculated Observation.",
							setName: "NinjaStageCalculatedObservationConsideredTags",
							width: 30,
							widthUnits: "%",
							hidden: false,
							parameters: [
								{
									name: "@TagName",
									value: "TagName"
								},
								{
									name: "@ObservationDateTime",
									value: "ObservationDateTime"
								},
								{
									name: "@NinjaStageId",
									value: "NinjaStageId"
								}
							]
						},
						{
							name: "NinjaStageId",
							label: "Ninja Stage Id",
							type: "text",
							title: "Id number of the parent Ninja Stage record.",
							hidden: true
						}
					]
				}
			}, // -- End of Ninja Stage Calculated Observations
			{
				// Ninja Stage Calculated Observation Reasons
				setName: "NinjaStageCalculatedObservationConsideredTags",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_NinjaStageCalculatedObservationConsideredTags",
					expandedStoredProcedure: true,
					entityName: "ETL.NinjaStageCalculatedObservationConsideredTag",
					entityDisplayName: "Calculated Observation Considered Tag",
					label: "Calculated Observation Considered Tags",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Observation.",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "TagId",
							label: "TagId",
							type: "text",
							title: "Id number of the Tag record.",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "TagName",
							label: "Tag Name",
							title: "The Name of the Tag record.",
							type: "text",
							width: 35,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastObservationDate",
							label: "Date",
							title: "The date and time this Observation occurred.",
							type: "date",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastObservationTextValue",
							label: "Text Value",
							title: "The Text Value of this Observation.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationId",
							label: "Std Obs Id",
							type: "text",
							title: "Id number of the JBT Standard Observation for this tag record.",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "JBTStandardObservationName",
							label: "Standard Name",
							title: "The Name of the JBT Standard Observation for this tag record.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "NinjaStageId",
							label: "Ninja Stage Id",
							type: "text",
							title: "Id number of the Ninja Stage.",
							hidden: true
						}
					]
				}
			}, // -- End of Ninja Stage Calculated Observation Reasons
			{
				// Ninja Stage Log
				setName: "NinjaStageLog",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_NinjaStageLog",
					expandedStoredProcedure: true,
					entityName: "ETL.NinjaStageLog",
					entityDisplayName: "Ninja Stage Log",
					label: "Ninja Stage Log",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Ninja Stage Log.",
							hidden: true
						},
						{
							name: "LogDate",
							label: "Date",
							title: "The date and time this Ninja Stage Log occurred.",
							type: "date",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Asset",
							label: "Asset",
							title: "The Asset related to this Ninja Stage Log.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Message",
							label: "Message",
							title: "The Message of this Ninja Stage Log.",
							type: "text",
							width: 55,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "NinjaStageId",
							label: "Ninja Stage Id",
							type: "text",
							title: "Id number of the parent Ninja Stage record.",
							hidden: true
						}
					]
				}
			}, // -- End of Ninja Stage Log
			{
				// Environment
				setName: "Environment",
				tableDisplay: {
					entityName: "Environment",
					entityDisplayName: "Environment",
					label: "Environments",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Environment.",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Environment.",
							type: "text",
							width: 64,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Environment.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Environment.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of this Environment",
							order: 1,
							type: "text"
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Environment.",
							order: 6,
							visible: false
						})
					]
				}
			}, // End of Environment
			{
				// Sabel OData Standard Observation
				setName: "SabelODataStandardObservation",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetSabelODataStandardObservations",
					expandedStoredProcedure: true,
					entityName: "SabelODataStandardObservation",
					entityDisplayName: "Sabel OData Standard Observation",
					maxRows: 40,
					label: "Sabel OData Standard Observations",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Sabel OData Standard Observation.",
							width: 3,
							widthUnits: "%",
							hidden: true
						},
						{
							name: "JBTStandardObservation",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation record that we're reporting to Sabel.",
							type: "text",
							width: 44,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created these Release Notes.",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified these Release Notes.",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "Select the appropriate JBT Standard Observation for reporting to Sabel.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: false,
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Sabel JBT Standard Observation record.",
							order: 2,
							visible: false
						})
					]
				}
			}, // End of Sabel OData Standard Observation
			{
				// JTP Data Reader
				setName: "JTPDataReader",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetJTPDataReader",
					expandedStoredProcedure: true,
					entityName: "JTPDataReaderIPAddress",
					entityDisplayName: "JTP Data Reader IP Addresses",
					maxRows: 40,
					label: "JTP Data Reader IP Addresses",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this JTP Data Reader IP Address.",
							width: 3,
							widthUnits: "%",
							hidden: true
						},
						{
							name: "SiteName",
							label: "Site Name",
							title: "The Site Name of this address.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "GateName",
							label: "Gate Name",
							title: "The Gate Name of this address.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "IPAddress",
							label: "IP Address",
							title: "The IP Address of this JTP Data Reader record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Port",
							label: "Port",
							title: "The Port of this JTP Data Reader record.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Enabled",
							label: "Enabled",
							title: "Whether or not this JTP Data Reader record is enabled.",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "NetworkName",
							label: "Network Name",
							title: "The Network Name of this JTP Data Reader record.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "Monitored",
							label: "Monitored",
							title: "Whether or not this JTP Data Reader record is being monitored.",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "LastConnectionMessage",
							label: "Last Connection Message",
							title: "The Last Connection Message of this JTP Data Reader record.",
							type: "text",
							width: 41,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this JTP Data Reader record.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this JTP Data Reader record.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "SiteName",
							label: "Site Name",
							title: "Enter the Site Name for this JTP Data Reader record.",
							order: 1,
							type: "text"
						}),
						new TextboxQuestion({
							key: "GateName",
							label: "Gate Name",
							title: "Enter the Gate Name for this JTP Data Reader record.",
							order: 2,
							type: "text"
						}),
						new TextboxQuestion({
							key: "IPAddress",
							label: "IP Address",
							title: "Enter the IP Address for this JTP Data Reader record.",
							order: 3,
							type: "text"
						}),
						new TextboxQuestion({
							key: "Port",
							label: "Port Number",
							title: "Enter the Port Number for this JTP Data Reader record.",
							order: 4,
							type: "text"
						}),
						new TextboxQuestion({
							key: "NetworkName",
							label: "Network Name",
							title: "Enter the Network Name for this JTP Data Reader record.",
							order: 5,
							type: "text"
						}),
						new SliderYesNoQuestion({
							key: "Enabled",
							title: "Enabled",
							label: "Enable this JTP Data Record?",
							value: false,
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "Monitored",
							title: "Monitored",
							label: "Is this JTP Data Record monitored?",
							value: false,
							order: 7
						}),
						new ReadOnlyTextareaQuestion({
							key: "LastConnectionMessage",
							label: "Last Connection Message",
							title: "The Last Connection Message for this JTP Data Reader record.",
							order: 8,
							type: "text"
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this JTP Data Reader record.",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of JTP Data Reader
			{
				// Recipe Frequency Types
				setName: "RecipeFrequencyType",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_FrequencyTypes",
					expandedStoredProcedure: true,
					entityName: "RecipeFrequencyType",
					entityDisplayName: "Recipe Frequency Type",
					label: "Recipe Frequency Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Recipe Frequency Type.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Recipe Frequency Type.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description for this Recipe Frequency Type. This will go in the 'title' when the user hovers over the Recipe Frequency Types being displayed.",
							type: "text",
							width: 40,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							title: "The Order of the Recipe Frequency Types for the order in which they will be displayed.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Recipe Frequency Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Recipe Frequency Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of the Recipe Frequency Type.",
							order: 1,
							type: "text"
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the general Description for this Recipe Frequency Type. This will display in the 'title' of the Recipe Frequency Type when the user hovers over it.'",
							order: 2,
							type: "text"
						}),
						new NumberQuestion({
							key: "Ordinal",
							label: "Ordinal",
							title: "Enter the Ordinal value for the order in which this Recipe Frequency Type should be displayed.",
							order: 3,
							min: 0,
							max: 100
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Frequency Type.",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Recipe Frequency Types
			{
				// Recipe Evaluation Types
				setName: "RecipeEvaluationType",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_EvaluationTypes",
					expandedStoredProcedure: true,
					entityName: "RecipeEvaluationType",
					entityDisplayName: "Recipe Evaluation Type",
					label: "Recipe Evaluation Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Recipe Evaluation Type.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Recipe Evaluation Type.",
							type: "text",
							width: 32,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "NumericOrDateOnly",
							label: "Numeric or Date Only",
							title: "Whether or not this Recipe Evaluation Type is only for numeric or date values.",
							type: "boolean",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "Deleted",
							label: "Deleted",
							title: "Whether or not this Recipe Evaluation Type has been deleted.",
							type: "boolean",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							title: "The Order of the Recipe Evaluation Types for the order in which they will be displayed.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Recipe Evaluation Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Recipe Evaluation Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of the Recipe Evaluation Type.",
							order: 1,
							type: "text"
						}),
						new SliderYesNoQuestion({
							key: "NumericOrDateOnly",
							label: "Numeric or Date Only",
							title: "Select whether or not this Recipe Evaluation Type should be only for numeric or date values.",
							order: 2
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							label: "Deleted",
							title: "Select whether or not this Recipe Evaluation Type should be marked for Deletion.",
							order: 3
						}),
						new NumberQuestion({
							key: "Ordinal",
							label: "Ordinal",
							title: "Enter the Ordinal value for the order in which this Recipe Evaluation Type should be displayed.",
							order: 4,
							min: 0,
							max: 100
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Evaluation Type.",
							order: 5,
							visible: false
						})
					]
				}
			}, // End of Recipe Evaluation Types
			{
				// Recipe Watch Types
				setName: "RecipeWatchType",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_WatchTypes",
					expandedStoredProcedure: true,
					entityName: "RecipeWatchType",
					entityDisplayName: "Recipe Watch Type",
					label: "Recipe Watch Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Recipe Watch Type.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Recipe Watch Type.",
							type: "text",
							width: 22,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description for this Recipe Watch Type. This will go in the 'title' when the user hovers over the Recipe Watch Types being displayed.",
							type: "text",
							width: 40,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							title: "The Order of the Recipe Watch Types for the order in which they will be displayed.",
							type: "text",
							hidden: true
						},
						{
							name: "AllowMultipleWithinSameCondition",
							label: "Allow Multiple Selections",
							title: "Whether we will allow multiple selections of the same Recipe Watch Type based on the same condition.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Recipe Watch Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Recipe Watch Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of the Recipe Watch Type.",
							order: 1,
							type: "text"
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the general Description for this Recipe Watch Type. This will display in the 'title' of the Recipe Watch Type when the user hovers over it.'",
							order: 2,
							type: "text"
						}),
						new SliderYesNoQuestion({
							key: "AllowMultipleWithinSameCondition",
							label: "Allow Multiple Watch Types",
							title: "Select whether or not this Recipe Watch Type should be allowed to be selected multiple times within the same Recipe Condition.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Watch Type.",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Recipe Watch Types
			{
				// Recipe
				setName: "Recipe",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_ListOfRecipes",
					expandedStoredProcedure: true,
					entityName: "dbo.Recipe",
					entityDisplayName: "Recipe",
					label: "Recipes",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Recipe.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "RecipeName",
							label: "Name",
							title: "The Name of this Recipe.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description for this Recipe. This will go in the 'title' when the user hovers over the Recipes being displayed.",
							type: "text",
							width: 23,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "WaitTime",
							label: "Wait Time",
							title: "The length of time to wait before this Recipe is considered 'true'.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Site",
							label: "Site",
							title: "The Site(s) assigned to this Recipe, if applicable.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Organization",
							label: "Organization",
							title: "The Organization assigned to this Recipe, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this Recipe is active.",
							type: "boolean",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "SeverityLevel",
							label: "Severity Level",
							title: "The Severity Level of this Recipe.",
							type: "text",
							hidden: true
						},
						// {
						// 	name: "Conditionals",
						// 	label: "Conditionals",
						// 	title: "The Conditions of this Recipe in order for it to be evaluated as 'true'.",
						// 	type: "set",
						// 	setName: "RecipeConditional",
						// 	width: 5,
						// 	widthUnits: "%",
						// 	parentColumnLink: "Id",
						// 	childColumnLink: "RecipeId"
						// },
						{
							name: "RecipeConditions",
							label: "Conditions",
							title: "The Conditions of this Recipe in order for it to be evaluated as 'true'.",
							type: "set",
							setName: "RecipeCondition",
							width: 20,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "RecipeId"
						},
						{
							name: "ConditionsInEnglish",
							label: "List of Conditions",
							title: "List of Conditions for this Recipe to be calculated.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Recipe Watch Type.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Recipe Watch Type.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_Recipe",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_Recipe",
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of the Recipe.",
							order: 1,
							type: "text"
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the general Description for this Recipe. This will display in the 'title' of the Recipe when the user hovers over it.'",
							order: 2,
							type: "text"
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Organization",
							title: "Choose the Organization that will be specific for this Recipe, if applicable.",
							order: 3,
							type: "generic-selector",
							setName: "Organization"
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Choose the Site that will be specific for this Recipe, if applicable.",
							order: 4,
							type: "generic-selector",
							setName: "Sites"
						}),
						new TextboxQuestion({
							key: "OutputJBTStandardObservationId",
							label: "Output JBT Standard Observation",
							title: "Choose the JBT Standard Observation Name of this Recipe when the calculated tag is generated from this recipe.",
							order: 5,
							type: "generic-selector",
							setName: "JBTStandardObservation"
						}),
						new TextboxQuestion({
							key: "DebounceUnits",
							label: "Wait Time",
							title: "Choose the length of time to wait while true before calculating this Recipe as a true observation.",
							order: 6
						}),
						new UibButtonQuestion({
							key: "DebounceType",
							label: "Wait Time Units",
							title: "Choose the Time Units Type for waiting while true for this Recipe to be calculated as a true observation.",
							order: 7,
							listOfValues: [
								{
									Id: "ms",
									Name: "ms",
									Title: "Time in Milliseconds"
								},
								{
									Id: "s",
									Name: "s",
									Title: "Time in Seconds"
								},
								{
									Id: "min",
									Name: "min",
									Title: "Time in Minutes"
								}
							],
							visibleFields: [
								{
									field: "DebounceUnits",
									operator: "!=",
									value: ""
								} //-- Debounce Units must have a value before this button group will appear. --Kirk T. Sherer, March 14, 2022.
							]
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this Recipe should be active.",
							order: 8
						}),
						new UibButtonQuestion({
							key: "OutputTagType",
							label: "Output Tag Type",
							title: "Choose the Type of Output Tag for this Recipe.",
							order: 9,
							listOfValues: [
								{ Id: "True/False", Name: "True/False" },
								{ Id: "Calculation", Name: "Calculation" }
							]
						}),
						new UibButtonQuestion({
							key: "CalculationType",
							label: "Calculation Type",
							title: "Choose the Type of Calculation for this Recipe.",
							order: 10,
							listOfValues: [
								{ Id: "Average", Name: "Average" },
								{ Id: "Count", Name: "Count" },
								{ Id: "Conversion", Name: "Conversion" }
							],
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								} //-- Debounce Units must have a value before this button group will appear. --Kirk T. Sherer, March 14, 2022.
							]
						}),
						new UibButtonMultiSelectQuestion({
							//-- this one is for the average.  The next one is for the count.
							key: "CalculationFrequencyTypes",
							label: "Frequency",
							title: "Choose how frequently this Recipe should be calculated.",
							order: 11,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_RecipeFrequencyTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@CalculationFrequencyTypes",
										value: "CalculationFrequencyTypes"
									}
								]
							},
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Average"
								}
							]
						}),
						new UibButtonMultiSelectQuestion({
							//-- this one is for the count calculation.  The previous one was for the average calculation.
							key: "CalculationFrequencyTypes",
							label: "Frequency",
							title: "Choose how frequently this Recipe should be calculated.",
							order: 11,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_RecipeFrequencyTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@CalculationFrequencyTypes",
										value: "CalculationFrequencyTypes"
									}
								]
							},
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Count"
								}
							]
						}),
						new NumberQuestion({
							key: "MinimumInput",
							label: "Minimum Input",
							title: "Choose the Minimum Input value for this Recipe Conversion.",
							order: 12,
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Conversion"
								}
							],
							fieldValidation: [{ field: "MaximumInput", operator: ">=" }]
						}),
						new NumberQuestion({
							key: "MaximumInput",
							label: "Maximum Input",
							title: "Choose the Maximum Input value for this Recipe Conversion.",
							order: 13,
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Conversion"
								}
							],
							fieldValidation: [{ field: "MinimumInput", operator: "<=" }]
						}),
						new NumberQuestion({
							key: "MinimumOutput",
							label: "Minimum Output",
							title: "Choose the Minimum Output value for this Recipe Conversion.",
							order: 14,
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Conversion"
								}
							],
							fieldValidation: [{ field: "MaximumOutput", operator: ">=" }]
						}),
						new NumberQuestion({
							key: "MaximumOutput",
							label: "Maximum Output",
							title: "Choose the Maximum Output value for this Recipe Conversion.",
							order: 15,
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Conversion"
								}
							],
							fieldValidation: [{ field: "MinimumOutput", operator: "<=" }]
						}),
						new NumberQuestion({
							key: "Intercept",
							label: "Intercept",
							title: "The Intercept calculated from the minimum and maximum values for this Recipe Conversion.",
							order: 16,
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Conversion"
								}
							],
							value: "MinimumOutput"
						}),
						new NumberQuestion({
							key: "Slope",
							label: "Slope",
							title: "The Slope calculated from the minimum and maximum values for this Recipe Conversion.",
							order: 17,
							visibleFields: [
								{
									field: "OutputTagType",
									operator: "==",
									value: "Calculation"
								},
								{
									field: "CalculationType",
									operator: "==",
									value: "Conversion"
								}
							],
							onChangeFunction: function () {
								var minimumInput = this.form.get("MinimumInput").value;
								var maximumInput = this.form.get("MaximumInput").value;
								var minimumOutput = this.form.get("MinimumOutput").value;
								var maximumOutput = this.form.get("MaximumOutput").value;
								var calculationType = this.form.get("CalculationType").value;
								if (minimumInput != null && maximumInput != null && minimumOutput != null && maximumOutput != null && calculationType == "Conversion") {
									this.form.get("Intercept").setValue(minimumOutput);
									this.form.get("Slope").setValue((maximumOutput - minimumOutput) / (maximumInput - minimumInput));
								}
							}
						}),
						new UibButtonQuestion({
							key: "OutputJBTStandardObservationSeverityLevelId",
							label: "Severity Level",
							title: "Choose the Severity level of this Recipe.",
							order: 18,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_JBTStandardObservationSeverityLevels",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@OutputJBTStandardObservationSeverityLevelId",
										value: "OutputJBTStandardObservationSeverityLevelId"
									}
								]
							}
						}),
						new UibButtonMultiSelectQuestion({
							key: "OutputAssetTypes",
							label: "Output Asset Types",
							title: "Choose the Asset Types where this Recipe should be applied.  Select all of them if it applies to all.",
							order: 19,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_AssetTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@OutputAssetTypes",
										value: "OutputAssetTypes"
									}
								]
							}
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Watch Type.",
							order: 20,
							visible: false
						})
					]
				}
			}, // End of Recipes
			{
				// Recipe Conditionals
				setName: "RecipeConditional",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_ListOfRecipeConditionals",
					expandedStoredProcedure: true,
					entityName: "dbo.RecipeConditional",
					entityDisplayName: "Conditionals",
					label: "Conditionals",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Recipe Condition.",
							hidden: true
						},
						{
							name: "RecipeId",
							label: "Recipe Id",
							type: "text",
							title: "Id number of the Recipe.",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "ORCollectionLineNumber",
							label: "OR Condition",
							title: "The OR Condition of this Recipe.",
							type: "number",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LineNumber",
							label: "Line Number",
							title: "The Line Number of this Recipe Condition.",
							type: "number",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "WatchType",
							label: "Watch Type",
							title: "The Watch Type of this Recipe Condition.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ValuesToWatch",
							label: "Values to Watch",
							title: "The Values to Watch for this Recipe Condition.",
							type: "text",
							width: 24,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "WaitTime",
							label: "Wait Time",
							title: "The length of time to wait before this Recipe is considered 'true'.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Recipe Watch Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Recipe Watch Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of the Recipe.",
							order: 1,
							type: "text"
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the general Description for this Recipe. This will display in the 'title' of the Recipe when the user hovers over it.'",
							order: 2,
							type: "text"
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Choose the Site that will be specific for this Recipe, if applicable.",
							order: 5,
							type: "generic-selector",
							setName: "Sites"
						}),
						new TextboxQuestion({
							key: "OrganizationId",
							label: "Organization",
							title: "Choose the Organization that will be specific for this Recipe, if applicable.",
							order: 5,
							type: "generic-selector",
							setName: "Organization"
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Watch Type.",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of Recipe Conditionals
			{
				// Recipe Conditions
				setName: "RecipeCondition",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_ListOfRecipeConditions",
					expandedStoredProcedure: true,
					entityName: "dbo.RecipeCondition",
					entityDisplayName: "Conditions",
					label: "Conditions",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Recipe Condition.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							title: "Name of this Condition for the Recipe evaluation.",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "Description",
							label: "Description",
							title: "The Description of this Condition for evaluating this Recipe.",
							type: "number",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							title: "The Ordinal value of this Recipe Condition.",
							type: "number",
							hidden: true
						},
						{
							name: "IsConstant",
							label: "Is Constant?",
							title: "Whether or not this condition is considered a Constant (i.e. must always be true) for this Recipe.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ConditionWatches",
							label: "Objects to Watch",
							title: "The observations we are watching for this Condition to be evaluated as 'true'.",
							type: "set",
							setName: "RecipeConditionWatch",
							width: 14,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "RecipeConditionId"
						},
						{
							name: "ConditionWatchInEnglish",
							label: "Object to Watch",
							title: "The Objects we're watching for this Condition to be evaluated as 'true'.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Recipe Watch Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Recipe Watch Type.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextareaQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the Name of this Condition for evaluating this Recipe.",
							order: 1,
							type: "text"
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the general Description for this Condition when evaluating this Recipe.'",
							order: 2,
							type: "text"
						}),
						new SliderYesNoQuestion({
							key: "IsConstant",
							label: "Is Constant?",
							title: "Choose whether or not this Condition is a constant (i.e. must always be true) for this Recipe.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "RecipeId",
							label: "Recipe Id",
							title: "Id of the Recipe.",
							order: 4,
							type: "parent-key-field",
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Condition.",
							order: 5,
							visible: false
						})
					]
				}
			}, // End of Recipe Conditions
			{
				// Recipe Conditions
				setName: "RecipeConditionWatch",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Recipe_ListOfRecipeConditionWatches",
					expandedStoredProcedure: true,
					entityName: "dbo.RecipeConditionWatch",
					entityDisplayName: "Condition Watch",
					label: "Condition Watches",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this observation to Watch for this Condition.",
							hidden: true
						},
						{
							name: "RecipeConditionId",
							label: "Recipe Condition Id",
							type: "text",
							title: "Id number of the Recipe Condition.",
							hidden: true
						},
						{
							name: "RecipeWatchTypeId",
							label: "Recipe Watch Type Id",
							type: "text",
							title: "Id number of the Recipe Watch Type.",
							hidden: true
						},
						{
							name: "JBTStandardObservationId",
							label: "JBT Standard Observation Id",
							type: "text",
							title: "Id number of the JBT Standard Observation.",
							hidden: true
						},
						{
							name: "Ordinal",
							label: "Ordinal",
							title: "The Ordinal value of this observation to watch for this Condition.",
							type: "number",
							hidden: true
						},
						{
							name: "WatchType",
							label: "Watch Type",
							title: "The Type of Watch for this Condition.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Val",
							label: "Objects to Watch",
							title: "The Objects to Watch for this observation to Watch for this Condition.",
							type: "text",
							width: 57,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OriginalVal",
							label: "Original Value",
							title: "The Original Value of this observation to watch for this Condition.",
							type: "text",
							hidden: true
						},
						{
							name: "IdNumbersToWatch",
							label: "Id Numbers to Watch",
							title: "The Id Numbers of the Watch Type selected for this Condition.",
							type: "text",
							hidden: true
						},
						{
							name: "WaitTime",
							label: "Wait Time",
							title: "The length of time to wait before this observation to Watch for this Condition is considered 'true'.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this observation to Watch for this Condition.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this observation to Watch for this Condition.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_RecipeConditionWatch",
					editStoredProcedureName: "API.RDN_EditRecord_dbo_RecipeConditionWatch",
					fields: [
						new UibButtonQuestion({
							key: "RecipeWatchTypeId",
							label: "Object to Watch",
							title: "Choose the Watch Type for this Condition.",
							order: 1,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_RecipeWatchTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@RecipeConditionId",
										value: "RecipeConditionId"
									},
									{
										name: "@RecipeWatchTypeId",
										value: "RecipeWatchTypeId"
									}
								]
							}
						}),
						new UibButtonMultiSelectQuestion({
							key: "AssetTypes",
							label: "Asset Types",
							title: "Choose from the list of Asset Types for this evaluation.",
							order: 2,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_AssetTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@selectedAssetTypeIdList",
										formKeyField: "AssetTypes"
									}
								]
							},
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 5
								} //-- if the Watch Type is 'Asset Type'.
							]
						}),
						new UibButtonMultiSelectQuestion({
							key: "Sites",
							label: "Sites",
							title: "Choose from the list of Sites for this evaluation.",
							order: 2,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_PermittedSites",
								canExecuteStoredProcedureWithoutParameters: false,
								parameters: [
									{
										name: "@UserPermittedSiteIdList",
										value: Global.User.PermittedSites.select((s: any) => {
											return s.Id;
										})
											.toArray()
											.join(",")
									},
									{
										name: "@SitesSelectedIdList",
										formKeyField: "Sites"
									}
								]
							},
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 1
								} //-- if the Watch Type is 'Site'.
							]
						}),
						new UibButtonMultiSelectQuestion({
							key: "Organizations",
							label: "Organizations",
							title: "Choose from the list of Organizations for this evaluation.",
							order: 2,
							visible: Global.User.isAdmin,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_Organizations",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@OrganizationsSelectedIdList",
										formKeyField: "Organizations"
									}
								]
							},
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 2
								} //-- if the Watch Type is 'Organization'.
							]
						}),
						new UibButtonMultiSelectQuestion({
							key: "GateSystems",
							label: "Gates",
							title: "Choose from the list of Gates for this evaluation.",
							order: 2,
							type: "gate-system",
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_GateSystems",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@GateSystemsSelectedIdList",
										formKeyField: "GateSystems"
									},
									{
										name: "@SiteIdList",
										listLookupField: "RecipeWatchTypeId",
										listLookupValue: "1",
										listValue: "IdNumbersToWatch"
									},
									{
										name: "@RecipeOrganizationId",
										formKeyField: "RecipeOrganizationId"
									},
									{
										name: "@RecipeSiteId",
										formKeyField: "RecipeSiteId"
									}
								]
							},

							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 4
								}, //-- if the Watch Type is 'Gate' AND...
								{ field: "Sites", operator: "!=", value: null } //-- if the Sites are not equal to a null value.
							]
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "JBT Standard Observation",
							title: "The JBT Standard Observation record to Watch for this Recipe Condition.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							order: 2,
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 3
								} //-- if the Watch Type is a Standard Observation type.
							]
						}),
						new TextboxQuestion({
							key: "TagId",
							label: "Tag",
							title: "The specific Tag record to Watch for this Recipe Condition.",
							type: "generic-selector",
							setName: "Tag",
							validateObject: true,
							allowBlankValue: true,
							order: 2,
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 6
								} //-- if the Watch Type is a Tag type.
							]
						}),
						new TextboxQuestion({
							key: "SystemId",
							label: "System",
							title: "The specific System record to Watch for this Recipe Condition.",
							type: "generic-selector",
							setName: "System",
							validateObject: true,
							allowBlankValue: true,
							order: 2,
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 9
								} //-- if the Watch Type is a System type.
							]
						}),
						new TextboxQuestion({
							key: "AssetId",
							label: "Asset",
							title: "The specific Asset record to Watch for this Recipe Condition.",
							type: "generic-selector",
							setName: "Assets",
							validateObject: true,
							allowBlankValue: true,
							order: 2,
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "==",
									value: 10
								} //-- if the Watch Type is a Asset type.
							]
						}),
						new UibButtonQuestion({
							key: "RecipeEvaluationTypeId",
							label: "Evaluation Type",
							title: "Choose the Evaluation Type for this Condition.",
							order: 3,
							buttonList: {
								storedProcedure: "API.RDN_ListOfValues_RecipeEvaluationTypes",
								canExecuteStoredProcedureWithoutParameters: true,
								parameters: [
									{
										name: "@RecipeEvaluationTypeId",
										value: "RecipeEvaluationTypeId"
									}
								]
							},
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 1
								}, //-- if the Watch Type is NOT 'Site'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 2
								}, //-- if the Watch Type is NOT 'Organization'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 4
								}, //-- if the Watch Type is NOT 'Gate System'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 5
								} //-- if the Watch Type is NOT 'Asset Type'.
							]
						}),
						new TextareaQuestion({
							key: "ValuesToWatch",
							label: "Values to Watch",
							title: "Enter the Values to watch as a comma-separated list.",
							order: 4,
							type: "text",
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 1
								}, //-- if the Watch Type is NOT 'Site'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 2
								}, //-- if the Watch Type is NOT 'Organization'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 4
								}, //-- if the Watch Type is NOT 'Gate System'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 5
								} //-- if the Watch Type is NOT 'Asset Type'.
							]
						}),
						new TextboxQuestion({
							key: "TimeToWaitWhileTrue",
							label: "Time to Wait While True",
							title: "Choose the length of time to wait while true before calculating this Watch as a true condition.",
							order: 5,
							visibleFields: [
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 1
								}, //-- if the Watch Type is NOT 'Site'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 2
								}, //-- if the Watch Type is NOT 'Organization'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 4
								}, //-- if the Watch Type is NOT 'Gate System'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 5
								} //-- if the Watch Type is NOT 'Asset Type'.
							]
						}),
						new UibButtonQuestion({
							key: "WaitType",
							label: "Wait Time Units",
							title: "Choose the Time Units Type for waiting while true for this Recipe to be calculated as a true observation.",
							order: 6,
							listOfValues: [
								{
									Id: "ms",
									Name: "ms",
									Title: "Time in Milliseconds"
								},
								{
									Id: "s",
									Name: "s",
									Title: "Time in Seconds"
								},
								{
									Id: "min",
									Name: "min",
									Title: "Time in Minutes"
								}
							],
							visibleFields: [
								{
									field: "TimeToWaitWhileTrue",
									operator: "!=",
									value: ""
								}, //-- TimeToWaitWhileTrue must have a value before this button group will appear. --Kirk T. Sherer, March 14, 2022.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 1
								}, //-- if the Watch Type is NOT 'Site'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 2
								}, //-- if the Watch Type is NOT 'Organization'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 4
								}, //-- if the Watch Type is NOT 'Gate System'.
								{
									field: "RecipeWatchTypeId",
									operator: "!=",
									value: 5
								} //-- if the Watch Type is NOT 'Asset Type'.
							]
						}),
						new ReadOnlyQuestion({
							key: "RecipeConditionId",
							label: "Recipe Condition Id",
							title: "Id of the Recipe Condition.",
							order: 7,
							type: "parent-key-field",
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Recipe Condition Watch.",
							order: 8,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "RecipeSiteId",
							label: "Recipe Site Id",
							title: "Site Id of this Recipe, if applicable.",
							order: 9,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "RecipeOrganizationId",
							label: "Recipe Organization Id",
							title: "Organization Id of this Recipe, if applicable.",
							order: 10,
							visible: false
						})
					]
				}
			}, // End of Recipe Condition Watches
			{
				// GSE Tag Simple Name
				setName: "GSETagSimpleName",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GSE_TagSimpleName",
					expandedStoredProcedure: true,
					entityName: "dbo.GSETagSimpleName",
					entityDisplayName: "GSE Tag Simple Name",
					label: "GSE Tag Simple Names",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this GSE Tag Simple Name.",
							hidden: true
						},
						{
							name: "SimpleName",
							label: "Simple Name",
							type: "text",
							title: "Simple Name of this Metric Type.",
							width: 30,
							widthUnits: "%"
						},
						{
							name: "SimpleNameTranslation",
							label: "Simple Name Translation",
							title: "The Simple Name Translation in our JBT Standard Observation Table.",
							type: "text",
							width: 30,
							widthUnits: "%"
						},
						{
							name: "JBTStandardObservationId",
							label: "Std Obs Id",
							title: "The matching JBT Standard Observation Id for this Metric Type.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "JBTStandardObservationName",
							label: "Std Obs Name",
							title: "The matching JBT Standard Observation Name for this Metric Type. If it's null, then that means we have no matching JBT Standard Observation mapping for this metric type.",
							type: "text",
							width: 27,
							widthUnits: "%"
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this observation to Watch for this Condition.",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "SimpleName",
							label: "Simple Name",
							title: "Enter the Simple Name of this Metric Type.",
							order: 1,
							type: "text"
						}),
						new TextboxQuestion({
							key: "SimpleNameTranslation",
							label: "Simple Name Translation",
							title: "Enter the simple name translation of this Metric Type.",
							order: 2,
							type: "text"
						}),
						new TextboxQuestion({
							key: "JBTStandardObservationId",
							label: "Std Obs Id",
							title: "Choose whether or not this Condition is a constant (i.e. must always be true) for this Recipe.",
							order: 3,
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this GSE Tag Simple Name record.",
							order: 4,
							visible: false
						})
					]
				}
			}, // End of GSE Tag Simple Names (i.e. Adveez GSE Metric Types to JBTStandardObservationId mapping)
			// {
			// 	// Aircraft Docked Log
			// 	setName: "AircraftDockedLog",
			// 	tableDisplay: {
			// 		sqlStoredProcedure: "API.RDN_AircraftDockedLog",
			// 		expandedStoredProcedure: true,
			// 		entityName: "dbo.AircraftDockedLog",
			// 		entityDisplayName: "Aircraft Docked Log",
			// 		label: "Aircraft Docked Log",
			// 		displayColumns: [
			// 			{
			// 				name: "Id",
			// 				label: "Id",
			// 				type: "text",
			// 				title: "Id number of this Aircraft Docked Log.",
			// 				hidden: true
			// 			},
			// 			{
			// 				name: "Gate",
			// 				label: "Gate",
			// 				title: "The Site and Gate System related to this Aircraft Docked log record.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "AssetId",
			// 				label: "AssetId",
			// 				type: "text",
			// 				title: "The Asset Id related to this Aircraft Docked Log record.",
			// 				width: 5,
			// 				widthUnits: "%"
			// 			},
			// 			{
			// 				name: "AutolevelTagId",
			// 				label: "Autolevel Tag Id",
			// 				type: "text",
			// 				title: "The AutolevelTagId related to this Aircraft Docked Log record.",
			// 				width: 5,
			// 				widthUnits: "%"
			// 			},
			// 			{
			// 				name: "AutolevelValue",
			// 				label: "Autolevel Value",
			// 				type: "text",
			// 				title: "The AutolevelValue related to this Aircraft Docked Log record.",
			// 				width: 5,
			// 				widthUnits: "%"
			// 			},
			// 			{
			// 				name: "AutolevelDate",
			// 				label: "Autolevel Date",
			// 				type: "date",
			// 				title: "The AutolevelDate related to this Aircraft Docked Log record.",
			// 				width: 10,
			// 				widthUnits: "%"
			// 			},
			// 			{
			// 				name: "DockedDate",
			// 				label: "Docked Date",
			// 				title: "The Docked Date and time this Aircraft Docked Log occurred.",
			// 				type: "date",
			// 				width: 10,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "DockedTagId",
			// 				label: "Docked Tag Id",
			// 				title: "The DockedTagId related to this Aircraft Docked Log.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "DockedValue",
			// 				label: "Docked Value",
			// 				title: "The DockedValue of this Aircraft Docked Log.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "IRSensorOnSeconds",
			// 				label: "IR Sensor On Seconds",
			// 				title: "The IR Sensor On in seconds of this Aircraft Docked Log.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "IRSensorCountLast15Seconds",
			// 				label: "IR Sensor Count Last 15 Seconds",
			// 				title: "The IR Sensor Count in the last fifteen (15) seconds of this Aircraft Docked Log.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "CanopyDownSeconds",
			// 				label: "Canopy Down Seconds",
			// 				title: "How long the Canopy was down in seconds of this Aircraft Docked Log.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "BrakeReleaseCountLast2Minutes",
			// 				label: "Brake Release Count Last 2 Minutes",
			// 				title: "How many times the Horizontal Brake was released in the last two (2) minutes of this Aircraft Docked Log.",
			// 				type: "text",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "ShouldBeDocked",
			// 				label: "Should Be Docked",
			// 				title: "Whether or not this aircraft should be docked.",
			// 				type: "boolean",
			// 				width: 5,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "OutputTag",
			// 				label: "Output Tag",
			// 				title: "The Output Tag for this Aircraft Docked event.",
			// 				type: "text",
			// 				width: 10,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			},
			// 			{
			// 				name: "Reason",
			// 				label: "Reason",
			// 				title: "The reason why we generated the output tag with the value entered.",
			// 				type: "text",
			// 				width: 25,
			// 				widthUnits: "%",
			// 				sortable: true
			// 			}
			// 		]
			// 	}
			// }, // -- End of Aircraft Docked Log
			{
				// Training Course
				setName: "TrainingCourse",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetTrainingCourses",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Training Course",
					entityName: "TrainingCourse",
					entityDisplayName: "Training Course",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,

					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Training Course record.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of the this Training Course.",
							type: "text",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed Description of this Training Course.",
							type: "text",
							width: 40,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Lessons",
							label: "Lessons",
							title: "The Lessons for this Training Course.",
							setName: "TrainingCourseLesson",
							type: "set",
							width: 10,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "TrainingCourseId"
						},
						{
							name: "Ordinal",
							title: "The Ordinal value of this Training Course Lesson.",
							label: "Ordinal",
							type: "number",
							hidden: true
						},
						{
							name: "CreatorUser",
							title: "The person who created this Training Course record.",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this Training Course record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this Training Course record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this Training Course record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this Training Course.",
							required: true,
							order: 1
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Training Course.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this Training Course.",
							visible: false,
							type: "primary-key-field",
							order: 1
						})
					]
				}
			}, // End of Training Course
			{
				// Training Course
				setName: "TrainingCourseLesson",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_GetTrainingCourseLessons",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "Training Course Lessons",
					entityName: "TrainingCourseLesson",
					entityDisplayName: "Training Course Lesson",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,

					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Training Course Lesson record.",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of the this Training Course Lesson.",
							type: "text",
							width: 27,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed Description of this Training Course Lesson.",
							type: "text",
							width: 30,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "WidgetType",
							label: "Widget Type",
							title: "The Widget Type that corresponds to this Training Course Lesson.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "VideoImageKey",
							title: "The Video Image Key of this Training Course Lesson.",
							label: "Video",
							type: "text",
							hidden: true
						},
						{
							name: "Ordinal",
							title: "The Ordinal value of this Training Course Lesson.",
							label: "Ordinal",
							type: "number",
							hidden: true
						},
						{
							name: "TrainingCourseId",
							title: "The Id of the Training Course.",
							label: "Training Course Id",
							type: "text",
							hidden: true
						},
						{
							name: "WidgetTypeId",
							title: "The Widget Type Id of the Training Course Lesson.",
							label: "Widget Type Id",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							title: "The person who created this Training Course record.",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this Training Course record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The creation date of this Training Course record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this Training Course record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this Training Course Lesson.",
							required: true,
							order: 1
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Training Course Lesson.",
							order: 2
						}),
						new TextboxQuestion({
							key: "WidgetTypeId",
							label: "Widget Type",
							title: "Please select the corresponding Widget Type for this training course lesson.",
							order: 3,
							type: "generic-selector",
							setName: "WidgetTypes",
							validateObject: true,
							required: false,
							allowBlankValue: true,
							listOfValues: this.dataService.cache.widgetTypes
						}),
						new FileUploadQuestion({
							key: "VideoImageKey",
							label: "Video",
							required: false,
							title: "Upload a video for this Lesson.",
							order: 4
						}),
						new ReadOnlyQuestion({
							key: "Ordinal",
							label: "Ordinal",
							title: "The Ordinal value of the Training Course Lesson.",
							visible: false,
							type: "number",
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "TrainingCourseId",
							label: "TrainingCourseId",
							title: "The Id field of the Training Course.",
							visible: false,
							type: "parent-key-field",
							order: 6
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this Training Course Lesson.",
							visible: false,
							type: "primary-key-field",
							order: 7
						})
					]
				}
			}, // Training Course Lesson
			{
				// SQLServerChange
				setName: "SQLServerChange",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_SQLServerChanges",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "SQL Server Change",
					entityName: "SQLServerChange",
					entityDisplayName: "SQL Server Change",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this SQL Server Change record.",
							type: "text",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this SQL Server Change.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed Description of this SQL Server Change.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "ScriptedCode",
							label: "SQL Change Script",
							title: "The SQL change script generated from SSMS for your changes to the destination environment.",
							type: "text",
							width: 29,
							widthUnits: "%"
						},
						{
							name: "Deleted",
							title: "Whether or not this stored procedure change was deleted.",
							label: "Deleted",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "MoveToProduction",
							title: "Whether or not this stored procedure has been scheduled to move to Production.",
							label: "Move to Production",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "DateRequestedToMoveToProduction",
							label: "Requested Move to Prod",
							title: "The date the stored procedure was requested to move to Production.",
							type: "date",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "DateMovedToProduction",
							label: "Date Moved",
							title: "The date the stored procedure was moved to Production.",
							type: "date",
							width: 15,
							widthUnits: "%"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Admin_SQLServerChange",
					editStoredProcedureName: "API.RDN_EditRecord_Admin_SQLServerChange",
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this SQL Server Change.",
							required: true,
							order: 1
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this SQL Server Change.",
							order: 2
						}),
						new TextareaQuestion({
							key: "ScriptedCode",
							label: "SQL Change Script",
							title: "Enter the SQL Change Script that comes from SSMS for this SQL Server Change.",
							required: true,
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "MoveToProduction",
							label: "Move to Production?",
							title: "Should this SQL Server Change move to production? Selecting this and saving changes will timestamp the Requested Date to move to Production field.",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							label: "Delete this change?",
							title: "Should this SQL Server Change be deleted?",
							order: 5
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this SQL Server Change.",
							visible: false,
							type: "primary-key-field",
							order: 6
						})
					]
				}
			}, // End of SQLServerChange
			{
				// SQLServerStoredProcedureChange
				setName: "SQLServerStoredProcedureChange",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_SQLServerStoredProcedureChanges",
					expandedStoredProcedure: true,
					maxRows: 40,
					label: "SQL Server Stored Procedure Changes",
					entityName: "Admin.SQLServerStoredProcedureChange",
					entityDisplayName: "SQL Server Stored Procedure Change",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					orderBy: {
						column: "TestLastModifiedDate",
						sortOrder: "desc"
					},
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this SQL Server Stored Procedure Change record.",
							type: "text",
							hidden: true
						},
						{
							name: "StoredProcedureName",
							label: "Name",
							title: "The Name of the SQL Server Stored Procedure being changed.",
							type: "text",
							width: 40,
							widthUnits: "%"
						},
						{
							name: "TestLastModifiedDate",
							label: "Test Last Modified Date",
							title: "The last modified date of this stored procedure on the Test SQL Server.",
							type: "date",
							width: 12,
							widthUnits: "%"
						},
						{
							name: "MoveToProduction",
							title: "Whether or not this stored procedure has been scheduled to move to Production.",
							label: "Requested Move to Production",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "ApprovedToMoveToProduction",
							title: "Whether or not this stored procedure has been approved to move to Production.",
							label: "Approved to Move to Production",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Deleted",
							title: "Whether or not this stored procedure change was deleted.",
							label: "Deleted",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "RequestorUser",
							label: "Requested Move to Prod",
							title: "The person who requested this SQL Server Stored Procedure Change to move to Production.",
							type: "requestor-user",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "ApproverUser",
							label: "Approval",
							title: "The person who approved this SQL Server Stored Procedure Change to move to Production.",
							type: "approver-user",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "MovedToProductionUser",
							label: "Moved to Prod",
							title: "The person who moved this SQL Server Stored Procedure Change to Production.",
							type: "moved-to-production-user",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "RequestedDate",
							label: "Date Requested Move to Prod",
							title: "The date the stored procedure was requested to move to Production.",
							type: "text",
							hidden: true
						},
						{
							name: "ApprovedDate",
							label: "Date Approved",
							title: "The date the stored procedure was approved to be moved to Production.",
							type: "text",
							hidden: true
						},
						{
							name: "DateMovedToProduction",
							label: "Date Moved",
							title: "The date the stored procedure was moved to Production.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Admin_SQLServerStoredProcedureChange",
					editStoredProcedureName: "API.RDN_EditRecord_Admin_SQLServerStoredProcedureChange",
					fields: [
						new ReadOnlyQuestion({
							key: "StoredProcedureName",
							label: "Name",
							title: "The Name of this SQL Server Stored Procedure.",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "TestLastModifiedDate",
							label: "Test Date Last Modified",
							title: "The date/time this SQL Server Stored Procedure was last modified on Test.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "ProductionLastModifiedDate",
							label: "Production Date Last Modified",
							title: "The date/time this SQL Server Stored Procedure was last modified on Production, if applicable.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "DateMovedToProduction",
							label: "Date Moved to Production",
							title: "The date/time this SQL Server Stored Procedure Change was moved to Production.",
							visibleFields: [
								{
									field: "DateMovedToProduction",
									operator: "!=",
									value: null
								},
								{
									field: "DateMovedToProduction",
									operator: "!=",
									value: ""
								}
							],
							type: "date",
							order: 4
						}),
						new SliderYesNoQuestion({
							key: "MoveToProduction",
							label: "Request to Move to Production?",
							title: "Should this stored procedure move to production? Selecting this and saving changes will timestamp the Requested Date to move to Production field.",
							order: 5,
						}),
						new SliderYesNoQuestion({
							key: "ApprovedToMoveToProduction",
							label: "Approve Move to Production?",
							title: "Should this stored procedure move to production? Selecting this and saving changes will trigger the automated stored procedure to actually move the Test version to the Prod version.",
							order: 6,
							visible: this.currentUser?.Rockstar,
							visibleFields: [
								{
									field: "MoveToProduction",
									operator: "==",
									value: true
								}
							]
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							label: "Ignore this change?",
							title: "Should this stored procedure be ignored or removed from this list? Those changes with no difference between Test and Prod should be ignored (marked for deletion) since the text in each version is identical.",
							order: 7
						}),
						new TextDifferencesComparisonQuestion({
							key: "TextDifferences",
							label: "Changes in Stored Procedure",
							title: "The changes in the stored procedure in comparing the Production version with the Test version.",
							leftFile: "ProductionStoredProcedureText",
							rightFile: "TestStoredProcedureText",
							order: 8
						}),
						new ReadOnlyTextareaLargeQuestion({
							key: "ProductionStoredProcedureText",
							label: "Production version of the Stored Procedure",
							title: "The Production version of this stored procedure.",
							order: 9,
							visible: false
						}),
						new ReadOnlyTextareaLargeQuestion({
							key: "TestStoredProcedureText",
							label: "Test version of the Stored Procedure",
							title: "The Test version of this stored procedure.",
							order: 10,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this SQL Server Stored Procedure Change.",
							visible: false,
							type: "primary-key-field",
							order: 11
						})
					]
				}
			}, // End of SQLServerStoredProcedureChange
			{
				// Dynamic Form Template
				setName: "DynamicFormTemplate",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_DynamicFormTemplates",
					expandedStoredProcedure: true,
					label: "Dynamic Form Template",
					entityName: "DynamicFormTemplate",
					entityDisplayName: "Dynamic Form Template",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Dynamic Form Template record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of the this Dynamic Form Template.",
							type: "text",
							width: 20,
							widthUnits: "%"
						},
						{
							name: "Description",
							label: "Description",
							title: "The detailed Description of this Dynamic Form Template.",
							type: "text",
							width: 42,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							title: "The person who created this Dynamic Form Template record.",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this Dynamic Form Template record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "CreationDate",
							title: "The creation date of this Dynamic Form Template record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this Dynamic Form Template record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this Dynamic Form Template.",
							required: true,
							order: 1
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the Description of this Dynamic Form Template.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this Dynamic Form Template.",
							visible: false,
							type: "primary-key-field",
							order: 3
						})
					]
				}
			}, // End of Dynamic Form Template
			{
				// User Issue Contacts
				setName: "UserIssueContact",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_UserIssueContacts",
					expandedStoredProcedure: true,
					label: "User Issue Contacts",
					entityName: "UserIssueContact",
					entityDisplayName: "User Issue Contact",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this User Issue Contact record.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Contact with the email address for the contact.",
							type: "text",
							width: 47,
							widthUnits: "%"
						},
						{
							name: "CreatorUser",
							title: "The person who created this Dynamic Form Template record.",
							label: "Created By",
							type: "creator-user",
							width: 25,
							widthUnits: "%"
						},
						{
							name: "LastModifiedUser",
							title: "The person who last modified this Dynamic Form Template record.",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 25,
							widthUnits: "%"
						},
						{
							name: "CreationDate",
							title: "The creation date of this Dynamic Form Template record.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							title: "The last modified date of this Dynamic Form Template record.",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new TextboxQuestion({
							key: "UserId",
							label: "Username",
							title: "Enter the username to assign as a contact for User Issues.",
							order: 2,
							type: "generic-selector",
							setName: "iOPSUsers",
							validateObject: true,
							required: true
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this Contact record.",
							visible: false,
							type: "primary-key-field",
							order: 2
						})
					]
				}
			}, // User Issue Contacts
			{
				// Time Zone List
				setName: "TimeZoneList",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_TimeZoneList",
					expandedStoredProcedure: true,
					label: "Time Zone List",
					entityName: "TimeZoneList",
					entityDisplayName: "Time Zone",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Time Zone record.",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Time Zone.",
							type: "text",
							width: 35,
							widthUnits: "%"
						},
						{
							name: "CountryCode",
							title: "The Country Code of this Time Zone record.",
							label: "Country Code",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "UTCTimeOffset",
							title: "The Time Offset from the Coordinated Universal Time (UTC).",
							label: "UTC Time Offset",
							type: "text",
							width: 25,
							widthUnits: "%"
						},
						{
							name: "DaylightSavingTimeUTCTimeOffset",
							title: "The Time Offset when using Daylight Saving Time from the Coordinated Universal Time (UTC).",
							label: "Daylight Saving Time UTC Time Offset",
							type: "text",
							width: 25,
							widthUnits: "%"
						}
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_TimeZoneList",
					allowAdd: false
				}
			}, //--Time Zone List
			{
				// List of Tag Records with No AssetId defined
				setName: "TagsWithNoAssetIdDefined",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_ListOfValidTagRecordsWithNoAssetIdDefined",
					expandedStoredProcedure: true,
					label: "Tag Records with No AssetId Defined",
					entityName: "TagsWithNoAssetIdDefined",
					entityDisplayName: "Tags with No AssetId Defined",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordInsideRecursedSet: true,
					disableAddRecordOutsideRecursedSet: true,
					deleteStoredProcedure: "API.RDN_DeleteTagWithNoAssetIdDefined",
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number of this Tag with missing AssetId record.",
							type: "text",
							hidden: true
						},
						{
							name: "TagId",
							label: "Tag Id",
							title: "The Id number of this Tag record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Tag record.",
							type: "text",
							width: 32,
							widthUnits: "%"
						},
						{
							name: "StandardName",
							label: "Standard Name",
							title: "The Standard Name of this Tag record.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "JBTStandardObservationId",
							label: "Standard Obs Id",
							title: "The Standard Observation Id of this Tag record.",
							type: "text",
							hidden: true
						},
						{
							name: "AssetId",
							label: "AssetId",
							title: "The AssetId assigned to this Tag record.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "LastObservationDate",
							label: "Last Obs Date",
							title: "The Last Observation Date of this Tag record.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "LastObservationTextValue",
							label: "Last Obs Text Value",
							title: "The Last Observation Text Value of this Tag record.",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "EffectiveSeverityLevel",
							label: "Severity Level",
							title: "The Effective Severity Level of this Tag record.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					editStoredProcedureName: "API.RDN_EditRecord_dbo_TagsWithNoAssetIdDefined",
					saveStoredProcedureName: "API.RDN_SaveRecord_dbo_TagsWithNoAssetIdDefined",
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							title: "The Id field of this Tag record with a missing AssetId.",
							visible: false,
							type: "primary-key-field",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "TagId",
							label: "Id",
							title: "The ID number of this Tag record.",
							order: 2,
							type: "number"
						}),
						new ReadOnlyQuestion({
							key: "Name",
							label: "Name",
							title: "The Name of this Tag record.",
							order: 3,
							type: "text"
						}),
						new ReadOnlyQuestion({
							key: "StandardName",
							label: "Standard Observation Name",
							title: "The Standard Observation Name of this Tag record.",
							order: 4,
							type: "text"
						}),
						new TextboxQuestion({
							key: "AssetId",
							label: "Asset",
							title: "Enter the Asset to assign to this Tag record.",
							order: 6,
							type: "generic-selector",
							setName: "Assets",
							validateObject: true,
							required: true
						})
					]
				}
			}, // End List of Tag Records with No AssetId defined
			{  // Tag Standard Names
				setName: "TagStandardName",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_TagStandardNameList",
					expandedStoredProcedure: true,
					entityName: "TagStandardName",
					entityDisplayName: "Tag Standard Name",
					maxRows: 40,
					label: "Tag Standard Names",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id Number of this JBT Standard Observation.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "Name of this JBT Standard Observation.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Unit",
							label: "Unit of Measure",
							title: "The Unit of Measure for this Standard Observation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							title: "Description of this JBT Standard Observation.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationValueType",
							label: "Value Type",
							title: "The Value Type of this JBT Standard Observation.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "JBTStandardObservationSeverityLevel",
							label: "Severity Level",
							title: "The Severity Level of this JBT Standard Observation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Type",
							label: "Type",
							type: "text",
							title: "The Type of this JBT Standard Observation.",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "InternalType",
							label: "Internal Type",
							title: "The Internal Type of this JBT Standard Observation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Active",
							label: "Active",
							title: "Whether or not this JBT Standard Observation is Active.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ValueWhenActive",
							label: "Value When Active",
							title: "The Value of this JBT Standard Observation when it is considered Active (true), if different from 'true'.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ExcludeFromReporting",
							label: "Exclude From Reporting",
							title: "Whether or not this JBT Standard Observation record is excluded from reporting.",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							title: "The person who created this JBT Standard Observation record.",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							title: "The person who last modified this JBT Standard Observation record.",
							type: "last-modified-user",
							width: 14,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							title: "The date and time that this JBT Standard Observation record was created.",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							title: "The date and time that this JBT Standard Observation record was last modified.",
							type: "text",
							hidden: true
						},
						{
							name: "MinimumDeadbandSeconds",
							label: "Minimum Deadband in Seconds",
							title: "The minimum length of time in seconds before considering an observation assigned to this Standard Observation to wait until considered too frequent.  Setting this to zero means to ignore any tags assigned to this Standard Observation.",
							type: "number",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							title: "Id Number of this JBT Standard Observation.",
							type: "primary-key-field",
							label: "Id",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							title: "Enter the Name of this JBT Standard Observation.",
							label: "Name",
							required: true,
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							title: "The Description of this JBT Standard Observation.",
							label: "Description",
							order: 3
						}),
						new TextboxQuestion({
							key: "ValueWhenActive",
							label: "ValueWhenActive",
							title: "Enter the Value when Active, if different from the normal 1=True (Active) and 0=False (Inactive).",
							required: false,
							validateObject: false,
							allowBlankValue: true,
							order: 4
						}),
						new NumberQuestion({
							key: "MinimumDeadbandSeconds",
							label: "Minimum Deadband Seconds",
							title: "The minimum length of time in seconds before considering an observation assigned to this Standard Observation to wait until considered too frequent.  Setting this to zero means to ignore any tags assigned to this Standard Observation.",
							order: 5,
							required: false,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "Active",
							title: "Select whether or not this JBT Standard Observation should be considered 'Active'.",
							label: "Active",
							required: true,
							order: 6
						}),
						new SliderYesNoQuestion({
							key: "ExcludeFromReporting",
							title: "Select whether or not this JBT Standard Observation should be Excluded from Reporting. Standard Observations like 'Comms Loss' would qualify for this.",
							label: "Exclude from Reporting",
							required: false,
							order: 7
						}),
						new SliderYesNoQuestion({
							key: "AlarmStatusConfirmed",
							title: "Select whether or not the Fault Status of this JBT Standard Observation has been confirmed (validated).",
							label: "Fault Status Confirmed",
							required: false,
							order: 8
						}),
						new SliderYesNoQuestion({
							key: "ActivePeriodTracking",
							title: "Select whether or not we're in an Active Period of Tracking this JBT Standard Observation record.",
							label: "Active Period Tracking",
							required: false,
							order: 9
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationSeverityLevelId",
							label: "Severity Level",
							title: "Enter the severity level of this standard observation.",
							listOfValues: this.dataService.cache.jbtStandardObservationSeverityLevels,
							order: 10
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationValueTypeId",
							label: "Value Type",
							title: "Enter the value type of this standard observation.",
							listOfValues: this.dataService.cache.jbtStandardObservationValueTypes,
							order: 11
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationTypeId",
							label: "Type",
							title: "Enter the type of standard observation.",
							listOfValues: this.dataService.cache.jbtStandardObservationTypes,
							order: 12
						}),
						new UibButtonQuestion({
							key: "JBTStandardObservationInternalTypeId",
							label: "Internal Type",
							title: "Enter the internal type of standard observation, if applicable.",
							listOfValues: this.dataService.cache.jbtStandardObservationInternalTypes,
							order: 13
						}),
						new UibButtonQuestion({
							key: "UnitId",
							label: "Unit of Measure",
							title: "Please select the appropriate Unit of Measure for this standard observation.",
							listOfValues: this.dataService.cache.units,
							order: 14,
							required: false,
						}),
						new SliderYesNoQuestion({
							key: "Deleted",
							title: "Select whether or not this JBT Standard Observation should be considered 'Deleted'.",
							label: "Deleted",
							order: 14,
							visible: false
						})
					]
				},
				selector: {
					sqlStoredProcedure: "API.RDN_SelectorLookup_JBTStandardObservation",
					allowAdd: true
				}
			}, // End of Tag Standard Names
			{  // Tag Standard Name Types
				setName: "TagStandardNameTypes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_TagStandardNameTypesList",
					expandedStoredProcedure: true,
					entityName: "TagStandardNameType",
					entityDisplayName: "Tag Standard Name Type",
					maxRows: 40,
					label: "Tag Standard Name Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 37,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Marked for Deletion",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Tag Standard Name type.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this Tag Standard Name type.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this Tag Standard Name type is active.",
							order: 6
						})
					]
				}
			}, // End of Tag Standard Name Types
			{  // Tag Standard Name Internal Types
				setName: "TagStandardNameInternalTypes",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_TagStandardNameInternalTypesList",
					expandedStoredProcedure: true,
					entityName: "TagStandardNameInternalType",
					entityDisplayName: "Tag Standard Name Internal Type",
					maxRows: 40,
					label: "Tag Standard Name Internal Types",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							hidden: true
						},
						{
							name: "Name",
							label: "Name",
							type: "text",
							width: 20,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Description",
							label: "Description",
							type: "text",
							width: 37,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Deleted",
							label: "Marked for Deletion",
							type: "boolean",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							order: 1
						}),
						new TextboxQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this internal observation type.",
							order: 2
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this internal observation type.",
							order: 3
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active",
							title: "Select whether or not this internal observation type is active.",
							order: 6
						})
					]
				}
			}, // End of Tag Standard Name Internal Types
			{  // PropertiesByThingId
				setName: "PropertiesByThingId",
				tableDisplay: {
					apiUrl: Global.Data.thingApiUrl + "/PropertiesByThingId",
					label: "Properties",
					entityName: "IoT.ThingProperty",
					entityDisplayName: "Properties",
					allowDelete: false,
					allowModification: false,
					disableAddRecord: true,
					displayColumns: [
						{
							name: "name",
							label: "Key",
							title: "The Key of this key/value pair.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "value",
							label: "Value",
							title: "The Value of this key/value pair.",
							type: "text",
							width: 75,
							widthUnits: "%",
							sortable: true
						},
					]
				},
			}, // End of PropertiesByThingId
			{  // Things
				setName: "Things",
				tableDisplay: {
					apiUrl: Global.Data.thingApiUrl + "/ThingAssets",
					label: "Things",
					entityName: "IoT.Thing",
					entityDisplayName: "Thing",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					orderBy: {
						column: "Name",
						sortOrder: "asc"
					},
					displayColumns: [
						{
							name: "id",
							label: "Id",
							title: "The Id number of this Thing.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "name",
							label: "Name",
							title: "The Name of this Thing.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "type",
							label: "Type",
							title: "The Type of this Thing.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "latitude",
							label: "Latitude",
							title: "The Latitude of this Thing, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "longitude",
							label: "Longitude",
							title: "The Longitude of this Thing, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "typeId",
							label: "TypeId",
							title: "The Thing TypeId for this record.",
							type: "text",
							hidden: true
						},
						{
							name: "properties",
							label: "Properties",
							title: "The properties of this Thing.",
							type: "set",
							setName: "PropertiesByThingId",
							width: 12,
							widthUnits: "%",
							parentColumnLink: "id",
							childColumnLink: "Id"
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveApiUrl: Global.Data.thingApiUrl + "/SaveProperty",
					editApiUrl: Global.Data.thingApiUrl + "/PropertiesByThingId",
					deleteApiUrl: Global.Data.thingApiUrl + "/DeleteProperty",
					allowAddNewQuestion: true,
					fields: [
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id for the Thing record.",
							visible: false,
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "Name",
							label: "Name",
							title: "The name of this Thing.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Type",
							label: "Type",
							title: "The Type of this Thing.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "Latitude",
							label: "Latitude",
							title: "The latitude for this Thing, if applicable.",
							order: 4
						}),
						new ReadOnlyQuestion({
							key: "Longitude",
							label: "Longitude",
							title: "The longitude for this Thing, if applicable.",
							order: 5
						}),
						new TextboxQuestion({
							key: "Description",
							label: "Description",
							title: "Enter the description of this Thing.",
							order: 5
						}),
						new TextboxQuestion({
							key: "TimeZoneListId",
							label: "Time Zone",
							title: "Select the appropriate Time Zone for this Site. Note that the displayed UTC Time Offset is taking into account if we're in the Daylight Saving Time season, and also whether or not the selected Time Zone uses Daylight Saving Time.",
							order: 6,
							type: "generic-selector",
							setName: "TimeZoneList",
							validateObject: true,
							required: false
						}),
						new TextboxQuestion({
							key: "TagNameFormat",
							label: "Tag Name Format",
							title: "Enter the correct pattern for the name of the Tag records for this site. The system will use the @SiteName|@SystemName|@AssetName|@TagSimpleName if you don't need to set this.",
							placeholder: "@SiteName|@SystemName|@AssetName|@TagSimpleName",
							order: 7
						}),
						new TextboxQuestion({
							key: "PCAPerformanceTemperatureThreshold",
							label: "PCA Performance Temperature Threshold",
							title: "Enter the temperature threshold for when the Discharge Temperature is too high for the air conditioner to be considered 'meeting performance'.",
							order: 8,
							allowBlankValue: true
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Is Active",
							title: "Is this Site active?",
							order: 9
						}),
						new SliderYesNoQuestion({
							key: "HasGates",
							label: "Has Gates?",
							title: "Does this Site have gate systems?",
							order: 10
						}),
						new SliderYesNoQuestion({
							key: "EnableFlightData",
							label: "Enable Flight Data?",
							title: "Does this Site need flight data?",
							order: 11
						}),
						new NumberQuestion({
							key: "FlightDataSamplingIntervalMinutes",
							label: "Flight Data Sampling Interval (in minutes)",
							title: "Enter how frequently to get Flight Data for this site.",
							order: 12,
							min: 0,
							max: 60,
							visibleFields: [
								{
									field: "EnableFlightData",
									operator: "==",
									value: true
								} //-- if the Site is enabled to get flight data, then show this field.
							]
						}),
						new SliderYesNoQuestion({
							key: "HasBaggageHandling",
							label: "Has Baggage Handling?",
							title: "Does this site have Baggage Handling Systems?",
							order: 13
						}),

					]
				}
			}, // End of Things
			{  // ThingSystems
				setName: "ThingSystems",
				tableDisplay: {
					apiUrl: Global.Data.thingApiUrl + "/ThingSystems",
					label: "Systems",
					entityName: "IoT.Thing",
					entityDisplayName: "Systems",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					orderBy: {
						column: "Name",
						sortOrder: "asc"
					},
					displayColumns: [
						{
							name: "id",
							label: "Id",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "systemType",
							label: "System Type",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "site",
							label: "Site",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "name",
							label: "Gate or System Name",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "description",
							label: "Description",
							type: "text",
							width: 20,
							widthUnits: "%"
						},
						{
							name: "doNotDisplay",
							label: "Do Not Display",
							type: "boolean",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "ownerOrganization",
							label: "Owner Organization",
							type: "text",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "assets",
							label: "Assets",
							type: "set",
							setName: "Assets",
							width: 12,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "ParentSystemId"
						},
						{
							name: "creatorUser",
							label: "Created By",
							type: "creator-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "lastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "creationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "lastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveApiUrl: Global.Data.thingApiUrl + "/SaveProperty",
					editApiUrl: Global.Data.thingApiUrl + "/PropertiesByThingId",
					deleteApiUrl: Global.Data.thingApiUrl + "/DeleteProperty",
					allowAddNewQuestion: true,
					fields: [
						new ReadOnlyQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this System.",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "Latitude",
							label: "Latitude",
							title: "Enter the latitude for this System.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Longitude",
							label: "Longitude",
							title: "Enter the longitude for this System.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "OriginalEntityId",
							label: "OriginalEntityId",
							type: "primary-key-field",
							order: 4
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Enter the site where this system is located.",
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							order: 5
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this system.",
							order: 6
						}),
						new TextboxQuestion({
							key: "BaseUnitImageURL",
							label: "Base Unit Image URL",
							title: "Enter the URL for the base unit image.",
							order: 7
						}),
						new SliderYesNoQuestion({
							key: "DoNotDisplay",
							label: "Do Not Display",
							title: "Select whether or not this system should be displayed.",
							order: 8
						}),
						new FileUploadQuestion({
							key: "BaseUnitGraphicImageKey",
							label: "System Graphic",
							title: "Upload the system's graphic here.",
							order: 9
						})
					]
				}
			}, // End of ThingSystems
			{  // ThingAssets
				setName: "ThingAssets",
				tableDisplay: {
					apiUrl: Global.Data.thingApiUrl + "/ThingAssets",
					label: "Assets",
					entityName: "IoT.Thing",
					entityDisplayName: "Assets",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					orderBy: {
						column: "Name",
						sortOrder: "asc"
					},
					displayColumns: [
						{
							name: "id",
							label: "Id",
							type: "text",
							title: "Id number of this Asset.",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "site",
							label: "Site",
							title: "Site location of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "gate",
							label: "Gate or System",
							title: "Gate or System of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "originalName",
							label: "Asset Name",
							title: "Name of this Asset.",
							type: "text",
							width: 6,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "preferredName",
							label: "Preferred Name",
							title: "The Preferred Name of this Asset, if applicable.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "assetType",
							label: "Asset Type",
							title: "The type of Asset.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "model",
							label: "Model",
							title: "The Model of this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "modemNumber",
							label: "Modem Number",
							title: "The modem number for this asset, if applicable.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "pLCProgramRevision",
							label: "PLC Program",
							title: "PLC Program / Revision being used by this Asset.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "active",
							label: "Active",
							title: "Whether or not this Asset is active.",
							type: "boolean",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "tags",
							label: "Tags",
							title: "The list of Tags for this Asset.",
							type: "set",
							setName: "Tag",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "AssetId"
						},
						{
							name: "ownerOrganization",
							label: "Owner Organization",
							title: "The Organization that owns this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "operatorOrganization",
							label: "Operator Organization",
							title: "The Organization that operates (uses) this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "manufacturerOrganization",
							label: "Manufacturer Organization",
							title: "The Organization that manufactured this Asset.",
							type: "text",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "adveezAssetId",
							label: "Adveez Asset Id",
							title: "The Adveez Asset Id of this asset, if applicable.",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveApiUrl: Global.Data.thingApiUrl + "/SaveProperty",
					editApiUrl: Global.Data.thingApiUrl + "/PropertiesByThingId",
					deleteApiUrl: Global.Data.thingApiUrl + "/DeleteProperty",
					allowAddNewQuestion: true,
					fields: [
						new ReadOnlyQuestion({
							key: "Name",
							label: "Name",
							title: "Enter the name of this Asset.",
							order: 1
						}),
						new ReadOnlyQuestion({
							key: "Latitude",
							label: "Latitude",
							title: "Enter the latitude for this Asset.",
							order: 2
						}),
						new ReadOnlyQuestion({
							key: "Longitude",
							label: "Longitude",
							title: "Enter the longitude for this Asset.",
							order: 3
						}),
						new ReadOnlyQuestion({
							key: "OriginalEntityId",
							label: "OriginalEntityId",
							type: "primary-key-field",
							order: 4
						}),
						new TextareaQuestion({
							key: "Description",
							label: "Description",
							title: "Enter a detailed description of this asset.",
							order: 5
						}),
						new TextboxQuestion({
							key: "ModemNumber",
							label: "Modem Number",
							title: "Enter the Modem Number of this Asset, if applicable.",
							order: 6,
							type: "text",
							required: false,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "contains",
									value: "AssetTypeId",
									valuesToCheck: "gseAssetTypeIds",
									type: "Id"
								} //-- if the current AssetTypeId is in the list of valid GSE Asset Ids on the RDN component.
							]
						}),
						new TextboxQuestion({
							key: "PreferredName",
							label: "Preferred Name",
							title: "Enter the preferred Name of the asset, if applicable. Please do not change from the original Asset Name field value if it is appropriate. Only enter a value here if the name needs to be changed.",
							order: 7,
							type: "text",
							required: false,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the appropriate Type for this Asset.",
							order: 8,
							type: "generic-selector",
							setName: "AssetType",
							validateObject: true,
							required: true
						}),
						new TextboxQuestion({
							key: "AssetModelId",
							label: "Asset Model",
							title: "Select the appropriate Model for this Asset.",
							order: 9,
							type: "generic-selector",
							setName: "AssetModel",
							validateObject: true,
							allowBlankValue: true,
							required: false
						}),
						new TextboxQuestion({
							key: "SiteId",
							label: "Site",
							title: "Select the appropriate Site where this Asset is located.",
							order: 10,
							type: "generic-selector",
							setName: "Sites",
							validateObject: true,
							required: true
						}),
						new SliderYesNoQuestion({
							key: "HasElevatorRotunda",
							label: "Has Elevating Rotunda?",
							title: "Does this asset have an Elevating Rotunda?",
							order: 11
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Active?",
							title: "Should this Asset be considered Active?",
							order: 12
						}),
						new DateQuestion({
							key: "RetireDate",
							label: "Date of Inactivation",
							title: "The Date this asset was set to Inactive.",
							visibleFields: [
								{
									field: "Active",
									operator: "==",
									value: false
								} //-- if this Asset was set to Active = false.
							],
							order: 13
						}),
						new DateQuestion({
							key: "InServiceDate",
							label: "In Service Date",
							title: "The Date this asset placed in service.",
							order: 14
						}),
						new TextboxQuestion({
							key: "OwnerOrganizationId",
							label: "Owner Organization",
							title: "Select the appropriate Organization that Owns this Asset.",
							order: 15,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "OperatorOrganizationId",
							label: "Operator Organization",
							title: "Select the appropriate Organization that Operates (or uses) this Asset.",
							order: 16,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "ManufacturerOrganizationId",
							label: "Manufacturer Organization",
							title: "Select the appropriate Organization that Manufactured this Asset.",
							order: 17,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "MaintenanceOrganizationId",
							label: "Maintenance Organization",
							title: "Select the appropriate Organization that will maintain this Asset.",
							order: 18,
							type: "generic-selector",
							setName: "Organization",
							validateObject: true,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "PLCProgramRevisionId",
							title: "Select the appropriate PLC (Programmable Logic Controller) Software Program Revision that communicates with this Asset.",
							label: "PLC Program Revision",
							order: 19,
							type: "generic-selector",
							setName: "PLCProgramRevisions",
							validateObject: false,
							allowBlankValue: true
						}),
						new TextboxQuestion({
							key: "AdveezAssetId",
							title: "The Id number of the Adveez Asset record, if applicable.",
							label: "Adveez Asset Name",
							order: 20,
							type: "generic-selector",
							setName: "AdveezAsset",
							validateObject: true,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "!=",
									value: 1
								} //-- if the current AssetTypeGroupId is not a gate system asset, then show this field.
							],
						}),
						new SliderYesNoQuestion({
							key: "GenerateDockedTagFromAutolevelDeployedTag",
							label: "Generate Docked Tag from Autolevel Deployed Tag?",
							title: "Will this asset only use Autolevel Deployed status for generating an Aircraft Docked tag?",
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: 3
								} //-- if this Asset is a PBB asset.
							],
							order: 21
						}),
						new ReadOnlyQuestion({
							key: "ParentSystemId",
							label: "System",
							type: "parent-key-field",
							title: "The System where this is referenced.",
							listOfValues: this.dataService.cache.systems,
							order: 22,
							visible: false
						})
					]
				}
			}, // End of ThingAssets
			{  // Calculation.Rule
				setName: "Rules",
				tableDisplay: {
					//dataCacheCollection: this.dataService.cache.calculationRules,
					sqlStoredProcedure: "API.RDN_Rules_List",
					expandedStoredProcedure: true,
					entityName: "Calculation.Rule",
					entityDisplayName: "Rule",
					label: "Rules",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Rule.",
							width: 3,
							widthUnits: "%"
						},
						{
							name: "RuleName",
							label: "Name",
							title: "The Name of this Rule.",
							type: "text",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OutputTagSimpleName",
							label: "Output Tag Simple Name",
							title: "The Tag Name of the calculated observation created by this Rule.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OutputTrueValue",
							label: "Output True Value",
							title: "The Output True Value created by this Rule calculation.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "OutputFalseValue",
							label: "Output False Value",
							title: "The Output False Value created by this Rule calculation.",
							type: "text",
							width: 3,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CalculationType",
							label: "Calculation Type",
							title: "The Calculation Type used for the conditions to perform this Rule calculation.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "RuleConditions",
							label: "Conditions",
							title: "The Conditions of this Rule in order for it to be evaluated as 'true'.",
							type: "set",
							setName: "RuleConditions",
							width: 18,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "RuleId"
						},
						{
							name: "RuleTags",
							label: "Tags",
							title: "The list of Tags created from this Rule calculation.",
							type: "set",
							setName: "RuleTags",
							width: 5,
							widthUnits: "%",
							parentColumnLink: "Id",
							childColumnLink: "RuleId"
						},
						{
							name: "AssetTypeGroup",
							label: "Asset Type Group",
							title: "The Types of Assets that this Rule calculation will apply.",
							type: "text",
							width: 5,
							widthUnits: "%",
						},
						{
							name: "AssetType",
							label: "Asset Type",
							title: "The specific Asset Type where this Rule calculation will apply.",
							type: "text",
							width: 5,
							widthUnits: "%",
						},
						{
							name: "Active",
							label: "Active",
							type: "boolean",
							title: "Whether or not this Rule calculation is Active.",
							width: 5,
							widthUnits: "%",
							sortable: false
						},
						{
							name: "ConditionsInEnglish",
							label: "List of Conditions",
							title: "List of Conditions for this Rule to be calculated.",
							type: "text",
							hidden: true
						},
						{
							name: "ConditionsWithDescriptionsInEnglish",
							label: "List of Conditions with Descriptions",
							title: "List of Conditions with Descriptions for this Rule to be calculated.",
							type: "text",
							hidden: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this Environment.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this Environment.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Calculation_Rule",
					editStoredProcedureName: "API.RDN_EditRecord_Calculation_Rule",
					fields: [
						new TextboxQuestion({
							key: "RuleName",
							label: "Name",
							title: "Enter the Name of this Rule calculation.",
							order: 1,
							type: "text",
							required: true
						}),
						new TextboxQuestion({
							key: "OutputTagSimpleName",
							label: "Output Tag Name",
							title: "Enter the Tag Name to be generated when executing this Rule calculation.",
							order: 3,
							type: "text",
							required: true
						}),
						new TextboxQuestion({
							key: "OutputTrueValue",
							label: "Output True Value",
							title: "Enter the True Value to be generated when executing this Rule calculation, if applicable.",
							order: 4,
							type: "text",
							required: false
						}),
						new TextboxQuestion({
							key: "OutputFalseValue",
							label: "Output False Value",
							title: "Enter the False Value to be generated when executing this Rule calculation, if applicable.",
							order: 5,
							type: "text",
							required: false
						}),
						new SliderYesNoQuestion({
							key: "Active",
							label: "Is Active?",
							title: "Is this Rule set to Active to process its calculations?",
							order: 6
						}),
						new UibButtonQuestion({
							key: "CalculationTypeId",
							label: "Calculation Type",
							title: "Select the evaluation (calculation) type used when executing this Rule calculation.",
							listOfValues: this.dataService.cache.calculationTypes,
							order: 7,
							required: true
						}),
						new UibButtonQuestion({
							key: "AssetTypeGroupId",
							label: "Asset Type Group",
							title: "Select the appropriate Asset Type Group that will be affected when executing this Rule calculation.  If this rule applies to all assets, or the rule is specific to a certain Asset Type, then leave these buttons unselected.",
							listOfValues: this.dataService.cache.assetTypeGroups,
							order: 8,
							required: false,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeId",
									operator: "==",
									value: null
								}
							],

						}),
						new UibButtonQuestion({
							key: "AssetTypeId",
							label: "Asset Type",
							title: "Select the specific Asset Type that will be affected when executing this Rule calculation.  If this rule applies to all assets, or the rule is applicable to a number of Asset Types in an Asset Type Group, then leave these buttons unselected.",
							listOfValues: this.dataService.cache.assetTypes,
							order: 9,
							required: false,
							allowBlankValue: true,
							visibleFields: [
								{
									field: "AssetTypeGroupId",
									operator: "==",
									value: null
								}
							],

						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Rule.",
							order: 10,
							visible: false
						})
					]
				}
			}, // End of Calculation.Rule
			{  // Rule Conditions (aka Calculation.Operation)
				setName: "RuleConditions",
				tableDisplay: {
					//dataCacheCollection: this.dataService.cache.calculationRuleOperations,
					sqlStoredProcedure: "API.RDN_Rules_ListOfRuleConditions",
					expandedStoredProcedure: true,
					entityName: "Calculation.Operation",
					entityDisplayName: "Rule Condition",
					label: "Rule Conditions",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecordOutsideRecursedSet: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							type: "text",
							title: "Id number of this Calculation Operation / Rule Condition.",
							hidden: true
						},
						{
							name: "RuleId",
							label: "Rule Id",
							type: "text",
							title: "Id number of the Calculation Rule.",
							width: 10,
							widthUnits: "%"
						},
						{
							name: "StandardId",
							label: "Standard Id",
							type: "text",
							title: "Id number of the Standard Observation Record.",
							hidden: true
						},
						{
							name: "StandardIdAsText",
							label: "Standard Id",
							type: "text",
							title: "Id number of the Standard Observation Record for this Calculation Operation / Rule Condition.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Operator",
							label: "Operator",
							title: "The Type of Operator for this Calculation Operation / Rule Condition.",
							type: "text",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Value",
							label: "Values to Watch",
							title: "The Values of the Standard Observation to watch for this Calculation Operation / Rule Condition.",
							type: "text",
							width: 32,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "DebounceSeconds",
							label: "Wait Time in Seconds",
							title: "The length of time to wait before the Standard Observation to watch for this Calculation Operation / Rule Condition is considered 'true'.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreatorUser",
							label: "Created By",
							type: "creator-user",
							title: "The Person who created this observation to Watch for this Calculation Operation / Rule Condition.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "LastModifiedUser",
							label: "Last Modified By",
							type: "last-modified-user",
							title: "The Person who last modified this observation to Watch for this Calculation Operation / Rule Condition.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "CreationDate",
							label: "Date Created",
							type: "text",
							hidden: true
						},
						{
							name: "LastModifiedDate",
							label: "Date Last Modified",
							type: "text",
							hidden: true
						},
						Global.User.isAdmin ? this.commonColumnStructures.actions : ""
					]
				},
				editor: {
					saveStoredProcedureName: "API.RDN_SaveRecord_Calculation_Operation",
					editStoredProcedureName: "API.RDN_EditRecord_Calculation_Operation",
					fields: [
						new TextboxQuestion({
							key: "StandardId",
							label: "Standard Name",
							title: "The Standard Name record to watch for this Calculation Operation.",
							type: "generic-selector",
							setName: "JBTStandardObservation",
							validateObject: true,
							allowBlankValue: true,
							order: 1,
							required: true
						}),
						new UibButtonQuestion({
							key: "OperatorTypeId",
							label: "Operator",
							title: "Select the Operator for evaluating the Standard Observation value.",
							listOfValues: this.dataService.cache.calculationOperatorTypes,
							order: 2,
							required: true
						}),
						new TextboxQuestion({
							key: "Value",
							label: "Values to Watch",
							title: "Enter the Values to watch as a comma-separated list.",
							order: 3,
							type: "text",
							required: true
						}),
						new NumberQuestion({
							key: "DebounceSeconds",
							label: "Number of Seconds to Wait while True",
							title: "Choose the length of time to wait while true before calculating this operation as a true condition.",
							order: 4,
							allowBlankValue: true,
							required: false
						}),
						new ReadOnlyQuestion({
							key: "Id",
							label: "Id",
							type: "primary-key-field",
							title: "Id of this Calculation Rule Operation.",
							order: 5,
							visible: false
						}),
						new ReadOnlyQuestion({
							key: "RuleId",
							label: "Rule Id",
							type: "parent-key-field",
							title: "Rule Id of the parent Calculation.Rule record.",
							order: 6,
							visible: false
						}),
					]
				}
			}, // End of Rule Conditions
			{  // Rule Tags
				setName: "RuleTags",
				tableDisplay: {
					sqlStoredProcedure: "API.RDN_Rules_GetTagsGeneratedByRule",
					expandedStoredProcedure: true,
					maxRows: 10,
					label: "Tags Generated By Rule",
					entityName: "Tag",
					entityDisplayName: "Tag",
					allowDelete: Global.User.isAdmin,
					allowModification: Global.User.isAdmin,
					disableAddRecord: true,
					displayColumns: [
						{
							name: "Id",
							label: "Id",
							title: "The Id number associated with this Tag record.",
							type: "text",
							width: 5,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "Name",
							label: "Name",
							title: "The Name of this Tag record.",
							type: "text",
							width: 25,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "AssetName",
							label: "Asset Name",
							type: "text",
							title: "The Name of Asset with this Tag record.",
							width: 10,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "StandardName",
							label: "Standard Name",
							type: "text",
							title: "The Standard ID and Name associated with this Tag record.",
							width: 15,
							widthUnits: "%",
							sortable: true
						},
						{
							name: "ValueWhenActive",
							label: "Value When Active",
							title: "The Value this tag is sending out when it should be considered 'active'.  This would be overriding the default, which is 1=True (Active) and 0=False (Inactive).",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "EffectiveSeverityLevel",
							label: "Severity",
							title: "The effective Severity Level of this observation, based on the Tag settings, and then the Standard Name settings.",
							type: "text",
							width: 5,
							widthUnits: "%"
						},
						{
							name: "LastObservationDate",
							label: "Last Observation Date",
							title: "The last observation date and time of this record.",
							type: "date",
							width: 15,
							widthUnits: "%"
						},
						{
							name: "LastObservationTextValue",
							label: "Last Observation Text Value",
							title: "The last observation text value of this record.",
							type: "text",
							width: 20,
							widthUnits: "%"
						}
					]
				},
			}  // End of Rule Tags
		];
	}
}
