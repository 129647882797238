<div class="card card-tasks widget-card">
	<div
		*ngIf="dialogModalInputObject.WidgetName === 'tag-graph'"
		class="card-header widget-card-header"
		style="cursor: default; padding-top: 0px"
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">
				<strong>{{ dialogModalInputObject.widgetNameDisplay }}</strong>

			</h6>
		</div>
		<div style="display: inline-block; padding-top: 15px">
			<button
				*ngIf="
					dialogModalInputObject.preventFromAddingToDashboard !== true
				"
				class="btn btn-fill btn-primary"
				(click)="addTagToChart(dialogModalInputObject.TagId)"
				style="padding: 3px 6px; margin: 0px"
			>
				<div
					*ngIf="
						!dataService.tempTagsToChart.includes(
							dialogModalInputObject.TagId
						)
					"
				>
					<i class="fas fa-chart-line" [style.color]="'white'"></i
					><span style="display: inline"> Add Tag To Collection</span>
				</div>
				<div
					*ngIf="
						dataService.tempTagsToChart.includes(
							dialogModalInputObject.TagId
						)
					"
				>
					<i class="fas fa-check" [style.color]="'white'"></i>
					<span style="display: inline">
						Remove Tag From Collection</span
					>
				</div>
			</button>
		</div>

		<div style="display: inline; margin-left: 20px; padding-top: 15px">
			<mat-radio-group [(ngModel)]="dialogModalInputObject.timeZoneType">
				<mat-radio-button
					style="margin-right: 10px"
					class="example-margin"
					[value]="'User Time'"
					(change)="radioChange('User Time')"
					>User Time</mat-radio-button
				>
				<mat-radio-button
					class="example-margin"
					[value]="'Site Time'"
					(change)="radioChange('Site Time')"
					>Site Time</mat-radio-button
				>
				<mat-radio-button
					class="example-margin"
					[value]="'UTC Time'"
					(change)="radioChange('UTC Time')"
					>UTC Time</mat-radio-button
				>
			</mat-radio-group>
		</div>
		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>

		<button
			mat-button
			style="padding-top: 5px; float: right"
			(click)="fullScreen()"
			matTooltip="Full Screen"
			[matTooltipPosition]="'below'"
		>
			<i [hidden]="this.isFullScreen" class="fas fa-expand"></i>
			<i [hidden]="!this.isFullScreen" class="fas fa-compress"></i>
		</button>

		<div
			style="
				display: inline;
				float: right;
				padding-right: 10px;
				padding-top: 15px;
				margin-top: -5px;
			"
		>
			<lib-live-data
				[live]="
					dashboardService.determineWhatTimeScopeForWidget(
						dialogModalInputObject
					) === 'Custom' &&
					dialogModalInputObject.WidgetName === 'tag-graph'
						? false
						: dialogModalInputObject.isDisplayDataLive === undefined
						? false
						: dialogModalInputObject.isDisplayDataLive
				"
			></lib-live-data>
		</div>

		<div
			*ngIf="
				dashboardService.evaluateAngularDirectiveNameForTimeScopeOption(
					'dialogPopupWidget',
					dialogModalInputObject
				)
			"
			style="
				display: inline;
				float: right;
				padding-right: 0px;
				padding-top: 15px;
			"
		>
			<h6 class="title" style="display: inline-block">
				{{
					dashboardService.determineWhatTimeScopeForWidget(
						dialogModalInputObject
					)
				}}
			</h6>
		</div>
	</div>
	<div
		*ngIf="dialogModalInputObject.WidgetName === 'GSE-Summary'"
		class="card-header widget-card-header"
		style="cursor: default; padding-top: 0px !important"
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">
				{{ dialogModalInputObject.widgetNameDisplay }}:
				<span *ngIf="dialogModalInputObject.siteName != null"
					>:&nbsp;&nbsp;<span
						style="color: lightseagreen; text-transform: uppercase"
						><strong
							>{{ dialogModalInputObject.siteName }}
						</strong></span
					></span
				>
				<span *ngIf="dialogModalInputObject.gseName != null"
					>:&nbsp;&nbsp;<span
						style="color: lightseagreen; text-transform: uppercase"
						><strong>{{
							dialogModalInputObject.gseName
						}}</strong></span
					></span
				>
				<span *ngIf="!dialogModalInputObject.gseName"
					>&nbsp;<strong
						><span style="text-transform: uppsercase"
							>GSE-Summary for
							{{ dialogModalInputObject.widgetObject.Name }}</span
						><span
							*ngIf="
								dialogModalInputObject.WidgetSiteName != null
							"
							>:&nbsp;&nbsp;<strong>{{
								widget.WidgetSiteName
							}}</strong></span
						></strong
					></span
				>
			</h6>
		</div>
		<div style="display: inline-block; padding-top: 15px">
			<button
				class="btn btn-fill btn-primary"
				type="submit"
				(click)="createGSESummaryWidget()"
				style="padding: 0px 6px 0px 0px; margin: 0px"
			>
				<i style="font-size: 10px">Create Widget</i>
			</button>
		</div>

		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>
		<span>
			<lib-widget-settings
				[widgetObject]="{
					AngularDirectiveName: 'gseSummary',
					AssetId: dialogModalInputObject.widgetObject.Id
				}"
				[currentDashboard]="dashboardService.currentDashboard"
			></lib-widget-settings>
		</span>

		<button
			mat-button
			style="padding-top: 5px; float: right"
			(click)="fullScreen()"
			matTooltip="Full Screen"
			[matTooltipPosition]="'below'"
		>
			<i [hidden]="this.isFullScreen" class="fas fa-expand"></i>
			<i [hidden]="!this.isFullScreen" class="fas fa-compress"></i>
		</button>

		<button mat-button
				style="display: inline; float: right; padding-top: 5px;">
			<i class="tim-icons icon-bell-55 clipboard-larger" [ngStyle]="alertNotificationColor" style="cursor: pointer"
				[matTooltip]="this.alertNotificationTitle" (click)="openAlertsPopup()"></i>
		</button>

		<button
			mat-button
			style="float: right; padding-top: 5px"
			(click)="openNotesPopup(dialogModalInputObject.widgetObject)"
		>
			<mat-icon
				[matBadge]="
					this.dataService.evaluateNotesForWidget(
						dialogModalInputObject.widgetObject,
						'dialog'
					)
				"
				[matBadgeHidden]="
					this.dataService.evaluateNotesForWidget(
						dialogModalInputObject.widgetObject,
						'dialog'
					) == 0
				"
				>notes</mat-icon
			>
		</button>

		<button
			mat-button
			style="float: right; padding-top: 5px"
			matTooltip="Upload File"
			[matTooltipPosition]="'below'"
			(click)="openFileImageUploadWindow()"
			*ngIf="!global.isMobile"
		>
			<i
				[ngClass]="{
					'object-contains-files':
						dialogModalInputObject.CountOfFilesUploaded >= 1
				}"
				class="tim-icons icon-notes clipboard-larger"
				aria-hidden="true"
				style="cursor: pointer"
				title="View files attached to this Asset.  {{
					dialogModalInputObject.CountOfFilesUploaded > 0
						? dialogModalInputObject.CountOfFilesUploaded +
						  ' file' +
						  (dialogModalInputObject.CountOfFilesUploaded > 1
								? 's'
								: '') +
						  ' uploaded currently.'
						: ''
				}}"
			>
			</i>
		</button>
		<!-- this.dialogModalInputObject.CountOfFilesUploaded -->
		<div
			style="
				display: inline;
				float: right;
				padding-right: 10px;
				padding-top: 15px;
				margin-top: -5px;
			"
		>
			<lib-live-data
				[live]="
					dialogModalInputObject.isDisplayDataLive === undefined
						? false
						: dialogModalInputObject.isDisplayDataLive
				"
			></lib-live-data>
		</div>
		<div
			*ngIf="
				dashboardService.evaluateAngularDirectiveNameForTimeZoneOption(
					'dialogPopupWidget',
					dialogModalInputObject
				)
			"
			style="
				display: inline;
				float: right;
				padding-right: 0px;
				padding-top: 15px;
			"
		>
			<h6
				[matTooltip]="
					dashboardService.determineTimeZoneType(
						dialogModalInputObject
					) === 'User Time'
						? 'Information in this widget is formatted to be in the timezone of your computer.'
						: dashboardService.determineTimeZoneType(
								dialogModalInputObject
						  ) === 'Site Time'
						? 'Information in this widget is formatted to be in the timezone of the site the tag belongs to.'
						: dashboardService.determineTimeZoneType(
								dialogModalInputObject
						  ) === 'UTC Time'
						? 'Information in this widget is formatted to UTC Time, with no offset for a timezone.'
						: ''
				"
				[matTooltipPosition]="'below'"
				class="title"
				style="padding-top: 0px; display: inline-block"
			>
				{{
					dashboardService.determineTimeZoneType(
						dialogModalInputObject
					)
				}}
			</h6>
		</div>
	</div>
	<div
		*ngIf="
			dialogModalInputObject.WidgetName === 'Kendo-Grid' ||
			dialogModalInputObject.WidgetName === 'Kendo-Chart' ||
			dialogModalInputObject.WidgetName === 'WCL-Summary' ||
			dialogModalInputObject.WidgetName === 'CU-Summary' ||
			dialogModalInputObject.WidgetName === 'GPU-Summary' ||
			dialogModalInputObject.WidgetName === 'AHU-Summary' ||
			dialogModalInputObject.WidgetName === 'PBB-Summary' ||
			dialogModalInputObject.WidgetName === 'PCA-Summary' ||
			dialogModalInputObject.WidgetName ===
				'GS-Top-Five-Alarms-Summary' ||
			dialogModalInputObject.WidgetName ===
				'GS-Top-Five-Equipment-Alarms-Summary' ||
			dialogModalInputObject.WidgetName ===
				'GSE-Top-Five-Equipment-Alarms-Summary' ||
			dialogModalInputObject.WidgetName === 'GSE-Top-Five-Alarms-Summary'
		"
		class="card-header widget-card-header"
		style="cursor: default; padding-top: 0px"
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">
				{{ dialogModalInputObject.widgetNameDisplay }}
				<span
					*ngIf="
						!dialogModalInputObject.siteName ||
						!dialogModalInputObject.widgetNameDisplay ||
						dialogModalInputObject.popupTitle != null
					"
					>&nbsp;<strong>{{
						dialogModalInputObject.popupTitle
					}}</strong></span
				>
				<span
					*ngIf="
						dialogModalInputObject.siteName != null &&
						dialogModalInputObject.siteName !== undefined
					"
					>&nbsp;&nbsp;<span style="color: lightseagreen"
						><strong
							>{{ dialogModalInputObject.siteName }}
						</strong></span
					></span
				>
				<span
					*ngIf="
						dialogModalInputObject.WidgetName !== 'CU-Summary' &&
						dialogModalInputObject.gateSystemName != null &&
						dialogModalInputObject.gateSystemName !== undefined
					"
					>&nbsp;<strong
						>Gate&nbsp;&nbsp;{{
							dialogModalInputObject.gateSystemName
						}}</strong
					></span
				>
				<span
					*ngIf="
						dialogModalInputObject.WidgetName === 'CU-Summary' &&
						dialogModalInputObject.gateSystemName != null &&
						dialogModalInputObject.gateSystemName !== undefined
					"
					>&nbsp;<strong
						>&nbsp;{{
							dialogModalInputObject.gateSystemName
						}}</strong
					></span
				>
			</h6>
		</div>
		<div style="display: inline-block; padding-top: 15px">
			<button
				*ngIf="
					dialogModalInputObject.WidgetName !==
						'GS-Top-Five-Alarms-Summary' &&
					dialogModalInputObject.WidgetName !==
						'GS-Top-Five-Equipment-Alarms-Summary' &&
					dialogModalInputObject.WidgetName !==
						'GSE-Top-Five-Equipment-Alarms-Summary' &&
					dialogModalInputObject.WidgetName !==
						'GSE-Top-Five-Alarms-Summary' &&
					dialogModalInputObject.canCreateDashboardWidgetFromPopup !==
						false
				"
				class="btn btn-fill btn-primary"
				type="submit"
				(click)="createAssetSummaryWidget()"
				style="padding: 3px 6px; margin: 0px"
			>
				<i style="font-size: 10px">Add to Dashboard</i>
			</button>
		</div>

		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>

		<button
			mat-button
			style="padding-top: 5px; float: right"
			(click)="fullScreen()"
			matTooltip="Full Screen"
			[matTooltipPosition]="'below'"
		>
			<i [hidden]="this.isFullScreen" class="fas fa-expand"></i>
			<i [hidden]="!this.isFullScreen" class="fas fa-compress"></i>
		</button>

		<button
			mat-button
			*ngIf="
				dialogModalInputObject.WidgetName === 'GPU-Summary' ||
				dialogModalInputObject.WidgetName === 'AHU-Summary' ||
				dialogModalInputObject.WidgetName === 'PBB-Summary' ||
				dialogModalInputObject.WidgetName === 'PCA-Summary'
			"
			style="display: inline; float: right; padding-top: 5px"
		>
			<i
				class="tim-icons icon-bell-55 clipboard-larger"
				[ngStyle]="alertNotificationColor"
				style="cursor: pointer"
				[matTooltip]="this.alertNotificationTitle"
				(click)="openAlertsPopup()"
			></i>
		</button>

		<button mat-button  *ngIf="dialogModalInputObject.WidgetName === 'GPU-Summary' || dialogModalInputObject.WidgetName === 'AHU-Summary' || dialogModalInputObject.WidgetName === 'PBB-Summary' || dialogModalInputObject.WidgetName === 'PCA-Summary'"
				style="float: right;  padding-top: 5px" (click)="openNotesPopupForAsset(dialogModalInputObject.assetObj)">
			<mat-icon [matBadge]="this.dataService.evaluateNotesForWidget(this.dialogModalInputObject.assetObj, 'dialog')"
						[matBadgeHidden]="this.dataService.evaluateNotesForWidget(this.dialogModalInputObject.assetObj, 'dialog') == 0">notes</mat-icon>
		</button>

		<button
			mat-button
			*ngIf="
				dialogModalInputObject.WidgetName !==
					'GS-Top-Five-Alarms-Summary' &&
				dialogModalInputObject.WidgetName !==
					'GS-Top-Five-Equipment-Alarms-Summary' &&
				dialogModalInputObject.WidgetName !==
					'GSE-Top-Five-Equipment-Alarms-Summary' &&
				dialogModalInputObject.WidgetName !==
					'GSE-Top-Five-Alarms-Summary' &&
				dialogModalInputObject.WidgetName !== 'Kendo-Grid' &&
				dialogModalInputObject.WidgetName !== 'Kendo-Chart'
			"
			style="float: right; padding-right: 5px; padding-top: 5px"
			matTooltip="Upload File"
			[matTooltipPosition]="'below'"
		>
			<i
				(click)="openFileImageUploadWindow()"
				[ngClass]="{
					'object-contains-files':
						dialogModalInputObject.CountOfFilesUploaded >= 1
				}"
				class="tim-icons icon-notes clipboard-larger"
				aria-hidden="true"
				style="cursor: pointer"
				title="View files attached to this Asset.  {{
					dialogModalInputObject.CountOfFilesUploaded > 0
						? dialogModalInputObject.CountOfFilesUploaded +
						  ' file' +
						  (dialogModalInputObject.CountOfFilesUploaded > 1
								? 's'
								: '') +
						  ' uploaded currently.'
						: ''
				}}"
			>
			</i>
		</button>

		<div
			style="
				display: inline;
				float: right;
				padding-right: 10px;
				padding-top: 15px;
				margin-top: -5px;
			"
		>
			<lib-live-data
				[live]="
					dialogModalInputObject.isDisplayDataLive === undefined
						? false
						: dialogModalInputObject.isDisplayDataLive
				"
			></lib-live-data>
		</div>
		<div
			*ngIf="
				dashboardService.evaluateAngularDirectiveNameForTimeZoneOption(
					'dialogPopupWidget',
					dialogModalInputObject
				)
			"
			style="
				display: inline;
				float: right;
				padding-right: 0px;
				padding-top: 15px;
			"
		>
			<h6
				[matTooltip]="
					dashboardService.determineTimeZoneType(
						dialogModalInputObject
					) === 'User Time'
						? 'Information in this widget is formatted to be in the timezone of your computer.'
						: dashboardService.determineTimeZoneType(
								dialogModalInputObject
						  ) === 'Site Time'
						? 'Information in this widget is formatted to be in the timezone of the site the tag belongs to.'
						: dashboardService.determineTimeZoneType(
								dialogModalInputObject
						  ) === 'UTC Time'
						? 'Information in this widget is formatted to UTC Time, with no offset for a timezone.'
						: ''
				"
				[matTooltipPosition]="'below'"
				class="title"
				style="padding-top: 0px; display: inline-block"
			>
				{{
					dashboardService.determineTimeZoneType(
						dialogModalInputObject
					)
				}}
			</h6>
		</div>
		<div
			style="
				display: inline;
				float: right;
				padding-right: 20px;

			"
		>
			<button
				*ngIf="
					this.dialogModalInputObject.entityData != null &&
					this.dialogModalInputObject.entityData.EntityId != null
				"
				class="btn btn-primary btn-round btn-icon mr-1 pull-right"
				style="display: inline; float: right; padding-right: 15px; padding-top: 15px; margin-right:20px !important;"
				tooltip="New Log Entry"
				title="Click here to enter a new Log Entry for this entity."
				(click)="editNote(null)">
			<i class="tim-icons icon-simple-add"> </i>
			</button>
		</div>
	</div>

	<div
		*ngIf="dialogModalInputObject.WidgetName === 'canvas'"
		class="card-header widget-card-header"
		style="cursor: default; padding-top: 0px !important"
	>
		<div style="padding-top: 15px; display: inline-block">
			<h6 class="title d-inline">
				{{ dialogModalInputObject.widgetNameDisplay }}
			</h6>
		</div>

		<button
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="onCancel()"
			matTooltip="Close"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-simple-remove"></i>
			</div>
		</button>

		<button
			*ngIf="dialogModalInputObject.WidgetName === 'canvas'"
			mat-button
			style="display: inline; float: right; padding-top: 5px"
			(click)="openGSETemplatePopup()"
			matTooltip="Template Selection"
			[matTooltipPosition]="'below'"
		>
			<div>
				<i class="tim-icons icon-pencil"></i>
			</div>
		</button>
	</div>

	<div class="card-body widget-card-body" style="width: 100%">
		<ng-template #lazyLoadedWidgetInsert></ng-template>
	</div>
</div>
