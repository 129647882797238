import { Component, Input, OnInit, Optional, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';



import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { GridSettings } from '../../_models/grid-settings.interface';
import {
	process,
	State,
	toDataSourceRequest,
} from '@progress/kendo-data-query';

import { filter, take } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { GseService } from '../../services/gse.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { Global } from '../../_constants/global.variables';
import { UtilityService } from '../../services/utility.service';
import { MapsService } from '../../services/maps.service';
import { ITag } from '../../_models/tag.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';

declare const google: any;

@Component({
	selector: 'lib-asset-geofencing',
	templateUrl: './asset-geofencing.component.html',
	styleUrls: ['./asset-geofencing.component.scss'],
})
export class AssetGeofencingComponent implements OnInit, OnDestroy {
	@Optional() @Input() widgetObject: any;
	public polygonRotationAngle: number = 0;
	public isLoading: boolean = true;
	defaultLeftWidth = 20;
	defaultMiddleWidth = 80;
	defaultRightWidth = 0;
	panelOpenState = false;
	zoneForm: FormGroup;
	zoneTypeForm: FormGroup;
	showEditingPanel = false;
	zonesAndZonesInstances: any;
	private swalWithBootstrapButtons: any;
	public polygonAnchorPoint: number = undefined;

	editingExistingZone = false;
	fullDataCacheSubscription: any;
	infoWindow: any;
	zoneTypes: any;
	polygonSelectedConfirmationObject: any = {
		message: 'Please select or create a polygon.',
		isOkToSubmit: false,
		submissionButtonMessage: 'Add',
	};
	selectedCoordinates: any[];
	listOfPolygons = [];
	currentlySelectedZone: any;
	assetListForUserSites: any;
	assetsForSelectedZoneType: any = [];
	zoneFormIsPopulated: boolean;
	mapForGeofencing: any;
	assetTotalLength: any;
	zoneTypeFormIsPopulated: boolean;
	showZoneForm: boolean;
	showZoneTypeForm: boolean;
	editingZone: boolean;
	editingZoneType: boolean;
	editingExistingZoneType: boolean;
	availableZoneTypesForCurrentZone: any[];
	currentlySelectedZoneTypeInstance: any;
	speedLimitUnits: any = [{ name: 'mph' }, { name: 'kph' }];
	siteForMap: any;
	siteAssets: any;
	public widgetGroupSettings: any;
	public fleetId: number;

	tagOptions = [
		{ tagName: 'power', standardObservationId: 4504 },
		{ tagName: 'maintenance', standardObservationId: 56324 },
		{ tagName: 'drivemode', standardObservationId: 19127 },
		{ tagName: 'speed', standardObservationId: 77417 },
		{ tagName: 'fuellevel', standardObservationId: 19141 },
		{ tagName: 'batterylevel', standardObservationId: 55367 },
	];

	public gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'isSelected',
				title: 'Is Selected',
				filterable: false,
				hidden: false,
				_width: 100,
			},
			{
				field: 'Name',
				title: 'Name',
				filterable: true,
				_width: 200,
			},
			{
				field: 'AssetTypeName',
				title: 'Type',
				filterable: true,
				_width: 150,
			},
			{
				field: 'OwnerOrganizationName',
				title: 'Owner',
				filterable: true,
				_width: 150,
			},
			{
				field: 'ManufacturerOrganizationName',
				title: 'Manufacturer',
				filterable: true,
				_width: 250,
			},
		],
	};
	currentlySelectedGooglePolygon: any;
	anchorPointMarker: any;
	mapDiv: string;
	drawingManager: any;
	overlayCompleteListener: any;
	markers: any = [];
	infoWindows: any = [];

	shouldShowAssets: boolean = false;
	jbtStandardObservationSeverityLevels: any;
	theme: string;
	colorChangedSubscription: any;
	signalRTagUpdateSubscription: any;
	tagList: any;
	public guid: string;
	public componentName: string = "asset-geofencing: ";

	constructor(
		private dataService: DataService,
		private toastr: ToastrService,
		public gseService: GseService,
		public signalRCore: SignalRCoreService,
		private utilityService: UtilityService,
		private mapsService: MapsService
	) {
		this.swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: 'btn btn-success',
				cancelButton: 'btn btn-danger',
			},
			buttonsStyling: false,
		});
	}

	ngOnInit() {
		this.guid = this.dataService.guid();
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);
	}

	ngAfterViewInit() {
		this.mapsService.loadGoogleMaps().then(() => {
			this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((data: any) => {
				Global.User.DebugMode &&
					console.log('gse-summary: color changed data = %O', data);
				if (data === 'light') {
					this.theme = 'light';
				} else {
					this.theme = 'dark';
				}
			});

		let id = 1;

		if (!this.widgetObject.WidgetId) {
			return;
		}
		let tempMapDiv = 'geoMap' + this.widgetObject.WidgetId.toString();
		this.mapDiv = tempMapDiv;
		if (this.showEditingPanel) {
			this.defaultMiddleWidth = 60;
			this.defaultRightWidth = 20;
		}
		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.getAssetsAfterCacheIsLoaded();
					}
				});
		} else {
			this.getAssetsAfterCacheIsLoaded();
		}
		})
	}

	getAssetsAfterCacheIsLoaded(initialLoad: boolean = true) {
		if (Global.Theme === 'light') {
			this.theme = 'light';
		} else if (Global.Theme === 'dark') {
			this.theme = 'dark';
		}
		let siteAssets: any = [];
		if(this.widgetObject.WidgetSiteId){
			siteAssets = this.dataService.cache.assets.filter(
				(asset) => {
					const allGSEAssetIds = [
						86292, 86293, 86294, 86295, 86296, 86297,
						86298, 86299, 86300, 86313, 86314
					];
					if (Global.User.isAdmin) {
						return (
							allGSEAssetIds.includes(
								asset.AssetTypeId
							) &&
							asset.Site !== undefined &&
							asset.Site.Id ==
								this.widgetObject.WidgetSiteId
						);
					} else {
						return (
							allGSEAssetIds.includes(
								asset.AssetTypeId
							) &&
							asset.Site !== undefined &&
							Global.User.currentUser.Security.Aggregate.Collections.SiteIds.includes(
								asset.Site.Id
							) &&
							asset.Site.Id ==
								this.widgetObject.WidgetSiteId
						);
					}
				}
			);
			if(initialLoad){
				this.getServerityLevels(siteAssets);

			}
		} else if(this.widgetObject.VocationalSettingsJSON){
			var VocationalSettings = JSON.parse(
				this.widgetObject.VocationalSettingsJSON
			);
			this.fleetId = VocationalSettings.id;

			this.dataService.GetFleets().subscribe((data: any) => {
				if(data.length > 0){
					let myFleet = data.find((fleet) => {
						return fleet.FleetId === this.fleetId;
					});
					this.widgetObject.FleetName = myFleet?.FleetName;
					this.dataService.GetFleetAssets(this.fleetId).subscribe((data: any) => {
						console.log(data);
						data.forEach((asset) => {
							let dataCacheAsset = this.dataService.cache.assets.find((assetInstance) => {
								return assetInstance.Id === asset.Id;
							});
							if(dataCacheAsset){
								siteAssets.push(dataCacheAsset);
							}

						});
						if(initialLoad){
							this.getServerityLevels(siteAssets);

						}
					});
				}
			});
		}


	}

	getServerityLevels(siteAssets){
		let statement =
		'API.GetJBTStandardObservationSeverityLevels';
	this.dataService
		.SQLActionAsPromise(statement)
		.then((data: any) => {
			console.log(data);
			this.jbtStandardObservationSeverityLevels =
				data;
			this.siteAssets = siteAssets;
			this.initMap();
			if(this.fullDataCacheSubscription){
				this.fullDataCacheSubscription.unsubscribe();

			}
		});
	}

	onResized(event) {
		//here we will handle resizing the map.
	}

	initializeDrawingManagerAndListener() {
		this.drawingManager = new google.maps.drawing.DrawingManager({
			drawingControl: true,
			drawingControlOptions: {
				position: google.maps.ControlPosition.RIGHT_CENTER,
				drawingModes: [google.maps.drawing.OverlayType.POLYGON],
			},
			circleOptions: {
				fillColor: '#ffff00',
				fillOpacity: 1,
				strokeWeight: 5,
				clickable: false,
				editable: true,
				zIndex: 1,
			},
		});
		this.drawingManager.setMap(this.mapForGeofencing);
		this.overlayCompleteListener = google.maps.event.addListener(
			this.drawingManager,
			'overlaycomplete',
			(overlayObject) => {
				console.log(overlayObject);
				if (overlayObject.type === 'polygon') {
					var path = overlayObject.overlay.getPath();
					this.selectedCoordinates = [];

					path.forEach((coor, i) => {
						this.selectedCoordinates.push({
							lat: path.getAt(i).lat(),
							lng: path.getAt(i).lng(),
						});
					});
					console.log(this.selectedCoordinates);
					console.log(this.zoneForm);
					if (this.zoneForm !== undefined) {
						this.polygonSelectedConfirmationObject.message =
							'Successfully drawn ' +
							this.selectedCoordinates.length +
							' point polygon.';
						this.polygonSelectedConfirmationObject.isOkToSubmit =
							true;
						this.currentlySelectedGooglePolygon =
							overlayObject.overlay;

						google.maps.event.addListener(
							this.currentlySelectedGooglePolygon,
							'contextmenu',
							(e: any) => {
								// Check if click was on a vertex control point
								if (e.vertex == undefined) {
									return;
								}
								if (this.anchorPointMarker !== undefined) {
									this.anchorPointMarker.setMap(null);
									this.anchorPointMarker = undefined;
								}
								this.anchorPointMarker = new google.maps.Marker(
									{
										position: e.latLng,
										map: this.mapForGeofencing,
									}
								);
								this.changePolygonAnchorPoint(
									this.currentlySelectedGooglePolygon,
									e.vertex
								);
							}
						);
						// this.selectedZone = {};
					}
				}
			}
		);
	}

	createNewZone(zoneFromTemplate?) {
		if (!zoneFromTemplate) {
			this.currentlySelectedZone = undefined;
			this.initializeDrawingManagerAndListener();
		} else {
			this.currentlySelectedZone = zoneFromTemplate;
		}
		//build new formgroup

		this.editGeographyZone();
	}

	colorChanged(event, tag) {
		console.log(event);
		console.log(tag);
		// let colorChange = {
		//     "Id": tag.Id,
		//     "HexColor": event
		// }
		// //discover if change has already been made before save
		// let colorChanges = this.unsavedChanges.findIndex(tagChange => tagChange.Id === colorChange.Id);
		// if (colorChanges !== -1) {
		//     this.unsavedChanges[colorChanges].HexColor = event;
		// } else {
		//     this.unsavedChanges.push(colorChange);
		// }
	}

	editGeographyZone(zoneInstance?) {
		this.polygonAnchorPoint = undefined;
		this.showZoneTypeForm = false;
		this.showZoneForm = true;
		this.editingZone = true;
		this.editingZoneType = false;
		this.editingExistingZoneType = false;
		this.zoneFormIsPopulated = false;
		this.defaultMiddleWidth = 60;
		this.defaultRightWidth = 20;
		this.listOfPolygons.forEach((polygon) => {
			google.maps.event.clearListeners(polygon, 'contextmenu');
		});
		//This means we are dealing with an already existing saved zone.
		if (zoneInstance !== undefined && zoneInstance.GeographyZoneId) {
			this.currentlySelectedZone = zoneInstance;
			this.editingExistingZone = true;

			this.zoneForm = new FormGroup({
				name: new FormControl(zoneInstance.GeographyZoneName),
				backgroundColor: new FormControl(
					!_.isNil(zoneInstance.GeographyZoneBackgroundColor)
						? zoneInstance.GeographyZoneBackgroundColor
						: '#FF0000'
				),
				description: new FormControl(
					zoneInstance.GeographyZoneDescription
				),
				rotation: new FormControl(this.polygonRotationAngle),
			});

			let pathAsString = zoneInstance.GeographyZoneLatLonPairs.split('|');
			let pathAsArray = [];
			pathAsString.forEach((pathInstance) => {
				let latLongArray = pathInstance.split(',');
				let latLongObject = {
					lat: parseFloat(latLongArray[0]),
					lng: parseFloat(latLongArray[1]),
				};
				pathAsArray.push(latLongObject);
			});

			this.polygonSelectedConfirmationObject.message =
				'Selected ' + pathAsArray.length + ' point polygon.';
			this.polygonSelectedConfirmationObject.submissionButtonMessage =
				'Submit Edit';
			this.polygonSelectedConfirmationObject.isOkToSubmit = true;
			this.showEditingPanel = true;
			this.zoneFormIsPopulated = true;

			google.maps.event.addListener(
				this.currentlySelectedGooglePolygon,
				'contextmenu',
				(e: any) => {
					// Check if click was on a vertex control point
					if (e.vertex == undefined) {
						return;
					}
					if (this.anchorPointMarker !== undefined) {
						this.anchorPointMarker.setMap(null);
						this.anchorPointMarker = undefined;
					}
					this.anchorPointMarker = new google.maps.Marker({
						position: e.latLng,
						map: this.mapForGeofencing,
					});
					this.changePolygonAnchorPoint(
						this.currentlySelectedGooglePolygon,
						e.vertex
					);
				}
			);

			this.currentlySelectedGooglePolygon.addListener('dragend', () => {
				let infoMarkerPosition =
					this.currentlySelectedGooglePolygon
						.infoWindowForZoneInstance.position;
				if (this.anchorPointMarker !== undefined) {
					let test = this.currentlySelectedGooglePolygon
						.getPath()
						.getAt(this.polygonAnchorPoint);
					console.log(test);
					this.anchorPointMarker.setMap(null);
					this.anchorPointMarker = undefined;
					this.anchorPointMarker = new google.maps.Marker({
						position: test,
						map: this.mapForGeofencing,
					});
				}
				// this.listOfPolygons.forEach(polygon => {
				//     polygon.infoWindowForZoneInstance.close();
				// })
				this.currentlySelectedGooglePolygon.infoWindowForZoneInstance.close();
			});
			// });
		} else {
			this.assetsForSelectedZoneType = [];
			this.editingExistingZone = false;
			this.zoneForm = new FormGroup({
				name: new FormControl(''),
				description: new FormControl(''),
				rotation: new FormControl(this.polygonRotationAngle),
			});
			//means we are creating from a template
			if (zoneInstance !== undefined) {
				var path = zoneInstance.getPath();
				this.selectedCoordinates = [];

				path.forEach((coor, i) => {
					this.selectedCoordinates.push({
						lat: path.getAt(i).lat(),
						lng: path.getAt(i).lng(),
					});
				});
				console.log(this.selectedCoordinates);
				console.log(this.zoneForm);
				var bounds = new google.maps.LatLngBounds();
				for (var i = 0; i < this.selectedCoordinates.length; i++) {
					bounds.extend(this.selectedCoordinates[i]);
				}
				let zoneCenter = bounds.getCenter();
				this.mapForGeofencing.fitBounds(bounds);
				if (this.zoneForm !== undefined) {
					this.polygonSelectedConfirmationObject = {
						message:
							'Successfully drawn ' +
							this.selectedCoordinates.length +
							' point polygon.',
						isOkToSubmit: true,
						submissionButtonMessage: 'Add',
					};
					// this.selectedZone = {};
				}

				google.maps.event.addListener(
					this.currentlySelectedGooglePolygon,
					'contextmenu',
					(e: any) => {
						// Check if click was on a vertex control point
						if (e.vertex == undefined) {
							return;
						}
						if (this.anchorPointMarker !== undefined) {
							this.anchorPointMarker.setMap(null);
							this.anchorPointMarker = undefined;
						}
						this.anchorPointMarker = new google.maps.Marker({
							position: e.latLng,
							map: this.mapForGeofencing,
						});
						this.changePolygonAnchorPoint(
							this.currentlySelectedGooglePolygon,
							e.vertex
						);
					}
				);
			} else {
				this.polygonSelectedConfirmationObject = {
					message: 'Please select or create a polygon.',
					isOkToSubmit: false,
					submissionButtonMessage: 'Add',
				};
			}

			this.showEditingPanel = true;
			this.zoneFormIsPopulated = true;
		}
	}

	showAssetsOnMap() {
		this.shouldShowAssets = !this.shouldShowAssets;
		var bounds = new google.maps.LatLngBounds();

		this.mapForGeofencing.fitBounds(bounds);
		if (this.shouldShowAssets === true) {
			this.siteAssets.forEach((gse: any, index) => {
				let lat = gse.Tags.find(
					(t) => t.JBTStandardObservationId == 54260
				);
				let long = gse.Tags.find(
					(t) => t.JBTStandardObservationId == 54261
				);

				if (!_.isNil(lat?.Value) && !_.isNil(long?.Value)) {
					var gseLatLng = new google.maps.LatLng(
						parseFloat(lat.Value),
						parseFloat(long.Value)
					);
					bounds.extend(gseLatLng);

					let contentString = '';

					if (gse.Site !== undefined) {
						contentString = contentString + gse.Site.Name;
					}
					if (gse.Name !== undefined) {
						contentString = contentString + ' ' + gse.Name;
					}

					var infoWindow = new google.maps.InfoWindow({
						content: `<div class='firstHeading' style='font-size: 12px'><b><span style='color:teal;'> ${contentString}</div></b></div>`,
					});
					let evaluationObject: any =
						this.gseService.determineAssetImageFromAssetTypeForMap(
							gse
						);
					if (evaluationObject !== {}) {
						if (evaluationObject.alarmType === 'Critical') {
							var marker = new google.maps.Marker({
								position: gseLatLng,
								// title: gse.Name,
								infowindow: infoWindow,
								icon: evaluationObject.icon,
								animation: google.maps.Animation.BOUNCE,
							});
						} else {
							var marker = new google.maps.Marker({
								position: gseLatLng,
								// title: gse.Name,
								infowindow: infoWindow,
								icon: evaluationObject.icon,
							});
						}
					} else {
						var marker = new google.maps.Marker({
							position: gseLatLng,
							// title: gse.Name,
							infowindow: infoWindow,
						});
					}

					google.maps.event.addListener(
						marker,
						'click',
						function (evt) {
							infoWindow.open(this.mapForGeofencing, marker);
						}
					);
					marker.Id = gse.Id;
					marker.setMap(this.mapForGeofencing);
					infoWindow.open(this.mapForGeofencing, marker);
					this.infoWindows.push(infoWindow);
					this.markers.push(marker);

					// setTimeout(() => {
					// 			infoWindow.setContent(
					// 				`
					//   <div class='firstHeading' style='font-size: 12px'><b><span style='color:teal;'> ${contentString}</div></b>${this.recreateTagString(gse)}</div>`
					// 			);
					// 		}, 1000);
					// dynamically naming intervals by asset name so we can cancel them in a loop later.
				}
			});
		} else {
			this.markers.forEach((marker) => {
				marker.setMap(null);
			});
			this.infoWindows.forEach((infoWindow) => {
				infoWindow.close();
			});
			this.markers = [];
			this.infoWindows = [];
		}

		this.listOfPolygons.forEach((googlePoly) => {
			let pathAsArray = this.getPathAsArrayFromSQLZoneInstance(
				googlePoly.zoneInstanceObjectFromSql.GeographyZoneLatLonPairs
			);
			//here we show the polygonToUse info on the map and in the form.

			for (var i = 0; i < pathAsArray.length; i++) {
				bounds.extend(pathAsArray[i]);
			}
		});

		this.mapForGeofencing.fitBounds(bounds);
	}

	changePolygonAnchorPoint(polygon, event) {
		console.log(polygon);
		console.log(event);
		this.polygonAnchorPoint = event;
		this.utilityService.showToastMessageShared({
			type: 'info',
			message: "'Zone anchor point has been selected.",
		});
		// this.toastr.info("'Zone anchor point has been selected.");
	}

	rotatePolygon(zoneForm) {
		if (
			this.currentlySelectedGooglePolygon !== undefined &&
			this.currentlySelectedGooglePolygon.infoWindowForZoneInstance !==
				undefined
		) {
			this.currentlySelectedGooglePolygon.infoWindowForZoneInstance.close();
		}
		let angle = zoneForm.rotation;
		var map = this.currentlySelectedGooglePolygon.getMap();
		var prj = map.getProjection();
		var origin = prj.fromLatLngToPoint(
			this.currentlySelectedGooglePolygon
				.getPath()
				.getAt(this.polygonAnchorPoint)
		); //rotate around first point

		var coords = this.currentlySelectedGooglePolygon
			.getPath()
			.getArray()
			.map((latLng) => {
				var point = prj.fromLatLngToPoint(latLng);
				var rotatedLatLng = prj.fromPointToLatLng(
					this.rotatePoint(point, origin, angle)
				);
				return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
			});
		console.log(this.currentlySelectedGooglePolygon);
		this.currentlySelectedGooglePolygon.setPath(coords);
		console.log(this.currentlySelectedGooglePolygon);
	}

	rotatePoint(point, origin, angle) {
		var angleRad = (angle * Math.PI) / 180.0;
		return {
			x:
				Math.cos(angleRad) * (point.x - origin.x) -
				Math.sin(angleRad) * (point.y - origin.y) +
				origin.x,
			y:
				Math.sin(angleRad) * (point.x - origin.x) +
				Math.cos(angleRad) * (point.y - origin.y) +
				origin.y,
		};
	}

	editGeographyZoneType(zoneTypeInstance?) {
		this.assetsForSelectedZoneType = [];
		this.showZoneTypeForm = true;
		this.showZoneForm = false;
		this.zoneTypeFormIsPopulated = false;
		this.editingZone = false;
		this.editingExistingZone = false;
		this.editingZoneType = true;
		this.showEditingPanel = true;
		this.availableZoneTypesForCurrentZone = [];

		this.defaultMiddleWidth = 60;
		this.defaultRightWidth = 20;
		this.getAssetsAfterCacheIsLoaded(false);
		this.gridSettings.gridData = process(
			this.siteAssets,
			this.gridSettings.state
		);
		this.assetTotalLength = 0;

		this.assetListForUserSites = [];

		let assetIdsForSelectedGeographyZoneType = [];
		if (
			zoneTypeInstance &&
			zoneTypeInstance.Assets &&
			zoneTypeInstance.Assets.length > 0
		) {
			zoneTypeInstance.Assets.forEach((asset) => {
				assetIdsForSelectedGeographyZoneType.push(asset.AssetId);
			});
		}

		if (zoneTypeInstance !== undefined) {
			this.currentlySelectedZoneTypeInstance = zoneTypeInstance;
			let selectedZoneTypeCategory = this.zoneTypes.find((zone) => {
				return zoneTypeInstance.GeographyZoneTypeId === zone.Id;
			});
			if (
				this.currentlySelectedZone.Types &&
				this.currentlySelectedZone.Types.length > 0
			) {
				this.zoneTypes.forEach((zoneType) => {
					// let search = _.find(this.currentlySelectedZone.Types, (item) => {
					// 	return item.GeographyZoneTypeId === zoneType.Id;
					// });
					// if (search === undefined || search.GeographyZoneTypeId === selectedZoneTypeCategory.Id) {
					this.availableZoneTypesForCurrentZone.push(zoneType);
					// }
				});
				console.log(this.zoneTypes);
			}
			this.editingExistingZoneType = true;
			let level = this.jbtStandardObservationSeverityLevels.find(
				(level) => {
					return (
						level.Ordinal ===
						zoneTypeInstance.GeographyZoneToGeographyZoneTypeJBTStandardObservationSeverityLevel
					);
				}
			);
			this.zoneTypeForm = new FormGroup({
				zoneTypeName: new FormControl(
					zoneTypeInstance.GeographyZoneToGeographyZoneTypeName
				),
				// assets: new FormControl(this.assetsForSelectedZoneType),
				type: new FormControl(selectedZoneTypeCategory),
				severityLevel: new FormControl(level.Name),
			});
			zoneTypeInstance.Assets.forEach((asset) => {
				this.assetsForSelectedZoneType.push(parseInt(asset.AssetId));
			});
			console.log(this.assetsForSelectedZoneType);
			console.log(this.siteAssets);

			this.evaluateZoneTypeForm(zoneTypeInstance);
		} else {
			this.currentlySelectedZoneTypeInstance = undefined;
			this.availableZoneTypesForCurrentZone = this.zoneTypes;
			this.editingExistingZoneType = false;
			this.zoneTypeForm = new FormGroup({
				zoneTypeName: new FormControl(''),
				assets: new FormControl(this.assetsForSelectedZoneType),
				type: new FormControl(''),
				severityLevel: new FormControl(''),
			});
		}
		this.zoneTypeFormIsPopulated = true;
	}

	toggleAssetInFormList(asset) {
		if (!this.assetsForSelectedZoneType.includes(asset.Id)) {
			this.assetsForSelectedZoneType.push(parseInt(asset.Id));
		} else {
			_.remove(this.assetsForSelectedZoneType, (assetId) => {
				return assetId == asset.Id;
			});
		}
	}

	evaluateZoneTypeForm(zoneTypeInstance?) {
		if (this.zoneTypeForm.value.type.Id === 2) {
			this.zoneTypeForm.addControl(
				'speedLimit',
				new FormControl(
					zoneTypeInstance !== undefined
						? zoneTypeInstance.GeographyZoneToGeographyZoneTypeSpeedLimit
						: 0
				)
			);
			this.zoneTypeForm.addControl(
				'speedLimitUnits',
				new FormControl(
					zoneTypeInstance !== undefined
						? zoneTypeInstance.GeographyZoneToGeographyZoneTypeSpeedLimitUnits
						: 'mph'
				)
			);
		} else if (this.zoneTypeForm.controls.speedLimit !== undefined) {
			this.zoneTypeForm.removeControl('speedLimit');
			this.zoneTypeForm.removeControl('speedLimitUnits');
		}
	}

	deleteZoneType() {
		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, cancel!',
				reverseButtons: true,
				animation: false,
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.

					let statement =
						'API.GeofencingDeleteZoneTypeAndRelatedAssets ' +
						'@GeozoneTypeId = ' +
						this.currentlySelectedZoneTypeInstance.Id +
						', @GeographyZoneId = ' +
						this.currentlySelectedZoneTypeInstance.GeographyZoneId;

					this.dataService
						.SQLActionAsPromise(statement)
						.then((data: any) => {
							console.log(data);
							this.zonesAndZonesInstances.forEach((zone) => {
								if (
									zone.GeographyZoneId ===
									this.currentlySelectedZoneTypeInstance
										.GeographyZoneId
								) {
									zone.Types = zone.Types.filter(
										(zoneType) => {
											return (
												zoneType.Id !==
												this
													.currentlySelectedZoneTypeInstance
													.Id
											);
										}
									);
								}
							});
							this.utilityService.showToastMessageShared({
								type: 'info',
								message:
									"'ZoneType " +
									this.currentlySelectedZoneTypeInstance
										.GeographyZoneToGeographyZoneTypeName +
									"' has been DELETED.",
							});
							// this.toastr.info(
							// 	"'ZoneType " +
							// 		this.currentlySelectedZoneTypeInstance
							// 			.GeographyZoneToGeographyZoneTypeName +
							// 		"' has been DELETED."
							// );

							this.showEditingPanel = false;
							this.editingZoneType = false;
							this.defaultMiddleWidth = 80;
							this.defaultRightWidth = 0;
							this.currentlySelectedZone = undefined;
							this.currentlySelectedZoneTypeInstance = undefined;
						});
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
					this.swalWithBootstrapButtons.fire({
						title: 'Cancelled',
						text: 'Your widget has not been deleted',

						animation: false,
					});
				}
			});
	}

	deleteZone() {
		console.log(this.listOfPolygons);
		this.swalWithBootstrapButtons
			.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'No, cancel!',
				reverseButtons: true,
				animation: false,
			})
			.then((result) => {
				if (result.value) {
					// logic for deleting dashboard goes here.
					let polygonToDelete = this.listOfPolygons.find(
						(polygonIteration) => {
							return (
								polygonIteration.zoneInstanceObjectFromSql
									.GeographyZoneId ===
								this.currentlySelectedZone.GeographyZoneId
							);
						}
					);
					let statement =
						'API.GeofencingDeleteZoneAndRelatedAssets ' +
						'@GeozoneId = ' +
						polygonToDelete.zoneInstanceObjectFromSql
							.GeographyZoneId;
					console.log(statement);
					console.log(this.listOfPolygons);
					console.log(this.currentlySelectedZone);
					console.log(polygonToDelete);

					this.dataService
						.SQLActionAsPromise(statement)
						.then((data: any) => {
							console.log(data);
							this.listOfPolygons = this.listOfPolygons.filter(
								(poly) => {
									return (
										poly.zoneInstanceObjectFromSql
											.GeographyZoneId !==
										this.currentlySelectedZone
											.GeographyZoneId
									);
								}
							);
							this.zonesAndZonesInstances =
								this.zonesAndZonesInstances.filter(
									(zoneType) => {
										return (
											zoneType.GeographyZoneId !==
											this.currentlySelectedZone
												.GeographyZoneId
										);
									}
								);
							this.closeAllPolygonWindows();
							polygonToDelete.setMap(null);
							this.utilityService.showToastMessageShared({
								type: 'info',
								message:
									"'Zone " +
									polygonToDelete.zoneInstanceObjectFromSql
										.Name +
									"' has been DELETED.",
							});
							// this.toastr.info(
							// 	"'Zone " +
							// 		polygonToDelete.zoneInstanceObjectFromSql
							// 			.Name +
							// 		"' has been DELETED."
							// );
							this.showEditingPanel = false;
							this.defaultMiddleWidth = 80;
							this.defaultRightWidth = 0;
							this.currentlySelectedZone = undefined;

							let newLatLngBounds =
								new google.maps.LatLngBounds();
							this.listOfPolygons.forEach((googlePoly) => {
								let pathAsArray =
									this.getPathAsArrayFromSQLZoneInstance(
										googlePoly.zoneInstanceObjectFromSql
											.GeographyZoneLatLonPairs
									);
								//here we show the polygonToUse info on the map and in the form.

								for (var i = 0; i < pathAsArray.length; i++) {
									newLatLngBounds.extend(pathAsArray[i]);
								}
							});

							this.mapForGeofencing.fitBounds(newLatLngBounds);
						});
				} else if (
					/* Read more about handling dismissals below */
					result.dismiss === Swal.DismissReason.cancel
				) {
					this.swalWithBootstrapButtons.fire({
						title: 'Cancelled',
						text: 'Your widget has not been deleted',

						animation: false,
					});
				}
			});
		//we need to look it up in the list that we have.
	}

	createNewZoneType(zoneForZoneType) {
		this.showPolygonInfo(zoneForZoneType, false);
		this.editGeographyZoneType();
	}

	submitZoneForm(values: any) {
		console.log(values);
		console.log(this.selectedCoordinates);
		let selectedLatLongCordAsString = '';
		let selectedLongLatCordAsString = '';

		let editedCoordinates = [];
		//this.currentlyselectedgooglepolygon is not updating when rotated or dragged.
		var path = this.currentlySelectedGooglePolygon.getPath();

		path.forEach((coor, i) => {
			editedCoordinates.push({
				lat: path.getAt(i).lat(),
				lng: path.getAt(i).lng(),
			});
		});

		console.log(editedCoordinates);
		editedCoordinates.forEach((coor, index) => {
			let stringLatLongToConcat =
				coor.lat.toString() + ', ' + coor.lng.toString();
			if (index !== editedCoordinates.length - 1) {
				stringLatLongToConcat = stringLatLongToConcat + ' | ';
			}
			selectedLatLongCordAsString =
				selectedLatLongCordAsString + stringLatLongToConcat;

			let stringLongLatToConcat =
				coor.lng.toString() + ', ' + coor.lat.toString();
			if (index !== editedCoordinates.length - 1) {
				stringLongLatToConcat = stringLongLatToConcat + ' | ';
			}
			selectedLongLatCordAsString =
				selectedLongLatCordAsString + stringLongLatToConcat;
		});

		selectedLongLatCordAsString =
			selectedLongLatCordAsString +
			' | ' +
			editedCoordinates[0].lng.toString() +
			', ' +
			editedCoordinates[0].lat.toString();

		let statement =
			'API.GeofencingAddOrUpdateZone ' +
			"@LatLonPairs = '" +
			selectedLatLongCordAsString +
			"', " +
			"@LonLatPairs = '" +
			selectedLongLatCordAsString +
			"', " +

			" @Name = '" +
			values.name +
			"', @Description = '" +
			values.description +
			"', @CreatorUserId = " +
			Global.User.currentUser.Id +
			", @BackgroundColor = '" +
			values.backgroundColor +
			"'";
		if(this.widgetObject.WidgetSiteId){
			statement += ', @SiteId = ' +
			this.widgetObject.WidgetSiteId;
		} else if(this.fleetId){
			statement += ', @FleetId = ' +
			this.fleetId;
		}
		if (
			this.polygonSelectedConfirmationObject.submissionButtonMessage ===
			'Submit Edit'
		) {
			statement =
				statement +
				', @GeographyZoneId = ' +
				this.currentlySelectedZone.GeographyZoneId;
		}
		// if (assetIds.length > 0) {
		// 	assetIdsAsString = assetIds.join();
		// 	statement = statement + ", @AssetIds = '" + assetIdsAsString + "'";
		// }
		console.log(statement);
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			this.listOfPolygons.forEach((polygonIteration) => {
				let isEditable = polygonIteration.getEditable();
				let isDraggable = polygonIteration.getDraggable();
				if (isEditable) {
					polygonIteration.setEditable(false);
				}
				if (isDraggable) {
					polygonIteration.setDraggable(false);
				}
			});
			let returnedZone = data[0];
			// this.dataService.SQLActionAsPromise("API.GeofencingGetListOfAssetsByGeographyZoneId @GeographyZoneTypeId = " + returnedZone.Id).then((data: any) => {
			let index = this.listOfPolygons.findIndex((poly) => {
				return (
					poly.zoneInstanceObjectFromSql === undefined ||
					poly.zoneInstanceObjectFromSql.GeographyZoneId ===
						returnedZone.Id
				);
			});
			if (index !== -1) {
				this.listOfPolygons[index].infoWindowForZoneInstance.setContent(
					returnedZone.Name
				);
				this.listOfPolygons[
					index
				].zoneInstanceObjectFromSql.GeographyZoneDescription =
					returnedZone.Description;
				this.listOfPolygons[
					index
				].zoneInstanceObjectFromSql.GeographyZoneName =
					returnedZone.Name;
				this.listOfPolygons[
					index
				].zoneInstanceObjectFromSql.GeographyZoneBackgroundColor =
					returnedZone.BackgroundColor;
				this.listOfPolygons[
					index
				].zoneInstanceObjectFromSql.GeographyZoneLatLonPairs =
					returnedZone.LatLonPairs;
				this.utilityService.showToastMessageShared({
					type: 'info',
					message:
						"'Zone " + returnedZone.Name + "' has been EDITED.",
				});
				// this.toastr.info(
				// 	"'Zone " + returnedZone.Name + "' has been EDITED."
				// );
				console.log(this.listOfPolygons);
				// this.currentlySelectedZone.infoWindowForZoneInstance.setContent(returnedZone.Name)
			} else {
				let zoneInstanceFromSql = {
					GeographyZoneDescription: returnedZone.Description,
					GeographyZoneId: returnedZone.Id,
					GeographyZoneLatLonPairs: returnedZone.LatLonPairs,
					GeographyZoneName: returnedZone.Name,
					GeographyZoneBackgroundColor: returnedZone.BackgroundColor,
					Types: [],
				};
				this.currentlySelectedZone =
					this.currentlySelectedGooglePolygon;
				this.currentlySelectedZone.zoneInstanceObjectFromSql =
					zoneInstanceFromSql;
				const infoWindow = new google.maps.InfoWindow({
					content:
						this.currentlySelectedZone.zoneInstanceObjectFromSql
							.GeographyZoneName,
				});
				this.currentlySelectedZone.infoWindowForZoneInstance =
					infoWindow;
				this.listOfPolygons.push(this.currentlySelectedZone);
				this.zonesAndZonesInstances.push(zoneInstanceFromSql);
				let zoneWithGoogleInfo = this.currentlySelectedZone;
				// // this.showPolygonInfo(zoneWithGoogleInfo, true);
				// this.loadGeographyZoneAndEdit(zoneWithGoogleInfo, true);
				google.maps.event.addListener(
					this.listOfPolygons[this.listOfPolygons.length - 1],
					'click',
					() => {
						this.showPolygonInfo(zoneWithGoogleInfo, true);
					}
				);
				this.utilityService.showToastMessageShared({
					type: 'info',
					message: "'Zone " + returnedZone.Name + "' has been ADDED.",
				});
				// this.toastr.info(
				// 	"'Zone " + returnedZone.Name + "' has been ADDED."
				// );
			}
			this.cancelZoneEditing();
			// });
		});
	}

	formatLabel(value: number) {
		return value + 'deg';
	}

	createGeographyZoneFromExistingZone(zoneIteration, direction) {
		let pathAsArray = [];
		pathAsArray = this.getPathAsArrayFromSQLZoneInstance(
			zoneIteration.GeographyZoneLatLonPairs
		);
		const infoWindow = new google.maps.InfoWindow({
			content: zoneIteration.GeographyZoneName,
		});
		console.log(pathAsArray);
		if (direction === 'right') {
			pathAsArray.forEach((marker) => {
				marker.lng = marker.lng + 0.005;
			});
		} else if (direction === 'left') {
			pathAsArray.forEach((marker) => {
				marker.lng = marker.lng - 0.005;
			});
		} else if (direction === 'top') {
			pathAsArray.forEach((marker) => {
				marker.lat = marker.lat + 0.005;
			});
		} else if (direction === 'bottom') {
			pathAsArray.forEach((marker) => {
				marker.lat = marker.lat - 0.005;
			});
		}

		let zone = new google.maps.Polygon({
			paths: pathAsArray,
			strokeColor: '#FEE12B',
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: '#FEE12B',
			fillOpacity: 0.35,
			editable: true,
			draggable: true,
		});
		zone.setMap(this.mapForGeofencing);
		this.currentlySelectedGooglePolygon = zone;
		this.editGeographyZone(zone);

		// google.maps.event.addListener(zone, "click", (evt) => {
		//     this.loadGeographyZoneAndEdit(zone, true);
		//     // this.showPolygonInfo(zone, true);
		// });
	}

	toggleShowingAssetsOnMap() {
		this.showAssetsOnMap();
	}

	initMapOnly() {
		this.drawingManager = undefined;
		this.overlayCompleteListener = undefined;
		if(this.widgetObject.WidgetSiteId) {
			this.mapForGeofencing = new google.maps.Map(
				document.getElementById(this.mapDiv),

				this.mapsService.loadMapConfiguration({zoom: 15, center: {lat: this.siteForMap.GoogleLatitude, lng: this.siteForMap.GoogleLongitude}})

			);
		}
		else if (this.fleetId){
			//find an asset with a location, make that the center of the map.
			let lat: any;
			let long: any;
			let assetWithBoth = this.siteAssets.find((asset) => {
				lat = asset.Tags.find(	(t) => t.JBTStandardObservationId == 54260);
				long = asset.Tags.find(	(t) => t.JBTStandardObservationId == 54261);
				if(lat && long){
					return asset;
				}
			})
			if(assetWithBoth){
				this.mapForGeofencing = new google.maps.Map(
					document.getElementById(this.mapDiv),
					this.mapsService.loadMapConfiguration({zoom: 15, center: {lat: lat.Value, lng: long.Value}})
				);
			}

		}


		this.listOfPolygons = [];
		var bounds = new google.maps.LatLngBounds();

		this.zonesAndZonesInstances.forEach((zoneIteration) => {
			// if (zonetype.zonesOfType !== undefined) {
			// 	zonetype.zonesOfType.forEach((zoneInstance) => {
			if (zoneIteration.GeographyZoneLatLonPairs !== undefined) {
				let pathAsArray = this.getPathAsArrayFromSQLZoneInstance(
					zoneIteration.GeographyZoneLatLonPairs
				);
				const infoWindow = new google.maps.InfoWindow({
					content: zoneIteration.GeographyZoneName,
				});
				for (var i = 0; i < pathAsArray.length; i++) {
					bounds.extend(pathAsArray[i]);
				}

				console.log(pathAsArray);
				let zone = new google.maps.Polygon({
					paths: pathAsArray,
					strokeColor: !_.isNil(
						zoneIteration.GeographyZoneBackgroundColor
					)
						? zoneIteration.GeographyZoneBackgroundColor
						: '#FF0000',
					strokeOpacity: 0.8,
					strokeWeight: 2,
					fillColor: !_.isNil(
						zoneIteration.GeographyZoneBackgroundColor
					)
						? zoneIteration.GeographyZoneBackgroundColor
						: '#FF0000',
					fillOpacity: 0.35,
					content: zoneIteration.GeographyZoneName,
					zoneInstanceObjectFromSql: zoneIteration,
					infoWindowForZoneInstance: infoWindow,
					// editable: true,
					// draggable: true
				});

				this.listOfPolygons.push(zone);
				this.listOfPolygons[this.listOfPolygons.length - 1].setMap(
					this.mapForGeofencing
				);

				google.maps.event.addListener(zone, 'click', (evt) => {
					this.loadGeographyZoneAndEdit(zone, true);
					// this.showPolygonInfo(zone, true);
				});

				console.log(this.listOfPolygons);
			}
			// 	});
			// }
		});
		this.isLoading = false;
		if (this.zonesAndZonesInstances.length > 0) {
			this.mapForGeofencing.fitBounds(bounds);
		}

		// Construct the polygon.

		// const polygonComplete = google.maps.event.addListener(drawingManager, "polygoncomplete", function (polygon) {
		// 	console.log(polygon);
		// 	var coordinatesArray = polygon.overlay.getPath().getArray();
		// 	console.log(coordinatesArray);
		// });

		//number, number |

		const overlayComplete = google.maps.event.addListener(
			this.drawingManager,
			'overlaycomplete',
			(overlayObject) => {
				console.log(overlayObject);
				if (overlayObject.type === 'polygon') {
					var path = overlayObject.overlay.getPath();
					this.selectedCoordinates = [];
				}
			}
		);
	}

	cancelZoneEditing() {
		this.showEditingPanel = false;
		this.editingZone = false;
		this.editingZoneType = false;
		this.defaultMiddleWidth = 80;
		this.defaultRightWidth = 0;
		this.currentlySelectedZone = undefined;
		this.currentlySelectedZoneTypeInstance = undefined;
		this.currentlySelectedGooglePolygon = undefined;
		this.selectedCoordinates = [];
		this.initMapOnly();
	}

	cancelZoneTypeEditing() {
		this.showEditingPanel = false;
		this.editingZone = false;
		this.editingZoneType = false;
		this.defaultMiddleWidth = 80;
		this.defaultRightWidth = 0;
		this.currentlySelectedZone = undefined;
		this.currentlySelectedGooglePolygon = undefined;
		this.selectedCoordinates = [];
		this.currentlySelectedZoneTypeInstance = undefined;
	}

	submitZoneTypeForm(values: any) {
		console.log(values);

		let assetIds = this.assetsForSelectedZoneType;
		let assetIdsAsString = '';

		console.log(assetIdsAsString);

		//concat asset id into string list

		let statement =
			'API.GeofencingAddOrUpdateZoneType  @GeographyZoneId = ' +
			this.currentlySelectedZone.GeographyZoneId +
			",  @Name = '" +
			values.zoneTypeName +
			"', @TypeId = " +
			values.type.Id +
			', @UserId = ' +
			Global.User.currentUser.Id;
		if (this.editingExistingZoneType === true) {
			statement =
				statement +
				', @Id = ' +
				this.currentlySelectedZoneTypeInstance.Id;
		}
		if (this.zoneTypeForm.value.type.Id === 2) {
			statement =
				statement +
				', @SpeedLimit = ' +
				values.speedLimit +
				", @SpeedLimitUnits = '" +
				values.speedLimitUnits +
				"'";
		}
		if (assetIds.length > 0) {
			assetIdsAsString = assetIds.join();
			statement = statement + ", @AssetIds = '" + assetIdsAsString + "'";
		}
		console.log(statement);
		let level = this.jbtStandardObservationSeverityLevels.find((level) => {
			return level.Name === values.severityLevel;
		});

		statement = statement + ', @SeverityLevel = ' + level.Ordinal;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			console.log(data);

			console.log(this.zonesAndZonesInstances);

			let newType = {};
			newType = data
				.groupBy((group) => group.GeographyZoneToGeographyZoneTypeId)
				.select((t) => {
					t.group = t.toArray();
					return {
						Id: t.group.firstOrDefault()
							.GeographyZoneToGeographyZoneTypeId,
						GeographyZoneId:
							this.currentlySelectedZone.GeographyZoneId,
						GeographyZoneTypeId:
							t.group.firstOrDefault().GeographyZoneTypeId,
						GeographyZoneToGeographyZoneTypeName:
							t.group.firstOrDefault().Name,
						GeographyZoneToGeographyZoneTypeSpeedLimit:
							t.group.firstOrDefault().SpeedLimit,
						GeographyZoneToGeographyZoneTypeSpeedLimitUnits:
							t.group.firstOrDefault().SpeedLimitUnits,
						Assets: t.group
							.where((ty) => {
								return (
									ty.GeographyZoneToGeographyZoneTypeId ==
									t.key
								);
							})
							.select((a) => {
								return {
									AssetId: a.AssetId,
									AssetName: a.AssetName,
								};
							})
							.toArray(),
					};
				})
				.toArray();

			console.log(newType);
			let zoneIndex = this.zonesAndZonesInstances.findIndex((zone) => {
				return (
					this.currentlySelectedZone.GeographyZoneId ===
					zone.GeographyZoneId
				);
			});
			if (this.editingExistingZoneType === true) {
				let zoneTypeIndex = this.zonesAndZonesInstances[
					zoneIndex
				].Types.findIndex((type) => {
					return (
						this.currentlySelectedZoneTypeInstance.Id === type.Id
					);
				});
				this.zonesAndZonesInstances[zoneIndex].Types[
					zoneTypeIndex
				].GeographyZoneToGeographyZoneTypeName =
					newType[0].GeographyZoneToGeographyZoneTypeName;
				this.zonesAndZonesInstances[zoneIndex].Types[
					zoneTypeIndex
				].GeographyZoneTypeId = newType[0].GeographyZoneTypeId;
				this.zonesAndZonesInstances[zoneIndex].Types[
					zoneTypeIndex
				].GeographyZoneToGeographyZoneTypeSpeedLimit =
					newType[0].GeographyZoneToGeographyZoneTypeSpeedLimit;
				this.zonesAndZonesInstances[zoneIndex].Types[
					zoneTypeIndex
				].GeographyZoneToGeographyZoneTypeSpeedLimitUnits =
					newType[0].GeographyZoneToGeographyZoneTypeSpeedLimitUnits;
				this.zonesAndZonesInstances[zoneIndex].Types[
					zoneTypeIndex
				].Assets = newType[0].Assets;
				console.log(
					this.zonesAndZonesInstances[zoneIndex].Types[zoneTypeIndex]
				);
			} else if (this.editingExistingZoneType === false) {
				this.zonesAndZonesInstances[zoneIndex].Types.push(newType[0]);
				console.log(this.zonesAndZonesInstances[zoneIndex].Types);
			}

			if (this.editingExistingZoneType === true) {
				this.utilityService.showToastMessageShared({
					type: 'info',
					message:
						"'ZoneType " +
						newType[0].GeographyZoneToGeographyZoneTypeName +
						"' has been EDITED.",
				});
				// this.toastr.info(
				// 	"'ZoneType " +
				// 		newType[0].GeographyZoneToGeographyZoneTypeName +
				// 		"' has been EDITED."
				// );
			} else if (this.editingExistingZoneType === false) {
				this.utilityService.showToastMessageShared({
					type: 'info',
					message:
						"'ZoneType " +
						newType[0].GeographyZoneToGeographyZoneTypeName +
						"' has been ADDED.",
				});
				// this.toastr.info(
				// 	"'ZoneType " +
				// 		newType[0].GeographyZoneToGeographyZoneTypeName +
				// 		"' has been ADDED."
				// );
			}
			this.showEditingPanel = false;
			this.editingZoneType = false;
			this.defaultMiddleWidth = 80;
			this.defaultRightWidth = 0;
			this.currentlySelectedZone = undefined;
			this.currentlySelectedZoneTypeInstance = undefined;
		});
	}

	closeAllPolygonWindows() {
		this.listOfPolygons.forEach((listpolygon) => {
			listpolygon.infoWindowForZoneInstance.close();
		});
	}

	loadGeographyZoneAndEdit(zone, isGoogleMapPolygonInstance) {
		let returnedZone = this.showPolygonInfo(
			zone,
			isGoogleMapPolygonInstance
		);
		this.editGeographyZone(returnedZone);
	}

	loadGeographyZoneTypeAndEdit(zoneTypeInstance, zone) {
		this.showPolygonInfo(zone, false, true);
		this.editGeographyZoneType(zoneTypeInstance);
	}

	showPolygonInfo(
		polygon,
		isGoogleMapPolygonInstance,
		shouldNotEditPolygon?
	) {
		//close all infowindows that are open, then show one in question
		this.closeAllPolygonWindows();

		this.listOfPolygons.forEach((polygonIteration) => {
			let isEditable = polygonIteration.getEditable();
			let isDraggable = polygonIteration.getDraggable();
			if (isEditable) {
				polygonIteration.setEditable(false);
			}
			if (isDraggable) {
				polygonIteration.setDraggable(false);
			}
		});
		console.log(polygon);
		let polygonToUse;
		console.log(this.listOfPolygons);
		if (isGoogleMapPolygonInstance === false) {
			//we need to look it up in the list that we have.
			polygonToUse = this.listOfPolygons.find((polygonIteration) => {
				return (
					polygonIteration.zoneInstanceObjectFromSql
						.GeographyZoneId === polygon.GeographyZoneId
				);
			});
		} else {
			polygonToUse = polygon;
		}
		if (!shouldNotEditPolygon) {
			polygonToUse.setDraggable(true);
			polygonToUse.setEditable(true);
		}

		let pathAsArray = this.getPathAsArrayFromSQLZoneInstance(
			polygonToUse.zoneInstanceObjectFromSql.GeographyZoneLatLonPairs
		);
		//here we show the polygonToUse info on the map and in the form.
		var bounds = new google.maps.LatLngBounds();
		for (var i = 0; i < pathAsArray.length; i++) {
			bounds.extend(pathAsArray[i]);
		}
		let zoneCenter = bounds.getCenter();
		polygonToUse.infoWindowForZoneInstance.setPosition(zoneCenter);
		polygonToUse.infoWindowForZoneInstance.open(
			this.mapForGeofencing,
			polygonToUse
		);
		this.mapForGeofencing.fitBounds(bounds);
		this.currentlySelectedZone = polygonToUse.zoneInstanceObjectFromSql;
		this.currentlySelectedGooglePolygon = polygonToUse;
		return polygonToUse.zoneInstanceObjectFromSql;
	}

	getPathAsArrayFromSQLZoneInstance(latlongpairs) {
		let pathAsString = latlongpairs.split('|');
		let pathAsArray = [];
		pathAsString.forEach((pathInstance) => {
			let latLongArray = pathInstance.split(',');
			let latLongObject = {
				lat: parseFloat(latLongArray[0]),
				lng: parseFloat(latLongArray[1]),
			};
			pathAsArray.push(latLongObject);
		});
		return pathAsArray;
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = _.uniq(this.siteAssets.map((a: any) => this.dataService.cache.assetsObject[a.Id].TagNamePrefix	)).join();
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;


		this.signalRCore.joinGroups();


		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false,
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
		.createSubjectAndSubscribe({ Id: this.guid,
									WidgetName: "Locate All GSE",
									TagNamePrefix: tagNamePrefixesString.split(",")
		 })
		.then((data) => {
			//subscribe to existing subject
			Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

			var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
			activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
				//console.log(this.componentName + "Updating tag we care about: %O", tag);
				this.updateGoogleMapMarker(tag);
			});
		});

	}

	updateGoogleMapMarker(tagObj: ITag) {

		if (!_.isNil(this.markers) && this.markers?.length !== 0) {
			let infoWindowToUpdate = this.infoWindows.find((iw) => {
				return iw.asset.Id === tagObj.Asset.Id;
			});
			if (!_.isNil(infoWindowToUpdate)) {
				this.setContentForInfoWindow(infoWindowToUpdate, false);
			}
			if (
				tagObj.JBTStandardObservation?.Id === 54260 ||
				tagObj.JBTStandardObservation?.Id === 54261
			) {
				let marker = this.markers.find((marker) => {
					return marker.Id === tagObj.Asset.Id;
				});
				if (!_.isNil(marker)) {
					this.setMarkerLocationForMap(marker);
				}
			}
		}
	}

	recreateTagString(assetObj) {
		let gseTagString = '';
		this.tagOptions.forEach((tag) => {
			let tempString = this.getTagValueString(
				assetObj,
				tag.standardObservationId,
				tag.tagName
			);
			// gseTagString.concat(
			//   this.getTagValueString(assetObj, tag.standardObservationId, tag.tagName)
			// );
			gseTagString = gseTagString + tempString;
		});
		return gseTagString;
	}

	getTagValueString(assetObject, observationId, idName) {
		let getTagValueReturn = this.getTagValue(assetObject, observationId);
		if (getTagValueReturn === null) {
			return '';
		} else {
			return `<div id='${idName}${assetObject.Id}' style='cursor: pointer; color: black;'>${getTagValueReturn}</div>`;
		}
	}

	getTagValue(assetObject, observationId, needObject?) {
		let tag = assetObject.Tags.find(
			(tag) => tag.JBTStandardObservationId === observationId
		);
		if (tag == undefined) {
			return null;
		} else if (needObject === undefined) {
			return `${tag.JBTStandardObservation?.Name}: ${tag.Value}`;
		} else if (needObject === true) {
			return tag;
		}
	}

	setContentForInfoWindow(infoWindow, firstTimeSettingInfoWindow: boolean) {
		//lookup most recent information about GSE and use that to populate data.
		let gse = this.dataService.cache.assets.find((assetToFind) => {
			return assetToFind.Id === infoWindow.asset.Id;
		});

		if (!_.isNil(gse)) {
			let marker = this.markers.find((mrkr) => {
				return mrkr.asset.Id === infoWindow.asset.Id;
			});

			let contentStringForInfoWindow = `<div class='firstHeading' style='font-size: 12px'><b><span style='color:teal;'> ${
				gse.Site?.Name
			} ${gse.Name}</div></b>${this.recreateTagString(gse)}</div>`;

			let evaluationObject: any =
				this.gseService.determineAssetImageFromAssetTypeForMap(gse);
			let evaluationObjectForUserDefinedAlarms: any =
				this.gseService.determineUserDefinedAlarmsImageFromAssetTypeForTable(
					gse,
					true
				);

			if (
				evaluationObjectForUserDefinedAlarms.alarmType !== '' &&
				evaluationObjectForUserDefinedAlarms.icon !== undefined
			) {
				if (
					evaluationObjectForUserDefinedAlarms.alarmType ===
					'Critical'
				) {
					contentStringForInfoWindow =
						contentStringForInfoWindow +
						`<div style="color: black;" >Most Severe Active User Defined Alert: </div><img class="blink-image" src="${evaluationObjectForUserDefinedAlarms.icon.url}" style="height: 30px" />`;
				} else {
					contentStringForInfoWindow =
						contentStringForInfoWindow +
						`<div style="color: black;" >Most Severe Active User Defined Alert: </div><img src="${evaluationObjectForUserDefinedAlarms.icon.url}" style="height: 30px" />`;
				}
				let stringOfHtmlCurrentAlarms =
					'<div style="color: black;" >List of Active User Defined Alerts:</div>';
				evaluationObjectForUserDefinedAlarms.currentListOfAlarms.forEach(
					(alert) => {
						stringOfHtmlCurrentAlarms =
							stringOfHtmlCurrentAlarms +
							`<div style="padding-left: 5px; color: black;">${alert.Value} - ${alert.JBTStandardObservation.Name}</div>`;
					}
				);
				contentStringForInfoWindow =
					contentStringForInfoWindow + stringOfHtmlCurrentAlarms;
			}

			if (
				firstTimeSettingInfoWindow === false &&
				evaluationObject.icon !== undefined
			) {
				marker.setIcon(evaluationObject.icon);
				if (evaluationObject.alarmType === 'Critical') {
					marker.setAnimation(google.maps.Animation.BOUNCE);
				}
			}

			infoWindow.setContent(contentStringForInfoWindow);
		}
	}

	setMarkerLocationForMap(marker) {
		let lat = this.dataService.cache.assetsObject[marker.Id].Tags.find(
			(t) => t.JBTStandardObservationId == 54260
		);
		let long = this.dataService.cache.assetsObject[marker.Id].Tags.find(
			(t) => t.JBTStandardObservationId == 54261
		);
		let myLatlng = new google.maps.LatLng(lat.Value, long.Value);
		marker.setPosition(myLatlng);
	}

	initMap() {
		if (!this.widgetObject.WidgetSiteId && !this.fleetId) {
			return;
		}
		console.log(this.widgetObject);
		this.listOfPolygons = [];
		this.currentlySelectedZone = undefined;
		this.zonesAndZonesInstances = undefined;
		this.zoneTypes = undefined;
			this.zoneTypes = _.cloneDeep(
				Global.User.currentUser.GeofencingZoneTypes
			);
			this.zonesAndZonesInstances =
				Global.User.currentUser.GeofencingZoneTypes;

			var sqlStatement =
				'API.Geofencing_GetGeographyZonesBySiteId '
			if(this.widgetObject.WidgetSiteId){
				sqlStatement += '@SiteId=' +
				this.widgetObject.WidgetSiteId;
			} else if(this.fleetId){
				sqlStatement += '@FleetId=' +
				this.fleetId;
			}
			// var sqlStatement = "API.GeofencingGetAllZonesBySiteId @SiteId=" + this.widgetObject.WidgetSiteId;
			console.log('sqlStatement = ' + sqlStatement);
			this.dataService
				.SQLActionAsPromise(sqlStatement)
				.then((data: any) => {
					console.log(data);

					this.zonesAndZonesInstances = data
						.groupBy((group) => group.GeographyZoneId)
						.select((n) => {
							n.group = n.toArray();
							return {
								GeographyZoneId: n.key,
								GeographyZoneBackgroundColor:
									n.group.firstOrDefault()
										.GeographyZoneBackgroundColor,
								GeographyZoneTypeDefaultBackgroundColor:
									n.group.firstOrDefault()
										.GeographyZoneTypeDefaultBackgroundColor,
								GeographyZoneName:
									n.group.firstOrDefault().GeographyZoneName,
								GeographyZoneDescription:
									n.group.firstOrDefault()
										.GeographyZoneDescription,
								GeographyZoneLatLonPairs:
									n.group.firstOrDefault()
										.GeographyZoneLatLonPairs,
								Types: n.group
									.groupBy(
										(typ: any) => typ.GeographyZoneTypeId
									)
									.select((t) => {
										t.group = t.toArray();
										return {
											GeographyZoneTypeId: t.key,
											GeographyZoneId:
												t.group.firstOrDefault()
													.GeographyZoneId,
											Id: t.group.firstOrDefault()
												.GeographyZoneToGeographyZoneTypeId,
											GeographyZoneTypeName:
												t.group.firstOrDefault()
													.GeographyZoneTypeName,
											GeographyZoneToGeographyZoneTypeName:
												t.group.firstOrDefault()
													.GeographyZoneToGeographyZoneTypeName,
											GeographyZoneToGeographyZoneTypeSpeedLimit:
												t.group.firstOrDefault()
													.GeographyZoneToGeographyZoneTypeSpeedLimit !==
												null
													? parseFloat(
															t.group.firstOrDefault()
																.GeographyZoneToGeographyZoneTypeSpeedLimit
													  )
													: t.group.firstOrDefault()
															.GeographyZoneToGeographyZoneTypeSpeedLimit,
											GeographyZoneToGeographyZoneTypeSpeedLimitUnits:
												t.group.firstOrDefault()
													.GeographyZoneToGeographyZoneTypeSpeedLimitUnits,
											GeographyZoneToGeographyZoneTypeJBTStandardObservationSeverityLevel:
												t.group.firstOrDefault()
													.GeographyZoneToGeographyZoneTypeJBTStandardObservationSeverityLevel,
											Assets: t.group
												.where((ty) => {
													return (
														ty.GeographyZoneTypeId ==
														t.key
													);
												})
												.select((a) => {
													return {
														AssetId: a.AssetId,
														AssetName: a.AssetName,
													};
												})
												.toArray(),
										};
									})
									.toArray(),
							};
						})
						.toArray();

					console.log(this.zonesAndZonesInstances);
					this.zonesAndZonesInstances.forEach((zone) => {
						zone.Types = zone.Types.filter((zoneType) => {
							return zoneType.Id !== 0;
						});
					});
					let siteForMapAssetIds = [];

					if(this.widgetObject.WidgetSiteId){
						this.siteForMap = this.dataService.cache.sites.find(
							(site) => {
								return site.Id == this.widgetObject.WidgetSiteId;
							}
						);

						this.siteForMap.Assets.forEach((asset) => {
							siteForMapAssetIds.push(asset.Id.toString());
						});
						siteForMapAssetIds.join(',');
					} else if(this.fleetId){
						this.siteAssets.forEach((asset) => {
							siteForMapAssetIds.push(asset.Id.toString());

						})
					}



					this.dataService
						.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
							siteForMapAssetIds.join(','),
							false,
							[54260, 54261].toString()
						)
						.subscribe((data: any) => {
							this.tagList = data.map((tag) => tag.Id);
							this.getSignalRUpdates();

							this.initMapOnly();
						});
				});
	}
}
