import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, ViewEncapsulation, EventEmitter, OnDestroy, NgZone, AfterViewInit } from "@angular/core";
import { GridSettings } from "../../_models/grid-settings.interface";
import { process } from "@progress/kendo-data-query";

import { filter } from "rxjs/operators";
import * as moment from "moment";

import { PageChangeEvent } from "@progress/kendo-angular-grid";

import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";

import _ from "lodash";
import { ChartComponent } from "@progress/kendo-angular-charts";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { DashboardService } from "../../services/dashboard.service";
import { UtilityService } from "../../services/utility.service";
import { Global } from "../../_constants/global.variables";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { PerfectTurnComponent } from "../perfect-turn/perfect-turn.component";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITag } from "../../_models/tag.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";
import { IAsset } from "../../_models/asset.model";
import { setTime } from "ngx-bootstrap/chronos/utils/date-setters";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'lib-site-all-gate-summary',
	templateUrl: './site-all-gate-summary.component.html',
	styleUrls: ['./site-all-gate-summary.component.scss'],
})
export class SiteAllGateSummaryComponent implements OnInit, OnDestroy {
	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() public dashboardTimeZoneChanged: EventEmitter<any>;
	@ViewChild('allGateGrid') allGateGrid: KendoGridParentComponent;
	@ViewChildren('gpuCharts') gpuCharts: any;
	@ViewChildren('pcaCharts') pcaCharts: any;

	fullDataCache$: any;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	timeZoneType: any;
	assetRuntimeUpdateInterval: NodeJS.Timeout;
	assetsWithTags: any;
	assetIds: any;
	signalRAssetIds: any;
	pcaOrAhuAssetIds: any;
	gpuAssetIds: any;
	ahuAssetsAvailable: boolean;
	iconHeight: number;
	theme: string;
	gpuAmpsChartColumnHidden: boolean;
	pcaDischChartColumnHidden: boolean;
	pageSize: number;
	assetDataArray = [];
	runtimeData = [];
	siteGates = [];
	componentName: string = 'site-all-gate-summary: ';

	subscriptions: Subscription[] = [];
	tagGraphSingleModalSubscription: Subscription;
	pbbSummaryModalSubscription: Subscription;
	pcaSummaryModalSubscription: Subscription;
	gpuSummaryModalSubscription: Subscription;
	fullDataCacheSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	private displayAllSignalRMessages: boolean = false;

	private PBBAircraftDockedIds = [12245]; // Aircraft Docked
	private PBBDrivingIds = [12825, 14864]; // DO: Horizontal Drive Brake, DI: Joystick Reverse
	private PCADischTempIds = [2736]; // Discharge Temperature - Aircraft
	private PCADischModeIds = [15220, 15215]; // Mode Bridge, Mode Selection - Aircraft Output,
	private PCAOpModeIds = [15219]; // Mode of Operation
	private GPUAmpsOutIds = [1942]; // Amps Output Average
	private GPUSwitchedOverIds = [15898, 15166]; // Aircraft GPU Power On, Aircraft On GPU Power
	private PCAStatusIds = [12374]; // Unit On
	private StatusIds = [12374]; // Unit On
	private CommsLossIds = [4331]; // Comms Loss
	private OutOfServiceIds = [54284]; // Is Out of Service
	private MaintenanceModeIds = [54283]; // Is in Maintenance Mode
	private ActiveAlarmIds = [12323]; // Alarm Is Active
	private CriticalActiveAlarmIds = [12349]; // Critical Alarm Is Active

	private joinedSignalRGroupsViaParentSystemId: string = null;
	private checkAlarmNotificationsInterval;
	public guid: string;

	private allStdIds = [
		...this.PBBAircraftDockedIds,
		...this.PBBDrivingIds,
		...this.PCADischTempIds,
		...this.PCADischModeIds,
		...this.PCAOpModeIds,
		...this.GPUAmpsOutIds,
		...this.GPUSwitchedOverIds,
		...this.StatusIds,
		...this.CommsLossIds,
		...this.OutOfServiceIds,
		...this.MaintenanceModeIds,
		...this.ActiveAlarmIds,
		...this.CriticalActiveAlarmIds,
	];

	gridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: 'and',
				filters: [],
			},
			take: 15,
		},
		columnsConfig: [
			{
				field: 'gate',
				title: 'Gate',
				filterable: true,
				_width: 65,
				minResizableWidth: 65,
			},
			{
				field: 'NextArrival',
				title: 'Next Arrival',
				filterable: false,
				_width: 95,
				minResizableWidth: 95,
			},
			{
				field: 'pbbAircraftDocked',
				title: 'Aircraft',
				filterable: false,
				_width: 95,
				minResizableWidth: 95,
			},
			{
				field: 'pbbOn',
				title: 'PBB',
				filterable: false,
				_width: 95,
				minResizableWidth: 95,
			},
			{
				field: 'pcaOn',
				title: 'PCA',
				filterable: false,
				_width: 95,
				minResizableWidth: 95,
			},
			{
				field: 'gpuOn',
				title: 'GPU',
				filterable: false,
				_width: 95,
				minResizableWidth: 95,
			},
			{
				field: 'ahuOn',
				title: 'AHU',
				filterable: false,
				_width: 95,
				minResizableWidth: 95,
			},
			{
				field: 'gpuAmpsOutAvg',
				title: 'Avg Amps Out',
				filterable: true,
				filter: 'numeric',
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'gpuAmpsOutChart',
				title: 'Avg Amps Out Trend (Last Hour)',
				filterable: false,
				_width: 235,
				minResizableWidth: 235,
			},
			{
				field: 'pcaDischTemp',
				title: 'Disch Temp (°F)',
				filterable: true,
				filter: 'numeric',
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'pcaDischTempChart',
				title: 'Disch Temp Trend (Last Hour)',
				filterable: false,
				_width: 190,
				minResizableWidth: 190,
			},
			{
				field: 'pcaDischMode',
				title: 'Disch Mode',
				filterable: true,
				_width: 100,
				minResizableWidth: 100,
			},
			{
				field: 'pcaOpMode',
				title: 'Operation Mode',
				filterable: true,
				_width: 125,
				minResizableWidth: 125,
			},
			{
				field: 'pbbRuntime',
				title: 'PBB Runtime (min)',
				filterable: true,
				_width: 120,
				minResizableWidth: 120,
			},
			{
				field: 'pcaRuntime',
				title: 'PCA Runtime (min)',
				filterable: true,
				_width: 120,
				minResizableWidth: 120,
			},
			{
				field: 'gpuRuntime',
				title: 'GPU Runtime (min)',
				filterable: true,
				_width: 120,
				minResizableWidth: 120,
			},
			{
				field: 'ahuRuntime',
				title: 'AHU Runtime (min)',
				filterable: true,
				_width: 120,
				minResizableWidth: 120,
			},
			{
				field: 'GateAlarm',
				title: 'Fault',
				filterable: false,
				_width: 60,
				hidden: false,
				minResizableWidth: 60,
			},
		],
	};
	siteUTCTimeOffset: any;
	isWidgetInView: boolean = false;
	widgetIsInViewSubscription: Subscription;
	checkPcaDischTempInterval: NodeJS.Timeout;
	checkGpuAmpsOutInterval: NodeJS.Timeout;
	constructor(
		private dataService: DataService,
		private signalRCore: SignalRCoreService,
		private kendoSettingsService: KendoSettingsService,
		private ref: ChangeDetectorRef,
		private dashboardService: DashboardService,
		public dialog: MatDialog,
		private utilityService: UtilityService,
		private zone: NgZone
	) {}

	ngOnInit() {
		var service = this;
		this.guid = this.dataService.guid();
		this.isDataLoading = true;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}

		if (this.widgetResizedEvent) {
			this.subscriptions.push(
				this.widgetResizedEvent.subscribe((data) => {})
			);
		}

		this.subscriptions.push(
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
			})
		);

		this.isWidgetInView = this.widgetObject.isWidgetInView;
		this.widgetIsInViewSubscription =
			this.dashboardService.widgetInViewChanged$.subscribe(
				(data: any) => {
					if (data.widgetId === this.widgetObject.WidgetId) {
						this.isWidgetInView = data.isInView;
					}
				}
			);

		if (this.dashboardTimeZoneChanged) {
			this.subscriptions.push(
				(this.dashboardTimeZoneChangedSubscription =
					this.dashboardTimeZoneChanged.subscribe((data) => {
						console.log(data);
						let foundWidgetWithSameWidgetId = data.find(
							(widgetThatWasChanged) => {
								return (
									widgetThatWasChanged.WidgetId ===
									this.widgetObject.WidgetId
								);
							}
						);

						if (!_.isNil(foundWidgetWithSameWidgetId)) {
							console.log('Widget Time Zone Changed');
							this.timeZoneType =
								this.dashboardService.determineTimeZoneType(
									this.widgetObject
								);
						}
					}))
			);
		}

		

	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.assetRuntimeUpdateInterval) {
			clearInterval(this.assetRuntimeUpdateInterval);
		}
		if (this.checkGpuAmpsOutInterval) {
			clearInterval(this.checkGpuAmpsOutInterval);
		}
		if (this.checkPcaDischTempInterval) {
			clearInterval(this.checkPcaDischTempInterval);
		}
		this.subscriptions.forEach((subscription: Subscription) =>
			subscription.unsubscribe()
		);
		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}
		this.assetDataArray.forEach((dataItem) => {
			clearInterval(dataItem.pcaDischTempChartDataInterval);
			clearInterval(dataItem.gpuAmpsOutChartInterval);
		});

		if (this.checkAlarmNotificationsInterval) {
			clearInterval(this.checkAlarmNotificationsInterval);
		}
	}

	initialize() {
		Global.User.DebugMode &&
			console.log(this.componentName + ': initialize invoked...');
		if (
			Global.User.currentUser.Username != 'kirk' &&
			Global.User.DebugMode
		) {
			this.displayAllSignalRMessages = true;
		}

		if (this.widgetObject.WidgetSiteId == undefined) {
			Global.User.DebugMode &&
				console.log(this.componentName + ': No site Id provided.');
			this.isDataLoading = false;
			return;
		}

		this.siteUTCTimeOffset =
			this.dataService.cache.sitesObject[
				this.widgetObject.WidgetSiteId
			].UTCTimeOffset;
		this.timeZoneType = this.dashboardService.determineTimeZoneType(
			this.widgetObject
		);
		this.theme = Global.Theme;
		this.iconHeight = Global.isMobile ? 15 : 25;

		// get assets for site
		this.assetsWithTags = this.dataService.cache.assets
			.filter((a: any) =>
				_.isNil(a.SiteId)
					? a.ParentSystem.SiteId == this.widgetObject.WidgetSiteId
					: a.SiteId == this.widgetObject.WidgetSiteId
			)
			.filter((a: any) => ['PBB', 'PCA', 'GPU', 'AHU'].includes(a.Name))
			.filter(
				(a: any) =>
					a.ParentSystem.Assets && a.ParentSystem.Assets.length > 0
			)
			.map((a) => ({
				Asset: a,
				Id: a.Id,
				Type: a.Name,
				Tags: a.Tags,
				AllTagsPopulated: false,
				BaseUnitImageURL: a.BaseUnitImageURL,
				SiteId: a.SiteId,
				SiteName: a.Site.Name,
				Site: a.Site,
				GateName: a.ParentSystem?.Name,
				ParentSystem: a.ParentSystem,
				GateId: a.ParentSystem?.Id,
				TerminalName: a.ParentSystem?.ParentSystem?.Name,
				TerminalId: a.ParentSystem?.ParentSystem?.Id,
				TagNamePrefix: a.TagNamePrefix
			}));

		let gateNames = this.assetsWithTags.map((a) => a.GateName);
		this.assetIds = this.assetsWithTags
			.map((a) => a.Id.toString())
			.join(',');
		this.signalRAssetIds = this.assetsWithTags
			.map((a: any) => 'A' + a.Id.toString())
			.join(); //-- Asset groups must have the letter 'A' in front of the assetId to join the groups in the SignalR hub. --Kirk T. Sherer, November 8, 2021.

		this.pcaOrAhuAssetIds = this.assetsWithTags
			.filter((a) => a.Type == 'PCA' || a.Type == 'AHU')
			.map((a) => a.Id.toString());
		this.gpuAssetIds = this.assetsWithTags
			.filter((a) => a.Type == 'GPU')
			.map((a) => a.Id.toString());

		this.subscriptions.push(
			this.dataService
				.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
					this.assetIds,
					false,
					this.allStdIds.toString()
				)
				.subscribe((data) => {
					Global.User.DebugMode &&
						console.log(
							this.componentName +
								': GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O',
							data
						);
					this.sortGateNames(gateNames);
					this.getRuntimeData(data);
				})
		);

		if (this.widgetObject.WidgetKendoUIJson) {
			let jsonObjectParsed =
				this.kendoSettingsService.parseReturnedSettingsForDates(
					this.widgetObject.WidgetKendoUIJson
				);
			let returnedParsedObject = jsonObjectParsed[0]; // the first item in the array is the gridSettings for the first tab of data for this widget.

			//We pass it into the function in the kendo service to compare what has been saved vs the template declaration of columns to make sure they get the lastest updates.
			this.gridSettings = this.mapGridSettings(
				this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(
					this.gridSettings,
					returnedParsedObject
				)
			);
			if (this.allGateGrid) {
				this.allGateGrid.gridDataSubject.next(this.assetDataArray);
			}
			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(
				[this.gridSettings.columnsConfig],
				this.widgetObject.WidgetId
			);

			// Figure out if sparkline columns are hidden
			this.gpuAmpsChartColumnHidden =
				this.gridSettings.columnsConfig.find(
					(column) => column.field == 'gpuAmpsOutChart'
				)?.hidden
					? true
					: false;
			this.pcaDischChartColumnHidden =
				this.gridSettings.columnsConfig.find(
					(column) => column.field == 'pcaDischTempChart'
				)?.hidden
					? true
					: false;
		} else {
			if (this.allGateGrid) {
				this.allGateGrid.gridDataSubject.next(this.assetDataArray);
			}
		}
		this.widgetObject.isDisplayDataLive = true;
		
		
	}

	getRuntimeData(tagData) {
		Global.User.DebugMode &&
			console.log(this.componentName + ': GetRuntimeData Invoked');
		// Get Runtime Data
		let statement = `GSGetRuntimeForAssetsBySiteList @siteNames = '${this.widgetObject.WidgetSiteName}', @assetTypes = 'PBB,PCA,GPU,AHU'`;
		Global.User.DebugMode &&
			console.log(this.componentName + ': SQL Statement = ' + statement);
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName + ': ' + statement + ' data = %O',
					data
				);
			this.runtimeData = data;
			this.getInitialData(tagData);
		});
	}

	checkAlarmNotifications() {

		this.assetDataArray.forEach(g => {

			var gate = this.dataService.cache.systems.find((gate) => {
				return gate.SiteId == this.widgetObject.WidgetSiteId && gate.Name == g.gate;
			});

			if(gate) {
				let userAlert = this.dataService.cache.emailTagUsers.find((a) =>
					a.UserId == Global.User.currentUser.Id &&
					(a.CriticalGateSystemId == gate.Id || a.GateSystemId == gate.Id)
				);

				g.alertNotification = userAlert == undefined ? false : true;

				// set the title with alert info
				if(userAlert != undefined) {

					let title = userAlert.CriticalGateSystemId == gate.Id ? "Critical " : "";
					title += "Alert notifications set for " + gate.Name;
					let alertObject = this.GetAlertNotificationDetails(userAlert);

					if(alertObject.Email == true && alertObject.TextMessage == true) {
						title += " email and text delivery";
					}
					else {
						title += alertObject.Email == true ? " email delivery" : "";
						title += alertObject.TextMessage == true ? " text delivery" : "";
					}

					title += " on " + alertObject.DaysSelected;
					title += " during "+ alertObject.HoursSelected;

					g.alertNotificationTitle = title;
				}
				else {
					g.alertNotificationTitle = "Click to set alert management settings.";
				}

			}

		});

	}


	GetAlertNotificationDetails(userAlert) : any {

		let alertObject: any = {
			siteId: 0,
			siteName: '',
			gateSystemId: 0,
			gateSystemName: '',
			category: '',
			Email: 0,
		};


		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if(userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		}
		else {
			alertObject.DaysSelected = "select days";
		}


		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if(userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		}
		else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}

	// Get the initial data from the cache
	getInitialData(tags: any[]) {
		Global.User.DebugMode &&
			console.log(this.componentName + ': GetInitialData Invoked');

		this.ahuAssetsAvailable = tags?.any((t) => t.Asset.Name == 'AHU');
		if (!this.ahuAssetsAvailable) {
			this.gridSettings.columnsConfig.filter(
				(c) => c.field == 'ahuOn'
			)[0].hidden = true;
			this.gridSettings.columnsConfig.filter(
				(c) => c.field == 'ahuRuntime'
			)[0].hidden = true;
		}

		this.assetDataArray.forEach((row, rowIndex) => {
			row.gridIndex = rowIndex;

			// Get Data for AHU Asset
			// let ahuAssetId = tags.find(
			// 	(t) =>
			// 		t.Asset.ParentSystem.Name == row.gate &&
			// 		t.Asset.Name == 'AHU'
			// )?.AssetId;

			let ahuAssetId = this.assetsWithTags.firstOrDefault((asset: any) => {
				//console.log("pbbAsset = %O", asset);
				return asset.ParentSystem.Name == row.gate && asset.Asset.Name == 'AHU'
			})?.Id;

			let ahuTags = tags.filter((t) => t.Asset.Id == ahuAssetId);
			row.ahuId = ahuAssetId;

			let ahuRuntime = this.runtimeData.find(
				(data) => data.AssetId == row.ahuId
			);
			if (ahuRuntime) {
				row.ahuRuntime = Math.abs(
					moment
						.utc()
						.diff(moment.utc(ahuRuntime.UnitOnDate), 'minutes')
				);
				row.ahuOnDate = ahuRuntime.UnitOnDate;
			}

			row.ahuOn = ahuTags
				.filter((t) =>
					this.StatusIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;

			row.ahuAlarmActive = ahuTags
				.filter((t) => {
					return this.ActiveAlarmIds.includes(
						t.JBTStandardObservationId
					);
				})
				.some((t) => {
					return t.Value == '1';
				})
				? true
				: false;
			row.ahuCriticalAlarmActive = ahuTags
				.filter((t) => {
					return this.CriticalActiveAlarmIds.includes(
						t.JBTStandardObservationId
					);
				})
				.some((t) => {
					return t.Value == '1';
				})
				? true
				: false;
			row.ahuCommsLoss = ahuTags
				.filter((t) =>
					this.CommsLossIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.ahuOutOfService =
				ahuTags.find((t) => t.JBTStandardObservationId == 54284)
					?.Value == '1'
					? true
					: false;
			row.ahuMaintenanceMode =
				ahuTags.find((t) => t.JBTStandardObservationId == 54283)
					?.Value == '1'
					? true
					: false;

			// Get Data for PBB Asset
			// let pbbAssetId = tags.find(
			// 	(t) =>
			// 		t.Asset.ParentSystem.Name == row.gate &&
			// 		t.Asset.Name == 'PBB'
			// )?.Asset.Id;

			let pbbAssetId = this.assetsWithTags.firstOrDefault((asset: any) => {
				//console.log("pbbAsset = %O", asset);
				return asset.ParentSystem.Name == row.gate && asset.Asset.Name == 'PBB'
			})?.Id;

			let pbbTags = tags.filter((t) => t.Asset.Id == pbbAssetId);
			row.pbbId = pbbAssetId;

			let pbbRuntime = this.runtimeData.find(
				(data) => data.AssetId == row.pbbId
			);
			if (pbbRuntime) {
				row.pbbRuntime = Math.abs(
					moment
						.utc()
						.diff(moment.utc(pbbRuntime.UnitOnDate), 'minutes')
				);
				row.pbbOnDate = pbbRuntime.UnitOnDate;
			}

			row.pbbAircraftDocked = pbbTags
				.filter((t) =>
					this.PBBAircraftDockedIds.includes(
						t.JBTStandardObservationId
					)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.pbbOn = row.pbbAircraftDocked;
			row.pbbDriving = pbbTags
				.filter((t) =>
					this.PBBDrivingIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.pbbOutOfService =
				pbbTags.find((t) => t.JBTStandardObservationId == 54284)
					?.Value == '1'
					? true
					: false;
			row.pbbMaintenanceMode =
				pbbTags.find((t) => t.JBTStandardObservationId == 54283)
					?.Value == '1'
					? true
					: false;
			row.pbbCommsLoss = pbbTags
				.filter((t) =>
					this.CommsLossIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.pbbAlarmActive = pbbTags
				.filter((t) => {
					return this.ActiveAlarmIds.includes(
						t.JBTStandardObservationId
					);
				})
				.some((t) => {
					return t.Value == '1';
				})
				? true
				: false;
			row.pbbCriticalAlarmActive = pbbTags
				.filter((t) => {
					return this.CriticalActiveAlarmIds.includes(
						t.JBTStandardObservationId
					);
				})
				.some((t) => {
					return t.Value == '1';
				})
				? true
				: false;
			// Get Data for PCA Asset
			// let pcaAssetId = tags.find(
			// 	(t) =>
			// 		t.Asset.ParentSystem.Name == row.gate &&
			// 		t.Asset.Name == 'PCA'
			// )?.Asset.Id;

			let pcaAssetId = this.assetsWithTags.firstOrDefault((asset: any) => {
				//console.log("pcaAsset = %O", asset);
				return asset.ParentSystem.Name == row.gate && asset.Asset.Name == 'PCA'
			})?.Id;

			let pcaOrAhuTags = tags.filter(
				(t) => t.Asset.Id == pcaAssetId || t.Asset.Id == ahuAssetId
			);
			row.pcaId = pcaAssetId;



			let pcaRuntime = this.runtimeData.find(
				(data) => data.AssetId == row.pcaId
			);
			if (pcaRuntime) {
				row.pcaRuntime = Math.abs(
					moment
						.utc()
						.diff(moment.utc(pcaRuntime.UnitOnDate), 'minutes')
				);
				row.pcaOnDate = pcaRuntime.UnitOnDate;
			}

			row.pcaOn = pcaOrAhuTags
				.filter((t) =>
					this.PCAStatusIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.pcaOutOfService =
				pcaOrAhuTags.find((t) => t.JBTStandardObservationId == 54284)
					?.Value == '1'
					? true
					: false;
			row.pcaMaintenanceMode =
				pcaOrAhuTags.find((t) => t.JBTStandardObservationId == 54283)
					?.Value == '1'
					? true
					: false;
			row.pcaCommsLoss = pcaOrAhuTags
				.filter((t) =>
					this.CommsLossIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.pcaAlarmActive = pcaOrAhuTags
				.filter((t) =>
					this.ActiveAlarmIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.pcaCriticalAlarmActive = pcaOrAhuTags
				.filter((t) => {
					return this.CriticalActiveAlarmIds.includes(
						t.JBTStandardObservationId
					);
				})
				.some((t) => {
					return t.Value == '1';
				})
				? true
				: false;
			let dischTempTag = pcaOrAhuTags.find((t) =>
				this.PCADischTempIds.includes(t.JBTStandardObservationId)
			);
			if (dischTempTag) {
				row.pcaDischTemp = parseFloat(
					parseFloat(dischTempTag.Value).toFixed(1)
				);
			}

			let dischMode =
				pcaOrAhuTags.find((t) => t.JBTStandardObservationId == 15220)
					?.Value == '1'
					? 'Bridge'
					: 'Aircraft';
			if (dischMode == 'Bridge') {
				row.pcaDischMode = dischMode;
			} else {
				let modeSelectionTag = pcaOrAhuTags.find(
					(t) => t.JBTStandardObservationId == 15215
				);
				if (modeSelectionTag) {
					switch (modeSelectionTag.Value) {
						case '1':
							row.pcaDischMode = 'Commuter';
							break;
						case '2':
							row.pcaDischMode = 'Narrow';
							break;
						case '3':
							row.pcaDischMode = 'Wide';
							break;
						case '4':
							row.pcaDischMode = 'Jumbo';
							break;
						default:
							row.pcaDischMode = 'Aircraft';
							break;
					}
				} else {
					row.pcaDischMode = dischMode;
				}
			}

			let opModeTag = pcaOrAhuTags.find((t) =>
				this.PCAOpModeIds.includes(t.JBTStandardObservationId)
			);
			if (opModeTag) {
				switch (opModeTag.Value) {
					case '1':
						row.pcaOpMode = 'Auto';
						break;
					case '2':
						row.pcaOpMode = 'Cool';
						break;
					case '3':
						row.pcaOpMode = 'Vent';
						break;
					case '4':
						row.pcaOpMode = 'Heat';
						break;
					default:
						row.pcaOpMode = 'Auto';
				}
			} else {
				row.pcaOpMode = 'Auto';
			}

			// Get Data for GPU Asset

			// let gpuAssetId = tags.find(
			// 	(t) =>
			// 		t.Asset.ParentSystem.Name == row.gate &&
			// 		t.Asset.Name == 'GPU'
			// )?.Asset.Id;

			let gpuAssetId = this.assetsWithTags.firstOrDefault((gpuAsset: any) => {
				//console.log("gpuAsset = %O", gpuAsset);
				return gpuAsset.ParentSystem.Name == row.gate && gpuAsset.Asset.Name == 'GPU'
			})?.Id;

			let gpuTags = tags.filter((t) => t.Asset.Id == gpuAssetId);
			row.gpuId = gpuAssetId;

			let gpuRuntime = this.runtimeData.find(
				(data) => data.AssetId == row.gpuId
			);
			if (gpuRuntime) {
				row.gpuRuntime = Math.abs(
					moment
						.utc()
						.diff(moment.utc(gpuRuntime.UnitOnDate), 'minutes')
				);
				row.gpuOnDate = gpuRuntime.UnitOnDate;
			}

			row.gpuOn = gpuTags
				.filter((t) =>
					this.StatusIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.gpuOutOfService =
				gpuTags.find((t) => t.JBTStandardObservationId == 54284)
					?.Value == '1'
					? true
					: false;
			row.gpuMaintenanceMode =
				gpuTags.find((t) => t.JBTStandardObservationId == 54283)
					?.Value == '1'
					? true
					: false;
			row.gpuCommsLoss = gpuTags
				.filter((t) =>
					this.CommsLossIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;
			row.gpuAlarmActive = gpuTags
				.filter((t) =>
					this.ActiveAlarmIds.includes(t.JBTStandardObservationId)
				)
				.some((t) => t.Value == '1')
				? true
				: false;

			row.gpuCriticalAlarmActive = gpuTags
				.filter((t) => {
					return this.CriticalActiveAlarmIds.includes(
						t.JBTStandardObservationId
					);
				})
				.some((t) => {
					return t.Value == '1';
				})
				? true
				: false;

			let ampsOutTag = gpuTags.find((t) =>
				this.GPUAmpsOutIds.includes(t.JBTStandardObservationId)
			);
			if (ampsOutTag) {
				row.gpuAmpsOutAvg = parseFloat(
					parseFloat(ampsOutTag.Value).toFixed(1)
				);
				row.gpuSwitchedOver =
					row.gpuOn && row.gpuAmpsOutAvg >= 6 ? true : false;
			} else {
				row.gpuSwitchedOver = gpuTags
					.filter((t) =>
						this.GPUSwitchedOverIds.includes(
							t.JBTStandardObservationId
						)
					)
					.some((t) => t.Value == '1')
					? true
					: false;
			}
		});

		this.checkAlarmNotifications();
		this.checkAlarmNotificationsInterval = setInterval(() => {
			this.checkAlarmNotifications();
		}, 10000);

		if (this.allGateGrid) {
			this.allGateGrid.gridDataSubject.next(this.assetDataArray);
		}
		Global.User.DebugMode &&
			console.log(
				this.componentName + ': this.assetDataArray = %O',
				this.assetDataArray
			);

		this.isDataLoading = false;
		if (tags != null) {
			this.getSignalRUpdates(tags.map((t) => t.Id));
		}

		this.assetRuntimeUpdateInterval = setInterval(() => {
			// Update the runtime values every minute
			this.updateAssetRuntimes();
		}, 1000 * 60);

		// Create Sparkline Charts
		if (!this.pcaDischChartColumnHidden) {
			this.createSparkLineChartForDischTemp(this.pcaOrAhuAssetIds);
		}
		if (!this.gpuAmpsChartColumnHidden) {
			this.createSparkLineChartForAmpsOut(this.gpuAssetIds);
		}
	}

	updateAssetRuntimes() {
		this.assetDataArray.forEach((row) => {
			if (row.pbbOn) {
				row.pbbRuntime = Math.abs(
					moment.utc().diff(moment.utc(row.pbbOnDate), 'minutes')
				);
			}
			if (row.pcaOn) {
				row.pcaRuntime = Math.abs(
					moment.utc().diff(moment.utc(row.pcaOnDate), 'minutes')
				);
			}
			if (row.gpuOn) {
				row.gpuRuntime = Math.abs(
					moment.utc().diff(moment.utc(row.gpuOnDate), 'minutes')
				);
			}
			if (row.ahuOn) {
				row.ahuRuntime = Math.abs(
					moment.utc().diff(moment.utc(row.ahuOnDate), 'minutes')
				);
			}
		});
	}

	mapGridSettings(gridSettings: GridSettings) {
		const state = gridSettings.state;
		Global.User.DebugMode &&
			console.log(this.componentName + ': state = %O', state);
		let emptyArray: any = [];
		return {
			state,
			columnsConfig: gridSettings.columnsConfig.sort(
				(a, b) => a.orderIndex - b.orderIndex
			),
			gridData:
				this.dataService.cache !== undefined
					? process(this.assetDataArray, state)
					: emptyArray,
		};
	}

	createSparkLineChartForDischTemp(assetIds: any) {
		// Get Chart Data
		let statement = `GetSparklineDataByAssetAndJBTStandardObId @AssetIdList = '${assetIds.join()}', @JBTStandardObId = '${2736}'`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						': GetSparklineDataByAssetAndJBTStandardObId data = %O',
					data
				);

			this.assetDataArray.forEach((dataItem) => {
				let assetData = data.filter((d) => {
					return (
						d.AssetId == dataItem.pcaId ||
						d.AssetId == dataItem.ahuId
					);
				});

				dataItem.pcaDischTempChart = [
					{
						field: 'y',
						width: 1.5,
						color:
							dataItem.pcaOpMode == 'Cool'
								? 'dodgerblue'
								: dataItem.pcaOpMode == 'Heat'
								? 'red'
								: 'forestgreen',
						data:
							assetData.length > 0
								? assetData.map((a) => ({
										y: parseFloat(
											parseFloat(a.TextValue).toFixed(1)
										),
										obsTimeFormattedLocal: moment(
											a.DateInMS
										).format('hh:mm:ss a'),
										obsTimeFormattedSite: moment(
											this.utilityService.convertFromUtcToLocalToSite(
												a.DateInMS,
												this.siteUTCTimeOffset
											)
										).format('hh:mm:ss a'),
										obsTimeFormattedUTC: moment(
											this.utilityService.convertFromUtcToLocalToSite(
												a.DateInMS,
												0
											)
										).format('hh:mm:ss a'),
										obsTimeMS: a.DateInMS,
								  }))
								: [],
					},
				];
				dataItem.pcaDischTempChartData = _.cloneDeep(
					dataItem.pcaDischTempChart[0].data
				);
			}); // end of foreach

			this.checkPcaDischTempInterval = setInterval(() => {
				if (this.isWidgetInView === true) {
					this.assetDataArray.forEach((dataItem) => {
						if (
							dataItem.pcaDischTempChartData.length !==
							dataItem.pcaDischTempChart[0].data.length
						) {
							this.zone.runOutsideAngular(() => {
								dataItem.pcaDischTempChart = [
									{
										field: 'y',
										width: 1.5,
										color:
											dataItem.pcaOpMode == 'Cool'
												? 'dodgerblue'
												: dataItem.pcaOpMode == 'Heat'
												? 'red'
												: 'forestgreen',
										data: _.cloneDeep(
											dataItem.pcaDischTempChartData
										),
									},
								];
							});
						}
					});
				}
			}, 30000);
		});
	}

	createSparkLineChartForAmpsOut(assetIds: any) {
		// Get Chart Data
		let statement = `GetSparklineDataByAssetAndJBTStandardObId @AssetIdList = '${assetIds.join()}', @JBTStandardObId = '${1942}'`;
		this.dataService.SQLActionAsPromise(statement).then((data: any) => {
			Global.User.DebugMode &&
				console.log(
					this.componentName +
						': GetSparklineDataByAssetAndJBTStandardObId data = %O',
					data
				);

			// Create Chart Definition
			this.assetDataArray.forEach((dataItem) => {
				let assetData = data.filter((d) => d.AssetId == dataItem.gpuId); // change to reduce later

				dataItem.gpuAmpsOutChart = [
					{
						field: 'y',
						width: 1.5,
						color: 'limegreen',
						data:
							assetData.length > 0
								? assetData.map((a) => ({
										y: parseFloat(
											parseFloat(a.TextValue).toFixed(1)
										),
										obsTimeFormattedLocal: moment(
											a.DateInMS
										).format('hh:mm:ss a'),
										obsTimeFormattedSite: moment(
											this.utilityService.convertFromUtcToLocalToSite(
												a.DateInMS,
												this.siteUTCTimeOffset
											)
										).format('hh:mm:ss a'),
										obsTimeFormattedUTC: moment(
											this.utilityService.convertFromUtcToLocalToSite(
												a.DateInMS,
												0
											)
										).format('hh:mm:ss a'),
										obsTimeMS: a.DateInMS,
								  }))
								: [],
					},
				];
				dataItem.gpuAmpsOutChartData = _.cloneDeep(
					dataItem.gpuAmpsOutChart[0].data
				);
			}); // end of foreach

			this.checkGpuAmpsOutInterval = setInterval(() => {
				if (this.isWidgetInView === true) {
					this.assetDataArray.forEach((dataItem) => {
						if (
							dataItem.gpuAmpsOutChartData.length !==
							dataItem.gpuAmpsOutChart[0].data.length
						) {
							this.zone.runOutsideAngular(() => {
								dataItem.gpuAmpsOutChart = [
									{
										field: 'y',
										width: 1.5,
										color: 'limegreen',
										data: _.cloneDeep(
											dataItem.gpuAmpsOutChartData
										),
									},
								];
							});
						}
					});
				}
			}, 30000);
		});
	}

	saveGridSettings() {
		console.log(this.allGateGrid.kendoGridParent);
		// let updatedPCADischChartColumn = event.columns.find(
		// 	(column) => column.field == 'pcaDischTempChart'
		// );
		// if (updatedPCADischChartColumn) {
		// 	if (
		// 		updatedPCADischChartColumn.hidden == false &&
		// 		this.pcaDischChartColumnHidden
		// 	) {
		// 		this.createSparkLineChartForDischTemp(this.pcaOrAhuAssetIds);
		// 	}
		// 	this.pcaDischChartColumnHidden = updatedPCADischChartColumn.hidden;
		// }

		// let updatedGPUAmpsChartColumn = event.columns.find(
		// 	(column) => column.field == 'gpuAmpsOutChart'
		// );
		// if (updatedGPUAmpsChartColumn) {
		// 	if (
		// 		updatedGPUAmpsChartColumn.hidden == false &&
		// 		this.gpuAmpsChartColumnHidden
		// 	) {
		// 		this.createSparkLineChartForAmpsOut(this.gpuAssetIds);
		// 	}
		// 	this.gpuAmpsChartColumnHidden = updatedGPUAmpsChartColumn.hidden;
		// }
		this.kendoSettingsService
			.saveGridSettings(
				[
					{
						gridObject: this.allGateGrid.kendoGridParent,
						gridState: this.gridSettings.state,
					},
				],
				this.widgetObject.WidgetId
			)
			.then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
	}

	onResized(event) {
		this.pageSize = ((event.newRect.height - 120) / 36) * 3;
		Global.User.DebugMode &&
			console.log(this.componentName + ': PageSize = ' + this.pageSize);
	}

	sortGateNames(gateNames) {
		gateNames.forEach((gate) => {
			if (!this.siteGates.includes(gate)) {
				this.siteGates.push(gate);
			}
		});

		this.siteGates = this.siteGates.sort((g1, g2) =>
			this.utilityService
				.GetGateNameSortValue(g1)
				.toString()
				.localeCompare(
					this.utilityService.GetGateNameSortValue(g2),
					'en',
					{ numeric: true }
				)
		);

		this.siteGates.forEach((gate) => {
			let rowObj = {
				gate: gate,
				gridIndex: undefined,
				pbbId: undefined,
				pbbOn: undefined,
				pbbOnDate: undefined,
				pbbRuntime: 0,
				pbbAircraftDocked: undefined,
				pbbDriving: undefined,
				pbbCommsLoss: undefined,
				pbbAlarmActive: undefined,
				pbbOutOfService: undefined,
				pbbMaintenanceMode: undefined,
				pcaId: undefined,
				pcaOn: undefined,
				pcaOnDate: undefined,
				pcaRuntime: 0,
				pcaDischTemp: 0,
				pcaDischTempChart: undefined,
				pcaDischMode: undefined,
				pcaOpMode: undefined,
				pcaCommsLoss: undefined,
				pcaAlarmActive: undefined,
				pcaOutOfService: undefined,
				pcaMaintenanceMode: undefined,
				gpuId: undefined,
				gpuOn: undefined,
				gpuOnDate: undefined,
				gpuRuntime: 0,
				gpuAmpsOutAvg: 0,
				gpuAmpsOutChart: undefined,
				gpuSwitchedOver: undefined,
				gpuCommsLoss: undefined,
				gpuAlarmActive: undefined,
				gpuOutOfService: undefined,
				gpuMaintenanceMode: undefined,
				ahuOn: undefined,
				ahuOnDate: undefined,
				ahuRuntime: 0,
			};

			this.assetDataArray.push(rowObj);
		});
	}

	getSignalRUpdates(tagIds: any) {
		let tagNamePrefixesString = this.assetsWithTags
			.map((a: any) => a.TagNamePrefix)
			.join(",");
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();
			
		this.widgetGroupSettings = {
			WidgetId: this.widgetObject.WidgetId,
			GroupList: tagNamePrefixesString,
			IsPopup: false
		};

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										WidgetName: this.widgetObject.WidgetName, 
										TagNamePrefix: tagNamePrefixesString.split(",")
			})
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);
				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTableData(tag);
				});
			});
			

	}

	updateTableData(tagObj: any) {
		//Global.User.DebugMode && console.log(this.componentName + ': updateTableData invoked. tagObj = %O', tagObj);
		let gate = tagObj.Asset.ParentSystem.Name;
		let assetType = tagObj.Asset.Name;
		let gridIndex = this.assetDataArray.findIndex((a) => a.gate == gate);
		let newValue: any, currentValue: any;

		if (
			this.PBBAircraftDockedIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].pbbAircraftDocked;
			newValue = this.getDataFromNewTag(tagObj, 'AIRCRAFTDOCKED');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].pbbOn = newValue;
				this.assetDataArray[gridIndex].pbbAircraftDocked = newValue;

				if (newValue) {
					this.assetDataArray[gridIndex].pbbOnDate =
						tagObj.DateInMilliseconds;
				} else {
					this.assetDataArray[gridIndex].pbbOnDate = undefined;
					this.assetDataArray[gridIndex].pbbRuntime = 0;
				}

				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: Aircraft Docked updated: ${newValue}`
					);
				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: PBB Status updated: ${newValue}`
					);
			}
		} else if (
			this.PBBDrivingIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].pbbDriving;
			newValue = this.getDataFromNewTag(tagObj, 'PBBDRIVING');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].pbbDriving = newValue;
				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: PBB Driving updated: ${newValue}`
					);
			}
		} else if (
			this.PCADischTempIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].pcaDischTemp;
			newValue = parseFloat(this.getDataFromNewTag(tagObj, 'DISCHTEMP'));

			if (currentValue != newValue && !isNaN(newValue)) {
				// Update Table
				this.assetDataArray[gridIndex].pcaDischTemp = newValue;

				// Update Chart
				if (
					!this.pcaDischChartColumnHidden &&
					this.assetDataArray[gridIndex].pcaDischTempChart
				) {
					let tempArray =
						this.assetDataArray[
							gridIndex
						].pcaDischTempChartData.slice(0);
					let chartDataPoint = {
						y: newValue,
						obsTimeFormattedLocal: moment(tagObj.DateInMilliseconds).format(
							'hh:mm:ss a'
						),
						obsTimeFormattedSite: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								tagObj.DateInMilliseconds,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a'),
						obsTimeFormattedUTC: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								tagObj.DateInMilliseconds,
								0
							)
						).format('hh:mm:ss a'),
						obsTimeMS: tagObj.DateInMilliseconds,
					};
					this.assetDataArray[gridIndex].pcaDischTempChartData.push(
						chartDataPoint
					);
				}

				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: PCA Disch Temp updated: ${newValue}`
					);
			}
		} else if (
			this.PCADischModeIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].pcaDischMode;
			newValue = this.getDataFromNewTag(tagObj, 'DISCHMODE');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].pcaDischMode = newValue;
				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: PCA Disch Mode updated: ${newValue}`
					);
			}
		} else if (
			this.PCAOpModeIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].pcaOpMode;
			newValue = this.getDataFromNewTag(tagObj, 'OPMODE');

			if (currentValue != newValue) {
				// Update Table
				this.assetDataArray[gridIndex].pcaOpMode = newValue;

				// Update Chart
				if (
					!this.pcaDischChartColumnHidden &&
					this.assetDataArray[gridIndex].pcaDischTempChart
				) {
					let updateColor =
						newValue?.pcaOpMode == 'Cool'
							? 'dodgerblue'
							: newValue?.pcaOpMode == 'Heat'
							? 'red'
							: 'forestgreen';
					let tempArray =
						this.assetDataArray[
							gridIndex
						].pcaDischTempChart[0].data.slice(0);
					this.assetDataArray[gridIndex].pcaDischTempChart = [
						{
							field: 'y',
							width: 1.5,
							color: updateColor,
							data: tempArray,
						},
					];
				}

				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: PCA Operation Mode updated: ${newValue}`
					);
			}
		} else if (
			this.GPUAmpsOutIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].gpuAmpsOutAvg;
			newValue = parseFloat(this.getDataFromNewTag(tagObj, 'AMPSOUT'));

			if (currentValue != newValue && !isNaN(newValue)) {
				// Update Table
				this.assetDataArray[gridIndex].gpuAmpsOutAvg = newValue;

				// Update Chart
				if (
					!this.gpuAmpsChartColumnHidden &&
					this.assetDataArray[gridIndex].gpuAmpsOutChart
				) {
					let tempArray =
						this.assetDataArray[
							gridIndex
						].gpuAmpsOutChartData.slice(0);
					let chartDataPoint = {
						y: newValue,
						obsTimeFormattedLocal: moment(tagObj.DateInMilliseconds).format(
							'hh:mm:ss a'
						),
						obsTimeFormattedSite: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								tagObj.DateInMilliseconds,
								this.siteUTCTimeOffset
							)
						).format('hh:mm:ss a'),
						obsTimeFormattedUTC: moment(
							this.utilityService.convertFromUtcToLocalToSite(
								tagObj.DateInMilliseconds,
								0
							)
						).format('hh:mm:ss a'),
						obsTimeMS: tagObj.DateInMilliseconds,
					};
					this.assetDataArray[gridIndex].gpuAmpsOutChartData.push(
						chartDataPoint
					);
				}

				this.assetDataArray[gridIndex].gpuSwitchedOver =
					this.assetDataArray[gridIndex].gpuOn && newValue >= 6
						? true
						: false; // Update GPU Switched Over
				// this.displayAllSignalRMessages &&
				// 	console.log(
				// 		this.componentName +
				// 			': ' +
				// 			`${gate}: GPU Amps Out Avg updated: ${newValue}`
				// 	);
			}
		} else if (
			this.GPUSwitchedOverIds.includes(tagObj.JBTStandardObservationId)
		) {
			currentValue = this.assetDataArray[gridIndex].gpuSwitchedOver;
			newValue = this.getDataFromNewTag(tagObj, 'SWITCHOVER');

			if (currentValue != newValue) {
				this.assetDataArray[gridIndex].gpuSwitchedOver = newValue;
				this.displayAllSignalRMessages &&
					console.log(
						this.componentName +
							': ' +
							`${gate}: GPU Switched Over updated: ${newValue}`
					);
			}
		} else if (
			this.ActiveAlarmIds.includes(tagObj.JBTStandardObservationId)
		) {
			newValue = this.getDataFromNewTag(tagObj, 'ALARMS');
			switch (assetType) {
				case 'PBB':
					currentValue =
						this.assetDataArray[gridIndex].pbbAlarmActive;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pbbAlarmActive =
							newValue;
					}
					break;
				case 'PCA':
					currentValue =
						this.assetDataArray[gridIndex].pcaAlarmActive;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pcaAlarmActive =
							newValue;
					}
					break;

				case 'GPU':
					currentValue =
						this.assetDataArray[gridIndex].gpuAlarmActive;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].gpuAlarmActive =
							newValue;
					}
					break;
			}
		} else if (this.StatusIds.includes(tagObj.JBTStandardObservationId)) {
			newValue = this.getDataFromNewTag(tagObj, 'UNITON');

			switch (assetType) {
				case 'GPU':
					currentValue = this.assetDataArray[gridIndex].gpuOn;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].gpuOn = newValue;
						if (!newValue) {
							this.assetDataArray[gridIndex].gpuOnDate =
								undefined;
							this.assetDataArray[gridIndex].gpuRuntime = 0;
						} else {
							this.assetDataArray[gridIndex].gpuOnDate =
								tagObj.DateInMilliseconds;
							this.assetDataArray[gridIndex].gpuSwitchedOver =
								this.assetDataArray[gridIndex].gpuAmpsOutAvg >=
								6
									? true
									: false; // Update GPU Switched Over
						}
					}
					break;
				case 'AHU':
					currentValue = this.assetDataArray[gridIndex].ahuOn;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].ahuOn = newValue;
						if (!newValue) {
							this.assetDataArray[gridIndex].ahuOnDate =
								undefined;
							this.assetDataArray[gridIndex].ahuRuntime = 0;
						} else {
							this.assetDataArray[gridIndex].ahuOnDate =
								tagObj.DateInMilliseconds;
						}
					}
					break;
				case 'PCA':
					currentValue = this.assetDataArray[gridIndex].pcaOn;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pcaOn = newValue;
						if (!newValue) {
							this.assetDataArray[gridIndex].pcaOnDate =
								undefined;
							this.assetDataArray[gridIndex].pcaRuntime = 0;
						} else {
							this.assetDataArray[gridIndex].pcaOnDate =
								tagObj.DateInMilliseconds;
						}
					}
					break;
			}

			// this.displayAllSignalRMessages
			// &&
			// 	console.log(
			// 		this.componentName +
			// 			': ' +
			// 			`${gate}: ${assetType} Status updated: ${newValue}`
			// 	);
		} else if (
			this.MaintenanceModeIds.includes(tagObj.JBTStandardObservationId)
		) {
			newValue = this.getDataFromNewTag(tagObj, 'MAINTENANCE');

			switch (assetType) {
				case 'PBB':
					currentValue =
						this.assetDataArray[gridIndex].pbbMaintenanceMode;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pbbMaintenanceMode =
							newValue;
					}
					break;
				case 'PCA':
					currentValue =
						this.assetDataArray[gridIndex].pcaMaintenanceMode;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pcaMaintenanceMode =
							newValue;
					}
					break;

				case 'GPU':
					currentValue =
						this.assetDataArray[gridIndex].gpuMaintenanceMode;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].gpuMaintenanceMode =
							newValue;
					}
					break;
			}
			this.displayAllSignalRMessages &&
				console.log(
					this.componentName +
						': ' +
						`${gate}: ${assetType} Maintenance updated: ${newValue}`
				);
		} else if (
			this.OutOfServiceIds.includes(tagObj.JBTStandardObservationId)
		) {
			newValue = this.getDataFromNewTag(tagObj, 'OUTOFSERVICE');

			switch (assetType) {
				case 'PBB':
					currentValue =
						this.assetDataArray[gridIndex].pbbOutOfService;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pbbOutOfService =
							newValue;
					}
					break;
				case 'PCA':
					currentValue =
						this.assetDataArray[gridIndex].pcaOutOfService;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pcaOutOfService =
							newValue;
					}
					break;

				case 'GPU':
					currentValue =
						this.assetDataArray[gridIndex].gpuOutOfService;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].gpuOutOfService =
							newValue;
					}
					break;
			}
			this.displayAllSignalRMessages &&
				console.log(
					this.componentName +
						': ' +
						`${gate}: ${assetType} Out of Service updated: ${newValue}`
				);
		} else if (
			this.CommsLossIds.includes(tagObj.JBTStandardObservationId)
		) {
			newValue = this.getDataFromNewTag(tagObj, 'COMMS');

			switch (assetType) {
				case 'PBB':
					currentValue = this.assetDataArray[gridIndex].pbbCommsLoss;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pbbCommsLoss = newValue;
					}
					break;
				case 'PCA':
					currentValue = this.assetDataArray[gridIndex].pcaCommsLoss;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].pcaCommsLoss = newValue;
					}
					break;

				case 'GPU':
					currentValue = this.assetDataArray[gridIndex].gpuCommsLoss;
					if (currentValue != newValue) {
						this.assetDataArray[gridIndex].gpuCommsLoss = newValue;
					}
					break;
			}
			this.displayAllSignalRMessages &&
				console.log(
					this.componentName +
						': ' +
						`${gate}: ${assetType} Comms Loss updated: ${newValue}`
				);
		}
		// this.assetDataArray = this.assetDataArray.slice(0);
		if (this.allGateGrid) {
			this.allGateGrid.gridDataSubject.next(this.assetDataArray);
		}
	}

	getDataFromNewTag(tagObj: any, code: string): any {
		switch (code) {
			case 'UNITON':
				return tagObj?.Value == '1' ? true : false;

			case 'OUTOFSERVICE':
				return tagObj?.Value == '1' ? true : false;

			case 'MAINTENANCE':
				return tagObj?.Value == '1' ? true : false;

			case 'AIRCRAFTDOCKED':
				return tagObj?.Value == '1' ? true : false;

			case 'PBBDRIVING':
				return tagObj?.Value == '1' ? true : false;

			case 'COMMS':
				return tagObj?.Value == '1' ? true : false;

			case 'ALARMS':
				return tagObj?.Value == '1' ? true : false;

			case 'DISCHTEMP':
				return parseFloat(tagObj?.Value).toFixed(1);

			case 'DISCHMODE':
				switch (tagObj.JBTStandardObservationId) {
					case 15220:
						return tagObj.Value == '1' ? 'Bridge' : 'Aircraft';

					case 15215:
						if (tagObj.Value == '0') return 'Unknown';
						else if (tagObj.Value == '1') return 'Commuter';
						else if (tagObj.Value == '2') return 'Narrow';
						else if (tagObj.Value == '3') return 'Wide';
						else if (tagObj.Value == '4') return 'Jumbo';
						else return 'Aircraft';
				}
				break;

			case 'OPMODE':
				if (tagObj?.Value == '0') return 'UDF';
				else if (tagObj?.Value == '1') return 'Auto';
				else if (tagObj?.Value == '2') return 'Cool';
				else if (tagObj?.Value == '3') return 'Vent';
				else if (tagObj?.Value == '4') return 'Heat';
				break;

			case 'AMPSOUT':
				return parseFloat(tagObj?.Value).toFixed(1);

			case 'SWITCHOVER':
				return tagObj?.Value == '1' ? true : false;
		}
	}
}
