<ng-template #popTitle
	><h4 style="color: black">
		<b>{{
			tooltipInformationObject.title !== ""
				? tooltipInformationObject.title
				: "No Tag Found"
		}}</b>
	</h4>
	<p style="color: black" *ngIf="tooltipInformationObject.title !== ''">
		Right Click To Copy To Clipboard
	</p>
</ng-template>
<ng-template #popContent>
	<div *ngIf="tooltipInformationObject.title !== ''">
		<p style="color: black"><b>TagName:</b></p>
		<p style="color: black">{{ tooltipInformationObject.TagName }}</p>
		<p style="color: black"><b>TagId:</b></p>
		<p style="color: black">{{ tooltipInformationObject.TagId }}</p>
		<p style="color: black"><b>ListOfJBTSTDIDSUsed:</b></p>
		<p style="color: black">
			{{ tooltipInformationObject.ListOfJBTSTDIDSUsed }}
		</p>
		<p style="color: black"><b>JBTStdId:</b></p>
		<p style="color: black">{{ tooltipInformationObject.JBTStdId }}</p>
		<p style="color: black"><b>JBTStdName:</b></p>
		<p style="color: black">{{ tooltipInformationObject.JBTStdName }}</p>
		<p style="color: black"><b>Value:</b></p>
		<p style="color: black">{{ tooltipInformationObject.Value }}</p>
		<p style="color: black"><b>ValueWhenActive:</b></p>
		<p style="color: black">
			{{ tooltipInformationObject.ValueWhenActive }}
		</p>
	</div>
	<div *ngIf="tooltipInformationObject.title === ''">
		<p style="color: black"><b>ListOfJBTSTDIDSUsed:</b></p>
		<p style="color: black">
			{{ tooltipInformationObject.ListOfJBTSTDIDSUsed }}
		</p>
	</div>
</ng-template>

<div *ngIf="!isDataLoading" class="box">
	<div class="d-flex flex-row" style="justify-content: normal !important">
		<div class="d-flex flex-column">
			<div class="d-flex flex-row">
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'PowerOn')"
					container="body"
					(mouseenter)="tooltipInformation('PowerOn')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.PowerOn
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.PowerOn != undefined"
					[ngClass]="{
						'unit-on': assetTags.PowerOn,
						'unit-off': !assetTags.PowerOn
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{ assetTags.PowerOn ? "Power On" : "Power Off" }}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'Moving')"
					container="body"
					(mouseenter)="tooltipInformation('Moving')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.Moving
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.Moving != undefined"
					[ngClass]="{
						'unit-on': assetTags.Moving,
						'unit-off': !assetTags.Moving
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{ assetTags.Moving ? "Moving" : "Not Moving" }}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'AutoLevelStatus')"
					container="body"
					(mouseenter)="tooltipInformation('AutoLevelStatus')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.AutoLevelStatus
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.AutoLevelStatus != undefined"
					[ngClass]="{
						'unit-on': assetTags.AutoLevelStatus,
						'unit-off': !assetTags.AutoLevelStatus
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{
						assetTags.AutoLevelStatus
							? "AutoLevel On"
							: "AutoLevel Off"
					}}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'UnitStatus')"
					container="body"
					(mouseenter)="tooltipInformation('UnitStatus')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.UnitStatus
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.UnitStatus != undefined"
					[ngClass]="{
						'unit-on': assetTags.UnitStatus,
						'unit-off': !assetTags.UnitStatus
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					{{ assetTags.UnitStatus ? assetTags.RunTime : "UnDocked" }}
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'CanopyDown')"
					container="body"
					(mouseenter)="tooltipInformation('CanopyDown')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.CanopyDown
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.CanopyDown != undefined"
					[ngClass]="{
						'unit-on': assetTags.CanopyDown,
						'unit-off': !assetTags.CanopyDown
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					Canopy Down
				</div>
				<div
					[openDelay]="300"
					triggers="mouseenter:mouseleave"
					[disablePopover]="!global.User.DebugMode"
					(contextmenu)="onRightClick($event, 'SlowDownActive')"
					container="body"
					(mouseenter)="tooltipInformation('SlowDownActive')"
					[ngbPopover]="popContent"
					[popoverTitle]="popTitle"
					(click)="
						openTagGraphSingle(
							ObservationIdMappingObjectForTrueFalseValues.SlowDownActive
						)
					"
					class="col box-stat-content"
					*ngIf="assetTags.SlowDownActive != undefined"
					[ngClass]="{
						'unit-on': assetTags.SlowDownActive,
						'unit-off': !assetTags.SlowDownActive
					}"
					[ngStyle]="{
						color: theme === 'light' ? '#27304c' : 'white'
					}"
				>
					Slow Down
				</div>
			</div>
			<div
				class="d-flex flex-row"
				style="justify-content: normal !important"
			>
				<div class="d-flex flex-column basis30">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AcfStatus')"
						container="body"
						(mouseenter)="tooltipInformation('AcfStatus')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AcfStatus
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.AcfStatus != undefined"
						[ngClass]="{
							'unit-on': assetTags.AcfStatus,
							'unit-off': !assetTags.AcfStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						ACF Floor
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VentilatorStatus')"
						container="body"
						(mouseenter)="tooltipInformation('VentilatorStatus')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VentilatorStatus
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.VentilatorStatus != undefined"
						[ngClass]="{
							'unit-on': assetTags.VentilatorStatus,
							'unit-off': !assetTags.VentilatorStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Ventilator
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'FloodLight')"
						container="body"
						(mouseenter)="tooltipInformation('FloodLight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.FloodLight
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.FloodLight != undefined"
						[ngClass]="{
							'unit-on': assetTags.FloodLight,
							'unit-off': !assetTags.FloodLight
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Flood Light
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'GateSign')"
						container="body"
						(mouseenter)="tooltipInformation('GateSign')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.GateSign
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.GateSign != undefined"
						[ngClass]="{
							'unit-on': assetTags.GateSign,
							'unit-off': !assetTags.GateSign
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Gate Sign
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'FloorHeat')"
						container="body"
						(mouseenter)="tooltipInformation('FloorHeat')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.FloorHeat
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.FloorHeat != undefined"
						[ngClass]="{
							'unit-on': assetTags.FloorHeat,
							'unit-off': !assetTags.FloorHeat
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Floor Heat
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'WindowHeat')"
						container="body"
						(mouseenter)="tooltipInformation('WindowHeat')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.WindowHeat
							)
						"
						class="col box-stat-content-left"
						*ngIf="assetTags.WindowHeat != undefined"
						[ngClass]="{
							'unit-on': assetTags.WindowHeat,
							'unit-off': !assetTags.WindowHeat
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Window Heat
					</div>
				</div>
				<div class="d-flex flex-column" *ngIf="camera && viewCamera">
					<iframe
						width="120%"
						height="100%"
						[src]="camera.trustedSourceURL"
					></iframe>
					<button
						title="Switch to Graphic View"
						class="jbt-btn-sm camera-button"
						(click)="switchView(false)"
					>
						<i class="tim-icons icon-image-02"></i>
					</button>
				</div>
				<div class="d-flex flex-column" *ngIf="!viewCamera">
					<img
						[src]="
							assetTags.UnitStatus
								? global.imagesUrl + 'assets/images/PBBGraphics/Plane_At_Gate.png'
								: global.imagesUrl + 'assets/images/PBBGraphics/Bridge.png'
						"
					/>
					<button
						title="Switch to Live Camera View"
						class="jbt-btn-sm camera-button"
						*ngIf="camera"
						(click)="switchView(true)"
					>
						<i class="tim-icons icon-camera-18"></i>
					</button>
				</div>
			</div>
		</div>
		<div class="d-flex flex-column basis40">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Interlock/SlowDown</legend>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'UnitStatus')"
						container="body"
						(mouseenter)="tooltipInformation('UnitStatus')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.UnitStatus
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.UnitStatus != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Docked
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'Hoist1')"
						container="body"
						(mouseenter)="tooltipInformation('Hoist1')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.Hoist1
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.Hoist1 != undefined"
						[ngClass]="{
							'unit-on': assetTags.Hoist1,
							'unit-off': !assetTags.Hoist1
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hoist 1
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'DeadmanSwitch')"
						container="body"
						(mouseenter)="tooltipInformation('DeadmanSwitch')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.DeadmanSwitch
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.DeadmanSwitch != undefined"
						[ngClass]="{
							'unit-on': assetTags.DeadmanSwitch,
							'unit-off': !assetTags.DeadmanSwitch
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Deadman Switch
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'Hoist2')"
						container="body"
						(mouseenter)="tooltipInformation('Hoist2')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.Hoist2
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.Hoist2 != undefined"
						[ngClass]="{
							'unit-on': assetTags.Hoist2,
							'unit-off': !assetTags.Hoist2
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hoist 2
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VdgsInterlock')"
						container="body"
						(mouseenter)="tooltipInformation('VdgsInterlock')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VdgsInterlock
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VdgsInterlock != undefined"
						[ngClass]="{
							'unit-on': assetTags.VdgsInterlock,
							'unit-off': !assetTags.VdgsInterlock
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						VDGS Interlock
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'Hoist3')"
						container="body"
						(mouseenter)="tooltipInformation('Hoist3')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.Hoist3
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.Hoist3 != undefined"
						[ngClass]="{
							'unit-on': assetTags.Hoist3,
							'unit-off': !assetTags.Hoist3
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hoist 3
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingDoorLeft')"
						container="body"
						(mouseenter)="tooltipInformation('SwingDoorLeft')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingDoorLeft
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingDoorLeft != undefined"
						[ngClass]="{
							'unit-on': assetTags.SwingDoorLeft,
							'unit-off': !assetTags.SwingDoorLeft
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Door Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CenterIRSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('CenterIRSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CenterIRSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CenterIRSlowDown != undefined"
						[ngClass]="{
							'unit-on': assetTags.CenterIRSlowDown,
							'unit-off': !assetTags.CenterIRSlowDown
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Center IR SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingDoorRight')"
						container="body"
						(mouseenter)="tooltipInformation('SwingDoorRight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingDoorRight
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingDoorRight != undefined"
						[ngClass]="{
							'unit-on': assetTags.SwingDoorRight,
							'unit-off': !assetTags.SwingDoorRight
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Door Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'LeftIRSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('LeftIRSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.LeftIRSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.LeftIRSlowDown != undefined"
						[ngClass]="{
							'unit-on': assetTags.LeftIRSlowDown,
							'unit-off': !assetTags.LeftIRSlowDown
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Left IR SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ServiceStairDoor')"
						container="body"
						(mouseenter)="tooltipInformation('ServiceStairDoor')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.ServiceStairDoor
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.ServiceStairDoor != undefined"
						[ngClass]="{
							'unit-on': assetTags.ServiceStairDoor,
							'unit-off': !assetTags.ServiceStairDoor
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Service Stair Door
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'RightIRSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('RightIRSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.RightIRSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.RightIRSlowDown != undefined"
						[ngClass]="{
							'unit-on': assetTags.RightIRSlowDown,
							'unit-off': !assetTags.RightIRSlowDown
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Right IR SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'TerminalDoor')"
						container="body"
						(mouseenter)="tooltipInformation('TerminalDoor')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.TerminalDoor
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.TerminalDoor != undefined"
						[ngClass]="{
							'unit-on': assetTags.TerminalDoor,
							'unit-off': !assetTags.TerminalDoor
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Terminal Door
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ForwardSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('ForwardSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.ForwardSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.ForwardSlowDown != undefined"
						[ngClass]="{
							'unit-on': assetTags.ForwardSlowDown,
							'unit-off': !assetTags.ForwardSlowDown
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Forward SlowDown
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'BagLiftInterlock')"
						container="body"
						(mouseenter)="tooltipInformation('BagLiftInterlock')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.BagLiftInterlock
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.BagLiftInterlock != undefined"
						[ngClass]="{
							'unit-on': assetTags.BagLiftInterlock,
							'unit-off': !assetTags.BagLiftInterlock
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Bag Lift Interlock
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ReverseSlowDown')"
						container="body"
						(mouseenter)="tooltipInformation('ReverseSlowDown')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.ReverseSlowDown
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.ReverseSlowDown != undefined"
						[ngClass]="{
							'unit-on': assetTags.ReverseSlowDown,
							'unit-off': !assetTags.ReverseSlowDown
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Reverse SlowDown
					</div>
				</div>
			</fieldset>
		</div>
	</div>
	<div class="d-flex flex-row" style="justify-content: normal !important">
		<div class="d-flex flex-column basis40">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Alarms</legend>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AutolevelAlarm')"
						container="body"
						(mouseenter)="tooltipInformation('AutolevelAlarm')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AutolevelAlarm
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.AutolevelAlarm != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.AutolevelAlarm,
							'unit-off': !assetTags.AutolevelAlarm
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Autolevel Failure
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HorizInverterFault')
						"
						container="body"
						(mouseenter)="tooltipInformation('HorizInverterFault')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HorizInverterFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HorizInverterFault != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.HorizInverterFault,
							'unit-off': !assetTags.HorizInverterFault
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Horizontal Inverter
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'EstopMain')"
						container="body"
						(mouseenter)="tooltipInformation('EstopMain')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.EstopMain
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.EstopMain != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.EstopMain,
							'unit-off': !assetTags.EstopMain
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						E-Stop Main
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'AcfFault')"
						container="body"
						(mouseenter)="tooltipInformation('AcfFault')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.AcfFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.AcfFault != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.AcfFault,
							'unit-off': !assetTags.AcfFault
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						ACF Floor
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'EstopDriveRight')"
						container="body"
						(mouseenter)="tooltipInformation('EstopDriveRight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.EstopDriveRight
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.EstopDriveRight != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.EstopDriveRight,
							'unit-off': !assetTags.EstopDriveRight
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						E-Stop Drive Col Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SpacerBypassPB')"
						container="body"
						(mouseenter)="tooltipInformation('SpacerBypassPB')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SpacerBypassPB
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SpacerBypassPB != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.SpacerBypassPB,
							'unit-off': !assetTags.SpacerBypassPB
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Spacer Bypass PB
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'EstopDriveLeft')"
						container="body"
						(mouseenter)="tooltipInformation('EstopDriveLeft')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.EstopDriveLeft
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.EstopDriveLeft != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.EstopDriveLeft,
							'unit-off': !assetTags.EstopDriveLeft
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						E-Stop Drive Col Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalOverloadLeft')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('VerticalOverloadLeft')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalOverloadLeft
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalOverloadLeft != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.VerticalOverloadLeft,
							'unit-off': !assetTags.VerticalOverloadLeft
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Left Overload
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SmokeDetector')"
						container="body"
						(mouseenter)="tooltipInformation('SmokeDetector')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SmokeDetector
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SmokeDetector != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.SmokeDetector,
							'unit-off': !assetTags.SmokeDetector
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Smoke Detector
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalOverloadRight')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('VerticalOverloadRight')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalOverloadRight
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalOverloadRight != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.VerticalOverloadRight,
							'unit-off': !assetTags.VerticalOverloadRight
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Right Overload
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VerticalRack')"
						container="body"
						(mouseenter)="tooltipInformation('VerticalRack')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalRack
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalRack != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.VerticalRack,
							'unit-off': !assetTags.VerticalRack
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Rack
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalDownWeldFault')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('VerticalDownWeldFault')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalDownWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalDownWeldFault != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.VerticalDownWeldFault,
							'unit-off': !assetTags.VerticalDownWeldFault
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Down Weld
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'SafetyShoeNotConnected')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('SafetyShoeNotConnected')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SafetyShoeNotConnected
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SafetyShoeNotConnected != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.SafetyShoeNotConnected,
							'unit-off': !assetTags.SafetyShoeNotConnected
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Safety Shoe Not Connected
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLeftWeldFault')"
						container="body"
						(mouseenter)="tooltipInformation('CabLeftWeldFault')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabLeftWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabLeftWeldFault != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.CabLeftWeldFault,
							'unit-off': !assetTags.CabLeftWeldFault
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Left Weld
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HydraulicMotorWeldFault')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HydraulicMotorWeldFault')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HydraulicMotorWeldFault != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.HydraulicMotorWeldFault,
							'unit-off': !assetTags.HydraulicMotorWeldFault
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hydraulic Motor Weld
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'MotionEnableWeldFault')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('MotionEnableWeldFault')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.MotionEnableWeldFault
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.MotionEnableWeldFault != undefined"
						[ngClass]="{
							'unit-alarm': assetTags.MotionEnableWeldFault,
							'unit-off': !assetTags.MotionEnableWeldFault
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Motion Enable Weld
					</div>
				</div>
			</fieldset>
		</div>
		<div class="d-flex flex-column basis30">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Limits</legend>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLeftLimit')"
						container="body"
						(mouseenter)="tooltipInformation('CabLeftLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabLeftLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.CabLeftLimit,
							'unit-off': !assetTags.CabLeftLimit,
							WarningBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit) === 'Warning',
							AlarmBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLeftUltimate')"
						container="body"
						(mouseenter)="tooltipInformation('CabLeftUltimate')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabLeftUltimate != undefined"
						[ngClass]="{
							'unit-on': assetTags.CabLeftUltimate,
							'unit-off': !assetTags.CabLeftUltimate,
							WarningBackground:
								assetTags.CabLeftUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate) === 'Warning',
							AlarmBackground:
								assetTags.CabLeftUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabLeftUltimate) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Left Ult.
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabRightLimit')"
						container="body"
						(mouseenter)="tooltipInformation('CabRightLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabRightLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabRightLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.CabRightLimit,
							'unit-off': !assetTags.CabRightLimit,
							WarningBackground:
								assetTags.CabRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightLimit) === 'Warning',
							AlarmBackground:
								assetTags.CabRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabRightUltimate')"
						container="body"
						(mouseenter)="tooltipInformation('CabRightUltimate')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabRightUltimate != undefined"
						[ngClass]="{
							'unit-on': assetTags.CabRightUltimate,
							'unit-off': !assetTags.CabRightUltimate,
							WarningBackground:
								assetTags.CabRightUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate) === 'Warning',
							AlarmBackground:
								assetTags.CabRightUltimate &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.CabRightUltimate) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab Right Ult.
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingLeftLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SwingLeftLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.CabRightLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.SwingLeftLimit,
							'unit-off': !assetTags.SwingLeftLimit,
							WarningBackground:
								assetTags.SwingLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit) === 'Warning',
							AlarmBackground:
								assetTags.SwingLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingLeftLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Left
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'VerticalDownLimit')
						"
						container="body"
						(mouseenter)="tooltipInformation('VerticalDownLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalDownLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.VerticalDownLimit,
							'unit-off': !assetTags.VerticalDownLimit,
							WarningBackground:
								assetTags.VerticalDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit) === 'Warning',
							AlarmBackground:
								assetTags.VerticalDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalDownLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Down
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingRightLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SwingRightLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SwingRightLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.SwingRightLimit,
							'unit-off': !assetTags.SwingRightLimit,
							WarningBackground:
								assetTags.SwingRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit) === 'Warning',
							AlarmBackground:
								assetTags.SwingRightLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SwingRightLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing Right
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'VerticalUpLimit')"
						container="body"
						(mouseenter)="tooltipInformation('VerticalUpLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.VerticalUpLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.VerticalUpLimit,
							'unit-off': !assetTags.VerticalUpLimit,
							WarningBackground:
								assetTags.VerticalUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit) === 'Warning',
							AlarmBackground:
								assetTags.VerticalUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit) === 'Alarm',
							CriticalBackground:
								assetTags.CabLeftLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.VerticalUpLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Vertical Up
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SlopeUpLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SlopeUpLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SlopeUpLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.SlopeUpLimit,
							'unit-off': !assetTags.SlopeUpLimit,
							WarningBackground:
								assetTags.SlopeUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
								) === 'Warning',
							AlarmBackground:
								assetTags.SlopeUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
								) === 'Alarm',
							CriticalBackground:
								assetTags.SlopeUpLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.SlopeUpLimit
								) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Slope Up
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'LeftSpacerLimit')"
						container="body"
						(mouseenter)="tooltipInformation('LeftSpacerLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.LeftSpacerLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.LeftSpacerLimit,
							'unit-off': !assetTags.LeftSpacerLimit,
							WarningBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
								) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
								) === 'Alarm',
							CriticalBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(
									ObservationIdMappingObjectForTrueFalseValues.LeftSpacerLimit
								) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Left Spacer
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SlopeDownLimit')"
						container="body"
						(mouseenter)="tooltipInformation('SlopeDownLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.SlopeDownLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.SlopeDownLimit,
							'unit-off': !assetTags.SlopeDownLimit,
							WarningBackground:
								assetTags.SlopeDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit) === 'Alarm',
							CriticalBackground:
								assetTags.SlopeDownLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.SlopeDownLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Slope Dow
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'RightSpacerLimit')"
						container="body"
						(mouseenter)="tooltipInformation('RightSpacerLimit')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.RightSpacerLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.RightSpacerLimit,
							'unit-off': !assetTags.RightSpacerLimit,
							WarningBackground:
								assetTags.RightSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit) === 'Alarm',
							CriticalBackground:
								assetTags.RightSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.RightSpacerLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Right Spacer
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'LimitsDisabled')"
						container="body"
						(mouseenter)="tooltipInformation('LimitsDisabled')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.LimitsDisabled != undefined"
						[ngClass]="{
							'unit-on': assetTags.LimitsDisabled,
							'unit-off': !assetTags.LimitsDisabled,
							WarningBackground:
								assetTags.LimitsDisabled &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled) === 'Alarm',
							CriticalBackground:
								assetTags.LimitsDisabled &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.LimitsDisabled) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Limits Disabled
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HorizontalExtendLimit')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HorizontalExtendLimit')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HorizontalExtendLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.HorizontalExtendLimit,
							'unit-off': !assetTags.HorizontalExtendLimit,
							WarningBackground:
								assetTags.HorizontalExtendLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit) === 'Alarm',
							CriticalBackground:
								assetTags.HorizontalExtendLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalExtendLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Horizontal Extend
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HydraulicMotorOverload')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HydraulicMotorOverload')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload
							)
						"
						title="Hydraulic Motor Overload"
						class="col box-stat-content"
						*ngIf="assetTags.HydraulicMotorOverload != undefined"
						[ngClass]="{
							'unit-on': assetTags.HydraulicMotorOverload,
							'unit-off': !assetTags.HydraulicMotorOverload,
							WarningBackground:
								assetTags.HydraulicMotorOverload &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload) === 'Alarm',
							CriticalBackground:
								assetTags.HydraulicMotorOverload &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HydraulicMotorOverload) === 'Critical'

						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Hydraulic M Overload
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'HorizontalRetractLimit')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('HorizontalRetractLimit')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit
							)
						"
						class="col box-stat-content"
						*ngIf="assetTags.HorizontalRetractLimit != undefined"
						[ngClass]="{
							'unit-on': assetTags.HorizontalRetractLimit,
							'unit-off': !assetTags.HorizontalRetractLimit,
							WarningBackground:
								assetTags.HorizontalRetractLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit) === 'Warning',
							AlarmBackground:
								assetTags.LeftSpacerLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit) === 'Alarm',
							CriticalBackground:
								assetTags.HorizontalRetractLimit &&
								this.dataService.determineTagEffectiveSeverityLevel(ObservationIdMappingObjectForTrueFalseValues.HorizontalRetractLimit) === 'Critical'
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Horizontal Retract
					</div>
				</div>
			</fieldset>
		</div>
		<div class="d-flex flex-column basis30">
			<fieldset class="fieldsetstyle">
				<legend class="legendstyle">Analog I/O</legend>
				<div class="d-flex flex-row">
					<div
						title="Wheel Bogie Limit Low"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Wheel Bogie L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'WheelBogieLimitLow')
						"
						container="body"
						(mouseenter)="tooltipInformation('WheelBogieLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.WheelBogieLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.WheelBogieLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.WheelBogieLimitLow }}
					</div>
					<div
						title="Cab Limit Low"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('CabLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.CabLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.CabLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.CabLimitLow }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Wheel Bogie Limit High"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Wheel Bogie L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'WheelBogieLimitHigh')
						"
						container="body"
						(mouseenter)="tooltipInformation('WheelBogieLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.WheelBogieLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.WheelBogieLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.WheelBogieLimitHigh }}
					</div>
					<div
						title="Cab Limit High"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Cab L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'CabLimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('CabLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.CabLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.CabLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.CabLimitHigh }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Height Limit Low"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Height L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'HeightLimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('HeightLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.HeightLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.HeightLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.HeightLimitLow }}
					</div>
					<div
						title="Swing Limit Low"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingLimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('SwingLimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.SwingLimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.SwingLimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.SwingLimitLow }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Height Limit High"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Height L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'HeightLimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('HeightLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.HeightLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.HeightLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.HeightLimitHigh }}
					</div>
					<div
						title="Swing Limit High"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Swing L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'SwingLimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('SwingLimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.SwingLimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.SwingLimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.SwingLimitHigh }}
					</div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Joystick Left Right"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Joystick L-R
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'JoystickLeftRight')
						"
						container="body"
						(mouseenter)="tooltipInformation('JoystickLeftRight')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.JoystickLeftRight
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.JoystickLeftRight != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.JoystickLeftRight }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Joystick Forward Reverse"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Joystick F-R
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="
							onRightClick($event, 'JoystickForwardReverse')
						"
						container="body"
						(mouseenter)="
							tooltipInformation('JoystickForwardReverse')
						"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.JoystickForwardReverse
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.JoystickForwardReverse != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.JoystickForwardReverse }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Extend Limit Low"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Extend L-L
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ExtendlimitLow')"
						container="body"
						(mouseenter)="tooltipInformation('ExtendlimitLow')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.ExtendlimitLow
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.ExtendlimitLow != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.ExtendlimitLow }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
				<div class="d-flex flex-row">
					<div
						title="Extend Limit High"
						class="col aligntext"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						Extend L-H
					</div>
					<div
						[openDelay]="300"
						triggers="mouseenter:mouseleave"
						[disablePopover]="!global.User.DebugMode"
						(contextmenu)="onRightClick($event, 'ExtendlimitHigh')"
						container="body"
						(mouseenter)="tooltipInformation('ExtendlimitHigh')"
						[ngbPopover]="popContent"
						[popoverTitle]="popTitle"
						(click)="
							openTagGraphSingle(
								ObservationIdMappingObjectForParseFloatValues.ExtendlimitHigh
							)
						"
						class="col box-stat-content widthdiv"
						*ngIf="assetTags.ExtendlimitHigh != undefined"
						[ngClass]="{
							'unit-on': assetTags.UnitStatus,
							'unit-off': !assetTags.UnitStatus
						}"
						[ngStyle]="{
							color: theme === 'light' ? '#27304c' : 'white'
						}"
					>
						{{ assetTags.ExtendlimitHigh }}
					</div>
					<div class="col aligntext"></div>
					<div class="col widthdiv"></div>
				</div>
			</fieldset>
		</div>
	</div>
</div>
