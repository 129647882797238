import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	ViewChild,
	EventEmitter,
	NgZone,
} from '@angular/core';



import { filter } from 'rxjs/operators';
import _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import { SassHelperComponent } from '../../_helpers/sass-helper/sass-helper.component';
import { DataService } from '../../services/data.service';
import { DashboardService } from '../../services/dashboard.service';
import { SignalRCoreService } from '../../services/signalr-core.service';
import { UtilityService } from '../../services/utility.service';
import { Global } from '../../_constants/global.variables';
import * as $ from 'jquery';
import { IWidgetSignalRGroupObject } from '../../_models/signalr-widget-group.model';
import { ITagNamePrefixSubject } from '../../_models/tag-name-prefix-subject.model';
import { ITag } from '../../_models/tag.model';

declare var Highcharts: any;

@Component({
	selector: 'lib-gs-equipment-hours-of-operation',
	templateUrl: './gs-equipment-hours-of-operation.component.html',
	styleUrls: ['./gs-equipment-hours-of-operation.component.scss'],
})
export class GsEquipmentHoursOfOperationComponent implements OnInit, OnDestroy {
	@Input() widgetObject: any;
	@Input() private widgetResizedEvent: EventEmitter<any>;
	@Input() private dashboardTimeScopeChanged: EventEmitter<any>;
	@ViewChild(SassHelperComponent)
	private sassHelper: SassHelperComponent;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	public componentName: string = "gs-equipment-hours: ";
	public isDataLoading: boolean;

	private gateList: any;
	private assetList: any;
	Highcharts: typeof Highcharts = Highcharts;
	Chart: any;
	theme: string;
	tagIds: any;
	runtimeInterval: NodeJS.Timeout;

	private StatusIds = [12374, 4504, 13760]; // Unit On, Power On, Unit Running Status
	private PBBAircraftDockedIds = [12245]; // Aircraft Docked

	private ChartData: any;

	private queryStartDate: any;
	private queryEndDate: any;

	colorChangedSubscription: Subscription;
	widgetResizedSubscription: Subscription;
	dashboardTimeScopeChangedSubscription: Subscription;
	signalRSubscription: Subscription;
	signalRTagUpdateSubscription: Subscription;
	fullDataCacheSubscription: Subscription;
	assetIds: any;
	public guid: string;

	constructor(
		private dataService: DataService,
		private dashboardService: DashboardService,
		private signalRCore: SignalRCoreService,
		private utilityService: UtilityService,
		private zone: NgZone
	) {}

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;

		this.colorChangedSubscription =
			this.dataService.colorChanged$.subscribe((theme: any) => {
				this.theme = theme;
				this.createChart();
			});

		if (this.widgetResizedEvent) {
			this.widgetResizedSubscription = this.widgetResizedEvent.subscribe(
				(data) => {
					if (
						this.Chart &&
						this.widgetObject.WidgetId == data.item.WidgetId
					) {
						if (Global.isMobile) {
							let tagGraph = $(
								'#gsEquipmentHoursOfOperation' +
									this.widgetObject.WidgetId
							);
							this.Chart.setSize(
								tagGraph.width(),
								tagGraph.height(),
								false
							);
						} else {
							this.Chart.setSize(
								data.width,
								data.height - 43,
								false
							);
						}
					}
				}
			);
		}

		if (this.dashboardTimeScopeChanged) {
			this.dashboardTimeScopeChangedSubscription =
				this.dashboardTimeScopeChanged.subscribe((data) => {
					let foundWidgetWithSameWidgetId = data.find(
						(widgetThatWasChanged) => {
							return (
								widgetThatWasChanged.WidgetId ===
								this.widgetObject.WidgetId
							);
						}
					);

					if (!_.isNil(foundWidgetWithSameWidgetId)) {
						console.log('Widget Time Zone Changed');
						this.initialize();
					}
				});
		}

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription =
				this.dataService.fullDataCacheExists$.subscribe((data: any) => {
					if (data === true) {
						this.initialize();
						this.fullDataCacheSubscription.unsubscribe();
					}
				});
		} else {
			this.initialize();
		}
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.colorChangedSubscription !== undefined) {
			this.colorChangedSubscription.unsubscribe();
		}

		if (this.widgetResizedSubscription !== undefined) {
			this.widgetResizedSubscription.unsubscribe();
		}

		if (this.dashboardTimeScopeChangedSubscription !== undefined) {
			this.dashboardTimeScopeChangedSubscription.unsubscribe();
		}
		if (this.runtimeInterval) {
			clearInterval(this.runtimeInterval);
		}
	}

	initialize() {
		this.getDatesFromDashboard();

		this.theme = Global.Theme;

		let chartData = {
			gateNames: [],
			pbbHours: [],
			pcaHours: [],
			gpuHours: [],
		};
		let gates = [];

		let assets = this.dataService.cache.assets
			.filter((a) => a.SiteId == this.widgetObject.WidgetSiteId)
			.filter((a) => ['PBB', 'PCA', 'GPU'].includes(a.Name))
			.filter((a) => a.ParentSystem.Name != '')
			.map((a) => ({
				Id: a.Id,
				GateName: a.ParentSystem.Name,
				Type: a.Name,
				Tags: a.Tags,
			}));

		assets.forEach((a) => {
			if (!gates.includes(a.GateName)) {
				gates.push(a.GateName);
			}
		});

		this.gateList = gates;
		this.assetList = assets;
		this.ChartData = chartData;
		let JBTStandardObservationIds = [12374, 13760, 12245, 56885].toString();
		let assetIds = assets.map((a) => a.Id.toString()).join();
		this.assetIds = assets.map((a) => a.Id);
		this.dataService
			.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(
				assetIds,
				false,
				JBTStandardObservationIds
			)
			.subscribe((data) => {
				this.widgetObject.isDisplayDataLive = true;

				console.log(data);
				this.tagIds = data.map((t) => t.Id);
				console.log('Asset Tags have arrived');

				this.gateList = this.gateList.sort((g1, g2) =>
					this.utilityService
						.GetGateNameSortValue(g1)
						.localeCompare(
							this.utilityService.GetGateNameSortValue(g2),
							'en',
							{ numeric: true }
						)
				);

				let statement = `GetCumulativeRuntimeForSiteAssets @SiteId = ${this.widgetObject.WidgetSiteId}, @AssetTypes = 'PBB,PCA,GPU', @StartDate = '${this.queryStartDate}', @EndDate = '${this.queryEndDate}'`;
				this.dataService
					.SQLActionAsPromise(statement)
					.then((data: any) => {
						console.log(data);
						this.getChartData(data);
						this.createChart();

						// Repeatedly update the runtime values every minute
						this.runtimeInterval = setInterval(() => {
							this.updateRuntimeValues();
						}, 1000 * 60);
					});
			});
	}

	getChartData(apiData: any) {
		this.gateList.forEach((gate) => {
			let gateIndex = this.ChartData.gateNames.length;
			// Get Data for PBB
			let pbbObj = this.assetList.find(
				(a) => a.GateName == gate && a.Type == 'PBB'
			);

			if (pbbObj) {
				let pbbRuntimeData = apiData.find(
					(a) => a.Gate == gate && a.AssetName == 'PBB'
				);
				let pbbStatus = pbbObj.Tags.filter((t) =>
					this.PBBAircraftDockedIds.includes(
						t.JBTStandardObservationId
					)
				).some((t) => t.Value == '1')
					? 'On'
					: 'Off';
				let pbbHourMeterReadingExists = pbbObj.Tags.find(
					(a) => a.JBTStandardObservationId == 56885
				);

				this.ChartData.pbbHours.push({
					y: pbbRuntimeData
						? this.formatRuntimeData(
								pbbRuntimeData.RuntimeInMinutes,
								'DECIMAL'
						  )
						: 0,
					dateRuntime: pbbRuntimeData
						? this.formatRuntimeData(
								pbbRuntimeData.RuntimeInMinutes,
								'TIME'
						  )
						: '0 min',
					totalMinRuntime: pbbRuntimeData
						? pbbRuntimeData.RuntimeInMinutes
						: 0,
					status: pbbStatus,
					assetId: pbbObj.Id,
					gate: gate,
					gateIdx: gateIndex,
					seriesIdx: 0,
					color: '#FF6600',
					hourMeterReadingValue: pbbHourMeterReadingExists?.Value
						? pbbHourMeterReadingExists.Value
						: 0,
					//color: pbbStatus == "On" ? "#FF6600" : "LightGrey",// Ignore Unit/Off On changes and show bar colors as defined in the widget legend
				});
			}

			// Get data for PCA
			let pcaObj = this.assetList.find(
				(a) => a.GateName == gate && a.Type == 'PCA'
			);
			if (pcaObj) {
				let pcaRuntimeData = apiData.find(
					(a) => a.Gate == gate && a.AssetName == 'PCA'
				);
				let pcaStatus = pcaObj.Tags.filter((t) =>
					this.StatusIds.includes(t.JBTStandardObservationId)
				).some((t) => t.Value == '1')
					? 'On'
					: 'Off';
				let pcaHourMeterReadingExists = pcaObj.Tags.find(
					(a) => a.JBTStandardObservationId == 56885
				);

				this.ChartData.pcaHours.push({
					y: pcaRuntimeData
						? this.formatRuntimeData(
								pcaRuntimeData.RuntimeInMinutes,
								'DECIMAL'
						  )
						: 0,
					dateRuntime: pcaRuntimeData
						? this.formatRuntimeData(
								pcaRuntimeData.RuntimeInMinutes,
								'TIME'
						  )
						: '0 min',
					totalMinRuntime: pcaRuntimeData
						? pcaRuntimeData.RuntimeInMinutes
						: 0,
					status: pcaStatus,
					assetId: pcaObj.Id,
					gate: gate,
					gateIdx: gateIndex,
					seriesIdx: 1,
					color: '#5288DB',
					hourMeterReadingValue: pcaHourMeterReadingExists.Value
						? pcaHourMeterReadingExists.Value
						: 0,
					//color: pcaStatus == "On" ? "#5288DB" : "LightGrey",// Ignore Unit/Off On changes and show bar colors as defined in the widget legend
				});
			}

			// Get Data for GPU
			let gpuObj = this.assetList.find(
				(a) => a.GateName == gate && a.Type == 'GPU'
			);
			if (gpuObj) {
				let gpuRuntimeData = apiData.find(
					(a) => a.Gate == gate && a.AssetName == 'GPU'
				);
				let gpuStatus = gpuObj.Tags.filter((t) =>
					this.StatusIds.includes(t.JBTStandardObservationId)
				).some((t) => t.Value == '1')
					? 'On'
					: 'Off';
				let gpuHourMeterReadingExists = gpuObj.Tags.find(
					(a) => a.JBTStandardObservationId == 56885
				);

				this.ChartData.gpuHours.push({
					y: gpuRuntimeData
						? this.formatRuntimeData(
								gpuRuntimeData.RuntimeInMinutes,
								'DECIMAL'
						  )
						: 0,
					dateRuntime: gpuRuntimeData
						? this.formatRuntimeData(
								gpuRuntimeData.RuntimeInMinutes,
								'TIME'
						  )
						: '0 min',
					totalMinRuntime: gpuRuntimeData
						? gpuRuntimeData.RuntimeInMinutes
						: 0,
					status: gpuStatus,
					assetId: gpuObj.Id,
					gate: gate,
					gateIdx: gateIndex,
					seriesIdx: 2,
					color: '#55D43F',
					hourMeterReadingValue:
						gpuHourMeterReadingExists?.Value != null
							? gpuHourMeterReadingExists.Value
							: 0,

					//color: gpuStatus == "On" ? "#55D43F" : "LightGrey",// Ignore Unit/Off On changes and show bar colors as defined in the widget legend
				});
			}

			if (pbbObj && pcaObj && gpuObj) {
				this.ChartData.gateNames.push(gate);
			}
		});

		console.log(this.ChartData);
	}

	createChart() {
		let innerChartTextColor =
			this.theme === 'dark'
				? 'white'
				: this.sassHelper.readProperty('body-text-color-dark');
		let outerChartTextColor =
			this.theme === 'dark'
				? this.sassHelper.readProperty('body-text-color-light')
				: this.sassHelper.readProperty('body-text-color-dark');

		let chartOptions = {
			chart: {
				type: 'column',
				backgroundColor:
					this.theme === 'dark' ? 'rgb(39,41,61)' : 'white',
				style: {
					fontFamily: 'Poppins, sans-serif',
					color: outerChartTextColor,
				},
			},
			animation: false,
			credits: { enabled: false },
			title: {
				text: '',
			},
			xAxis: {
				categories: this.ChartData.gateNames,
				title: {
					text: 'Gate',
					style: {
						color: outerChartTextColor,
					},
				},
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
			},
			yAxis: {
				labels: {
					autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
					style: {
						fontSize: '10px',
						wordWrap: 'break word',
						fontFamily: 'Verdana, sans-serif',
						color: outerChartTextColor,
					},
				},
				min: 0,
				title: {
					text: 'Hours In Use',
					style: {
						color: outerChartTextColor,
					},
				},
				stackLabels: {
					enabled: true,
					style: {
						fontWeight: 'bold',
						color: innerChartTextColor,
					},
				},
			},
			legend: {
				align: 'center',
				verticalAlign: 'top',
				itemStyle: {
					color: innerChartTextColor,
					fontWeight: 'bold',
				},
				shadow: false,
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: true,
						color: innerChartTextColor,
					},
				},
			},
			series: [
				{
					name: 'PBB',
					data: this.ChartData.pbbHours,
					color: '#FF6600',
					tooltip: {
						headerFormat: 'PBB - Gate {point.key}<br/>',
						pointFormatter: function () {
							let tooltip = `
						  Status: ${this.status} <br/>
						  Runtime: ${this.dateRuntime}
						  `;
							return tooltip;
						},
					},
				},
				{
					name: 'PCA',
					data: this.ChartData.pcaHours,
					color: '#5288DB',
					tooltip: {
						headerFormat: 'PCA - Gate {point.key}<br/>',
						pointFormatter: function () {
							let tooltip = `
							  Status: ${this.status} <br/>
							  Runtime: ${this.dateRuntime}
						  `;
							return tooltip;
						},
					},
				},
				{
					name: 'GPU',
					data: this.ChartData.gpuHours,
					color: '#55D43F',
					tooltip: {
						headerFormat: 'GPU - Gate {point.key}<br/>',
						pointFormatter: function () {
							let tooltip = `
							  Status: ${this.status} <br/>
							  Runtime: ${this.dateRuntime}
						  `;
							return tooltip;
						},
					},
				},
			],
		};
		this.zone.runOutsideAngular(() => {
			this.Chart = Highcharts.chart(
				'gsEquipmentHoursOfOperation' + this.widgetObject.WidgetId,
				chartOptions
			);
		});
		this.isDataLoading = false;

		this.getSignalRUpdates();
	}

	getSignalRUpdates() {
		let tagNamePrefixesString = _.uniq(this.assetIds.map((a: any) => this.dataService.cache.assetsObject[a].TagNamePrefix )).join();
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;
		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false,
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + ": widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
		.createSubjectAndSubscribe({ Id: this.guid, 
									WidgetName: "Equipment Hours of Operation", 
									TagNamePrefix: tagNamePrefixesString.split(",")
		 })
		.then((data) => {
			//subscribe to existing subject
			Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

			var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
			activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
				//console.log(this.componentName + "Updating tag we care about: %O", tag);
				this.updateAssetStatus(tag);
			});
		});

	}

	updateAssetStatus(tagObj: any) {
		let gateName = tagObj.Asset.ParentSystem.Name;
		let newValue = tagObj.Value == '1' ? 'On' : 'Off';
		//let newColor: any;
		let gateObj: any;

		switch (tagObj.Asset.Name) {
			case 'PBB':
				gateObj = this.ChartData.pbbHours.find(
					(a) => a.assetId == tagObj.AssetId
				);
				//newColor = newValue == "On" ? "#FF6600" : "LightGrey";
				break;

			case 'PCA':
				gateObj = this.ChartData.pcaHours.find(
					(a) => a.assetId == tagObj.AssetId
				);
				//newColor = newValue == "On" ? "#5288DB" : "LightGrey";
				break;

			case 'GPU':
				gateObj = this.ChartData.gpuHours.find(
					(a) => a.assetId == tagObj.AssetId
				);
				//newColor = newValue == "On" ? "#55D43F" : "LightGrey";
				break;
		}

		if (gateObj.status != newValue) {
			gateObj.status = newValue;
			this.Chart.series[gateObj.seriesIdx].data[gateObj.gateIdx].update({
				status: newValue,
			});
			console.log(
				`Gate ${gateName}: ${tagObj.Asset.Name} Status was updated to "${newValue}"`
			);
		}
		if (tagObj.JBTStandardObservationId == 56885) {
			//Update Hour Meter Reading Values
			if (
				gateObj.hourMeterReadingValue == '' ||
				gateObj.hourMeterReadingValue == null
			)
				gateObj.hourMeterReadingValue = 0;
			let runTimeWithoutHourMeterReading =
				gateObj.totalMinRuntime -
				parseInt(gateObj.hourMeterReadingValue) * 60;
			let totalMinRuntimeWithHourMeterReading =
				runTimeWithoutHourMeterReading + parseInt(tagObj.Value) * 60;
			this.Chart.series[gateObj.seriesIdx].data[gateObj.gateIdx].update({
				totalMinRuntime: totalMinRuntimeWithHourMeterReading,
			});
			this.Chart.series[gateObj.seriesIdx].data[gateObj.gateIdx].update({
				y: this.formatRuntimeData(
					totalMinRuntimeWithHourMeterReading,
					'DECIMAL'
				),
			});
			this.Chart.series[gateObj.seriesIdx].data[gateObj.gateIdx].update({
				dateRuntime: this.formatRuntimeData(
					totalMinRuntimeWithHourMeterReading,
					'TIME'
				),
			});
			switch (tagObj.Asset.Name) {
				case 'PBB':
					this.ChartData.pbbHours[
						gateObj.gateIdx
					].hourMeterReadingValue = tagObj.Value;
					break;
				case 'PCA':
					this.ChartData.pcaHours[
						gateObj.gateIdx
					].hourMeterReadingValue = tagObj.Value;
					break;
				case 'GPU':
					this.ChartData.gpuHours[
						gateObj.gateIdx
					].hourMeterReadingValue = tagObj.Value;
					break;
			}
		}
	}

	updateRuntimeValues() {
		this.ChartData.pbbHours
			.filter((a) => a.status == 'On')
			.forEach((a) => {
				a.totalMinRuntime += 1;
				a.y = this.formatRuntimeData(a.totalMinRuntime, 'DECIMAL');
				a.dateRuntime = this.formatRuntimeData(
					a.totalMinRuntime,
					'TIME'
				);
			});

		this.ChartData.pcaHours
			.filter((a) => a.status == 'On')
			.forEach((a) => {
				a.totalMinRuntime += 1;
				a.y = this.formatRuntimeData(a.totalMinRuntime, 'DECIMAL');
				a.dateRuntime = this.formatRuntimeData(
					a.totalMinRuntime,
					'TIME'
				);
			});

		this.ChartData.gpuHours
			.filter((a) => a.status == 'On')
			.forEach((a) => {
				a.totalMinRuntime += 1;
				a.y = this.formatRuntimeData(a.totalMinRuntime, 'DECIMAL');
				a.dateRuntime = this.formatRuntimeData(
					a.totalMinRuntime,
					'TIME'
				);
			});

		this.Chart.series[0].setData(this.ChartData.pbbHours);
		this.Chart.series[1].setData(this.ChartData.pcaHours);
		this.Chart.series[2].setData(this.ChartData.gpuHours);

		console.log('Unit Runtimes have been updated');
	}

	formatRuntimeData(runtimeMinutes: number, type: string): any {
		let hours = Math.floor(runtimeMinutes / 60);
		let minutes = runtimeMinutes % 60;
		let timeResult, decimalResult;

		switch (type) {
			case 'DECIMAL':
				decimalResult = hours + minutes / 60;
				decimalResult = parseFloat(decimalResult.toFixed(2));
				return decimalResult;

			case 'TIME':
				timeResult = `${hours} hr ${minutes} min`;
				return timeResult;
		}
	}

	getDatesFromDashboard() {
		let startDateQuery = new Date();
		let endDateQuery = new Date();
		let widgetTimeScopeObject =
			this.dashboardService.dashboardTimeScopes.find((v) => {
				return v.Id === this.widgetObject.TimeScopeId;
			});
		let dashboardTimeScopeObject =
			this.dashboardService.dashboardTimeScopes.find((v) => {
				return (
					v.Id === this.dashboardService.currentDashboard.TimeScopeId
				);
			});

		if (
			_.isNil(widgetTimeScopeObject) &&
			_.isNil(dashboardTimeScopeObject)
		) {
			startDateQuery.setDate(startDateQuery.getDate() - 7);
			this.queryStartDate = moment(startDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
			endDateQuery.setDate(endDateQuery.getDate());
			this.queryEndDate = moment(endDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
		} else if (!_.isNil(widgetTimeScopeObject)) {
			if (widgetTimeScopeObject.Days == -5)
				//Last 5 minutes
				startDateQuery.setMinutes(startDateQuery.getMinutes() - 5);
			else if (widgetTimeScopeObject.Days == 0)
				//Today (Since Midnight)
				startDateQuery.setHours(0, 0, 0, 0);
			else
				startDateQuery.setDate(
					startDateQuery.getDate() - widgetTimeScopeObject.Days
				);
			this.queryStartDate = moment(startDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
			endDateQuery.setDate(endDateQuery.getDate());
			this.queryEndDate = moment(endDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
		} else {
			if (dashboardTimeScopeObject.Days == -5)
				//Last 5 minutes
				startDateQuery.setMinutes(startDateQuery.getMinutes() - 5);
			else if (dashboardTimeScopeObject.Days == 0)
				//Today (Since Midnight)
				startDateQuery.setHours(0, 0, 0, 0);
			else
				startDateQuery.setDate(
					startDateQuery.getDate() - dashboardTimeScopeObject.Days
				);
			this.queryStartDate = moment(startDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
			endDateQuery.setDate(endDateQuery.getDate());
			this.queryEndDate = moment(endDateQuery).format(
				'YYYY/MM/DD HH:mm:ss'
			);
		}
	}
}
