import { Component, OnInit, Input, Optional, ViewChild, ChangeDetectorRef, ViewEncapsulation, EventEmitter, OnDestroy, ElementRef, NgZone } from "@angular/core";

import { process, State } from "@progress/kendo-data-query";

import { GridSettings } from "../../_models/grid-settings.interface";
import { RowClassArgs, PageChangeEvent } from "@progress/kendo-angular-grid";

import { filter } from "rxjs/operators";
import { Subscription } from "rxjs";
import _ from "lodash";
import { FormGroup, FormControl } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { LastThousandGridComponent } from "../../components/last-thousand-grid/last-thousand-grid.component";
import { QuestionBase } from "../../_models/dynamic-fields/questions/question-base";
import { NumberQuestion } from "../../_models/dynamic-fields/questions/question-number";
import { DataService } from "../../services/data.service";
import { SignalRCoreService } from "../../services/signalr-core.service";
import { KendoSettingsService } from "../../services/kendo-settings.service";
import { DashboardService } from "../../services/dashboard.service";
import { Global } from "../../_constants/global.variables";
import { UtilityService } from "../../services/utility.service";
import { KendoGridParentComponent } from "../kendo-grid-parent/kendo-grid-parent.component";
import { ISystem } from "../../_models/system.model";
import { IAsset } from "../../_models/asset.model";
import { DialogModalParentComponent } from "../dialog-modal-parent/dialog-modal-parent.component";
import { MatDialog } from "@angular/material/dialog";
import { ITag } from "../../_models/tag.model";
import { IWidgetSignalRGroupObject } from "../../_models/signalr-widget-group.model";
import { ITagNamePrefixSubject } from "../../_models/tag-name-prefix-subject.model";

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: "lib-pbb-summary",
	templateUrl: "./pbb-summary.component.html",
	styleUrls: ["./pbb-summary.component.scss"]
})
export class PbbSummaryComponent implements OnInit, OnDestroy {
	@Optional() @Input() widgetObject: any;
	@Optional() @Input() dataObject: any;
	@ViewChild("tagDataGrid") tagDataGrid: KendoGridParentComponent;
	@ViewChild("optionDataGrid") optionDataGrid: KendoGridParentComponent;
	@ViewChild("movePreventionDataGrid")
	movePreventionDataGrid: KendoGridParentComponent;
	@ViewChild("alarmDataGrid") alarmDataGrid: KendoGridParentComponent;
	@ViewChild("last1000EventsGrid") last1000EventsGrid: any;
	@ViewChild("last1000AlarmsGrid") last1000AlarmsGrid: any;
	@ViewChild("elevatorRotundaDataGrid")
	elevatorRotundaDataGrid: KendoGridParentComponent;
	@ViewChild("tagDataRowForCalculation") tagDataRowForCalculation: ElementRef;
	@ViewChild("eventDataRowForCalculation")
	eventDataRowForCalculation: ElementRef;
	@ViewChild("hourMeterReadingEventsGrid")
	hourMeterReadingEventsGrid: KendoGridParentComponent;
	@Input() private pbbSummaryCreatedEvent: EventEmitter<any>;
	@Input() private dashboardTimeZoneChanged: EventEmitter<any>;

	pbbSummaryCreatedSubscription: Subscription;
	dashboardTimeZoneChangedSubscription: Subscription;
	tagDataRowHeight: any = 43;
	eventDataRowHeight: any = 26;
	assetTagIds = [];
	alarmTagIds = [];
	quickViewTagIds = [];
	assetObj: any;
	siteUTCTimeOffset: Number;
	assetBaseImageURL: any;
	aircraftDockedImageURL: any;
	fullDataCache$: any;
	signalRSubscription: any;
	signalRTagUpdateSubscription: any;
	signalRAssetGroupUpdateSubscription: any;

	colorChangedSubscription: any;
	widgetResizedSubscription: any;
	isDataLoading: boolean;
	isAircraftDocked: boolean;
	timeZoneType: string;
	fromDashboard: boolean;
	defaultRightWidth = 40;
	defaultLeftWidth = 60;
	defaultWidth = 100;
	selectedMatTabIndex = 0;
	widgetIsInViewSubscription: Subscription;
	tagDataPageSize: number;
	eventDataPageSize: number;
	isWidgetInView: boolean = false;
	theme: string;
	fullDataCacheSubscription: Subscription;
	widgetGroupSettings: IWidgetSignalRGroupObject;
	parentContainerSize: any;
	countOfAlarms: any;
	alertStatus: any;
	private componentName: string = "pbb-summary: ";
	private quickViewIds = [12245, 4504, 12347, 12374, 54271, 3782, 1822, 4521, 1937, 3945, 3824, 15260, 2032, 3796, 13696, 3922, 14488, 15575, 56766, 56767, 56775, 13608, 12431, 13453, 13548, 3783, 13571, 56756, 12467, 12454, 56759, 56761, 13498, 1904, 4511, 3830, 12384, 13359, 13358, 56760, 15227, 14378, 13553, 13503, 19075, 56379, 12409, 12987, 3868, 3869, 12393, 14139, 12387, 12397, 12403, 3545, 12401, 12461, 12410, 12385, 12407, 12392, 3499, 12440, 12404, 12455, 12406, 12452, 12400, 12418, 54299, 12419, 3767, 19062, 12458, 56796, 12443, 13475, 56758, 14672, 14688, 4461, 4466, 12716, 12773, 2044, 2045, 2042, 2043, 14096, 14104, 14844, 14841, 13183, 13005];
	//Hour Meter Reading Fields
	public editHeader: string;
	public editSubHeader: string;
	public hourMeterReadingQuestions: QuestionBase<any>[];
	public hourMeterReadingFields: QuestionBase<any>[];
	public isHourMeterReadingFormLoading: boolean;
	public hourMeterReadingFormOptions: any = {
		submitButtonText: "Save Entry",
		submitButtonClass: "btn-success",
		submitButtonTitle: "Press this button to save the Hour Meter Reading entry.",
		saveValuesPerField: false,
		clearFieldsAfterSubmit: true,
		saveStoredProcedureName: "API.GS_HourMeterReading"
	};
	public hourMeterReadingEvents: any;
	currentHourMeterReadingValue: any;

	//-- Maintenance Mode / Out of Service fields
	public isMaintenanceFormLoading: boolean = true;
	public maintenanceHeader: string = "Maintenance";
	public maintenanceSubHeader: string = "Please enter the fields listed for this maintenance event.";
	public maintenanceFields: Array<any>;
	public maintenanceFormOptions: any = {
		submitButtonText: "",
		saveValuesPerField: true,
		saveStoredProcedureName: "API.Asset_UpdateMaintenanceModeOrOutOfService"
	};

	public maintenanceForm: FormGroup;
	private maintenanceMode: FormControl;
	private outOfService: FormControl;
	private outOfServiceReasonId: FormControl;
	private maintenanceModeReasonId: FormControl;

	public maintenanceModeReasons: Array<any>;
	public outOfServiceReasons: Array<any>;
	private fullAssetRecord: any;
	public hasElevatorRotunda: boolean = false;
	public systemHasCameras: boolean = false;
	public displaySystemCameras: boolean = false;
	public parentSystem: ISystem = null;
	public assetId: number;
	public currentAsset: IAsset = null;
	public widgetInstance: number = Math.random();

	public kendoGrids = {
		tags: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						filter: "numeric",
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "Favorite",
						title: "Favorite",
						filterable: true,
						filter: 'boolean',
						_width: 50,
					},
					{
						field: 'alertNotification',
						title: 'Alarm',
						filterable: true,
						filter: 'boolean',
						_width: 40,
						hidden: false,
						minResizableWidth: 40,
					},
				],
			} as GridSettings,
		},
		activeAlerts: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 50,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						_width: 100,
						minResizableWidth: 125
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					},
					{
						field: "ValueWhenActive",
						title: "Value When Active",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		},
		options: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		},
		movePrevention: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		},
		elevatorRotunda: {
			data: [],
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "chart",
						title: "Chart",
						filterable: false,
						_width: 40,
						hidden: false,
						minResizableWidth: 40
					},
					{
						field: "ShortTagName",
						title: "Name",
						filterable: true,
						_width: 150,
						minResizableWidth: 200
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 50,
						minResizableWidth: 60
					}
				]
			} as GridSettings
		},
		last1000Events: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 125,
						_width: 100
					},
					{
						field: "JavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "Date",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filter: "numeric",
						filterable: true,
						_width: 100
					}
				]
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				alarmsOnly: false,
				gridInView: false,
				exportTitle: ""
			}
		},
		last1000Alarms: {
			isRendered: false,
			gridSettings: {
				state: {
					skip: 0,
					filter: {
						logic: "and",
						filters: []
					},
					take: 15
				},
				columnsConfig: [
					{
						field: "Name",
						title: "Name",
						filterable: true,
						_width: 100
					},
					{
						field: "Severity",
						title: "Severity",
						filterable: true,
						minResizableWidth: 125,
						_width: 50
					},
					{
						field: "JavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptDate",
						title: "StartDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "JavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: true,
						_width: 100
					},
					{
						field: "SiteLocalJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "UTCJavascriptEndDate",
						title: "EndDate",
						filterable: true,
						filter: "date",
						includeInChooser: false,
						_width: 100
					},
					{
						field: "Value",
						title: "Value",
						filterable: true,
						filter: "numeric",
						_width: 0
					},
					{
						field: "Duration",
						title: "Duration (secs)",
						filterable: true,
						_width: 100
					},
					{
						field: "DurationinMins",
						title: "Duration (mins)",
						filterable: true,
						_width: 100
					}
				]
			} as GridSettings,
			config: {
				parentSelectedTabIndex: 0,
				assetId: undefined,
				siteUTCTimeOffset: undefined,
				assetTagIds: undefined,
				currentAlarms: undefined,
				alarmsOnly: true,
				gridInView: false,
				exportTitle: ""
			}
		}
	};

	hourMeterReadingEventsGridSettings: GridSettings = {
		state: {
			skip: 0,
			filter: {
				logic: "and",
				filters: []
			},
			take: 15
		},

		columnsConfig: [
			{
				field: "Reading",
				title: "Reading",
				filterable: true,
				_width: 50
			},
			{
				field: "Date",
				title: "Date",
				filterable: true,
				filter: "date",
				_width: 100
			},
			{
				field: "CreatorUser",
				title: "Creator User",
				filterable: true,
				_width: 100
			}
		]
	};

	public widgetTabs = [
		{ textLabel: "Quick View", rendered: true, visible: true },
		{ textLabel: "Data", rendered: false, visible: true },
		{ textLabel: "Options", rendered: false, visible: true },
		{ textLabel: "Move Prevention", rendered: false, visible: true },
		{ textLabel: "Elevator", rendered: false, visible: true },
		{ textLabel: "Alerts", rendered: false, visible: true },
		{ textLabel: "Last 1000 Events", rendered: false, visible: true },
		{ textLabel: "Last 1000 Faults", rendered: false, visible: true },
		{ textLabel: "Statistics", rendered: false, visible: true },
		{ textLabel: "Cameras", rendered: false, visible: true },
		{ textLabel: "Maintenance", rendered: false, visible: true }
	];

	selectedMatTabLabel: any;
	tagGraphSingleModalSubscription: any;
	public gridContextMenuItems = ["Quick Trend of Tag"];

	checkAlarmNotificationsInterval;
	public guid: string;

	constructor(public dataService: DataService,
		private signalRCore: SignalRCoreService,
		private ref: ChangeDetectorRef,
		private kendoSettingsService: KendoSettingsService,
		private dashboardService: DashboardService,
		private toastr: ToastrService,
		private utilityService: UtilityService,
		private dialog: MatDialog,
		private zone: NgZone
	) { }

	ngOnInit() {
		this.guid = this.dataService.guid();
		this.isDataLoading = true;
		this.fromDashboard = this.widgetObject ? true : false;

		if (!Global.FullDataCacheExists) {
			this.fullDataCacheSubscription = this.dataService.fullDataCacheExists$.subscribe((data: any) => {
				if (data === true) {
					if (Global.Theme === "light") {
						this.theme = "light";
					} else if (Global.Theme === "dark") {
						this.theme = "dark";
					}
					this.initialize();
					this.fullDataCacheSubscription.unsubscribe();
				}
			});
		} else {
			this.initialize();
		}

		if (this.fromDashboard === true) {
			Global.User.DebugMode && console.log(this.componentName + ": this.widgetObject = %O", this.widgetObject);
			this.isWidgetInView = this.widgetObject.isWidgetInView;
			this.widgetIsInViewSubscription = this.dashboardService.widgetInViewChanged$.subscribe((data: any) => {
				if (data.widgetId === this.widgetObject.WidgetId) {
					if (this.tagDataRowForCalculation !== undefined) {
						this.tagDataRowHeight = this.tagDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}
					if (this.eventDataRowForCalculation !== undefined) {
						this.eventDataRowHeight = this.eventDataRowForCalculation.nativeElement.getBoundingClientRect().height;
					}
					console.log(this.isWidgetInView);
					console.log(this.widgetObject.isWidgetInView);
					if (this.isWidgetInView === false && data.isInView === true) {
						// this.loadDataForSelectedTab();
					}
					this.isWidgetInView = data.isInView;
				}
			});
		} else {
			this.isWidgetInView = true;
		}

		this.colorChangedSubscription = this.dataService.colorChanged$.subscribe((data: any) => {
			Global.User.DebugMode && console.log(this.componentName + ": color changed data = %O", data);
			if (data === "light") {
				this.theme = "light";
			} else {
				this.theme = "dark";
			}
		});

		if (this.dashboardTimeZoneChanged) {
			this.dashboardTimeZoneChangedSubscription = this.dashboardTimeZoneChanged.subscribe((data) => {
				console.log(data);
				let foundWidgetWithSameWidgetId = data.find((widgetThatWasChanged) => {
					return widgetThatWasChanged.WidgetId === this.widgetObject.WidgetId;
				});

				if (!_.isNil(foundWidgetWithSameWidgetId)) {
					console.log("Widget Time Zone Changed");
					this.timeZoneType = this.dashboardService.determineTimeZoneType(this.widgetObject);
					Object.values(this.kendoGrids).forEach((grid) => {
						// Adjust visible and hidden columns
						const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
						const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
						const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

						localDateCol.hidden = this.timeZoneType !== "User Time";
						siteDateCol.hidden = this.timeZoneType !== "Site Time";
						utcDateCol.hidden = this.timeZoneType !== "UTC Time";
						localDateCol.includeInChooser = !localDateCol.hidden;
						siteDateCol.includeInChooser = !siteDateCol.hidden;
						utcDateCol.includeInChooser = !utcDateCol.hidden;
						const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
						const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
						const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
						if (localEndDateCol != undefined) {
							localEndDateCol.hidden = this.timeZoneType !== 'User Time';
							localEndDateCol.includeInChooser = !localEndDateCol.hidden;
						}
						if (siteEndDateCol != undefined) {
							siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
							siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
						}
						if (utcEndDateCol != undefined) {
							utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
							utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
						}
					});
				}
				//if data (which is list of widgets that had the time zone changed as an array of widgets includes a widget with this widget id, we can assume this widget needs to be updated. needs to initialize with new selected time zone in mind. )
				this.initialize();
			});
		}
		// setTimeout(() => {
		// 	this.checkDataCacheForMyTags();
		// }, 500);
		console.log("widget object = %O", this.widgetObject);
		console.log("data object = %O", this.dataObject);

		
	}

	onResized(event) {
		this.tagDataPageSize = Math.floor(((event.newRect.height - 120) / this.tagDataRowHeight) * 3);
		this.eventDataPageSize = Math.floor(((event.newRect.height - 120) / this.eventDataRowHeight) * 3);
		this.parentContainerSize = event.newRect.height;
	}

	ngOnDestroy() {
		Global.User.DebugMode && console.log(this.componentName + ": ngOnDestroy invoked...");
		this.dataService.unsubscribeAndLeaveActiveSubjects(this.guid);

		if (this.widgetIsInViewSubscription) {
			this.widgetIsInViewSubscription.unsubscribe();
		}

		if (this.colorChangedSubscription) {
			this.colorChangedSubscription.unsubscribe();
		}

		if (this.dashboardTimeZoneChangedSubscription) {
			this.dashboardTimeZoneChangedSubscription.unsubscribe();
		}

		this.signalRAssetGroupUpdateSubscription && this.signalRAssetGroupUpdateSubscription.unsubscribe();

		if (this.checkAlarmNotificationsInterval) {
			clearInterval(this.checkAlarmNotificationsInterval);
		}
	}

	saveGridSettings() {
		if (this.fromDashboard) {
			let hiddenColsArray = [];
			let gridObjectsArray = [];

			if (this.tagDataGrid) {
				hiddenColsArray.push(this.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "tagDataGrid",
					gridObject: this.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.tags.gridSettings.state
				});
			}

			if (this.optionDataGrid) {
				hiddenColsArray.push(this.optionDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "optionDataGrid",
					gridObject: this.optionDataGrid.kendoGridParent,
					gridState: this.kendoGrids.options.gridSettings.state
				});
			}

			if (this.movePreventionDataGrid) {
				hiddenColsArray.push(this.movePreventionDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "movePreventionDataGrid",
					gridObject: this.movePreventionDataGrid.kendoGridParent,
					gridState: this.kendoGrids.movePrevention.gridSettings.state
				});
			}

			if (this.elevatorRotundaDataGrid) {
				hiddenColsArray.push(this.elevatorRotundaDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "elevatorRotundaDataGrid",
					gridObject: this.elevatorRotundaDataGrid.kendoGridParent,
					gridState: this.kendoGrids.elevatorRotunda.gridSettings.state
				});
			}

			if (this.alarmDataGrid) {
				hiddenColsArray.push(this.alarmDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "alarmDataGrid",
					gridObject: this.alarmDataGrid.kendoGridParent,
					gridState: this.kendoGrids.activeAlerts.gridSettings.state
				});
			}

			if (this.hourMeterReadingEventsGrid) {
				hiddenColsArray.push(this.hourMeterReadingEventsGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "hourMeterReadingEventsGrid",
					gridObject: this.hourMeterReadingEventsGrid.kendoGridParent,
					gridState: this.hourMeterReadingEventsGridSettings.state
				});
			}

			if (this.last1000EventsGrid) {
				hiddenColsArray.push(this.last1000EventsGrid.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "last1000EventsGrid",
					gridObject: this.last1000EventsGrid.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.last1000Events.gridSettings.state
				});
			}
			if (this.last1000AlarmsGrid) {
				hiddenColsArray.push(this.last1000AlarmsGrid.tagDataGrid.kendoGridParent.columnList.columns._results);
				gridObjectsArray.push({
					gridName: "last1000AlarmsGrid",
					gridObject: this.last1000AlarmsGrid.tagDataGrid.kendoGridParent,
					gridState: this.kendoGrids.last1000Alarms.gridSettings.state
				});
			}

			this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(hiddenColsArray, this.widgetObject.WidgetId); // update this to ignore columns not inccluded in chooser
			this.widgetObject.WidgetKendoUIJson = this.kendoSettingsService.saveGridSettings(gridObjectsArray, this.widgetObject.WidgetId).then((data: any) => {
				console.log(data);
				this.widgetObject.WidgetKendoUIJson = data;
			});
		}
	}

	mapGridSettings(gridSettings: GridSettings) {
		if (this.fromDashboard) {
			const state = gridSettings.state;
			Global.User.DebugMode && console.log(this.componentName + ": mapGridSettings state = %O", state);
			let emptyArray: any = [];
			return {
				state,
				columnsConfig: gridSettings.columnsConfig.sort((a, b) => a.orderIndex - b.orderIndex),
				gridData: this.dataService.cache !== undefined ? process(this.kendoGrids.tags.data, state) : emptyArray
			};
		}
	}

	initialize() {
		Global.User.DebugMode && console.log(this.componentName + ": initialize invoked...");

		if (this.fromDashboard && !this.widgetObject.WidgetGateSystemId) {
			this.isDataLoading = false;
			return;
		}

		this.selectedMatTabLabel = this.widgetTabs[0].textLabel;
		this.timeZoneType = this.dashboardService.determineTimeZoneType(this.fromDashboard ? this.widgetObject : this.dataObject);

		// Get Asset Info
		this.assetBaseImageURL = Global.imagesUrl + "assets/images/PBBGraphics/Bridge.png";
		this.aircraftDockedImageURL = Global.imagesUrl + "assets/images/PBBGraphics/Plane_At_Gate.png";

		if (this.fromDashboard) {
			if (this.widgetObject.WidgetId !== undefined) {
				this.finishInitializingWidget();
			} else if (this.pbbSummaryCreatedEvent) {
				this.pbbSummaryCreatedSubscription = this.pbbSummaryCreatedEvent.subscribe((data) => {
					if (data.WidgetId === this.widgetObject.WidgetId) {
						this.finishInitializingWidget();
					}
				});
			}
		} else {
			this.assetObj = this.dataObject.assetObj;
			this.assetId = this.assetObj.Id;
			this.currentAsset = this.dataService.cache.assetsObject[this.assetId];
			this.parentSystem = this.currentAsset.ParentSystem;

			this.kendoGrids.last1000Events.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${this.assetObj.SiteName}_${this.assetObj.GateName}_${this.assetObj.Type}`;
			this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${this.assetObj.SiteName}_${this.assetObj.GateName}_${this.assetObj.Type}`;
			this.finishInitializingWidget();
		}
	}

	finishInitializingWidget() {
		Global.User.DebugMode && console.log(this.componentName + ": finishInitializingWidget invoked...");
		Global.User.DebugMode && console.log(this.componentName + ": this.assetObj = %O", this.assetObj);

		if (!this.assetObj) {
			this.assetObj = this.dataService.cache.assets.find((a) => a.SiteId == this.widgetObject.WidgetSiteId && a.ParentSystemId == this.widgetObject.WidgetGateSystemId && a.Name == "PBB");
			this.kendoGrids.last1000Events.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Alarms.config.assetId = this.assetObj.Id;
			this.kendoGrids.last1000Events.config.exportTitle = `Last-1000-Events_${this.assetObj.Site.Name}_${this.assetObj.ParentSystem.Name}_${this.assetObj.AssetTypeName}`;
			this.kendoGrids.last1000Alarms.config.exportTitle = `Last-1000-Alarms_${this.assetObj.Site.Name}_${this.assetObj.ParentSystem.Name}_${this.assetObj.AssetTypeName}`;
		}

		this.siteUTCTimeOffset = this.dataService.cache.sitesObject[this.widgetObject ? this.widgetObject.WidgetSiteId : this.assetObj.SiteId].UTCTimeOffset;
		this.kendoGrids.last1000Events.config.siteUTCTimeOffset = this.siteUTCTimeOffset;
		this.kendoGrids.last1000Alarms.config.siteUTCTimeOffset = this.siteUTCTimeOffset;

		this.assetId = this.assetObj.Id;
		this.fullAssetRecord = this.dataService.cache.assetsObject[this.assetId];
		this.hasElevatorRotunda = this.fullAssetRecord.HasElevatorRotunda;
		this.systemHasCameras = this.dataService.cache.systemsObject[this.fullAssetRecord.ParentSystemId].Cameras?.length > 0;

		this.widgetTabs.forEach((tab: any) => {
			switch (tab.textLabel) {
				case "Elevator":
					tab.visible = this.hasElevatorRotunda;
					break;
				case "Cameras":
					tab.visible = this.systemHasCameras;
					break;
			}
		});

		this.widgetTabs = this.widgetTabs
			.where((tab: any) => {
				return tab.visible;
			})
			.toArray(); //--should hide the Elevator and Cameras tabs when there are no cameras and there is no elevating rotunda. --Kirk T. Sherer, August 7, 2023.

		Global.User.DebugMode && console.log(this.componentName + ": hasElevatorRotunda = " + this.hasElevatorRotunda);

		Global.User.DebugMode && console.log(this.componentName + ": Getting tags....");
		this.dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds(this.assetId.toString()).subscribe((data) => {
			Global.User.DebugMode && console.log(this.componentName + ": dataService.GetAllSignalRObservationFormattedTagsForAssetIdIntoInventoryByListOfAssetIds data = %O", data);

			this.formatTagDataForGrid(data);
			if (this.dataObject !== undefined) {
				this.dataObject.isDisplayDataLive = true;
			} else if (this.widgetObject !== undefined) {
				this.widgetObject.isDisplayDataLive = true;
			}
			if (this.fromDashboard) {
				if (this.widgetObject.WidgetKendoUIJson) {
					let gridColumnConfigs = [];
					let jsonObjectParsed = this.kendoSettingsService.parseReturnedSettingsForDates(this.widgetObject.WidgetKendoUIJson);
					let tagDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "tagDataGrid");
					let optionDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "optionDataGrid");
					let movePreventionDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "movePreventionDataGrid");
					let elevatorRotundaDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "elevatorRotundaDataGrid");
					let alarmDataGridSettings = jsonObjectParsed.find((grid) => grid.name == "alarmDataGrid");
					let hourMeterReadingEventsGridSettings = jsonObjectParsed.find((grid) => grid.name == "hourMeterReadingEventsGrid");
					let last1000EventsGridSettings = jsonObjectParsed.find((grid) => grid.name == "last1000EventsGrid");
					let last1000AlarmsGridSettings = jsonObjectParsed.find((grid) => grid.name == "last1000AlarmsGrid");

					if (tagDataGridSettings) {
						let mergedTemplateAndSavedColumnsForData = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.tags.gridSettings, tagDataGridSettings);
						this.kendoGrids.tags.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForData);
						gridColumnConfigs.push(this.kendoGrids.tags.gridSettings.columnsConfig);
					}

					if (optionDataGridSettings) {
						let mergedTemplateAndSavedColumnsForOptions = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.options.gridSettings, optionDataGridSettings);
						this.kendoGrids.options.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForOptions);
						gridColumnConfigs.push(this.kendoGrids.options.gridSettings.columnsConfig);
					}

					if (movePreventionDataGridSettings) {
						let mergedTemplateAndSavedColumnsForMovePrevention = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.movePrevention.gridSettings, movePreventionDataGridSettings);
						this.kendoGrids.movePrevention.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForMovePrevention);
						gridColumnConfigs.push(this.kendoGrids.movePrevention.gridSettings.columnsConfig);
					}

					if (elevatorRotundaDataGridSettings) {
						let mergedTemplateAndSavedColumnsForElevator = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.elevatorRotunda.gridSettings, elevatorRotundaDataGridSettings);
						this.kendoGrids.elevatorRotunda.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForElevator);
						gridColumnConfigs.push(this.kendoGrids.elevatorRotunda.gridSettings.columnsConfig);
					}

					if (alarmDataGridSettings) {
						let mergedTemplateAndSavedColumnsForAlarms = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.activeAlerts.gridSettings, alarmDataGridSettings);
						this.kendoGrids.activeAlerts.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForAlarms);
						gridColumnConfigs.push(this.kendoGrids.activeAlerts.gridSettings.columnsConfig);
					}

					if (hourMeterReadingEventsGridSettings) {
						let mergedTemplateAndSavedColumnsForHourMeter = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.hourMeterReadingEventsGridSettings, hourMeterReadingEventsGridSettings);
						this.hourMeterReadingEventsGridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForHourMeter);
						gridColumnConfigs.push(this.hourMeterReadingEventsGridSettings.columnsConfig);
					}

					if (last1000EventsGridSettings) {
						let mergedTemplateAndSavedColumnsForLast1000Events = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.last1000Events.gridSettings, last1000EventsGridSettings);
						this.kendoGrids.last1000Events.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForLast1000Events);
						gridColumnConfigs.push(this.kendoGrids.last1000Events.gridSettings.columnsConfig);
					}

					if (last1000AlarmsGridSettings) {
						let mergedTemplateAndSavedColumnsForLast1000Alarms = this.kendoSettingsService.mergeTemplateAndSavedColumnsToOneGrid(this.kendoGrids.last1000Alarms.gridSettings, last1000AlarmsGridSettings);
						this.kendoGrids.last1000Alarms.gridSettings = this.mapGridSettings(mergedTemplateAndSavedColumnsForLast1000Alarms);
						gridColumnConfigs.push(this.kendoGrids.last1000Alarms.gridSettings.columnsConfig);
					}

					this.dashboardService.addOrUpdateHiddenColumnCountToDashboardWidget(gridColumnConfigs, this.widgetObject.WidgetId);
				}

				Object.values(this.kendoGrids).forEach((grid) => {
					// Adjust visible and hidden columns
					const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
					const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
					const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

					localDateCol.hidden = this.timeZoneType !== "User Time";
					siteDateCol.hidden = this.timeZoneType !== "Site Time";
					utcDateCol.hidden = this.timeZoneType !== "UTC Time";
					localDateCol.includeInChooser = !localDateCol.hidden;
					siteDateCol.includeInChooser = !siteDateCol.hidden;
					utcDateCol.includeInChooser = !utcDateCol.hidden;
					const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
					const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
					const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
					if (localEndDateCol != undefined) {
						localEndDateCol.hidden = this.timeZoneType !== 'User Time';
						localEndDateCol.includeInChooser = !localEndDateCol.hidden;
					}
					if (siteEndDateCol != undefined) {
						siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
						siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
					}
					if (utcEndDateCol != undefined) {
						utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
						utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
					}
				});
			}

			Object.values(this.kendoGrids).forEach((grid) => {
				// Adjust visible and hidden columns
				const localDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "JavascriptDate");
				const siteDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "SiteLocalJavascriptDate");
				const utcDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == "UTCJavascriptDate");

				localDateCol.hidden = this.timeZoneType !== "User Time";
				siteDateCol.hidden = this.timeZoneType !== "Site Time";
				utcDateCol.hidden = this.timeZoneType !== "UTC Time";
				localDateCol.includeInChooser = !localDateCol.hidden;
				siteDateCol.includeInChooser = !siteDateCol.hidden;
				utcDateCol.includeInChooser = !utcDateCol.hidden;
				const localEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'JavascriptEndDate');
				const siteEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'SiteLocalJavascriptEndDate');
				const utcEndDateCol = grid.gridSettings.columnsConfig.find((col) => col.field == 'UTCJavascriptEndDate');
				if (localEndDateCol != undefined) {
					localEndDateCol.hidden = this.timeZoneType !== 'User Time';
					localEndDateCol.includeInChooser = !localEndDateCol.hidden;
				}
				if (siteEndDateCol != undefined) {
					siteEndDateCol.hidden = this.timeZoneType !== 'Site Time';
					siteEndDateCol.includeInChooser = !siteEndDateCol.hidden;
				}
				if (utcEndDateCol != undefined) {
					utcEndDateCol.hidden = this.timeZoneType !== 'UTC Time';
					utcEndDateCol.includeInChooser = !utcEndDateCol.hidden;
				}
			});
		});
	}

	formatTagDataForGrid(tags: any) {
		var service = this;
		service.assetId = service.assetObj.Id ? service.assetObj.Id : tags.first().AssetId;
		service.buildMaintenanceQuestions();
		service.buildHourMeterReadingFormQuestions();
		service.checkAircraftDocked(tags);
		service.checkAlarmNotifications(tags);

		this.checkAlarmNotificationsInterval = setInterval(() => {
			service.checkAlarmNotifications(tags);
		}, 10000);

		service.assetTagIds = service.dataService.cache.assetsObject[service.assetId].Tags.map((t) => t.TagId);

		service.alarmTagIds = service.dataService.cache.assetsObject[service.assetId].Tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm" || t.Severity == "Warning").map((t) => t.TagId);

		//Quick View tags
		let quickViewTags = service.dataService.cache.assetsObject[service.assetId].Tags.filter((t) => service.quickViewIds.includes(t.JBTStandardObservationId));
		service.quickViewTagIds = quickViewTags.map((t) => t.TagId);

		service.kendoGrids.last1000Events.config.assetTagIds = service.dataService.cache.assetsObject[service.assetId].Tags.filter((t) => t.Severity == "Informational" || t.Severity == "Warning").map((t) => t.TagId);
		service.kendoGrids.last1000Alarms.config.assetTagIds = service.dataService.cache.assetsObject[service.assetId].Tags.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm").map((t) => t.TagId);
		// service.kendoGrids.last1000Alarms.config.currentAlarms = this.kendoGrids.activeAlerts.data.filter((t) => t.Severity == "Critical" || t.Severity == "Alarm");

		if (service.tagDataGrid) {
			service.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
		}

		service.countOfAlarms = service.dataService.cache.assetsObject[service.assetObj.Id].ActiveAlarmTags.length;
		service.alertStatus = service.alertsStatus(service.dataService.cache.assetsObject[service.assetObj.Id].ActiveAlarmTags);

		// Get Option Tags

		service.isDataLoading = false;
		service.getSignalRUpdates();
	}

	alertsStatus(activeAlertsData: any) {
		//Critical
		if (activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 3).length > 0)
			return 3;
		//Alarm
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 1).length > 0
		)
			return 1;
		//Warning
		else if (
			activeAlertsData.filter((t) => t.EffectiveSeverityLevelId == 2).length > 0
		)
			return 2;
	}

	getSignalRUpdates() {

		let assetObjectInCache = this.dataService.cache.assetsObject[this.assetObj.Id];
		let tagNamePrefixesString = assetObjectInCache.TagNamePrefix;
		Global.SignalR.ListOfTagNamePrefixes = Global.SignalR.ListOfTagNamePrefixes != null ? Global.SignalR.ListOfTagNamePrefixes += "," + tagNamePrefixesString : tagNamePrefixesString;

		
		this.signalRCore.joinGroups();

		if (this.widgetObject && this.widgetObject.WidgetId !== undefined) {
			this.widgetGroupSettings = {
				WidgetId: this.widgetObject.WidgetId,
				GroupList: tagNamePrefixesString,
				IsPopup: false
			};
		} else {
			this.widgetGroupSettings = {
				WidgetId: this.signalRCore.generateIdForPopupThatIsUnique(),
				GroupList: tagNamePrefixesString,
				IsPopup: true,
			};
		}

		Global.User.DebugMode && console.log(this.componentName + "this.widgetGroupSettings = %O", this.widgetGroupSettings);

		this.dataService
			.createSubjectAndSubscribe({ Id: this.guid, 
										 WidgetName: (this.dataObject != null ? this.dataObject.WidgetName : this.widgetObject.WidgetName), 
										 TagNamePrefix: [ tagNamePrefixesString ]  })
			.then((data) => {
				//subscribe to existing subject
				Global.User.DebugMode && console.log(this.componentName + "current active subjects: %O", this.dataService.activeSubjects);

				var activeSubject = this.dataService.activeSubjects.firstOrDefault((subject:ITagNamePrefixSubject) => { return subject.Id == this.guid });
				activeSubject && activeSubject.Subject$.subscribe((tag: ITag) => {
					//console.log(this.componentName + "Updating tag we care about: %O", tag);
					this.updateTagGridData(tag);
					this.updateOptionGridData(tag);
					this.updateMovePreventionGridData(tag);
					if (this.hasElevatorRotunda) {
						this.updateElevatorRotundaGridData(tag);
					}
					this.updateAlarmGridData();
					this.systemHasCameras = this.dataService.cache.systemsObject[this.fullAssetRecord.ParentSystemId].Cameras?.length > 0;
				});
			});
	}

	matTabHasChanged(e) {
		console.log(e);
		this.selectedMatTabLabel = e.tab.textLabel;
		this.widgetTabs.find((tab) => tab.textLabel == this.selectedMatTabLabel).rendered = true;
		this.kendoGrids.last1000Events.config.gridInView = this.selectedMatTabLabel === "Last 1000 Events" ? true : false;
		this.kendoGrids.last1000Alarms.config.gridInView = this.selectedMatTabLabel === "Last 1000 Faults" ? true : false;

		switch (this.selectedMatTabLabel) {
			case "Data":
				if (this.tagDataGrid) {
					this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
				}
				break;

			case "Options":
				if (this.optionDataGrid) {
					this.optionDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].OptionTags);
				}
				break;

			case "Move Prevention":
				if (this.movePreventionDataGrid) {
					this.movePreventionDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].MovePreventionTags);
				}
				break;

			case "Elevator":
				if (this.elevatorRotundaDataGrid) {
					this.elevatorRotundaDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ElevatorRotundaTags);
				}
				break;

			case "Alerts":
				if (this.alarmDataGrid) {
					this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
				}
				break;

			case "Last 1000 Events":
				if (this.kendoGrids.last1000Events.isRendered) {
					this.last1000EventsGrid.updateDataAfterTabSwitch();
				} else {
					this.kendoGrids.last1000Events.isRendered = true;
				}
				break;
			case "Last 1000 Faults":
				if (this.kendoGrids.last1000Alarms.isRendered) {
					this.last1000AlarmsGrid.updateDataAfterTabSwitch();
				} else {
					this.kendoGrids.last1000Alarms.isRendered = true;
				}
				break;

			case "Statistics":
				this.buildHourMeterReadingFormQuestions();
				break;

			case "Cameras":
				if (this.systemHasCameras) {
					this.showSystemCameras();
				}
				break;
			case "Maintenance":
				this.buildMaintenanceQuestions();
				break;
		}
	}

	updateAlarmGridData() {
			if (this.alarmDataGrid) {
				this.alarmDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
			}

		this.alertStatus = this.alertsStatus(this.dataService.cache.assetsObject[this.assetId].ActiveAlarmTags);
	}

	updateTagGridData(tagObj: any) {
		if (this.tagDataGrid) {
			this.tagDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].Tags);
		}

		switch (tagObj.JBTStandardObservationId) {
			case 56885: //Update Hour Meter Reading Value
				this.currentHourMeterReadingValue = tagObj.Value;
				break;
		}

		if (tagObj.JBTStandardObservationId == 12245) {
			this.isAircraftDocked = tagObj.Value == "1" ? true : false; // Update Aircraft Docked
		}
	}

	updateOptionGridData(tagObj: any) {
		if (tagObj.ShortTagName?.toLowerCase().includes("option") && this.optionDataGrid) {
			this.optionDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].OptionTags);
		}
	}

	updateMovePreventionGridData(tagObj: any) {
		if (tagObj.JBTStandardObservationId && this.dataService.movePreventionTagJBTStandardObservationIds.includes(tagObj.JBTStandardObservationId) && this.movePreventionDataGrid) {
			this.movePreventionDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].MovePreventionTags);
		}
	}

	updateElevatorRotundaGridData(tagObj: any) {
		if (tagObj.JBTStandardObservationId && this.dataService.elevatorRotundaTagJBTStandardObservationIds.includes(tagObj.JBTStandardObservationId) && this.elevatorRotundaDataGrid) {
			this.elevatorRotundaDataGrid.gridDataSubject.next(this.dataService.cache.assetsObject[this.assetId].ElevatorRotundaTags);
		}
	}

	checkAlarmNotifications(tags: any) {

		tags.forEach(t => {

			let userAlert = this.dataService.cache.emailTagUsers.find((a) =>
								a.UserId == Global.User.currentUser.Id &&
								(a.TagId == t.TagId ||
									(a.JbtStandardObservationId == t.JBTStandardObservationId && a.JbtStandardObservationSiteId == t.SiteId )
								));

			t.alertNotification = userAlert == undefined ? false : true;

			// set the title with alert info
			if(userAlert != undefined) {

				let title = "Alert notifications set for " + t.JBTStandardObservation.Name;
				let alertObject = this.GetAlertNotificationDetails(userAlert);

				if(alertObject.Email == true && alertObject.TextMessage == true) {
					title += " email and text delivery";
				}
				else {
					title += alertObject.Email == true ? " email delivery" : "";
					title += alertObject.TextMessage == true ? " text delivery" : "";
				}

				title += " on " + alertObject.DaysSelected;
				title += " during "+ alertObject.HoursSelected;

				t.alertNotificationTitle = title;
			}
			else {
				t.alertNotificationTitle = "Click to set alert management settings.";
			}

		});

	}


	GetAlertNotificationDetails(userAlert) : any {

		let alertObject: any = {
			siteId: 0,
			siteName: '',
			gateSystemId: 0,
			gateSystemName: '',
			category: '',
			Email: 0,
		};


		// delivery
		alertObject.Email = userAlert.Email == 1 ? true : false;
		alertObject.TextMessage = userAlert.TextMessage == 1 ? true : false;

		// set days
		if(userAlert.SendAllDays == 1) {
			alertObject.DaysSelected = "all days";
		}
		else {
			alertObject.DaysSelected = "select days";
		}


		// calc work hours
		let hourToStart = userAlert.UserTimeZoneOffset + 8;

		// set hours
		if(userAlert.SendAllHours == 1) {
			alertObject.HoursSelected = "all Hours";
		}
		else {
			alertObject.HoursSelected = "select Hours";
		}

		return alertObject;
	}


	checkAircraftDocked(tags: any) {
		let tagObj = tags.find((t) => t.JBTStandardObservationId == 12245);
		if (tagObj?.Value) {
			this.isAircraftDocked = tagObj?.Value == "1" ? true : false;
		} else if (tagObj?.LastObservationTextValue) {
			this.isAircraftDocked = tagObj?.LastObservationTextValue == "1" ? true : false;
		}
		Global.User.DebugMode && console.log(this.componentName + ": Aircraft Docked: " + this.isAircraftDocked);
	}

	showSystemCameras() {
		Global.User.DebugMode && console.log(this.componentName + ": attempting to display system cameras... parentSystem = %O", this.parentSystem);
		this.displaySystemCameras = true;
	}

	buildMaintenanceQuestions() {
		var service = this;
		if (service.assetObj !== undefined) {
			service.isMaintenanceFormLoading = true;
			Global.User.DebugMode && console.log(service.componentName + ": buildMaintenanceQuestions invoked...");
			service.fullAssetRecord = service.currentAsset;
			if (!service.fullAssetRecord) {
				service.fullAssetRecord = service.dataService.cache.assetsObject[service.assetObj.Id];
			}

			Global.User.DebugMode && console.log(service.componentName + ": this.fullAssetRecord = %O", service.fullAssetRecord);
			service.maintenanceMode = new FormControl(service.fullAssetRecord.MaintenanceMode);
			service.outOfService = new FormControl(service.fullAssetRecord.OutOfService);
			service.maintenanceModeReasonId = new FormControl(service.fullAssetRecord.MaintenanceModeReasonId);
			service.outOfServiceReasonId = new FormControl(service.fullAssetRecord.OutOfServiceReasonId);

			service.maintenanceForm = new FormGroup({
				maintenanceMode: service.maintenanceMode,
				outOfService: service.outOfService,
				maintenanceModeReasonId: service.maintenanceModeReasonId,
				outOfServiceReasonId: service.outOfServiceReasonId
			});

			service.maintenanceModeReasons = service.dataService.cache.assetMaintenanceModeReasons;
			service.outOfServiceReasons = service.dataService.cache.assetOutOfServiceReasons;

			service.maintenanceForm.valueChanges.subscribe((val) => {
				Global.User.DebugMode && console.log(service.componentName + ": " + "val = %O", val);

				if ((!service.maintenanceForm.value.maintenanceMode || service.maintenanceForm.value.maintenanceMode == "") && (!service.maintenanceForm.value.outOfService || service.maintenanceForm.value.outOfService == "")) {
					service.saveValue(null, service.maintenanceForm); //-- have to save the asset since the maintenance mode and out of service have both been set to false. --Kirk T. Sherer, October 21, 2020.
				}
			});

			service.isMaintenanceFormLoading = false;
		}
	}
	sliderSelect(event: Event, obj: any) {
		//console.log("obj = %O", obj);
	}
	saveValue(event: Event, obj: any) {
		var service = this;
		if (event) {
			event.stopPropagation();
		}
		//		console.log("saveValue invoked... event.target = %O", event.target);
		//var changedObject: any = event.target;
		//		console.log("changedObject = %O", changedObject);

		//		console.log("obj = %O", obj);
		var sqlStatement = this.maintenanceFormOptions.saveStoredProcedureName + " @UserId=" + Global.User.currentUser.Id + ", @AssetId=" + this.assetId + ", @MaintenanceMode=" + (obj.value.maintenanceMode ? "1" : "0") + ", @OutOfService=" + (obj.value.outOfService ? "1" : "0") + ", @MaintenanceModeReasonId=" + (obj.value.maintenanceModeReasonId == "" || !obj.value.maintenanceMode ? "null" : obj.value.maintenanceModeReasonId) + ", @OutOfServiceReasonId=" + (obj.value.outOfServiceReasonId == "" || !obj.value.outOfService ? "null" : obj.value.outOfServiceReasonId);
		Global.User.DebugMode && console.log(this.componentName + ": SQL Statement = " + sqlStatement);

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		//-- SECTION FOR SENDING OUT SIGNALR MESSAGE IMMEDIATELY BEFORE SENDING THE STORED PROCEDURE TO UPDATE THE ACTUAL ASSET RECORD AND CREATE THE OBSERVATIONS.
		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
		var assetInCache = this.dataService.cache.assetsObject[this.assetObj.Id];
		assetInCache.Tags.forEach((tag:ITag) => {
			console.log("Id: " + tag.Id + ", Name: " + tag.Name + ", SimpleName: " + tag.SimpleName);
		});
		//-- Find the out of service and maintenance mode tags for this asset and build a set of SignalR messages so we can update the application immediately while the stored procedure is updating SQL Server.
		var maintenanceModeOutOfServiceTags = assetInCache.Tags.where((tag:ITag) => {

			return (tag.SimpleName?.toLowerCase().indexOf("is in maintenance mode") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("maintenance mode reason") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("is out of service") > -1 ||
					tag.SimpleName?.toLowerCase().indexOf("out of service reason") > -1)
 	    } ).toArray();

		//-- creating SignalR message for each of the four tags.
		if (maintenanceModeOutOfServiceTags.length > 0) {
			var signalRDataObject = "";
			maintenanceModeOutOfServiceTags.forEach((tag:ITag) => {
				signalRDataObject += "i!" + tag.Id + "~d!" + this.utilityService.DateTimeInMilliseconds(new Date()) + "~v!";
				var tagValue = "";
				switch (tag.SimpleName?.toLowerCase())
				{
					case "is in maintenance mode":
						tagValue = obj.value.maintenanceMode ? "1" : "0";
						break;
					case "maintenance mode reason":
						tagValue = obj.value.maintenanceModeReasonId == "" || !obj.value.maintenanceMode ? "" : obj.value.maintenanceModeReasonId;
						break;
					case "is out of service":
						tagValue = obj.value.outOfService ? "1" : "0";
						break;
					case "out of service reason":
						tagValue = obj.value.outOfServiceReasonId == "" || !obj.value.outOfService ? "" : obj.value.outOfServiceReasonId;
						break;
				}
				signalRDataObject += tagValue + "\r\n";
			});
			if (signalRDataObject != "") {
				console.log("broadcasting SignalR Message about changes to asset's maintenance mode / out of service: %O", signalRDataObject);
				this.signalRCore.broadcast("o", signalRDataObject, "PS" + this.assetObj.Id);
			}
		}

		//------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

		this.dataService.SQLActionAsPromise(sqlStatement).then((data: any) => {
			Global.User.DebugMode && console.log(service.componentName + ": data = %O", data);
			//-- update data cache since leaving the Maintenance tab and coming back to it will reload what the asset was originally set to in the data cache. --Kirk T. Sherer, April 22, 2023.
			var currentAsset = service.dataService.cache.assetsObject[service.assetObj.Id];
			currentAsset.MaintenanceMode = obj.value.maintenanceMode;
			currentAsset.MaintenanceModeReasonId = obj.value.maintenanceMode == false ? null : obj.value.maintenanceModeReasonId;
			currentAsset.OutOfService = obj.value.outOfService;
			currentAsset.OutOfServiceReasonId = obj.value.outOfService == false ? null : obj.value.outOfServiceReasonId;

			service.utilityService.showToastMessageShared({
				type: "success",
				message: "Asset maintenance updated."
			});
			// this.toastr.success('Asset maintenance updated.');
		});
	}

	buildHourMeterReadingFormQuestions() {
		this.isHourMeterReadingFormLoading = true;

		this.editHeader = "Hour meter Reading";
		this.editSubHeader = "Please enter the Hour meter reading";
		this.hourMeterReadingFields = [];

		var reading = new NumberQuestion({
			key: "@Reading",
			label: "Hour Meter Reading",
			value: null,
			required: true,
			title: "Please enter the Hour meter reading.",
			order: 1
		});

		this.hourMeterReadingFields.push(reading);

		Global.User.DebugMode && console.log("pbb-summary: this.hourMeterReadingFields = %O", this.hourMeterReadingFields);

		this.isHourMeterReadingFormLoading = false;

		this.getPastHistoryOfHourMeterReadingEvents();
	}

	submitHourMeterReadingEvent(submittedValues: string) {
		Global.User.DebugMode && console.log("pbb-summary: HourMeterReading event invoked... submittedValues: %O", submittedValues);
		var fieldListAsString = submittedValues.replace("{", "").replace("}", "").replace(/:/g, "=").replace(/\"/g, "");
		Global.User.DebugMode && console.log("pbb-summary: fieldListAsString = " + fieldListAsString);
		var submittedValuesObject = JSON.parse(submittedValues);
		Global.User.DebugMode && console.log("pbb-summary: submittedValuesObject = %O", submittedValuesObject);
		Global.User.DebugMode && console.log("pbb-summary: Object.keys(submittedValuesObject) = %O", Object.keys(submittedValuesObject));
		var keys: Array<any> = Object.keys(submittedValuesObject);

		var fieldListAsString: string = "";
		var countOfFields = 1;
		keys.forEach((key: any) => {
			var value = submittedValuesObject[key];
			console.log("key: " + key + ", value: " + value);
			fieldListAsString += key + "=";
			if (isNaN(submittedValuesObject[key]) || key == "zipcode") {
				fieldListAsString += value ? "'" + value + "'" : "null";
			} else {
				fieldListAsString += value ? value : "null";
			}
			if (countOfFields < keys.length) {
				fieldListAsString += ", ";
			}
			countOfFields++;
		});

		Global.User.DebugMode && console.log("fieldListAsString = " + fieldListAsString);

		var saveStoredProcedure = this.hourMeterReadingFormOptions.saveStoredProcedureName;
		this.dataService.SQLActionAsPromise(saveStoredProcedure + " @AssetId=" + this.assetId + ", @UserId = " + Global.User.currentUser.Id + ", " + fieldListAsString).then((data: any) => {
			console.log("pbb-summary: " + saveStoredProcedure + " return value = %O", data);
			var currentHourMeterReadingEvent = data.first();
			this.getPastHistoryOfHourMeterReadingEvents(currentHourMeterReadingEvent);

			// //Verify if Tag record exists for the Asset for Hour Meter Reading(JBTStandardObservationId 56885)
			// let tagRecord = this.dataService.cache.tags.filter((t) => {
			// 	return (t.JBTStandardObservationId == 56885 && t.AssetId == this.assetId);
			// });
			// //If Tag record doesnt not exist create one and also and Observation record for the same
			// if (tagRecord.length < 1) {
			// 	this.dataService.SQLActionAsPromise(createTagRecordStoredProcedure + " @AssetId=" + this.assetId + ", @UserId = " + Global.User.currentUser.Id + ", " + fieldListAsString).then((data: any) => {
			// 		console.log("pbb-summary: " + createTagRecordStoredProcedure + " return value = %O", data);
			// 		this.dataService.SQLActionAsPromise(createObservationRecordStoredProcedure + " @AssetId=" + this.assetId + ", " + fieldListAsString).then((data: any) => {
			// 			console.log("pbb-summary: " + createObservationRecordStoredProcedure + " return value = %O", data);
			// 			this.getPastHistoryOfHourMeterReadingEvents(null, data); //-- stored procedure update will send back entire list of hourMeterReading events for this asset.
			// 		});
			// 	});
			// }
			// //If Tag record exists create Observation record for it
			// else {
			// 	this.dataService.SQLActionAsPromise(createObservationRecordStoredProcedure + " @AssetId=" + this.assetId + ", " + fieldListAsString).then((data: any) => {
			// 		console.log("pbb-summary: " + createObservationRecordStoredProcedure + " return value = %O", data);
			// 		this.getPastHistoryOfHourMeterReadingEvents(null, data); //-- stored procedure update will send back entire list of hourMeterReading events for this asset.
			// 	});
			// }
		});
	}

	getPastHistoryOfHourMeterReadingEvents(lastEvent?: any, hourMeterReadingEvents?: any) {
		if (!this.hourMeterReadingEvents || (!lastEvent && !hourMeterReadingEvents)) {
			//-- if we don't have hourMeterReading events populated yet, then go get the list of hourMeterReading events again from SQL Server.
			this.dataService.SQLActionAsPromise("API.GS_HourMeterReading_ListOfPastReadingsByAssetId " + this.assetId).then((data: any) => {
				var hourMeterReadingEvents = data;
				var hourMeterReadingEventsSorted = _.orderBy(hourMeterReadingEvents, ["Id"], ["desc"]);
				this.hourMeterReadingEvents = hourMeterReadingEventsSorted;
				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				Global.User.DebugMode && console.log("pbb-summary: this.hourMeterReadingEventsGridSettings.gridData = %O", this.hourMeterReadingEventsGridSettings.gridData);
				if (this.hourMeterReadingEvents != undefined && this.hourMeterReadingEvents.length > 0) {
					let hourMeterTagRecord = this.dataService.cache.tags.find((t) => {
						return t.JBTStandardObservationId === 56885 && t.AssetId === this.assetId;
					});
					if (hourMeterTagRecord != undefined && (hourMeterTagRecord.Value != null || hourMeterTagRecord.Value != "")) this.currentHourMeterReadingValue = hourMeterTagRecord.Value;
				}
			});
		} else {
			if (hourMeterReadingEvents) {
				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				Global.User.DebugMode && console.log("pbb-summary: this.hourMeterReadingEventsGridSettings.gridData = %O", this.hourMeterReadingEventsGridSettings.gridData);
			} else {
				Global.User.DebugMode && console.log("pbb-summary: this.hourMeterReadingEvents = %O", this.hourMeterReadingEvents);
				Global.User.DebugMode && console.log("pbb-summary: lastEvent = %O", lastEvent);

				var hourMeterReadingEvents = this.hourMeterReadingEvents;
				hourMeterReadingEvents.push(lastEvent);
				var hourMeterReadingEventsSorted = _.orderBy(hourMeterReadingEvents, ["Id"], ["desc"]);
				this.hourMeterReadingEvents = hourMeterReadingEventsSorted;
				if (this.hourMeterReadingEventsGrid) {
					this.hourMeterReadingEventsGrid.gridDataSubject.next(this.hourMeterReadingEvents);
				}
				if (lastEvent != undefined && lastEvent.Reading > 0) {
					this.currentHourMeterReadingValue = lastEvent.Reading;
				}
			}
		}
	}

	public onRightClickSelect({ dataItem, item }): void {
		if (item === "Quick Trend of Tag") {
			const tagGraphSingleModal = this.dialog.open(DialogModalParentComponent, {
				width: "95%",
				height: "80%",
				data: {
					TagId: dataItem.TagId,
					widgetNameDisplay: "Tag Graph",
					WidgetName: "tag-graph",
					isDisplayDataLive: true,
					timeZoneType: this.dashboardService.determineTimeZoneType(this.widgetObject)
				},
				maxWidth: "100vw",
				maxHeight: "100vh"
			});
			this.tagGraphSingleModalSubscription = tagGraphSingleModal.afterClosed().subscribe((result) => {
				this.tagGraphSingleModalSubscription.unsubscribe();
			});
		}
	}

}
